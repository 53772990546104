import { Select } from '@mantine/core';
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { formatSelectorData } from 'utils';
import { EmployeeAutocomplete } from 'components/AutocompleteSelectors';

export default function JobInformationSelectors({
  jobInformation,
  values,
  onChange,
  errors,
  jobInformationState,
}) {
  const { jobTitles, locations, departments, divisions } = jobInformation;

  return (
    !_.isEmpty(jobInformation) && (
      <>
        <Select
          placeholder="Job Title"
          label="Job Title"
          data={formatSelectorData(jobTitles, {
            value: 'id',
            label: 'name',
          })}
          value={values.jobtitle_id}
          error={errors.jobtitle_id}
          onChange={(value) => onChange('jobtitle_id', value)}
          required
        />
        <Select
          placeholder="Location"
          label="Location"
          data={formatSelectorData(locations, {
            value: 'id',
            label: 'name',
          })}
          value={values.location_id}
          error={errors.location_id}
          onChange={(value) => onChange('location_id', value)}
          required
        />
        <Select
          placeholder="Department"
          label="Department"
          data={formatSelectorData(departments, {
            value: 'id',
            label: 'name',
          })}
          value={values.department_id}
          error={errors.department_id}
          onChange={(value) => onChange('department_id', value)}
          required
        />
        <Select
          placeholder="Division"
          label="Division"
          data={formatSelectorData(divisions, {
            value: 'id',
            label: 'name',
          })}
          value={values.division_id}
          error={errors.division_id}
          onChange={(value) => onChange('division_id', value)}
          required
        />

        <EmployeeAutocomplete
          onChange={(val) => onChange('report_to', val)}
          formValue={values?.report_to}
          defaultValue={jobInformationState?.report_to}
          label="Reports to"
          error={errors.report_to}
        />
      </>
    )
  );
}

JobInformationSelectors.propTypes = {
  jobInformation: PropTypes.object,
  jobInformationState: PropTypes.object,
  values: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
};

JobInformationSelectors.defaultProps = {
  jobInformation: {},
  jobInformationState: {},
  values: {},
  errors: {},
  onChange: () => {},
};
