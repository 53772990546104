import { rem } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { IconCalendar } from '@tabler/icons-react';
import moment from 'moment';
import PropTypes from 'prop-types';

export default function DatePickerFormInput({
  from,
  to,
  onChangeFrom,
  onChangeTo,
  errors,
  onUpdateRange,
  onClear,
  clearable,
  ...props
}) {
  const formattedFrom = from ? moment(from, 'YYYY-MM-DD').toDate() : null;
  const formattedTo = to ? moment(to, 'YYYY-MM-DD').toDate() : null;

  return (
    <DatePickerInput
      type="range"
      label="From To Date"
      placeholder="Pick dates range"
      value={[formattedFrom, formattedTo]}
      allowSingleDateInRange
      leftSection={
        <IconCalendar
          style={{ width: rem(18), height: rem(18) }}
          stroke={1.5}
        />
      }
      valueFormat="YYYY-MM-DD"
      leftSectionPointerEvents="none"
      onChange={(value) => {
        const [start, end] = value;
        onChangeFrom(start ? moment(start, 'YYYY-MM-DD').toDate() : null);
        onChangeTo(end ? moment(end, 'YYYY-MM-DD').toDate() : null);

        onUpdateRange(start, end);
      }}
      clearable={clearable}
      allowDeselect
      error={errors}
      clearButtonProps={{ onClick: onClear }}
      {...props}
    />
  );
}

DatePickerFormInput.propTypes = {
  from: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChangeFrom: PropTypes.func.isRequired,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChangeTo: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  clearable: PropTypes.bool,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onUpdateRange: PropTypes.func,
};

DatePickerFormInput.defaultProps = {
  errors: null,
  from: null,
  to: null,
  clearable: false,
  onUpdateRange: () => {},
  onClear: () => {},
};
