import React from 'react';
import PropTypes from 'prop-types';
import { Button, Flex, Paper, Space, Stack, Title } from '@mantine/core';
import { IconClipboardText, IconPlus } from '@tabler/icons-react';
import { BoardingTaskModal } from 'components/BoardingTaskModal';
import { useStateDisclosure } from 'utils/hooks';
import { formatSelectorData } from 'utils';
import { useCopyEmployeeTasks } from 'api/hooks';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { Loader } from 'components/Loader';

export default function ImportBoardingTemplate({
  title,
  taskList,
  employeeId,
  onImportEnded,
  onSubmitTask,
  taskType,
  hireDate,
  isTaskListLoading,
}) {
  const [
    openedTaskModal,
    { open: openTaskModal, close: closeTaskModal, state: taskState },
  ] = useStateDisclosure(false);

  const { setNotifications } = useNotifications();

  const { copyEmployeeTasks, isLoading } = useCopyEmployeeTasks(
    { id: employeeId, task_type: taskType },
    {
      onSuccess: () => {
        onImportEnded();
        setNotifications(['Tasks Imported Successfully']);
      },
    },
  );

  return (
    <>
      <BoardingTaskModal
        onClose={closeTaskModal}
        opened={openedTaskModal}
        taskState={taskState}
        onSubmit={onSubmitTask}
        title="Create task for employee"
        taskList={formatSelectorData(taskList, {
          value: 'name',
          label: 'name',
        })}
        hireDate={hireDate}
        isLoading={isTaskListLoading}
      />
      <Space h="lg" />
      <Paper px={20} py={40} radius="lg">
        <Stack align="center" justify="center">
          <IconClipboardText size={250} stroke={2} />
          <Title styles={{ root: { textAlign: 'center' } }}>{title}</Title>
          <Title size={15}>
            Don&apos;t worry, it&apos;s pretty simple and shouldn&apos;t take
            long.
          </Title>
          <Loader isLoading={isLoading} />
          <Flex gap={10}>
            <Button onClick={copyEmployeeTasks} disabled={isLoading}>
              Import from template
            </Button>
            <Button
              onClick={openTaskModal}
              rightSection={<IconPlus size={14} />}
            >
              Add a Task
            </Button>
          </Flex>
        </Stack>
      </Paper>
    </>
  );
}

ImportBoardingTemplate.propTypes = {
  employeeId: PropTypes.string.isRequired,
  taskType: PropTypes.number.isRequired,
  taskList: PropTypes.array,
  title: PropTypes.string,
  hireDate: PropTypes.string,
  onImportEnded: PropTypes.func,
  onSubmitTask: PropTypes.func,
  isTaskListLoading: PropTypes.bool,
};

ImportBoardingTemplate.defaultProps = {
  title: '',
  hireDate: '',
  taskList: [],
  onSubmitTask: () => {},
  onImportEnded: () => {},
  isTaskListLoading: false,
};
