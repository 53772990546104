import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from '@mantine/form';
import {
  Button,
  Divider,
  InputError,
  InputLabel,
  Stack,
  TextInput,
  Textarea,
} from '@mantine/core';
import _ from 'lodash';
import { TipTapEditor } from 'components/TipTapEditor';
import { genericValidators } from 'utils';

export default function EmailTemplateForm({ onSubmit, emailState, isLoading }) {
  const form = useForm({
    initialValues: {
      name: _.toString(emailState?.name),
      subject: _.toString(emailState?.subject),
      description: _.toString(emailState?.description),
      message: _.toString(emailState?.message),
    },
    validate: {
      name: (value) => genericValidators.notEmpty(value, 'Name is empty'),
      subject: (value) => genericValidators.notEmpty(value, 'Subject is empty'),
      description: (value) =>
        genericValidators.notEmpty(value, 'Description is empty'),
      message: (value) => genericValidators.notEmpty(value, 'Message is empty'),
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)} noValidate>
      <Stack gap={10}>
        <TextInput
          required
          label="Name"
          placeholder="name"
          {...form.getInputProps('name')}
        />
        <Textarea
          required
          label="Description"
          placeholder="description"
          resize="block"
          rows={5}
          {...form.getInputProps('description')}
          mb={10}
        />
        <Divider />
        <TextInput
          required
          label="Subject"
          placeholder="subject"
          {...form.getInputProps('subject')}
        />
        <Stack gap={0}>
          <InputLabel required>Message</InputLabel>
          <TipTapEditor
            {...form.getInputProps('message')}
            content={_.toString(emailState?.message)}
            onChange={(value) => form.setFieldValue('message', value)}
          />
          <InputError>{form.errors.message}</InputError>
        </Stack>
        <Button disabled={isLoading} type="submit">
          Submit
        </Button>
      </Stack>
    </form>
  );
}

EmailTemplateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  emailState: PropTypes.object,
  isLoading: PropTypes.bool,
};

EmailTemplateForm.defaultProps = {
  emailState: {},
  isLoading: false,
};
