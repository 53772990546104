import {
  Box,
  Button,
  Center,
  Container,
  Paper,
  Stack,
  Title,
  rem,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { IconArrowLeft } from '@tabler/icons-react';

import PasswordConfirmation from './PasswordConfirmation';
import { getPasswordStrength } from './utils';

export default function PasswordUpdate({
  onSubmit,
  formTitle,
  submitButtonLabel,
  isLoading,
}) {
  const form = useForm({
    initialValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
    validate: {
      newPassword: (val) =>
        getPasswordStrength(val) === 100 ? null : 'The passwords is not secure',

      confirmNewPassword: (val, { newPassword }) =>
        val === newPassword ? null : "Passwords don't match",
    },
    validateInputOnChange: ['confirmNewPassword', 'newPassword'],
  });

  return (
    <Container size={620} py={50} pos="relative">
      <Link
        style={{
          color: 'var(--mantine-color-blue-6)',
          width: 'max-content',
          whiteSpace: 'nowrap',
          marginLeft: 'auto',
          display: 'block',
        }}
        to="/login"
        c="dimmed"
        size="sm"
      >
        <Center inline>
          <IconArrowLeft
            style={{ width: rem(20), height: rem(20) }}
            stroke={1.5}
          />
          <Box ml={5}>Back to the login page</Box>
        </Center>
      </Link>
      <Box m="auto" w="100%">
        <Paper withBorder p={40} shadow="md" radius="md">
          <Title ta="center" style={{ color: '#cc3d5c' }}>
            Atomate
          </Title>
          <Title ta="center" fw={800} fz={30} mb="lg">
            {formTitle}
          </Title>
          <form onSubmit={form.onSubmit(onSubmit)} noValidate>
            <Stack>
              <PasswordConfirmation
                newPassword={form.values.newPassword}
                setNewPassword={(val) => form.setFieldValue('newPassword', val)}
                confirmPassword={form.values.confirmNewPassword}
                setConfirmPassword={(val) =>
                  form.setFieldValue('confirmNewPassword', val)
                }
                newPasswordError={form.errors.newPassword}
                confirmPasswordError={form.errors.confirmNewPassword}
              />
              <Button disabled={isLoading} type="submit" w="100%">
                {submitButtonLabel}
              </Button>
            </Stack>
          </form>
        </Paper>
      </Box>
    </Container>
  );
}

PasswordUpdate.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formTitle: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  isLoading: PropTypes.bool,
};

PasswordUpdate.defaultProps = {
  formTitle: '',
  submitButtonLabel: '',
  isLoading: false,
};
