import React from 'react';
import { Button, Flex, rem } from '@mantine/core';
import {
  IconDiscountCheck,
  IconEdit,
  IconEye,
  IconPencil,
  IconSend,
} from '@tabler/icons-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { offerStatuses } from 'utils/constants';
import StepItem from './CandidateOfferStepItem';

export default function CandidateOfferSteps({ candidateInfo, currentOffer }) {
  return (
    <Flex w="90%" mx="auto" justify="space-between" align="center" gap={1}>
      <StepItem
        Icon={IconSend}
        isPassed={Boolean(currentOffer?.created_at)}
        label="Sent"
        text={moment(currentOffer?.created_at).format('MMM DD hh:mm A')}
        withLeftLine={false}
      />
      {!_.isEmpty(currentOffer?.revised) && (
        <StepItem
          Icon={IconEdit}
          isPassed
          label={`Revised \n(${currentOffer?.revised?.length})`}
          withLeftLine
          revisedOffers={currentOffer?.revised}
          candidateDetails={candidateInfo}
        />
      )}
      <StepItem
        Icon={IconEye}
        isPassed={Boolean(currentOffer?.viewed_at)}
        label="Viewed"
      />
      <StepItem
        Icon={IconPencil}
        label="Signed"
        isPassed={currentOffer?.status?.id === offerStatuses.Signed}
        withRightLine
      />
      <Button
        variant="outline"
        mb={rem(28)}
        leftSection={<IconDiscountCheck />}
      >
        Hire {candidateInfo?.first_name} {candidateInfo?.last_name}
      </Button>
    </Flex>
  );
}

CandidateOfferSteps.propTypes = {
  candidateInfo: PropTypes.object.isRequired,
  currentOffer: PropTypes.object,
};

CandidateOfferSteps.defaultProps = {
  currentOffer: {},
};
