import { Flex, Table, Text, rem } from '@mantine/core';
import { IconFileTypePdf, IconFolder } from '@tabler/icons-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { fileTypes } from 'utils/constants';
import FileContextMenu from './FileContextMenu';

export default function FileTableRow({
  file,
  onClick,
  onDelete,
  onDownload,
  fileActions,
}) {
  const createdAt = file.created_at
    ? moment(file.created_at).format('DD/MM/YYYY, h:mm a')
    : '';
  const updatedAt = file.updated_at
    ? moment(file.updated_at).format('DD/MM/YYYY, h:mm a')
    : '';
  return (
    <Table.Tr onClick={() => onClick(file)} style={{ cursor: 'pointer' }}>
      <Table.Td>
        <Flex gap="md" align="center">
          <FileContextMenu
            file={file}
            onDelete={onDelete}
            onDownload={onDownload}
            fileActions={fileActions}
          />
          {file?.type === fileTypes.file && (
            <IconFileTypePdf style={{ width: rem(20), height: rem(20) }} />
          )}
          {file?.type === fileTypes.directory && (
            <IconFolder style={{ width: rem(20), height: rem(20) }} />
          )}
          <Text size="sm">{file.name}</Text>
        </Flex>
      </Table.Td>
      <Table.Td>{file?.metadata?.children ?? 0}</Table.Td>
      <Table.Td>{file?.metadata?.size ?? 0}</Table.Td>
      <Table.Td>{createdAt}</Table.Td>
      <Table.Td>{updatedAt}</Table.Td>
      <Table.Td>{file?.description ?? ''}</Table.Td>
    </Table.Tr>
  );
}

FileTableRow.propTypes = {
  file: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onDownload: PropTypes.func,
  onDelete: PropTypes.func,
  fileActions: PropTypes.array,
};

FileTableRow.defaultProps = {
  onClick: () => {},
  onDownload: () => {},
  onDelete: () => {},
  fileActions: [],
};
