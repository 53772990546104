import { Stack } from '@mantine/core';
import { useCandidate } from 'api/hooks';
import { CandidateOfferLetter } from 'components/CandidateOfferLetter';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSetPageTitle } from 'utils/hooks';
import { getUserFullName } from 'utils';
import CandidateOfferHeader from './CandidateOfferHeader';

export default function CandidateOfferPreview() {
  // use params works outside TabsProvider
  const { offerId, candidateId } = useParams();

  const { candidateDetails, isLoading: isCandidateLoading } =
    useCandidate(candidateId);

  const offer = useMemo(
    () => candidateDetails?.offers?.find((i) => i.id === +offerId) ?? {},
    [candidateDetails, offerId],
  );

  useSetPageTitle(
    candidateDetails
      ? `Candidate - ${getUserFullName(candidateDetails)}`
      : 'Candidate',
  );

  return (
    <Stack gap={0}>
      <CandidateOfferHeader
        offer={offer ?? {}}
        candidate={candidateDetails ?? {}}
      />
      <CandidateOfferLetter
        candidateOffer={{
          ...offer,
          candidate: {
            first_name: candidateDetails?.first_name,
            last_name: candidateDetails?.last_name,
          },
        }}
        signStatus="pending"
        isLoading={isCandidateLoading}
        readOnly
      />
    </Stack>
  );
}
