import { Stack } from '@mantine/core';
import { IconCalendarEvent } from '@tabler/icons-react';
import { useAuthContext } from 'auth';
import { usePermissions } from 'components/PermissionGuard/hooks';
import AllRequests from 'modules/requests/components/AllRequests';
import { useNavigate } from 'react-router-dom';
import { requestsCategories } from 'utils/constants';
import { WidgetWrapper } from '.';

export default function TimeOffRequest(props) {
  const { user } = useAuthContext();

  const { permissions, hasPermission } = usePermissions();

  const navigate = useNavigate();

  const navigateTo = (id, type) => {
    navigate(`/requests/all-requests/${requestsCategories[type]}/${id}`);
  };

  return (
    <WidgetWrapper
      title="Time Off Requests"
      wrapperProps={{ h: 'min-content', mih: 300, mah: 450 }}
      Icon={<IconCalendarEvent size={30} color="white" />}
      {...props}
    >
      <Stack gap={0} flex={1}>
        <AllRequests
          onPreview={(data) => navigateTo(data?.id, data?.category)}
          payload={{
            TimeoffRecords: {
              assignee_id: hasPermission(permissions.canManageEmployee)
                ? ''
                : user?.id,
            },
            status: [0],
          }}
          limit={5}
          displayStatus={false}
        />
      </Stack>
    </WidgetWrapper>
  );
}
