import { Button, Flex, Stack, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { clearCache } from 'api/clearCache';
import { useLoadFileBlob, useSignRequest } from 'api/hooks';
import { useAuthContext } from 'auth';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import { Loader } from 'components/Loader';
import { PDFPreview } from 'components/PDFPreview';
import { SignatureModal } from 'components/SignatureModal';
import { BackButton } from 'modules/requests/components';
import { usePreloadedRequest } from 'modules/requests/hooks/usePreloadedRequest';
import moment from 'moment';
import { useNotifications } from 'notifications/hooks/useNotifications';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { requestStatuses } from 'utils/constants';

export default function SignatureRequestPreview({ requestId }) {
  const { request, isLoading: isRequestLoading } =
    usePreloadedRequest(requestId);

  const { setNotifications } = useNotifications();

  const [
    openedSignatureModal,
    { open: openSignatureModal, close: closeSignatureModal },
  ] = useDisclosure(false);

  const {
    file,
    isError: isLoadFileError,
    isLoading: isFileLoading,
  } = useLoadFileBlob(request?.data?.template_id, {
    enabled: !!request?.data?.template_id && !isRequestLoading,
  });
  const queryClient = useQueryClient();
  const { user } = useAuthContext();

  const { signRequest, isLoading: isSigningRequest } = useSignRequest({
    onSuccess: () => {
      setNotifications(['File Signed Successfully!']);
      closeSignatureModal();
      clearCache.onSignRequestedDocument(queryClient, {
        contentId: String(user?.id),
        contentType: 'Employee',
        requestId,
      });
    },
  });

  const isLoading = isSigningRequest || isRequestLoading || isFileLoading;

  return (
    <Stack gap="5" pos="relative">
      <SignatureModal
        opened={openedSignatureModal}
        onClose={closeSignatureModal}
        onSubmit={(data) => {
          signRequest({
            requestId,
            signature: data,
            template_id: request?.data?.template_id,
            client_time: moment(),
          });
        }}
        disabled={isSigningRequest || !openedSignatureModal}
      />
      <Flex
        justify="end"
        gap={10}
        pos="sticky"
        top={60}
        style={{ zIndex: 10 }}
        p={10}
        bg="var(--white)"
      >
        <Tooltip
          label="This document has already been signed"
          disabled={request?.status === requestStatuses.Pending}
        >
          <Button
            disabled={
              isLoading ||
              isLoadFileError ||
              request?.status === requestStatuses.Signed
            }
            loading={isSigningRequest}
            onClick={openSignatureModal}
          >
            Sign
          </Button>
        </Tooltip>
        <BackButton />
      </Flex>
      <EmptyDataMessage isVisible={isLoadFileError} />
      <Loader isLoading={isRequestLoading || isFileLoading} />
      <PDFPreview isLoading={isRequestLoading} file={file} />
    </Stack>
  );
}

SignatureRequestPreview.propTypes = {
  requestId: PropTypes.string.isRequired,
};
