import { useMemo } from 'react';
import { Table } from '@mantine/core';
import PropTypes from 'prop-types';
import { KeyValueTableRow } from 'components/KeyValueTableRow';
import { sortFields } from 'utils';

export default function PersonalTab({ profile, fields }) {
  const keyValueMap = useMemo(
    () =>
      fields?.[0].available_fields
        ?.filter(
          (field) =>
            field.enabled && field.name !== 'avatar' && profile[field.name],
        )
        ?.sort((a, b) => sortFields(a, b, ['first_name', 'last_name']))
        .map((field) => ({
          key: field.displayName,
          value: profile[field.name],
        })),
    [fields, profile],
  );
  return (
    <Table withColumnBorders withTableBorder mt={20}>
      <Table.Tbody>
        {keyValueMap?.map((pair) => (
          <KeyValueTableRow
            key={pair.key}
            label={pair.key}
            value={pair.value}
          />
        ))}
      </Table.Tbody>
    </Table>
  );
}

PersonalTab.propTypes = {
  profile: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
};
