import {
  Badge,
  Group,
  Menu,
  UnstyledButton,
  rem,
  useMantineTheme,
} from '@mantine/core';
import { useClickOutside, useDisclosure } from '@mantine/hooks';
import { IconBell } from '@tabler/icons-react';
import { usePushNotifications } from 'api/hooks';
import { useAuthContext } from 'auth';
import { useEffect } from 'react';
import echo from 'ws/echo';

import NotificationsList from 'components/Notifications/NotificationsList';
import { ThemeSwitcher } from 'components/ThemeSwitcher';
import _ from 'lodash';

export default function NotificationsPopup() {
  const theme = useMantineTheme();
  const { user } = useAuthContext();
  const [opened, handlers] = useDisclosure(false);
  const ref = useClickOutside(() => handlers.close());

  const { notifications, isLoading, invalidatedNotificationsList } =
    usePushNotifications(
      {
        userId: user?.id,
      },
      { enabled: Boolean(user) },
    );

  useEffect(() => {
    if (echo.ws) {
      echo.ws
        .channel('notification')
        .listen('.App\\Events\\Employee\\EmployeeNotification', (event) => {
          if (!isLoading && Boolean(event?.title)) {
            invalidatedNotificationsList();
          }
        });
    }
  }, [invalidatedNotificationsList, isLoading]);

  useEffect(() => {
    if (user?.id && echo.ws) {
      echo.ws
        .private(`user.${user?.id}`)
        .listen('.user.notifications', (event) => {
          if (!isLoading && Boolean(event?.title)) {
            invalidatedNotificationsList();
          }
        });
    }
  }, [invalidatedNotificationsList, isLoading, user?.id]);

  useEffect(
    () => () => {
      if (echo.ws) {
        echo.ws.leave(`user.${user?.id}`);
        echo.ws.leave('notification');
      }
    },
    [user?.id],
  );

  const unreadNotificationsCount =
    notifications?.filter((n) => n?.status === 0)?.length ?? 0;
  const hasNotifications = unreadNotificationsCount > 0;

  return (
    <Menu
      width={450}
      position="bottom-end"
      transitionProps={{ transition: 'pop-top-right' }}
      withinPortal
      opened={opened}
    >
      <ThemeSwitcher />
      <Menu.Target>
        <UnstyledButton onClick={() => handlers.toggle()}>
          <Group gap={7}>
            <IconBell
              style={{ width: rem(24), height: rem(24) }}
              color={hasNotifications ? theme.colors.blue[6] : 'grey'}
              stroke={1.5}
            />
            {hasNotifications && (
              <Badge
                size="xs"
                variant="filled"
                style={{ marginLeft: '-18px', marginTop: '-16px' }}
              >
                {unreadNotificationsCount}
              </Badge>
            )}
          </Group>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown ref={ref} style={{ maxHeight: '50%', overflowY: 'auto' }}>
        <NotificationsList
          notifications={
            _.isArray(notifications)
              ? notifications.slice(0, 15)
              : notifications
          }
          isLoading={isLoading}
          onMarkedAsRead={() => invalidatedNotificationsList()}
          closeMenu={() => handlers.close()}
        />
      </Menu.Dropdown>
    </Menu>
  );
}
