import {
  Button,
  Flex,
  InputError,
  InputLabel,
  Select,
  Stack,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useLoadEmailTemplates } from 'api/hooks';
import { TipTapEditor } from 'components/TipTapEditor';
import React from 'react';
import { formatSelectorData } from 'utils';
import PropTypes from 'prop-types';

export default function CandidateSendEmailForm({ onSubmit, isLoading }) {
  const { emailTemplates } = useLoadEmailTemplates();

  const form = useForm({
    initialValues: {
      message: '',
      subject: '',
      template: '',
    },
  });

  return (
    <form
      onSubmit={form.onSubmit(({ message, subject }) =>
        onSubmit({
          message,
          subject:
            emailTemplates?.find((item) => +item.id === +subject)?.subject ??
            '',
        }),
      )}
      noValidate
    >
      <Stack>
        <Select
          {...form.getInputProps('subject')}
          label="Email Template"
          placeholder="Select Email Template"
          data={formatSelectorData(emailTemplates, {
            value: 'id',
            label: 'subject',
          })}
          onChange={(val, rest) => {
            form.setFieldValue('subject', val);
            form.setFieldValue('template', rest?.message);
            form.setFieldValue('message', rest?.message);
          }}
        />
        <Stack gap={0}>
          <InputLabel required>Message</InputLabel>
          <TipTapEditor
            {...form.getInputProps('message')}
            content={form.values.template}
            onChange={(value) => form.setFieldValue('message', value)}
          />
          <InputError>{form.errors.message}</InputError>
        </Stack>
        <Flex justify="flex-end" gap={5}>
          <Button disabled={isLoading} type="submit">
            Send now
          </Button>
        </Flex>
      </Stack>
    </form>
  );
}

CandidateSendEmailForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

CandidateSendEmailForm.defaultProps = {
  isLoading: false,
};
