import { Draggable } from '@hello-pangea/dnd';
import PropTypes from 'prop-types';
import GenericActiveWidget from './GenericActiveWidget';

export default function DraggableItems({ widgets, isFetching }) {
  return widgets?.map((widget, index) => (
    <Draggable key={widget.id} index={index} draggableId={String(widget.id)}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <GenericActiveWidget
            key={widget?.id}
            widget={widget}
            isFetching={isFetching}
            deletable={!widget?.is_required}
          />
        </div>
      )}
    </Draggable>
  ));
}

DraggableItems.propTypes = {
  widgets: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
};
