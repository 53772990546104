import { FormOption } from 'components/FormOption';
import React from 'react';
import PropTypes from 'prop-types';
import { IconCalendar } from '@tabler/icons-react';

export default function Flexible({ onClick, selected, error, viewOnly }) {
  return (
    <FormOption
      label="Flexible (unlimited)"
      text="There is no balance to deduct from, but I still want time off requests to be approved and tracked."
      icon={<IconCalendar size="30" />}
      onClick={onClick}
      selected={selected}
      error={error}
      viewOnly={viewOnly}
    />
  );
}

Flexible.propTypes = {
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  error: PropTypes.bool,
  viewOnly: PropTypes.bool,
};

Flexible.defaultProps = {
  onClick: () => {},
  selected: false,
  error: false,
  viewOnly: false,
};
