import { Modal } from '@mantine/core';
import { CreateEditBandForm } from 'components/CreateEditBandForm';
import PropTypes from 'prop-types';

export default function CreateEditBandModal({
  opened,
  onClose,
  state,
  onEdit,
  onCreate,
  isLoading,
  jobTitles,
  compensationGroups,
}) {
  const isEdit = state?.modalType === 'Edit';

  return (
    <Modal
      title={`${isEdit ? 'Edit' : 'Create'} Band`}
      opened={opened}
      onClose={onClose}
      centered
      size="lg"
    >
      <CreateEditBandForm
        state={state}
        onSubmit={isEdit ? onEdit : onCreate}
        isLoading={!opened || isLoading}
        jobTitles={jobTitles}
        compensationGroups={compensationGroups}
      />
    </Modal>
  );
}

CreateEditBandModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  state: PropTypes.object,
  jobTitles: PropTypes.array,
  compensationGroups: PropTypes.array,
};

CreateEditBandModal.defaultProps = {
  isLoading: false,
  state: undefined,
  jobTitles: [],
  compensationGroups: [],
};
