import { Tabs } from '@mantine/core';
import PropTypes from 'prop-types';

export default function TabList({ children, withPaddings, ...props }) {
  return (
    <Tabs.List
      style={{ position: 'sticky', zIndex: 10 }}
      top={60}
      left={0}
      right={0}
      {...(withPaddings ? { ml: -20, mr: -20 } : {})}
      bg="var(--white)"
      {...props}
    >
      {children}
    </Tabs.List>
  );
}

TabList.propTypes = {
  children: PropTypes.node.isRequired,
  withPaddings: PropTypes.bool,
};

TabList.defaultProps = {
  withPaddings: true,
};
