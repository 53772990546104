import { useEffect, useMemo } from 'react';
import { useTimeOffCategories, useTimeOffPolicies } from 'api/hooks';
import { usePermissions } from 'components/PermissionGuard/hooks';
import _ from 'lodash';
import moment from 'moment';

export const useTimeOffGroupedPolicies = (props, options) => {
  const { hasPermission, permissions } = usePermissions();
  const { employeeId, showEmptyCategories = true } = props ?? {};
  const {
    categories,
    isLoading: isCategoriesLoading,
    refetch: refetchCategories,
  } = useTimeOffCategories(
    { canManageEmployee: hasPermission(permissions.canManageEmployee) },
    options,
  );
  const {
    policies,
    isLoading: isPoliciesLoading,
    refetch: refetchPolicies,
    policyTypes,
  } = useTimeOffPolicies(
    {
      employeeId,
      canManageEmployee: hasPermission(permissions.canManageEmployee),
    },
    options,
  );

  const groupedCategories = useMemo(() => {
    const groups = categories.map((category) => ({
      ...category,
      policies: policies?.filter(
        (policy) => policy.category_id === category.id,
      ),
    }));

    const otherPolicies = policies.filter((policy) => !policy.category_id);
    if (otherPolicies.length > 0) {
      groups.push({
        id: 0,
        name: 'All Policies',
        policies: otherPolicies,
      });
    }

    if (!showEmptyCategories) {
      return groups.filter((item) => item.policies?.length > 0);
    }
    return groups;
  }, [categories, policies, showEmptyCategories]);
  return {
    groupedCategories,
    policies,
    categories,
    isPoliciesLoading,
    refetchPolicies,
    isCategoriesLoading,
    refetchCategories,
    policyTypes,
  };
};

export const usePolicyTypeSelect = ({ formValues, onChange, scheduleType }) => {
  const values = useMemo(
    () => _.get(formValues, `settings.accrue.period.${scheduleType}`, null),
    [formValues, scheduleType],
  );

  useEffect(() => {
    if (!values) {
      onChange('settings.accrue.period', {
        [scheduleType]: {},
      });
    }
  }, [values, onChange, scheduleType]);

  return values;
};

export const useComingWeeks = ({ week }) => {
  const comingWeeks = useMemo(() => {
    if (!week) return {};

    const todayWeekday = moment().weekday();

    const weekDayIndex = moment.weekdays().findIndex((item) => item === week);

    const startDate =
      weekDayIndex === todayWeekday
        ? moment()
        : moment().add(
            // check start next week relative current week
            // todo add tests
            todayWeekday > weekDayIndex
              ? todayWeekday + weekDayIndex - 1
              : weekDayIndex - todayWeekday,
            'days',
          );

    const range = {
      currentWeek: [],
      nextWeek: [],
    };

    // add coming weeks range
    Array.from({ length: 6 }).forEach((val, index) => {
      if (index % 2) {
        range.nextWeek.push(startDate.format('MMM DD'));
      } else {
        range.currentWeek.push(startDate.format('MMM DD'));
      }

      startDate.add(7, 'days');
    });

    return range;
  }, [week]);

  return comingWeeks;
};
