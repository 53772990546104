import { Space } from '@mantine/core';
import { RequestRow } from 'components/Requests';
import { SignatureRow } from 'components/Requests/rows';
import TimeOffRow from 'components/Requests/rows/TimeOffRow';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export default function AllRequestsList({
  requests,
  onPreview,
  displayStatus,
}) {
  return _.map(requests, (request) => (
    <React.Fragment key={request?.id}>
      <Space h="md" />

      {request?.category === 'TimeoffRecords' && (
        <TimeOffRow
          request={request}
          onClick={() => onPreview(request)}
          displayStatus={displayStatus}
        />
      )}

      {request?.category === 'Performance' && (
        <RequestRow request={request} onClick={onPreview} />
      )}

      {request?.category === 'Signature' && (
        <SignatureRow request={request} onClick={onPreview} />
      )}
    </React.Fragment>
  ));
}

AllRequestsList.propTypes = {
  requests: PropTypes.array.isRequired,
  onPreview: PropTypes.func.isRequired,
  displayStatus: PropTypes.bool,
};

AllRequestsList.defaultProps = {
  displayStatus: true,
};
