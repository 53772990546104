import { useEmployees } from 'api/hooks';
import { EmployeesGrid } from 'components/EmployeesGrid';
import { usePermissions } from 'components/PermissionGuard/hooks';
import React from 'react';

export default function HeadCountTab() {
  const { permissions, hasPermission } = usePermissions();
  const { employees, isLoading } = useEmployees({
    canManageEmployee: hasPermission(permissions.canManageEmployee),
    status: 1,
    params: {
      sortBy: '-hire_date',
    },
  });

  return <EmployeesGrid isLoading={isLoading} employees={employees} />;
}
