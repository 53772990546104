import { Flex, LoadingOverlay, Space, Text } from '@mantine/core';
import { useRequests } from 'api/hooks';
import { useAuthContext } from 'auth';
import { usePermissions } from 'components/PermissionGuard/hooks';
import { RequestRow } from 'components/Requests';
import { SurveyPreview } from 'components/Survey/views';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { requestStatusesVal, requestsCategories } from 'utils/constants';

export default function CompletedTab({ requestId, onPreview }) {
  const { user } = useAuthContext();
  const { hasPermission, permissions } = usePermissions();

  const { requests, isLoading } = useRequests(
    {
      filters: {
        Performance: {
          assignee_id: user?.id,
          status: [requestStatusesVal.Completed],
        },
      },
      sortBy: '-updated_at',
      limit: 30,
      canManageEmployee: hasPermission(permissions.canManageEmployee),
    },
    { enabled: Boolean(user?.id) },
  );

  if (requestId) {
    return <SurveyPreview requestId={requestId} />;
  }

  return (
    <>
      <h2>Performance</h2>
      <LoadingOverlay
        visible={isLoading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      {requests?.map((request) => (
        <Fragment key={request.id}>
          <Space h="md" />
          <RequestRow
            request={request}
            onClick={() => {
              if (requestsCategories[request.category]) {
                onPreview(request);
              }
            }}
            requestType="completed"
          />
        </Fragment>
      ))}
      {!isLoading && requests?.length === 0 && (
        <Flex align="center" justify="center">
          <Text fw={500} size="xl" c="dimmed">
            There are no records
          </Text>
        </Flex>
      )}
    </>
  );
}

CompletedTab.propTypes = {
  onPreview: PropTypes.func.isRequired,
  requestId: PropTypes.string,
};

CompletedTab.defaultProps = {
  requestId: '',
};
