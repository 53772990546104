import { Modal } from '@mantine/core';
import { CreateEditEmployeeAssetsForm } from 'components/CreateEditEmployeeAssetsForm';
import PropTypes from 'prop-types';

export default function CreateEditEmployeeAssetsModal({
  opened,
  onClose,
  onCreate,
  onEdit,
  assetState,
  isLoading,
  assetCategories,
}) {
  const isEdit = assetState?.modalType === 'Edit';

  return (
    <Modal
      title={`${isEdit ? 'Edit' : 'Create'} employee asset`}
      opened={opened}
      onClose={onClose}
      centered
    >
      <CreateEditEmployeeAssetsForm
        state={assetState}
        onSubmit={isEdit ? onEdit : onCreate}
        isLoading={isLoading}
        assetCategories={assetCategories}
      />
    </Modal>
  );
}

CreateEditEmployeeAssetsModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  assetState: PropTypes.object,
  assetCategories: PropTypes.array,
  isLoading: PropTypes.bool,
};

CreateEditEmployeeAssetsModal.defaultProps = {
  assetState: {},
  assetCategories: [],
  isLoading: false,
};
