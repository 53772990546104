import React from 'react';
import { IconUserCircle } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { FormOption } from 'components/FormOption';

export default function HireDateTypeItem({
  onClick,
  selected,
  error,
  viewOnly,
}) {
  return (
    <FormOption
      label="By Hire Date"
      text="Reviews start relative to employees' hire dates."
      icon={<IconUserCircle size="30" />}
      onClick={onClick}
      selected={selected}
      error={error}
      viewOnly={viewOnly}
    />
  );
}

HireDateTypeItem.propTypes = {
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  error: PropTypes.bool,
  viewOnly: PropTypes.bool,
};

HireDateTypeItem.defaultProps = {
  onClick: () => {},
  selected: false,
  error: false,
  viewOnly: false,
};
