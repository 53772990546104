import { rem, Menu, Button } from '@mantine/core';
import { IconDotsVertical } from '@tabler/icons-react';
import _ from 'lodash';
import PropTypes from 'prop-types';

export default function ContextMenu({
  menuItems,
  menuIcon: MenuIcon,
  width,
  targetPosition,
}) {
  const hasItems = _.some(
    menuItems,
    ({ condition }) => condition === undefined || condition === true,
  );

  if (menuItems.length === 0 || !hasItems) {
    return null;
  }

  return (
    <Menu shadow="md" width={width}>
      <Menu.Target>
        <Button
          onClick={(e) => e.stopPropagation()}
          variant="subtle"
          color="gray"
          p={0}
          pos={targetPosition}
        >
          <MenuIcon style={{ width: rem(20), height: rem(20) }} />
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        {menuItems.map((menuItem) => {
          const Icon = menuItem.icon;
          const condition = menuItem?.condition;

          if (condition !== undefined && condition !== true) return null;
          return (
            <Menu.Item
              key={menuItem.label}
              leftSection={<Icon style={{ width: rem(14), height: rem(14) }} />}
              onClick={(e) => {
                e.stopPropagation();
                menuItem.onClick();
              }}
            >
              {menuItem.label}
            </Menu.Item>
          );
        })}
      </Menu.Dropdown>
    </Menu>
  );
}

ContextMenu.propTypes = {
  menuItems: PropTypes.array.isRequired,
  menuIcon: PropTypes.object,
  width: PropTypes.number,
  targetPosition: PropTypes.string,
};
ContextMenu.defaultProps = {
  menuIcon: IconDotsVertical,
  width: 200,
  targetPosition: '',
};
