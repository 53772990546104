import { Button, Divider, Stack, TextInput, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { genericValidators } from 'utils';
import { FormEditorField } from 'components/FormEditorField';

export default function OfferTemplateForm({ offerState, isLoading, onSubmit }) {
  const form = useForm({
    initialValues: {
      name: _.toString(offerState?.name),
      subject: _.toString(offerState?.subject),
      description: _.toString(offerState?.description),
      message: _.toString(offerState?.message),
      letter: _.toString(offerState?.letter),
    },
    validate: {
      name: (value) => genericValidators.notEmpty(value, 'Name is empty'),
      subject: (value) => genericValidators.notEmpty(value, 'Subject is empty'),
      description: (value) =>
        genericValidators.notEmpty(value, 'Description is empty'),
      message: (value) => genericValidators.notEmpty(value, 'Message is empty'),
      letter: (value) => genericValidators.notEmpty(value, 'Letter is empty'),
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)} noValidate>
      <Stack gap={10}>
        <TextInput
          required
          label="Name"
          placeholder="name"
          {...form.getInputProps('name')}
        />
        <Textarea
          required
          label="Description"
          placeholder="description"
          resize="block"
          rows={5}
          mb="10"
          {...form.getInputProps('description')}
        />
        <Divider />
        <TextInput
          required
          label="Subject"
          placeholder="subject"
          {...form.getInputProps('subject')}
        />

        <FormEditorField
          label="Letter"
          required
          template={offerState?.letter}
          onChange={(val) => form.setFieldValue('letter', val)}
          errors={form.errors.letter}
        />

        <FormEditorField
          label="Offer Email"
          required
          template={offerState?.message}
          onChange={(val) => form.setFieldValue('message', val)}
          errors={form.errors.message}
        />

        <Button disabled={isLoading} type="submit">
          Submit
        </Button>
      </Stack>
    </form>
  );
}

OfferTemplateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  offerState: PropTypes.object,
  isLoading: PropTypes.bool,
};

OfferTemplateForm.defaultProps = {
  offerState: {},
  isLoading: false,
};
