import { Flex } from '@mantine/core';

export default function AppSplashScreen() {
  return (
    <Flex
      justify="center"
      align="center"
      h="100vh"
      w="100vw"
      style={{ zIndex: 999, backgroundColor: 'white', position: 'absolute' }}
    >
      <svg
        fill="none"
        height="70"
        viewBox="0 0 24 24"
        width="70"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M4 8L12 3L16 5.5M4 8V16L12 21M4 8L8 10.5M12 13L20 8M12 13V21M12 13L8 10.5M20 8V16L12 21M20 8L16 5.5M8 10.5L16 5.5" />
      </svg>
    </Flex>
  );
}
