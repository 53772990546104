import { Button, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconLink } from '@tabler/icons-react';
import { useLoadLookups } from 'api/hooks';
import { CompanyLinksModal } from 'components/CompanyLinksModal';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import _ from 'lodash';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { lookupsModels } from 'utils/constants';
import styles from '../styles.module.css';
import WidgetWrapper from './WidgetWrapper';

const maxLinksInView = 5;

export default function CompanyLinks() {
  const [
    openedCompanyLinksModal,
    { open: openCompanyLinksModal, close: closeCompanyLinksModal },
  ] = useDisclosure(false);

  const { lookups, isLoading: isLookupsLoading } = useLoadLookups({
    models: [lookupsModels.link],
  });

  const formattedLinks = useMemo(
    () =>
      lookups?.[lookupsModels.link]?.reduce(
        ({ data, totalLinksInView, totalLinks }, curr) => {
          if (totalLinksInView === maxLinksInView)
            return {
              data,
              totalLinksInView,
              totalLinks: totalLinks + (curr?.links?.length ?? 0),
            };

          const cloned = _.clone(curr.links);
          const slicedData = cloned.slice(0, maxLinksInView - totalLinksInView);
          return {
            data: data?.concat({ ...curr, links: slicedData }),
            totalLinksInView: totalLinksInView + slicedData.length,
            totalLinks: totalLinks + (curr?.links?.length ?? 0),
          };
        },
        { data: [], totalLinksInView: 0, totalLinks: 0 },
      ),
    [lookups],
  );

  return (
    <WidgetWrapper
      title="Company Links"
      Icon={<IconLink color="white" size={30} />}
      isLoading={isLookupsLoading}
    >
      <CompanyLinksModal
        onClose={closeCompanyLinksModal}
        opened={openedCompanyLinksModal}
        companyLinks={lookups?.[lookupsModels.link] ?? []}
      />
      <EmptyDataMessage
        isVisible={!isLookupsLoading && _.isEmpty(formattedLinks?.data)}
        m="auto"
      />
      <Stack gap={0}>
        {formattedLinks?.data?.map((category) => (
          <Stack gap={0} key={category?.id}>
            <Text fz="lg" fw="500">
              {category?.name}
            </Text>
            {category?.links?.map((link) => (
              <Link
                key={link?.id}
                to={link?.url}
                target={
                  !link?.url?.startsWith?.(window.location.origin)
                    ? '_blank'
                    : undefined
                }
                className={styles.hoverOpacity}
              >
                {link?.name}
              </Link>
            ))}
          </Stack>
        ))}
        <Button
          onClick={openCompanyLinksModal}
          variant="transparent"
          ta="left"
          w="max-content"
          p={0}
          c="gray"
        >
          {formattedLinks?.totalLinks > maxLinksInView
            ? `${formattedLinks.totalLinks - maxLinksInView} more links...`
            : ''}
        </Button>
      </Stack>
    </WidgetWrapper>
  );
}
