import { Container, LoadingOverlay } from '@mantine/core';

import {
  usePerformanceCycle,
  useSurveys,
  useUpdatePerformanceCycle,
} from 'api/hooks';
import { queryKeys } from 'api/keys';
import { PageWrapper } from 'components/PageWrapper';
import { PerformanceCycleEditForm } from 'components/PerformanceManagement';
import {
  employeeTypes,
  filterOnlySelectedValues,
} from 'components/PerformanceManagement/forms/utils';
import _ from 'lodash';
import moment from 'moment';
import { useNotifications } from 'notifications/hooks/useNotifications';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  performanceCycleDateTypes,
  surveyStatusesObj,
  surveys as surveysMeta,
} from 'utils/constants';
import { usePageTitle } from 'utils/hooks';

export default function PerformanceCycle({ params, globalTabName }) {
  const { cycleId } = params;
  const { setNotifications } = useNotifications();
  const { cycle, isLoading, isFetching } = usePerformanceCycle(cycleId);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { surveys, isLoading: surveyLoading } = useSurveys({
    'filter[type][]': [
      surveysMeta.assessment.value,
      surveysMeta.manager.value,
      surveysMeta.peerFeedback.value,
      surveysMeta.upwardFeedback.value,
    ],
    'filter[status]': surveyStatusesObj.Active,
    limit: 10,
  });

  const { updateCycle, isLoading: isUpdatingCycle } = useUpdatePerformanceCycle(
    {
      onSuccess: ({ data }) => {
        setNotifications(['Performance Cycle successfully updated']);
        queryClient.invalidateQueries(queryKeys.performanceCycles());
        queryClient.invalidateQueries(queryKeys.performanceCycle(data?.id));
        navigate(`/settings/performance-management`);
      },
    },
  );

  const surveysObj = useMemo(
    () => ({
      selfAssessmentSurveys: surveys?.filter(
        (s) => `${s.type}` === surveysMeta.assessment.value,
      ),
      managerSurveys: surveys?.filter(
        (s) => `${s.type}` === surveysMeta.manager.value,
      ),
      peerFeedback: surveys?.filter(
        (s) => `${s.type}` === surveysMeta.peerFeedback.value,
      ),
      upwardFeedback: surveys?.filter(
        (s) => `${s.type}` === surveysMeta.upwardFeedback.value,
      ),
    }),
    [surveys],
  );

  const onCycleSubmit = (values) => {
    const {
      assessment,
      assessmentFrequency,
      assessmentStartDate,
      feedback,
      feedbackStartDate,
      feedbackFrequency,
      feedbackSurveyIds,
      assessmentSurveyIds,
      status,
      filters,
      employee_type: employeeType,
      hasPeerFeedback,
      hasUpwardFeedback,
      ...rest
    } = values;

    const feedbackPayload = {
      feedback,
      f_start_date:
        feedback && cycle?.type === performanceCycleDateTypes.fixedDate
          ? moment(feedbackStartDate).format('YYYY-MM-DD')
          : undefined,
      f_frequency: feedback ? values.feedbackFrequency : '',
      f_repeat:
        feedback && cycle?.type === performanceCycleDateTypes.hireDate
          ? values.feedbackRepeat
          : false,
    };
    const assessmentPayload = {
      assessment,
      a_start_date:
        values.assessment && cycle?.type === performanceCycleDateTypes.fixedDate
          ? moment(values.assessmentStartDate).format('YYYY-MM-DD')
          : undefined,
      a_frequency: values.assessment ? values.assessmentFrequency : '',
      a_repeat:
        values.assessment && cycle?.type === performanceCycleDateTypes.hireDate
          ? values.assessmentRepeat
          : false,
    };
    const surveyIds = [];
    if (feedback) {
      const peerFeedbackIds = _.map(surveysObj.peerFeedback, 'id');
      const upwardFeedbackIds = _.map(surveysObj.upwardFeedback, 'id');

      const filteredPeerFeedbackIds = hasPeerFeedback
        ? feedbackSurveyIds?.filter((item) => peerFeedbackIds.includes(item))
        : [];

      const filteredUpwardFeedbackIds = hasUpwardFeedback
        ? feedbackSurveyIds?.filter((item) => upwardFeedbackIds.includes(item))
        : [];

      surveyIds.push(...filteredUpwardFeedbackIds, ...filteredPeerFeedbackIds);
    }
    if (assessment) {
      surveyIds.push(...assessmentSurveyIds);
    }
    const payload = {
      survey_ids: surveyIds,
      status: _.toNumber(status),
      type: cycle?.type,
      filters:
        employeeType === employeeTypes.someEmployee
          ? filterOnlySelectedValues(filters)
          : {},
      ...feedbackPayload,
      ...assessmentPayload,
      ...rest,
    };
    updateCycle({ id: cycleId, payload });
  };

  const isSurveysLoading = surveyLoading || isFetching;

  usePageTitle('Edit Performance Cycle - Settings', globalTabName);

  return (
    <PageWrapper title="Edit Performance Cycle">
      <LoadingOverlay
        visible={isLoading || isFetching}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <Container px={0} size="md">
        {!isLoading && !isSurveysLoading && (
          <PerformanceCycleEditForm
            cycle={cycle}
            assessmentSurveys={surveysObj.selfAssessmentSurveys}
            managerSurveys={surveysObj.managerSurveys}
            peerFeedbackSurveys={surveysObj.peerFeedback}
            upwardFeedbackSurveys={surveysObj.upwardFeedback}
            onSubmit={onCycleSubmit}
            isLoading={isUpdatingCycle || isSurveysLoading}
          />
        )}
      </Container>
    </PageWrapper>
  );
}

PerformanceCycle.propTypes = {
  params: PropTypes.object,
  globalTabName: PropTypes.string,
};

PerformanceCycle.defaultProps = {
  params: {},
  globalTabName: '',
};
