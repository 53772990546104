import { Space, Tabs } from '@mantine/core';
import { IconTournament, IconUsersGroup } from '@tabler/icons-react';
import { PageWrapper } from 'components/PageWrapper';
import { TabList, TabsCore } from 'components/Tabs';
import PropTypes from 'prop-types';
import { usePageTitle } from 'utils/hooks';
import { EmployeeOrgChart, Employees } from './EmployeesTabs';

export default function EmployeesProvider({ globalTabName }) {
  usePageTitle('All Employees', globalTabName);

  return (
    <PageWrapper title="Employees">
      <TabsCore defaultValue="employee">
        <TabList>
          <Tabs.Tab value="employee" leftSection={<IconUsersGroup size={20} />}>
            Employees
          </Tabs.Tab>
          <Tabs.Tab
            value="org-chart"
            leftSection={<IconTournament size={20} />}
          >
            Org Chart
          </Tabs.Tab>
        </TabList>
        <Tabs.Panel value="employee">
          <Space h="md" />
          <Employees />
        </Tabs.Panel>
        <Tabs.Panel value="org-chart">
          <Space h="md" />
          <EmployeeOrgChart />
        </Tabs.Panel>
      </TabsCore>
    </PageWrapper>
  );
}

EmployeesProvider.propTypes = {
  globalTabName: PropTypes.string,
};

EmployeesProvider.defaultProps = {
  globalTabName: '',
};
