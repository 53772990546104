import React from 'react';
import { Box, Flex, Paper, Stack, Text, rem } from '@mantine/core';

import PropTypes from 'prop-types';
import { PreviousOffersMenu } from 'components/PreviousOffersMenu';

export default function StepItem({
  Icon,
  isPassed,
  label,
  text,
  withLeftLine,
  withRightLine,
  revisedOffers,
  candidateDetails,
}) {
  const color = isPassed
    ? 'var(--mantine-color-green-6)'
    : 'var(--mantine-color-gray-6)';

  const lineColor = isPassed
    ? 'var(--mantine-color-green-6)'
    : 'var(--mantine-color-gray-3)';

  const statusColor = isPassed
    ? 'var(--mantine-black)'
    : 'var(--mantine-color-gray-6)';

  return (
    <>
      {withLeftLine && <Box flex={1} mb={rem(28)} bg={lineColor} h="3px" />}
      <Stack gap={3} align="center">
        <Paper
          radius="100%"
          p={10}
          withBorder={isPassed}
          styles={{
            root: {
              borderColor: color,
              borderWidth: '2px',
            },
          }}
          bg={isPassed ? undefined : 'var(--mantine-color-gray-3)'}
        >
          <Flex align="center" justify="center">
            <Icon size={50} color={color} />
          </Flex>
        </Paper>
        <Stack gap={0} align="center" pos="relative">
          <PreviousOffersMenu
            offers={revisedOffers}
            statusColor={statusColor}
            label={label}
            candidateDetails={candidateDetails}
          />
          <Text
            c="var(--mantine-color-gray-6)"
            pos="absolute"
            left={-40}
            right={-70}
            bottom={-20}
            h={25}
          >
            {text}
          </Text>
        </Stack>
      </Stack>
      {withRightLine && <Box flex={1} mb={rem(28)} bg={lineColor} h="3px" />}
    </>
  );
}

StepItem.propTypes = {
  Icon: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  candidateDetails: PropTypes.object,
  text: PropTypes.string,
  withLeftLine: PropTypes.bool,
  withRightLine: PropTypes.bool,
  isPassed: PropTypes.bool,
  revisedOffers: PropTypes.array,
};

StepItem.defaultProps = {
  isPassed: false,
  candidateDetails: {},
  withLeftLine: true,
  withRightLine: false,
  text: '',
  revisedOffers: [],
};
