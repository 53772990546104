import { Tabs } from '@mantine/core';
import PropTypes from 'prop-types';

export default function TabsCore({ children, ...props }) {
  return (
    <Tabs keepMounted={false} pos="relative" ml={10} mr={10} {...props}>
      {children}
    </Tabs>
  );
}

TabsCore.propTypes = {
  children: PropTypes.node.isRequired,
};
