import {
  Box,
  Button,
  Flex,
  LoadingOverlay,
  Paper,
  Tabs,
  Text,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPlaylistAdd } from '@tabler/icons-react';
import { useEmployee, useEmployeeSchema } from 'api/hooks';
import { PageWrapper } from 'components/PageWrapper';
import { RequestModal } from 'components/Requests';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { useAuthContext } from 'auth';
import { PermissionGuard } from 'components/PermissionGuard';
import { usePermissions } from 'components/PermissionGuard/hooks';
import { UserAvatar } from 'components/UserAvatar';
import {
  boardingListTypes,
  employeeStatusTypes,
  hideForNow,
  tabNames,
} from 'utils/constants';
import { useBasePath, usePageTitle, useTabNavigate } from 'utils/hooks';

import { ChangeEmployeeStatusModal } from 'components/ChangeEmployeeStatusModal';
import { useChangeEmployeeStatusModal } from 'components/ChangeEmployeeStatusModal/hooks';
import { TabList, TabsCore } from 'components/Tabs';
import moment from 'moment';
import { Options } from './options';
import {
  AssetsTab,
  EmployeeBoardingTab,
  FilesTab,
  JobInformationTab,
  NotesTab,
  PerformanceTab,
  PersonalTab,
  TimeOffTab,
  TrainingTab,
} from './tabs';

export default function EmployeeProfile({
  isMyProfile,
  params,
  globalTabName,
}) {
  const { user } = useAuthContext();
  const { userId: id, tab } = params;

  const navigate = useTabNavigate();

  const basePath = useBasePath();

  const userId = useMemo(
    () => (isMyProfile ? _.toString(user?.id) : id),
    [isMyProfile, id, user],
  );

  const { permissions, hasPermission } = usePermissions();
  const { profile, isLoading, error } = useEmployee({
    id: userId,
    canManageEmployee: hasPermission(permissions.canManageEmployee),
  });
  const { schema, isLoading: isSchemaLoading } = useEmployeeSchema({
    canManageEmployee: hasPermission(permissions.canManageEmployee),
  });

  const [isModalOpened, { close: closeModal, open: openModal }] =
    useDisclosure(false);

  const canAccess = useMemo(
    () => hasPermission(permissions.canManageEmployee) || isMyProfile,
    [hasPermission, permissions, isMyProfile],
  );

  const pageTitle = useMemo(() => {
    const fullName = `${profile?.first_name ?? ''} ${profile?.last_name ?? ''}`;
    return profile
      ? `${_.upperFirst(tab ?? 'personal')} - ${fullName} - ${
          isMyProfile ? 'My Info' : 'Employees'
        }`
      : '';
  }, [profile, tab, isMyProfile]);

  usePageTitle(pageTitle, globalTabName);

  const canEditAvatar =
    hasPermission(permissions.canManageEmployee) || isMyProfile;

  const { getModalProps, openChangeEmployeeStatusModal, statuses } =
    useChangeEmployeeStatusModal({
      employeeId: userId,
    });

  return (
    <PageWrapper
      title={`${profile?.first_name ?? ''} ${profile?.last_name ?? ''}`}
      withFlexStyles
      rightSection={
        canAccess && (
          <Flex gap={10}>
            {hideForNow && (
              <Button
                onClick={() => {
                  openModal();
                }}
                rightSection={<IconPlaylistAdd size={20} />}
                data-testid="create-request-button"
              >
                Create Request
              </Button>
            )}
            <Options
              profile={profile ?? {}}
              onChangeEmployeeStatus={() =>
                openChangeEmployeeStatusModal({
                  start_date: moment().format('YYYY-MM-DD'),
                  status_id:
                    statuses?.find(
                      (item) => item?.type === employeeStatusTypes.terminated,
                    )?.id ?? '',
                })
              }
            />
          </Flex>
        )
      }
    >
      <ChangeEmployeeStatusModal {...getModalProps()} />
      <LoadingOverlay
        visible={isLoading || isSchemaLoading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <RequestModal opened={isModalOpened} onClose={closeModal} />
      {profile && schema && (
        <Flex gap="md" bg="var(--white)" ml="-10">
          <Flex
            justify="flex-start"
            align="flex-start"
            direction="column"
            wrap="wrap"
            ml="10"
          >
            <UserAvatar
              avatarUrl={profile?.avatar_filename}
              cursor={canEditAvatar ? 'pointer' : ''}
              disabled={!canEditAvatar}
              employeeId={profile?.id ?? id}
            />
            <Paper
              w="100%"
              radius="md"
              ta="center"
              withBorder
              p="xl"
              mt={10}
              bg="var(--mantine-color-body)"
            >
              <Text size="xl">{`${profile.first_name} ${profile.last_name}`}</Text>
              <Text size="sm">{profile.job}</Text>
              <Text size="sm">{profile.location}</Text>
              <PermissionGuard permission={permissions.canManageEmployee}>
                <Box
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  c="blue"
                  onClick={() => {
                    navigate(
                      isMyProfile
                        ? `/my-profile/${userId}/edit`
                        : `/employees/${userId}/edit`,
                      {
                        tabName: tabNames.editEmployees,
                      },
                    );
                  }}
                >
                  Edit Profile
                </Box>
              </PermissionGuard>
            </Paper>
          </Flex>
          <Flex w="100%">
            <TabsCore
              defaultValue="personal"
              value={tab}
              onChange={(tabValue) => {
                if (isMyProfile) {
                  navigate(`/${basePath}/${tabValue}`);
                } else {
                  navigate(`/${basePath}/${id}/${tabValue}`);
                }
              }}
              mt={20}
              w="100%"
            >
              <TabList withPaddings={false}>
                <Tabs.Tab value="personal">Personal</Tabs.Tab>
                {canAccess && (
                  <>
                    <Tabs.Tab value="timeoff">Time Off</Tabs.Tab>
                    <PermissionGuard permission={permissions.canManageEmployee}>
                      <Tabs.Tab value="training">Training</Tabs.Tab>
                    </PermissionGuard>
                    <PermissionGuard permission={permissions.canManageEmployee}>
                      <Tabs.Tab value="assets">Assets</Tabs.Tab>
                    </PermissionGuard>
                    <PermissionGuard permission={permissions.canManageEmployee}>
                      <Tabs.Tab value="notes">Notes</Tabs.Tab>
                    </PermissionGuard>
                    <Tabs.Tab value="files">Files</Tabs.Tab>
                    <PermissionGuard permission={permissions.canManageEmployee}>
                      <Tabs.Tab value="performance">Performance</Tabs.Tab>
                    </PermissionGuard>
                    <PermissionGuard permission={permissions.canManageEmployee}>
                      <Tabs.Tab value="job-info">Job Info</Tabs.Tab>
                    </PermissionGuard>
                    <PermissionGuard permission={permissions.canManageEmployee}>
                      <Tabs.Tab value="onboarding">Onboarding</Tabs.Tab>
                    </PermissionGuard>
                    <PermissionGuard permission={permissions.canManageEmployee}>
                      <Tabs.Tab value="offboarding">Offboarding</Tabs.Tab>
                    </PermissionGuard>
                  </>
                )}
              </TabList>
              <Tabs.Panel value="personal">
                <PersonalTab profile={profile} fields={schema} />
              </Tabs.Panel>
              {canAccess && (
                <>
                  <Tabs.Panel value="training">
                    <PermissionGuard permission={permissions.canManageEmployee}>
                      <TrainingTab employeeId={userId} />
                    </PermissionGuard>
                  </Tabs.Panel>
                  <Tabs.Panel value="timeoff">
                    <TimeOffTab employeeId={userId} />
                  </Tabs.Panel>
                  <Tabs.Panel value="assets">
                    <AssetsTab employeeId={userId} />
                  </Tabs.Panel>
                  <Tabs.Panel value="notes">
                    <PermissionGuard permission={permissions.canManageEmployee}>
                      <NotesTab employeeId={userId} />
                    </PermissionGuard>
                  </Tabs.Panel>
                  <Tabs.Panel value="files">
                    <FilesTab employeeId={userId} />
                  </Tabs.Panel>
                  <Tabs.Panel value="performance">
                    <PerformanceTab employeeId={userId} />
                  </Tabs.Panel>
                  <Tabs.Panel value="job-info">
                    <PermissionGuard permission={permissions.canManageEmployee}>
                      <JobInformationTab
                        employeeId={userId}
                        profile={profile}
                      />
                    </PermissionGuard>
                  </Tabs.Panel>
                  <Tabs.Panel value="onboarding">
                    <PermissionGuard permission={permissions.canManageEmployee}>
                      <EmployeeBoardingTab
                        employeeId={userId}
                        type={boardingListTypes.onboarding}
                      />
                    </PermissionGuard>
                  </Tabs.Panel>
                  <Tabs.Panel value="offboarding">
                    <PermissionGuard permission={permissions.canManageEmployee}>
                      <EmployeeBoardingTab
                        employeeId={userId}
                        type={boardingListTypes.offboarding}
                      />
                    </PermissionGuard>
                  </Tabs.Panel>
                </>
              )}
            </TabsCore>
          </Flex>
        </Flex>
      )}
      {error && <h3 style={{ textAlign: 'center' }}>User Not Found</h3>}
    </PageWrapper>
  );
}

EmployeeProfile.propTypes = {
  isMyProfile: PropTypes.bool,
  params: PropTypes.object,
  globalTabName: PropTypes.string,
};

EmployeeProfile.defaultProps = {
  isMyProfile: false,
  params: {},
  globalTabName: '',
};
