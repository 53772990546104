import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Flex, Tooltip } from '@mantine/core';
import {
  IconBold,
  IconH1,
  IconItalic,
  IconStrikethrough,
  IconLetterP,
  IconListNumbers,
  IconList,
  IconCode,
} from '@tabler/icons-react';
import clsx from 'clsx';
import styles from './styles.module.css';

export default function TipTapToolbar({ editor }) {
  return (
    <Flex gap={10} justify="center" py={5}>
      <Tooltip label="Bold">
        <IconBold
          className={clsx(styles.toolbarIcon, {
            [styles.activeIcon]: editor.isActive('bold'),
          })}
          onClick={() => editor.chain().focus().toggleBold().run()}
        />
      </Tooltip>

      <Tooltip label="Italic">
        <IconItalic
          className={clsx(styles.toolbarIcon, {
            [styles.activeIcon]: editor.isActive('italic'),
          })}
          onClick={() => editor.chain().focus().toggleItalic().run()}
        />
      </Tooltip>

      <Tooltip label="Strike">
        <IconStrikethrough
          className={clsx(styles.toolbarIcon, {
            [styles.activeIcon]: editor.isActive('strike'),
          })}
          onClick={() => editor.chain().focus().toggleStrike().run()}
        />
      </Tooltip>

      <Tooltip label="Heading">
        <IconH1
          className={clsx(styles.toolbarIcon, {
            [styles.activeIcon]: editor.isActive('heading', { level: 1 }),
          })}
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
        />
      </Tooltip>

      <Tooltip label="Paragraph">
        <IconLetterP
          className={clsx(styles.toolbarIcon, {
            [styles.activeIcon]: editor.isActive('paragraph'),
          })}
          onClick={() => editor.chain().focus().setParagraph().run()}
        />
      </Tooltip>

      <Tooltip label="Code">
        <IconCode
          className={clsx(styles.toolbarIcon, {
            [styles.activeIcon]: editor.isActive('code'),
          })}
          onClick={() => editor.chain().focus().toggleCode().run()}
        />
      </Tooltip>
      <Divider orientation="vertical" />

      <Tooltip label="Ordered List">
        <IconListNumbers
          className={clsx(styles.toolbarIcon, {
            [styles.activeIcon]: editor.isActive('orderedList'),
          })}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
        />
      </Tooltip>

      <Tooltip label="Unordered List">
        <IconList
          className={clsx(styles.toolbarIcon, {
            [styles.activeIcon]: editor.isActive('bulletList'),
          })}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
        />
      </Tooltip>
    </Flex>
  );
}

TipTapToolbar.propTypes = {
  editor: PropTypes.object,
};

TipTapToolbar.defaultProps = {
  editor: {},
};
