import { Fragment } from 'react';
import {
  Flex,
  Text,
  Table,
  Select,
  Pill,
  Space,
  Paper,
  LoadingOverlay,
  Box,
  rem,
} from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { YearPickerInput } from '@mantine/dates';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ContextMenu } from 'components/ContextMenu';
import { usePermissions } from 'components/PermissionGuard/hooks';

export default function TimeOffRecordsTable({
  records,
  policies,
  onDeleteRecord,
  onEditRecord,
  onYearChanged,
  onPolicyChanged,
  selectedPolicy,
  selectedYear,
  isLoading,
}) {
  return (
    <>
      <Flex align="center" mt={20}>
        <h1>Records</h1>
        <Flex
          gap={4}
          justify="flex-end"
          mb={20}
          align="center"
          style={{ flexGrow: 1 }}
        >
          <Select
            label="Policy"
            placeholder="Pick value"
            data={policies.map((policy) => ({
              value: `${policy.id}`,
              label: policy.name,
            }))}
            value={selectedPolicy}
            onChange={onPolicyChanged}
            clearable
          />
          <YearPickerInput
            label="Pick date"
            placeholder="Pick date"
            value={selectedYear}
            onChange={onYearChanged}
            clearable
          />
        </Flex>
      </Flex>
      {isLoading && (
        <Box pos="relative" mih={rem(200)}>
          <LoadingOverlay
            visible
            zIndex={1000}
            overlayProps={{ radius: 'sm', blur: 2 }}
          />
        </Box>
      )}
      {!isLoading && records?.length === 0 && (
        <Flex align="center" justify="center">
          <Text fw={500} size="xl" c="dimmed">
            There are no records
          </Text>
        </Flex>
      )}
      {records
        ?.sort((a, b) => (a.policy_name === b.policy_name ? 1 : -1))
        .map((item) => (
          <Fragment
            key={`${item?.interval.start_date}-${item?.interval.end_date}`}
          >
            <Paper radius="lg" withBorder p="sm">
              <Flex align="center" gap="md">
                <Flex gap={8}>
                  <Text size="sm">Policy: </Text>
                  <Pill size="sm">
                    <Text size="sm" fw={500}>
                      {item?.interval.policy_name}
                    </Text>
                  </Pill>
                </Flex>

                <Flex style={{ flexGrow: 1 }} justify="flex-end" gap={8}>
                  <Flex gap={8}>
                    <Text size="sm">Start Date:</Text>
                    <Pill size="sm">
                      <Text size="sm" fw={500}>
                        {item?.interval.start_date}
                      </Text>
                    </Pill>
                  </Flex>

                  <Flex gap={8}>
                    <Text size="sm">End Date:</Text>
                    <Pill size="sm">
                      <Text size="sm" fw={500}>
                        {item?.interval.end_date}
                      </Text>
                    </Pill>
                  </Flex>
                  <Text size="sm">Total Hours:</Text>
                  <Pill size="sm">
                    <Text size="sm" fw={500}>
                      {item?.interval.total_hours}
                    </Text>
                  </Pill>
                </Flex>
              </Flex>
            </Paper>
            <Table highlightOnHover>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Date</Table.Th>
                  <Table.Th>Hours</Table.Th>
                  <Table.Th>Created at</Table.Th>
                  <Table.Th>Updated at</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {item?.interval.records.map((record) => (
                  <TimeOffRecordRow
                    key={record.id}
                    record={record}
                    onDelete={onDeleteRecord}
                    onEdit={onEditRecord}
                  />
                ))}
              </Table.Tbody>
            </Table>
            <Space h="xl" />
          </Fragment>
        ))}
    </>
  );
}

function TimeOffRecordRow({ record, onEdit, onDelete }) {
  const { hasPermission, permissions } = usePermissions();
  const menuItems = [
    {
      label: 'Edit Record',
      icon: IconEdit,
      condition: hasPermission(permissions.canManageEmployee),
      onClick: () => {
        onEdit(record);
      },
    },
    {
      label: hasPermission(permissions.canManageEmployee)
        ? 'Delete Record'
        : 'Cancel',
      condition:
        hasPermission(permissions.canManageEmployee) ||
        moment(record?.date, 'YYYY-MM-DD').diff(moment()) >= 0,
      icon: IconTrash,
      onClick: () => {
        onDelete(record);
      },
    },
  ];

  return (
    <Table.Tr>
      <Table.Td>
        <Flex gap={4} align="center">
          <ContextMenu menuItems={menuItems} width={250} />
          {record.date}
        </Flex>
      </Table.Td>
      <Table.Td>{record.hours}</Table.Td>
      <Table.Td>
        {moment(record.created_at).format('DD/MM/YYYY, h:mm a')}
      </Table.Td>
      <Table.Td>
        {moment(record.updated_at).format('DD/MM/YYYY, h:mm a')}
      </Table.Td>
    </Table.Tr>
  );
}

TimeOffRecordRow.propTypes = {
  record: PropTypes.object.isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};
TimeOffRecordRow.defaultProps = {
  onEdit: () => {},
  onDelete: () => {},
};

TimeOffRecordsTable.propTypes = {
  isLoading: PropTypes.bool,
  records: PropTypes.array,
  policies: PropTypes.array,
  selectedPolicy: PropTypes.string,
  selectedYear: PropTypes.object,
  onDeleteRecord: PropTypes.func,
  onEditRecord: PropTypes.func,
  onYearChanged: PropTypes.func,
  onPolicyChanged: PropTypes.func,
};
TimeOffRecordsTable.defaultProps = {
  isLoading: false,
  records: [],
  policies: [],
  selectedPolicy: '',
  selectedYear: '',
  onDeleteRecord: () => {},
  onEditRecord: () => {},
  onYearChanged: () => {},
  onPolicyChanged: () => {},
};
