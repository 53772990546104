import { usePolicyTypeSelect } from 'components/TimeOff/hooks';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mantine/core';
import Period from '../Period';

export default function Yearly({ formValues, onChange, scheduleType }) {
  const yearly = usePolicyTypeSelect({
    onChange,
    formValues,
    scheduleType,
  });

  const months = moment.months();
  return (
    <Stack>
      <Period
        values={yearly ?? {}}
        months={months}
        valuePath="yearly"
        onChange={onChange}
        isLast
      />
    </Stack>
  );
}

Yearly.propTypes = {
  formValues: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  scheduleType: PropTypes.string.isRequired,
};
