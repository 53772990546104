import { Modal, Text, Flex, Button, Space, Pill } from '@mantine/core';
import PropTypes from 'prop-types';
import { IconArrowNarrowRight } from '@tabler/icons-react';
import { parseJSON } from 'utils';
import moment from 'moment';

export default function JobInfoRequestModal({
  isLoading,
  opened,
  onClose,
  onApprove,
  onDeny,
  request,
}) {
  const data = parseJSON(request?.data);
  const createdAt = request?.created_at
    ? moment(request?.created_at).format('DD/MM/YYYY, h:mm a')
    : '';

  const hireDate = data?.hireDate
    ? moment(data.hireDate).format('DD/MM/YYYY')
    : '';

  return (
    <Modal
      opened={opened}
      onClose={() => {
        onClose();
      }}
      title={request?.title}
      centered
      size="auto"
    >
      <Text size="xl">{data?.name}</Text>
      <Text size="md">{request?.content}</Text>
      <Space h="sm" />
      <Flex gap="sm">
        <Text c="dimmed" size="sm">
          {createdAt}
        </Text>
      </Flex>
      <Flex gap="sm">
        <Text size="sm">Hire Date:</Text>
        <Pill>{hireDate}</Pill>
      </Flex>
      <Space h="md" />
      <Flex gap="md">
        <Flex gap={8}>
          <Text size="sm">Current Title: </Text>
          <Pill size="sm">
            <Text size="sm" fw={500}>
              {data?.currentJobTitle}
            </Text>
          </Pill>
        </Flex>
        <IconArrowNarrowRight size={22} />
        <Flex gap={8}>
          <Text size="sm">New Title: </Text>
          <Pill size="sm">
            <Text size="sm" fw={500}>
              {data?.jobTitle}
            </Text>
          </Pill>
        </Flex>
      </Flex>
      <Space h="md" />
      <Flex gap="md" justify="center" align="center">
        <Button onClick={() => onApprove(request)} loading={isLoading}>
          Approve
        </Button>
        <Button color="red" onClick={() => onDeny(request)}>
          Deny
        </Button>
      </Flex>
    </Modal>
  );
}

JobInfoRequestModal.propTypes = {
  isLoading: PropTypes.bool,
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  onApprove: PropTypes.func,
  onDeny: PropTypes.func,
  request: PropTypes.object,
};

JobInfoRequestModal.defaultProps = {
  isLoading: false,
  opened: false,
  onClose: () => {},
  onApprove: () => {},
  onDeny: () => {},
  request: null,
};
