import { Button, Flex, Space, Title } from '@mantine/core';
import { IconListCheck, IconPlus } from '@tabler/icons-react';
import {
  useCreateTask,
  useCreateTaskList,
  useDeleteTask,
  useDeleteTaskList,
  useLoadTaskList,
  useLoadTasks,
  useUpdateTask,
  useUpdateTaskList,
} from 'api/hooks';
import { BoardingListModal } from 'components/BoardingListModal';
import { BoardingListView } from 'components/BoardingListView';
import { BoardingTaskModal } from 'components/BoardingTaskModal';
import { Loader } from 'components/Loader';
import { useNotifications } from 'notifications/hooks/useNotifications';
import React from 'react';
import { useStateDisclosure } from 'utils/hooks';
import PropTypes from 'prop-types';
import { formatSelectorData } from 'utils';

export default function BoardingTab({ listType, title }) {
  const {
    tasksList,
    isLoading,
    refetch: refetchTasksList,
  } = useLoadTasks({ 'filter[task_type]': listType });

  const { list, refetch: refetchTaskList } = useLoadTaskList({
    'filter[task_type]': listType,
  });

  const { setNotifications } = useNotifications();

  const [
    openedListModal,
    { close: closeListModal, open: openListModal, state: listState },
  ] = useStateDisclosure(false);

  const [
    openedTaskModal,
    { close: closeTaskModal, open: openTaskModal, state: taskState },
  ] = useStateDisclosure(false);

  const { createTaskList } = useCreateTaskList({
    onSuccess: () => {
      setNotifications([`${title} List Created Successfully`]);
      closeListModal();
      refetchTasksList();
      refetchTaskList();
    },
  });

  const { updateTaskList } = useUpdateTaskList({
    onSuccess: () => {
      setNotifications([`${title} List Updated Successfully`]);
      closeListModal();
      refetchTasksList();
      refetchTaskList();
    },
  });

  const { deleteTaskList } = useDeleteTaskList({
    onSuccess: () => {
      setNotifications([`${title} List Deleted Successfully`]);
      refetchTasksList();
      refetchTaskList();
    },
  });

  const { createTask, isLoading: isCreatingTask } = useCreateTask({
    onSuccess: () => {
      setNotifications([`${title} Task Created Successfully`]);
      closeTaskModal();
      refetchTasksList();
    },
  });

  const { updateTask, isLoading: isUpdatingTask } = useUpdateTask({
    onSuccess: () => {
      setNotifications([`${title} Task Created Successfully`]);
      closeTaskModal();
      refetchTasksList();
    },
  });

  const { deleteTask } = useDeleteTask({
    onSuccess: () => {
      setNotifications([`${title} Task Deleted Successfully`]);
      refetchTasksList();
    },
  });

  const onSubmitList = ({ modalType, id, name }) => {
    if (modalType === 'Edit') {
      updateTaskList({
        id,
        taskType: listType,
        name,
      });
    } else {
      createTaskList({
        name,
        taskType: listType,
      });
    }
  };

  const onSubmitTaskList = ({ modalType, id, ...data }) => {
    if (modalType === 'Edit') {
      updateTask({ taskId: id, payload: data });
    } else {
      createTask(data);
    }
  };

  return (
    <>
      <BoardingListModal
        onClose={closeListModal}
        opened={openedListModal}
        title={`${title} list`}
        listState={listState}
        onSubmit={onSubmitList}
      />
      <BoardingTaskModal
        opened={openedTaskModal}
        onClose={closeTaskModal}
        taskState={taskState}
        title={`${title} task`}
        onSubmit={onSubmitTaskList}
        taskList={formatSelectorData(list, { value: 'id', label: 'name' })}
        isLoading={isCreatingTask || isUpdatingTask}
      />
      <Flex gap={10} justify="space-between" align="center">
        <Title fz="xl">{title}</Title>
        <Flex gap={10}>
          <Button onClick={openTaskModal} rightSection={<IconPlus size={14} />}>
            New Task
          </Button>
          <Button
            onClick={openListModal}
            rightSection={<IconListCheck size={14} />}
          >
            New List
          </Button>
        </Flex>
      </Flex>
      <Space h="md" />
      <Loader isLoading={isLoading} />
      <BoardingListView
        groupedList={tasksList}
        onEditTask={(data) => openTaskModal({ ...data, modalType: 'Edit' })}
        onEditList={(data) => openListModal({ ...data, modalType: 'Edit' })}
        onDeleteList={({ id }) => deleteTaskList(id)}
        onDeleteTask={({ id }) => deleteTask(id)}
      />
    </>
  );
}

BoardingTab.propTypes = {
  listType: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};
