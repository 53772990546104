import { Table } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { ContextMenu } from 'components/ContextMenu';
import PropTypes from 'prop-types';

export default function EmployeeStatusesRow({
  status,
  onEditEmployeeStatus,
  onDeleteEmployeeStatus,
}) {
  const menuItems = [
    {
      label: 'Edit',
      icon: IconEdit,
      onClick: () => {
        onEditEmployeeStatus(status);
      },
    },
    {
      label: 'Delete',
      icon: IconTrash,
      onClick: () => {
        onDeleteEmployeeStatus(status);
      },
      condition: status?.type === 2 && !status?.has_employees,
    },
  ];

  return (
    <Table.Tr>
      <Table.Td>{status?.name}</Table.Td>
      <Table.Td>{status?.employees_count}</Table.Td>
      <Table.Td ta="right">
        <ContextMenu menuItems={menuItems} />
      </Table.Td>
    </Table.Tr>
  );
}

EmployeeStatusesRow.propTypes = {
  status: PropTypes.object.isRequired,
  onEditEmployeeStatus: PropTypes.func.isRequired,
  onDeleteEmployeeStatus: PropTypes.func.isRequired,
};
