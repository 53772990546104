import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Select } from '@mantine/core';
import moment from 'moment';
import { useComingWeeks, usePolicyTypeSelect } from 'components/TimeOff/hooks';

export default function EveryOtherWeek({ onChange, formValues, scheduleType }) {
  const everyOtherWeek = usePolicyTypeSelect({
    onChange,
    formValues,
    scheduleType,
  });

  const weekdays = moment.weekdays();

  const weeks = useComingWeeks({ week: everyOtherWeek?.weeks });

  return (
    <Flex gap={5} align="center">
      on
      <Select
        value={everyOtherWeek?.weeks}
        onChange={(val) => {
          onChange('settings.accrue.period.every_other_week.weeks', val);
          onChange(
            'settings.accrue.period.every_other_week.startFromCurrentWeek',
            null,
          );
        }}
        data={weekdays}
        allowDeselect={false}
        placeholder="Select Weekday"
      />
      <Box flex={1}>
        <Select
          disabled={!everyOtherWeek?.weeks}
          value={everyOtherWeek?.startFromCurrentWeek}
          onChange={(val) =>
            onChange(
              'settings.accrue.period.every_other_week.startFromCurrentWeek',
              val,
            )
          }
          placeholder="Select start date"
          data={
            everyOtherWeek?.weeks
              ? [
                  {
                    label: `...${weeks?.currentWeek?.join(',')}...`,
                    value: '0',
                  },
                  { label: `...${weeks?.nextWeek?.join(',')}...`, value: '1' },
                ]
              : []
          }
          allowDeselect={false}
        />
      </Box>
    </Flex>
  );
}

EveryOtherWeek.propTypes = {
  formValues: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  scheduleType: PropTypes.string.isRequired,
};
