import { Modal } from '@mantine/core';
import PropTypes from 'prop-types';
import PerformanceCycleForm from '../forms/PerformanceCycleForm';

export default function PerformanceCycleModal({
  isLoading,
  opened,
  onClose,
  onSubmit,
  cycle,
}) {
  return (
    <Modal
      opened={opened}
      onClose={() => {
        onClose();
      }}
      title={cycle ? 'Edit Performance Cycle' : 'Performance Cycle'}
      centered
      size="lg"
    >
      <PerformanceCycleForm
        cycle={cycle}
        isLoading={isLoading}
        onSubmit={onSubmit}
      />
    </Modal>
  );
}

PerformanceCycleModal.propTypes = {
  isLoading: PropTypes.bool,
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  cycle: PropTypes.object,
};

PerformanceCycleModal.defaultProps = {
  isLoading: false,
  opened: false,
  onClose: () => {},
  onSubmit: () => {},
  cycle: null,
};
