import { Avatar, Flex, Text } from '@mantine/core';
import React from 'react';
import PropTypes from 'prop-types';
import {
  IconClipboardText,
  IconUserSearch,
  IconUsersGroup,
} from '@tabler/icons-react';
import styles from './style.module.css';

export default function GlobalSearchItem({ item }) {
  const SettingsIcon = {
    Survey: IconClipboardText,
    Onboarding: IconUsersGroup,
  };
  const IconByType = {
    Settings: SettingsIcon[item?.name] ?? null,
    Hiring: IconUserSearch,
    Employee: Avatar,
  };

  const IconProvider = IconByType[item?.type] ?? null;

  return (
    <Flex
      gap={5}
      px={10}
      py={8}
      className={styles.item__wrapper}
      align="center"
    >
      {IconProvider ? <IconProvider size={21} /> : null}
      {item?.name}
      <Text c="gray">({item?.type})</Text>
    </Flex>
  );
}

GlobalSearchItem.propTypes = {
  item: PropTypes.object.isRequired,
};
