import { Flex, Space, Table, rem } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconTrash } from '@tabler/icons-react';
import {
  useCandidateSources,
  useCreateCandidateSource,
  useDeleteCandidateSource,
} from 'api/hooks';
import { AddButton } from 'components/Buttons';
import { ConfirmDeleteModal } from 'components/ConfirmDeleteModal';
import { ContextMenu } from 'components/ContextMenu';
import { CreateSourceModal } from 'components/CreateSourceModal';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import { Loader } from 'components/Loader';
import _ from 'lodash';
import { useNotifications } from 'notifications/hooks/useNotifications';
import React from 'react';
import { useStateDisclosure } from 'utils/hooks';

export default function CandidateSources() {
  const { setNotifications } = useNotifications();
  const {
    candidateSource,
    isLoading,
    refetch: refetchCandidateSource,
  } = useCandidateSources();

  const [
    openedCreateSource,
    { open: openCreateSource, close: closeCreateSource },
  ] = useDisclosure(false);

  const [
    openedDeleteSource,
    {
      open: openDeleteSource,
      close: closeDeleteSource,
      state: deleteSourceState,
    },
  ] = useStateDisclosure(false);

  const { createCandidateSource, isLoading: isCreating } =
    useCreateCandidateSource({
      onSuccess: () => {
        refetchCandidateSource();
        setNotifications(['Candidate Source Create Successfully']);
        closeCreateSource();
      },
    });

  const { deleteCandidateSource, isLoading: isDeleting } =
    useDeleteCandidateSource({
      onSuccess: () => {
        refetchCandidateSource();
        setNotifications(['Candidate Source Deleted Successfully']);
        closeDeleteSource();
      },
    });

  return (
    <>
      <CreateSourceModal
        opened={openedCreateSource}
        onClose={closeCreateSource}
        onSubmit={createCandidateSource}
        isLoading={isCreating}
      />
      <ConfirmDeleteModal
        opened={openedDeleteSource}
        onClose={closeDeleteSource}
        onDelete={() => deleteCandidateSource(deleteSourceState?.id)}
        deleteItemName={deleteSourceState?.name}
        isLoading={isDeleting}
      />
      <Flex justify="flex-end">
        <AddButton onClick={openCreateSource}>Add Source</AddButton>
      </Flex>
      <Space h="xs" />

      {!_.isEmpty(candidateSource) && (
        <Table>
          <Table.Thead>
            <Table.Tr
              styles={{
                tr: { backgroundColor: 'var(--mantine-color-gray-4)' },
              }}
            >
              <Table.Th>Name</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {_.map(candidateSource, (item) => {
              const menuItems = [
                {
                  label: 'Delete',
                  icon: IconTrash,
                  onClick: () => openDeleteSource(item),
                },
              ];
              return (
                <Table.Tr key={item?.id}>
                  <Table.Td>
                    <Flex align="center" justify="space-between" mih={rem(30)}>
                      {item?.name}
                      {!!item?.is_deletable && (
                        <ContextMenu menuItems={menuItems} />
                      )}
                    </Flex>
                  </Table.Td>
                </Table.Tr>
              );
            })}
          </Table.Tbody>
        </Table>
      )}

      <Space h="md" />

      <Loader isLoading={isLoading} />
      <EmptyDataMessage isVisible={!isLoading && _.isEmpty(candidateSource)} />
    </>
  );
}
