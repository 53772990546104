import { Box, Flex, Popover, Text } from '@mantine/core';
import {
  IconCalendarEvent,
  IconCalendarTime,
  IconConfetti,
} from '@tabler/icons-react';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { calendarEventTypes } from 'utils/constants';
import { useTabNavigate } from 'utils/hooks';
import '../style.css';
import {
  AnniversaryDropdown,
  BirthdayDropdown,
  TimeOffDropdown,
} from './dropdowns';

const isContains = (className, ...classes) =>
  _.some(classes, (val) => className?.includes?.(val));

export const eventColors = {
  [calendarEventTypes.birthdays]: {
    bg: 'rgba(255, 99, 71, 0.15)',
    boxBg: 'var(--mantine-color-red-filled)',
    icon: <IconConfetti width={15} height={15} />,
    textColor: 'white',
  },
  [calendarEventTypes.anniversaries]: {
    bg: 'rgba(205, 209, 228, 0.45)',
    boxBg: 'var(--mantine-color-blue-filled)',
    icon: <IconCalendarEvent width={15} height={15} />,
    textColor: 'white',
  },
  [calendarEventTypes.approvedTimeOff]: {
    bg: 'rgba(242,185,73, 0.25)',
    boxBg: 'rgba(242,185,73, 0.95)',
    icon: <IconCalendarTime width={15} height={15} />,
  },
  default: {
    bg: 'rgba(255, 99, 71, 0.25)',
    boxBg: 'grey',
  },
};

export default function EventWrapper({ event, ...rest }) {
  const className = rest?.children?.props?.className;

  const [opened, setOpened] = useState(false);

  const navigation = useTabNavigate();

  const isEndEvent = isContains(className, 'rbc-event-continues-prior');
  const isStartEvent = !isEndEvent;

  const Provider = {
    [calendarEventTypes.birthdays]: {
      component: BirthdayDropdown,
      width: 270,
    },
    [calendarEventTypes.approvedTimeOff]: {
      component: TimeOffDropdown,
      width: 400,
    },
    [calendarEventTypes.anniversaries]: {
      component: AnniversaryDropdown,
      width: 330,
    },
    default: null,
  };

  const Dropdown = Provider[event?.type]?.component ?? Provider.default;

  const onClickUser = () => {
    setOpened(false);
    navigation(`/employees/${event?.employees?.id}`);
  };

  const getPositionByDate = () => {
    const datePositions = {
      Fri: 'top-end',
      Sun: 'top-start',
      default: 'top-end',
    };

    return event?.start_date
      ? datePositions[moment(event?.start_date).format('ddd')] ??
          datePositions.default
      : datePositions.default;
  };

  return (
    <Popover
      width={Provider[event?.type]?.width ?? 0}
      position={getPositionByDate()}
      withArrow
      shadow="md"
      opened={opened}
      onChange={setOpened}
      id="event-popover"
    >
      <Popover.Target>
        <Flex
          onClick={() => setOpened(true)}
          id="10"
          style={{
            backgroundColor: opened
              ? eventColors[event?.type]?.boxBg ?? eventColors.default.boxBg
              : eventColors[event?.type]?.bg ?? eventColors.default.bg,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            borderRadius: '5px',
            flexWrap: 'nowrap',
            cursor: 'pointer',
          }}
          align="center"
          gap={5}
          mx={5}
          pos="relative"
          h={30}
          pl={isEndEvent ? 0 : 10}
          c={
            opened
              ? eventColors[event?.type]?.textColor ?? undefined
              : undefined
          }
        >
          {isStartEvent && (
            <Box
              w={5}
              bg={eventColors[event?.type]?.boxBg ?? eventColors.default.boxBg}
              h={30}
              style={{ position: 'absolute', top: 0, left: 0, bottom: 0 }}
              className={!isStartEvent ? 'arrow-left' : ''}
            />
          )}

          {isEndEvent && <Box w={5} h={30} className="arrow-left" />}

          {eventColors[event?.type]?.icon && (
            <Flex align="center" w={15} h={15}>
              {eventColors[event?.type]?.icon}
            </Flex>
          )}
          <Text
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {event?.title}
          </Text>
        </Flex>
      </Popover.Target>
      {Dropdown && opened && (
        <Dropdown
          event={event}
          onClose={() => setOpened(false)}
          onClickUser={onClickUser}
        />
      )}
    </Popover>
  );
}

EventWrapper.propTypes = {
  event: PropTypes.object.isRequired,
};
