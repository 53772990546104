import { useUsers } from 'api/hooks';
import { Flex, LoadingOverlay, Pagination, Space, Table } from '@mantine/core';
import { usePageTitle, usePagination } from 'utils/hooks';
import { PageWrapper } from 'components/PageWrapper';
import moment from 'moment';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { UserFilter } from 'components/UsersFilter';

export default function Administration({ globalTabName }) {
  const { page, setPage, validateCurrentPage } = usePagination({ page: 1 });

  const [search, setSearch] = useState('');
  const { users, pagination, isLoading } = useUsers(
    {
      page,
      search,
    },
    { keepPreviousData: true },
  );

  useEffect(() => {
    validateCurrentPage(pagination?.total_pages);
  }, [pagination, validateCurrentPage]);

  usePageTitle('Administration', globalTabName);

  return (
    <PageWrapper title="Administration">
      <LoadingOverlay
        visible={isLoading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <Space h="md" />
      <UserFilter
        search={search}
        setSearch={setSearch}
        withStatusFilter={false}
      />
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>First Name</Table.Th>
            <Table.Th>Last Name</Table.Th>
            <Table.Th>Role</Table.Th>
            <Table.Th>Date registered</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {users.map((item) => (
            <Table.Tr key={item.id}>
              <Table.Td height={51}>{item.first_name}</Table.Td>
              <Table.Td>{item.last_name}</Table.Td>
              <Table.Td>{_.first(item.roles) ?? ''}</Table.Td>
              <Table.Td>
                {moment(item.date_registered).format('YYYY-MM-DD')}
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
      {pagination?.total_pages > 1 && (
        <Flex justify="flex-end" mt={10}>
          <Pagination
            total={pagination?.total_pages}
            value={page}
            onChange={setPage}
          />
        </Flex>
      )}
    </PageWrapper>
  );
}

Administration.propTypes = {
  globalTabName: PropTypes.string,
};

Administration.defaultProps = {
  globalTabName: '',
};
