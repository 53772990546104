import { Flex, Switch, Text, Tooltip } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import PropTypes from 'prop-types';

export default function ControlLabel({ title, isEnabled, onChange, tooltip }) {
  return (
    <Flex align="center">
      <Flex style={{ flexGrow: 1 }} gap={5}>
        <Text size="lg" fw={500}>
          {title}
        </Text>
        {tooltip && (
          <Tooltip multiline label={tooltip} w={200}>
            <IconInfoCircle size={18} />
          </Tooltip>
        )}
      </Flex>
      {onChange && (
        <Switch
          checked={isEnabled}
          onChange={(event) => onChange(event.currentTarget.checked)}
        />
      )}
    </Flex>
  );
}
ControlLabel.propTypes = {
  onChange: PropTypes.func,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  isEnabled: PropTypes.bool,
};

ControlLabel.defaultProps = {
  onChange: () => {},
  isEnabled: false,
  tooltip: '',
};
