import { Button, Flex, Stack, Table } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { clearCache } from 'api/clearCache';
import {
  useCreateEmployeeBonusRecords,
  useDeleteEmployeeBonusRecord,
  useLoadEmployeeBonusRecords,
  useLoadReasons,
  useUpdateEmployeeBonusRecord,
} from 'api/hooks';
import { AddEmployeeBonusModal } from 'components/AddEmployeeBonusModal';
import { ConfirmDeleteModal } from 'components/ConfirmDeleteModal';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import { Skeleton } from 'components/Skeleton';
import _ from 'lodash';
import { useNotifications } from 'notifications/hooks/useNotifications';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { useStateDisclosure } from 'utils/hooks';
import { BonusRecordRow } from '../tabs-rows';

const headers = ['Date', 'Amount', 'Reason', 'Comment'];

export default function BonusRecords({
  profile,
  compensationReasonType,
  isLookupsLoading,
}) {
  const queryClient = useQueryClient();
  const [
    openedAddBonusModal,
    {
      open: openAddBonusModal,
      close: closeAddBonusModal,
      state: addBonusState,
    },
  ] = useStateDisclosure(false);

  const [
    openedConfirmationDeleteModal,
    {
      open: openConfirmationDeleteModal,
      close: closeConfirmationDeleteModal,
      state: confirmationDeleteState,
    },
  ] = useStateDisclosure(false);

  const { reasons, isLoading: isReasonsLoading } = useLoadReasons(
    {
      params: { filter: { byType: compensationReasonType } },
    },
    { enabled: !!compensationReasonType },
  );

  const { setNotifications } = useNotifications();

  const { employeeBonusRecords, isLoading: isBonusRecordsLoading } =
    useLoadEmployeeBonusRecords({ employeeId: profile?.id });

  const { createEmployeeBonusRecord, isLoading: isCreating } =
    useCreateEmployeeBonusRecords({
      onSuccess: () => {
        clearCache.onChangeBonusRecord(
          queryClient,
          profile?.id,
          compensationReasonType,
        );
        setNotifications(['Employee bonus Created Successfully']);
        closeAddBonusModal();
      },
    });

  const { updateEmployeeBonusRecord, isLoading: isUpdating } =
    useUpdateEmployeeBonusRecord({
      onSuccess: () => {
        clearCache.onChangeBonusRecord(
          queryClient,
          profile?.id,
          compensationReasonType,
        );
        setNotifications(['Employee bonus Updated Successfully']);
        closeAddBonusModal();
      },
    });

  const { deleteEmployeeBonusRecord, isLoading: isDeleting } =
    useDeleteEmployeeBonusRecord({
      onSuccess: () => {
        clearCache.onChangeBonusRecord(
          queryClient,
          profile?.id,
          compensationReasonType,
        );
        setNotifications(['Employee bonus Deleted Successfully']);
        closeConfirmationDeleteModal();
      },
    });

  return (
    <Stack gap={0} mt={10}>
      <AddEmployeeBonusModal
        opened={openedAddBonusModal}
        onClose={closeAddBonusModal}
        employeeBonusState={addBonusState}
        onCreate={(data) =>
          createEmployeeBonusRecord({ ...data, employee_id: profile?.id })
        }
        onEdit={(data) =>
          updateEmployeeBonusRecord({
            ...data,
            bonusRecordId: addBonusState?.id,
            employee_id: profile?.id,
          })
        }
        userProfile={profile}
        employeeBonusReasons={reasons}
        isLoading={isReasonsLoading || isCreating || isUpdating}
      />
      <ConfirmDeleteModal
        opened={openedConfirmationDeleteModal}
        onClose={closeConfirmationDeleteModal}
        onDelete={() => deleteEmployeeBonusRecord(confirmationDeleteState?.id)}
        customText="Are you sure you want to delete this bonus record?"
        isLoading={isDeleting}
      />

      <Flex justify="space-between" align="center">
        <h2 style={{ margin: '0px' }}>Bonus</h2>
        <Button
          rightSection={<IconPlus size={14} />}
          onClick={() => openAddBonusModal()}
        >
          Add entry
        </Button>
      </Flex>
      <EmptyDataMessage
        isVisible={
          _.isEmpty(employeeBonusRecords) &&
          !isLookupsLoading &&
          !isBonusRecordsLoading
        }
        my={20}
      />

      <Skeleton
        count={4}
        isVisible={isLookupsLoading || isBonusRecordsLoading}
        skeletonWrapperProps={{ my: 10 }}
      />

      {!_.isEmpty(employeeBonusRecords) && (
        <Table>
          <Table.Thead>
            <Table.Tr>
              {_.map(headers, (item) => (
                <Table.Th key={item}>{item}</Table.Th>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {_.map(employeeBonusRecords, (bonusItem) => (
              <BonusRecordRow
                key={bonusItem?.id}
                bonusRecord={bonusItem}
                onEdit={(data) =>
                  openAddBonusModal({ modalType: 'Edit', ...data })
                }
                onDelete={openConfirmationDeleteModal}
              />
            ))}
          </Table.Tbody>
        </Table>
      )}
    </Stack>
  );
}

BonusRecords.propTypes = {
  profile: PropTypes.object,
  compensationReasonType: PropTypes.string,
  isLookupsLoading: PropTypes.bool.isRequired,
};

BonusRecords.defaultProps = {
  profile: {},
  compensationReasonType: '',
};
