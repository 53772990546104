import { Flex, Table } from '@mantine/core';
import React from 'react';
import PropTypes from 'prop-types';
import { ContextMenu } from 'components/ContextMenu';
import { IconEdit, IconTrash } from '@tabler/icons-react';

export default function JobInformationTabRow({
  jobInformation,
  onEdit,
  onDelete,
}) {
  const menuItems = [
    {
      label: 'Edit',
      icon: IconEdit,
      onClick: () => onEdit(jobInformation),
    },
    {
      label: 'Delete',
      icon: IconTrash,
      onClick: () => onDelete(jobInformation),
    },
  ];
  return (
    <Table.Tr>
      <Table.Td>{jobInformation?.jobTitle.name}</Table.Td>
      <Table.Td>{jobInformation?.location.name}</Table.Td>
      <Table.Td>{jobInformation?.division.name}</Table.Td>
      <Table.Td>{jobInformation?.department.name}</Table.Td>
      <Table.Td>
        {jobInformation?.report_to?.first_name}{' '}
        {jobInformation?.report_to?.last_name}
      </Table.Td>
      <Table.Td>{jobInformation?.date}</Table.Td>
      <Table.Td>
        <Flex justify="end">
          <ContextMenu menuItems={menuItems} />
        </Flex>
      </Table.Td>
    </Table.Tr>
  );
}

JobInformationTabRow.propTypes = {
  jobInformation: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
