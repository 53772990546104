import {
  Accordion,
  Checkbox,
  Divider,
  Flex,
  InputError,
  Paper,
  Select,
  Space,
  Stack,
  Text,
} from '@mantine/core';
import { ControlLabel } from 'components/ControlLabel';
import { DateFormInput } from 'components/DateFormInput';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import { SurveysTable } from 'components/Survey';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  frequencyValues,
  performanceCycleDateTypes,
  startAssessment,
} from 'utils/constants';

export default function FeedbackFormSection({
  surveys,
  onDateChange,
  onFrequencyChange,
  error,
  startDate,
  frequency,
  frequencyError,
  selectedSurveyIds,
  onSelectSurvey,
  surveySelectError,
  cycle,
  repeat,
  onChangeRepeat,
  surveyType,
  performanceLabel,
  withManagerAssessment,
  managerAssessmentSurveys,
  withUpwardFeedback,
  upwardFeedbackSurveys,
  feedbackAccordionValues,
  onChangeAccordionValue,
  upwardError,
  surveyTooltip,
  upwardTooltip,
}) {
  const withAccordion = !_.isEmpty(feedbackAccordionValues);

  return (
    <Flex direction="column" p={10}>
      {cycle.type === performanceCycleDateTypes.fixedDate && (
        <>
          <DateFormInput
            field={{ displayName: 'Start Date', placeholder: '2022-01-01' }}
            onChange={onDateChange}
            error={error}
            value={startDate}
            minDate={moment().add(1, 'days').toDate()}
          />
          <Space h="md" />
        </>
      )}
      {cycle.type === performanceCycleDateTypes.hireDate && (
        <Flex w="100%" align="center" gap={10}>
          <Select
            label={`Start as ${surveyType}...`}
            placeholder="Pick start date"
            data={startAssessment}
            onChange={onFrequencyChange}
            value={frequency}
            error={frequencyError}
            flex={1}
            allowDeselect={false}
          />
          {frequency && (
            <Checkbox
              mt="auto"
              size="md"
              label={`Repeat every ${frequency} ${
                frequency > 1 ? 'months' : 'month'
              }`}
              mb="6"
              checked={repeat}
              onChange={(e) => onChangeRepeat(e.currentTarget.checked)}
            />
          )}
        </Flex>
      )}
      {cycle.type === performanceCycleDateTypes.fixedDate && (
        <Select
          label="Frequency"
          placeholder="Pick Frequency"
          data={frequencyValues}
          onChange={onFrequencyChange}
          value={frequency}
          error={frequencyError}
        />
      )}
      <Accordion
        variant="contained"
        multiple
        value={
          // todo refactor remove hardcode peerFeedback
          withAccordion
            ? feedbackAccordionValues
                .filter((item) => item.enabled)
                .map((item) => item.key)
            : ['peerFeedback']
        }
        disableChevronRotation
        chevronSize={0}
        styles={{
          content: {
            padding: !withAccordion ? '0px' : 'var(--mantine-spacing-md)',
          },
        }}
      >
        <Paper shadow="xs" withBorder p="md" mt="md">
          <InputError>{surveySelectError}</InputError>
          <Stack>
            <Accordion.Item
              value="peerFeedback"
              styles={{
                item: {
                  background: !withAccordion ? 'white' : '',
                  border: !withAccordion ? '0px solid white' : '',
                },
              }}
            >
              {withAccordion && (
                <Accordion.Control>
                  <ControlLabel
                    tooltip={surveyTooltip}
                    title={performanceLabel}
                    isEnabled={
                      feedbackAccordionValues?.find(
                        (item) => item?.key === 'peerFeedback',
                      )?.enabled
                    }
                    onChange={(val) =>
                      onChangeAccordionValue('hasPeerFeedback', val)
                    }
                  />
                </Accordion.Control>
              )}
              {!withAccordion && (
                <Text fw={700} m="xs" align="center">
                  Self Assessment
                </Text>
              )}
              <Accordion.Panel>
                {surveys.length > 0 && (
                  <SurveysTable
                    surveys={surveys}
                    showContextMenu={false}
                    selectedSurveyIds={selectedSurveyIds}
                    selectable
                    onSelectSurvey={onSelectSurvey}
                  />
                )}
                <EmptyDataMessage
                  isVisible={surveys?.length === 0}
                  message="There are no surveys available."
                />
                <Divider />
              </Accordion.Panel>
            </Accordion.Item>
            {withManagerAssessment && (
              <>
                <>
                  <Text fw={700} m="xs" align="center">
                    Manager Assessment
                  </Text>
                  {managerAssessmentSurveys.length > 0 && (
                    <SurveysTable
                      surveys={managerAssessmentSurveys}
                      showContextMenu={false}
                      selectedSurveyIds={selectedSurveyIds}
                      selectable
                      onSelectSurvey={onSelectSurvey}
                    />
                  )}
                </>
                <EmptyDataMessage
                  isVisible={managerAssessmentSurveys?.length === 0}
                  message="There are no surveys available."
                />
              </>
            )}
            {withUpwardFeedback && (
              <Stack gap={0}>
                <InputError>{upwardError}</InputError>
                <Accordion.Item
                  value="upwardFeedback"
                  styles={{
                    item: {
                      borderRadius: 'var(--_accordion-radius)',
                      borderColor: 'var(--mantine-color-gray-3)',
                      border: '1px solid var(--mantine-color-gray-3)',
                    },
                  }}
                >
                  <Accordion.Control>
                    <ControlLabel
                      title="Upward Feedback"
                      isEnabled={
                        feedbackAccordionValues?.find(
                          (item) => item?.key === 'upwardFeedback',
                        )?.enabled
                      }
                      onChange={(val) =>
                        onChangeAccordionValue('hasUpwardFeedback', val)
                      }
                      tooltip={upwardTooltip}
                    />
                  </Accordion.Control>
                  <Accordion.Panel>
                    {upwardFeedbackSurveys.length > 0 && (
                      <SurveysTable
                        surveys={upwardFeedbackSurveys}
                        showContextMenu={false}
                        selectedSurveyIds={selectedSurveyIds}
                        selectable
                        onSelectSurvey={onSelectSurvey}
                      />
                    )}
                    <EmptyDataMessage
                      isVisible={upwardFeedbackSurveys?.length === 0}
                      message="There are no surveys available."
                    />
                  </Accordion.Panel>
                </Accordion.Item>
              </Stack>
            )}
          </Stack>
        </Paper>
      </Accordion>
    </Flex>
  );
}

FeedbackFormSection.propTypes = {
  onDateChange: PropTypes.func,
  onSelectSurvey: PropTypes.func,
  onFrequencyChange: PropTypes.func,
  onChangeRepeat: PropTypes.func,
  onChangeAccordionValue: PropTypes.func,
  error: PropTypes.string,
  upwardError: PropTypes.string,
  surveyType: PropTypes.string,
  frequencyError: PropTypes.string,
  surveySelectError: PropTypes.string,
  frequency: PropTypes.string,
  performanceLabel: PropTypes.string,
  startDate: PropTypes.object,
  cycle: PropTypes.object,
  repeat: PropTypes.bool,
  withManagerAssessment: PropTypes.bool,
  withUpwardFeedback: PropTypes.bool,
  managerAssessmentSurveys: PropTypes.array,
  upwardFeedbackSurveys: PropTypes.array,
  surveys: PropTypes.array,
  selectedSurveyIds: PropTypes.array,
  feedbackAccordionValues: PropTypes.array,
  surveyTooltip: PropTypes.string,
  upwardTooltip: PropTypes.string,
};

FeedbackFormSection.defaultProps = {
  onDateChange: () => {},
  onFrequencyChange: () => {},
  onSelectSurvey: () => {},
  onChangeRepeat: () => {},
  onChangeAccordionValue: () => {},
  error: null,
  frequencyError: null,
  surveySelectError: null,
  frequency: '',
  surveyTooltip: '',
  upwardTooltip: '',
  surveyType: '',
  performanceLabel: '',
  upwardError: '',
  startDate: null,
  repeat: false,
  withManagerAssessment: false,
  withUpwardFeedback: false,
  cycle: {},
  surveys: [],
  managerAssessmentSurveys: [],
  selectedSurveyIds: [],
  upwardFeedbackSurveys: [],
  feedbackAccordionValues: [],
};
