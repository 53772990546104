import PropTypes from 'prop-types';
import { Notes } from '../../notes';

export default function NotesTab({ employeeId }) {
  return (
    <>
      <h1>Notes</h1>
      <Notes contentType="Employee" contentId={employeeId} />
    </>
  );
}

NotesTab.propTypes = {
  employeeId: PropTypes.string.isRequired,
};
