import {
  Modal,
  Container,
  Table,
  Paper,
  List,
  Textarea,
  Text,
  Flex,
  Loader,
} from '@mantine/core';
import { useSurvey } from 'api/hooks';
import PropTypes from 'prop-types';

export default function SurveyPreviewModal({ survey, opened, onClose }) {
  const {
    survey: surveyData,
    isLoading,
    isFetched,
  } = useSurvey(
    { id: survey?.id, canManageEmployee: true },
    {
      enabled: Boolean(survey?.id),
    },
  );

  const hasQuestions = surveyData?.questions?.length > 0;
  return (
    <Modal
      opened={opened}
      onClose={() => {
        onClose();
      }}
      centered
      size="55%"
    >
      <Container>
        <Text fw={700} size="xl" mb="md">
          {survey?.name}
        </Text>
        {hasQuestions && (
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Question</Table.Th>
                <Table.Th>Response</Table.Th>
              </Table.Tr>
            </Table.Thead>

            <Table.Tbody>
              {surveyData?.questions?.map((item) => (
                <Table.Tr key={item.title}>
                  <Table.Td>{item.title}</Table.Td>
                  <Table.Td>
                    {item?.field_type === 'text' && (
                      <Textarea placeholder="Text Response" disabled />
                    )}
                    {item?.field_type === 'select' && (
                      <Paper shadow="xs" withBorder p="md" c="dimmed">
                        <List type="ordered" size="xs">
                          <List.Item>Strongly Disagree</List.Item>
                          <List.Item>Disagree</List.Item>
                          <List.Item>Neutral</List.Item>
                          <List.Item>Agree</List.Item>
                          <List.Item>Strongly Agree</List.Item>
                        </List>
                      </Paper>
                    )}
                    {item?.field_type === 'options' && (
                      <Text c="dimmed" size="md">
                        Options
                      </Text>
                    )}
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        )}
        {!hasQuestions && isFetched && (
          <Flex justify="center" align="center" p="md">
            <Text fw={500} size="xl" c="dimmed">
              There are no questions added
            </Text>
          </Flex>
        )}
        {isLoading && (
          <Flex align="center" p="xl" justify="center">
            <Loader color="blue" size="xl" />
          </Flex>
        )}
      </Container>
    </Modal>
  );
}

SurveyPreviewModal.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  survey: PropTypes.object,
};

SurveyPreviewModal.defaultProps = {
  opened: false,
  survey: null,
  onClose: () => {},
};
