/* eslint-disable react/no-danger */
import { Button, Divider, Flex, Modal } from '@mantine/core';
import React from 'react';
import PropTypes from 'prop-types';
import { OfferDetails } from 'components/OfferDetails';
import { IconSend } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { ConfirmDeleteModal } from 'components/ConfirmDeleteModal';
import { useCandidateActiveOffer } from 'utils/api';
import { offerStatuses } from 'utils/constants';

import styles from './styles.module.css';

export default function PreviewEmailTemplate({
  offer,
  opened,
  onClose,
  candidateDetails,
  onBack,
  onSubmit,
  onCancelOffer,
  readOnly,
  isLoading,
}) {
  const [
    openedConfirmCancelOffer,
    { open: openConfirmCancelOffer, close: closeConfirmCancelOffer },
  ] = useDisclosure(false);

  const { currentOffer } = useCandidateActiveOffer(candidateDetails?.offers);

  return (
    <Modal centered size="xl" title="Preview" opened={opened} onClose={onClose}>
      <ConfirmDeleteModal
        opened={openedConfirmCancelOffer}
        onClose={closeConfirmCancelOffer}
        confirmButtonLabel="Yes, Cancel"
        cancelButtonLabel="No"
        customText="Are you sure you want to cancel this offer?"
        onDelete={() => {
          closeConfirmCancelOffer();
          onCancelOffer();
        }}
      />
      <div
        className={styles.preview}
        dangerouslySetInnerHTML={{ __html: offer?.message }}
      />
      <Divider />
      <div
        className={styles.preview}
        dangerouslySetInnerHTML={{ __html: offer?.letter }}
      />

      <Divider mb={5} />
      <OfferDetails offer={offer} candidateDetails={candidateDetails} />

      {!readOnly && opened && (
        <Flex justify="end" gap={5} mt="5">
          <Button onClick={onBack}>Back to Revise</Button>
          <Button
            disabled={
              !opened ||
              isLoading ||
              currentOffer?.status?.id !== offerStatuses.Sent
            }
            onClick={openConfirmCancelOffer}
          >
            Cancel Offer
          </Button>
          <Button
            disabled={!opened || isLoading}
            onClick={onSubmit}
            rightSection={<IconSend size={15} />}
          >
            Submit & Send
          </Button>
        </Flex>
      )}
    </Modal>
  );
}

PreviewEmailTemplate.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancelOffer: PropTypes.func,
  offer: PropTypes.object.isRequired,
  candidateDetails: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  isLoading: PropTypes.bool,
};

PreviewEmailTemplate.defaultProps = {
  readOnly: false,
  isLoading: false,
  onBack: () => {},
  onSubmit: () => {},
  onCancelOffer: () => {},
};
