import { Modal } from '@mantine/core';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import TimeOffPolicyConfigure from '../forms/TimeOffPolicyConfigure';

export default function TimeOffPolicyModal({
  isLoading,
  opened,
  onClose,
  policyTypes,
}) {
  const navigate = useNavigate();
  const onSubmit = ({ option }) => {
    if (policyTypes[option]) {
      navigate(`settings/time-off/create/${policyTypes[option]}`);
    }
  };

  return (
    <Modal opened={opened} onClose={onClose} title="Create Policy" centered>
      <TimeOffPolicyConfigure isLoading={isLoading} onSubmit={onSubmit} />
    </Modal>
  );
}

TimeOffPolicyModal.propTypes = {
  isLoading: PropTypes.bool,
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  policyTypes: PropTypes.object,
};

TimeOffPolicyModal.defaultProps = {
  isLoading: false,
  opened: false,
  onClose: () => {},
  policyTypes: {},
};
