import { Button, Loader, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import PropTypes from 'prop-types';
import { genericValidators } from 'utils';

export default function CreateEditEmployeeStatusFrom({
  statusState,
  onSubmit,
  isLoading,
  disabled,
}) {
  const form = useForm({
    initialValues: {
      name: statusState?.name ?? '',
    },
    validate: {
      name: (val) => genericValidators.notEmpty(val),
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)} noValidate>
      <Stack>
        <TextInput
          label="Name"
          placeholder="status name"
          {...form.getInputProps('name')}
        />
        <Button
          ml="auto"
          w="max-content"
          type="submit"
          disabled={isLoading || disabled}
          rightSection={
            isLoading && <Loader color="var(--mantine-color-white)" size="xs" />
          }
        >
          {statusState?.modalType}
        </Button>
      </Stack>
    </form>
  );
}

CreateEditEmployeeStatusFrom.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  statusState: PropTypes.object,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};

CreateEditEmployeeStatusFrom.defaultProps = {
  statusState: { modalType: '' },
  isLoading: false,
  disabled: false,
};
