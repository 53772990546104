/* eslint-disable react/jsx-props-no-spreading */
import { Button, NumberInput, Space } from '@mantine/core';
import PropTypes from 'prop-types';
import { useForm } from '@mantine/form';
import { IconClockHour3 } from '@tabler/icons-react';
import { genericValidators } from 'utils';

export default function TimeOffEditRecordForm({ record, onSubmit, isLoading }) {
  const form = useForm({
    initialValues: {
      date: record?.date ?? '',
      hours: record?.hours ?? '',
      status: true,
    },
    validate: {
      hours: (value) =>
        genericValidators.lessThanOrEqualTo(
          parseInt(value, 10),
          8,
          "Can't set more than 8 hours",
        ) ||
        genericValidators.notLessThan(
          parseInt(value, 10),
          1,
          'You have to set at least 1 hour',
        ),
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)} noValidate>
      <Space h="md" />
      <NumberInput
        mb="md"
        allowNegative={false}
        label={record?.date}
        placeholder="Hours"
        rightSection={<IconClockHour3 size={20} color="black" />}
        {...form.getInputProps('hours')}
      />
      <Button type="submit" fullWidth mt="xl" loading={isLoading}>
        Submit
      </Button>
    </form>
  );
}

TimeOffEditRecordForm.propTypes = {
  record: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};

TimeOffEditRecordForm.defaultProps = {
  onSubmit: () => {},
  isLoading: false,
};
