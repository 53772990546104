import { Button, Select, TextInput, Space } from '@mantine/core';
import PropTypes from 'prop-types';
import { useForm } from '@mantine/form';
import { genericValidators } from 'utils';

export default function TimeOffEmployeeForm({
  policy,
  policies,
  onSubmit,
  isLoading,
}) {
  const form = useForm({
    initialValues: {
      policyId: '',
    },
    validate: {
      policyId: (value) =>
        genericValidators.notEmpty(value, 'You should pick a policy'),
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)} noValidate>
      {policy && (
        <>
          <TextInput
            label="Current Policy"
            value={policy.name}
            onChange={() => {}}
          />
          <Space h="md" />
        </>
      )}

      <Select
        label="Select Policy"
        placeholder="Select Policy"
        data={policies?.map((item) => ({
          value: `${item.id}`,
          label: item.name,
        }))}
        defaultValue={form.values.policyId}
        onChange={(value) => form.setFieldValue('policyId', value)}
        error={form.errors.policyId}
        clearable
      />
      <Button type="submit" fullWidth mt="xl" loading={isLoading}>
        Submit
      </Button>
    </form>
  );
}

TimeOffEmployeeForm.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  policies: PropTypes.array,
  policy: PropTypes.object,
};

TimeOffEmployeeForm.defaultProps = {
  onSubmit: () => {},
  isLoading: false,
  policies: [],
  policy: null,
};
