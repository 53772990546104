import { Skeleton as SkeletonCore, Stack } from '@mantine/core';
import PropTypes from 'prop-types';

export default function Skeleton({
  count,
  skeletonProps,
  skeletonWrapperProps,
  isVisible,
}) {
  if (!isVisible) return null;

  return (
    <Stack {...skeletonWrapperProps}>
      {Array.from({ length: count }).map((val, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <SkeletonCore key={index} {...skeletonProps} />
      ))}
    </Stack>
  );
}

Skeleton.propTypes = {
  count: PropTypes.number,
  skeletonProps: PropTypes.object,
  skeletonWrapperProps: PropTypes.object,
  isVisible: PropTypes.bool,
};

Skeleton.defaultProps = {
  count: 1,
  skeletonProps: { height: 30, width: '100%' },
  skeletonWrapperProps: {},
  isVisible: false,
};
