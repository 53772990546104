import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';

import GlobalTabsContext from './GlobalTabsContext';

const maxTabLimit = 5;

export default function GlobalTabsContextProvider({ children }) {
  const [tabs, setTabs] = useState({});
  const [selectedTab, setSelectedTab] = useState(null);

  const onSetTab = useCallback(
    (val) => {
      const { tabName, children: c, pathName, search } = val;
      if (typeof val === 'function') {
        setTabs((prev) => val(prev));
      } else if (tabName) {
        setTabs((prev) => {
          if (_.keys(prev).length <= maxTabLimit) {
            return {
              ...prev,
              [tabName]: {
                children: c,
                pathName,
                search,
              },
            };
          }

          const firstTabName = _.first(_.keys(prev));
          const clearedTabs = _.omit(prev, firstTabName);
          return {
            ...clearedTabs,
            [tabName]: {
              children: c,
              pathName,
              search,
            },
          };
        });
      }
    },
    [setTabs],
  );

  const onRemoveTab = useCallback(
    (tamName) => {
      setTabs((prev) => _.omit(prev, tamName));
    },
    [setTabs],
  );

  const data = useMemo(
    () => ({
      tabs,
      setTabs: onSetTab,
      selectedTab,
      setSelectedTab,
      onRemoveTab,
    }),
    [tabs, selectedTab, setSelectedTab, onSetTab, onRemoveTab],
  );

  return (
    <GlobalTabsContext.Provider value={data}>
      {children}
    </GlobalTabsContext.Provider>
  );
}

GlobalTabsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
