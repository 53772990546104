import { TextInput, Button, Text, Flex, Input } from '@mantine/core';
import PropTypes from 'prop-types';
import { useForm } from '@mantine/form';
import { genericValidators } from 'utils';
import { performanceCycleDateTypes } from 'utils/constants';
import HireDateTypeItem from '../components/HireDateTypeItem';
import { FixedDateTypeItem } from '../components';

export default function PerformanceCycleForm({ onSubmit, isLoading }) {
  const form = useForm({
    initialValues: {
      name: '',
      type: '',
    },
    validate: {
      name: (value) =>
        genericValidators.notEmpty(value, 'Performance Cycle name is empty') ||
        genericValidators.lessThanOrEqualTo(
          value,
          50,
          'Performance Cycle name must be less than 50 chars',
        ),
      type: (value) =>
        genericValidators.notEmpty(value, 'Please select start date type'),
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)} noValidate>
      <TextInput
        label="Performance Cycle Name"
        placeholder="Name"
        onChange={(event) =>
          form.setFieldValue('name', event.currentTarget.value)
        }
        value={form.values.name}
        error={form.errors.name}
        required
      />
      <Text mt="10">When should we start this review cycle?</Text>
      <Flex gap={10}>
        <HireDateTypeItem
          onClick={() =>
            form.setFieldValue('type', performanceCycleDateTypes.hireDate)
          }
          selected={form.values?.type === performanceCycleDateTypes.hireDate}
          error={!!form.errors.type}
        />
        <FixedDateTypeItem
          onClick={() =>
            form.setFieldValue('type', performanceCycleDateTypes.fixedDate)
          }
          selected={form.values?.type === performanceCycleDateTypes.fixedDate}
          error={!!form.errors.type}
        />
      </Flex>
      <Input.Error mt={5}>{form.errors.type}</Input.Error>

      <Button type="submit" fullWidth mt="xl" loading={isLoading}>
        Submit
      </Button>
    </form>
  );
}

PerformanceCycleForm.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};

PerformanceCycleForm.defaultProps = {
  onSubmit: () => {},
  isLoading: false,
};
