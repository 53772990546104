import { Avatar, Box } from '@mantine/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDisclosure } from '@mantine/hooks';
import { ImageCropModal } from 'components/ImageCropModal';
import { useDeleteEmployeeAvatar, useUpdateEmployeeAvatar } from 'api/hooks';
import { useQueryClient } from 'react-query';
import { clearCache } from 'api/clearCache';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { useAuthContext } from 'auth';

export default function UserAvatar({
  avatarUrl,
  cursor,
  disabled,
  employeeId,
  onSelectImage,
}) {
  const { user } = useAuthContext();

  const queryClient = useQueryClient();
  const { setNotifications } = useNotifications();
  const [isHovered, setIsHovered] = useState(false);
  const [
    imageCropModalOpened,
    { open: openImageCropModal, close: closeImageCropModal },
  ] = useDisclosure(false);

  const { deleteEmployeeAvatar, isLoading: isAvatarDeleting } =
    useDeleteEmployeeAvatar({
      onSuccess: () => {
        setNotifications(['Avatar deleted successfully']);
        clearCache.onChangeEmployee(
          queryClient,
          employeeId,
          +employeeId === user?.id,
        );
        closeImageCropModal();
      },
    });

  const { updateEmployeeAvatar, isLoading: isAvatarUpdating } =
    useUpdateEmployeeAvatar({
      onSuccess: () => {
        clearCache.onChangeEmployee(
          queryClient,
          employeeId,
          +employeeId === user?.id,
        );
        closeImageCropModal();
        setNotifications(['Avatar updated successfully']);
      },
    });

  return (
    <>
      {imageCropModalOpened && (
        <ImageCropModal
          opened={imageCropModalOpened}
          onClose={closeImageCropModal}
          onSave={(blobImg) => {
            if (employeeId) {
              updateEmployeeAvatar({
                employeeId,
                avatar_filename: blobImg,
              });
            } else {
              onSelectImage(blobImg);
              closeImageCropModal();
            }
          }}
          url={avatarUrl}
          onSaveEmptyImage={() => {
            if (employeeId) {
              deleteEmployeeAvatar(employeeId);
            } else {
              onSelectImage('');
              closeImageCropModal();
            }
          }}
          isLoading={isAvatarDeleting || isAvatarUpdating}
        />
      )}
      <Box
        pos="relative"
        style={{
          overflow: 'hidden',
          borderRadius: 'calc(7.5rem * var(--mantine-scale))',
          borderColor: 'blue',
          cursor,
        }}
        w={242}
        onMouseEnter={() => !disabled && setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => !disabled && openImageCropModal()}
        mx="auto"
      >
        <Avatar
          src={avatarUrl}
          size={240}
          radius={120}
          style={{
            border: isHovered
              ? '2px solid var(--mantine-color-blue-6)'
              : undefined,
          }}
        />
        <Box
          pos="absolute"
          w="100%"
          h="100%"
          bg="rgba(222, 220, 221, 0.5)"
          bottom={isHovered ? -170 : -300}
          ta="center"
          fw={600}
          fz="lg"
          style={{
            transition: 'all 0.2s ease-in',
            color: 'white',
          }}
        >
          Upload Image
        </Box>
      </Box>
    </>
  );
}

UserAvatar.propTypes = {
  avatarUrl: PropTypes.string,
  cursor: PropTypes.string,
  disabled: PropTypes.bool,
  employeeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSelectImage: PropTypes.func,
};

UserAvatar.defaultProps = {
  avatarUrl: '',
  cursor: 'pointer',
  disabled: false,
  employeeId: '',
  onSelectImage: () => {},
};
