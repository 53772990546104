import { Button, TextInput } from '@mantine/core';
import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from '@mantine/form';
import { genericValidators } from 'utils';

export default function CreateFolderForm({ isLoading, onSubmit }) {
  const form = useForm({
    initialValues: {
      name: '',
    },
    validate: {
      name: (value) =>
        genericValidators.notEmpty(value, 'Folder name is empty') ||
        genericValidators.lessThanOrEqualTo(
          value,
          50,
          'Folder name must be less than 50 chars',
        ),
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)} noValidate>
      <TextInput
        label="Folder Name"
        placeholder="New Folder"
        onChange={(event) =>
          form.setFieldValue('name', event.currentTarget.value)
        }
        value={form.values.name}
        error={form.errors.name}
        required
      />
      <Button type="submit" fullWidth mt="xl" loading={isLoading}>
        Submit
      </Button>
    </form>
  );
}

CreateFolderForm.propTypes = {
  isLoading: PropTypes.bool,

  onSubmit: PropTypes.func,
};

CreateFolderForm.defaultProps = {
  isLoading: false,
  onSubmit: () => {},
};
