import { clearCache } from 'api/clearCache';
import { useAddCandidate } from 'api/hooks';
import { CandidateForm } from 'components/CandidateForm';
import { useGlobalTabsContext } from 'globalTabs';
import { useNotifications } from 'notifications/hooks/useNotifications';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { tabNames } from 'utils/constants';
import { usePageTitle, useTabNavigate } from 'utils/hooks';
import { PageWrapper } from '../../components/PageWrapper';

export default function CreateCandidate({ params, globalTabName }) {
  const navigate = useTabNavigate();
  const { setNotifications } = useNotifications();

  const { onRemoveTab } = useGlobalTabsContext();
  const queryClient = useQueryClient();

  const { jobId } = params;
  const { addCandidate, isLoading } = useAddCandidate({
    onSuccess: () => {
      setNotifications(['The candidate has been successfully created']);
      clearCache.onCandidateChange(queryClient, { jobId });
      navigate(`/hiring/job/${jobId}`, {
        replace: true,
        tabName: tabNames.hiringJob,
      });
      onRemoveTab(globalTabName);
    },
  });

  const submitForm = (values) => {
    addCandidate({
      ...values,
      job_id: jobId,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      candidate_source: values.candidate_source ?? undefined,
      candidate_status: values.candidate_status ?? undefined,
    });
  };

  usePageTitle('Create Candidate - Hiring', globalTabName);

  return (
    <PageWrapper title="Create Candidate">
      <CandidateForm
        onSubmit={submitForm}
        jobId={jobId}
        isSubmitLoading={isLoading}
      />
    </PageWrapper>
  );
}

CreateCandidate.propTypes = {
  params: PropTypes.object,
  globalTabName: PropTypes.string,
};

CreateCandidate.defaultProps = {
  params: {},
  globalTabName: '',
};
