import { Button, Divider, Flex, Space, Table, Title } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { clearCache } from 'api/clearCache';
import {
  useCreateReason,
  useDeleteReason,
  useLoadLookups,
  useLoadReasons,
  useUpdateReason,
} from 'api/hooks';
import { ConfirmDeleteModal } from 'components/ConfirmDeleteModal';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import { Loader } from 'components/Loader';
import { OneFieldModal } from 'components/OneFieldModal';
import { SettingsTableHead } from 'components/SettingsTable';
import _ from 'lodash';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { getCurrentReasonType } from 'utils';
import { lookupsModels } from 'utils/constants';
import { useStateDisclosure } from 'utils/hooks';
import { GenericRow } from '../components';

const headerList = ['Termination Reason', 'Records', ''];

export default function TerminationReason() {
  const { setNotifications } = useNotifications();
  const queryClient = useQueryClient();

  const { lookups, isLoading: isLookupsLoading } = useLoadLookups({
    models: [lookupsModels.reason],
  });

  const currentReasonType = useMemo(
    () => getCurrentReasonType(lookups?.Reason, 'Termination'),
    [lookups?.Reason],
  );

  const { reasons, isLoading: isReasonsLoading } = useLoadReasons(
    { params: { filter: { byType: currentReasonType }, withCount: 1 } },
    { enabled: !!currentReasonType },
  );

  const [
    openedTerminationReasonModal,
    {
      open: openTerminationReasonModal,
      close: closeTerminationReasonModal,
      state: TerminationReasonState,
    },
  ] = useStateDisclosure(false);

  const [
    openedDeleteReasonModal,
    {
      open: openDeleteReasonModal,
      close: closeDeleteReasonModal,
      state: deleteReasonModalState,
    },
  ] = useStateDisclosure(false);

  const { createReason, isLoading: isCreatingReason } = useCreateReason({
    onSuccess: () => {
      setNotifications(['Termination Reason created successfully']);
      clearCache.onChangeReason(queryClient, currentReasonType);
      closeTerminationReasonModal();
    },
  });

  const { deleteReason, isLoading: isDeleting } = useDeleteReason({
    onSuccess: () => {
      setNotifications(['Termination Reason deleted successfully']);
      clearCache.onChangeReason(queryClient, currentReasonType);
      closeDeleteReasonModal();
    },
  });

  const { updateReason, isLoading: isUpdatingReason } = useUpdateReason({
    onSuccess: () => {
      setNotifications(['Termination Reason updated successfully']);
      clearCache.onChangeReason(queryClient, currentReasonType);
      closeTerminationReasonModal();
    },
  });

  return (
    <>
      <OneFieldModal
        isLoading={isCreatingReason || isUpdatingReason}
        state={TerminationReasonState}
        opened={openedTerminationReasonModal}
        onClose={closeTerminationReasonModal}
        onCreate={(data) => createReason({ ...data, type: currentReasonType })}
        onEdit={(data) =>
          updateReason({
            reasonId: TerminationReasonState?.id,
            ...data,
          })
        }
        title="Termination Reason"
      />

      <ConfirmDeleteModal
        opened={openedDeleteReasonModal}
        onClose={closeDeleteReasonModal}
        onDelete={() => deleteReason(deleteReasonModalState?.id)}
        isLoading={isDeleting}
      />

      <Flex gap={10} justify="space-between" align="center">
        <Title fz="xl">Termination Reason</Title>
        <Button
          onClick={() => openTerminationReasonModal()}
          rightSection={<IconPlus size={14} />}
        >
          Add Termination Reason
        </Button>
      </Flex>

      <Space h="xs" />

      <Loader isLoading={isReasonsLoading || isLookupsLoading} />

      <EmptyDataMessage
        isVisible={!isReasonsLoading && !isLookupsLoading && _.isEmpty(reasons)}
      />

      {!_.isEmpty(reasons) && (
        <>
          <Table>
            <SettingsTableHead headerList={headerList} />
            <Table.Tbody>
              {_.map(reasons, (reason) => (
                <GenericRow
                  key={reason?.id}
                  data={reason}
                  onEditRecord={(data) =>
                    openTerminationReasonModal({ ...data, modalType: 'Edit' })
                  }
                  withEmployeeCount
                  onDeleteRecord={openDeleteReasonModal}
                />
              ))}
            </Table.Tbody>
          </Table>
          <Divider />
        </>
      )}
    </>
  );
}
