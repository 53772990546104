import { Space, Tabs, Text } from '@mantine/core';
import { useState } from 'react';
import { boardingListTypes } from 'utils/constants';
import { BoardingTab } from './onboarding-tabs';

const tabs = [
  { value: 'onboarding', title: 'Onboarding' },
  { value: 'offboarding', title: 'Offboarding' },
];

export default function OnboardingProviderTab() {
  const [selectedTab, setSelectedTab] = useState(tabs[0].value);

  return (
    <>
      <Space h="md" />
      <Tabs
        onChange={setSelectedTab}
        value={selectedTab}
        orientation="vertical"
        keepMounted={false}
      >
        <Tabs.List mr={15}>
          {tabs.map((tab) => (
            <Tabs.Tab
              w="100%"
              bg={selectedTab === tab.value ? 'blue' : undefined}
              c={selectedTab === tab.value ? 'white' : undefined}
              value={tab.value}
              key={tab.value}
            >
              <Text p={5}>{tab.title}</Text>
            </Tabs.Tab>
          ))}
        </Tabs.List>
        <Tabs.Panel value="onboarding">
          <BoardingTab
            title="Onboarding"
            listType={boardingListTypes.onboarding}
          />
        </Tabs.Panel>
        <Tabs.Panel value="offboarding">
          <BoardingTab
            title="Offboarding"
            listType={boardingListTypes.offboarding}
          />
        </Tabs.Panel>
      </Tabs>
    </>
  );
}
