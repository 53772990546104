import { Box, Flex, Stack } from '@mantine/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { UploadFile } from 'components/UploadFile';
import { CandidateQuestions } from 'components/CandidateQuestions';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { CandidateManager } from '../CandidateManager';

export default function CandidateInfo({
  candidateDetails,
  jobId,
  offerTemplateProps,
}) {
  const { setNotifications } = useNotifications();

  const [file, setSelectedFile] = useState(null);

  return (
    <Flex flex="1">
      <Box p="md" flex="1">
        <Stack gap={10}>
          <UploadFile
            selectedFile={file}
            setSelectedFile={setSelectedFile}
            onFileSubmit={() => {
              setNotifications(['MOCK! File uploaded successfully']);
              setSelectedFile(null);
            }}
          />
          <CandidateQuestions
            jobId={jobId}
            candidateId={candidateDetails?.id}
          />
        </Stack>
      </Box>
      <CandidateManager
        candidateDetails={candidateDetails}
        jobId={jobId}
        offerTemplateProps={offerTemplateProps}
      />
    </Flex>
  );
}

CandidateInfo.propTypes = {
  jobId: PropTypes.string.isRequired,
  candidateDetails: PropTypes.object.isRequired,
  offerTemplateProps: PropTypes.object,
};

CandidateInfo.defaultProps = {
  offerTemplateProps: {},
};
