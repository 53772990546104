import { Button, Table } from '@mantine/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import { tabNames } from 'utils/constants';
import { useTabNavigate } from 'utils/hooks';
import classes from './Jobs.module.css';

export default function JobsTableRow({ item }) {
  const statusStyle =
    item.status === 'Active' ? classes.activeTd : classes.inactiveTd;
  const navigate = useTabNavigate();
  const navigateToProfile = (id) => {
    navigate(`/hiring/job/${id}`, { tabName: tabNames.hiringJob });
  };
  const navigateToEdit = (id) => {
    navigate(`/hiring/job/${id}/edit/`, { tabName: tabNames.editHiringJob });
  };
  return (
    <Table.Tr className={[classes.tableStyles, classes.rowSelected]}>
      <Table.Td
        className={classes.pointer}
        onClick={() => navigateToProfile(item.id)}
      >
        {item.title}
      </Table.Td>
      <Table.Td>{item.location}</Table.Td>
      <Table.Td>{item.status}</Table.Td>
      <Table.Td>{item.candidates}</Table.Td>
      <Table.Td>{item.interviews}</Table.Td>
      <Table.Td className={statusStyle}>{item.type}</Table.Td>
      <Table.Td>{moment(item.created_at).format('YYYY-MM-DD')}</Table.Td>
      <Table.Td>{moment(item.updated_at).format('YYYY-MM-DD')}</Table.Td>
      <Table.Td>
        <Button onClick={() => navigateToEdit(item.id)}>Edit</Button>
      </Table.Td>
    </Table.Tr>
  );
}

JobsTableRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    title: PropTypes.string,
    location: PropTypes.string,
    status: PropTypes.string,
    candidates: PropTypes.number,
    interviews: PropTypes.number,
    type: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,
};
