import {
  Accordion,
  Button,
  Flex,
  Input,
  InputLabel,
  Radio,
  Select,
  Space,
  Stack,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { ControlLabel } from 'components/ControlLabel';
import { EmployeesFilter } from 'components/EmployeesFilter';
import { useEmployeeFilter } from 'components/EmployeesFilter/hooks';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { datesValidators, genericValidators } from 'utils';
import {
  performanceCycleDateTypes,
  performanceCycleStatuses,
} from 'utils/constants';
import FeedbackFormSection from '../FeedbackFormSection';
import { FixedDateTypeItem, HireDateTypeItem } from '../components';
import {
  employeeTypes,
  filterOnlySelectedValues,
  markEmployeeFiltersAsSelected,
} from './utils';

const getSurveyIds = (surveyList, cycleSurveys) =>
  _.map(
    surveyList.filter((survey) =>
      _.some(_.map(cycleSurveys, 'id'), (val) => val === survey?.id),
    ),
    'id',
  );

export default function PerformanceCycleEditForm({
  cycle,
  peerFeedbackSurveys,
  upwardFeedbackSurveys,
  assessmentSurveys,
  onSubmit,
  isLoading,
  managerSurveys,
}) {
  const form = useForm({
    initialValues: {
      name: cycle?.name ?? '',
      feedback: cycle?.feedback,
      feedbackStartDate: cycle?.f_start_date
        ? moment(cycle.f_start_date, moment.ISO_8601)
        : null,
      feedbackFrequency: _.toString(cycle?.f_frequency),
      feedbackRepeat: !!cycle?.f_repeat,
      assessment: cycle?.assessment,
      assessmentStartDate: cycle?.a_start_date
        ? moment(cycle.a_start_date, moment.ISO_8601)
        : null,
      assessmentFrequency: _.toString(cycle?.a_frequency),
      status: _.toString(cycle?.status),
      feedbackSurveyIds: getSurveyIds(
        peerFeedbackSurveys?.concat(upwardFeedbackSurveys),
        cycle?.surveys,
      ),
      assessmentRepeat: !!cycle?.a_repeat,
      assessmentSurveyIds: getSurveyIds(
        managerSurveys?.concat(assessmentSurveys),
        cycle.surveys,
      ),
      employee_type: _.isEmpty(cycle?.filters)
        ? employeeTypes.all
        : employeeTypes.someEmployee,
      // format for deleted filters, to be able to reselect
      filters: markEmployeeFiltersAsSelected(cycle?.filters) ?? {},
      hasPeerFeedback: !_.isEmpty(
        getSurveyIds(peerFeedbackSurveys, cycle?.surveys),
      ),
      hasUpwardFeedback: !_.isEmpty(
        getSurveyIds(upwardFeedbackSurveys, cycle?.surveys),
      ),
    },
    validate: {
      name: (value) =>
        genericValidators.notEmpty(value, 'Performance Cycle name is empty') ||
        genericValidators.lessThanOrEqualTo(
          value,
          50,
          'Performance Cycle name must be less than 50 chars',
        ),
      status: (value) =>
        genericValidators.notEmpty(
          value,
          'Performance Cycle Status is required',
        ),
      assessmentStartDate: (value) =>
        form.values.assessment &&
        cycle?.type === performanceCycleDateTypes.fixedDate
          ? datesValidators.isValidDate(value, 'You should select a valid date')
          : null,
      assessmentFrequency: (value) =>
        form.values.assessment
          ? genericValidators.notEmpty(
              value,
              'Assessment Frequency is required',
            )
          : null,
      feedbackStartDate: (value) =>
        form.values.feedback &&
        cycle?.type === performanceCycleDateTypes.fixedDate
          ? datesValidators.isValidDate(value, 'You should select a valid date')
          : null,
      feedbackFrequency: (value) =>
        form.values.feedback
          ? genericValidators.notEmpty(value, 'Feedback Frequency is required')
          : null,

      feedbackSurveyIds: (value, { hasPeerFeedback, hasUpwardFeedback }) => {
        if (!form.values.feedback) return null;
        if (_.isEmpty(value) || (!hasUpwardFeedback && !hasPeerFeedback)) {
          return 'If Feedback is enabled, you must select at least 1 survey';
        }
        return null;
      },
      assessmentSurveyIds: (value) => {
        const hasManagerSurvey = value?.some((selectedSurveyId) =>
          _.map(managerSurveys, 'id').includes(selectedSurveyId),
        );

        const hasSelfAssessmentSurvey = value?.some((selectedSurveyId) =>
          _.map(assessmentSurveys, 'id').includes(selectedSurveyId),
        );

        if (
          form.values.assessment &&
          (!hasManagerSurvey || !hasSelfAssessmentSurvey)
        ) {
          return 'If Assessment is enabled, you must select at least 1 survey from self Assessment and Manager Assessment';
        }

        return null;
      },
      surveys: (val, { assessment, status, feedback }) => {
        const activeStatus = performanceCycleStatuses.find(
          (item) => item.label === 'Active',
        ).value;
        if (+status === +activeStatus && !assessment && !feedback) {
          return 'At least one must be selected when the status is Active';
        }
        return null;
      },
      filters: (val, { employee_type: employeeType }) =>
        _.isEmpty(filterOnlySelectedValues(val)) &&
        employeeType === employeeTypes.someEmployee
          ? 'At least one must be selected'
          : null,

      hasPeerFeedback: (val, { feedbackSurveyIds, feedback }) => {
        if (!val || !feedback) return null;

        const hasPeer = peerFeedbackSurveys?.some(({ id }) =>
          feedbackSurveyIds?.includes(id),
        );

        return !hasPeer
          ? 'If Peer Feedback is enabled, you must select at least 1 survey'
          : null;
      },

      hasUpwardFeedback: (val, { feedbackSurveyIds, feedback }) => {
        if (!val || !feedback) return null;

        const hasUpward = upwardFeedbackSurveys?.some(({ id }) =>
          feedbackSurveyIds?.includes(id),
        );

        return !hasUpward
          ? 'If Upward Feedback is enabled, you must select at least 1 survey'
          : null;
      },
    },
  });

  const accordionValues = [
    { key: 'assessment', enabled: form.values.assessment },
    { key: 'feedback', enabled: form.values.feedback },
  ];

  const feedbackAccordionValues = [
    { key: 'peerFeedback', enabled: form.values.hasPeerFeedback },
    { key: 'upwardFeedback', enabled: form.values.hasUpwardFeedback },
  ];

  const onSelectSurvey = (value, id, surveyTypeIds) => {
    if (value) {
      form.insertListItem(surveyTypeIds, id);
    } else {
      form.removeListItem(
        surveyTypeIds,
        form.values?.[surveyTypeIds]?.findIndex((item) => item === id),
      );
    }
  };

  const TypesProvider = {
    [performanceCycleDateTypes.hireDate]: HireDateTypeItem,
    [performanceCycleDateTypes.fixedDate]: FixedDateTypeItem,
  };

  const Type = TypesProvider[cycle?.type] ?? null;

  const { employeeFilters, onChangeEmployeeFilter, isLookupsLoading } =
    useEmployeeFilter({
      initialValues: form.values.filters,
    });

  return (
    <form onSubmit={form.onSubmit(onSubmit)} noValidate>
      <TextInput
        label="Performance Cycle Name"
        placeholder="Name"
        onChange={(event) =>
          form.setFieldValue('name', event.currentTarget.value)
        }
        value={form.values.name}
        error={form.errors.name}
        required
      />
      <Space h="md" />
      <Type viewOnly />
      <Space h="md" />

      <Radio.Group
        {...form.getInputProps('employee_type')}
        error={form.errors.employee_type}
      >
        <InputLabel required>Who should be included?</InputLabel>
        <Stack gap={10}>
          <Radio value={employeeTypes.all} label="All employees" />
          <Radio value={employeeTypes.someEmployee} label="Some employee" />
        </Stack>
      </Radio.Group>

      {form.values.employee_type === employeeTypes.someEmployee && (
        <>
          <Space h="md" />
          <EmployeesFilter
            error={form.errors.filters}
            onSelectFilter={(data) =>
              onChangeEmployeeFilter(data, (values) =>
                form.setFieldValue('filters', values),
              )
            }
            employeeFilters={employeeFilters}
            isLookupsLoading={isLookupsLoading}
            selectedValues={form.values.filters}
          />
        </>
      )}

      <Space h="md" />

      <Select
        label="Status"
        placeholder="Pick Status"
        data={performanceCycleStatuses}
        onChange={(val) => {
          form.setFieldValue('status', val);
          form.clearFieldError('surveys');
        }}
        value={form.values.status}
      />
      <Space h="md" />
      <Accordion
        variant="contained"
        multiple
        value={accordionValues
          .filter((item) => item.enabled)
          .map((item) => item.key)}
        disableChevronRotation
        chevronSize={0}
      >
        <Accordion.Item
          value="feedback"
          styles={{
            item: form.errors.surveys
              ? {
                  borderTop: '1px solid red',
                  borderLeft: '1px solid red',
                  borderRight: '1px solid red',
                }
              : {},
          }}
        >
          <Accordion.Control>
            <ControlLabel
              title="Feedback"
              isEnabled={form.values.feedback}
              onChange={(checked) => {
                form.setFieldValue('feedback', checked);
                form.clearFieldError('surveys');
              }}
            />
          </Accordion.Control>
          <Accordion.Panel>
            <FeedbackFormSection
              surveys={peerFeedbackSurveys}
              onDateChange={(val) => {
                form.setFieldValue('feedbackStartDate', val);
              }}
              onFrequencyChange={(value) =>
                form.setFieldValue('feedbackFrequency', value)
              }
              startDate={form.values.feedbackStartDate}
              frequency={form.values.feedbackFrequency}
              error={form.errors.feedbackStartDate}
              surveySelectError={
                form.errors.feedbackSurveyIds || form.errors.hasPeerFeedback
              }
              upwardError={form.errors.hasUpwardFeedback}
              frequencyError={form.errors.feedbackFrequency}
              selectedSurveyIds={form.values.feedbackSurveyIds}
              onSelectSurvey={(val, surveyId) =>
                onSelectSurvey(val, surveyId, 'feedbackSurveyIds')
              }
              cycle={cycle}
              onChangeRepeat={(value) =>
                form.setFieldValue('feedbackRepeat', value)
              }
              repeat={!!form?.values?.feedbackRepeat}
              surveyType="Feedback"
              performanceLabel="Peer Feedback"
              withUpwardFeedback
              upwardFeedbackSurveys={upwardFeedbackSurveys}
              feedbackAccordionValues={feedbackAccordionValues}
              onChangeAccordionValue={(field, value) =>
                form.setFieldValue(field, value)
              }
              upwardTooltip="Upward Feedback is when employees give feedback about their manager.
              All employees who report to a manager will receive a request to provide upward feedback."
              surveyTooltip="Peer feedback is when employees give feedback to each other. Managers will
              receive a request to nominate employees to provide peer feedback."
            />
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item
          value="assessment"
          styles={{
            item: form.errors.surveys ? { border: '1px solid red' } : {},
          }}
        >
          <Accordion.Control>
            <ControlLabel
              title="Assessment"
              isEnabled={form.values.assessment}
              onChange={(checked) => {
                form.setFieldValue('assessment', checked);
                form.clearFieldError('surveys');
              }}
            />
          </Accordion.Control>
          <Accordion.Panel>
            <FeedbackFormSection
              surveys={assessmentSurveys}
              onDateChange={(val) => {
                form.setFieldValue('assessmentStartDate', val);
              }}
              onFrequencyChange={(value) =>
                form.setFieldValue('assessmentFrequency', value)
              }
              frequency={form.values.assessmentFrequency}
              startDate={form.values.assessmentStartDate}
              error={form.errors.assessmentStartDate}
              frequencyError={form.errors.assessmentFrequency}
              surveySelectError={form.errors.assessmentSurveyIds}
              selectedSurveyIds={form.values.assessmentSurveyIds}
              onSelectSurvey={(val, surveyId) =>
                onSelectSurvey(val, surveyId, 'assessmentSurveyIds')
              }
              cycle={cycle}
              repeat={!!form?.values?.assessmentRepeat}
              onChangeRepeat={(value) =>
                form.setFieldValue('assessmentRepeat', value)
              }
              surveyType="Assessment"
              performanceLabel="Self Assessment"
              withManagerAssessment
              managerAssessmentSurveys={managerSurveys}
            />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
      {form.errors.surveys && <Input.Error>{form.errors.surveys}</Input.Error>}
      <Flex align="center" justify="center">
        <Button type="submit" mt="xl" loading={isLoading}>
          Submit
        </Button>
      </Flex>
    </form>
  );
}

PerformanceCycleEditForm.propTypes = {
  onSubmit: PropTypes.func,
  cycle: PropTypes.object,
  peerFeedbackSurveys: PropTypes.array,
  upwardFeedbackSurveys: PropTypes.array,
  assessmentSurveys: PropTypes.array,
  managerSurveys: PropTypes.array,
  isLoading: PropTypes.bool,
};

PerformanceCycleEditForm.defaultProps = {
  onSubmit: () => {},
  isLoading: false,
  peerFeedbackSurveys: [],
  upwardFeedbackSurveys: [],
  assessmentSurveys: [],
  managerSurveys: [],
  cycle: null,
};
