import {
  Box,
  Button,
  Divider,
  Flex,
  Paper,
  Rating,
  Select,
  Stack,
  Text,
} from '@mantine/core';
import { IconBrandLinkedin, IconStar } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { extractWithVal, formatSelectorData } from 'utils';
import {
  useCandidateActiveOffer,
  useCandidatesStatusSelectorData,
} from 'utils/api';

import { useToggle } from '@mantine/hooks';
import { clearCache } from 'api/clearCache';
import { useLoadOfferTemplates, useUpdateCandidate } from 'api/hooks';
import { queryKeys } from 'api/keys';
import _ from 'lodash';
import moment from 'moment';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { useQueryClient } from 'react-query';
import styles from './styles.module.css';

export default function CandidateManager({
  candidateDetails,
  jobId,
  offerTemplateProps,
}) {
  const queryClient = useQueryClient();

  const { offerTemplates } = useLoadOfferTemplates();
  const { setNotifications } = useNotifications();

  const { canCreateNewOffer } = useCandidateActiveOffer(
    candidateDetails?.offers ?? [],
  );

  const [rating, setRating] = useState(candidateDetails?.rating ?? 0);

  const [candidateStatus, setCandidateStatus] = useState(
    candidateDetails?.candidate_status ?? '',
  );

  const { candidateStatusSelectorData } = useCandidatesStatusSelectorData({
    selectedValue: candidateDetails?.candidate_status,
  });

  const { updateCandidate, isLoading: isUpdating } = useUpdateCandidate({
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: queryKeys.candidate(candidateDetails.id),
      });
      clearCache.onCandidateChange(queryClient, {
        candidateId: candidateDetails.id,
        jobId,
      });
      setNotifications(['Candidate Updated Successfully']);
    },
  });

  const onUpdateCandidate = ({ selectedData, prevData, field }, callback) => {
    if (prevData !== selectedData) {
      updateCandidate({
        id: candidateDetails?.id,
        payload: {
          ...extractWithVal(candidateDetails),
          job_id: jobId,
          [field]: selectedData,
        },
      });
    }
    callback(selectedData);
  };

  const { offerTemplateId, setOfferTemplateId } = offerTemplateProps;

  const [isTemplateSelectVisible, toggleTemplateSelect] = useToggle();

  useEffect(() => {
    if (offerTemplateId) toggleTemplateSelect(true);
  }, [offerTemplateId, toggleTemplateSelect]);

  const getEmptyStarIcon = (val) => (
    <IconStar
      stroke={0}
      width="100%"
      height="100%"
      fill={val <= rating ? 'orange' : 'gray'}
      fillOpacity="0.3"
    />
  );

  return (
    <Box
      bg="var(--white-dark)"
      p="md"
      h="max-content"
      w={300}
      pos="sticky"
      ml="auto"
      top="var(--app-shell-header-height)"
    >
      <Paper withBorder>
        <Stack p={10}>
          <div style={{ position: 'relative' }}>
            <Rating
              w="100%"
              size="100%"
              value={rating}
              onChange={(val) =>
                onUpdateCandidate(
                  { selectedData: val, prevData: rating, field: 'rating' },
                  setRating,
                )
              }
              emptySymbol={getEmptyStarIcon}
              fullSymbol={
                <IconStar stroke={0} width="100%" height="100%" fill="orange" />
              }
            />
          </div>
          <Select
            label="Candidate Status"
            placeholder="Select candidate status"
            data={candidateStatusSelectorData}
            searchable
            value={candidateStatus}
            onChange={(val) =>
              onUpdateCandidate(
                {
                  selectedData: val,
                  prevData: candidateStatus,
                  field: 'candidate_status',
                },
                setCandidateStatus,
              )
            }
            allowDeselect={false}
            disabled={isUpdating}
          />
          {canCreateNewOffer && isTemplateSelectVisible && (
            <Select
              value={offerTemplateId}
              disabled={!_.isEmpty(candidateDetails?.offer)}
              label="Offer Template"
              placeholder="Select Offer Template"
              data={formatSelectorData(offerTemplates, {
                value: 'id',
                label: 'subject',
              })}
              onChange={setOfferTemplateId}
              allowDeselect={false}
            />
          )}
          <Button
            onClick={() => toggleTemplateSelect()}
            disabled={!canCreateNewOffer}
          >
            Create Offer
          </Button>
        </Stack>
      </Paper>
      <Stack p="md" gap={10}>
        <InfoSub
          label="Linkedin"
          link={candidateDetails?.linkedin}
          icon={<IconBrandLinkedin color="gray" />}
        />
        <InfoSub
          label="Available Start Date"
          info={
            candidateDetails?.created_at
              ? moment(candidateDetails?.created_at).format('MMM D, YYYY')
              : ''
          }
        />
        <InfoSub label="Phone" info={candidateDetails?.phone} />
        <InfoSub label="Email" info={candidateDetails?.email} />
        <InfoSub
          label="Website, Blog or Portfolio"
          link={candidateDetails?.portfolio}
        />
        <InfoSub label="Address" info={candidateDetails?.address} />
      </Stack>
    </Box>
  );
}

CandidateManager.propTypes = {
  candidateDetails: PropTypes.object.isRequired,
  jobId: PropTypes.string.isRequired,
  offerTemplateProps: PropTypes.object,
};

CandidateManager.defaultProps = {
  offerTemplateProps: {},
};

function InfoSub({ label, info, link, icon }) {
  return (
    <Stack gap={0}>
      <Flex align="center">
        {icon}
        <Text c="gray">{label}</Text>
      </Flex>
      {info && <Text classNames={{ root: styles.text }}>{info}</Text>}
      {link && (
        <Link to={link} className={styles.text}>
          {link}
        </Link>
      )}

      <Divider orientation="horizontal" />
    </Stack>
  );
}

InfoSub.propTypes = {
  label: PropTypes.string,
  info: PropTypes.string,
  link: PropTypes.string,
  icon: PropTypes.node,
};

InfoSub.defaultProps = {
  label: '',
  info: '',
  link: '',
  icon: null,
};
