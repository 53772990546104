import { Fragment } from 'react';
import { LoadingOverlay, Text, Flex, Space } from '@mantine/core';
import { useRequests } from 'api/hooks';
import { usePermissions } from 'components/PermissionGuard/hooks';
import TimeOffRow from 'components/Requests/rows/TimeOffRow';
import PropTypes from 'prop-types';
import { TimeOffRequestPreview } from 'components/Requests/views';
import { useAuthContext } from 'auth';

export default function TimeOffTab({ onPreview, timeOffId }) {
  const { hasPermission, permissions } = usePermissions();
  const { user } = useAuthContext();

  const { requests, isLoading } = useRequests({
    filters: {
      TimeoffRecords: {
        assignee_id: hasPermission(permissions.canManageEmployee)
          ? ''
          : user?.id,
      },
      status: [0],
    },
    limit: 10,
    canManageEmployee: hasPermission(permissions.canManageEmployee),
  });

  if (timeOffId) {
    return <TimeOffRequestPreview requestId={timeOffId} />;
  }

  return (
    <>
      <h2>Time Off</h2>
      <LoadingOverlay
        visible={isLoading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      {requests?.map((request) => (
        <Fragment key={request.id}>
          <Space h="md" />
          <TimeOffRow
            key={request.id}
            request={request}
            onClick={onPreview}
            displayStatus={false}
          />
        </Fragment>
      ))}
      {!isLoading && requests?.length === 0 && (
        <Flex align="center" justify="center">
          <Text fw={500} size="xl" c="dimmed">
            There are no records
          </Text>
        </Flex>
      )}
    </>
  );
}

TimeOffTab.propTypes = {
  onPreview: PropTypes.func.isRequired,
  timeOffId: PropTypes.string,
};

TimeOffTab.defaultProps = {
  timeOffId: '',
};
