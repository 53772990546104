import { Button, Flex, Modal, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { clearCache } from 'api/clearCache';
import { useDeleteEmployee, useEmployee, useUpdateEmployee } from 'api/hooks';
import { useAuthContext } from 'auth';
import { EmployeeForm } from 'components/EmployeeForm';
import { PageWrapper } from 'components/PageWrapper';
import { usePermissions } from 'components/PermissionGuard/hooks';
import _ from 'lodash';
import moment from 'moment';
import { useNotifications } from 'notifications/hooks/useNotifications';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { tabNames } from 'utils/constants';
import { usePageTitle, useTabNavigate } from 'utils/hooks';

export default function EditEmployee({ isMyProfile, params, globalTabName }) {
  const { user, refetchUser } = useAuthContext();
  const { userId: id } = params;

  const queryClient = useQueryClient();

  const userId = useMemo(
    () => (isMyProfile ? _.toString(user?.id) : id),
    [isMyProfile, id, user],
  );
  const navigate = useTabNavigate();
  const { permissions, hasPermission } = usePermissions();
  const { setNotifications } = useNotifications();
  const [isModalOpened, { close: closeModal, open: openModal }] =
    useDisclosure(false);

  const {
    profile,
    isLoading: isProfileLoading,
    error,
    isFetched,
  } = useEmployee({
    id: userId,
    canManageEmployee: hasPermission(permissions.canManageEmployee),
  });

  const { deleteEmployee, isLoading: isDeleteLoading } = useDeleteEmployee({
    onSuccess: () => {
      clearCache.onChangeEmployee(queryClient, userId);
      setNotifications(['Employee Deleted Successfully']);
      navigate('/employees', { replace: true });
    },
  });
  const { updateEmployee, isLoading } = useUpdateEmployee({
    onSuccess: () => {
      clearCache.onChangeEmployee(queryClient, userId);
      setNotifications(['Employee Updated Successfully']);
      navigate(`/employees/${userId}`, {
        replace: true,
        tabName: tabNames.employeesProfile,
      });
      if (_.toNumber(id) === _.toNumber(user?.id) || isMyProfile) refetchUser();
    },
  });
  const submitForm = (values) => {
    updateEmployee({
      id: userId,
      payload: {
        ...values,
        dob: values?.dob ? moment(values.dob).format('YYYY-MM-DD') : undefined,
        hire_date: values?.hire_date
          ? moment(values.hire_date).format('YYYY-MM-DD')
          : undefined,
      },
    });
  };

  const onDeleteClick = () => {
    openModal();
  };
  const onDelete = () => {
    deleteEmployee({ id: userId });
  };

  usePageTitle(
    profile
      ? `Edit Employee - ${profile?.first_name ?? ''} ${
          profile?.last_name ?? ''
        } - Employees`
      : 'Edit Employee - Employees',
    globalTabName,
  );

  return (
    <PageWrapper title="Edit Employee">
      <EmployeeForm
        onSubmit={submitForm}
        profile={profile}
        onDelete={onDeleteClick}
        isSubmitLoading={isLoading}
        isDeleteLoading={isDeleteLoading}
        enableFetch={isFetched}
        isProfileLoading={isProfileLoading}
      />
      <Modal
        opened={isModalOpened}
        onClose={closeModal}
        size="auto"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        title="Warning"
      >
        <Text>Are you sure you want to delete this employee?</Text>
        <Flex mt={20} justify="center" align="center" gap="xl">
          <Button onClick={onDelete} variant="filled" color="red">
            Delete
          </Button>
          <Button onClick={closeModal} variant="outline">
            Cancel
          </Button>
        </Flex>
      </Modal>
      {error && <h3 style={{ textAlign: 'center' }}>Employee Not Found</h3>}
    </PageWrapper>
  );
}

EditEmployee.propTypes = {
  isMyProfile: PropTypes.bool,
  params: PropTypes.object,
  globalTabName: PropTypes.string,
};

EditEmployee.defaultProps = {
  isMyProfile: false,
  params: {},
  globalTabName: '',
};
