import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Group, Textarea, MultiSelect } from '@mantine/core';
import { useCandidates } from 'api/hooks/queries';
import { genericValidators } from 'utils';
import { useForm } from '@mantine/form';
import _ from 'lodash';

const formatCandidateData = (data) =>
  _.map(data, (item) => ({
    item,
    value: `${item.id}`,
    label: item.first_name,
  }));

export default function AddCandidateForm({
  candidates,
  onSubmit,
  isSubmitLoading,
}) {
  const form = useForm({
    initialValues: {
      searchCandidates: candidates?.searchCandidates,
      description: candidates?.description ?? '',
    },
    validate: {
      searchCandidates: (value) =>
        genericValidators.notEmpty(value, 'Select a candidate'),
    },
  });
  const [search, setSearch] = useState('');
  const [multiselectData, setMultiSelectData] = useState([]);

  useCandidates(
    { search },
    {
      enabled: search.length > 2,
      onSuccess: (data) => {
        const candidateList = _.get(data, 'data', []);
        setMultiSelectData((prev) => {
          const newData = formatCandidateData(candidateList);
          return _.uniqBy(prev.concat(newData), 'value');
        });
      },
    },
  );

  const handleChangeWithDebounce = _.debounce(async (value) => {
    setSearch(value);
  }, 300);
  useEffect(
    () => () => handleChangeWithDebounce.cancel(),
    [handleChangeWithDebounce],
  );
  return (
    <form onSubmit={form.onSubmit(onSubmit)} noValidate>
      <MultiSelect
        mb="md"
        name="searchCandidates"
        value={form.values.searchCandidates}
        label="Pick the candidate"
        placeholder="Tap candidate name"
        data={multiselectData}
        onChange={(values) => {
          form.setFieldValue('searchCandidates', values);
        }}
        onSearchChange={handleChangeWithDebounce}
        error={form.errors.searchCandidates}
        searchable
      />

      <Textarea
        mb="md"
        name="description"
        label="Why are you adding this candidate to this pool?"
        placeholder="Description"
        minRows={3}
        value={form.values.description}
        onChange={(event) =>
          form.setFieldValue('description', event.currentTarget.value)
        }
        error={form.errors.description}
      />

      <Group gap="xl" mt="xl" justify="center">
        <Button loading={isSubmitLoading} type="submit">
          Add
        </Button>
      </Group>
    </form>
  );
}

AddCandidateForm.propTypes = {
  candidates: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  isSubmitLoading: PropTypes.bool,
};
AddCandidateForm.defaultProps = {
  candidates: null,
  isSubmitLoading: false,
};
