import { Modal, Text } from '@mantine/core';
import PropTypes from 'prop-types';
import TimeOffPolicyDateForm from '../forms/TimeOffPolicyDateForm';

export default function TimeOffPolicyDateModal({
  opened,
  onClose,
  onSubmit,
  policy,
  isLoading,
}) {
  return (
    <Modal
      opened={opened}
      onClose={() => {
        onClose();
      }}
      title="Adjust Accrual Date"
      centered
    >
      <Text c="red" size="xs">
        Not implemented
      </Text>
      <TimeOffPolicyDateForm
        policy={policy}
        isLoading={isLoading}
        onSubmit={onSubmit}
      />
    </Modal>
  );
}

TimeOffPolicyDateModal.propTypes = {
  isLoading: PropTypes.bool,
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  policy: PropTypes.object,
};

TimeOffPolicyDateModal.defaultProps = {
  isLoading: false,
  opened: false,
  onClose: () => {},
  onSubmit: () => {},
  policy: null,
};
