import { Box, rem } from '@mantine/core';
import React from 'react';
import PropTypes from 'prop-types';
import { IconCheck, IconX } from '@tabler/icons-react';

export default function PasswordRequirement({ meets, label }) {
  return (
    <Box
      c={meets ? 'teal' : 'red'}
      style={{ display: 'flex', alignItems: 'center' }}
      mt={7}
      size="sm"
    >
      {meets ? (
        <IconCheck style={{ width: rem(14), height: rem(14) }} />
      ) : (
        <IconX style={{ width: rem(14), height: rem(14) }} />
      )}{' '}
      <Box ml={10}>{label}</Box>
    </Box>
  );
}

PasswordRequirement.propTypes = {
  meets: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};
