import {
  Divider,
  Flex,
  Indicator,
  Pill,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import React from 'react';
import PropTypes from 'prop-types';
import { Calendar } from '@mantine/dates';
import _ from 'lodash';
import moment from 'moment';

const calculatedCols = {
  sm: 1,
  md: 2,
  lg: 3,
  maxSmItems: 5,
  maxMdItems: 15,
  getCols(val, maxCols) {
    if (val <= this.maxSmItems) {
      return maxCols && maxCols < this.sm ? maxCols : this.sm;
    }
    if (val <= this.maxMdItems) {
      return maxCols && maxCols < this.md ? maxCols : this.md;
    }
    return maxCols && maxCols < this.lg ? maxCols : this.lg;
  },
};

export default function TimeOffList({ timeOffList }) {
  return (
    <Stack>
      <Divider />
      <Flex align="flex-start" gap={35} justify="space-between">
        <Calendar
          static
          defaultDate={moment(_.first(timeOffList)?.date) ?? undefined}
          renderDay={(date) => {
            const day = date.getDate();
            const formattedDate = moment(date).format('YYYY-MM-DD');
            const dayExist = _.find(
              timeOffList,
              (item) => item?.date === formattedDate,
            );
            return (
              <Tooltip disabled={!dayExist} label={`${dayExist?.hours} hours`}>
                <Indicator
                  size={6}
                  color="red"
                  offset={-2}
                  disabled={!dayExist}
                >
                  <div>{day}</div>
                </Indicator>
              </Tooltip>
            );
          }}
        />
        <SimpleGrid
          cols={{
            md: calculatedCols.getCols(timeOffList?.length ?? 0),
            sm: calculatedCols.getCols(timeOffList?.length ?? 0, 2),
          }}
          gap={10}
        >
          {_.map(timeOffList, (timeOff) => (
            <Flex
              gap={5}
              wrap="nowrap"
              key={timeOff?.date}
              justify="flex-start"
            >
              <Text fz="sm" fw={500}>
                {timeOff?.date}
              </Text>
              <Pill bg="var(--mantine-color-gray-2)" w="120px">
                {timeOff?.hours} hours
              </Pill>
            </Flex>
          ))}
        </SimpleGrid>
      </Flex>
      <Divider />
    </Stack>
  );
}

TimeOffList.propTypes = {
  timeOffList: PropTypes.array,
};

TimeOffList.defaultProps = {
  timeOffList: [],
};
