import { TextInput } from '@mantine/core';
import PropTypes from 'prop-types';

export default function TextFormField({ field, onChange, value, error }) {
  return (
    <TextInput
      label={field?.displayName}
      placeholder={field?.displayName}
      value={value ?? ''}
      onChange={onChange}
      error={error}
      required={field?.validation?.isRequired}
    />
  );
}

TextFormField.propTypes = {
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  error: PropTypes.any,
};
TextFormField.defaultProps = {
  value: '',
  error: null,
};
