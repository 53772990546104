import {
  Button,
  Divider,
  Flex,
  Group,
  Modal,
  NumberInput,
  Pill,
  Stack,
  Text,
  TextInput,
  Textarea,
  Title,
  rem,
} from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { IconPhoto, IconX, IconUpload } from '@tabler/icons-react';
import { DateFormInput } from 'components/DateFormInput';
import PropTypes from 'prop-types';
import { datesValidators, genericValidators } from 'utils';

export default function CreateTrainingRecordModal({
  opened,
  onClose,
  record,
  onSubmit,
  isLoading,
}) {
  const form = useForm({
    initialValues: {
      completeDate: record?.complete_date ?? '',
      cost: record?.cost ?? 0,
      notes: record?.notes ?? '',
      instructor: record?.instructor ?? '',
      file: null,
    },
    validate: {
      completeDate: (value) =>
        value instanceof Date
          ? datesValidators.isValidDate(value, 'Complete date is empty')
          : genericValidators.notEmpty(value, 'Complete date is empty'),
    },
  });

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Record a completed training"
      centered
    >
      <Title fz="xl">{record?.name}</Title>
      <form
        onSubmit={form.onSubmit(() =>
          onSubmit({
            ...record,
            ...form.values,
            modalType: record?.modalType,
          }),
        )}
      >
        <Stack gap={10}>
          <DateFormInput
            field={{
              displayName: 'Complete date',
              placeholder: 'Select complete date',
            }}
            onChange={(val) => {
              form.setFieldValue('completeDate', val);
            }}
            error={form.errors.completeDate}
            value={form.values.completeDate}
            minDate={
              record?.modalType === 'Create'
                ? new Date(record?.start_date)
                : new Date(record?.training?.created_at)
            }
          />
          <NumberInput
            label="Cost"
            onChange={(value) => {
              form.setFieldValue('cost', value);
            }}
            value={form.values.cost}
            error={form.errors.cost}
            min={0}
            max={100000}
            clampBehavior="strict"
            allowNegative={false}
            rightSection={
              <Flex gap={5} pr={15}>
                <Divider orientation="vertical" />
                USD
              </Flex>
            }
          />
          <TextInput
            label="Instructor"
            error={form.errors.instructor}
            value={form.values.instructor}
            onChange={(event) =>
              form.setFieldValue('instructor', event.target.value)
            }
          />
          <Textarea
            label="Notes"
            error={form.errors.notes}
            value={form.values.notes}
            onChange={(event) =>
              form.setFieldValue('notes', event.target.value)
            }
          />
          <Dropzone
            onDrop={(files) => form.setFieldValue('file', files[0])}
            maxSize={20 * 1024 ** 2}
          >
            <Group
              justify="center"
              align="center"
              gap="xl"
              mih={130}
              style={{ pointerEvents: 'none' }}
            >
              <Dropzone.Accept>
                <IconUpload
                  style={{
                    width: rem(52),
                    height: rem(52),
                    color: 'var(--mantine-color-blue-6)',
                  }}
                  stroke={1.5}
                />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <IconX
                  style={{
                    width: rem(52),
                    height: rem(52),
                    color: 'var(--mantine-color-red-6)',
                  }}
                  stroke={1.5}
                />
              </Dropzone.Reject>
              <Dropzone.Idle>
                <IconPhoto
                  style={{
                    width: rem(52),
                    height: rem(52),
                    color: 'var(--mantine-color-dimmed)',
                  }}
                  stroke={1.5}
                />
              </Dropzone.Idle>

              <Stack align="center">
                <Text size="xl" inline>
                  Drag files here or click to select files
                </Text>
                <Text size="sm" c="dimmed" inline>
                  File should not exceed 20mb
                </Text>
              </Stack>
            </Group>
            <Flex justify="center" mt={5}>
              {form.values.file && (
                <Pill
                  withRemoveButton
                  onRemove={() => form.setFieldValue('file', null)}
                >
                  {form.values.file.name}
                </Pill>
              )}
            </Flex>
          </Dropzone>
          <Button disabled={isLoading || !opened} type="submit">
            Submit
          </Button>
        </Stack>
      </form>
    </Modal>
  );
}

CreateTrainingRecordModal.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  record: PropTypes.object,
  isLoading: PropTypes.bool,
};

CreateTrainingRecordModal.defaultProps = {
  opened: false,
  isLoading: false,
  onClose: () => {},
  onSubmit: () => {},
  record: {},
};
