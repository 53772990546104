import { Button, Grid, Stack, Tabs } from '@mantine/core';

import { PageWrapper } from 'components/PageWrapper';
import PropTypes from 'prop-types';
import { hideForNow } from 'utils/constants';
import { usePageTitle } from 'utils/hooks';

import { useDisclosure } from '@mantine/hooks';
import { IconApps } from '@tabler/icons-react';
import { clearCache } from 'api/clearCache';
import { PermissionGuard } from 'components/PermissionGuard';
import { usePermissions } from 'components/PermissionGuard/hooks';
import { TabList, TabsCore } from 'components/Tabs';
import { WidgetSettings } from 'components/WidgetSettings';
import { useQueryClient } from 'react-query';
import {
  AdminSection,
  EmployeeSection,
  HiringSection,
  RequestSection,
} from './sections';

export default function Home({ globalTabName }) {
  const queryClient = useQueryClient();

  const [
    openedWidgetSettings,
    { open: openWidgetSettings, close: closeWidgetSettings },
  ] = useDisclosure(false);

  usePageTitle('Home', globalTabName);

  const onCloseWidgetSettings = () => {
    clearCache.onChangeWidget(queryClient);
    closeWidgetSettings();
  };

  const { permissions } = usePermissions();

  return (
    <PageWrapper
      title="Home"
      withFlexStyles
      rightSection={
        <PermissionGuard permission={permissions.canViewEmployees}>
          <Button onClick={openWidgetSettings} leftSection={<IconApps />}>
            Edit
          </Button>
        </PermissionGuard>
      }
    >
      <TabsCore defaultValue="controlCenter">
        <TabList>
          <Tabs.Tab value="controlCenter">Control Center</Tabs.Tab>
          <Tabs.Tab value="onboarding">Onboarding</Tabs.Tab>
        </TabList>
        <Tabs.Panel value="controlCenter">
          <WidgetSettings
            opened={openedWidgetSettings}
            onClose={onCloseWidgetSettings}
          />
          <Stack gap="md" mt="sm" pb="sm">
            <EmployeeSection />
            {hideForNow && (
              <>
                <RequestSection />
                <HiringSection />
                <AdminSection />
              </>
            )}
          </Stack>
        </Tabs.Panel>
        <Tabs.Panel value="onboarding">
          <Grid>
            <Grid.Col mt="0" span={12}>
              Onboarding container
            </Grid.Col>
          </Grid>
        </Tabs.Panel>
      </TabsCore>
    </PageWrapper>
  );
}

Home.propTypes = {
  globalTabName: PropTypes.string,
};

Home.defaultProps = {
  globalTabName: '',
};
