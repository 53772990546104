import { Modal } from '@mantine/core';
import { AddEditHolidayForm } from 'components/AddEditHolidayForm';
import PropTypes from 'prop-types';

export default function AddEditHolidayModal({
  opened,
  onClose,
  onEdit,
  onCreate,
  isLoading,
  holidayState,
}) {
  const isEdit = holidayState?.modalType === 'Edit';

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={`${isEdit ? 'Edit' : 'Create'} holiday`}
      centered
    >
      <AddEditHolidayForm
        onSubmit={isEdit ? onEdit : onCreate}
        holidayState={holidayState}
        isLoading={isLoading}
      />
    </Modal>
  );
}

AddEditHolidayModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  holidayState: PropTypes.object,
};

AddEditHolidayModal.defaultProps = {
  holidayState: {},
};
