import { Container, Text } from '@mantine/core';
import { useLoadPolicy, useUpdateTimeOffPolicy } from 'api/hooks';
import { Loader } from 'components/Loader';
import { PageWrapper } from 'components/PageWrapper';
import TimeOffPolicyForm from 'components/TimeOff/forms/TimeOffPolicyForm';
import { useTimeOffGroupedPolicies } from 'components/TimeOff/hooks';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { useQueryClient } from 'react-query';
import { clearCache } from 'api/clearCache';

export default function PolicyEdit({ params }) {
  const { setNotifications } = useNotifications();

  const { policyId } = params;
  const navigate = useNavigate();

  const { categories, isPoliciesLoading, isCategoriesLoading } =
    useTimeOffGroupedPolicies();

  const queryClient = useQueryClient();

  const {
    policy,
    isLoading: isPolicyLoading,
    error: loadedPolicyError,
  } = useLoadPolicy(policyId);

  const { updatePolicy, isLoading: isUpdatingPolicy } = useUpdateTimeOffPolicy({
    onSuccess: () => {
      clearCache.onTimeOffPolicyChange(queryClient);
      setNotifications(['Policy successfully updated']);
      navigate('/settings/time-off');
    },
  });

  const isLoading = isPoliciesLoading || isCategoriesLoading;

  const unSubmitPolicy = (payload) => {
    if (!_.isEmpty(policy)) {
      updatePolicy({
        id: policy.id,
        payload: { ...payload, type: policy?.type },
      });
    }
  };

  return (
    <PageWrapper title="Edit Time Off policy">
      <Loader isLoading={isPolicyLoading} />
      {!_.isEmpty(policy) && !isLoading && (
        <Container px={0} size="md">
          <TimeOffPolicyForm
            policy={policy}
            isLoading={isLoading || isUpdatingPolicy}
            onSubmit={unSubmitPolicy}
            categories={categories}
            selectedPolicyType={String(policy?.type)}
          />
        </Container>
      )}
      {loadedPolicyError && (
        <Text ta="center" fw="600" fz="20">
          Policy Not Found
        </Text>
      )}
    </PageWrapper>
  );
}

PolicyEdit.propTypes = {
  params: PropTypes.object,
};

PolicyEdit.defaultProps = {
  params: null,
};
