import { Flex, Stack, Text } from '@mantine/core';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

export default function YearStats({ yearStats, range }) {
  const totalEmployees = useMemo(() => {
    const annualStats = yearStats?.annual_stats;
    return _.keys(annualStats)
      .filter(Boolean)
      .map((year) => ({ year, employeeCount: annualStats[year] }));
  }, [yearStats?.annual_stats]);

  return (
    <Stack gap={0} mt={27} px={20}>
      <Text>
        {range?.from && range?.to
          ? `${moment(range.from).format('DD MMM YY')} - ${moment(
              range.to,
            ).format('DD MMM YY')}`
          : ''}
      </Text>
      <Flex gap={5}>
        <Text fz={30} fw="bold">
          {yearStats?.final_count}
        </Text>
        <Stack gap={0}>
          <Flex gap={2} c={yearStats?.difference >= 0 ? 'green' : 'red'}>
            <Text fz={13}>
              {yearStats?.difference >= 0 && '+'}
              {yearStats?.difference}
            </Text>

            <Text fz={13} c="gray">
              ({yearStats?.percentage_change}%)
            </Text>
          </Flex>
        </Stack>
      </Flex>
      <Text fz="xl" fw="600">
        Total Employees
      </Text>
      <Stack gap={0}>
        {_.map(totalEmployees, (employeeStat) => (
          <Text key={employeeStat.year}>
            {employeeStat.year}: {employeeStat.employeeCount}
          </Text>
        ))}
      </Stack>
    </Stack>
  );
}

YearStats.propTypes = {
  yearStats: PropTypes.object.isRequired,
  range: PropTypes.object,
};

YearStats.defaultProps = {
  range: {},
};
