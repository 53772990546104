import { Modal, Table, Text } from '@mantine/core';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import _ from 'lodash';
import PropTypes from 'prop-types';

export default function CompletedSurveyPreviewModal({
  opened,
  onClose,
  responses,
}) {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      size="lg"
      title="Survey Response Preview"
      styles={{ title: { fontWeight: 500, fontSize: 19 } }}
    >
      <EmptyDataMessage isVisible={_.isEmpty(responses)} />
      {!_.isEmpty(responses) && (
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Question</Table.Th>
              <Table.Th>Response</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {_.map(responses, (response) => {
              const key = `${response?.title} ${response?.response} ${response?.field_type}`;
              return (
                <Table.Tr key={key}>
                  <Table.Td>
                    <Text
                      style={{
                        textTransform: 'capitalize',
                        border: '1px solid var(--mantine-color-gray-2)',
                        background: 'var(--mantine-color-gray-1)',
                        borderRadius: '10px',
                      }}
                      px={10}
                      maw="60%"
                    >
                      {response?.title}
                    </Text>
                  </Table.Td>
                  <Table.Td>
                    <Text
                      style={{
                        whiteSpace: 'pre-wrap',
                        textTransform: 'capitalize',
                        borderRadius: '10px',
                        maxWidth: 'max-content',
                      }}
                    >
                      {response?.response}
                    </Text>
                  </Table.Td>
                </Table.Tr>
              );
            })}
          </Table.Tbody>
        </Table>
      )}
    </Modal>
  );
}

CompletedSurveyPreviewModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  responses: PropTypes.array,
};

CompletedSurveyPreviewModal.defaultProps = {
  responses: [],
};
