import { Avatar, Card, Group, Stack, Table, Text, rem } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import { useAuthContext } from 'auth';
import { usePermissions } from 'components/PermissionGuard/hooks';
import AllRequests from 'modules/requests/components/AllRequests';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { requestsCategories } from 'utils/constants';
import classes from '../../Home.module.css';

export default function WhatsHappening() {
  const { user } = useAuthContext();

  const { permissions, hasPermission } = usePermissions();

  const navigate = useNavigate();

  const navigateTo = (id, type) => {
    navigate(`/requests/all-requests/${requestsCategories[type]}/${id}`);
  };

  return (
    <Card p={0} pb="xl" withBorder radius="md" className={classes.card}>
      <Text
        bg="var(--mantine-color-blue-6)"
        fz="xl"
        p="xl"
        className={classes.label}
        c="white"
      >
        What is happening
      </Text>
      <Stack px="xl" gap={0} h="100%" className={classes.card__content}>
        <AllRequests
          onPreview={(data) => navigateTo(data?.id, data?.category)}
          payload={{
            Performance: {
              assignee_id: user?.id,
              status: [0],
            },
            TimeoffRecords: {
              assignee_id: hasPermission(permissions.canManageEmployee)
                ? ''
                : user?.id,
            },
            Signature: {
              assignee_id: user?.id,
            },
            status: [0],
          }}
          limit={5}
          displayStatus={false}
        />
      </Stack>
    </Card>
  );
}

function Row({ name, dateDescription, avatar }) {
  return (
    <Table.Tr key="1" className={classes.pointer}>
      <Table.Td>
        <Group>
          <Avatar size={50} src={avatar} radius={36} />
          <div>
            <Group>
              <Text size="sm">{name}</Text>
            </Group>
            <Text size="xs" c="dimmed">
              {dateDescription}
            </Text>
          </div>
        </Group>
      </Table.Td>
      <Table.Td className={classes.putRight}>
        <IconArrowRight
          style={{ width: rem(16), height: rem(16) }}
          stroke={1.5}
        />
      </Table.Td>
    </Table.Tr>
  );
}

Row.propTypes = {
  name: PropTypes.string.isRequired,
  dateDescription: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
};
