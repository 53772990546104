import { Modal } from '@mantine/core';
import PropTypes from 'prop-types';
import TimeOffRecordForm from '../forms/TimeOffRecordForm';
import TimeOffEditRecordForm from '../forms/TimeOffEditRecordForm';

export default function TimeOffRecordModal({
  isLoading,
  opened,
  onClose,
  onSubmit,
  policies,
  record,
}) {
  const isEditMode = Boolean(record);
  return (
    <Modal
      opened={opened}
      onClose={() => {
        onClose();
      }}
      title={record ? 'Edit Record' : 'Create Record'}
      centered
    >
      {!isEditMode && (
        <TimeOffRecordForm
          policies={policies}
          isLoading={isLoading}
          onSubmit={onSubmit}
        />
      )}
      {isEditMode && (
        <TimeOffEditRecordForm
          record={record}
          onSubmit={onSubmit}
          isLoading={isLoading}
        />
      )}
    </Modal>
  );
}

TimeOffRecordModal.propTypes = {
  isLoading: PropTypes.bool,
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  policies: PropTypes.array,
  record: PropTypes.object,
};

TimeOffRecordModal.defaultProps = {
  isLoading: false,
  opened: false,
  onClose: () => {},
  onSubmit: () => {},
  policies: [],
  record: null,
};
