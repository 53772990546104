import React from 'react';
import PropTypes from 'prop-types';
import { CandidateSendEmailForm } from 'components/CandidateSendEmailForm';
import { TemplateModal } from 'components/TemplateModal';

export default function CandidateSendEmailModal({
  opened,
  onClose,
  candidateInitials,
  onSubmit,
  isLoading,
}) {
  return (
    <TemplateModal
      opened={opened}
      onClose={onClose}
      title={`Send an Email to ${candidateInitials}`}
    >
      <CandidateSendEmailForm
        onSubmit={onSubmit}
        isLoading={isLoading || !opened}
      />
    </TemplateModal>
  );
}

CandidateSendEmailModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  candidateInitials: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

CandidateSendEmailModal.defaultProps = {
  isLoading: false,
};
