import { useCallback } from 'react';

import { useAuthContext } from 'auth';

export const roles = {
  HR: 'HR',
  ADMIN: 'superadmin',
  EMPLOYEE: 'employee',
};

const permissions = {
  canAuthenticate: 'can-login-logout',
  canManageAccount: 'can-manage-account',
  canViewEmployees: 'can-view-employees',
  canManageEmployee: 'can-manage-employee',
  canManageRequests: 'can-manage-my-requests',
  canAccessHiring: 'can-access-hiring',
  canAccessReports: 'can-access-reports',
  canAccessSettings: 'can-access-settings',
  canAccessAdmin: 'can-access-administration',
  canViewMyProfile: 'can-view-my-profile',
};

export const usePermissions = () => {
  const { user } = useAuthContext();

  const hasPermission = useCallback(
    (permission) => user?.permissions?.includes(permission),
    [user],
  );
  return { role: user?.roles?.[0], hasPermission, permissions, roles };
};
