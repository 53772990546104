import _ from 'lodash';
import { useCallback, useState } from 'react';
import { useFormattedFilters } from './useFormattedFilters';

export function useEmployeeFilter({ initialValues = {} } = {}) {
  const [selectedValues, setSelectedValues] = useState(initialValues);
  const { filteredData, isLookupsLoading } = useFormattedFilters({
    selectedValues,
  });

  const onChangeEmployeeFilter = useCallback(
    ({ type, value, id, name }, onChange = null) => {
      setSelectedValues((prevValues) => {
        const formattedArray = value
          ? (prevValues?.[type] ?? []).concat({ id, name, value: true })
          : _.filter(prevValues?.[type], (item) => item.id !== id).concat({
              id,
              name,
              value: false,
            });

        // todo filter by value
        const result = { ...prevValues, [type]: formattedArray };
        onChange?.(result);
        return result;
      });
    },
    [],
  );

  const clearEmployeeFilter = useCallback(() => setSelectedValues({}), []);

  return {
    employeeFilters: filteredData,
    isLookupsLoading,
    onChangeEmployeeFilter,
    selectedValues,
    clearEmployeeFilter,
  };
}
