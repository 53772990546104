import { Button, Flex, LoadingOverlay, Space, Text } from '@mantine/core';
import { IconArrowCapsule } from '@tabler/icons-react';

import { modals } from '@mantine/modals';
import {
  useCreatePerformanceCycle,
  useDeletePerformanceCycle,
  usePerformanceCycles,
} from 'api/hooks';
import {
  PerformanceCycleModal,
  PerformanceManagementTable,
} from 'components/PerformanceManagement';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { tabNames } from 'utils/constants';
import { useStateDisclosure, useTabNavigate } from 'utils/hooks';

export default function PerformanceManagementTab() {
  const { setNotifications } = useNotifications();
  const navigate = useTabNavigate();

  const [isCycleModalOpened, { open: openCycleModal, close: closeCycleModal }] =
    useStateDisclosure(false);
  const {
    performanceCycles,
    isLoading,
    refetch: refetchCycles,
  } = usePerformanceCycles();

  const { createCycle, isLoading: isCreatingCycle } = useCreatePerformanceCycle(
    {
      onSuccess: () => {
        refetchCycles();
        closeCycleModal();
        setNotifications(['Performance Cycle successfully created']);
      },
    },
  );

  const {
    deleteCycle,
    isLoading: isDeletingCycle,
    variables: deleteCycleId,
  } = useDeletePerformanceCycle({
    onSuccess: () => {
      refetchCycles();
      setNotifications(['Performance Cycle successfully deleted']);
    },
  });

  const onCycleSubmit = (values) => {
    createCycle(values);
  };

  const onCycleEdit = (cycle) => {
    navigate(`/settings/performance-management/${cycle.id}/edit`, {
      tabName: tabNames.settingsPerformanceManagement,
    });
  };
  const onDeleteCycle = (cycle) => {
    modals.openConfirmModal({
      title: 'Delete Performance Cycle',
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete performance cycle{' '}
          <Text span fw={500}>
            {cycle.name}
          </Text>
        </Text>
      ),
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: () => deleteCycle(cycle.id),
    });
  };
  return (
    <>
      <LoadingOverlay
        visible={isLoading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <PerformanceCycleModal
        onSubmit={onCycleSubmit}
        isLoading={isCreatingCycle}
        opened={isCycleModalOpened}
        onClose={() => closeCycleModal()}
      />
      <Space h="md" />
      <Flex gap="md" justify="flex-end" align="center">
        <Button
          onClick={() => openCycleModal()}
          leftSection={<IconArrowCapsule size={20} />}
          disabled={isLoading}
        >
          Create Review Cycle
        </Button>
      </Flex>
      <Space h="xxl" />
      <PerformanceManagementTable
        performanceCycles={performanceCycles}
        onEditCycle={onCycleEdit}
        onDeleteCycle={onDeleteCycle}
        deleteState={{ isDeleting: isDeletingCycle, itemId: deleteCycleId }}
      />
    </>
  );
}
