import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Stack, Text, Title } from '@mantine/core';
import { IconDiscountCheck } from '@tabler/icons-react';
import { EmployeeForContact } from 'components/EmployeeForContact';

export default function SignedOfferState({ candidateOffer }) {
  return (
    <Stack align="center" justify="center">
      <IconDiscountCheck
        fill="var(--mantine-color-green-9)"
        size={150}
        color="white"
        stroke={1}
      />
      <Title c="var(--mantine-color-green-9)">
        Congratulations, {candidateOffer?.candidate?.first_name}{' '}
        {candidateOffer?.candidate?.last_name}
      </Title>
      <Text ta="center">
        We&apos;ve emailed you a copy of your signed offer letter, please get in
        touch with Test if you have any questions. We can&apos;t wait to have
        you as a part of the team!
      </Text>
      <Paper bg="var(--mantine-color-gray-1)" p={15} withBorder>
        <EmployeeForContact employee={candidateOffer?.contact} />
      </Paper>
    </Stack>
  );
}

SignedOfferState.propTypes = {
  candidateOffer: PropTypes.object.isRequired,
};
