import { Box, Divider, Flex, Loader, Stack, Text, Title } from '@mantine/core';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { useParams } from 'react-router-dom';
import { useSignCandidateOffer } from 'api/hooks';

export default function OfferLetterAcceptance({
  candidate,
  signStatus,
  toggleSignStatus,
}) {
  const { offerHash } = useParams();
  const { setNotifications } = useNotifications();

  const { signCandidateOffer, isLoading } = useSignCandidateOffer({
    onSuccess: () => {
      setNotifications(['Offer Signed Successfully!']);
      toggleSignStatus();
    },
  });

  return (
    <Stack w="50%" gap={5}>
      <Title fz="lg">Offer Letter Acceptance</Title>
      <Text>I have read and accept this offer of employment:</Text>
      <Box
        p={6}
        style={{
          border: `1px solid ${
            signStatus === 'pending'
              ? 'var(--mantine-color-gray-4)'
              : 'var(--mantine-color-green-6)'
          }`,
          color:
            signStatus === 'pending'
              ? 'var(--mantine-color-gray-6)'
              : 'var(--mantine-color-green-6)',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
        w="50%"
        onClick={() => {
          if (signStatus === 'pending' && !isLoading) {
            signCandidateOffer(offerHash);
          }
        }}
      >
        <Flex justify="space-between" align="center">
          {signStatus === 'pending' ? 'Click to Sign...' : 'Signed'}
          {isLoading && <Loader size={20} />}
        </Flex>
      </Box>
      <Divider w="50%" color="var(--mantine-color-black)" />
      <Text mt={-3} fz="sm">
        {candidate?.full_name}
      </Text>
      <Text>{moment().format('YYYY-MM-DD')}</Text>
      <Divider w="50%" mt={-4} color="var(--mantine-color-black)" />
      <Text mt={-3} fz="sm">
        Today&apos;s Date
      </Text>
    </Stack>
  );
}

OfferLetterAcceptance.propTypes = {
  candidate: PropTypes.object.isRequired,
  signStatus: PropTypes.string.isRequired,
  toggleSignStatus: PropTypes.func.isRequired,
};
