import { Table } from '@mantine/core';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import { SortTh } from 'components/SortTh';
import _ from 'lodash';
import PropTypes from 'prop-types';

export default function HeadCountTable({
  headCountList,
  sort,
  setSort,
  isLoading,
}) {
  return (
    <>
      {_.isEmpty(headCountList) && !isLoading && <EmptyDataMessage isVisible />}
      <Table>
        <Table.Thead>
          {!_.isEmpty(headCountList) && (
            <Table.Tr>
              <Table.Th>Name</Table.Th>
              <SortTh
                label="Hire Date"
                field="hire_date"
                sorted={sort}
                setSorted={setSort}
              />
              <Table.Th>Job Title</Table.Th>
              <Table.Th>Department</Table.Th>
              <Table.Th>Location</Table.Th>
            </Table.Tr>
          )}
        </Table.Thead>
        <Table.Tbody>
          {_.map(headCountList, (headCount) => (
            <Table.Tr key={headCount?.id}>
              <Table.Td>{headCount?.full_name}</Table.Td>
              <Table.Td>{headCount?.hire_date}</Table.Td>
              <Table.Td>
                {headCount?.current_job_information?.job_title?.name}
              </Table.Td>
              <Table.Td>
                {headCount?.current_job_information?.department?.name}
              </Table.Td>
              <Table.Td>
                {headCount?.current_job_information?.location?.name}
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </>
  );
}

HeadCountTable.propTypes = {
  headCountList: PropTypes.array,
  sort: PropTypes.object.isRequired,
  setSort: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

HeadCountTable.defaultProps = {
  headCountList: [],
  isLoading: false,
};
