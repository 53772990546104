import React from 'react';
import PropTypes from 'prop-types';
import { OfferTemplateForm } from 'components/OfferTemplateForm';
import { TemplateModal } from 'components/TemplateModal';

export default function CreateOfferTemplateModal({
  opened,
  onClose,
  offerState,
  onCreate,
  onEdit,
  isLoading,
}) {
  return (
    <TemplateModal
      opened={opened}
      onClose={onClose}
      title={`${offerState?.modalType ?? 'Create'} Offer Template`}
    >
      <OfferTemplateForm
        offerState={offerState}
        isLoading={isLoading || !opened}
        onSubmit={offerState?.modalType === 'Edit' ? onEdit : onCreate}
      />
    </TemplateModal>
  );
}

CreateOfferTemplateModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  offerState: PropTypes.object,
  onCreate: PropTypes.func,
  onEdit: PropTypes.func,
  isLoading: PropTypes.bool,
};

CreateOfferTemplateModal.defaultProps = {
  offerState: {},
  onCreate: () => {},
  onEdit: () => {},
  isLoading: false,
};
