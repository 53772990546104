import { Flex, Stack, Text } from '@mantine/core';
import { IconCalendarStar } from '@tabler/icons-react';
import { useLoadEmployeeHolidays } from 'api/hooks';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import _ from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import { WidgetWrapper } from '.';

export default function CompanyHolidays(rest) {
  const { holidays, isLoading: isHolidaysLoading } = useLoadEmployeeHolidays({
    filter: {
      byYear: moment().year(),
    },
    sortBy: '-date_from',
  });

  const formattedHolidays = useMemo(() => {
    const currentDate = moment().startOf('day');

    return holidays
      ?.filter((holiday) =>
        moment(holiday?.date_to).startOf('day').isSameOrAfter(currentDate),
      )
      ?.map((holiday) => ({
        ...holiday,
        date_from: moment(holiday?.date_from).format('MM-DD (dddd)'),
        date_to: moment(holiday?.date_to).format('MM-DD (dddd)'),
      }));
  }, [holidays]);

  return (
    <WidgetWrapper
      title="Company Holidays"
      Icon={<IconCalendarStar size={30} color="white" />}
      isLoading={isHolidaysLoading}
      {...rest}
    >
      <EmptyDataMessage
        isVisible={!isHolidaysLoading && _.isEmpty(formattedHolidays)}
        m="auto"
        message="There are no upcoming holidays this year"
        ta="center"
      />
      {formattedHolidays?.map((item) => (
        <Flex gap={10} align="center" key={item?.id}>
          <IconCalendarStar size={30} />
          <Stack gap={0}>
            <Text>{item?.name}</Text>
            <Text>
              {item?.date_from === item?.date_to
                ? item?.date_from
                : `${item?.date_from} - ${item?.date_to}`}
            </Text>
          </Stack>
        </Flex>
      ))}
    </WidgetWrapper>
  );
}
