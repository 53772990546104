import { Switch, rem, useMantineColorScheme } from '@mantine/core';
import { IconMoonStars, IconSun } from '@tabler/icons-react';
import { themes } from 'utils';

export default function ThemeSwitcher() {
  const { toggleColorScheme, colorScheme } = useMantineColorScheme();

  const sunIcon = (
    <IconSun
      style={{
        width: rem(16),
        height: rem(16),
        stroke: 'var(--mantine-color-yellow-5)',
      }}
      stroke={2.5}
    />
  );

  const moonIcon = (
    <IconMoonStars style={{ width: rem(16), height: rem(16) }} stroke={2.5} />
  );

  return (
    <Switch
      size="md"
      onLabel={sunIcon}
      offLabel={moonIcon}
      onChange={toggleColorScheme}
      checked={colorScheme === themes.light}
    />
  );
}
