import { Container, Text } from '@mantine/core';
import PropTypes from 'prop-types';
import { requestStatuses } from 'utils/constants';
import { EmployeeList } from './components';
import EmployeeSelectForm from './components/EmployeeSelectForm';

export default function PeerFeedbackEmployeeList({ employeeId, request }) {
  return (
    <Container>
      {request?.status === requestStatuses.Pending && (
        <Text fw={500} fz={18} ta="center" mb={10}>
          Please select employees who will give feedback, about your
          subordinates.
        </Text>
      )}
      {request?.status === requestStatuses.Completed && (
        <EmployeeList request={request} />
      )}
      {request?.status === requestStatuses.Pending && (
        <EmployeeSelectForm employeeId={employeeId} request={request} />
      )}
    </Container>
  );
}

PeerFeedbackEmployeeList.propTypes = {
  employeeId: PropTypes.number.isRequired,
  request: PropTypes.object.isRequired,
};
