import { Modal } from '@mantine/core';
import { useLoadLookups, useLoadReasons } from 'api/hooks';
import { ChangeEmployeeStatusForm } from 'components/ChangeEmployeeStatusForm';
import PropTypes from 'prop-types';
import {
  employeeReasons,
  lookupsFilters,
  lookupsModels,
} from 'utils/constants';

export default function ChangeEmployeeStatusModal({
  opened,
  onClose,
  onCreate,
  onEdit,
  status,
  isLoading,
  statuses,
  isStatusesLoading,
}) {
  const { lookups } = useLoadLookups({
    models: [lookupsModels.employeeStatus],
    filters: {
      [lookupsModels.employeeStatus]: [
        lookupsFilters[lookupsModels.employeeStatus].getTerminationFields,
      ],
    },
  });

  const { reasons, isLoading: isReasonsLoading } = useLoadReasons(
    {
      params: { filter: { byType: employeeReasons.termination }, withCount: 1 },
    },
    { enabled: opened },
  );

  return (
    <Modal
      centered
      opened={opened}
      onClose={onClose}
      title="Update Employee Status"
    >
      {!isStatusesLoading && (
        <ChangeEmployeeStatusForm
          onSubmit={status?.modalType === 'Edit' ? onEdit : onCreate}
          status={status}
          statuses={statuses}
          isLoading={isLoading || !opened || isReasonsLoading}
          terminationTypes={
            lookups?.[lookupsModels.employeeStatus]?.termination_type
          }
          terminationRegrettable={
            lookups?.[lookupsModels.employeeStatus]?.termination_regrettable
          }
          terminationReasons={reasons}
        />
      )}
    </Modal>
  );
}

ChangeEmployeeStatusModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
  onEdit: PropTypes.func,
  status: PropTypes.object,
  isLoading: PropTypes.bool,
  statuses: PropTypes.array,
  isStatusesLoading: PropTypes.bool,
};

ChangeEmployeeStatusModal.defaultProps = {
  status: {},
  onEdit: () => {},
  onCreate: () => {},
  isLoading: false,
  statuses: [],
  isStatusesLoading: false,
};
