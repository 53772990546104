import { Box, Flex, MultiSelect } from '@mantine/core';
import { useLoadCalendarData, useLoadCalendarTypes } from 'api/hooks';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import { calendarEventTypeLabels, calendarEventTypes } from 'utils/constants';
import { usePageTitle } from 'utils/hooks';
import { EventWrapper } from './components';
import { eventColors } from './components/EventWrapper';
import './style.css';

const localizer = momentLocalizer(moment);

const getDateSlice = (date) => {
  const startDate = moment(date)
    .add(-1, 'months')
    .startOf('month')
    .format('YYYY-MM-DD');
  const endDate = moment(date)
    .add(1, 'months')
    .endOf('month')
    .format('YYYY-MM-DD');

  return { start_date: startDate, end_date: endDate };
};

export default function Calendar({ globalTabName }) {
  const [selectedTypes, setSelectedTypes] = useState([]);

  const { eventTypes } = useLoadCalendarTypes({
    onSuccess: (data) => setSelectedTypes(data),
  });

  const [currentDate, setCurrentDate] = useState(moment().toDate());

  const { calendarData } = useLoadCalendarData({
    ...getDateSlice(currentDate),
    event_types: selectedTypes,
    enabled: !_.isEmpty(selectedTypes),
  });

  const events = useMemo(() => {
    if (_.isEmpty(calendarData)) return [];

    const formattedEvents = calendarData?.map((item) => ({
      ...item,
      title: `${item?.employees?.first_name} ${item?.employees?.last_name}`,
      start: moment(item?.start_date).toDate(),
      // We add one day. The last day is not inclusive.
      end: moment(item?.end_date).add(1, 'days').toDate(),
    }));

    return formattedEvents;
  }, [calendarData]);

  usePageTitle('Calendar', globalTabName);

  return (
    <Box mt="10" miw="1124" w="100%" mx="auto" maw={1500}>
      <MultiSelect
        mb={10}
        flex={1}
        data={eventTypes?.map((item) => ({
          value: item,
          label: calendarEventTypeLabels[item] ?? '',
        }))}
        value={selectedTypes}
        placeholder="Types"
        label="Select Types"
        onChange={setSelectedTypes}
        maw="350"
        styles={{
          pillsList: { flexWrap: 'nowrap', overflow: 'hidden' },
        }}
      />
      <BigCalendar
        events={events}
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        style={{ height: '70vh' }}
        onNavigate={setCurrentDate}
        components={{ eventWrapper: EventWrapper }}
      />
      <Flex mt={10} gap={20}>
        {Object.values(calendarEventTypes).map((eventType) => (
          <Flex gap={5} key={eventType} align="center">
            <Box
              w={20}
              h={20}
              bg={eventColors[eventType].boxBg ?? eventColors.default}
              style={{ borderRadius: '50%' }}
            />
            {calendarEventTypeLabels[eventType] ?? ''}
          </Flex>
        ))}
      </Flex>
    </Box>
  );
}

Calendar.propTypes = {
  globalTabName: PropTypes.string,
};

Calendar.defaultProps = {
  globalTabName: '',
};
