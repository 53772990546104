import { Button, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';
import { genericValidators } from 'utils';
import PropTypes from 'prop-types';

export default function CreateBoardingForm({ listState, onSubmit, isLoading }) {
  const form = useForm({
    initialValues: {
      name: listState?.name ?? '',
    },
    validate: {
      name: (value) =>
        genericValidators.notEmpty(value, 'Name is empty') ||
        genericValidators.lessThanOrEqualTo(
          value,
          50,
          'Name must be less than 50 chars',
        ),
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)} noValidate>
      <Stack>
        <TextInput
          value={form.values.name}
          onChange={(event) =>
            form.setFieldValue('name', event.currentTarget.value)
          }
          error={form.errors.name}
          label="Name"
        />
        <Button disabled={isLoading} type="submit">
          Submit
        </Button>
      </Stack>
    </form>
  );
}

CreateBoardingForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  listState: PropTypes.object,
  isLoading: PropTypes.bool,
};

CreateBoardingForm.defaultProps = {
  listState: {},
  isLoading: false,
};
