import { Flex, Stack } from '@mantine/core';
import { Skeleton } from 'components/Skeleton';
import PropTypes from 'prop-types';

export default function IntegrationsSkeleton({ isVisible }) {
  if (!isVisible) return null;

  return (
    <Stack gap={10}>
      <Skeleton skeletonWrapperProps={{ w: 150, h: 25 }} isVisible />
      <Flex gap={20}>
        <Skeleton
          isVisible
          skeletonWrapperProps={{ ml: 20 }}
          skeletonProps={{ w: 150, h: 150 }}
        />
        <Stack gap={20} justify="space-around">
          <Skeleton
            isVisible
            skeletonWrapperProps={{ w: 250, mt: 'auto' }}
            skeletonProps={{ h: 30 }}
          />
          <Skeleton
            isVisible
            skeletonWrapperProps={{ w: 100, mb: 'auto' }}
            skeletonProps={{ h: 40 }}
          />
        </Stack>
      </Flex>
      <Skeleton skeletonWrapperProps={{ w: 150, h: 25 }} isVisible />
      {Array.from({ length: 3 }).map((i, index) => (
        // eslint-disable-next-line
        <Flex gap={20} key={index}>
          <Skeleton
            isVisible
            skeletonWrapperProps={{ ml: 20 }}
            skeletonProps={{ w: 150, h: 150 }}
          />
          <Stack gap={20} justify="space-around">
            {/* integration name */}
            <Skeleton
              isVisible
              skeletonWrapperProps={{ w: 250, mt: 'auto' }}
              skeletonProps={{ h: 30 }}
            />
            {/* button */}
            <Skeleton
              isVisible
              skeletonWrapperProps={{ w: 100, mb: 'auto' }}
              skeletonProps={{ h: 40 }}
            />
          </Stack>
        </Flex>
      ))}
    </Stack>
  );
}

IntegrationsSkeleton.propTypes = {
  isVisible: PropTypes.bool,
};

IntegrationsSkeleton.defaultProps = {
  isVisible: false,
};
