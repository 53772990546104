import { Box, Divider, Flex, Stack, Title } from '@mantine/core';
import { OfferLetterAcceptance } from 'components/OfferLetterAcceptance';
import React from 'react';
import PropTypes from 'prop-types';
import { EmployeeForContact } from 'components/EmployeeForContact';

export default function PendingOfferState({
  candidateOffer,
  signStatus,
  toggleSignStatus,
}) {
  return (
    <>
      <Box
        py="lg"
        dangerouslySetInnerHTML={{ __html: candidateOffer?.letter }}
      />
      <Divider />

      <Flex justify="space-between">
        <OfferLetterAcceptance
          candidate={{
            full_name: `${candidateOffer?.candidate?.first_name} ${candidateOffer?.candidate?.last_name}`,
          }}
          signStatus={signStatus}
          toggleSignStatus={toggleSignStatus}
        />
        <Stack gap={5}>
          <Title fz="lg">Questions?</Title>
          <EmployeeForContact employee={candidateOffer?.contact ?? {}} />
        </Stack>
      </Flex>
    </>
  );
}

PendingOfferState.propTypes = {
  candidateOffer: PropTypes.object.isRequired,
  signStatus: PropTypes.string.isRequired,
  toggleSignStatus: PropTypes.func.isRequired,
};
