import { Box, Flex, Paper, Stack } from '@mantine/core';
import { IconFileTypePdf } from '@tabler/icons-react';

import PropTypes from 'prop-types';

export default function SignatureRow({ request, onClick }) {
  return (
    <Paper
      shadow="sm"
      radius="md"
      px="xl"
      py="md"
      style={{ cursor: 'pointer' }}
      withBorder
      onClick={() => onClick({ ...request })}
    >
      <Flex gap={10} align="center">
        <IconFileTypePdf />
        <Stack gap={0}>
          {request?.title}
          <Box c="var(--mantine-color-gray-6)" py={0}>
            {request?.data?.template_name}
          </Box>
        </Stack>
      </Flex>
    </Paper>
  );
}

SignatureRow.propTypes = {
  request: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};
