import {
  Checkbox,
  Divider,
  Flex,
  Paper,
  Select,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { ContextMenu } from 'components/ContextMenu';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import { Loader } from 'components/Loader';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

export default function CompletedTrainings({
  deleteUserRecord,
  editUserRecord,
  recordDeleteState,
  recordUpdateState,
  categorySelector,
  yearSelector,
  isRecordsLoading,
  groupedRecords,
}) {
  const { isDeletingRecord, deletingItemId } = recordDeleteState;
  const { isUpdatingRecord, updatingRecordId } = recordUpdateState;

  const { setSelectedCategory, categories, selectedCategory } =
    categorySelector;
  const { years, setSelectedYear } = yearSelector;

  return (
    <>
      <Flex gap={10} align="center" justify="space-between">
        <h2>Completed</h2>
        <Flex gap={10}>
          <Select placeholder="Year" data={years} onChange={setSelectedYear} />
          <Select
            placeholder="Category"
            onChange={setSelectedCategory}
            data={categories}
            value={selectedCategory}
          />
        </Flex>
      </Flex>

      <Loader isLoading={isRecordsLoading} />

      <EmptyDataMessage
        isVisible={!isRecordsLoading && _.isEmpty(groupedRecords)}
      />

      {!_.isEmpty(groupedRecords) && (
        <Paper withBorder p={10}>
          <Stack gap={10}>
            {_.keys(groupedRecords).map((category) => {
              const title = category === 'null' ? 'No Category' : category;
              return (
                <Stack key={category} gap={10}>
                  <Title pb={5} fz={20}>
                    {title}
                  </Title>
                  {_.map(groupedRecords[category], (record) => {
                    const menuItems = [
                      {
                        label: 'Edit',
                        icon: IconEdit,
                        onClick: () => {
                          editUserRecord(record);
                        },
                      },
                      {
                        label: 'Delete',
                        icon: IconTrash,
                        onClick: () => {
                          deleteUserRecord(record.id);
                        },
                      },
                    ];

                    const isDeletingOrUpdating =
                      (deletingItemId === record?.id && isDeletingRecord) ||
                      (updatingRecordId === record?.id && isUpdatingRecord);

                    return (
                      <React.Fragment key={record.id}>
                        <Flex align="center" gap={10}>
                          <Checkbox checked py={5} disabled />
                          <Flex gap={10} align="center">
                            <Text size="md">{record?.training?.name}</Text>
                            {record?.complete_date && (
                              <Text c="gray" size="sm">
                                Completed{' '}
                                {moment(record.complete_date).format(
                                  'MMMM DD, YYYY',
                                )}
                              </Text>
                            )}
                          </Flex>
                          <Flex ml="auto" gap={10}>
                            {isDeletingOrUpdating ? (
                              <Loader color="blue" size={19} />
                            ) : (
                              <ContextMenu menuItems={menuItems} />
                            )}
                          </Flex>
                        </Flex>
                        <Divider />
                      </React.Fragment>
                    );
                  })}
                </Stack>
              );
            })}
          </Stack>
        </Paper>
      )}
    </>
  );
}

CompletedTrainings.propTypes = {
  groupedRecords: PropTypes.object.isRequired,
  deleteUserRecord: PropTypes.func.isRequired,
  editUserRecord: PropTypes.func.isRequired,
  recordDeleteState: PropTypes.object,
  recordUpdateState: PropTypes.object,
  categorySelector: PropTypes.object,
  yearSelector: PropTypes.object,
  isRecordsLoading: PropTypes.bool,
};

CompletedTrainings.defaultProps = {
  recordDeleteState: {},
  recordUpdateState: {},
  categorySelector: {},
  yearSelector: {},
  isRecordsLoading: false,
};
