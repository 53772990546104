import { useAuthContext } from 'auth';
import { usePushNotifications } from 'api/hooks';

import NotificationsList from 'components/Notifications/NotificationsList';
import { Flex, Pagination } from '@mantine/core';
import PropTypes from 'prop-types';
import { usePageTitle, usePagination } from 'utils/hooks';
import { PageWrapper } from '../../components/PageWrapper';

export default function Notifications({ globalTabName }) {
  const { user } = useAuthContext();

  const { page, setPage } = usePagination({ page: 1 });

  const { notifications, pagination, isLoading, invalidatedNotificationsList } =
    usePushNotifications(
      {
        userId: user?.id,
        page,
        limit: 20,
      },
      { enabled: Boolean(user) },
    );

  usePageTitle('Notifications', globalTabName);

  return (
    <PageWrapper title="Notifications">
      <NotificationsList
        showSeeAll={false}
        notifications={notifications}
        isLoading={isLoading}
        onMarkedAsRead={() => invalidatedNotificationsList()}
        fullView
      />
      {pagination?.total_pages > 1 && (
        <Flex justify="flex-end" mt={10}>
          <Pagination
            total={pagination?.total_pages}
            value={page}
            onChange={setPage}
          />
        </Flex>
      )}
    </PageWrapper>
  );
}

Notifications.propTypes = {
  globalTabName: PropTypes.string,
};

Notifications.defaultProps = {
  globalTabName: '',
};
