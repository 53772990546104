import React from 'react';
import PropTypes from 'prop-types';
import { CandidateOfferForm } from 'components/CandidateOfferForm';
import { TemplateModal } from 'components/TemplateModal';

export default function CreateCandidateOfferModal({
  opened,
  onClose,
  offerState,
  onSubmit,
  onCancelOffer,
  meta,
}) {
  return (
    <TemplateModal
      opened={opened}
      onClose={onClose}
      title={`${offerState?.modalType ?? 'Create'} offer`}
    >
      <CandidateOfferForm
        onSubmit={onSubmit}
        offerState={offerState}
        onCancelOffer={onCancelOffer}
        meta={meta}
      />
    </TemplateModal>
  );
}

CreateCandidateOfferModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancelOffer: PropTypes.func.isRequired,
  offerState: PropTypes.object,
  meta: PropTypes.object,
};

CreateCandidateOfferModal.defaultProps = {
  offerState: {},
  meta: {},
};
