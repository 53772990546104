import { Button } from '@mantine/core';
import { IconBrandGoogle, IconBrandWindows } from '@tabler/icons-react';
import { useCurrentUserMutation, useOAuthLogin } from 'api/hooks';
import { useAuthContext } from 'auth';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { useNavigate } from 'react-router-dom';
import { useAllowedIntegrations, useGoogleAuth } from '../hooks';
import useMicrosoftAuth from '../hooks/useMicrosoftAuth';

export default function OAuth2List() {
  const { setTokenData, setUser, prevUrl } = useAuthContext();

  const navigate = useNavigate();
  const { setNotifications } = useNotifications();

  const { getApiUser } = useCurrentUserMutation({
    onSuccess: (response) => {
      setUser(response.data);
      setNotifications(['You are successfully logged in']);
    },
  });

  const { oauthLogin, isLoading: isOauthLoading } = useOAuthLogin({
    onSuccess: (data, passedValues) => {
      setTokenData({
        access_token: data?.access_token,
        refresh_token: null,
        provider: passedValues?.provider,
      });
      navigate(prevUrl ?? '/', { replace: true });
      getApiUser();
    },
  });

  const { isCompanyIntegrationsLoading, allowedIntegrations } =
    useAllowedIntegrations();

  const { isGoogleLoading, googleLogin } = useGoogleAuth({ oauthLogin });

  const loginMicrosoft = useMicrosoftAuth({ oauthLogin, isOauthLoading });

  if (isCompanyIntegrationsLoading) return null;

  return (
    <>
      {allowedIntegrations.google && (
        <Button
          c="white"
          onClick={googleLogin}
          loading={isGoogleLoading}
          leftSection={<IconBrandGoogle size={20} />}
        >
          Sign in with Google
        </Button>
      )}

      {allowedIntegrations.microsoft && (
        <Button
          c="black"
          style={{ borderColor: 'var(--mantine-color-gray-3)' }}
          variant="outline"
          onClick={loginMicrosoft}
          leftSection={
            <IconBrandWindows
              fill="var(--mantine-color-blue-5)"
              stroke={1}
              color="white"
              size={25}
            />
          }
        >
          Sign in with Microsoft
        </Button>
      )}
    </>
  );
}
