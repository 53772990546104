import { Flex, Table } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { ContextMenu } from 'components/ContextMenu';
import _ from 'lodash';
import PropTypes from 'prop-types';

export default function CompensationGroup({
  group,
  onEditGroup,
  onDeleteGroup,
  onEditBand,
  onDeleteBand,
}) {
  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>
            <Flex fw={700} style={{ fontSize: '20px' }}>
              {group?.name}
              <ContextMenu
                menuItems={[
                  {
                    label: 'Edit',
                    icon: IconEdit,
                    onClick: () => onEditGroup(group),
                  },
                  {
                    label: 'Delete',
                    icon: IconTrash,
                    onClick: () => onDeleteGroup(group),
                  },
                ]}
              />
            </Flex>
          </Table.Th>
        </Table.Tr>
        <Table.Tr>
          <Table.Th>Level Name</Table.Th>
          <Table.Th>Salary MidPoint</Table.Th>
          <Table.Th>Salary Percentage</Table.Th>
          <Table.Th>Pay Band</Table.Th>
          <Table.Th>Job Titles</Table.Th>
          <Table.Th>Employees</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {_.map(group?.bands, (band) => {
          const formattedJobTitles = _.map(band?.job_titles, 'name').join(
            ', \n',
          );

          const totalEmployee = _.map(
            band?.job_titles,
            'employees_count',
          ).reduce((prev, curr) => prev + curr, 0);

          return (
            <Table.Tr key={band?.id}>
              <Table.Td>{band?.name}</Table.Td>
              <Table.Td>{band?.midpoint_salary}$</Table.Td>
              <Table.Td>{band?.percentage}%</Table.Td>
              <Table.Td>
                {band?.min_salary}$ - {band?.max_salary}$
              </Table.Td>
              <Table.Td style={{ whiteSpace: 'pre-wrap' }}>
                {formattedJobTitles}
              </Table.Td>
              <Table.Td>{totalEmployee}</Table.Td>
              <Table.Td ta="right">
                <ContextMenu
                  menuItems={[
                    {
                      label: 'Edit',
                      icon: IconEdit,
                      onClick: () => onEditBand(band),
                    },
                    {
                      label: 'Delete',
                      icon: IconTrash,
                      onClick: () => onDeleteBand(band),
                    },
                  ]}
                />
              </Table.Td>
            </Table.Tr>
          );
        })}
      </Table.Tbody>
    </Table>
  );
}

CompensationGroup.propTypes = {
  group: PropTypes.object.isRequired,
  onEditGroup: PropTypes.func.isRequired,
  onDeleteGroup: PropTypes.func.isRequired,
  onEditBand: PropTypes.func.isRequired,
  onDeleteBand: PropTypes.func.isRequired,
};
