import {
  Accordion,
  AccordionControl,
  AccordionItem,
  AccordionPanel,
  Checkbox,
  Flex,
  InputLabel,
} from '@mantine/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import styles from '../style.module.css';

export function EmployeeFilterList({
  filteredData,
  selectedValues,
  onSelectFilter,
}) {
  return (
    <Accordion multiple bg="var(--white-dark)">
      {_.keys(filteredData).map((key) => {
        const selectedFiltersFromSection = selectedValues?.[key]?.filter(
          ({ value }) => value,
        )?.length;
        return (
          <AccordionItem className={styles.accordionItem} value={key} key={key}>
            <AccordionControl
              classNames={{
                control: styles.accordionControl,
                label: styles.accordionControlLabel,
              }}
            >
              {filteredData[key].field}{' '}
              {selectedFiltersFromSection
                ? `(${selectedFiltersFromSection})`
                : ''}
            </AccordionControl>
            {_.map(filteredData[key].values, (value) => {
              const isChecked = _.some(
                selectedValues?.[key],
                (val) => val?.id === value?.id && val?.value,
              );

              return (
                <AccordionPanel
                  styles={{ content: { paddingBottom: 0 } }}
                  key={value?.id}
                >
                  <InputLabel>
                    <Flex
                      align="center"
                      gap={10}
                      c={value?.isExist ? '' : 'gray'}
                      style={{
                        textDecoration: !value?.isExist ? 'line-through' : '',
                      }}
                    >
                      <Checkbox
                        checked={isChecked}
                        onChange={() =>
                          onSelectFilter({
                            type: key,
                            value: !isChecked,
                            id: value?.id,
                            name: value?.name,
                            isExist: value?.isExist,
                          })
                        }
                        color={value?.isExist ? '' : 'red'}
                      />
                      {value?.name}
                    </Flex>
                  </InputLabel>
                </AccordionPanel>
              );
            })}
          </AccordionItem>
        );
      })}
    </Accordion>
  );
}

EmployeeFilterList.propTypes = {
  filteredData: PropTypes.object.isRequired,
  selectedValues: PropTypes.object.isRequired,
  onSelectFilter: PropTypes.func.isRequired,
};
