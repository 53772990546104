import { Flex, Select } from '@mantine/core';
import { usePolicyTypeSelect } from 'components/TimeOff/hooks';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default function Monthly({ onChange, formValues, scheduleType }) {
  const monthly = usePolicyTypeSelect({
    onChange,
    formValues,
    scheduleType,
  });

  const daysList = _.map({ length: 28 }, (val, index) => ({
    label: `${index + 1}th`,
    value: `${index + 1}`,
  })).concat({ label: 'Last day', value: '-1' });

  return (
    <Flex align="center" gap={5}>
      on the{' '}
      <Select
        value={monthly?.day}
        onChange={(val) => onChange('settings.accrue.period.monthly.day', val)}
        data={daysList}
        allowDeselect={false}
        placeholder="Select Day"
      />
    </Flex>
  );
}

Monthly.propTypes = {
  formValues: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  scheduleType: PropTypes.string.isRequired,
};
