import { Container, Divider, Stack, Text, Title } from '@mantine/core';
import { Loader } from 'components/Loader';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import PendingOfferState from 'modules/candidate-offer/PendingOfferState';
import SignedOfferState from 'modules/candidate-offer/SignedOfferState';
import ErrorOfferState from 'modules/candidate-offer/ErrorOfferState';

export default function CandidateOfferLetter({
  candidateOffer,
  signStatus,
  isLoading,
  toggleSignStatus,
  readOnly,
}) {
  const offerProvider = {
    pending: (
      <PendingOfferState
        candidateOffer={candidateOffer}
        signStatus={signStatus}
        toggleSignStatus={toggleSignStatus}
      />
    ),
    signed: <SignedOfferState candidateOffer={candidateOffer} />,
    error: <ErrorOfferState />,
  };

  const offerComponent = offerProvider[signStatus] ?? offerProvider.error;

  return (
    <Container styles={{ root: { pointerEvents: readOnly ? 'none' : 'auto' } }}>
      <Stack gap={10}>
        <Stack gap={5} mx="auto" align="center">
          <Title style={{ color: '#cc3d5c' }}>Atomate</Title>
          {signStatus !== 'error' && (
            <Text>
              {moment(candidateOffer?.created_at).format('dddd, MMMM DD, YYYY')}
            </Text>
          )}
        </Stack>
        <Divider />
        <Loader isLoading={isLoading} />
        {!isLoading && offerComponent}
      </Stack>
    </Container>
  );
}

CandidateOfferLetter.propTypes = {
  candidateOffer: PropTypes.object.isRequired,
  signStatus: PropTypes.string,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
  toggleSignStatus: PropTypes.func,
};

CandidateOfferLetter.defaultProps = {
  signStatus: 'pending',
  isLoading: false,
  readOnly: false,
  toggleSignStatus: () => {},
};
