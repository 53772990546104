import { Button, Flex, Stack, Table } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { clearCache } from 'api/clearCache';
import {
  useDeleteEmployeeStatusRecord,
  useLoadEmployeeStatusRecords,
} from 'api/hooks';
import { ChangeEmployeeStatusModal } from 'components/ChangeEmployeeStatusModal';
import { useChangeEmployeeStatusModal } from 'components/ChangeEmployeeStatusModal/hooks';
import { ConfirmDeleteModal } from 'components/ConfirmDeleteModal';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import { Skeleton } from 'components/Skeleton';
import _ from 'lodash';
import moment from 'moment';
import { useNotifications } from 'notifications/hooks/useNotifications';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { useStateDisclosure } from 'utils/hooks';
import { EmploymentStatusRecordRow } from '../tabs-rows';

const headers = ['Effective Date', 'Employment Status', 'Comment'];

export default function EmploymentStatusRecords({ employeeId, profile }) {
  const queryClient = useQueryClient();
  const { setNotifications } = useNotifications();

  const { records, isLoading: isRecordsLoading } =
    useLoadEmployeeStatusRecords(employeeId);

  const [
    openedConfirmationDeleteModal,
    {
      open: openConfirmationDeleteModal,
      close: closeConfirmationDeleteModal,
      state: confirmationDeleteModalState,
    },
  ] = useStateDisclosure(false);

  const { deleteEmployeeStatusRecord, isLoading: isDeletingEmployeeRecord } =
    useDeleteEmployeeStatusRecord({
      onSuccess: () => {
        setNotifications(['Employee Status Deleted Successfully']);
        clearCache.onChangeEmployeeStatusRecord(queryClient, {
          employeeId,
        });
        closeConfirmationDeleteModal();
      },
    });

  const { getModalProps, openChangeEmployeeStatusModal } =
    useChangeEmployeeStatusModal({
      employeeId,
    });

  return (
    <Stack gap={0} my={10}>
      <ConfirmDeleteModal
        opened={openedConfirmationDeleteModal}
        onClose={closeConfirmationDeleteModal}
        onDelete={() =>
          deleteEmployeeStatusRecord(confirmationDeleteModalState?.id)
        }
        isLoading={isDeletingEmployeeRecord}
      />
      <ChangeEmployeeStatusModal {...getModalProps()} />

      <Flex justify="space-between" align="center">
        <h2 style={{ margin: '0px' }}>Employment status</h2>
        <Button
          rightSection={<IconPlus size={14} />}
          onClick={() =>
            openChangeEmployeeStatusModal({
              status_id: profile?.current_status?.status_id,
              comment: '',
              start_date: moment().format('YYYY-MM-DD'),
            })
          }
        >
          Change Status
        </Button>
      </Flex>

      <Skeleton
        count={4}
        isVisible={isRecordsLoading}
        skeletonWrapperProps={{ my: 10 }}
      />

      <EmptyDataMessage
        isVisible={!isRecordsLoading && _.isEmpty(records)}
        my={20}
      />

      {!isRecordsLoading && !_.isEmpty(records) && (
        <Table>
          <Table.Thead>
            <Table.Tr>
              {_.map(headers, (item) => (
                <Table.Th key={item}>{item}</Table.Th>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {_.map(records, (record) => (
              <EmploymentStatusRecordRow
                key={record?.id}
                record={record}
                onEdit={(item) =>
                  openChangeEmployeeStatusModal({ ...item, modalType: 'Edit' })
                }
                onDelete={openConfirmationDeleteModal}
              />
            ))}
          </Table.Tbody>
        </Table>
      )}
    </Stack>
  );
}

EmploymentStatusRecords.propTypes = {
  employeeId: PropTypes.string.isRequired,
  profile: PropTypes.object,
};

EmploymentStatusRecords.defaultProps = {
  profile: {},
};
