import { Avatar, Flex, Skeleton, Stack, Text } from '@mantine/core';
import _ from 'lodash';
import PropTypes from 'prop-types';

export default function UserMainInfoPreviewCard({
  userInfo,
  avatarProps,
  ...props
}) {
  if (_.isEmpty(userInfo)) {
    return (
      <Flex gap={10} align="center">
        <Skeleton height={50} circle />
        <Stack gap={10}>
          <Skeleton height={14} w="200" />
          <Skeleton height={14} w="200" />
        </Stack>
      </Flex>
    );
  }

  return (
    <Flex bg="var(--mantine-color-gray-1)" p={10} gap={15} {...props}>
      <Avatar
        styles={{ root: { border: '1px solid var(--mantine-color-blue-5)' } }}
        src={userInfo?.avatar_filename}
        size={50}
        {...avatarProps}
      />
      <Stack gap={0}>
        <Text fw={600} fz={18}>
          {userInfo?.first_name} {userInfo?.last_name}
        </Text>
        <Text fz={15}>{userInfo?.jobTitle}</Text>
      </Stack>
    </Flex>
  );
}

UserMainInfoPreviewCard.propTypes = {
  userInfo: PropTypes.object,
  avatarProps: PropTypes.object,
};

UserMainInfoPreviewCard.defaultProps = {
  userInfo: {},
  avatarProps: {},
};
