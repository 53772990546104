import { Modal } from '@mantine/core';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { JobInformationForm } from 'components/JobInformationForm';

export default function CreateJobInformationModal({
  opened,
  onClose,
  onCreate,
  onEdit,
  jobInformationState,
  isLoading,
}) {
  const onSubmit = (values) => {
    if (jobInformationState?.modalType === 'Edit') {
      onEdit({
        ...values,
        job_information_id: jobInformationState?.id,
        date: moment(values?.date).format('YYYY-MM-DD'),
      });
    } else {
      onCreate({
        ...values,
        date: moment(values?.date).format('YYYY-MM-DD'),
      });
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      title="Create Job information"
    >
      <JobInformationForm
        jobInformationState={jobInformationState}
        isCreating={isLoading || !opened}
        onSubmit={onSubmit}
      />
    </Modal>
  );
}

CreateJobInformationModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  jobInformationState: PropTypes.object,
  isLoading: PropTypes.bool,
  onEdit: PropTypes.func,
};

CreateJobInformationModal.defaultProps = {
  jobInformationState: {},
  isLoading: false,
  onEdit: () => {},
};
