import { Fragment } from 'react';
import { Flex, Button, Text, Table, Loader } from '@mantine/core';
import { IconEdit, IconTrash, IconSettings } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ContextMenu } from 'components/ContextMenu';

export default function TimeOffCategoriesTable({
  categories,
  onEditCategory,
  onDeleteCategory,
  categoryDeleteState,
  policyDeleteState,
  onEditPolicy,
  onDeletePolicy,
  policyTypes,
}) {
  const { isDeletingCategory, deletingCategoryId } = categoryDeleteState;
  const { isDeletingPolicy, deletingPolicyId } = policyDeleteState;
  return (
    <Table highlightOnHover>
      {categories
        .sort((a, b) => {
          if (a.id === 0) return -1;
          if (b.id === 0) return 1;
          return b.policies.length - a.policies.length;
        })
        .map((item) => {
          const menuItems = [
            {
              label: 'Edit',
              icon: IconEdit,
              onClick: () => {
                onEditCategory(item);
              },
            },
            {
              label: 'Delete',
              icon: IconTrash,
              onClick: () => {
                onDeleteCategory(item);
              },
            },
          ];
          const isDeletingItem =
            isDeletingCategory && deletingCategoryId === item.id;
          const opacity = isDeletingItem ? 0.5 : 1;
          return (
            <Fragment key={item.name + item.id}>
              <Table.Thead opacity={opacity}>
                <Table.Tr>
                  <Table.Th>
                    <Flex align="center" gap="xs">
                      {!isDeletingItem && item.id !== 0 && (
                        <ContextMenu
                          menuItems={menuItems}
                          menuIcon={IconSettings}
                        />
                      )}
                      {isDeletingItem && <Loader color="blue" size={15} />}
                      <Text fw={700} size="xl">
                        {item.name}
                      </Text>
                    </Flex>
                  </Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Thead opacity={opacity}>
                <Table.Tr>
                  <Table.Th>Policy Name</Table.Th>
                  <Table.Th>Created at</Table.Th>
                  <Table.Th>Updated at</Table.Th>
                  <Table.Th>Policy Type</Table.Th>
                  <Table.Th>Actions</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody opacity={opacity}>
                {item.policies.map((policy) => (
                  <TimeOffPolicyRow
                    key={policy.id}
                    policy={policy}
                    onDelete={onDeletePolicy}
                    onEdit={onEditPolicy}
                    isDeleting={
                      isDeletingPolicy && policy.id === deletingPolicyId
                    }
                    policyTypes={policyTypes}
                  />
                ))}
              </Table.Tbody>
            </Fragment>
          );
        })}
    </Table>
  );
}

function TimeOffPolicyRow({
  policy,
  onEdit,
  onDelete,
  isDeleting,
  policyTypes,
}) {
  return (
    <Table.Tr opacity={isDeleting ? 0.5 : 1}>
      <Table.Td>
        <Flex gap={4} align="center">
          {isDeleting && <Loader color="blue" size={15} />} {policy.name}
        </Flex>
      </Table.Td>
      <Table.Td>
        {moment(policy.created_at).format('DD/MM/YYYY, h:mm a')}
      </Table.Td>
      <Table.Td>
        {moment(policy.updated_at).format('DD/MM/YYYY, h:mm a')}
      </Table.Td>
      <Table.Td>{policyTypes?.[policy?.type] ?? ''}</Table.Td>
      <Table.Td>
        <Flex gap={4}>
          <Button
            leftSection={<IconEdit size={20} />}
            onClick={() => onEdit(policy)}
            variant="outline"
          >
            Edit
          </Button>
          <Button
            leftSection={<IconTrash size={20} />}
            onClick={() => onDelete(policy)}
            variant="outline"
            color="red"
          >
            Delete
          </Button>
        </Flex>
      </Table.Td>
    </Table.Tr>
  );
}

TimeOffPolicyRow.propTypes = {
  policy: PropTypes.object.isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
  policyTypes: PropTypes.object,
};
TimeOffPolicyRow.defaultProps = {
  onEdit: () => {},
  onDelete: () => {},
  isDeleting: false,
  policyTypes: {},
};

TimeOffCategoriesTable.propTypes = {
  categories: PropTypes.array,
  onEditCategory: PropTypes.func,
  onDeleteCategory: PropTypes.func,
  onEditPolicy: PropTypes.func,
  onDeletePolicy: PropTypes.func,
  categoryDeleteState: PropTypes.object,
  policyDeleteState: PropTypes.object,
  policyTypes: PropTypes.object,
};
TimeOffCategoriesTable.defaultProps = {
  categories: [],
  onEditCategory: () => {},
  onDeleteCategory: () => {},
  onEditPolicy: () => {},
  onDeletePolicy: () => {},
  categoryDeleteState: {},
  policyDeleteState: {},
  policyTypes: {},
};
