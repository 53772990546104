import { Flex, Space, Table, rem } from '@mantine/core';
import { AddButton } from 'components/Buttons';
import React from 'react';
import _ from 'lodash';
import {
  useCreateOfferTemplate,
  useDeleteOfferTemplate,
  useLoadOfferTemplates,
  useUpdateOfferTemplate,
} from 'api/hooks';
import { useStateDisclosure } from 'utils/hooks';
import { Loader } from 'components/Loader';
import { ConfirmDeleteModal } from 'components/ConfirmDeleteModal';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { CreateOfferTemplateModal } from 'components/CreateOfferTemplateModal';
import { useQueryClient } from 'react-query';
import { queryKeys } from 'api/keys';
import { TemplatesRow } from './tab-rows';

export default function OfferTemplates() {
  const { setNotifications } = useNotifications();
  const queryClient = useQueryClient();

  const { offerTemplates, isLoading: isOffersLoading } =
    useLoadOfferTemplates();

  const [
    openedDeleteModal,
    { open: openDeleteModal, close: closeDeleteModal, state: deleteState },
  ] = useStateDisclosure(false);

  const [
    openedCreateModal,
    {
      open: openCreateModal,
      close: closeCreateModal,
      state: offerTemplateState,
    },
  ] = useStateDisclosure(false);

  const { createOfferTemplate, isLoading: isCreateOfferTemplateLoading } =
    useCreateOfferTemplate({
      onSuccess: () => {
        setNotifications(['Offer Template Created Successfully']);
        closeCreateModal();
        queryClient.invalidateQueries(queryKeys.offerTemplates());
      },
    });

  const { updateOfferTemplate, isLoading: isUpdatingOfferTemplate } =
    useUpdateOfferTemplate({
      onSuccess: () => {
        setNotifications(['Offer Template Updated Successfully']);
        closeCreateModal();
        queryClient.invalidateQueries(queryKeys.offerTemplates());
      },
    });

  const { deleteOfferTemplate, isLoading: isDeleting } = useDeleteOfferTemplate(
    {
      onSuccess: () => {
        setNotifications(['Offer Template Deleted Successfully']);
        closeDeleteModal();
        queryClient.invalidateQueries(queryKeys.offerTemplates());
      },
    },
  );

  return (
    <>
      <ConfirmDeleteModal
        opened={openedDeleteModal}
        onClose={closeDeleteModal}
        deleteItemName={deleteState?.name}
        isLoading={isDeleting}
        onDelete={() => deleteOfferTemplate(deleteState?.id)}
      />
      <CreateOfferTemplateModal
        opened={openedCreateModal}
        onClose={closeCreateModal}
        offerState={offerTemplateState}
        onCreate={createOfferTemplate}
        onEdit={(data) =>
          updateOfferTemplate({ id: offerTemplateState?.id, ...data })
        }
        isLoading={isCreateOfferTemplateLoading || isUpdatingOfferTemplate}
      />

      <Flex justify="flex-end">
        <AddButton onClick={() => openCreateModal()}>Add Template</AddButton>
      </Flex>
      <Space h="xs" />
      {!_.isEmpty(offerTemplates) && (
        <Table>
          <Table.Thead
            styles={{
              thead: { backgroundColor: 'var(--mantine-color-gray-4)' },
            }}
          >
            <Table.Tr>
              <Table.Th>Name</Table.Th>
              <Table.Th miw={rem(300)}>Subject</Table.Th>
              <Table.Th />
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {_.map(offerTemplates, (item) => (
              <TemplatesRow
                key={item?.id}
                data={item}
                onEdit={(data) =>
                  openCreateModal({ ...data, modalType: 'Edit' })
                }
                onDelete={openDeleteModal}
              />
            ))}
          </Table.Tbody>
        </Table>
      )}
      <Loader isLoading={isOffersLoading} />
    </>
  );
}
