import { Button, Flex, Space, Table, Title } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { clearCache } from 'api/clearCache';
import {
  useCreateReason,
  useDeleteReason,
  useLoadLookups,
  useLoadReasons,
  useUpdateReason,
} from 'api/hooks';
import { AddBonusReasonModal } from 'components/AddBonusReasonModal';
import { ConfirmDeleteModal } from 'components/ConfirmDeleteModal';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import { Loader } from 'components/Loader';
import { SettingsTableHead } from 'components/SettingsTable';
import _ from 'lodash';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { getCurrentReasonType } from 'utils';
import { lookupsModels } from 'utils/constants';
import { useStateDisclosure } from 'utils/hooks';
import { BonusReasonRow } from '../components';

const headerList = ['Bonus: Reason', 'People', ''];

export default function BonusReasons() {
  const { setNotifications } = useNotifications();
  const queryClient = useQueryClient();

  const [
    openedBonusReasonModal,
    {
      open: openBonusReasonModal,
      close: closeBonusReasonModal,
      state: bonusReasonModalState,
    },
  ] = useStateDisclosure(false);

  const [
    openedConfirmDeleteModal,
    {
      open: openConfirmDeleteModal,
      close: closeConfirmDeleteModal,
      state: confirmDeleteModalState,
    },
  ] = useStateDisclosure(false);

  const { lookups, isLoading: isLookupsLoading } = useLoadLookups({
    models: [lookupsModels.reason],
  });

  const currentType = useMemo(
    () => getCurrentReasonType(lookups?.Reason, 'Bonus'),
    [lookups?.Reason],
  );

  const { reasons, isLoading: isReasonsLoading } = useLoadReasons(
    { params: { filter: { byType: currentType }, withCount: 1 } },
    { enabled: !!currentType },
  );

  const { createReason, isLoading: isCreating } = useCreateReason({
    onSuccess: () => {
      clearCache.onChangeReason(queryClient, currentType);
      setNotifications(['Bonus reason Created Successfully!']);
      closeBonusReasonModal();
    },
  });

  const { deleteReason, isLoading: isDeleting } = useDeleteReason({
    onSuccess: () => {
      clearCache.onChangeReason(queryClient, currentType);
      setNotifications(['Bonus reason Deleted Successfully!']);
      closeConfirmDeleteModal();
    },
  });

  const { updateReason, isLoading: isUpdatingReason } = useUpdateReason({
    onSuccess: () => {
      clearCache.onChangeReason(queryClient, currentType);
      setNotifications(['Bonus reason Updated Successfully!']);
      closeBonusReasonModal();
    },
  });

  const isMainDataLoading = isLookupsLoading || isReasonsLoading;

  return (
    <>
      <AddBonusReasonModal
        opened={openedBonusReasonModal}
        onClose={closeBonusReasonModal}
        reasonState={bonusReasonModalState}
        onEdit={(data) =>
          updateReason({
            ...data,
            reasonId: bonusReasonModalState?.id,
          })
        }
        onCreate={(data) => createReason({ ...data, type: currentType })}
        isLoading={isCreating || isUpdatingReason}
      />

      <ConfirmDeleteModal
        opened={openedConfirmDeleteModal}
        onClose={closeConfirmDeleteModal}
        onDelete={() => deleteReason(confirmDeleteModalState?.id)}
        customText="Are you sure you want to delete this row?"
        isLoading={isDeleting}
      />

      <Flex gap={10} justify="space-between" align="center">
        <Title fz="xl">Bonus: Reason</Title>
        <Button
          onClick={() => openBonusReasonModal()}
          rightSection={<IconPlus size={14} />}
        >
          Add Bonus Reason
        </Button>
      </Flex>

      <Space h="xs" />

      <EmptyDataMessage isVisible={!isMainDataLoading && _.isEmpty(reasons)} />

      <Loader isLoading={isMainDataLoading} />

      {!isMainDataLoading && !_.isEmpty(reasons) && (
        <Table>
          <SettingsTableHead headerList={headerList} />
          <Table.Tbody>
            {_.map(reasons, (reason) => (
              <BonusReasonRow
                key={reason?.id}
                reasonItem={reason}
                onEditBonusReason={(data) =>
                  openBonusReasonModal({ ...data, modalType: 'Edit' })
                }
                onDeleteBonusReason={openConfirmDeleteModal}
              />
            ))}
          </Table.Tbody>
        </Table>
      )}
    </>
  );
}
