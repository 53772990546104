import React from 'react';
import { IconCalendar } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { FormOption } from 'components/FormOption';

export default function FixedDateTypeItem({
  onClick,
  selected,
  error,
  viewOnly,
}) {
  return (
    <FormOption
      label="On Fixed Date"
      text="Reviews start on the same date."
      icon={<IconCalendar size="30" />}
      onClick={onClick}
      selected={selected}
      error={error}
      viewOnly={viewOnly}
    />
  );
}

FixedDateTypeItem.propTypes = {
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  error: PropTypes.bool,
  viewOnly: PropTypes.bool,
};

FixedDateTypeItem.defaultProps = {
  onClick: () => {},
  selected: false,
  error: false,
  viewOnly: false,
};
