import { useDebouncedValue, useDisclosure, useTimeout } from '@mantine/hooks';
import { useCheckIsValidPasswordResetToken } from 'api/hooks';
import { useGlobalTabsContext } from 'globalTabs';
import _ from 'lodash';
import moment from 'moment';
import { useNotifications } from 'notifications/hooks/useNotifications';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { avoidRedirectRoots, siteName } from './constants';

export const useStateDisclosure = (initialValue, initialState) => {
  const [state, setState] = useState(initialState);
  const [opened, handlers] = useDisclosure(initialValue);

  const open = (newState) => {
    setState(newState);
    handlers.open();
  };
  const close = () => {
    setState();
    handlers.close();
  };

  const toggle = (newState) => {
    if (newState) {
      setState(newState);
    }
    handlers.toggle();
  };
  return [opened, { open, close, toggle, state }];
};

export const useURLQuery = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { query } = queryString.parseUrl(location.search);

  const setQueryTo = useCallback(
    (queryObj) => {
      navigate(`${location.pathname}?${queryString.stringify(queryObj)}`);
    },
    [navigate, location.pathname],
  );

  const getQueryInt = useCallback(
    (number, defaultVal = 0) =>
      _.isNaN(_.toNumber(number)) || !_.isInteger(_.toNumber(number))
        ? defaultVal
        : _.toNumber(number),
    [],
  );

  return { query, setQueryTo, getQueryInt };
};

export const usePagination = ({ page: defaultPage, canUseQuery }) => {
  const {
    query: { page: queryPage },
    getQueryInt,
    setQueryTo,
  } = useURLQuery();

  const [page, setPage] = useState(
    canUseQuery ? getQueryInt(queryPage, defaultPage) : defaultPage,
  );

  useEffect(() => {
    if (canUseQuery) {
      setQueryTo({ page });
    }
  }, [page, setQueryTo, canUseQuery]);

  useEffect(() => {
    setPage((prev) => (canUseQuery ? getQueryInt(queryPage, prev) : prev));
  }, [queryPage, canUseQuery, getQueryInt]);

  const validateCurrentPage = useCallback(
    (lastPage) => {
      if (lastPage === undefined) return;
      if (lastPage < page) {
        setPage(lastPage);
      } else if (page < 1) {
        setPage(1);
      }
    },
    [setPage, page],
  );

  return { page, setPage, validateCurrentPage };
};

export const useBasePath = () => {
  const location = useLocation();

  return location.pathname.split('/')[1] ?? '';
};

export const useSelector = ({ defaultSelectedValue, values = [] } = {}) => {
  const [data, setData] = useState(values);
  const [selectedValue, setSelectedValue] = useState(defaultSelectedValue);

  return [selectedValue, setSelectedValue, { setData, data }];
};

export const useSearchableSelector = ({
  ms = 300,
  defaultSelectorData = [],
} = {}) => {
  const [search, setSearch] = useState('');
  const [debounced] = useDebouncedValue(search, ms);
  const [selectorData, setSelectorData] = useState(defaultSelectorData);

  const updateSelectorData = useCallback((updData, iterate = 'value') => {
    setSelectorData((prev) => _.uniqBy(prev.concat(updData), iterate));
  }, []);

  return {
    debouncedSearch: debounced,
    setSearch,
    selectorData,
    updateSelectorData,
  };
};

export const useGetDateAgo = ({ date }) => {
  const monthAgo = useMemo(() => {
    if (!date) return {};

    const currentDate = moment();
    const momentDate = moment(date);

    const diffMonths = Math.floor(currentDate.diff(momentDate, 'months', true));

    const diffDays = Math.floor(currentDate.diff(momentDate, 'days', true));

    return {
      months: diffMonths,
      days: diffDays,
      formattedMonths: `${diffMonths} ${
        diffMonths > 1 ? 'months' : 'month'
      } ago`,
      formattedDays: `${diffDays} ${diffDays > 1 ? 'days' : 'day'} ago`,
    };
  }, [date]);

  return monthAgo;
};

export const useStorePreviousUrlBeforeLogin = (isLoggedIn) => {
  const [prevUrl, setPrevUrl] = useState('/');
  const location = useLocation();

  useEffect(() => {
    const hasWrongRootForRedirect = avoidRedirectRoots.some((root) =>
      location.pathname.startsWith(root),
    );

    const isFirstOpenedRoot = window.history.state.idx === 0;

    if (!isLoggedIn && !hasWrongRootForRedirect && isFirstOpenedRoot) {
      setPrevUrl(`${location.pathname}${location.search}`);
    } else if (isLoggedIn) {
      setPrevUrl('/');
    }
  }, [isLoggedIn, location]);

  return { prevUrl, setPrevUrl };
};

export const usePageTitle = (title, tab, ...additionalDeps) => {
  const { selectedTab } = useGlobalTabsContext();

  useEffect(() => {
    if (title && selectedTab === tab) {
      document.title = `${title} - ${siteName}`;
    }
  }, [title, selectedTab, tab, additionalDeps]);
};

export const useSetPageTitle = (title) => {
  useEffect(() => {
    document.title = `${title} - ${siteName}`;
  }, [title]);
};

export const useExportFile = () => {
  const exportFile = useCallback(({ data, name }) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    const fileName = `${name}.csv`;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }, []);

  return { exportFile };
};

export const useCheckValidPasswordToken = (resetHash) => {
  const { setErrors } = useNotifications();
  const navigate = useNavigate();

  const { isLoading: isCheckValidationLoading, isError: isInvalidToken } =
    useCheckIsValidPasswordResetToken(resetHash, {
      onError: () => {
        setErrors([
          'Then link you are trying to access has expired. Please request a new one.',
        ]);
        navigate('/login');
      },
    });

  return { isCheckValidationLoading, isInvalidToken };
};

export const useUpdateTabQuery = ({ globalTabName, queryObject }) => {
  const { selectedTab } = useGlobalTabsContext();
  const navigate = useNavigate();

  const q = useMemo(() => {
    const query = queryString.stringify(queryObject);

    return query;
  }, [queryObject]);

  useEffect(() => {
    if (globalTabName === selectedTab) {
      if (q) {
        navigate(`?${q}`, { replace: true });
      }
    }
  }, [globalTabName, selectedTab, navigate, q]);

  return { generatedQuery: q };
};

export function useIsFirstRender() {
  const renderRef = useRef(true);

  if (renderRef.current === true) {
    renderRef.current = false;
    return true;
  }

  return renderRef.current;
}

export const useFileSizeControl = ({
  maxFileSize,
  timeout = 2000,
  onSelectFile,
}) => {
  const [error, setError] = useState('');

  const { start: clearErrorAfterTimeout } = useTimeout(
    () => setError(''),
    timeout,
  );

  const setFileError = useCallback(() => {
    setError(`Image size should not exceed ${maxFileSize}mb`);
    clearErrorAfterTimeout();
  }, [clearErrorAfterTimeout, maxFileSize]);

  const setFile = useCallback(
    (file) => {
      if (file && maxFileSize >= file.size / 1024 ** 2) {
        onSelectFile(file);
      } else {
        setFileError();
      }
    },
    [setFileError, maxFileSize, onSelectFile],
  );

  return { setFile, error, displayError: setFileError };
};

export const useTabNavigate = () => {
  const { setSelectedTab, setTabs } = useGlobalTabsContext() ?? {};
  const navigation = useNavigate();

  const navigate = useCallback(
    (link, { tabName, ...rest } = {}) => {
      if (tabName) {
        setTabs({ tabName, children: null, pathName: link });
        setSelectedTab(tabName);
      }
      navigation(link, rest);
    },
    [navigation, setTabs, setSelectedTab],
  );

  return navigate;
};
