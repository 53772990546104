import { Button, Flex, Group, Modal, Pill, Text, rem } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import {
  IconFileBroken,
  IconPhoto,
  IconUpload,
  IconX,
} from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useFileSizeControl } from 'utils/hooks';

export default function UploadFileModal({
  opened,
  onClose,
  onSubmit,
  isLoading,
  // in mb
  maxFileSize,
}) {
  const [selectedFile, setSelectedFile] = useState();

  const { error, setFile } = useFileSizeControl({
    maxFileSize,
    onSelectFile: setSelectedFile,
  });

  const onFileSubmit = () => {
    onSubmit(selectedFile);
    setSelectedFile();
  };
  return (
    <Modal
      size="55%"
      opened={opened}
      onClose={onClose}
      title="Upload File"
      centered
      zIndex={1001}
    >
      {error && (
        <Flex mb={10} c="red" align="center">
          <IconFileBroken size={30} />
          {error}
        </Flex>
      )}
      <Dropzone onDrop={(files) => setFile(files[0])} my={5}>
        <Group
          justify="center"
          gap="xl"
          mih={220}
          style={{ pointerEvents: 'none' }}
        >
          <Dropzone.Accept>
            <IconUpload
              style={{
                width: rem(52),
                height: rem(52),
                color: 'var(--mantine-color-blue-6)',
              }}
              stroke={1.5}
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX
              style={{
                width: rem(52),
                height: rem(52),
                color: 'var(--mantine-color-red-6)',
              }}
              stroke={1.5}
            />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconPhoto
              style={{
                width: rem(52),
                height: rem(52),
                color: 'var(--mantine-color-dimmed)',
              }}
              stroke={1.5}
            />
          </Dropzone.Idle>

          <div>
            <Text size="xl" inline>
              Drag files here or click to select files
            </Text>
            <Text size="sm" c="dimmed" inline mt={7}>
              File should not exceed {maxFileSize}mb
            </Text>
          </div>
        </Group>
      </Dropzone>
      {selectedFile && (
        <Flex
          gap="md"
          justify="center"
          align="center"
          direction="column"
          wrap="wrap"
          p="xl"
        >
          <div>
            <Pill withRemoveButton onRemove={() => setSelectedFile()}>
              {selectedFile.name}
            </Pill>
          </div>
          <Button loading={isLoading} onClick={onFileSubmit}>
            Submit
          </Button>
        </Flex>
      )}
    </Modal>
  );
}

UploadFileModal.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  maxFileSize: PropTypes.number,
};

UploadFileModal.defaultProps = {
  isLoading: false,
  opened: false,
  onClose: () => {},
  onSubmit: () => {},
  maxFileSize: 20,
};
