import {
  Box,
  Button,
  Flex,
  NumberInput,
  Paper,
  Pill,
  Select,
  SimpleGrid,
  Space,
  Stack,
  Text,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconClockHour3 } from '@tabler/icons-react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { useLoadTimeOffBalance } from 'api/hooks';
import { DatePickerFormInput } from 'components/DatePicketFormInput';
import moment from 'moment';
import { useMemo } from 'react';
import { datesValidators, genericValidators } from 'utils';

export default function TimeOffRecordForm({
  policies,
  onSubmit,
  isLoading,
  withNotes,
  requests,
}) {
  const form = useForm({
    initialValues: {
      timeoffDetails: requests?.timeoffDetails ?? [],
      policyId: _.toString(requests?.policy_id) ?? '',
      status: true,
      notes: requests?.notes ?? '',
      startDate: _.first(requests?.timeoffDetails)?.date ?? null,
      endDate: _.last(requests?.timeoffDetails)?.date ?? null,
    },
    validate: {
      policyId: (value) =>
        genericValidators.notEmpty(value, 'You should select a policy'),
      startDate: (value) =>
        datesValidators.isValidDate(value, 'Start Date must be a valid date'),
      endDate: (value) =>
        datesValidators.isDateSameOrAfter(
          value,
          form.values.startDate,
          'End Date must be after start date',
        ),
      timeoffDetails: {
        date: null,
        hours: (value) =>
          genericValidators.notEmpty(
            value,
            'You have to set at least 1 hour',
          ) ||
          genericValidators.lessThanOrEqualTo(
            parseInt(value, 10),
            8,
            "Can't set more than 8 hours",
          ) ||
          genericValidators.notLessThan(
            parseInt(value, 10),
            1,
            'You have to set at least 1 hour',
          ),
      },
    },
  });

  const totalHours = form.values?.timeoffDetails?.reduce(
    (prev, curr) => prev + +curr.hours,
    0,
  );

  const updateDetails = (startDate, endDate) => {
    if (startDate && endDate) {
      const start = moment(startDate);
      const end = moment(endDate);

      const daysCount = end.diff(start, 'days') + 1;
      const dates = Array.from({ length: daysCount }, (i, index) =>
        start.clone().add(index, 'days').format('YYYY-MM-DD'),
      );

      const updated = dates.map((date) => {
        const existedDetailIndex = form.values.timeoffDetails?.findIndex(
          (item) => item?.date === date,
        );
        return {
          date,
          hours:
            existedDetailIndex === -1
              ? 8
              : form.values.timeoffDetails[existedDetailIndex]?.hours,
        };
      });

      form.setFieldValue('timeoffDetails', updated);
    }
  };

  const { timeOffBalance } = useLoadTimeOffBalance({
    startDate:
      form?.values?.startDate && form?.values?.endDate
        ? moment(form?.values?.startDate).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD'),
    endDate:
      form?.values?.startDate && form?.values?.endDate
        ? moment(form?.values?.endDate).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD'),
  });

  const groupedTimeOff = useMemo(
    () => _.groupBy(timeOffBalance, 'category.name'),
    [timeOffBalance],
  );

  return (
    <>
      <Box flex={2}>
        <form
          onSubmit={form.onSubmit((data) =>
            onSubmit(_.omit(data, withNotes ? '' : 'notes')),
          )}
          noValidate
        >
          <Space h="md" />
          <DatePickerFormInput
            from={form.values.startDate}
            to={form.values.endDate}
            onChangeFrom={(val) => form.setFieldValue('startDate', val)}
            onChangeTo={(val) => form.setFieldValue('endDate', val)}
            onUpdateRange={updateDetails}
            minDate={
              (form.values.startDate && form.values.endDate) ||
              (!form.values.startDate && !form.values.endDate)
                ? undefined
                : moment(form.values.startDate, 'YYYY-MM-DD').toDate()
            }
            errors={form.errors.startDate ?? form.errors.endDate}
            onClear={() => {
              form.setFieldValue('startDate', null);
              form.setFieldValue('endDate', null);
              form.setFieldValue('timeoffDetails', []);
            }}
            clearable
          />

          <Space h="md" />

          <SimpleGrid cols={{ base: 4 }} spacing={5}>
            {form.values.timeoffDetails.map((details, index) => (
              <NumberInput
                key={details.date}
                mb="md"
                allowNegative={false}
                label={details.date}
                placeholder="Hours"
                max={24}
                rightSection={<IconClockHour3 size={20} color="black" />}
                {...form.getInputProps(`timeoffDetails.${index}.hours`)}
              />
            ))}
          </SimpleGrid>

          <Flex gap={8}>
            <Text size="sm">Total: </Text>
            <Pill size="sm">
              <Text size="sm" fw={500}>
                {totalHours}h
              </Text>
            </Pill>
          </Flex>

          <Space h="md" />

          <Select
            label="Select Policy"
            placeholder="Select Policy"
            data={policies?.map((item) => ({
              value: `${item.id}`,
              label: item.name,
            }))}
            defaultValue={form.values.policyId}
            onChange={(value) => form.setFieldValue('policyId', value)}
            error={form.errors.policyId}
            clearable
          />

          {withNotes && (
            <Textarea
              placeholder="Notes"
              mt="md"
              w="100%"
              resize="vertical"
              rows={5}
              {...form.getInputProps('notes')}
            />
          )}
          <Button type="submit" fullWidth mt="xl" loading={isLoading}>
            Submit
          </Button>
        </form>
      </Box>
      <Box pos="relative">
        <Paper
          radius="md"
          style={{ overflow: 'hidden' }}
          title="Balance"
          withBorder
          miw={350}
          h="content"
          pos="sticky"
          right={0}
          top={70}
        >
          <Box
            p={10}
            style={{ backgroundColor: 'var(--mantine-color-gray-1)' }}
          >
            <Text fw={500}>
              Balance as of{' '}
              {form?.values?.startDate && form?.values?.endDate
                ? moment(form?.values?.startDate).format('YYYY-MM-DD')
                : moment().format('YYYY-MM-DD')}
            </Text>
          </Box>

          {_.keys(groupedTimeOff).map((category) => {
            const availableBalance = _.reduce(
              groupedTimeOff[category],
              (prev, curr) => prev + (_.last(curr.accrued_time).balance ?? 0),
              0,
            );

            return (
              <Stack key={category} p={10} gap={5}>
                <Flex gap={5}>
                  <Text fw={500} span="true">
                    {category}
                  </Text>
                  <Pill span="true" fz="sm">
                    {availableBalance}h available
                  </Pill>
                </Flex>
                <Stack gap={5} pl={10}>
                  {_.map(groupedTimeOff[category], (policy) => (
                    <Text key={policy?.id}>
                      {policy?.name}
                      <Pill ml={5} span="true" fz="sm">
                        {_.last(policy?.accrued_time)?.balance}h
                      </Pill>
                    </Text>
                  ))}
                </Stack>
              </Stack>
            );
          })}
        </Paper>
      </Box>
    </>
  );
}

TimeOffRecordForm.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  policies: PropTypes.array,
  withNotes: PropTypes.bool,
  requests: PropTypes.object,
};

TimeOffRecordForm.defaultProps = {
  onSubmit: () => {},
  isLoading: false,
  withNotes: false,
  policies: [],
  requests: {},
};
