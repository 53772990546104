import { Avatar, Flex, Stack, Text } from '@mantine/core';
import _ from 'lodash';
import PropTypes from 'prop-types';

import classes from '../../Home.module.css';

export default function OutList({ label, outs, outDate }) {
  return (
    <Stack gap={0} className={classes.card__content}>
      <Text fz="xl" fw={500} c="var(--mantine-color-gray-6)">
        {label}
      </Text>
      {_.isEmpty(outs) && (
        <Text c="var(--mantine-color-gray-6)">
          Nobody requested time off for {label}
        </Text>
      )}
      <Stack gap={5}>
        {_.map(outs, (out) => (
          <Flex
            key={`${out?.id} ${out?.name}`}
            align="center"
            gap={10}
            justify="space-between"
          >
            <Flex gap={10} align="center">
              <Avatar src={out?.avatar_filename} size={50} />
              <Stack gap={0} align="space-between">
                <Text>{out?.name}</Text>
                <Text c="var(--mantine-color-gray-6)">
                  {outDate?.format?.('MMMM DD')}
                </Text>
              </Stack>
            </Flex>
          </Flex>
        ))}
      </Stack>
    </Stack>
  );
}

OutList.propTypes = {
  label: PropTypes.string.isRequired,
  outs: PropTypes.array,
  outDate: PropTypes.object,
};

OutList.defaultProps = {
  outs: [],
  outDate: {},
};
