import { Box, Table } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { ContextMenu } from 'components/ContextMenu';
import PropTypes from 'prop-types';

export default function CompensationRecordRow({
  compensationRecord,
  onEdit,
  onDelete,
}) {
  const menuItems = [
    {
      label: 'Edit',
      icon: IconEdit,
      onClick: () => {
        onEdit(compensationRecord);
      },
    },
    {
      label: 'Delete',
      icon: IconTrash,
      onClick: () => {
        onDelete(compensationRecord);
      },
    },
  ];
  return (
    <Table.Tr>
      <Table.Td style={{ position: 'relative' }}>
        {compensationRecord?.active && (
          <Box
            pos="absolute"
            left={-2}
            top="50%"
            bg="green"
            w={7}
            h={7}
            style={{ transform: 'translateY(-50%)', borderRadius: '50%' }}
          />
        )}
        {compensationRecord?.effective_date}
      </Table.Td>
      <Table.Td>{compensationRecord?.pay_schedule?.name}</Table.Td>
      <Table.Td>{compensationRecord?.type_name}</Table.Td>
      <Table.Td>{compensationRecord?.pay_rate}</Table.Td>
      <Table.Td>{compensationRecord?.reason?.name}</Table.Td>
      <Table.Td>{compensationRecord?.comment}</Table.Td>
      <Table.Td ta="right">
        <ContextMenu menuItems={menuItems} />
      </Table.Td>
    </Table.Tr>
  );
}

CompensationRecordRow.propTypes = {
  compensationRecord: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
