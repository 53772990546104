import OrganizationChart from '@dabeng/react-orgchart';
import { Button, Flex } from '@mantine/core';
import { IconZoomIn, IconZoomOut } from '@tabler/icons-react';
import html2canvas from 'html2canvas';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import CustomNode from './CustomNode';
import './index.css';

export default function OrgChart({ data }) {
  const orgChart = useRef();
  const ref = useRef();
  const downloadButton = useRef();

  const [scale, setScale] = useState(1);

  const exportToPdf = () => {
    orgChart.current.exportTo('organization', 'pdf');
  };

  const exportToPng = () => {
    const chart = ref.current?.getElementsByClassName?.('orgchart')?.[0];
    if (chart) {
      html2canvas(chart, {
        onclone: (d, el) => {
          // fix wrong image quality if transform scale
          /* eslint no-param-reassign: "error" */
          el.style.transform = '';
        },
      }).then((canvas) => {
        const url = canvas.toDataURL();
        downloadButton.current.href = url;
        downloadButton.current.click();
      });
    }
  };

  useEffect(() => {
    const chart = ref.current?.getElementsByClassName?.('orgchart')?.[0];
    if (chart) {
      chart.style.transform = `scale(${scale})`;
    }
  }, [scale]);

  return (
    <>
      <Flex gap={10}>
        <Button disabled={_.isEmpty(data)} type="button" onClick={exportToPng}>
          Export to png
        </Button>
        <Button disabled={_.isEmpty(data)} type="button" onClick={exportToPdf}>
          Export to pdf
        </Button>
      </Flex>
      <a
        ref={downloadButton}
        style={{ display: 'none' }}
        download="org-chart.png"
        href="/"
      >
        -
      </a>
      <Flex
        gap={10}
        pos="sticky"
        mr={20}
        top={130}
        justify="flex-end"
        right={10}
        style={{ zIndex: 1000 }}
      >
        <IconZoomIn
          onClick={() => setScale((prev) => prev + 0.1)}
          className="zoomButton"
        />
        <IconZoomOut
          onClick={() => setScale((prev) => prev - 0.1)}
          className="zoomButton"
        />
      </Flex>
      <div ref={ref} style={{ display: 'flex', position: 'relative' }}>
        {!_.isEmpty(data) && (
          <OrganizationChart
            ref={orgChart}
            datasource={{ id: -1, name: 'Admin', children: data }}
            NodeTemplate={CustomNode}
            containerClass="chartContainer"
          />
        )}
      </div>
    </>
  );
}

OrgChart.propTypes = {
  data: PropTypes.array.isRequired,
};
