import { Button, Select, Stack, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { formatSelectorDataObj, genericValidators } from 'utils';
import { datePeriods } from 'utils/constants';

export default function PayScheduleForm({
  onSubmit,
  isLoading,
  payScheduleState,
  frequencyObjectList,
}) {
  const form = useForm({
    initialValues: {
      name: payScheduleState?.name ?? '',
      frequency: _.toString(payScheduleState?.settings?.frequency) ?? '',
      pay_period: _.toString(payScheduleState?.settings?.pay_period) ?? '',
      pay_days: _.toString(payScheduleState?.settings?.pay_days) ?? '',
    },
    validate: {
      name: (val) => genericValidators.notEmpty(val),
      frequency: (val) => genericValidators.notEmpty(val),
      pay_period: (val) => genericValidators.notEmpty(val),
      pay_days: (val) => genericValidators.notEmpty(val),
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)} noValidate>
      <Stack gap={10}>
        <TextInput
          label="Name"
          placeholder="Name"
          {...form.getInputProps('name')}
          required
        />
        <Select
          placeholder="Select Frequency"
          label="Frequency"
          data={formatSelectorDataObj(frequencyObjectList)}
          allowDeselect={false}
          {...form.getInputProps('frequency')}
          required
        />
        <Select
          placeholder="Select Pay periods end of the..."
          label="Pay periods end on the..."
          data={datePeriods.fullMonth()}
          allowDeselect={false}
          {...form.getInputProps('pay_period')}
          required
        />
        <Stack gap={0}>
          <Select
            label="Pay day are..."
            placeholder="Select Pay day"
            data={Array.from({ length: 15 }, (v, i) => `${i + 1}`)}
            allowDeselect={false}
            {...form.getInputProps('pay_days')}
            required
          />
          <Text c="var(--mantine-color-gray-6)" fz={14}>
            day(s) after the period ends
          </Text>
        </Stack>
        <Button ml="auto" type="submit" disabled={isLoading}>
          Submit
        </Button>
      </Stack>
    </form>
  );
}

PayScheduleForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  frequencyObjectList: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  payScheduleState: PropTypes.object,
};

PayScheduleForm.defaultProps = {
  isLoading: false,
  payScheduleState: {},
};
