import {
  Avatar,
  Box,
  Button,
  Flex,
  Modal,
  Paper,
  Text,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useState } from 'react';

import { useDisclosure, useToggle } from '@mantine/hooks';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { ContextMenu } from 'components/ContextMenu';
import moment from 'moment';
import PropTypes from 'prop-types';
import { genericValidators } from 'utils/validators';

export default function NoteContainer({ note, onDelete, onUpdateNote }) {
  const { user } = note;
  const [isModalOpened, { close: closeModal, open: openModal }] =
    useDisclosure(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [mode, toggleMode] = useToggle(['display', 'edit']);
  const form = useForm({
    initialValues: { noteText: note?.text ?? '' },
    validate: {
      noteText: (val) =>
        genericValidators.notEmpty(val, 'Should not be empty') ||
        genericValidators.lessThanOrEqualTo(
          val,
          255,
          'Should be less than 255 characters',
        ),
    },
  });

  const cancelEdit = () => {
    toggleMode();
    form.setFieldValue('noteText', note.text);
  };

  const onEdit = (values) => {
    setIsUpdating(true);
    onUpdateNote(note.id, values, () => {
      setIsUpdating(false);
      cancelEdit();
    });
  };

  return (
    <Paper withBorder p="lg">
      <Flex gap={10}>
        <Avatar src={user?.avatar_filename} size={45} />
        <Flex direction="column" wrap="wrap" style={{ flexGrow: 1 }}>
          <Text fz="md">{`${user.first_name} ${user.last_name}`}</Text>
          <Text fz="xs" dimmed="true">
            {note?.created_at &&
              moment(note.created_at).format('MMMM Do YYYY, h:mm:ss a')}
          </Text>
          {mode === 'display' && (
            <Text mt={10} size="sm" style={{ whiteSpace: 'pre-wrap' }}>
              {note?.text}
            </Text>
          )}

          {mode === 'edit' && (
            <form onSubmit={form.onSubmit(onEdit)} noValidate>
              <Textarea
                placeholder="Write your note here... "
                value={form.values.noteText}
                onChange={(event) =>
                  form.setFieldValue('noteText', event.currentTarget.value)
                }
                error={form.errors.noteText}
                required
              />
              <Flex gap={10}>
                <Button
                  disabled={!form.values.noteText?.length === 0}
                  type="submit"
                  mt={20}
                  variant="filled"
                  loading={isUpdating}
                >
                  Edit
                </Button>
                <Button mt={20} variant="outline" onClick={cancelEdit}>
                  Cancel
                </Button>
              </Flex>
            </form>
          )}
        </Flex>
        <Box w={15} h={15}>
          <ContextMenu
            menuItems={[
              {
                label: 'Edit',
                icon: IconEdit,
                onClick: () => {
                  cancelEdit();
                },
              },
              {
                label: 'Delete',
                icon: IconTrash,
                onClick: () => {
                  openModal();
                },
              },
            ]}
          />
        </Box>
      </Flex>

      <Modal
        opened={isModalOpened}
        onClose={closeModal}
        size="auto"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        title="Warning"
      >
        <Text>Are you sure you want to delete this note?</Text>
        <Flex mt={20} justify="center" align="center" gap="xl">
          <Button
            onClick={() => {
              onDelete(note?.id);
              closeModal();
            }}
            variant="filled"
            color="red"
          >
            Delete
          </Button>
          <Button onClick={closeModal} variant="outline">
            Cancel
          </Button>
        </Flex>
      </Modal>
    </Paper>
  );
}

NoteContainer.propTypes = {
  note: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdateNote: PropTypes.func.isRequired,
};
