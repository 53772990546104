import { Flex, Select } from '@mantine/core';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { usePolicyTypeSelect } from 'components/TimeOff/hooks';

export default function Weekly({ formValues, onChange, scheduleType }) {
  const weeklyValues = usePolicyTypeSelect({
    onChange,
    formValues,
    scheduleType,
  });

  const weekdays = moment.weekdays();

  return (
    <Flex gap={5} align="center">
      on{' '}
      <Select
        value={weeklyValues?.week}
        onChange={(val) => onChange('settings.accrue.period.weekly.week', val)}
        data={weekdays}
        allowDeselect={false}
        placeholder="Select Weekday"
      />
    </Flex>
  );
}

Weekly.propTypes = {
  formValues: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  scheduleType: PropTypes.string.isRequired,
};
