import {
  Button,
  Flex,
  LoadingOverlay,
  Modal,
  Space,
  Table,
  Tabs,
  Text,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';
import {
  useAddCandidateToPool,
  useDeleteCandidateToPool,
  useTalentGroup,
} from 'api/hooks';
import { AddCandidateForm } from 'components/AddCandidateForm';
import { useNotifications } from 'notifications/hooks/useNotifications';
import PropTypes from 'prop-types';
import { usePageTitle, useTabNavigate } from 'utils/hooks';

import { tabNames } from 'utils/constants';
import { PageWrapper } from '../../components/PageWrapper';
import { PoolCandidatesTableRow } from '../hiring/tab-rows';

export default function TalentGroupProfile({ params, globalTabName }) {
  const { groupId } = params;
  const { setNotifications } = useNotifications();
  const { talentGroupDetails, isLoading, error, refetch } =
    useTalentGroup(groupId);
  const navigate = useTabNavigate();
  const navigateToEdit = (id) => {
    navigate(`/hiring/talent-group/${id}/edit/`, {
      tabName: tabNames.editTalentGroup,
    });
  };

  const [opened, { open, close }] = useDisclosure(false);

  const { addCandidateToPool } = useAddCandidateToPool({
    onSuccess: () => {
      close();
      refetch();
      setNotifications(['The candidate has been successfully added to Pool']);
    },
  });

  const {
    deleteCandidateToPool,
    isLoading: isDeleteLoading,
    variables,
  } = useDeleteCandidateToPool({
    onSuccess: () => {
      setNotifications([
        'The candidate has been successfully deleted from Pool',
      ]);
      refetch();
    },
  });
  const onDelete = (item) => {
    deleteCandidateToPool({ poolId: groupId, candidateId: item.id });
  };

  const submitForm = (values) => {
    addCandidateToPool({
      id: groupId,
      payload: {
        candidates: values.searchCandidates,
        description: values.description,
      },
    });
  };

  usePageTitle(
    talentGroupDetails?.name
      ? `Hiring - ${talentGroupDetails?.name} - Talent Groups`
      : 'Hiring - Talent Groups',
    globalTabName,
  );

  return (
    <PageWrapper
      title={`${talentGroupDetails?.name ?? ''}`}
      rightSection={
        <Button onClick={() => navigateToEdit(talentGroupDetails.id)}>
          Edit
        </Button>
      }
    >
      <LoadingOverlay
        visible={isLoading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <Text>{`${talentGroupDetails?.description ?? ''}`}</Text>

      {talentGroupDetails && (
        <Flex gap="md">
          <Flex w="100%">
            <Tabs
              defaultValue="candidates"
              w="100%"
              mt={20}
              keepMounted={false}
            >
              <Tabs.List>
                <Tabs.Tab value="candidates">
                  Candidates({talentGroupDetails?.candidates?.length ?? 0})
                </Tabs.Tab>
                <Tabs.Tab value="interviews">
                  Interviews({talentGroupDetails?.interviews?.length ?? 0})
                </Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel value="candidates">
                <Space h="md" />
                <Button onClick={open} rightSection={<IconPlus size={14} />}>
                  Add Candidate
                </Button>
                <Space h="md" />
                <Table>
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th>Candidate info</Table.Th>
                      <Table.Th>Created at</Table.Th>
                      <Table.Th>Why they were added</Table.Th>
                      <Table.Th>Actions</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {talentGroupDetails?.candidates?.map((item) => (
                      <PoolCandidatesTableRow
                        onDelete={onDelete}
                        deleteId={variables?.candidateId}
                        isDeleteLoading={isDeleteLoading}
                        key={item.id}
                        item={item}
                      />
                    ))}
                  </Table.Tbody>
                </Table>
              </Tabs.Panel>
              <Tabs.Panel value="interviews">
                <Space h="md" />
                Interviews Content
              </Tabs.Panel>
            </Tabs>
          </Flex>
        </Flex>
      )}
      {error && <h3 style={{ textAlign: 'center' }}>Talent Group Not Found</h3>}

      <Modal
        opened={opened}
        onClose={close}
        title="Add candidate to Talent Group"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <AddCandidateForm onSubmit={submitForm} isSubmitLoading={isLoading} />
      </Modal>
    </PageWrapper>
  );
}

TalentGroupProfile.propTypes = {
  params: PropTypes.object,
  globalTabName: PropTypes.string,
};

TalentGroupProfile.defaultProps = {
  params: {},
  globalTabName: '',
};
