import { Paper, Stack } from '@mantine/core';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { IconReportSearch } from '@tabler/icons-react';
import GlobalSearchSection from './GlobalSearchSection';

export default function GlobalSearchList({ opened, data }) {
  const filteredData = _.groupBy(data, 'type');

  return (
    <Paper
      mah={opened ? 200 : 0}
      styles={{
        root: {
          overflow: _.isEmpty(data) ? 'hidden' : 'auto',
          transition: 'max-height 0.2s ease',
          borderBottom: '0px',
          borderRadius: '0px',
        },
      }}
      withBorder
      pt={opened ? 10 : 0}
    >
      {_.isEmpty(filteredData) && (
        <Stack
          gap={5}
          align="center"
          px={10}
          fw={500}
          w="80%"
          mx="auto"
          ta="center"
          size="xl"
        >
          <IconReportSearch stroke={1.5} size={50} />
          We couldn&apos;t find anything matching your search. Try again with a
          different term
        </Stack>
      )}

      <Stack gap={0} mb={5}>
        <GlobalSearchSection data={filteredData} />
      </Stack>
    </Paper>
  );
}

GlobalSearchList.propTypes = {
  opened: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
};
