import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Flex, Select, Stack, Text } from '@mantine/core';
import _ from 'lodash';
import moment from 'moment';

const carryOverDateSelectorData = [
  { label: '1st of January', value: '0' },
  { label: 'Employee hire date', value: '1' },
  { label: 'Other...', value: '2' },
];

export default function CarryOverRules({ formValues, onChange }) {
  const carryOverValues = formValues?.settings?.carryover_rules;

  const otherValue = carryOverDateSelectorData.find(
    (d) => d.value === '2',
  ).value;

  const days = _.map({ length: 28 }, (val, index) => ({
    label: `${index + 1}th`,
    value: `${index + 1}`,
  })).concat({ label: 'Last day', value: '-1' });

  const months = moment.months();

  return (
    <Stack gap={10}>
      <Text fw={600} fz={14}>
        Carryover rules
      </Text>
      <Flex align="end" gap={5}>
        <Select
          data={carryOverDateSelectorData}
          value={carryOverValues?.option}
          onChange={(val) =>
            onChange('settings.carryover_rules', {
              ...carryOverValues,
              option: val,
            })
          }
          label="What should the carryover date be?"
          w="max-content"
        />
        {carryOverValues?.option === otherValue && (
          <>
            <Text mb="8">on the</Text>
            <Select
              data={days}
              placeholder="day"
              value={carryOverValues?.carryover_date}
              onChange={(val) =>
                onChange('settings.carryover_rules', {
                  ...carryOverValues,
                  carryover_date: val,
                })
              }
            />
            <Text mb="8">day of</Text>
            <Select
              data={months}
              placeholder="day"
              value={carryOverValues?.carryover_month}
              onChange={(val) =>
                onChange('settings.carryover_rules', {
                  ...carryOverValues,
                  carryover_month: val,
                })
              }
            />
          </>
        )}
      </Flex>
      <Checkbox
        checked={!!(carryOverValues?.reset_negative_balance ?? 0)}
        onChange={() =>
          onChange('settings.carryover_rules', {
            ...carryOverValues,
            reset_negative_balance: carryOverValues?.reset_negative_balance
              ? 0
              : 1,
          })
        }
        label="Reset negative balances to 0 days on carryover date"
      />
    </Stack>
  );
}

CarryOverRules.propTypes = {
  formValues: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
