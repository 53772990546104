import { Flex, Space } from '@mantine/core';
import { clearCache } from 'api/clearCache';
import {
  useCreateCompensationBand,
  useCreateCompensationGroup,
  useDeleteCompensationBand,
  useDeleteCompensationGroup,
  useLoadCompensationGroups,
  useLoadLookups,
  useUpdateCompensationBand,
  useUpdateCompensationGroup,
} from 'api/hooks';
import { AddButton } from 'components/Buttons';
import { ConfirmDeleteModal } from 'components/ConfirmDeleteModal';
import { CreateEditBandModal } from 'components/CreateEditBandModal';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import { OneFieldModal } from 'components/OneFieldModal';
import _ from 'lodash';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { useQueryClient } from 'react-query';
import { lookupsModels } from 'utils/constants';
import { useStateDisclosure } from 'utils/hooks';
import { CompensationGroup, CompensationGroupSkeleton } from './components';

export default function CompensationTab() {
  const queryClient = useQueryClient();
  const { setNotifications } = useNotifications();

  const [
    openedGroupModal,
    { open: openGroupModal, close: closeGroupModal, state: groupModalState },
  ] = useStateDisclosure(false);

  const [
    openedBandModal,
    { close: closeBandModal, open: openBandModal, state: bandModalState },
  ] = useStateDisclosure(false);

  const [
    openedDeleteGroupModal,
    {
      open: openDeleteGroupModal,
      close: closeDeleteGroupModal,
      state: deleteGroupModalState,
    },
  ] = useStateDisclosure(false);

  const [
    openedDeleteBandModal,
    {
      open: openDeleteBandModal,
      close: closeDeleteBandModal,
      state: deleteBandModalState,
    },
  ] = useStateDisclosure(false);

  const { lookups } = useLoadLookups({
    models: [lookupsModels.jobTitle],
  });

  const { compensationGroups, isLoading: isCompensationGroupsLoading } =
    useLoadCompensationGroups();

  const { createGroup, isLoading: isCreating } = useCreateCompensationGroup({
    onSuccess: () => {
      clearCache.onChangeCompensationGroup(queryClient);
      setNotifications(['Compensation Group Created Successfully']);
      closeGroupModal();
    },
  });

  const { updateCompensationGroup, isLoading: isUpdatingCompensationGroup } =
    useUpdateCompensationGroup({
      onSuccess: () => {
        clearCache.onChangeCompensationGroup(queryClient);
        setNotifications(['Compensation Group Updated Successfully']);
        closeGroupModal();
      },
    });

  const { deleteCompensationGroup, isLoading: isDeleting } =
    useDeleteCompensationGroup({
      onSuccess: () => {
        clearCache.onChangeCompensationGroup(queryClient);
        setNotifications(['Compensation Group Deleted Successfully']);
        closeDeleteGroupModal();
      },
    });

  const { createCompensationBand, isLoading: isBandCreating } =
    useCreateCompensationBand({
      onSuccess: () => {
        closeBandModal();
        clearCache.onChangeCompensationBand(queryClient);
        setNotifications(['Compensation Band Created Successfully']);
      },
    });

  const { updateCompensationBand, isLoading: isUpdatingCompensationBand } =
    useUpdateCompensationBand({
      onSuccess: () => {
        closeBandModal();
        clearCache.onChangeCompensationBand(queryClient);
        setNotifications(['Compensation Band Updated Successfully']);
      },
    });

  const { deleteCompensationBand, isLoading: isDeletingCompensationBand } =
    useDeleteCompensationBand({
      onSuccess: () => {
        closeDeleteBandModal();
        clearCache.onChangeCompensationBand(queryClient);
        setNotifications(['Compensation Band Deleted Successfully']);
      },
    });

  const onCompensationBandAction = (data, action) => {
    const payload = {
      name: data?.name,
      min_salary: data?.minSalary,
      midpoint_salary: data?.midpointSalary,
      max_salary: data?.maxSalary,
      percentage: data?.percentage,
      group_id: data?.group_id,
      job_title_ids: data?.job_title_ids,
    };

    action(payload);
  };

  return (
    <>
      <Space h="md" />
      <Flex justify="flex-end" gap={10}>
        <AddButton onClick={() => openBandModal()}>Create Band</AddButton>
        <AddButton onClick={() => openGroupModal()}>Create Group</AddButton>
      </Flex>
      <EmptyDataMessage
        isVisible={
          _.isEmpty(compensationGroups) && !isCompensationGroupsLoading
        }
      />
      <CompensationGroupSkeleton isLoading={isCompensationGroupsLoading} />
      <OneFieldModal
        opened={openedGroupModal}
        onClose={closeGroupModal}
        state={groupModalState}
        onEdit={(data) =>
          updateCompensationGroup({ groupId: groupModalState?.id, ...data })
        }
        onCreate={createGroup}
        title="Group"
        formSettings={{
          label: 'Group Name',
          field: 'name',
        }}
        isLoading={isCreating || isUpdatingCompensationGroup}
      />
      <CreateEditBandModal
        opened={openedBandModal}
        onClose={closeBandModal}
        state={bandModalState}
        onCreate={(data) =>
          onCompensationBandAction(data, createCompensationBand)
        }
        onEdit={(data) =>
          onCompensationBandAction(data, (params) =>
            updateCompensationBand({ bandId: bandModalState?.id, ...params }),
          )
        }
        jobTitles={lookups[lookupsModels.jobTitle]}
        compensationGroups={compensationGroups}
        isLoading={isBandCreating || isUpdatingCompensationBand}
      />
      <ConfirmDeleteModal
        opened={openedDeleteGroupModal}
        onClose={closeDeleteGroupModal}
        onDelete={() => deleteCompensationGroup(deleteGroupModalState?.id)}
        isLoading={isDeleting}
      />
      <ConfirmDeleteModal
        opened={openedDeleteBandModal}
        onClose={closeDeleteBandModal}
        onDelete={() => deleteCompensationBand(deleteBandModalState?.id)}
        isLoading={isDeletingCompensationBand}
      />
      {_.map(compensationGroups, (group) => (
        <CompensationGroup
          key={group?.id}
          group={group}
          onEditGroup={(data) => openGroupModal({ ...data, modalType: 'Edit' })}
          onEditBand={(data) => openBandModal({ ...data, modalType: 'Edit' })}
          onDeleteGroup={openDeleteGroupModal}
          onDeleteBand={openDeleteBandModal}
        />
      ))}
    </>
  );
}
