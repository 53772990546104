import {
  Accordion,
  Button,
  Divider,
  Flex,
  Paper,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import {
  IconCheckbox,
  IconClock,
  IconEdit,
  IconTrash,
} from '@tabler/icons-react';
import { ConfirmDeleteModal } from 'components/ConfirmDeleteModal';
import { ContextMenu } from 'components/ContextMenu';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useStateDisclosure } from 'utils/hooks';

const dueDaysStringFormats = {
  0: 'Due on hire date',
  format: (days) =>
    `Due ${Math.abs(days)} days ${days > 0 ? 'after' : 'before'} hire date`,
};

const deleteTypes = {
  list: 'list',
  task: 'task',
};

export default function BoardingListView({
  groupedList,
  onEditTask,
  onEditList,
  onDeleteTask,
  onDeleteList,
}) {
  const [openedValue, setOpenedValue] = useState('');

  const [
    openedDeleteModal,
    { open: openDeleteModal, close: closeDeleteModal, state: deleteState },
  ] = useStateDisclosure(false);

  const onConfirmDelete = useCallback(async () => {
    const deleteTypesFunc = {
      [deleteTypes.list]: onDeleteList,
      [deleteTypes.task]: onDeleteTask,
    };

    await deleteTypesFunc[deleteState?.deleteType]?.(deleteState);
    closeDeleteModal();
  }, [onDeleteList, onDeleteTask, closeDeleteModal, deleteState]);

  return (
    <Accordion value={openedValue} onChange={setOpenedValue} chevron={null}>
      <ConfirmDeleteModal
        opened={openedDeleteModal}
        onClose={closeDeleteModal}
        onDelete={onConfirmDelete}
        deleteItemName={deleteState?.name ?? undefined}
      />
      <Stack>
        {_.map(groupedList, (taskList) => (
          <Paper key={taskList?.name} radius="lg">
            <Accordion.Item style={{ border: 'none' }} value={taskList?.name}>
              <Accordion.Control
                py={10}
                styles={() => ({
                  control: {
                    background: 'var(--mantine-color-gray-1)',
                    borderRadius: '20px',
                  },
                })}
              >
                <Flex justify="space-between" align="center">
                  <Title fw={500} fz={18}>
                    {taskList?.name}
                  </Title>
                  <Flex gap={10} onClick={(e) => e.stopPropagation()}>
                    {openedValue === taskList?.name && (
                      <>
                        <Button
                          onClick={() => onEditList(taskList)}
                          component="div"
                        >
                          Edit
                        </Button>
                        <Button
                          component="div"
                          onClick={() =>
                            openDeleteModal({
                              ...taskList,
                              deleteType: deleteTypes.list,
                            })
                          }
                        >
                          Delete
                        </Button>
                      </>
                    )}
                  </Flex>
                  {openedValue !== taskList?.name && (
                    <Text w="max-content">{taskList?.tasks?.length} tasks</Text>
                  )}
                </Flex>
              </Accordion.Control>
              <Accordion.Panel>
                <Stack gap={10} mt={8} px={10}>
                  {_.map(taskList?.tasks, (task) => {
                    const menuItems = [
                      {
                        label: 'Edit',
                        icon: IconEdit,
                        onClick: () =>
                          onEditTask({
                            ...task,
                            tasklist_name: taskList?.name,
                          }),
                      },
                      {
                        label: 'Delete',
                        icon: IconTrash,
                        onClick: () =>
                          openDeleteModal({
                            ...task,
                            deleteType: deleteTypes.task,
                          }),
                      },
                    ];
                    const dueDateFormattedString =
                      _.isNumber(task?.date_due) && task?.date_due !== 0
                        ? dueDaysStringFormats.format(task?.date_due)
                        : dueDaysStringFormats[task?.date_due] ?? '';
                    return (
                      <React.Fragment key={task?.id}>
                        <Flex gap={10} justify="space-between">
                          <Flex gap={20}>
                            <IconCheckbox color="green" />
                            <div>{task?.name}</div>
                          </Flex>
                          <Flex gap={10} align="center">
                            {dueDateFormattedString && <IconClock />}
                            {dueDateFormattedString}
                            <ContextMenu menuItems={menuItems} />
                          </Flex>
                        </Flex>
                        <Divider />
                      </React.Fragment>
                    );
                  })}
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>
          </Paper>
        ))}
      </Stack>
    </Accordion>
  );
}

BoardingListView.propTypes = {
  groupedList: PropTypes.array,
  onEditTask: PropTypes.func,
  onEditList: PropTypes.func,
  onDeleteTask: PropTypes.func,
  onDeleteList: PropTypes.func,
};

BoardingListView.defaultProps = {
  groupedList: [],
  onEditTask: () => {},
  onEditList: () => {},
  onDeleteTask: () => {},
  onDeleteList: () => {},
};
