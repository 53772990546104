import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import { useCallback, useEffect } from 'react';
import { useIsFirstRender } from 'utils/hooks';

import { defaultFilterValues } from '../constants';

const useReadDataFromQuery = ({ filterHandlers, locationSearch }) => {
  const isFirstRender = useIsFirstRender();

  const isValidDate = useCallback(
    (data) => new Date(data).toString() !== 'Invalid Date',
    [],
  );

  useEffect(() => {
    if (isFirstRender) {
      const {
        locations: loc,
        employeeStatus,
        sortField,
        sortSortBy,
        from,
        to,
      } = queryString.parse(locationSearch);

      filterHandlers.setSelectedLocation(
        _.isArray(loc) ? loc : _.toArray(loc ?? defaultFilterValues.locations),
      );
      filterHandlers.setSelectedStatus(
        employeeStatus ?? defaultFilterValues.employeeStatus,
      );
      filterHandlers.setSort({
        field: sortField ?? defaultFilterValues.sort.field,
        sortBy: sortSortBy ?? defaultFilterValues.sort.sortBy,
      });

      filterHandlers.setDateRange({
        from: isValidDate(from)
          ? moment(new Date(from))
          : defaultFilterValues.date.from,
        to: isValidDate(to)
          ? moment(new Date(to))
          : defaultFilterValues.date.to,
      });
    }
  }, [isFirstRender, locationSearch, filterHandlers, isValidDate]);
};

export default useReadDataFromQuery;
