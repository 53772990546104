import { Button, Flex, LoadingOverlay, Space, Text } from '@mantine/core';
import { IconClipboardPlus } from '@tabler/icons-react';

import { modals } from '@mantine/modals';
import {
  useCreateSurvey,
  useDeleteSurvey,
  useLoadLookups,
  useSurveys,
} from 'api/hooks';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { useStateDisclosure, useTabNavigate } from 'utils/hooks';

import { SurveyModal, SurveysTable } from 'components/Survey';
import { lookupsModels, tabNames } from 'utils/constants';

export default function PerformanceManagementTab() {
  const { setNotifications } = useNotifications();
  const navigate = useTabNavigate();

  const { lookups } = useLoadLookups({ models: [lookupsModels.survey] });

  const { surveys, isLoading, refetch: refetchSurveys } = useSurveys();

  const [isModalOpened, { open: openModal, close: closeModal }] =
    useStateDisclosure(false);

  const { createSurvey, isLoading: isCreatingSurvey } = useCreateSurvey({
    onSuccess: () => {
      refetchSurveys();
      closeModal();
      setNotifications(['Survey successfully created']);
    },
  });
  const {
    deleteSurvey,
    isLoading: isDeletingSurvey,
    variables: surveyId,
  } = useDeleteSurvey({
    onSuccess: () => {
      refetchSurveys();
      setNotifications(['Survey successfully deleted']);
    },
  });
  const onSurveySubmit = (values) => {
    createSurvey(values);
  };

  const onSurveyEdit = (survey) => {
    navigate(`/settings/surveys/${survey.id}/edit`, {
      tabName: tabNames.settingsSurveys,
    });
  };

  const onSurveyDelete = (survey) => {
    modals.openConfirmModal({
      title: 'Delete Survey',
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete survey{' '}
          <Text span fw={500}>
            {survey.title}
          </Text>
        </Text>
      ),
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: () => {
        deleteSurvey(survey.id);
      },
    });
  };

  return (
    <>
      <LoadingOverlay
        visible={isLoading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <SurveyModal
        onSubmit={onSurveySubmit}
        isLoading={isCreatingSurvey}
        opened={isModalOpened}
        onClose={() => closeModal()}
        surveyTypes={lookups?.[lookupsModels.survey]?.types}
      />
      <Space h="md" />
      <Flex gap="md" justify="flex-end" align="center">
        <Button
          onClick={() => openModal()}
          leftSection={<IconClipboardPlus size={20} />}
          disabled={isLoading}
        >
          Create Survey
        </Button>
      </Flex>
      <Space h="xxl" />
      <SurveysTable
        surveys={surveys}
        onEditSurvey={onSurveyEdit}
        onDeleteSurvey={onSurveyDelete}
        deleteState={{ isDeleting: isDeletingSurvey, itemId: surveyId }}
      />
    </>
  );
}
