import { Modal } from '@mantine/core';
import React from 'react';
import PropTypes from 'prop-types';
import { JobInformationCommonForm } from 'components/JobInformationCommonForm';
import { useCreateJobTitle, useUpdateJobTitle } from 'api/hooks';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { clearCache } from 'api/clearCache';
import { useQueryClient } from 'react-query';

export default function CreateEditJobTitleModal({ opened, onClose, jobTitle }) {
  const { setNotifications } = useNotifications();
  const queryClient = useQueryClient();
  const { createJobTitle, isLoading: isCreating } = useCreateJobTitle({
    onSuccess: () => {
      setNotifications(['Job Title Created Successfully']);
      clearCache.onJobTitleChange(queryClient);
      onClose();
    },
  });

  const { updateJobTitle, isLoading: isUpdating } = useUpdateJobTitle({
    onSuccess: () => {
      setNotifications(['Job Title Updated Successfully']);
      clearCache.onJobTitleChange(queryClient);
      onClose();
    },
  });

  return (
    <Modal title="Job Title" opened={opened} onClose={onClose} centered>
      <JobInformationCommonForm
        label="Job Title"
        name={jobTitle?.name ?? ''}
        onSubmit={
          jobTitle?.modalType === 'Edit'
            ? (payload) => updateJobTitle({ id: jobTitle.id, payload })
            : createJobTitle
        }
        isLoading={isCreating || isUpdating}
        type={jobTitle?.modalType}
      />
    </Modal>
  );
}

CreateEditJobTitleModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  jobTitle: PropTypes.object,
};

CreateEditJobTitleModal.defaultProps = {
  jobTitle: {},
};
