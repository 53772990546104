import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from '@mantine/form';
import _ from 'lodash';
import { DateFormInput } from 'components/DateFormInput';
import {
  Button,
  Divider,
  Flex,
  NumberInput,
  Select,
  Stack,
  Title,
} from '@mantine/core';
import {
  formatSelectorData,
  formatSelectorDataObj,
  genericValidators,
} from 'utils';
import { useLoadJobTitles, useLoadOfferTemplates } from 'api/hooks';
import { IconCurrencyDollar, IconEye } from '@tabler/icons-react';
import { FormEditorField } from 'components/FormEditorField';
import { ConfirmDeleteModal } from 'components/ConfirmDeleteModal';
import { useDisclosure } from '@mantine/hooks';
import { EmployeeAutocomplete } from 'components/AutocompleteSelectors';

export default function CandidateOfferForm({
  offerState,
  onSubmit,
  onCancelOffer,
  meta,
}) {
  const { jobTitles } = useLoadJobTitles();

  const [
    openedConfirmCancelOffer,
    { open: openConfirmCancelOffer, close: closeConfirmCancelOffer },
  ] = useDisclosure(false);

  const form = useForm({
    initialValues: {
      start_date: offerState?.start_date ?? '',
      expiry_date: offerState?.expiry_date ?? '',
      job_information: offerState?.job_information,

      pay_rate: _.toString(offerState?.pay_rate),
      offerTemplateMessage: _.toString(offerState?.message),
      letterTemplateMessage: _.toString(offerState?.letter),
      message: _.toString(offerState?.message),
      letter: _.toString(offerState?.letter),
      pay_frequency: {
        value: _.toString(offerState?.pay_frequency?.id),
        ...(offerState?.pay_frequency ?? {}),
      },
      contact: offerState?.contact,
      subject: offerState?.subject,
    },
    validate: {
      start_date: (val) =>
        val instanceof Date
          ? false
          : genericValidators.notEmpty(val, 'Start Date is empty'),
      expiry_date: (val) =>
        val instanceof Date
          ? false
          : genericValidators.notEmpty(val, 'Expiration Date is empty'),
      job_information: (val) =>
        genericValidators.notEmpty(val?.id, 'Job Title is empty'),
      pay_rate: (val) => genericValidators.notEmpty(val, 'Pay Rate is empty'),
      message: (val) => genericValidators.notEmpty(val, 'Offer is empty'),
      letter: (val) => genericValidators.notEmpty(val, 'Letter is empty'),
      pay_frequency: (val) =>
        genericValidators.notEmpty(val?.value, 'Pay Frequency is empty'),
      contact: (val) =>
        _.isEmpty(val) ? 'Who To Contact Field is empty' : null,
    },
  });

  useLoadOfferTemplates({
    enabled:
      !offerState?.message || !offerState?.letter || !!offerState?.subject,
    onSuccess: (data) => {
      const selectedTemplate = data?.find(
        (template) => +template.id === +offerState.templateId,
      );

      if (!offerState?.subject) {
        form.setFieldValue('subject', selectedTemplate?.subject);
      }

      if (!offerState?.message) {
        form.setFieldValue('offerTemplateMessage', selectedTemplate?.message);
        form.setFieldValue(
          'message',
          offerState?.message ?? selectedTemplate?.message,
        );
      }
      if (!offerState?.letter) {
        form.setFieldValue('letterTemplateMessage', selectedTemplate?.letter);
        form.setFieldValue(
          'letter',
          offerState?.letter ?? selectedTemplate?.letter,
        );
      }
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)} noValidate>
      <Stack>
        <ConfirmDeleteModal
          opened={openedConfirmCancelOffer}
          onClose={closeConfirmCancelOffer}
          confirmButtonLabel="Yes, Cancel"
          cancelButtonLabel="No"
          customText="Are you sure you want to cancel this offer?"
          onDelete={() => {
            closeConfirmCancelOffer();
            onCancelOffer();
          }}
        />
        <DateFormInput
          minDate={new Date()}
          field={{
            displayName: 'Expiration Date',
            placeholder: 'Select Expiration Date',
          }}
          required
          {...form.getInputProps('expiry_date')}
        />
        <Divider />
        <Title size={15}>Job Info</Title>

        <DateFormInput
          required
          minDate={new Date()}
          field={{
            displayName: 'Start Date',
            placeholder: 'Select Start Date',
          }}
          {...form.getInputProps('start_date')}
        />

        <Select
          required
          placeholder="Job Title"
          label="Job Title"
          data={formatSelectorData(jobTitles, {
            value: 'id',
            label: 'name',
          })}
          {...form.getInputProps('job_information')}
          onChange={(val, option) =>
            form.setFieldValue('job_information', option ?? {})
          }
          value={_.toString(form.values.job_information?.id)}
        />

        <Divider />

        <Title size={15}>Compensation</Title>

        <NumberInput
          required
          label="Pay Rate"
          placeholder="Enter Pay Rate"
          hideControls
          prefix="$"
          rightSection={<IconCurrencyDollar />}
          allowNegative={false}
          {...form.getInputProps('pay_rate')}
        />

        <Select
          required
          placeholder="Pay Frequency"
          label="Pay Frequency"
          data={formatSelectorDataObj(meta?.frequencies)}
          {...form.getInputProps('pay_frequency')}
          value={form.values.pay_frequency?.value}
          onChange={(val, opt) => form.setFieldValue('pay_frequency', opt)}
        />

        <EmployeeAutocomplete
          formValue={form.values.contact}
          defaultValue={offerState?.contact}
          label="Who to Contact"
          onChange={(val, opt) => form.setFieldValue('contact', opt)}
          error={form.errors.contact}
        />

        <FormEditorField
          required
          label="Offer Email"
          template={form.values.offerTemplateMessage}
          onChange={(val) => form.setFieldValue('message', val)}
          errors={form.errors.message}
        />

        <FormEditorField
          label="Letter"
          required
          template={form.values.letterTemplateMessage}
          onChange={(val) => form.setFieldValue('letter', val)}
          errors={form.errors.letter}
        />

        <Flex gap={5} justify="end">
          <Button
            disabled={Boolean(offerState?.templateId)}
            onClick={openConfirmCancelOffer}
          >
            Cancel Offer
          </Button>
          <Button rightSection={<IconEye size={15} />} type="submit">
            Preview & Submit
          </Button>
        </Flex>
      </Stack>
    </form>
  );
}

CandidateOfferForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancelOffer: PropTypes.func.isRequired,
  offerState: PropTypes.object,
  meta: PropTypes.object,
};

CandidateOfferForm.defaultProps = {
  offerState: {},
  meta: {},
};
