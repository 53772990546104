import { Button, Container, Group, Select, TextInput } from '@mantine/core';
import PropTypes from 'prop-types';
import { useForm } from '@mantine/form';
import { genericValidators, formValidators } from 'utils';
import { useCandidateSources } from 'api/hooks';
import _ from 'lodash';
import { useMemo } from 'react';
import { useCandidatesStatusSelectorData } from 'utils/api';

export default function CandidateForm({
  candidate,
  onSubmit,
  onDelete,
  isSubmitLoading,
  isDeleteLoading,
  jobId,
}) {
  const { candidateSource } = useCandidateSources();

  const form = useForm({
    initialValues: {
      job_id: jobId,
      first_name: _.toString(candidate?.first_name),
      last_name: _.toString(candidate?.last_name),
      email: _.toString(candidate?.email),
      linkedin: _.toString(candidate?.linkedin),
      phone: _.toString(candidate?.phone),
      portfolio: _.toString(candidate?.portfolio),
      address: _.toString(candidate?.address),
      candidate_source: _.toString(candidate?.candidate_source),
      candidate_status: _.toString(candidate?.candidate_status),
    },
    validate: {
      first_name: (value) =>
        genericValidators.notEmpty(value, 'First Name is empty') ||
        genericValidators.lessThanOrEqualTo(
          value,
          50,
          'First name needs to be less than 50 chars',
        ),
      last_name: (value) =>
        genericValidators.notEmpty(value, 'Last Name is empty') ||
        genericValidators.lessThanOrEqualTo(
          value,
          50,
          'Last name needs to be less than 50 chars',
        ),
      email: (value) =>
        formValidators.email(value) ||
        genericValidators.notEmpty(value, 'Email is empty'),
    },
  });

  const isEditAction = Boolean(candidate);

  const candidateSourceSelectorData = useMemo(() => {
    const data = _.map(candidateSource, 'name');
    if (
      candidate?.candidate_source &&
      !_.includes(data, candidate.candidate_source)
    ) {
      // if candidate source was deleted
      return data.concat(candidate.candidate_source);
    }
    return data;
  }, [candidateSource, candidate?.candidate_source]);

  const { candidateStatusSelectorData } = useCandidatesStatusSelectorData({
    selectedValue: candidate?.candidate_status,
  });

  const preSubmit = (values) => {
    const sourceIsChanged = form.isDirty('candidate_source');
    const statusIsChanged = form.isDirty('candidate_status');

    onSubmit({
      ...values,
      candidate_source: sourceIsChanged ? values?.candidate_source : undefined,
      candidate_status: statusIsChanged ? values?.candidate_status : undefined,
    });
  };

  return (
    <Container px={0} size="md">
      <form onSubmit={form.onSubmit(preSubmit)} noValidate>
        <TextInput
          mb="md"
          name="first_name"
          label="First Name"
          placeholder="Enter candidate's first name"
          value={form.values.first_name ?? ''}
          onChange={(event) =>
            form.setFieldValue('first_name', event.currentTarget.value)
          }
          error={form.errors.first_name}
        />
        <TextInput
          mb="md"
          name="last_name"
          label="Last Name"
          placeholder="Enter candidate's last name"
          value={form.values.last_name ?? ''}
          onChange={(event) =>
            form.setFieldValue('last_name', event.currentTarget.value)
          }
          error={form.errors.last_name}
        />
        <TextInput
          mb="md"
          name="email"
          label="Email"
          placeholder="Enter candidate's email"
          value={form.values.email ?? ''}
          onChange={(event) =>
            form.setFieldValue('email', event.currentTarget.value)
          }
          error={form.errors.email}
        />

        <TextInput
          {...form.getInputProps('linkedin')}
          mb="md"
          name="linkedin"
          label="Linkedin"
          placeholder="Enter candidate's Linkedin profile link"
        />

        <TextInput
          {...form.getInputProps('phone')}
          mb="md"
          name="phone"
          label="Phone"
          placeholder="Enter candidate's phone number"
        />
        <TextInput
          {...form.getInputProps('portfolio')}
          mb="md"
          name="portfolio"
          label="Portfolio"
          placeholder="Enter candidate's portfolio link"
        />
        <TextInput
          {...form.getInputProps('address')}
          mb="md"
          name="address"
          label="Address"
          placeholder="Enter candidate's address"
        />

        <Select
          label="Candidate Source"
          placeholder="Select candidate's source"
          data={candidateSourceSelectorData}
          searchable
          allowDeselect={false}
          {...form.getInputProps('candidate_source')}
          mb="md"
        />

        <Select
          label="Candidate Status"
          placeholder="Select candidate's status"
          data={candidateStatusSelectorData}
          searchable
          allowDeselect={false}
          {...form.getInputProps('candidate_status')}
        />

        <Group gap="xl" mt="xl" justify="center">
          {isEditAction && (
            <Button
              disabled={isDeleteLoading}
              loading={isSubmitLoading}
              type="submit"
            >
              Update
            </Button>
          )}
          {!isEditAction && (
            <Button loading={isSubmitLoading} type="submit">
              Create
            </Button>
          )}
          {isEditAction && (
            <Button
              disabled={isSubmitLoading}
              loading={isDeleteLoading}
              onClick={onDelete}
              variant="filled"
              color="red"
            >
              Delete
            </Button>
          )}
        </Group>
      </form>
    </Container>
  );
}

CandidateForm.propTypes = {
  candidate: PropTypes.object,
  jobId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  isSubmitLoading: PropTypes.bool,
  isDeleteLoading: PropTypes.bool,
};
CandidateForm.defaultProps = {
  candidate: null,
  onDelete: () => {},
  isSubmitLoading: false,
  isDeleteLoading: false,
};
