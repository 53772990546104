import { Button, Table } from '@mantine/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import { tabNames } from 'utils/constants';
import { useTabNavigate } from 'utils/hooks';
import classes from './Candidate.module.css';

export default function CandidateTableRow({ item }) {
  const navigate = useTabNavigate();
  const jobId = item?.job?.id;
  const navigateToProfile = (id) => {
    navigate(`/hiring/job/${jobId}/candidate/${id}`, {
      tabName: tabNames.hiringJobCandidate,
    });
  };
  const navigateToEdit = (id) => {
    navigate(`/hiring/job/${jobId}/candidate/${id}/edit/`, {
      tabName: tabNames.hiringJobCandidate,
    });
  };
  return (
    <Table.Tr key={item.id} className={[classes.rowSelected, classes.pointer]}>
      <Table.Td onClick={() => navigateToProfile(item.id)}>
        {item.first_name} {item.last_name}
      </Table.Td>
      <Table.Td>{item.candidate_status}</Table.Td>
      <Table.Td>{item.email}</Table.Td>
      <Table.Td>{item.interviews}</Table.Td>
      <Table.Td>{moment(item?.created_at).format('YYYY-MM-DD')}</Table.Td>
      <Table.Td>{moment(item?.updated_at).format('YYYY-MM-DD')}</Table.Td>
      <Table.Td>
        {jobId && <Button onClick={() => navigateToEdit(item.id)}>Edit</Button>}
      </Table.Td>
    </Table.Tr>
  );
}

CandidateTableRow.propTypes = {
  item: PropTypes.object.isRequired,
};
