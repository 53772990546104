import { Stack } from '@mantine/core';
import { IconCalendarEvent } from '@tabler/icons-react';
import { useLoadDashboardInfo } from 'api/hooks';
import _ from 'lodash';
import { OutList } from 'modules/home/sections/components';
import moment from 'moment';
import { Time } from 'utils/constants';
import { WidgetWrapper } from '.';

export default function WhoIsOut() {
  const { dashboardInfo, isLoading: isDashboardInfoLoading } =
    useLoadDashboardInfo({ staleTime: Time.WidgetStaleQueryTime });

  const { today, tomorrow } = _.get(dashboardInfo, 'outRecords', {});

  return (
    <WidgetWrapper
      isLoading={isDashboardInfoLoading}
      title="Who is out"
      Icon={<IconCalendarEvent size={30} color="white" />}
    >
      {!isDashboardInfoLoading && (
        <Stack gap={0}>
          <OutList label="Today" outs={today} outDate={moment()} />
          <OutList
            label="Tomorrow"
            outs={tomorrow}
            outDate={moment().add(1, 'days')}
          />
        </Stack>
      )}
    </WidgetWrapper>
  );
}
