import { Table } from '@mantine/core';
import _ from 'lodash';
import PropTypes from 'prop-types';

export default function SettingsTableHead({ headerList }) {
  return (
    <Table.Thead>
      <Table.Tr
        styles={{
          tr: { backgroundColor: 'var(--mantine-color-gray-4)' },
        }}
      >
        {_.map(headerList, (item) => (
          <Table.Th key={item}>{item}</Table.Th>
        ))}
      </Table.Tr>
    </Table.Thead>
  );
}

SettingsTableHead.propTypes = {
  headerList: PropTypes.array.isRequired,
};
