import {
  Space,
  LoadingOverlay,
  Flex,
  Tabs,
  Text,
  Table,
  Button,
  Pagination,
} from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { useJob } from 'api/hooks';
import { Notes } from 'modules/notes';
import { Files } from 'modules/files';
import PropTypes from 'prop-types';
import { usePageTitle, usePagination } from 'utils/hooks';
import { PageWrapper } from '../../components/PageWrapper';
import { CandidateTableRow } from '../hiring/tab-rows';

export default function JobProfile({ params, globalTabName }) {
  const { jobId } = params;

  const { page, setPage } = usePagination({ page: 1 });

  const { jobDetails, candidates, isLoading, pagination, error } = useJob({
    jobId,
    page,
  });
  const navigate = useNavigate();
  const navigateToEdit = (id) => {
    navigate(`/hiring/job/${id}/edit/`);
  };
  const navigateToAddCandidate = () => {
    navigate(`/hiring/job/${jobId}/candidate/create`);
  };

  usePageTitle(
    jobDetails?.title ? `Hiring - ${jobDetails?.title} - Job` : 'Hiring - Job',
    globalTabName,
  );

  return (
    <PageWrapper
      title={`${jobDetails?.title ?? ''}`}
      rightSection={
        jobDetails?.id ? (
          <Button onClick={() => navigateToEdit(jobDetails?.id)}>Edit</Button>
        ) : null
      }
    >
      <LoadingOverlay
        visible={isLoading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <Text>{`${jobDetails?.description ?? ''}`}</Text>

      {jobDetails && (
        <Flex gap="md">
          <Flex w="100%">
            <Tabs
              defaultValue="candidates"
              w="100%"
              mt={20}
              keepMounted={false}
            >
              <Tabs.List>
                <Tabs.Tab value="candidates">
                  Candidates({candidates?.length ?? 0})
                </Tabs.Tab>
                <Tabs.Tab value="interviews">
                  Interviews({jobDetails?.interviews?.length ?? 0})
                </Tabs.Tab>
                <Tabs.Tab value="notes">Notes</Tabs.Tab>
                <Tabs.Tab value="files">Files</Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel value="candidates">
                <Space h="md" />
                <Button
                  onClick={navigateToAddCandidate}
                  rightSection={<IconPlus size={14} />}
                >
                  Add Candidate
                </Button>
                <Space h="md" />
                <Table>
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th>Name</Table.Th>
                      <Table.Th>Status</Table.Th>
                      <Table.Th>Email</Table.Th>
                      <Table.Th>Interviews</Table.Th>
                      <Table.Th>Created at</Table.Th>
                      <Table.Th>Updated at</Table.Th>
                      <Table.Th>Actions</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {candidates?.map((item) => (
                      <CandidateTableRow key={item.id} item={item} />
                    ))}
                  </Table.Tbody>
                </Table>
                {pagination?.total_pages > 1 && (
                  <Flex justify="flex-end" mt={10}>
                    <Pagination
                      total={pagination?.total_pages}
                      value={page}
                      onChange={setPage}
                    />
                  </Flex>
                )}
              </Tabs.Panel>
              <Tabs.Panel value="interviews">
                <Space h="md" />
                Interviews Content
              </Tabs.Panel>
              <Tabs.Panel value="notes">
                <h2>Notes</h2>
                <Notes contentType="Job" contentId={jobId} />
              </Tabs.Panel>
              <Tabs.Panel value="files">
                <Space h="md" />
                <Files contentType="Hiring" contentId={jobId} />
              </Tabs.Panel>
            </Tabs>
          </Flex>
        </Flex>
      )}
      {error && <h3 style={{ textAlign: 'center' }}>Job Not Found</h3>}
    </PageWrapper>
  );
}

JobProfile.propTypes = {
  params: PropTypes.object,
  globalTabName: PropTypes.string,
};

JobProfile.defaultProps = {
  params: {},
  globalTabName: '',
};
