import { Modal } from '@mantine/core';
import { OneFieldForm } from 'components/OneFieldForm';
import PropTypes from 'prop-types';

export default function OneFieldModal({
  opened,
  onClose,
  state,
  onEdit,
  onCreate,
  isLoading,
  formSettings,
  title,
}) {
  const isEdit = state?.modalType === 'Edit';

  return (
    <Modal
      title={`${isEdit ? 'Edit' : 'Create'} ${title}`}
      opened={opened}
      onClose={onClose}
      centered
    >
      <OneFieldForm
        state={state}
        onSubmit={isEdit ? onEdit : onCreate}
        isLoading={!opened || isLoading}
        {...formSettings}
      />
    </Modal>
  );
}

OneFieldModal.propTypes = {
  formSettings: PropTypes.object,
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  state: PropTypes.object,
  title: PropTypes.string.isRequired,
};

OneFieldModal.defaultProps = {
  state: {},
  isLoading: false,
  formSettings: {},
};
