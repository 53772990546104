import { Flex, Table } from '@mantine/core';
import React from 'react';
import PropTypes from 'prop-types';
import { ContextMenu } from 'components/ContextMenu';
import { IconEdit, IconTrash } from '@tabler/icons-react';

export default function TemplatesRow({ data, isLast, onDelete, onEdit }) {
  const menuItems = [
    {
      label: 'Edit',
      icon: IconEdit,
      onClick: () => onEdit(data),
    },
    {
      label: 'Delete',
      icon: IconTrash,
      onClick: () => onDelete(data),
      condition: !!(data?.is_deletable ?? true),
    },
  ];

  return (
    <Table.Tr
      styles={{
        tr: {
          borderBottom: isLast ? '2px solid var(--mantine-color-gray-6)' : '',
        },
      }}
    >
      <Table.Td>
        <div>
          {data?.name}
          <div style={{ color: 'var(--mantine-color-gray-6)' }}>
            {data?.description}
          </div>
        </div>
      </Table.Td>
      <Table.Td>{data?.subject}</Table.Td>
      <Table.Td>
        <Flex justify="end">
          <ContextMenu menuItems={menuItems} />
        </Flex>
      </Table.Td>
    </Table.Tr>
  );
}

TemplatesRow.propTypes = {
  data: PropTypes.object.isRequired,
  isLast: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

TemplatesRow.defaultProps = {
  isLast: false,
  onDelete: () => {},
  onEdit: () => {},
};
