import { Button, Flex, Loader, Space, Table, Title } from '@mantine/core';
import { IconEdit, IconPlus, IconTrash } from '@tabler/icons-react';
import { clearCache } from 'api/clearCache';
import { useDeleteJobTitle, useLoadJobTitles } from 'api/hooks';
import { ContextMenu } from 'components/ContextMenu';
import CreateEditJobTitleModal from 'components/CreateEditJobTitleModal/CreateEditJobTitleModal';
import { SettingsTableHead } from 'components/SettingsTable';
import _ from 'lodash';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { useQueryClient } from 'react-query';
import { useStateDisclosure } from 'utils/hooks';

const headerList = ['Job Title', 'People', ''];

export default function JobTitleTab() {
  const { jobTitles, isLoading } = useLoadJobTitles();
  const { setNotifications } = useNotifications();

  const queryClient = useQueryClient();

  const { deleteJobTitle } = useDeleteJobTitle({
    onSuccess: () => {
      setNotifications(['Job Title Deleted Successfully']);
      clearCache.onJobTitleChange(queryClient);
    },
  });

  const [
    openedJobTitle,
    { open: openJobTitle, close: closeJobTitle, state: jobTitleState },
  ] = useStateDisclosure(false);

  return (
    <>
      <CreateEditJobTitleModal
        opened={openedJobTitle}
        onClose={closeJobTitle}
        jobTitle={jobTitleState}
      />
      <Flex gap={10} justify="space-between" align="center">
        <Title fz="xl">Job Title</Title>
        <Button onClick={openJobTitle} rightSection={<IconPlus size={14} />}>
          Add job title
        </Button>
      </Flex>
      {isLoading && (
        <Flex justify="center">
          <Loader />
        </Flex>
      )}
      <Space h="xs" />
      <Table>
        {!_.isEmpty(jobTitles) && <SettingsTableHead headerList={headerList} />}
        <Table.Tbody>
          {jobTitles?.map((job) => {
            const menuItems = [
              {
                label: 'Edit',
                icon: IconEdit,
                onClick: () => {
                  openJobTitle({ ...job, modalType: 'Edit' });
                },
              },
              {
                label: 'Delete',
                icon: IconTrash,
                onClick: () => {
                  deleteJobTitle(job?.id);
                },
                condition: !job.employees_count,
              },
            ];
            return (
              <Table.Tr key={job.id}>
                <Table.Td>{job.name}</Table.Td>
                <Table.Td>{job.employees_count ?? 0}</Table.Td>
                <Table.Td>
                  <Flex justify="end">
                    <ContextMenu menuItems={menuItems} />
                  </Flex>
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
    </>
  );
}
