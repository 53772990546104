import { Button, Select, Stack, TextInput, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { DateFormInput } from 'components/DateFormInput';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { formatSelectorData, genericValidators } from 'utils';

export default function CreateEditEmployeeAssetsForm({
  onSubmit,
  isLoading,
  state,
  assetCategories,
}) {
  const form = useForm({
    initialValues: {
      category_id: _.toString(state?.category?.id ?? ''),
      description: state?.description ?? '',
      serial_number: state?.serial_number ?? '',
      assigned_at: state?.assigned_at ?? '',
      returned_at: state?.returned_at ?? '',
    },
    validate: {
      category_id: (val) => genericValidators.notEmpty(val),
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)} noValidate>
      <Stack gap={10}>
        <Select
          required
          label="Category"
          {...form.getInputProps('category_id')}
          data={formatSelectorData(assetCategories, {
            value: 'id',
            label: 'name',
          })}
          placeholder="Select Category"
          allowDeselect={false}
        />

        <Textarea
          label="Description"
          {...form.getInputProps('description')}
          resize="block"
          rows={4}
        />

        <TextInput label="Serial #" {...form.getInputProps('serial_number')} />

        <DateFormInput
          {...form.getInputProps('assigned_at')}
          field={{ displayName: 'Date Assigned' }}
          onChange={(val) =>
            form.setFieldValue(
              'assigned_at',
              val ? moment(val).format('YYYY-MM-DD') : '',
            )
          }
          clearable
        />

        <DateFormInput
          {...form.getInputProps('returned_at')}
          field={{ displayName: 'Date Returned' }}
          onChange={(val) =>
            form.setFieldValue(
              'returned_at',
              val ? moment(val).format('YYYY-MM-DD') : '',
            )
          }
          clearable
        />

        <Button ml="auto" disabled={isLoading} type="submit">
          Submit
        </Button>
      </Stack>
    </form>
  );
}

CreateEditEmployeeAssetsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  state: PropTypes.object,
  assetCategories: PropTypes.array,
};

CreateEditEmployeeAssetsForm.defaultProps = {
  isLoading: false,
  state: {},
  assetCategories: [],
};
