import axios from 'axios';
import { getTokenData } from 'storage';

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  const { forwardAuthorization } = config;
  const token = getTokenData()?.access_token;
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization =
    forwardAuthorization ?? `Bearer ${token ?? ''}`;
  return config;
});
