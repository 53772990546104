import { Stack, Text } from '@mantine/core';
import { IconMail } from '@tabler/icons-react';
import React from 'react';
import PropTypes from 'prop-types';

export default function CandidateStartEmailConversation({ recipientInitials }) {
  return (
    <Stack h="100%" flex={1} gap={0} align="center">
      <IconMail color="var(--mantine-color-gray-4)" size={250} />
      <Text size="xl" fw={700} c="var(--mantine-color-gray-6)">
        It&#39;s a little quiet in here, don&#39;t you think?
      </Text>
      <Text c="var(--mantine-color-gray-6)">
        Start an email conversation with {recipientInitials}.
      </Text>
    </Stack>
  );
}

CandidateStartEmailConversation.propTypes = {
  recipientInitials: PropTypes.string.isRequired,
};
