import { Modal } from '@mantine/core';
import React from 'react';
import PropTypes from 'prop-types';
import { JobInformationCommonForm } from 'components/JobInformationCommonForm';
import { useCreateDepartment, useUpdateDepartment } from 'api/hooks';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { useQueryClient } from 'react-query';
import { clearCache } from 'api/clearCache';

export default function CreateEditDepartmentModal({
  opened,
  onClose,
  department,
}) {
  const { setNotifications } = useNotifications();

  const queryClient = useQueryClient();

  const { createDepartment, isLoading: isCreating } = useCreateDepartment({
    onSuccess: () => {
      setNotifications(['Department Created Successfully']);
      clearCache.onDepartmentChange(queryClient);
      onClose();
    },
  });

  const { updateDepartment, isLoading: isUpdating } = useUpdateDepartment({
    onSuccess: () => {
      setNotifications(['Department Updated Successfully']);
      clearCache.onDepartmentChange(queryClient);
      onClose();
    },
  });

  return (
    <Modal title="Department" opened={opened} onClose={onClose} centered>
      <JobInformationCommonForm
        label="Department"
        name={department?.name ?? ''}
        onSubmit={
          department?.modalType === 'Edit'
            ? (payload) => updateDepartment({ id: department.id, payload })
            : createDepartment
        }
        isLoading={isCreating || isUpdating}
        type={department?.modalType}
      />
    </Modal>
  );
}

CreateEditDepartmentModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  department: PropTypes.object,
};

CreateEditDepartmentModal.defaultProps = {
  department: {},
};
