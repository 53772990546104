import { Button, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import PropTypes from 'prop-types';
import { genericValidators } from 'utils';

export default function CompensationChangeReasonForm({
  onSubmit,
  changeReasonState,
  isLoading,
}) {
  const form = useForm({
    initialValues: {
      name: changeReasonState?.name ?? '',
    },
    validate: {
      name: (val) => genericValidators.notEmpty(val),
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)} noValidate>
      <Stack gap={10}>
        <TextInput
          label="Name"
          placeholder="Name"
          {...form.getInputProps('name')}
        />
        <Button type="submit" ml="auto" disabled={isLoading}>
          Submit
        </Button>
      </Stack>
    </form>
  );
}

CompensationChangeReasonForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  changeReasonState: PropTypes.object,
  isLoading: PropTypes.bool,
};

CompensationChangeReasonForm.defaultProps = {
  changeReasonState: {},
  isLoading: false,
};
