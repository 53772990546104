import { Modal } from '@mantine/core';
import React from 'react';
import PropTypes from 'prop-types';
import { TemplateVariableList } from 'components/TemplateVariableList';
import styles from './styles.module.css';

export default function TemplateModal({
  opened,
  onClose,
  modalProps,
  variableListProps,
  children,
  title,
}) {
  return (
    <Modal
      centered
      opened={opened}
      onClose={onClose}
      title={title}
      size="xl"
      classNames={{ content: styles.content, body: styles.body }}
      {...modalProps}
    >
      {children}
      <TemplateVariableList {...variableListProps} />
    </Modal>
  );
}

TemplateModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  modalProps: PropTypes.object,
  variableListProps: PropTypes.object,
};

TemplateModal.defaultProps = {
  title: '',
  modalProps: {},
  variableListProps: {},
};
