import { Box, Flex, Space, Stack, Table } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { clearCache } from 'api/clearCache';
import {
  useAddRoleToEmployees,
  useLoadRoles,
  useRemoveEmployeeRole,
} from 'api/hooks';
import { AddEmployeePermissionModal } from 'components/AddEmployeePermissionModal';
import { AddButton } from 'components/Buttons';
import { ConfirmDeleteModal } from 'components/ConfirmDeleteModal';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import { roles as ProjectRoles } from 'components/PermissionGuard/hooks';
import { SettingsTableHead } from 'components/SettingsTable';
import _ from 'lodash';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useStateDisclosure } from 'utils/hooks';
import { GenericRow, PermissionsSkeleton } from './components';

export default function PermissionsTab() {
  const { roles, isLoading: isRolesLoading } = useLoadRoles({
    filter: { withEmployee: 1 },
  });

  const queryClient = useQueryClient();
  const { setNotifications } = useNotifications();

  const [
    openedAddEmployeeModal,
    { open: openAddEmployeeModal, close: closeAddEmployeeModal },
  ] = useDisclosure(false);

  const [
    openedConfirmDeleteModal,
    {
      open: openConfirmDeleteModal,
      close: closeConfirmDeleteModal,
      state: employeeDeleteState,
    },
  ] = useStateDisclosure(false);

  const { addRolesToEmployee, isLoading: isRoleAttaching } =
    useAddRoleToEmployees({
      onSuccess: () => {
        setNotifications([
          'The role for the user has been successfully updated',
        ]);
        closeAddEmployeeModal();
        clearCache.onChangeEmployeesRole(queryClient);
      },
    });

  const { removeEmployeeRole, isLoading: isRemovingRole } =
    useRemoveEmployeeRole({
      onSuccess: () => {
        setNotifications([
          'The role for the user has been successfully deleted',
        ]);
        closeConfirmDeleteModal();
        clearCache.onChangeEmployeesRole(queryClient);
      },
    });

  const [selectedTab, setSelectedTab] = useState();

  useEffect(() => {
    if (selectedTab === undefined && !_.isEmpty(roles)) {
      setSelectedTab(roles[0]?.id);
    }
  }, [roles, selectedTab]);

  const selectedRoleData = useMemo(() => {
    if (selectedTab === undefined) return null;

    const selectedRole = roles?.find((role) => role?.id === selectedTab) ?? '';
    selectedRole.employees = selectedRole?.employees?.map((item) => ({
      ...item,
      name: `${item?.first_name} ${item?.last_name}`,
    }));

    return selectedRole;
  }, [roles, selectedTab]);

  const isAllowToDeleteUsers = useMemo(() => {
    if (_.isEmpty(roles) || !selectedTab) return false;

    const { name, employee_count: employeeCount } = roles.find(
      (role) => role.id === selectedTab,
    );

    const isHrOrAdmin = name === ProjectRoles.ADMIN || name === ProjectRoles.HR;
    if (name && isHrOrAdmin) {
      return employeeCount > 1;
    }
    return true;
  }, [selectedTab, roles]);

  return (
    <>
      <Space h="md" />
      <AddEmployeePermissionModal
        opened={openedAddEmployeeModal}
        onClose={closeAddEmployeeModal}
        isLoading={isRolesLoading || isRoleAttaching}
        onCreate={({ employees }) =>
          addRolesToEmployee({
            employees,
            role_id: selectedRoleData?.id,
            action: 'add',
          })
        }
      />
      <ConfirmDeleteModal
        opened={openedConfirmDeleteModal}
        onClose={closeConfirmDeleteModal}
        onDelete={() =>
          removeEmployeeRole({
            role_id: selectedRoleData?.id,
            employees: [employeeDeleteState?.id],
            action: 'remove',
          })
        }
        isLoading={isRemovingRole}
      />
      <EmptyDataMessage isVisible={!isRolesLoading && _.isEmpty(roles)} />
      <PermissionsSkeleton isVisible={isRolesLoading} />
      <Flex gap={10}>
        <Stack gap={0}>
          {_.map(roles, (role) => (
            <Box
              w="100%"
              style={{
                borderTopLeftRadius: 'var(--tabs-radius)',
                borderBottomLeftRadius: 'var(--tabs-radius)',
                padding: 'var(--mantine-spacing-xs) var(--mantine-spacing-md)',
                cursor: 'pointer',
                textTransform: 'capitalize',
                whiteSpace: 'nowrap',
              }}
              bg={selectedTab === role.id ? 'blue' : undefined}
              c={selectedTab === role.id ? 'white' : undefined}
              key={role?.id}
              onClick={() => setSelectedTab(role?.id)}
            >
              {role?.name} ({role?.employee_count})
            </Box>
          ))}
        </Stack>
        <Stack gap={0} w="100%">
          {selectedTab && (
            <Flex gap="md" justify="flex-end" align="center">
              <AddButton
                onClick={() => openAddEmployeeModal()}
                disabled={isRolesLoading || !selectedTab || isRoleAttaching}
              >
                Add Employee
              </AddButton>
            </Flex>
          )}
          <Space h="md" />
          {!_.isEmpty(roles) && (
            <Table>
              {!_.isEmpty(selectedRoleData?.employees) && (
                <SettingsTableHead headerList={['Name', '']} />
              )}
              <Table.Tbody>
                <EmptyDataMessage
                  isVisible={
                    selectedRoleData
                      ? _.isEmpty(selectedRoleData?.employees)
                      : false
                  }
                />
                {_.map(selectedRoleData?.employees, (item) => (
                  <GenericRow
                    data={item}
                    onDeleteRecord={
                      selectedTab && isAllowToDeleteUsers
                        ? () => openConfirmDeleteModal(item)
                        : undefined
                    }
                    withEmployeeCount={false}
                    key={item?.id}
                  />
                ))}
              </Table.Tbody>
            </Table>
          )}
        </Stack>
      </Flex>
    </>
  );
}
