import { Fragment } from 'react';
import { Table, Text, rem, Anchor, Flex, Loader } from '@mantine/core';
import {
  IconCircleCheck,
  IconLink,
  IconCircleX,
  IconTrash,
  IconEdit,
} from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { ContextMenu } from 'components/ContextMenu';
import { frequencyValues } from 'utils/constants';

export default function TrainingsTable({
  groupedItems,
  onEditTraining,
  onEditCategory,
  onDeleteTraining,
  onDeleteCategory,
  categoryDeleteState,
  trainingDeleteState,
}) {
  return (
    <Table highlightOnHover>
      {groupedItems
        .sort((a) => (a.id ? 1 : -1))
        .map((item) => {
          const isDeletingCategory =
            categoryDeleteState.isDeleting &&
            categoryDeleteState.itemId === item.id;

          const menuItems = [
            {
              label: 'Edit',
              icon: IconEdit,
              onClick: () => {
                onEditCategory(item);
              },
            },
            {
              label: 'Delete',
              icon: IconTrash,
              onClick: () => {
                onDeleteCategory(item);
              },
            },
          ];
          return (
            <Fragment key={item.name + item.id}>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>
                    <Flex align="center" gap="xs">
                      <Text fw={700} size="xl">
                        {item.name}
                      </Text>
                      {!isDeletingCategory && item.id && (
                        <ContextMenu menuItems={menuItems} />
                      )}
                      {isDeletingCategory && <Loader color="blue" size={15} />}
                    </Flex>
                  </Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Training Name</Table.Th>
                  <Table.Th>Required</Table.Th>
                  <Table.Th>Frequency</Table.Th>
                  <Table.Th>Description</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {item.trainings.map((training) => (
                  <TrainingRow
                    key={training.id}
                    training={training}
                    onDelete={onDeleteTraining}
                    onEdit={onEditTraining}
                    isDeleting={
                      trainingDeleteState.isDeleting &&
                      trainingDeleteState.itemId === training.id
                    }
                  />
                ))}
              </Table.Tbody>
            </Fragment>
          );
        })}
    </Table>
  );
}
function TrainingRow({ training, onEdit, onDelete, isDeleting }) {
  const menuItems = [
    {
      label: 'Edit',
      icon: IconEdit,
      onClick: () => {
        onEdit(training);
      },
    },
    {
      label: 'Delete',
      icon: IconTrash,
      onClick: () => {
        onDelete(training);
      },
    },
  ];
  const frequency = frequencyValues.find(
    (item) => parseInt(item.value, 10) === training.frequency,
  );
  return (
    <Table.Tr opacity={isDeleting ? 0.5 : 1}>
      <Table.Td>
        <Flex align="center" gap="md">
          {!isDeleting && <ContextMenu menuItems={menuItems} />}
          {isDeleting && <Loader color="blue" size={19} />}
          {training.url && (
            <Anchor href={training.url} target="_blank">
              <Flex align="center">
                {training.name}
                <IconLink size={18} />
              </Flex>
            </Anchor>
          )}
          {!training.url && training.name}
        </Flex>
      </Table.Td>
      <Table.Td>
        {training.required ? (
          <IconCircleCheck
            color="green"
            style={{ width: rem(20), height: rem(20) }}
          />
        ) : (
          <IconCircleX
            color="red"
            style={{ width: rem(20), height: rem(20) }}
          />
        )}
      </Table.Td>
      <Table.Td>{frequency?.label ?? ''}</Table.Td>
      <Table.Td>{training.description}</Table.Td>
    </Table.Tr>
  );
}

TrainingRow.propTypes = {
  training: PropTypes.object.isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
};
TrainingRow.defaultProps = {
  onEdit: () => {},
  onDelete: () => {},
  isDeleting: false,
};
TrainingsTable.propTypes = {
  groupedItems: PropTypes.array,
  onEditTraining: PropTypes.func,
  onEditCategory: PropTypes.func,
  onDeleteTraining: PropTypes.func,
  onDeleteCategory: PropTypes.func,
  categoryDeleteState: PropTypes.object,
  trainingDeleteState: PropTypes.object,
};
TrainingsTable.defaultProps = {
  groupedItems: [],
  onEditTraining: () => {},
  onEditCategory: () => {},
  onDeleteTraining: () => {},
  onDeleteCategory: () => {},
  categoryDeleteState: {},
  trainingDeleteState: {},
};
