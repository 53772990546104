import { Table } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { ContextMenu } from 'components/ContextMenu';
import PropTypes from 'prop-types';

export default function BonusReasonRow({
  reasonItem,
  onEditBonusReason,
  onDeleteBonusReason,
}) {
  const menuItems = [
    {
      label: 'Edit',
      icon: IconEdit,
      onClick: () => {
        onEditBonusReason(reasonItem);
      },
    },
    {
      label: 'Delete',
      icon: IconTrash,
      onClick: () => {
        onDeleteBonusReason(reasonItem);
      },
      condition: !reasonItem?.employees_count,
    },
  ];

  return (
    <Table.Tr>
      <Table.Td>{reasonItem?.name}</Table.Td>
      <Table.Td>{reasonItem?.employees_count}</Table.Td>
      <Table.Td ta="right">
        <ContextMenu menuItems={menuItems} />
      </Table.Td>
    </Table.Tr>
  );
}

BonusReasonRow.propTypes = {
  reasonItem: PropTypes.object.isRequired,
  onEditBonusReason: PropTypes.func.isRequired,
  onDeleteBonusReason: PropTypes.func.isRequired,
};
