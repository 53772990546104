import { Avatar, Box, Flex, Stack, Text } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { IconUser } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { tabNames } from 'utils/constants';
import { useTabNavigate } from 'utils/hooks';
import './index.css';

export default function CustomNode({ nodeData }) {
  const { hovered, ref } = useHover();
  const navigate = useTabNavigate();

  const onClickEmployee = () => {
    navigate(`/employees/${nodeData?.id}`, {
      tabName: tabNames.employeesProfile,
    });
  };

  return (
    <Box
      style={{
        borderRadius: '20px',
        overflow: 'hidden',
        border: '1px solid var(--mantine-color-blue-5)',
      }}
      pb={0.5}
    >
      <Flex py={5} style={{ backgroundColor: 'var(--mantine-color-blue-5)' }}>
        <Avatar
          mx="auto"
          color="white"
          onClick={onClickEmployee}
          size={50}
          src={nodeData?.avatar_filename}
        />
      </Flex>
      <Box mih="60px" miw="150px" p={5} pb={15}>
        <Stack justify="center" gap={3}>
          <Box
            ref={ref}
            style={{ textDecoration: hovered ? 'underline' : 'none' }}
            onClick={onClickEmployee}
          >
            {nodeData?.first_name} {nodeData?.last_name}
          </Box>
          <div>{nodeData?.jobTitle}</div>
        </Stack>
      </Box>
      {!!nodeData?.children_count && (
        <Flex justify="center" align="end">
          <IconUser size={20} fontWeight={700} />
          <Text style={{ lineHeight: '12px' }} fz={13} fw={500}>
            {nodeData?.children_count}
          </Text>
        </Flex>
      )}
    </Box>
  );
}

CustomNode.propTypes = {
  nodeData: PropTypes.object.isRequired,
};
