import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { offerStatuses } from 'utils/constants';

const useCandidateActiveOffer = (offers) => {
  const [offer, setOffer] = useState(null);

  useEffect(() => {
    const sentOffer = offers?.find(
      (item) => item?.status?.id === offerStatuses.Sent,
    );
    const signedOffer = offers?.find(
      (item) => item?.status?.id === offerStatuses.Signed,
    );
    setOffer(sentOffer ?? signedOffer ?? null);
  }, [offers]);

  const previousSignedOffers = useMemo(() => {
    if (_.isEmpty(offers) || _.isEmpty(offer)) return [];

    const signedOffers = offers?.filter(
      (item) =>
        item?.id !== offer?.id && item?.status?.id === offerStatuses.Signed,
    );

    return signedOffers;
  }, [offers, offer]);

  const canCreateNewOffer = useMemo(() => {
    if (_.isEmpty(offer)) return true;

    return offer?.status?.id === offerStatuses.Signed;
  }, [offer]);

  return { currentOffer: offer, canCreateNewOffer, previousSignedOffers };
};

export default useCandidateActiveOffer;
