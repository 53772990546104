import { Button, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { EmployeeMultipleAutocomplete } from 'components/EmployeeMultipleAutocomplete';
import PropTypes from 'prop-types';
import { genericValidators } from 'utils';

export default function AddEmployeePermissionForm({ isLoading, onSubmit }) {
  const form = useForm({
    initialValues: {
      employees: [],
    },
    validate: {
      employees: (val) => genericValidators.notEmpty(val),
    },
  });

  return (
    <form noValidate onSubmit={form.onSubmit(onSubmit)}>
      <Stack>
        <EmployeeMultipleAutocomplete
          values={form.values.employees}
          setValues={(val) => form.setFieldValue('employees', val)}
          disabled={isLoading}
          clearable
          label="Employee"
          error={form.errors.employees}
        />
        <Button disabled={isLoading} type="submit">
          Submit
        </Button>
      </Stack>
    </form>
  );
}

AddEmployeePermissionForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

AddEmployeePermissionForm.defaultProps = {
  isLoading: false,
};
