import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { Divider, Flex, Stack } from '@mantine/core';
import { useListState, useToggle } from '@mantine/hooks';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useDragAndDrop } from '../hooks';
import DraggableItems from './DraggableItems';

const numberOfColumns = 3;

export default function ActiveWidgets({ usedWidgets, isFetching, onReorder }) {
  const { sliceWidgetColumnPosition, applyAllColumns } = useDragAndDrop();

  const groupedWidgets = useMemo(
    () => applyAllColumns(_.groupBy(usedWidgets, 'column'), numberOfColumns),
    [usedWidgets, applyAllColumns],
  );

  const [state, { setState }] = useListState(usedWidgets);
  const [listStatus, toggleListStatus] = useToggle([
    'initializing',
    'reorder',
    'hold',
  ]);

  useEffect(() => {
    setState(usedWidgets);
  }, [usedWidgets, setState]);

  // TODO REFACOR
  const reorderWithDroppableContext = (source, destination) => {
    const { index: from, droppableId: fromDroppableId } = source;
    const { index: to, droppableId: toDroppableId } = destination;

    setState((prev) => {
      const cloned = _.cloneDeep(prev);

      const grouped = applyAllColumns(
        _.groupBy(cloned, 'column'),
        numberOfColumns,
      );

      const fromItem = grouped[+fromDroppableId][from];
      const toItem = grouped[+toDroppableId][to];
      const isSameColumn = fromDroppableId === toDroppableId;

      if (!isSameColumn) {
        fromItem.column = +toDroppableId;

        const data = sliceWidgetColumnPosition({
          fromIndex: from,
          toIndex: to,
          fromItem,
          toItem,
          state,
          toColumn: +toDroppableId,
        });

        return data;
      }

      if (fromItem && toItem && isSameColumn) {
        let fromIndex = cloned?.findIndex((i) => i?.id === fromItem?.id);

        const toIndex = cloned?.findIndex((i) => i?.id === toItem?.id);

        // from top to down
        if (fromIndex < toIndex) {
          while (fromIndex < toIndex) {
            cloned[fromIndex] = cloned[fromIndex + 1];
            fromIndex += 1;
          }
          cloned[toIndex] = fromItem;

          return cloned;
        }

        // from down to top
        if (fromIndex > toIndex) {
          while (fromIndex > toIndex) {
            cloned[fromIndex] = cloned[fromIndex - 1];
            fromIndex -= 1;
          }
          cloned[toIndex] = fromItem;

          return cloned;
        }
      }

      return cloned;
    });
  };

  const reorderList = (source, destination) => {
    if (!_.isNil(source?.index) && !_.isNil(destination?.index)) {
      toggleListStatus('reorder');
      reorderWithDroppableContext(source, destination);
    } else {
      toggleListStatus('hold');
    }
  };

  useEffect(() => {
    if (listStatus === 'reorder') {
      onReorder(state);
      toggleListStatus('hold');
    }
  }, [state, listStatus, toggleListStatus, onReorder]);

  return (
    <Flex wrap="nowrap" w="100%" gap={10} style={{ overflow: 'auto' }}>
      <DragDropContext
        onDragEnd={({ destination, source }) =>
          reorderList(source, destination)
        }
      >
        {_.keys(
          applyAllColumns(_.groupBy(state, 'column'), numberOfColumns),
        ).map((columnNumber) => (
          <Stack
            gap={0}
            flex={1}
            w={`${100 / numberOfColumns}%`}
            key={columnNumber}
          >
            <Droppable droppableId={columnNumber} direction="vertical">
              {(provided) => (
                <Stack gap={10} flex={1}>
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ height: '100%' }}
                  >
                    <DraggableItems
                      widgets={
                        applyAllColumns(
                          _.groupBy(state, 'column'),
                          numberOfColumns,
                        )?.[columnNumber]
                      }
                      isFetching={isFetching}
                    />
                    {provided.placeholder}
                  </div>
                </Stack>
              )}
            </Droppable>
            {_.last(_.keys(groupedWidgets)) !== columnNumber && (
              <Divider orientation="vertical" />
            )}
          </Stack>
        ))}
      </DragDropContext>
    </Flex>
  );
}

ActiveWidgets.propTypes = {
  usedWidgets: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onReorder: PropTypes.func.isRequired,
};
