import { useAddTalentGroup } from 'api/hooks';
import { queryKeys } from 'api/keys';
import { TalentGroupForm } from 'components/TalentGroupForm';
import { useGlobalTabsContext } from 'globalTabs';
import { useNotifications } from 'notifications/hooks/useNotifications';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { tabNames } from 'utils/constants';
import { usePageTitle, useTabNavigate } from 'utils/hooks';
import { PageWrapper } from '../../components/PageWrapper';

export default function CreateTalentGroup({ globalTabName }) {
  const navigate = useTabNavigate();

  const { onRemoveTab } = useGlobalTabsContext();
  const queryClient = useQueryClient();
  const { setNotifications } = useNotifications();

  const { addTalentGroup, isLoading } = useAddTalentGroup({
    onSuccess: () => {
      setNotifications(['The Talent Group has been successfully created']);
      queryClient.invalidateQueries(queryKeys.talentGroups());
      navigate(`/hiring/talentGroups`, { tabName: tabNames.hiring });
      onRemoveTab(globalTabName);
    },
  });

  const submitForm = (values) => {
    addTalentGroup({ ...values, candidates: [] });
  };

  usePageTitle('Create Talent Group - Hiring', globalTabName);

  return (
    <PageWrapper title="Create Talent Group">
      <TalentGroupForm onSubmit={submitForm} isSubmitLoading={isLoading} />
    </PageWrapper>
  );
}

CreateTalentGroup.propTypes = {
  globalTabName: PropTypes.string,
};

CreateTalentGroup.defaultProps = {
  globalTabName: '',
};
