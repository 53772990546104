import {
  Button,
  Checkbox,
  Divider,
  Flex,
  InputLabel,
  Paper,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ContextMenu } from 'components/ContextMenu';
import { IconEdit, IconPlus, IconTrash } from '@tabler/icons-react';
import { BoardingTaskModal } from 'components/BoardingTaskModal';
import { useStateDisclosure } from 'utils/hooks';
import { ConfirmDeleteModal } from 'components/ConfirmDeleteModal';
import { formatSelectorData } from 'utils';
import moment from 'moment';

const dueColors = {
  due: 'green',
  overdue: 'red',
};

export default function EmployeeBoardingList({
  tasksList,
  markAsComplete,
  isCompletedOnboarding,
  onDeleteTask,
  onSubmitTaskList,
  boardingType,
  taskList,
  completeItemState,
  employeeHireDate,
  isTaskListLoading,
}) {
  const [
    openedTaskModal,
    { open: openTaskModal, close: closeTaskModal, state: taskState },
  ] = useStateDisclosure(false);

  const [
    openedDeleteModal,
    { open: openDeleteModal, close: closeDeleteModal, state: deleteState },
  ] = useStateDisclosure(false);

  const groupedTaskList = useMemo(
    () => _.groupBy(tasksList, 'tasklist_name'),
    [tasksList],
  );

  const onDelete = () => {
    onDeleteTask(deleteState);
    closeDeleteModal();
  };

  const onSubmitTaskModal = (data) => {
    onSubmitTaskList(data);
    closeTaskModal();
  };

  return (
    <>
      <ConfirmDeleteModal
        opened={openedDeleteModal}
        onClose={closeDeleteModal}
        onDelete={onDelete}
        deleteItemName={deleteState?.name}
      />
      <Flex align="center" justify="space-between" gap={10}>
        <h2>{boardingType}</h2>
        {!isCompletedOnboarding && (
          <Button onClick={openTaskModal} rightSection={<IconPlus size={14} />}>
            Create Task
          </Button>
        )}
      </Flex>
      <BoardingTaskModal
        onClose={closeTaskModal}
        opened={openedTaskModal}
        taskState={taskState}
        onSubmit={onSubmitTaskModal}
        taskList={formatSelectorData(taskList, {
          value: 'name',
          label: 'name',
        })}
        title={`${taskState?.modalType ?? 'Create'} task for employee`}
        hireDate={employeeHireDate}
        isLoading={isTaskListLoading}
      />
      {!_.isEmpty(groupedTaskList) && (
        <Paper withBorder p={10}>
          <Stack gap={10}>
            {_.keys(groupedTaskList).map((boardingItem) => (
              <div key={boardingItem}>
                <Title pb={5} fz={20}>
                  {boardingItem}
                </Title>
                <Stack gap={10}>
                  {_.map(groupedTaskList[boardingItem], (task) => {
                    const menuItems = [
                      {
                        label: 'Edit',
                        icon: IconEdit,
                        onClick: () =>
                          openTaskModal({
                            ...task,
                            tasklist_id: task?.tasklist_name,
                            tasklist_name: task?.tasklist_name,
                            modalType: 'Edit',
                          }),
                      },
                      {
                        label: 'Delete',
                        icon: IconTrash,
                        onClick: () => openDeleteModal(task),
                      },
                    ];
                    if (isCompletedOnboarding) {
                      menuItems.shift();
                    }

                    const { completeId, isCompletingLoading } =
                      completeItemState;

                    const isCurrentItemLoading =
                      completeId === task?.id && isCompletingLoading;

                    const deadline =
                      employeeHireDate && task?.date_due !== null
                        ? moment(employeeHireDate)
                            .add(task?.date_due ?? 0, 'day')
                            .format('MMM-DD, YYYY')
                        : '';

                    const currentDateFormatted =
                      moment().format('MMM-DD, YYYY');

                    const color =
                      moment(deadline, 'MMM-DD, YYYY').diff(
                        moment(currentDateFormatted, 'MMM-DD, YYYY'),
                      ) >= 0
                        ? dueColors.due
                        : dueColors.overdue;

                    return (
                      <React.Fragment key={task?.id}>
                        <Flex align="center" gap={10}>
                          <Flex align="center" justify="space-between" w="100%">
                            <InputLabel>
                              <Flex gap={10} align="center">
                                <Checkbox
                                  onChange={() => markAsComplete(task)}
                                  py={5}
                                  disabled={isCurrentItemLoading}
                                  checked={
                                    isCurrentItemLoading ||
                                    isCompletedOnboarding
                                  }
                                />
                                <Text
                                  td={
                                    isCompletedOnboarding
                                      ? 'line-through'
                                      : undefined
                                  }
                                >
                                  {task?.name}
                                </Text>
                                {deadline && (
                                  <Text c={deadline ? color : 'gray'}>
                                    {deadline}
                                  </Text>
                                )}
                                {task?.completed_by && (
                                  <Text c="gray">
                                    Completed by {task?.completed_by} on{' '}
                                    {task?.updated_at
                                      ? moment(task?.update_at).format(
                                          'MMM-DD, YYYY',
                                        )
                                      : undefined}
                                  </Text>
                                )}
                              </Flex>
                            </InputLabel>
                            <ContextMenu menuItems={menuItems} />
                          </Flex>
                        </Flex>
                        <Divider />
                      </React.Fragment>
                    );
                  })}
                </Stack>
              </div>
            ))}
          </Stack>
        </Paper>
      )}
    </>
  );
}

EmployeeBoardingList.propTypes = {
  boardingType: PropTypes.string.isRequired,
  isCompletedOnboarding: PropTypes.bool.isRequired,
  taskList: PropTypes.array,
  tasksList: PropTypes.array,
  markAsComplete: PropTypes.func,
  onDeleteTask: PropTypes.func,
  onSubmitTaskList: PropTypes.func,
  completeItemState: PropTypes.object,
  employeeHireDate: PropTypes.string,
  isTaskListLoading: PropTypes.bool,
};
EmployeeBoardingList.defaultProps = {
  tasksList: [],
  taskList: [],
  markAsComplete: () => {},
  onSubmitTaskList: () => {},
  onDeleteTask: () => {},
  completeItemState: {},
  employeeHireDate: '',
  isTaskListLoading: false,
};
