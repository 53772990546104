import { Text, Space, Flex, Button, LoadingOverlay } from '@mantine/core';
import { IconTextPlus, IconCategory } from '@tabler/icons-react';
import {
  TimeOffCategoryModal,
  TimeOffPolicyModal,
  TimeOffCategoriesTable,
} from 'components/TimeOff';
import { useTimeOffGroupedPolicies } from 'components/TimeOff/hooks';
import {
  useCreateTimeOffCategory,
  useUpdateTimeOffCategory,
  useDeleteTimeOffCategory,
  useDeleteTimeOffPolicy,
} from 'api/hooks';
import { useStateDisclosure } from 'utils/hooks';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { modals } from '@mantine/modals';
import { DoubleConfirmDeleteModal } from 'components/ConfirmDeleteModal';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { clearCache } from 'api/clearCache';

export default function TimeOffTab() {
  const { setNotifications } = useNotifications();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    groupedCategories,
    isPoliciesLoading,
    isCategoriesLoading,
    policyTypes,
    refetchCategories,
  } = useTimeOffGroupedPolicies();
  const [
    categoryModalOpened,
    {
      open: openCategoryModal,
      close: closeCategoryModal,
      state: categoryToEdit,
    },
  ] = useStateDisclosure(false);

  const [
    policyModalOpened,
    { open: openPolicyModal, close: closePolicyModal },
  ] = useStateDisclosure(false);

  const [
    openedPolicyDeleteModal,
    {
      open: openPolicyDeleteModal,
      close: closePolicyDeleteModal,
      state: policyDeleteModalState,
    },
  ] = useStateDisclosure(false);

  const { createCategory, isLoading: isCreatingCategory } =
    useCreateTimeOffCategory({
      onSuccess: () => {
        refetchCategories();
        closeCategoryModal();
        setNotifications(['Category successfully created']);
      },
    });

  const { updateCategory, isLoading: isUpdatingCategory } =
    useUpdateTimeOffCategory({
      onSuccess: () => {
        refetchCategories();
        closeCategoryModal();
        setNotifications(['Category successfully updated']);
      },
    });

  const {
    deleteCategory,
    isLoading: isDeletingCategory,
    variables: deletingCategoryId,
  } = useDeleteTimeOffCategory({
    onSuccess: () => {
      refetchCategories();
      setNotifications(['Category successfully deleted']);
    },
  });
  const {
    deletePolicy,
    isLoading: isDeletingPolicy,
    variables: deletingPolicyId,
  } = useDeleteTimeOffPolicy({
    onSuccess: () => {
      refetchCategories();
      clearCache.onTimeOffPolicyChange(queryClient);
      setNotifications(['Policy successfully deleted']);
      closePolicyDeleteModal();
    },
  });

  const onSubmitCategory = (values) => {
    createCategory(values);
  };
  const onUpdateCategory = (payload) => {
    if (categoryToEdit) {
      updateCategory({ id: categoryToEdit.id, payload });
    }
  };

  const onEditCategoryClick = (category) => {
    openCategoryModal(category);
  };

  const onDeleteCategoryClick = (category) => {
    modals.openConfirmModal({
      title: 'Delete category',
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete category{' '}
          <Text span fw={500}>
            {category.name}
          </Text>
        </Text>
      ),
      labels: { confirm: 'Delete category', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: () => deleteCategory(category.id),
    });
  };

  const onDeletePolicyClick = (policy) => {
    openPolicyDeleteModal(policy);
  };

  return (
    <>
      <LoadingOverlay
        visible={isCategoriesLoading || isPoliciesLoading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <TimeOffCategoryModal
        opened={categoryModalOpened}
        onClose={() => {
          closeCategoryModal();
        }}
        onSubmit={categoryToEdit ? onUpdateCategory : onSubmitCategory}
        isLoading={isCreatingCategory || isUpdatingCategory}
        category={categoryToEdit}
      />
      <TimeOffPolicyModal
        opened={policyModalOpened}
        onClose={closePolicyModal}
        policyTypes={policyTypes}
      />
      <DoubleConfirmDeleteModal
        opened={openedPolicyDeleteModal}
        onClose={closePolicyDeleteModal}
        title="Delete Policy"
        deleteLabel="Delete Policy"
        onDelete={() => deletePolicy(policyDeleteModalState?.id)}
        deleteText={`policy ${policyDeleteModalState?.name ?? ''}`}
        isLoading={isDeletingPolicy}
        additionInfo={
          <Text c="red">
            {policyDeleteModalState?.unique_employee_count ?? 0} employees will
            be removed from this policy
          </Text>
        }
      />
      <Space h="md" />
      <Flex gap="md" justify="flex-end" align="center">
        <Button
          onClick={() => openCategoryModal()}
          leftSection={<IconCategory size={20} />}
          disabled={isCategoriesLoading || isPoliciesLoading}
        >
          Add Category
        </Button>
        <Button
          onClick={() => openPolicyModal()}
          leftSection={<IconTextPlus size={20} />}
          disabled={isCategoriesLoading || isPoliciesLoading}
        >
          Add Policy
        </Button>
      </Flex>
      <Space h="xxl" />
      <TimeOffCategoriesTable
        categories={groupedCategories}
        onEditCategory={onEditCategoryClick}
        onDeleteCategory={onDeleteCategoryClick}
        onEditPolicy={(params) =>
          navigate(`settings/time-off/${params?.id}/edit`)
        }
        onDeletePolicy={onDeletePolicyClick}
        categoryDeleteState={{ isDeletingCategory, deletingCategoryId }}
        policyDeleteState={{ isDeletingPolicy, deletingPolicyId }}
        policyTypes={policyTypes}
      />
    </>
  );
}
