import { EditorContent, useEditor } from '@tiptap/react';
import React from 'react';
import PropTypes from 'prop-types';
import StarterKit from '@tiptap/starter-kit';

import { Box, Divider, Paper, Stack } from '@mantine/core';
import TipTapToolbar from './TipTapToolbar';
import styles from './styles.module.css';
import { Placeholder } from './extensions';

export default function TipTapEditor({ content, onChange, editable, error }) {
  const editor = useEditor(
    {
      content,
      editable,
      extensions: [StarterKit.configure({ code: false }), Placeholder],
      onUpdate: ({ editor: e } = {}) => {
        if (e) {
          const html = e.getHTML();
          const text = e.getText();
          onChange(text && html);
        }
      },
    },
    [content],
  );

  if (!editor) return null;

  return (
    <Paper
      my={0}
      withBorder
      styles={{
        root: {
          borderColor: error ? 'var(--mantine-color-error)' : '',
        },
      }}
    >
      <Stack gap={0}>
        {editor && <TipTapToolbar editor={editor} />}
        <Divider color={error ? 'var(--mantine-color-error)' : ''} />
        {editor && (
          <Box p="xs">
            <EditorContent className={styles.editor} editor={editor} />
          </Box>
        )}
      </Stack>
    </Paper>
  );
}

TipTapEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  // todo change content -> template
  content: PropTypes.string,
  editable: PropTypes.bool,
  error: PropTypes.string,
};
TipTapEditor.defaultProps = {
  content: '',
  error: '',
  editable: true,
};
