import { InputError, InputLabel, Stack } from '@mantine/core';
import { TipTapEditor } from 'components/TipTapEditor';
import React from 'react';
import PropTypes from 'prop-types';

export default function FormEditorField({
  errors,
  template,
  onChange,
  label,
  required,
  ...rest
}) {
  return (
    <Stack gap={0}>
      {label && <InputLabel required={required}>{label}</InputLabel>}
      <TipTapEditor
        error={errors}
        content={template}
        onChange={onChange}
        {...rest}
      />
      {errors && <InputError>{errors}</InputError>}
    </Stack>
  );
}

FormEditorField.propTypes = {
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.string,
  template: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
};

FormEditorField.defaultProps = {
  errors: '',
  template: '',
  label: '',
  required: false,
};
