import React from 'react';
import PropTypes from 'prop-types';
import { IconPlus } from '@tabler/icons-react';
import { Button } from '@mantine/core';

export default function AddButton({ onClick, children, ...rest }) {
  return (
    <Button onClick={onClick} rightSection={<IconPlus size={14} />} {...rest}>
      {children ?? ''}
    </Button>
  );
}

AddButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

AddButton.defaultProps = {
  onClick: () => {},
  children: null,
};
