import {
  useCreateUserRecord,
  useDeleteUserRecord,
  useTrainingsCategories,
  useUpdateUserRecord,
  useUserRecords,
  useUserTrainings,
} from 'api/hooks';
import { CompletedTrainings } from 'components/CompletedTrainings';
import { CreateTrainingRecordModal } from 'components/CreateTrainingRecordModal';
import { UpcomingTrainings } from 'components/UpcomingTrainings.jsx';
import _ from 'lodash';
import moment from 'moment';
import { useNotifications } from 'notifications/hooks/useNotifications';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useSelector, useStateDisclosure } from 'utils/hooks';

export default function TrainingTab({ employeeId }) {
  const { setNotifications } = useNotifications();
  const {
    trainings,
    refetch: refetchUserTrainings,
    isLoading: isTrainingsLoading,
  } = useUserTrainings({
    userId: employeeId,
  });

  const [
    isCreateRecordOpen,
    { open: openCreateRecord, close: closeCreateRecord, state: recordState },
  ] = useStateDisclosure(false);

  const [selectedYear, setSelectedYear] = useSelector();
  const [
    selectedCategory,
    setSelectedCategory,
    { data: categories, setData: setCategories },
  ] = useSelector({
    defaultSelectedValue: '',
    values: [{ label: 'All', value: '' }],
  });

  const {
    records,
    years,
    refetch: refetchUserRecords,
    isLoading: isRecordsLoading,
  } = useUserRecords({
    userId: employeeId,
    filter: {
      year: selectedYear,
      'training.category_id': selectedCategory || undefined,
    },
  });

  useTrainingsCategories(
    {
      onSuccess: (data) => {
        const updCategories = _.map(data, (i) => ({
          label: i.name,
          value: `${i.id}`,
        })).concat({
          label: 'No category',
          value: 'null',
        });
        setCategories([{ label: 'All', value: '' }].concat(updCategories));
      },
    },
    { active: false },
  );

  const {
    deleteUserRecord,
    isLoading: isDeletingRecord,
    variables: deletingItemId,
  } = useDeleteUserRecord({
    onSuccess: () => {
      setNotifications(['User Record Successfully Removed']);
      refetchUserRecords();
      refetchUserTrainings();
    },
    onError: () => {},
  });

  const { createUserRecord, isLoading: isUserRecordCreating } =
    useCreateUserRecord({
      onSuccess: () => {
        setNotifications(['User Record Successfully Created']);
        closeCreateRecord();
        refetchUserRecords();
        refetchUserTrainings();
      },
    });

  const {
    updateUserRecord,
    isLoading: isUpdatingRecord,
    variables: { recordId: updatingRecordId } = {},
  } = useUpdateUserRecord({
    onSuccess: () => {
      setNotifications(['User Record Successfully Updated']);
      closeCreateRecord();
      refetchUserRecords();
      refetchUserTrainings();
    },
  });

  const groupedTrainings = useMemo(
    () => _.groupBy(trainings, 'category_name'),
    [trainings],
  );

  const groupedRecords = useMemo(
    () => _.groupBy(records, 'training.category_name'),
    [records],
  );

  const onSubmit = (values) => {
    const data = {
      complete_date: moment(values.completeDate).format('YYYY-MM-DD'),
      employee_id: employeeId,
      training_id: values.trainingId,
      cost: values.cost || 0,
      notes: values.notes,
      hours: values.hours,
      instructor: values.instructor,
      file: values?.file,
    };

    if (values.modalType === 'Create') {
      createUserRecord(data);
    } else {
      updateUserRecord({ recordId: values?.recordId, payload: data });
    }
  };

  return (
    <>
      {isCreateRecordOpen && (
        <CreateTrainingRecordModal
          opened={isCreateRecordOpen}
          onClose={closeCreateRecord}
          record={recordState}
          onSubmit={onSubmit}
          isLoading={isUserRecordCreating}
        />
      )}
      <UpcomingTrainings
        groupedTrainings={groupedTrainings}
        markAsComplete={(training) =>
          openCreateRecord({
            ...training,
            trainingId: training?.id,
            modalType: 'Create',
          })
        }
        checkedState={recordState}
        isTrainingsLoading={isTrainingsLoading}
      />
      <CompletedTrainings
        groupedRecords={groupedRecords}
        isRecordsLoading={isRecordsLoading}
        deleteUserRecord={deleteUserRecord}
        editUserRecord={(record) =>
          openCreateRecord({
            ...record,
            trainingId: record?.training?.id,
            recordId: record?.id,
            modalType: 'Edit',
          })
        }
        recordDeleteState={{ isDeletingRecord, deletingItemId }}
        recordUpdateState={{ isUpdatingRecord, updatingRecordId }}
        categorySelector={{
          setSelectedCategory,
          categories,
          selectedCategory,
        }}
        yearSelector={{
          years,
          selectedYear,
          setSelectedYear,
        }}
      />
    </>
  );
}

TrainingTab.propTypes = {
  employeeId: PropTypes.string.isRequired,
};
