import { Flex, Select } from '@mantine/core';
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

export default function Period({
  values,
  months,
  valuePath,
  onChange,
  isLast,
}) {
  const daysList = _.map({ length: 28 }, (val, index) => ({
    label: `${index + 1}th`,
    value: `${index + 1}`,
  })).concat({ label: 'Last day', value: '-1' });

  return (
    <Flex align="center" gap={5}>
      on the{' '}
      <Select
        value={values?.day}
        onChange={(val) =>
          onChange(`settings.accrue.period.${valuePath}`, {
            ...values,
            day: val,
          })
        }
        data={daysList}
        allowDeselect={false}
        placeholder="Select Day"
        w={120}
      />
      day of
      <Select
        value={values?.month}
        onChange={(val) =>
          onChange(`settings.accrue.period.${valuePath}`, {
            ...values,
            month: val,
          })
        }
        data={months}
        allowDeselect={false}
        placeholder="Select Month"
        w={140}
      />
      {!isLast && 'and ...'}
    </Flex>
  );
}

Period.propTypes = {
  values: PropTypes.object.isRequired,
  months: PropTypes.array.isRequired,
  valuePath: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isLast: PropTypes.bool,
};

Period.defaultProps = {
  isLast: false,
};
