import { Container, Space, LoadingOverlay } from '@mantine/core';
import moment from 'moment';
import { useForm } from '@mantine/form';
import {
  useNotes,
  useCreateNote,
  useDeleteNote,
  useUpdateNote,
} from 'api/hooks';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { CreateNoteContainer, NoteContainer } from 'components/Notes';
import PropTypes from 'prop-types';
import { genericValidators } from 'utils/validators';

export default function Notes({ contentType, contentId, setNotesCount }) {
  const form = useForm({
    validate: {
      noteText: (val) =>
        genericValidators.notEmpty(val, 'Should not be empty') ||
        genericValidators.lessThanOrEqualTo(
          val,
          255,
          'Should be less than 255 characters',
        ),
    },
  });
  const { setNotifications } = useNotifications();

  const { notes, isLoading, refetch } = useNotes(
    {
      contentId,
      contentType,
    },
    {
      onSuccess: ({ data }) => {
        setNotesCount(data?.length ?? 0);
      },
    },
  );

  const { createNote, isLoading: isCreating } = useCreateNote({
    onSuccess: () => {
      setNotifications(['Note Created Successfully']);
      form.setFieldValue('noteText', '');
      refetch();
    },
  });

  const { updateNote } = useUpdateNote({
    onSuccess: () => {
      setNotifications(['Note Updated Successfully']);
      refetch();
    },
  });

  const onUpdateNote = (noteId, values, callback) => {
    updateNote({
      id: noteId,
      payload: {
        content_id: contentId,
        content_type: contentType,
        text: values.noteText,
      },
      callback,
    });
  };
  const onCreateForm = (values) => {
    createNote({
      content_id: contentId,
      content_type: contentType,
      text: values.noteText,
    });
  };

  const { deleteNote } = useDeleteNote({
    onSuccess: () => {
      setNotifications(['Note Deleted Successfully']);
      refetch();
    },
  });
  const onDeleteNote = (noteId) => {
    deleteNote({ id: noteId });
  };

  const sortNotes = (a, b) => moment(b.created_at).diff(moment(a.created_at));
  return (
    <Container>
      <LoadingOverlay
        visible={isLoading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <CreateNoteContainer
        onSubmit={form.onSubmit(onCreateForm)}
        isLoading={isCreating}
        value={form.values.noteText}
        error={form.errors.noteText}
        onChange={(event) =>
          form.setFieldValue('noteText', event.currentTarget.value)
        }
      />
      {notes?.data?.sort(sortNotes)?.map((note) => (
        <div key={note.id}>
          <NoteContainer
            note={note}
            onDelete={onDeleteNote}
            onUpdateNote={onUpdateNote}
          />
          <Space h="xl" />
        </div>
      ))}
    </Container>
  );
}

Notes.propTypes = {
  contentType: PropTypes.string.isRequired,
  contentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,

  setNotesCount: PropTypes.func,
};

Notes.defaultProps = {
  setNotesCount: () => {},
};
