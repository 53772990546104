import { Stack } from '@mantine/core';
import { Skeleton } from 'components/Skeleton';
import PropTypes from 'prop-types';
import React from 'react';

export default function CompensationGroupSkeleton({ isLoading }) {
  if (!isLoading) return null;

  return (
    <Stack gap={20}>
      {Array.from({ length: 4 }).map((i, index) => (
        <React.Fragment
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        >
          <Skeleton skeletonWrapperProps={{ w: 150, h: 25 }} isVisible />
          <Skeleton count={3} skeletonProps={{ height: 50 }} isVisible />
        </React.Fragment>
      ))}
    </Stack>
  );
}

CompensationGroupSkeleton.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};
