import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useGlobalTabsContext } from './hooks/useGlobalTabsContext';

export default function GlobalTabItem({ children, tab }) {
  const { setTabs, setSelectedTab } = useGlobalTabsContext();
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    setSelectedTab(tab);
    setTabs({
      tabName: tab,
      children: React.cloneElement(children, {
        params,
        globalTabName: tab,
        locationSearch: location.search,
      }),
      pathName: location.pathname,
    });
  }, [
    setTabs,
    tab,
    setSelectedTab,
    children,
    params,
    location.pathname,
    location.search,
  ]);

  return null;
}

GlobalTabItem.propTypes = {
  children: PropTypes.node.isRequired,
  tab: PropTypes.string,
};

GlobalTabItem.defaultProps = {
  tab: '',
};
