import { CloseButton, Flex, Input, Select, rem } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import propTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { userStatuses, userStatusesList } from 'utils/constants';

export default function UserFilter({
  search,
  setSearch,
  selectedStatus,
  setSelectedStatus,
  withStatusFilter,
}) {
  const [value, setValue] = useState(search);

  const [debounced] = useDebouncedValue(value, 300);

  useEffect(() => {
    setSearch(debounced);
  }, [debounced, setSearch]);

  return (
    <Flex gap={rem(10)}>
      <Input
        value={value}
        onChange={(e) => setValue(e.currentTarget.value)}
        placeholder="Name, Title, Department, Location"
        w="100%"
        rightSectionPointerEvents="all"
        rightSection={value && <CloseButton onClick={() => setValue('')} />}
      />
      {withStatusFilter && (
        <Select
          data={userStatusesList}
          onChange={setSelectedStatus}
          value={selectedStatus}
          placeholder="test"
        />
      )}
    </Flex>
  );
}

UserFilter.propTypes = {
  search: propTypes.string.isRequired,
  setSearch: propTypes.func.isRequired,
  selectedStatus: propTypes.string,
  setSelectedStatus: propTypes.func,
  withStatusFilter: propTypes.bool,
};

UserFilter.defaultProps = {
  selectedStatus: userStatuses.all,
  setSelectedStatus: () => {},
  withStatusFilter: true,
};
