import { Button, Flex, Stack, Table } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { clearCache } from 'api/clearCache';
import {
  useCreateEmployeeAsset,
  useDeleteEmployeeAsset,
  useLoadEmployeeAssets,
  useLoadLookups,
  useUpdateEmployeeAsset,
} from 'api/hooks';
import { ConfirmDeleteModal } from 'components/ConfirmDeleteModal';
import { CreateEditEmployeeAssetsModal } from 'components/CreateEditEmployeeAssetsModal';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import { Skeleton } from 'components/Skeleton';
import _ from 'lodash';
import { useNotifications } from 'notifications/hooks/useNotifications';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { lookupsModels } from 'utils/constants';
import { useStateDisclosure } from 'utils/hooks';
import { AssetRecordRow } from './tabs-rows';

const headerList = [
  'Asset Category',
  'Asset Description',
  'Serial #',
  'Date Assigned',
  'Date Returned',
  '',
];

export default function AssetsTab({ employeeId }) {
  const { setNotifications } = useNotifications();
  const queryClient = useQueryClient();

  const [
    openedAddAssetModal,
    {
      open: openAddAssetModal,
      close: closeAddAssetModal,
      state: addAssetState,
    },
  ] = useStateDisclosure(false);

  const [
    openedDeleteAssetModal,
    {
      open: openDeleteAssetModal,
      close: closeDeleteAssetModal,
      state: deleteAssetModalState,
    },
  ] = useStateDisclosure(false);

  const { assets, isLoading: isAssetsLoading } =
    useLoadEmployeeAssets(employeeId);

  const { lookups, isLoading: isAssetsCategoriesLoading } = useLoadLookups({
    models: [lookupsModels.assetCategory],
  });

  const { createEmployeeAsset, isLoading: isEmployeeCreating } =
    useCreateEmployeeAsset({
      onSuccess: () => {
        clearCache.onChangeEmployeeAsset(queryClient, employeeId);
        setNotifications(['Employee Asset Created Successfully']);
        closeAddAssetModal();
      },
    });

  const { updateEmployeeAsset, isLoading: isUpdatingEmployeeAsset } =
    useUpdateEmployeeAsset({
      onSuccess: () => {
        clearCache.onChangeEmployeeAsset(queryClient, employeeId);
        setNotifications(['Employee Asset Updated Successfully']);
        closeAddAssetModal();
      },
    });

  const { deleteEmployeeAsset, isLoading: isDeletingEmployeeAsset } =
    useDeleteEmployeeAsset({
      onSuccess: () => {
        clearCache.onChangeEmployeeAsset(queryClient, employeeId);
        setNotifications(['Employee Asset Deleted Successfully']);
        closeDeleteAssetModal();
      },
    });

  return (
    <Stack gap={0} my={10}>
      <ConfirmDeleteModal
        opened={openedDeleteAssetModal}
        onClose={closeDeleteAssetModal}
        onDelete={() => deleteEmployeeAsset(deleteAssetModalState?.id)}
        isLoading={isDeletingEmployeeAsset}
      />

      <CreateEditEmployeeAssetsModal
        opened={openedAddAssetModal}
        onClose={closeAddAssetModal}
        assetState={addAssetState}
        onCreate={(data) =>
          createEmployeeAsset({ ...data, employee_id: employeeId })
        }
        onEdit={(data) =>
          updateEmployeeAsset({
            ...data,
            assetId: addAssetState?.id,
            employee_id: employeeId,
          })
        }
        assetCategories={lookups[lookupsModels.assetCategory]}
        isLoading={
          isAssetsCategoriesLoading ||
          isEmployeeCreating ||
          isUpdatingEmployeeAsset
        }
      />

      <Flex justify="space-between" align="center">
        <h2 style={{ margin: '0px' }}>Assets</h2>
        <Button
          rightSection={<IconPlus size={14} />}
          onClick={() => openAddAssetModal({ modalType: 'Create' })}
        >
          Add Asset
        </Button>
      </Flex>

      <Skeleton
        count={4}
        isVisible={isAssetsLoading}
        skeletonWrapperProps={{ my: 10 }}
      />

      <EmptyDataMessage
        isVisible={!isAssetsLoading && _.isEmpty(assets)}
        my={20}
      />

      {!isAssetsLoading && !_.isEmpty(assets) && (
        <Table>
          <Table.Thead>
            <Table.Tr>
              {_.map(headerList, (item) => (
                <Table.Th key={item}>{item}</Table.Th>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {_.map(assets, (record) => (
              <AssetRecordRow
                key={record?.id}
                record={record}
                onEdit={(item) =>
                  openAddAssetModal({ ...item, modalType: 'Edit' })
                }
                onDelete={openDeleteAssetModal}
              />
            ))}
          </Table.Tbody>
        </Table>
      )}
    </Stack>
  );
}

AssetsTab.propTypes = {
  employeeId: PropTypes.string.isRequired,
};
