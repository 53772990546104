import React from 'react';
import {
  Button,
  Divider,
  Flex,
  PasswordInput,
  Space,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { IconKey } from '@tabler/icons-react';
import { useAuthContext } from 'auth';
import { useForm } from '@mantine/form';
import { getPasswordStrength } from 'components/Password/utils';
import PasswordConfirmation from 'components/Password/PasswordConfirmation';
import { genericValidators } from 'utils';
import { useUpdateCurrentUser } from 'api/hooks';
import { useNotifications } from 'notifications/hooks/useNotifications';

export default function PasswordAndSecurityTab() {
  const { user } = useAuthContext();
  const { setNotifications } = useNotifications();

  const form = useForm({
    initialValues: {
      prevPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validate: {
      newPassword: (val) =>
        getPasswordStrength(val) === 100 ? null : 'The passwords is not secure',

      confirmNewPassword: (val, { newPassword }) =>
        val === newPassword ? null : "Passwords don't match",

      prevPassword: (val) =>
        genericValidators.notEmpty(val, 'Current password is Empty'),
    },
    validateInputOnChange: ['confirmNewPassword', 'newPassword'],
  });

  const { updateCurrentUser, isLoading: isUserUpdating } = useUpdateCurrentUser(
    {
      onSuccess: () => {
        setNotifications(['Password Updated successfully']);
        form.reset();
      },
    },
  );

  const onSubmit = (data) => {
    updateCurrentUser({
      old_password: data?.prevPassword,
      password: data?.newPassword,
      password_confirmation: data?.confirmNewPassword,
    });
  };

  return (
    <Stack ml={10} gap={5}>
      <Flex gap={10} align="center" mt="10">
        <IconKey color="green" />
        <Text fz={20} fw={500}>
          Change Password
        </Text>
      </Flex>
      <Divider />
      <form onSubmit={form.onSubmit(onSubmit)} noValidate>
        <Space h="md" />
        <Title size={30}>
          {user?.first_name} {user?.last_name}
        </Title>
        <Text c="gray">{user?.email}</Text>
        <Stack gap={5} w="50%">
          <PasswordInput
            security="true"
            leftSection={<IconKey />}
            label="Current Password"
            {...form.getInputProps('prevPassword')}
          />
          <PasswordConfirmation
            newPassword={form.values.newPassword}
            setNewPassword={(val) => form.setFieldValue('newPassword', val)}
            confirmPassword={form.values.confirmNewPassword}
            setConfirmPassword={(val) =>
              form.setFieldValue('confirmNewPassword', val)
            }
            newPasswordError={form.errors.newPassword}
            confirmPasswordError={form.errors.confirmNewPassword}
          />
          <Button disabled={isUserUpdating} type="submit">
            Submit
          </Button>
        </Stack>
      </form>
    </Stack>
  );
}
