import { useLoadCandidateQuestions } from 'api/hooks';
import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'components/Loader';
import { Box, Flex, Stack, Text, Title } from '@mantine/core';
import _ from 'lodash';

export default function CandidateQuestions({ jobId, candidateId }) {
  const { candidateQuestions, isLoading: isQuestionsLoading } =
    useLoadCandidateQuestions({
      jobId,
      candidateId,
    });
  return (
    <>
      <Title size="xl">Application Questions for this Job</Title>
      <Loader isLoading={isQuestionsLoading} />
      <Stack gap={10}>
        {_.map(candidateQuestions, (item, index) => (
          <Box maw="50%" key={item?.id}>
            <Flex gap={0}>
              <Text w="md">{index + 1}.</Text>
              <Text fw="700">{item?.questions}</Text>
            </Flex>
            <Text styles={{ root: { whiteSpace: 'pre-wrap' } }} ml="md">
              {item?.response}
            </Text>
          </Box>
        ))}
      </Stack>
    </>
  );
}

CandidateQuestions.propTypes = {
  jobId: PropTypes.string.isRequired,
  candidateId: PropTypes.number.isRequired,
};
