import {
  Checkbox,
  Divider,
  Flex,
  InputLabel,
  Paper,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Loader } from 'components/Loader';
import { EmptyDataMessage } from 'components/EmptyDataMessage';

export default function UpcomingTrainings({
  groupedTrainings,
  markAsComplete,
  checkedState,
  isTrainingsLoading,
}) {
  return (
    <>
      <h2>Upcoming Training</h2>
      <Loader isLoading={isTrainingsLoading} />
      <EmptyDataMessage
        isVisible={!isTrainingsLoading && _.isEmpty(groupedTrainings)}
      />
      {!_.isEmpty(groupedTrainings) && (
        <Paper withBorder p={10}>
          <Stack gap={10}>
            {_.keys(groupedTrainings).map((category) => {
              const title = category === 'null' ? 'No Category' : category;
              return (
                <div key={category}>
                  <Title pb={5} fz={20}>
                    {title}
                  </Title>
                  <Stack gap={10}>
                    {_.map(groupedTrainings[category], (training) => {
                      const isExpired = training?.end_date
                        ? moment(training?.end_date).diff(moment()) < 0
                        : false;

                      const isDisabled = training?.end_date
                        ? moment(training?.end_date)
                            .subtract(training?.frequency, 'M')
                            .diff(moment()) > 0
                        : false;

                      const startDateText = isDisabled
                        ? `Start ${moment(training.start_date).format(
                            'MMMM DD, YYYY',
                          )} - `
                        : '';

                      const dueDateText = `Due ${moment(
                        training.end_date,
                      ).format('MMMM DD, YYYY')}`;
                      return (
                        <React.Fragment key={training?.id}>
                          <InputLabel>
                            <Flex align="center" gap={10}>
                              <Checkbox
                                checked={checkedState?.id === training?.id}
                                onChange={() => markAsComplete(training)}
                                py={5}
                                disabled={isDisabled}
                              />
                              <Flex align="center" gap={10}>
                                <Text>{training?.name}</Text>
                                {training?.frequency !== 0 &&
                                  training?.end_date && (
                                    <Text
                                      size="sm"
                                      c={isExpired ? 'red' : 'gray'}
                                    >
                                      {startDateText}
                                      {dueDateText}
                                    </Text>
                                  )}
                              </Flex>
                            </Flex>
                          </InputLabel>
                          <Divider />
                        </React.Fragment>
                      );
                    })}
                  </Stack>
                </div>
              );
            })}
          </Stack>
        </Paper>
      )}
    </>
  );
}

UpcomingTrainings.propTypes = {
  groupedTrainings: PropTypes.object.isRequired,
  checkedState: PropTypes.object,
  markAsComplete: PropTypes.func,
  isTrainingsLoading: PropTypes.bool,
};

UpcomingTrainings.defaultProps = {
  isTrainingsLoading: false,
  markAsComplete: () => {},
  checkedState: {},
};
