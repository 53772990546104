import { Accordion, Flex, Space, Stack } from '@mantine/core';
import { AddButton } from 'components/Buttons';
import { CandidateStartEmailConversation } from 'components/CandidateStartEmailConversation';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconChevronDown, IconMail } from '@tabler/icons-react';
import { CandidateSendEmailModal } from 'components/CandidateSendEmailModal';
import { useDisclosure } from '@mantine/hooks';
import _ from 'lodash';
import { ReceivedEmail } from 'components/ReceivedEmail';
import { useCreateCandidateEmail } from 'api/hooks';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { useQueryClient } from 'react-query';
import { queryKeys } from 'api/keys';
import styles from '../styles.module.css';

export default function Email({ candidateDetails, isEmailsLoading, jobId }) {
  const [openedAccordionItemsIds, setOpenedAccordionItemsIds] = useState([]);

  const queryClient = useQueryClient();

  const { setNotifications } = useNotifications();

  const [openedEmailModal, { open: openEmailModal, close: closeEmailModal }] =
    useDisclosure(false);

  const { createCandidateEmail, isLoading: isCandidateEmailLoading } =
    useCreateCandidateEmail({
      onSuccess: () => {
        setNotifications([
          'The message to the candidate was sent successfully',
        ]);
        closeEmailModal();
        queryClient.invalidateQueries(
          queryKeys.candidate(candidateDetails?.id),
        );
      },
    });

  return (
    <Stack>
      <CandidateSendEmailModal
        opened={openedEmailModal}
        onClose={closeEmailModal}
        candidateInitials={candidateDetails?.first_name}
        onSubmit={(data) =>
          createCandidateEmail({
            ...data,
            job_id: jobId,
            id: candidateDetails?.id,
          })
        }
        isLoading={isCandidateEmailLoading}
      />
      <Space h="md" />
      <Flex w="100%" justify="flex-end">
        <AddButton
          onClick={openEmailModal}
          rightSection={<IconMail size={18} />}
        >
          New Email
        </AddButton>
      </Flex>
      {_.isEmpty(candidateDetails?.emails) && !isEmailsLoading && (
        <CandidateStartEmailConversation
          recipientInitials={candidateDetails?.first_name}
        />
      )}
      <Stack gap={0}>
        <Accordion
          classNames={{ chevron: styles.chevron }}
          chevron={<IconChevronDown size={30} />}
          chevronSize={30}
          onChange={setOpenedAccordionItemsIds}
          multiple
          transitionDuration={200}
        >
          {_.map(candidateDetails?.emails, (email) => (
            <ReceivedEmail
              opened={openedAccordionItemsIds.some((id) => +id === email?.id)}
              key={email.id}
              receivedEmail={email}
              candidateDetails={candidateDetails}
            />
          ))}
        </Accordion>
      </Stack>
    </Stack>
  );
}

Email.propTypes = {
  candidateDetails: PropTypes.object.isRequired,
  isEmailsLoading: PropTypes.bool,
  jobId: PropTypes.string.isRequired,
};

Email.defaultProps = {
  isEmailsLoading: false,
};
