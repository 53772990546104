import { Modal } from '@mantine/core';
import { AddEmployeePermissionForm } from 'components/AddEmployeePermissionForm';
import PropTypes from 'prop-types';

export default function AddEmployeePermissionModal({
  opened,
  onClose,
  onCreate,
  isLoading,
}) {
  return (
    <Modal title="Add Employee" opened={opened} onClose={onClose} centered>
      <AddEmployeePermissionForm
        onSubmit={onCreate}
        isLoading={!opened || isLoading}
      />
    </Modal>
  );
}

AddEmployeePermissionModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

AddEmployeePermissionModal.defaultProps = {
  isLoading: false,
};
