import { TextInput, Button, Select, Flex, Box } from '@mantine/core';
import PropTypes from 'prop-types';
import { useForm } from '@mantine/form';
import { genericValidators } from 'utils';
import { Accrual, Flexible, Manually } from '../options';
import AccrualSettings from '../PolicySettings/AccrualSettings';

export default function TimeOffPolicyForm({
  policy,
  categories,
  onSubmit,
  isLoading,
  selectedPolicyType,
}) {
  const { setFieldValue, ...form } = useForm({
    initialValues: {
      name: policy?.name ?? '',
      category_id: policy?.category_id ? `${policy?.category_id}` : '',
      settings: {
        accrue: { ...policy?.settings?.accrue },
        accrue_options: { ...policy?.settings?.accrue_options },
        carryover_rules: { ...policy?.settings?.carryover_rules },
      },
    },
    validate: {
      name: (value) =>
        genericValidators.notLessThan(
          value,
          3,
          'Policy name must be at least 3 characters',
        ) ||
        genericValidators.lessThanOrEqualTo(
          value,
          50,
          'Policy name must be less than 50 chars',
        ),

      category_id: (value) =>
        genericValidators.notEmpty(value, 'Please select Policy Category'),
    },
  });

  const optionsProvider = {
    0: Accrual,
    10: Flexible,
    20: Manually,
  };

  const Type = optionsProvider[selectedPolicyType] ?? null;

  return (
    <>
      {Type && (
        <Box my={20}>
          <Type viewOnly />
        </Box>
      )}
      <form onSubmit={form.onSubmit(onSubmit)} noValidate>
        {Type && selectedPolicyType === '0' && (
          <AccrualSettings formValues={form.values} setValues={setFieldValue} />
        )}
        <TextInput
          label="Policy Name"
          placeholder="Policy Name"
          onChange={(event) => setFieldValue('name', event.currentTarget.value)}
          value={form.values.name}
          error={form.errors.name}
          required
        />
        <Select
          label="Policy Category"
          placeholder="Pick Category"
          data={categories?.map((cat) => ({
            value: `${cat.id}`,
            label: cat.name,
          }))}
          defaultValue={form.values.category_id}
          onChange={(value) => setFieldValue('category_id', value)}
          clearable
          allowDeselect={false}
          error={form.errors.category_id}
          required
        />
        <Flex align="center">
          <Button mx="auto" type="submit" mt="xl" loading={isLoading}>
            Submit
          </Button>
        </Flex>
      </form>
    </>
  );
}

TimeOffPolicyForm.propTypes = {
  onSubmit: PropTypes.func,
  policy: PropTypes.object,
  isLoading: PropTypes.bool,
  categories: PropTypes.array,
  selectedPolicyType: PropTypes.string,
};

TimeOffPolicyForm.defaultProps = {
  onSubmit: () => {},
  isLoading: false,
  policy: null,
  selectedPolicyType: '',
  categories: [],
};
