import {
  Box,
  Button,
  Flex,
  Modal,
  SegmentedControl,
  Stack,
  TextInput,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ConfirmationModal } from 'components/ConfirmationModal';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';

const signatureTypes = {
  draw: 'draw',
  type: 'type',
};

const canvasWidth = 400;
const canvasHeight = 200;
const defaultFontSize = 45;
const offset = 50;

export default function SignatureModal({
  opened,
  onClose,
  onSubmit,
  disabled,
}) {
  const drawCanvasRef = useRef(null);
  const typeCanvasRef = useRef(null);

  const [signatureType, setSignatureType] = useState(signatureTypes.draw);
  const [textSignature, setTextSignature] = useState('');
  const [
    openedConfirmationModal,
    { open: openConfirmationModal, close: closeConfirmationModal },
  ] = useDisclosure(false);

  const writeText = (text, style = {}) => {
    const ctx = typeCanvasRef.current?.getContext?.('2d');

    if (ctx) {
      ctx.clearRect(
        0,
        0,
        typeCanvasRef.current.width,
        typeCanvasRef.current.height,
      );
      const x = offset;
      const y = canvasHeight / 2 + defaultFontSize / 2;
      const {
        fontSize = defaultFontSize,
        fontFamily = 'Brush Script MT',
        color = 'black',
      } = style;

      ctx.beginPath();
      ctx.font = `${fontSize}px ${fontFamily}`;
      ctx.fillStyle = color;
      ctx.fillText(text, x, y, canvasWidth - offset * 2);
      ctx.stroke();
    }
  };

  const getBlob = async (
    canvas,
    {
      offscreenWidth = canvasWidth,
      offscreenHeight = canvasHeight,
      sx = 0,
      sy = 0,
      sw = canvasWidth,
      sh = canvasHeight,
      dx = 0,
      dy = 0,
      dw = canvasWidth,
      dh = canvasHeight,
    },
  ) => {
    const offscreen = new OffscreenCanvas(offscreenWidth, offscreenHeight);

    const ctx = offscreen.getContext('2d');
    if (!ctx) {
      throw new Error('No 2d context');
    }

    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvasWidth, canvasHeight);

    ctx.drawImage(canvas, sx, sy, sw, sh, dx, dy, dw, dh);

    const blob = await offscreen.convertToBlob({
      type: 'image/jpeg',
      quality: 1,
    });

    onSubmit(blob);
  };

  useEffect(() => {
    if (opened) {
      setTextSignature('');
    }
  }, [opened]);

  return (
    <Modal title="Add signature" opened={opened} centered onClose={onClose}>
      <ConfirmationModal
        opened={openedConfirmationModal}
        onClose={closeConfirmationModal}
        onConfirm={() => {
          closeConfirmationModal();
          if (signatureType === signatureTypes.draw) {
            getBlob(drawCanvasRef.current?.getCanvas(), {});
          } else if (signatureType === signatureTypes.type) {
            getBlob(typeCanvasRef.current, {
              offscreenWidth: canvasWidth - offset * 2,
              offscreenHeight: 80,
              sx: offset - 5,
              sy: canvasHeight / 2 - 10,
              sw: canvasWidth - offset * 2 + 5,
              sh: defaultFontSize,
              dw: canvasWidth - offset * 2,
              dh: 80,
            });
          }
        }}
        title="Confirmation"
        isLoading={disabled}
        text="You confirm your signature"
      />
      <Stack gap={10} mih={200}>
        <SegmentedControl
          data={[
            { value: signatureTypes.draw, label: 'Draw' },
            { value: signatureTypes.type, label: 'Type' },
          ]}
          value={signatureType}
          orientation="horizontal"
          onChange={(type) => {
            setSignatureType(type);
            setTextSignature('');
          }}
          withItemsBorders
          fullWidth
          p={7}
          my="auto"
          bg="var(mantine-color-body)"
        />
        <Box
          style={{
            border: '1px solid var(--mantine-color-gray-4)',
            borderRadius: '10px',
            width: canvasWidth,
            height: canvasHeight,
          }}
          mx="auto"
        >
          {signatureType === signatureTypes.draw && (
            <ReactSignatureCanvas
              ref={drawCanvasRef}
              penColor="black"
              canvasProps={{ width: canvasWidth, height: canvasHeight }}
            />
          )}

          {signatureType === signatureTypes.type && (
            <canvas
              ref={typeCanvasRef}
              width={canvasWidth}
              height={canvasHeight}
            />
          )}
        </Box>
        {signatureType === signatureTypes.type && (
          <TextInput
            placeholder="Signature"
            ml="auto"
            onChange={(e) => {
              setTextSignature(e.currentTarget.value);
              writeText(e.currentTarget.value);
            }}
            value={textSignature}
          />
        )}
        <Flex justify="end" gap={10}>
          <Button
            onClick={() => {
              if (signatureType === signatureTypes.draw) {
                drawCanvasRef.current?.clear?.();
              }
              if (signatureType === signatureTypes.type) {
                writeText('');
                setTextSignature('');
              }
            }}
          >
            Clear
          </Button>
          <Button onClick={openConfirmationModal} disabled={disabled}>
            Submit
          </Button>
        </Flex>
      </Stack>
    </Modal>
  );
}

SignatureModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};
