import { Button, Flex, Loader, Space, Table, Title } from '@mantine/core';
import { IconEdit, IconPlus, IconTrash } from '@tabler/icons-react';
import { clearCache } from 'api/clearCache';
import { useDeleteDivision, useLoadDivisions } from 'api/hooks';
import { ContextMenu } from 'components/ContextMenu';
import { CreateEditDivisionModal } from 'components/CreateEditDivisionModal';
import { SettingsTableHead } from 'components/SettingsTable';
import _ from 'lodash';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { useQueryClient } from 'react-query';
import { useStateDisclosure } from 'utils/hooks';

const headerList = ['Division', 'People', ''];

export default function Division() {
  const { divisions, isLoading } = useLoadDivisions();

  const { setNotifications } = useNotifications();
  const queryClient = useQueryClient();

  const { deleteDivision } = useDeleteDivision({
    onSuccess: () => {
      clearCache.onDivisionChange(queryClient);
      setNotifications(['Department Deleted Successfully']);
    },
  });

  const [
    openedDivision,
    { open: openDivision, close: closeDivision, state: stateDivision },
  ] = useStateDisclosure(false);

  return (
    <>
      <CreateEditDivisionModal
        opened={openedDivision}
        onClose={closeDivision}
        division={stateDivision}
      />
      <Flex gap={10} justify="space-between" align="center">
        <Title fz="xl">Division</Title>
        <Button onClick={openDivision} rightSection={<IconPlus size={14} />}>
          Add Division
        </Button>
      </Flex>
      {isLoading && (
        <Flex justify="center">
          <Loader />
        </Flex>
      )}

      <Space h="xs" />

      <Table>
        {!_.isEmpty(divisions) && <SettingsTableHead headerList={headerList} />}
        <Table.Tbody>
          {divisions?.map((division) => {
            const menuItems = [
              {
                label: 'Edit',
                icon: IconEdit,
                onClick: () => {
                  openDivision({ ...division, modalType: 'Edit' });
                },
              },
              {
                label: 'Delete',
                icon: IconTrash,
                onClick: () => {
                  deleteDivision(division.id);
                },
                condition: !division.employees_count,
              },
            ];
            return (
              <Table.Tr key={division.id}>
                <Table.Td>{division.name}</Table.Td>
                <Table.Td>{division.employees_count ?? 0}</Table.Td>
                <Table.Td>
                  <Flex justify="end">
                    <ContextMenu menuItems={menuItems} />
                  </Flex>
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
    </>
  );
}
