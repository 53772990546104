import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@mantine/core';
import { CreateBoardingForm } from 'components/CreateBoardingForm';

export default function BoardingListModal({
  opened,
  onClose,
  title,
  onSubmit,
  listState,
  isLoading,
}) {
  return (
    <Modal opened={opened} onClose={onClose} centered title={title}>
      <CreateBoardingForm
        isLoading={isLoading}
        onSubmit={(data) =>
          onSubmit({ ...listState, ...data, modalType: listState?.modalType })
        }
        listState={listState}
      />
    </Modal>
  );
}

BoardingListModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  listState: PropTypes.object,
  isLoading: PropTypes.bool,
};

BoardingListModal.defaultProps = {
  title: '',
  listState: {},
  isLoading: false,
};
