import { Modal } from '@mantine/core';
import PropTypes from 'prop-types';
import SurveyForm from '../forms/SurveyForm';

export default function SurveyModal({
  isLoading,
  opened,
  onClose,
  onSubmit,
  surveyTypes,
}) {
  return (
    <Modal
      opened={opened}
      onClose={() => {
        onClose();
      }}
      title="Create Survey"
      centered
    >
      <SurveyForm
        isLoading={isLoading}
        onSubmit={onSubmit}
        surveyTypes={surveyTypes}
      />
    </Modal>
  );
}

SurveyModal.propTypes = {
  isLoading: PropTypes.bool,
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  surveyTypes: PropTypes.object,
};

SurveyModal.defaultProps = {
  isLoading: false,
  opened: false,
  surveyTypes: {},
  onClose: () => {},
  onSubmit: () => {},
};
