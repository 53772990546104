import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Select } from '@mantine/core';
import { usePolicyTypeSelect } from 'components/TimeOff/hooks';
import _ from 'lodash';

export default function TwiceMonth({ onChange, formValues, scheduleType }) {
  const twiceMonth = usePolicyTypeSelect({
    onChange,
    formValues,
    scheduleType,
  });

  const firstDays = _.map({ length: 15 }, (val, index) => ({
    label: `${index + 1}th`,
    value: `${index + 1}`,
  }));

  const lastDays = _.map({ length: 15 }, (val, index) => ({
    label: `${index + 15}th`,
    value: `${index + 15}`,
  })).concat({ label: 'Last day', value: '-1' });

  return (
    <Flex align="center" gap={5}>
      on the
      <Select
        value={twiceMonth?.first_day}
        onChange={(val) =>
          onChange('settings.accrue.period.twice_a_month.first_day', val)
        }
        data={firstDays}
        allowDeselect={false}
        placeholder="Select Start Day"
      />
      and
      <Select
        value={twiceMonth?.second_day}
        onChange={(val) =>
          onChange('settings.accrue.period.twice_a_month.second_day', val)
        }
        data={lastDays}
        allowDeselect={false}
        placeholder="Select Start Day"
      />
    </Flex>
  );
}

TwiceMonth.propTypes = {
  formValues: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  scheduleType: PropTypes.string.isRequired,
};
