import { Space, SimpleGrid, LoadingOverlay } from '@mantine/core';
import { useTalentGroups } from 'api/hooks';
import { TalentGroupBlock } from '../tab-rows';

export default function TalentGroups() {
  const { talentGroups, isLoading } = useTalentGroups();

  return (
    <>
      <LoadingOverlay
        visible={isLoading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <Space h="md" />
      <SimpleGrid cols={{ base: 1, md: 3 }} spacing="xl" mt={50}>
        {talentGroups?.map((item) => (
          <TalentGroupBlock key={item.id} item={item} />
        ))}
      </SimpleGrid>
      <Space h="xl" />
    </>
  );
}
