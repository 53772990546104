import { useGoogleLogin } from '@react-oauth/google';
import { useFormatGoogleCodeToToken, useLoadGoogleUserData } from 'api/hooks';
import { authProviders } from 'utils/constants';

const useGoogleAuth = ({ oauthLogin }) => {
  const {
    mutateAsync: loadGoogleUserData,
    isLoading: isUserGoogleDataLoading,
  } = useLoadGoogleUserData({
    onSuccess: () => {},
  });

  const {
    mutateAsync: formatGoogleCodeToToken,
    isLoading: isFormatGoogleCodeToToken,
  } = useFormatGoogleCodeToToken({
    onSuccess: () => {},
  });

  const googleLogin = useGoogleLogin({
    onSuccess: async ({ code }) => {
      const {
        data: { access_token: accessToken, refresh_token: refreshToken },
      } = await formatGoogleCodeToToken(code);
      const {
        data: { email },
      } = await loadGoogleUserData(accessToken);

      oauthLogin({
        access_token: accessToken,
        refresh_token: refreshToken,
        email,
        provider: authProviders.google,
      });
    },
    flow: 'auth-code',
    prompt: 'consent select_account',
    response_type: 'code',
    access_type: 'offline',
  });

  return {
    googleLogin,
    isGoogleLoading: isFormatGoogleCodeToToken || isUserGoogleDataLoading,
  };
};

export default useGoogleAuth;
