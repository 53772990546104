import { Button, NumberInput, Select, Stack, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconCurrencyDollar } from '@tabler/icons-react';
import { DateFormInput } from 'components/DateFormInput';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { formatSelectorData, genericValidators } from 'utils';

export default function AddEmployeeBonusForm({
  onSubmit,
  employeeBonusState,
  isLoading,
  employeeBonusReasons,
}) {
  const form = useForm({
    initialValues: {
      date: employeeBonusState?.date ?? '',
      amount: employeeBonusState?.amount ?? '',
      reason_id: _.toString(employeeBonusState?.reason_id) ?? '',
      comment: employeeBonusState?.comment ?? '',
    },
    validate: {
      date: (val) => genericValidators.notEmpty(val),
      amount: (val) => genericValidators.notEmpty(val),
      reason_id: (val) => genericValidators.notEmpty(val),
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)} noValidate>
      <Stack gap={10}>
        <DateFormInput
          {...form.getInputProps('date')}
          field={{ displayName: 'Date' }}
          required
          onChange={(val) =>
            form.setFieldValue('date', moment(val).format('YYYY-MM-DD'))
          }
        />
        <NumberInput
          required
          label="Amount"
          placeholder="Amount"
          hideControls
          prefix="$"
          rightSection={<IconCurrencyDollar />}
          allowNegative={false}
          {...form.getInputProps('amount')}
        />
        <Select
          data={formatSelectorData(employeeBonusReasons, {
            label: 'name',
            value: 'id',
          })}
          placeholder="Select Reason"
          label="Reason"
          required
          {...form.getInputProps('reason_id')}
          allowDeselect={false}
        />

        <Textarea
          minRows={3}
          label="Comment"
          {...form.getInputProps('comment')}
          resize="block"
          autosize
        />
        <Button disabled={isLoading} type="submit" ml="auto">
          Submit
        </Button>
      </Stack>
    </form>
  );
}

AddEmployeeBonusForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  employeeBonusState: PropTypes.object,
  isLoading: PropTypes.bool,
  employeeBonusReasons: PropTypes.array,
};

AddEmployeeBonusForm.defaultProps = {
  isLoading: false,
  employeeBonusState: {},
  employeeBonusReasons: [],
};
