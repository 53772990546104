import { Space, Tabs, rem } from '@mantine/core';
import {
  IconActivity,
  IconBeach,
  IconCalendarTime,
  IconCash,
  IconClipboardText,
  IconLink,
  IconLockPlus,
  IconSchool,
  IconSettings,
  IconSettingsShare,
  IconUserPentagon,
  IconUserSearch,
  IconUsersGroup,
} from '@tabler/icons-react';
import { TabList, TabsCore } from 'components/Tabs';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { usePageTitle } from 'utils/hooks';
import { PageWrapper } from '../../components/PageWrapper';
import {
  CompanyLinksTab,
  CompensationTab,
  HiringProviderTab,
  HolidaysTab,
  IntegrationsTab,
  JobInformation,
  OnboardingProviderTab,
  PerformanceManagementTab,
  PermissionsTab,
  SurveysTab,
  TimeOffTab,
  TrainingsTab,
} from './tabs';

const iconStyle = { width: rem(18), height: rem(18) };
const tabs = [
  'general',
  'trainings',
  'time-off',
  'surveys',
  'performance-management',
  'job-information',
  'onboarding',
  'hiring',
  'integrations',
  'permissions',
  'compensation',
  'holidays',
  'company-links',
];

const tabLabels = {
  [tabs[0]]: 'General',
  [tabs[1]]: 'Trainings',
  [tabs[2]]: 'Time Off',
  [tabs[3]]: 'Surveys',
  [tabs[4]]: 'Performance Management',
  [tabs[5]]: 'Job Info',
  [tabs[6]]: 'Onboarding',
  [tabs[7]]: 'Hiring',
  [tabs[8]]: 'Integrations',
  [tabs[9]]: 'Permissions',
  [tabs[10]]: 'Compensation',
  [tabs[11]]: 'Holidays',
  [tabs[12]]: 'Company Links',
};

export default function Settings({ params, globalTabName }) {
  const { tabValue } = params;
  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    if (!tabValue || !tabs.includes(tabValue)) {
      navigate('/settings/general', { replace: true });
    }
  }, [tabValue, navigate]);

  usePageTitle(`Settings - ${tabLabels[tabValue] ?? ''}`, globalTabName);

  return (
    <PageWrapper title="Settings" withFlexStyles>
      <TabsCore
        value={tabValue}
        onChange={(value) => navigate(`/settings/${value}`)}
      >
        <TabList>
          <Tabs.Tab
            value="general"
            leftSection={<IconSettings style={iconStyle} />}
          >
            {t('settings.tabs.general')}
          </Tabs.Tab>
          <Tabs.Tab
            value="trainings"
            leftSection={<IconSchool style={iconStyle} />}
          >
            {t('settings.tabs.trainings')}
          </Tabs.Tab>
          <Tabs.Tab
            value="time-off"
            leftSection={<IconCalendarTime style={iconStyle} />}
          >
            {t('settings.tabs.timeOff')}
          </Tabs.Tab>

          <Tabs.Tab
            value="performance-management"
            leftSection={<IconActivity style={iconStyle} />}
          >
            {t('settings.tabs.performanceManagement')}
          </Tabs.Tab>

          <Tabs.Tab
            value="surveys"
            leftSection={<IconClipboardText style={iconStyle} />}
          >
            {t('settings.tabs.surveys')}
          </Tabs.Tab>
          <Tabs.Tab
            value="job-information"
            leftSection={<IconUserPentagon style={iconStyle} />}
          >
            {t('settings.tabs.jobInformation')}
          </Tabs.Tab>
          <Tabs.Tab
            value="onboarding"
            leftSection={<IconUsersGroup style={iconStyle} />}
          >
            {t('settings.tabs.onboarding')}
          </Tabs.Tab>
          <Tabs.Tab
            value="hiring"
            leftSection={<IconUserSearch style={iconStyle} />}
          >
            {t('settings.tabs.hiring')}
          </Tabs.Tab>
          <Tabs.Tab
            value="integrations"
            leftSection={<IconSettingsShare style={iconStyle} />}
          >
            {t('settings.tabs.integrations')}
          </Tabs.Tab>
          <Tabs.Tab
            value="permissions"
            leftSection={<IconLockPlus style={iconStyle} />}
          >
            {t('settings.tabs.permissions')}
          </Tabs.Tab>
          <Tabs.Tab
            value="compensation"
            leftSection={<IconCash style={iconStyle} />}
          >
            {t('settings.tabs.compensation')}
          </Tabs.Tab>
          <Tabs.Tab
            value="holidays"
            leftSection={<IconBeach style={iconStyle} />}
          >
            {t('settings.tabs.holidays')}
          </Tabs.Tab>
          <Tabs.Tab
            value="company-links"
            leftSection={<IconLink style={iconStyle} />}
          >
            {t('settings.tabs.company-links')}
          </Tabs.Tab>
        </TabList>
        <Tabs.Panel value="general">
          <Space h="md" />
          {t('settings.tabs.general')}
        </Tabs.Panel>
        <Tabs.Panel value="trainings">
          <TrainingsTab />
        </Tabs.Panel>
        <Tabs.Panel h="100%" value="time-off">
          <TimeOffTab />
        </Tabs.Panel>
        <Tabs.Panel h="100%" value="performance-management">
          <PerformanceManagementTab />
        </Tabs.Panel>
        <Tabs.Panel h="100%" value="surveys">
          <SurveysTab />
        </Tabs.Panel>
        <Tabs.Panel h="100%" value="job-information">
          <JobInformation />
        </Tabs.Panel>
        <Tabs.Panel h="100%" value="onboarding">
          <OnboardingProviderTab />
        </Tabs.Panel>
        <Tabs.Panel h="100%" value="hiring">
          <HiringProviderTab />
        </Tabs.Panel>
        <Tabs.Panel h="100%" value="integrations">
          <IntegrationsTab />
        </Tabs.Panel>
        <Tabs.Panel h="100%" value="permissions">
          <PermissionsTab />
        </Tabs.Panel>
        <Tabs.Panel h="100%" value="compensation">
          <CompensationTab />
        </Tabs.Panel>
        <Tabs.Panel h="100%" value="holidays">
          <HolidaysTab />
        </Tabs.Panel>
        <Tabs.Panel h="100%" value="company-links">
          <CompanyLinksTab />
        </Tabs.Panel>
      </TabsCore>
    </PageWrapper>
  );
}

Settings.propTypes = {
  params: PropTypes.object,
  globalTabName: PropTypes.string,
};

Settings.defaultProps = {
  params: {},
  globalTabName: '',
};
