import { Box, Flex, Paper, Stack, Text } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import React from 'react';
import PropTypes from 'prop-types';

export default function FormOption({
  label,
  text,
  icon,
  selected,
  onClick,
  error,
  viewOnly,
}) {
  const { ref, hovered } = useHover();

  const border = selected
    ? 'var(--mantine-color-blue-6)'
    : 'var(--mantine-color-gray-6)';

  return (
    <Paper
      ref={ref}
      w="100%"
      withBorder
      p="md"
      style={{
        [selected || error ? 'borderColor' : '']: error
          ? 'var(--mantine-color-red-6)'
          : border,
        [hovered ? 'transform' : '']: `${
          hovered && !viewOnly ? 'scale(1.01)' : ''
        }`,
        transition: 'border-color .2s, transform .15s',
        cursor: viewOnly ? 'auto' : 'pointer',
      }}
      onClick={onClick}
    >
      <Flex gap={2}>
        <Box>{icon}</Box>
        <Stack gap={0}>
          <Text fw="600">{label}</Text>
          <Text size="14px" c="gray">
            {text}
          </Text>
        </Stack>
      </Flex>
    </Paper>
  );
}

FormOption.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  error: PropTypes.bool,
  viewOnly: PropTypes.bool,
};

FormOption.defaultProps = {
  selected: false,
  error: false,
  viewOnly: false,
};
