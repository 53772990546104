import { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { IconInfoCircle } from '@tabler/icons-react';

import { notifications } from '@mantine/notifications';
import NotificationsContext from './NotificationsContext';
import classes from './NotificationsProvider.module.css';

export default function NotificationsProvider({ children }) {
  const setNotifications = useCallback((item) => {
    if (Array.isArray(item)) {
      item.forEach((notification) =>
        notifications.show({
          title: 'Info',
          message: notification,
          classNames: classes,
          icon: <IconInfoCircle />,
        }),
      );
    } else {
      notifications.show({
        title: 'Info',
        message: item,
        classNames: classes,
        icon: <IconInfoCircle />,
      });
    }
  }, []);
  const setErrors = useCallback((error) => {
    if (Array.isArray(error)) {
      error.forEach((notification, index) =>
        setTimeout(() => {
          notifications.show({
            title: 'Error',
            message: notification,
            classNames: classes,
            color: 'red',
            icon: <IconInfoCircle />,
          });
        }, 200 * index),
      );
    } else {
      notifications.show({
        title: 'Error',
        message: error,
        color: 'red',
        icon: <IconInfoCircle />,
        classNames: classes,
      });
    }
  }, []);

  const notificationsContext = useMemo(
    () => ({
      notifications,
      setErrors,
      setNotifications,
    }),
    [setNotifications, setErrors],
  );

  return (
    <NotificationsContext.Provider value={notificationsContext}>
      {children}
    </NotificationsContext.Provider>
  );
}

NotificationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
