import {
  Box,
  Breadcrumbs,
  Button,
  Center,
  Flex,
  Grid,
  LoadingOverlay,
  Paper,
  SegmentedControl,
  Table,
  Text,
  Tooltip,
  rem,
} from '@mantine/core';
import {
  IconChevronLeft,
  IconFileUpload,
  IconFolderPlus,
  IconLayout2,
  IconLayoutList,
} from '@tabler/icons-react';
import { PermissionGuard } from 'components/PermissionGuard';
import { usePermissions } from 'components/PermissionGuard/hooks';
import PropTypes from 'prop-types';
import FileColumn from './file/FileColumn';
import FileTableRow from './file/FileTableRow';

export default function FileManager({
  files,
  onClick,
  isLoading,
  displayMode,
  onDeleteClick,
  onDownloadClick,
  fileActions,
}) {
  const hasFiles = files.length > 0;
  return (
    <Box pos="relative" mih={rem(400)}>
      <LoadingOverlay
        visible={isLoading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      {hasFiles && displayMode === 'grid' && (
        <Grid gutter="xl" py="xl">
          {files?.map((file) => (
            <FileColumn
              onDelete={onDeleteClick}
              key={file?.id}
              file={file}
              onClick={onClick}
              onDownload={onDownloadClick}
              fileActions={fileActions}
            />
          ))}
        </Grid>
      )}
      {hasFiles && displayMode === 'list' && (
        <Table highlightOnHover>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Name</Table.Th>
              <Table.Th>Items</Table.Th>
              <Table.Th>Size</Table.Th>
              <Table.Th>Created At</Table.Th>
              <Table.Th>Updated At</Table.Th>
              <Table.Th>Description</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {files?.map((file) => (
              <FileTableRow
                key={file?.id}
                file={file}
                onClick={onClick}
                onDelete={onDeleteClick}
                onDownload={onDownloadClick}
                fileActions={fileActions}
              />
            ))}
          </Table.Tbody>
        </Table>
      )}
      {!hasFiles && !isLoading && (
        <Flex mih={rem(400)} justify="center" align="center">
          <Text size="xl" c="dimmed" ta="center">
            There are no files
          </Text>
        </Flex>
      )}
    </Box>
  );
}

export function Header({
  path,
  onBackClick,
  onCreateFolderClick,
  onUploadFileClick,
  onDisplayModeChange,
  withPath,
  withCreateButtons,
}) {
  const { permissions } = usePermissions();
  return (
    <Paper radius="lg" withBorder p="lg">
      <Flex>
        {withPath && (
          <>
            <Button mr={20} variant="subtle" p="0" onClick={onBackClick}>
              <IconChevronLeft
                color="var(--dark-white)"
                style={{ width: rem(25), height: rem(25) }}
              />
            </Button>

            <Flex
              justify="left"
              direction="row"
              style={{ flex: 1 }}
              wrap="wrap"
            >
              <Flex mr={15} justify="left" align="center" direction="row">
                <Text>/</Text>
              </Flex>
              <Breadcrumbs>
                {path.map((item) => (
                  <Text key={item.id}>{item.name}</Text>
                ))}
              </Breadcrumbs>
            </Flex>
          </>
        )}
        {withCreateButtons && (
          <PermissionGuard permission={permissions.canManageEmployee}>
            <Tooltip label="Create Folder">
              <Button
                mr={20}
                variant="subtle"
                p="0"
                onClick={onCreateFolderClick}
              >
                <IconFolderPlus
                  color="var(--dark-white)"
                  style={{ width: rem(25), height: rem(25) }}
                />
              </Button>
            </Tooltip>

            <Tooltip label="Upload File">
              <Button
                mr={20}
                variant="subtle"
                p="0"
                onClick={onUploadFileClick}
              >
                <IconFileUpload
                  color="var(--dark-white)"
                  style={{ width: rem(25), height: rem(25) }}
                />
              </Button>
            </Tooltip>
          </PermissionGuard>
        )}

        {onDisplayModeChange && (
          <SegmentedControl
            radius="lg"
            defaultValue="grid"
            onChange={onDisplayModeChange}
            ml={!withPath ? 'auto' : undefined}
            data={[
              {
                value: 'grid',
                label: (
                  <Center>
                    <IconLayout2 style={{ width: rem(16), height: rem(16) }} />
                    <Box ml={10}>Grid</Box>
                  </Center>
                ),
              },
              {
                value: 'list',
                label: (
                  <Center>
                    <IconLayoutList
                      style={{ width: rem(16), height: rem(16) }}
                    />
                    <Box ml={10}>List</Box>
                  </Center>
                ),
              },
            ]}
            mr={20}
          />
        )}
      </Flex>
    </Paper>
  );
}

Header.propTypes = {
  path: PropTypes.array,
  onBackClick: PropTypes.func,
  onUploadFileClick: PropTypes.func,
  onCreateFolderClick: PropTypes.func,
  onDisplayModeChange: PropTypes.func,
  withPath: PropTypes.bool,
  withCreateButtons: PropTypes.bool,
};

Header.defaultProps = {
  path: [],
  onBackClick: () => {},
  onUploadFileClick: () => {},
  onCreateFolderClick: () => {},
  onDisplayModeChange: null,
  withPath: true,
  withCreateButtons: true,
};

FileManager.Header = Header;
FileManager.propTypes = {
  displayMode: PropTypes.string,
  files: PropTypes.array,
  onClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onDownloadClick: PropTypes.func,
  isLoading: PropTypes.bool,
  fileActions: PropTypes.array,
};
FileManager.defaultProps = {
  files: [],
  fileActions: [],
  onClick: () => {},
  onDeleteClick: () => {},
  onDownloadClick: () => {},
  isLoading: false,
  displayMode: 'grid',
};
