import { IconCloudDownload, IconTrash } from '@tabler/icons-react';
import { ContextMenu } from 'components/ContextMenu';
import { usePermissions } from 'components/PermissionGuard/hooks';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { fileTypes } from 'utils/constants';

export default function FileContextMenu({
  file,
  onDelete,
  onDownload,
  fileActions,
  position,
}) {
  const { permissions, hasPermission } = usePermissions();

  const menuItems = useMemo(() => {
    const isFolder = file?.type === fileTypes.directory;

    const result = [];
    if (hasPermission(permissions.canManageEmployee)) {
      result.push({
        label: 'Delete',
        icon: IconTrash,
        onClick: () => {
          onDelete(file);
        },
      });
    }
    if (!isFolder) {
      result.push({
        label: 'Download',
        icon: IconCloudDownload,
        onClick: () => {
          onDownload(file);
        },
      });
    }

    if (fileActions) {
      _.forEach(fileActions, ({ type, onClick, condition, ...rest }) => {
        if (!type) result.push(rest);
        if (file?.type === type)
          result.push({
            ...rest,
            onClick: () => onClick(file),
            condition: condition?.(file) ?? undefined,
          });
      });
    }

    return result;
  }, [file, hasPermission, permissions, onDownload, onDelete, fileActions]);

  return <ContextMenu menuItems={menuItems} targetPosition={position} />;
}

FileContextMenu.propTypes = {
  file: PropTypes.object.isRequired,
  onDownload: PropTypes.func,
  onDelete: PropTypes.func,
  position: PropTypes.string,
  fileActions: PropTypes.array,
};

FileContextMenu.defaultProps = {
  onDownload: () => {},
  onDelete: () => {},
  position: '',
  fileActions: [],
};
