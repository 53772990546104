import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { Flex, Loader, Table, Text } from '@mantine/core';
import { useListState, useToggle } from '@mantine/hooks';
import {
  IconClipboardText,
  IconEdit,
  IconGripVertical,
  IconTrash,
} from '@tabler/icons-react';
import clsx from 'clsx';
import { ContextMenu } from 'components/ContextMenu';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import classes from './CandidateStatusesRow.module.css';

export default function CandidateStatusesRow({
  type,
  data,
  onEdit,
  onDelete,
  onReorder,
  isLoading,
}) {
  const [state, { setState, reorder }] = useListState(data);
  const [listStatus, toggleListStatus] = useToggle([
    'initializing',
    'reorder',
    'hold',
  ]);

  useEffect(() => {
    setState(data);
  }, [data, setState]);

  const reorderList = useCallback(
    (from, to) => {
      if (!_.isNil(from) && !_.isNil(to) && !_.isEqual(from, to)) {
        toggleListStatus('reorder');
        reorder({ from, to });
      } else {
        toggleListStatus('hold');
      }
    },
    [reorder, toggleListStatus],
  );

  useEffect(() => {
    if (listStatus === 'reorder') {
      onReorder(state);
      toggleListStatus('hold');
    }
  }, [state, onReorder, listStatus, toggleListStatus]);

  const items = state.map((item, index) => (
    <Draggable
      isDragDisabled={isLoading}
      key={item.id}
      index={index}
      draggableId={String(item.id)}
    >
      {(provided, snapshot) => {
        const menuItems = [
          {
            label: 'Edit',
            icon: IconEdit,
            onClick: () => onEdit(item),
            condition: Boolean(item?.is_editable),
          },
          {
            label: 'Delete',
            icon: IconTrash,
            onClick: () => onDelete(item),
          },
        ];
        return (
          <div
            className={clsx(classes.item, {
              [classes.itemDragging]: snapshot.isDragging,
            })}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <Flex gap={10} align="center">
              {isLoading ? (
                <Loader size={20} />
              ) : (
                <IconGripVertical
                  opacity={snapshot.isDragging ? 0.4 : 1}
                  size={20}
                />
              )}
              <Text>{item.name}</Text>
            </Flex>
            <Flex justify="end">
              <ContextMenu
                menuItems={menuItems.filter(
                  (i) => i.condition === undefined || i?.condition,
                )}
              />
            </Flex>
          </div>
        );
      }}
    </Draggable>
  ));

  return (
    <>
      <Table.Tr className={classes.typeRow}>
        <Table.Td>
          <Flex align="center" gap={10}>
            <IconClipboardText /> {type}
          </Flex>
        </Table.Td>
      </Table.Tr>

      <Table.Tr>
        <Table.Td p={0}>
          <DragDropContext
            onDragEnd={({ destination, source }) =>
              reorderList(source?.index, destination?.index)
            }
          >
            <Droppable droppableId="dnd-list" direction="vertical">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {items}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Table.Td>
      </Table.Tr>
    </>
  );
}

CandidateStatusesRow.propTypes = {
  onReorder: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.array,
  isLoading: PropTypes.bool,
};
CandidateStatusesRow.defaultProps = {
  data: [],
  isLoading: false,
};
