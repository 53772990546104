import { InteractionType } from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { useEffect } from 'react';
import { authProviders, oauthAPI } from 'utils/constants';

const useMicrosoftAuth = ({ oauthLogin, isOauthLoading }) => {
  const { accounts } = useMsal();

  const { login: loginMicrosoft } = useMsalAuthentication(InteractionType.None);

  useEffect(() => {
    const originalFetch = window.fetch;

    // This fetch is made to intercept the request from the library when we get access/refresh tokens
    window.fetch = (...args) =>
      originalFetch(...args).then((response) => {
        const clonedResponse = response.clone();
        clonedResponse.text().then((resp) => {
          const account = accounts?.[0];
          let parsedResponse = {};
          try {
            parsedResponse = JSON.parse(resp);
          } catch (e) {
            // eslint-disable-next-line
            console.error(e);
          }
          if (
            account &&
            !isOauthLoading &&
            args[0] === oauthAPI.microsoftLogin
          ) {
            oauthLogin({
              access_token: parsedResponse?.access_token,
              refresh_token: parsedResponse?.refresh_token,
              email: account.username,
              provider: authProviders.microsoft,
            });
          }
        });
        return response;
      });
    return () => {
      window.fetch = originalFetch;
    };
  }, [accounts, oauthLogin, isOauthLoading]);

  const login = async () => {
    await loginMicrosoft(InteractionType.Popup).catch(() => {});
  };

  return login;
};

export default useMicrosoftAuth;
