import { useForm } from '@mantine/form';
import { JobInformationSelectors } from 'components/JobInformationSelectors';
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Stack } from '@mantine/core';
import { datesValidators, genericValidators } from 'utils';
import { DateFormInput } from 'components/DateFormInput';
import _ from 'lodash';
import { lookupsModels } from 'utils/constants';
import { useLoadLookups } from 'api/hooks';

export default function JobInformationForm({
  onSubmit,
  isCreating,
  jobInformationState,
}) {
  const form = useForm({
    initialValues: {
      location_id: _.toString(jobInformationState?.location?.id) ?? '',
      department_id: _.toString(jobInformationState?.department?.id) ?? '',
      division_id: _.toString(jobInformationState?.division?.id) ?? '',
      jobtitle_id: _.toString(jobInformationState?.jobTitle?.id) ?? '',
      report_to: _.toString(jobInformationState?.report_to?.id) ?? '',
      date: jobInformationState?.date ?? '',
    },
    validate: {
      jobtitle_id: (value) =>
        genericValidators.notEmpty(value, 'Job Title should not be empty'),
      location_id: (value) =>
        genericValidators.notEmpty(value, 'Location should not be empty'),
      department_id: (value) =>
        genericValidators.notEmpty(value, 'Department should not be empty'),
      division_id: (value) =>
        genericValidators.notEmpty(value, 'Division should not be empty'),
      report_to: (value) =>
        genericValidators.notEmpty(value, 'Report to should not be empty'),
      date: (value) =>
        datesValidators.isValidDate(value, 'Date should not be empty'),
    },
  });

  const { lookups } = useLoadLookups({
    models: [
      lookupsModels.location,
      lookupsModels.department,
      lookupsModels.division,
      lookupsModels.jobTitle,
    ],
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)} noValidate>
      <Stack gap={10}>
        <JobInformationSelectors
          jobInformation={{
            locations: lookups[lookupsModels.location],
            departments: lookups[lookupsModels.department],
            divisions: lookups[lookupsModels.division],
            jobTitles: lookups[lookupsModels.jobTitle],
          }}
          jobInformationState={jobInformationState}
          values={form.values}
          errors={form.errors}
          onChange={(value, field) => form.setFieldValue(value, field)}
        />
        <DateFormInput
          field={{
            displayName: 'Date',
            placeholder: 'Date',
          }}
          label="Date"
          required
          value={form.values.date}
          onChange={(val) => form.setFieldValue('date', val)}
          error={form.errors.date}
        />
        <Button disabled={isCreating} type="submit">
          Submit
        </Button>
      </Stack>
    </form>
  );
}

JobInformationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isCreating: PropTypes.bool,
  jobInformationState: PropTypes.object,
};

JobInformationForm.defaultProps = {
  isCreating: false,
  jobInformationState: {},
};
