import { Modal } from '@mantine/core';
import { CreateEditEmployeeStatusFrom } from 'components/CreateEditEmployeeStatusForm';
import PropTypes from 'prop-types';

export default function CreateEditEmployeeStatusModal({
  opened,
  onClose,
  statusState,
  onEdit,
  onCreate,
  isLoading,
}) {
  return (
    <Modal
      title={`${statusState?.modalType ?? ''} employee status`}
      opened={opened}
      onClose={onClose}
      centered
    >
      <CreateEditEmployeeStatusFrom
        statusState={statusState}
        onSubmit={statusState?.modalType === 'Edit' ? onEdit : onCreate}
        isLoading={isLoading}
        disabled={!opened}
      />
    </Modal>
  );
}

CreateEditEmployeeStatusModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  statusState: PropTypes.object,
  onEdit: PropTypes.func,
  onCreate: PropTypes.func,
  isLoading: PropTypes.bool,
};

CreateEditEmployeeStatusModal.defaultProps = {
  statusState: {},
  onEdit: () => {},
  onCreate: () => {},
  isLoading: false,
};
