import { FormOption } from 'components/FormOption';
import React from 'react';
import PropTypes from 'prop-types';
import { IconUserStar } from '@tabler/icons-react';

export default function Manually({ onClick, selected, error, viewOnly }) {
  return (
    <FormOption
      label="Manually Managed"
      text="Doesn’t automatically accrue time. Balances are adjusted manually."
      icon={<IconUserStar size="30" />}
      onClick={onClick}
      selected={selected}
      error={error}
      viewOnly={viewOnly}
    />
  );
}

Manually.propTypes = {
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  error: PropTypes.bool,
  viewOnly: PropTypes.bool,
};

Manually.defaultProps = {
  onClick: () => {},
  selected: false,
  error: false,
  viewOnly: false,
};
