import {
  TextInput,
  Button,
  Checkbox,
  Select,
  Space,
  Tooltip,
} from '@mantine/core';
import { IconHelp } from '@tabler/icons-react';

import PropTypes from 'prop-types';
import { useForm } from '@mantine/form';
import { genericValidators } from 'utils';
import { frequencyValues } from 'utils/constants';

export default function TrainingForm({
  training,
  onSubmit,
  isLoading,
  categories,
}) {
  const isRequired = training?.required ? training?.required === 1 : false;
  const form = useForm({
    initialValues: {
      name: training?.name ?? '',
      description: training?.description ?? '',
      url: training?.url ?? '',
      frequency: `${training?.frequency ?? 0}`,
      required: training?.required ? training?.required === 1 : false,
      category_id: `${training?.category_id ?? ''}`,
    },
    validate: {
      name: (value) =>
        genericValidators.notEmpty(value, 'Training name is empty') ||
        genericValidators.lessThanOrEqualTo(
          value,
          50,
          'Training name must be less than 50 chars',
        ),
    },
  });
  return (
    <form onSubmit={form.onSubmit(onSubmit)} noValidate>
      <Space h="md" />
      <TextInput
        label="Training Name"
        placeholder="Training name"
        onChange={(event) =>
          form.setFieldValue('name', event.currentTarget.value)
        }
        value={form.values.name}
        error={form.errors.name}
        required
      />
      <Space h="md" />

      <TextInput
        label="Training Description"
        placeholder="Training description"
        onChange={(event) =>
          form.setFieldValue('description', event.currentTarget.value)
        }
        value={form.values.description}
        error={form.errors.description}
      />
      <Space h="md" />

      <TextInput
        label="Url"
        placeholder="https://www..."
        onChange={(event) =>
          form.setFieldValue('url', event.currentTarget.value)
        }
        value={form.values.url}
        error={form.errors.url}
      />
      <Space h="md" />

      <Select
        label="Frequency"
        placeholder="Pick Frequency"
        data={frequencyValues}
        rightSection={
          <Tooltip
            withArrow
            label=" This indicates the timeframe within which an employee is required to undergo the training again.  Please type 0 if is one time training"
          >
            <IconHelp size={20} color="black" />
          </Tooltip>
        }
        defaultValue={form.values.frequency}
        onChange={(value) => form.setFieldValue('frequency', value)}
      />
      <Space h="md" />

      <Checkbox
        defaultChecked={isRequired}
        label="Is Required"
        value={form.values.required}
        onChange={(e) => form.setFieldValue('required', e.target.checked)}
      />
      <Space h="md" />

      <Select
        label="Training Category"
        placeholder="Pick Category"
        data={categories?.map((cat) => ({
          value: `${cat.id}`,
          label: cat.name,
        }))}
        defaultValue={form.values.category_id}
        onChange={(value) => form.setFieldValue('category_id', value)}
        clearable
      />
      <Space h="md" />

      <Button type="submit" fullWidth mt="xl" loading={isLoading}>
        Submit
      </Button>
    </form>
  );
}

TrainingForm.propTypes = {
  onSubmit: PropTypes.func,
  training: PropTypes.object,
  isLoading: PropTypes.bool,
  categories: PropTypes.array,
};

TrainingForm.defaultProps = {
  onSubmit: () => {},
  isLoading: false,
  training: null,
  categories: [],
};
