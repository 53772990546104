import { Modal } from '@mantine/core';
import PropTypes from 'prop-types';
import RequestForm from '../forms/RequestForm';

export default function RequestModal({ isLoading, opened, onClose, onSubmit }) {
  return (
    <Modal
      opened={opened}
      onClose={() => {
        onClose();
      }}
      title="Create Request"
      centered
    >
      <RequestForm isLoading={isLoading} onSubmit={onSubmit} />
    </Modal>
  );
}

RequestModal.propTypes = {
  isLoading: PropTypes.bool,
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

RequestModal.defaultProps = {
  isLoading: false,
  opened: false,
  onClose: () => {},
  onSubmit: () => {},
};
