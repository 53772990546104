import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Flex,
  InputLabel,
  Modal,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';

export default function DoubleConfirmDeleteModal({
  opened,
  onClose,
  title,
  onDelete,
  deleteText,
  deleteLabel,
  isLoading,
  confirmationPass,
  additionInfo,
}) {
  const [confirmationText, setConfirmationText] = useState('');

  useEffect(() => {
    setConfirmationText('');
  }, [opened]);

  return (
    <Modal onClose={onClose} opened={opened} title={title ?? ''} centered>
      <Stack>
        <Text size="sm">
          Are you sure you want to delete{' '}
          <Text span fw={500}>
            {deleteText ?? ''}
          </Text>
        </Text>
        <Stack gap={5}>
          {additionInfo}
          <InputLabel c="red">
            Type &quot;{confirmationPass}&quot; to continue
          </InputLabel>
          <TextInput
            value={confirmationText}
            onChange={(e) => setConfirmationText(e.currentTarget.value)}
          />
        </Stack>
      </Stack>
      <Flex mt={10} justify="end" gap={10}>
        <Button variant="default" onClick={onClose}>
          Cancel
        </Button>
        <Button
          c="white"
          bg="red"
          onClick={onDelete}
          loading={isLoading}
          disabled={confirmationPass !== confirmationText || !opened}
        >
          {deleteLabel}
        </Button>
      </Flex>
    </Modal>
  );
}

DoubleConfirmDeleteModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  confirmationPass: PropTypes.string,
  title: PropTypes.string,
  deleteLabel: PropTypes.string,
  deleteText: PropTypes.string,
  isLoading: PropTypes.bool,
  additionInfo: PropTypes.node,
};

DoubleConfirmDeleteModal.defaultProps = {
  title: '',
  deleteText: '',
  deleteLabel: 'Delete',
  confirmationPass: 'Delete',
  isLoading: false,
  additionInfo: null,
};
