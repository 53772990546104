import { Modal } from '@mantine/core';
import PropTypes from 'prop-types';
import { TrainingCategoryForm } from 'components/TrainingCategoryForm';

export default function CreateCategoryModal({
  isLoading,
  opened,
  onClose,
  onSubmit,
  category,
}) {
  return (
    <Modal
      opened={opened}
      onClose={() => {
        onClose();
      }}
      title="Create Category"
      centered
    >
      <TrainingCategoryForm
        category={category}
        isLoading={isLoading}
        onSubmit={onSubmit}
      />
    </Modal>
  );
}

CreateCategoryModal.propTypes = {
  isLoading: PropTypes.bool,
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  category: PropTypes.object,
};

CreateCategoryModal.defaultProps = {
  isLoading: false,
  opened: false,
  onClose: () => {},
  onSubmit: () => {},
  category: null,
};
