import { Flex, Space, Stack } from '@mantine/core';
import { Skeleton } from 'components/Skeleton';
import PropTypes from 'prop-types';

export default function PermissionsSkeleton({ isVisible }) {
  if (!isVisible) return null;
  return (
    <Flex gap={10} w="100%">
      <Skeleton
        count={4}
        skeletonProps={{ h: 49, w: 150 }}
        skeletonWrapperProps={{ gap: 5 }}
        isVisible
      />
      <Stack gap={0} w="100%">
        <Flex gap="md" justify="flex-end" align="center">
          <Skeleton count={1} isVisible skeletonProps={{ w: 159, h: 34 }} />
        </Flex>
        <Space h="md" />
        <Skeleton
          count={10}
          isVisible
          skeletonProps={{ h: 35 }}
          skeletonWrapperProps={{ gap: 5 }}
        />
      </Stack>
    </Flex>
  );
}

PermissionsSkeleton.propTypes = {
  isVisible: PropTypes.bool.isRequired,
};
