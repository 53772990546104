import { Button, Flex, Space, Stack, Table } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconCopy } from '@tabler/icons-react';
import { clearCache } from 'api/clearCache';
import {
  useCopyHolidays,
  useCreateHoliday,
  useDeleteHoliday,
  useLoadHolidays,
  useUpdateHoliday,
} from 'api/hooks';
import { AddEditHolidayModal } from 'components/AddEditHolidayModal';
import { AddButton } from 'components/Buttons';
import { ConfirmDeleteModal } from 'components/ConfirmDeleteModal';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import {
  employeeTypes,
  filterOnlySelectedValues,
} from 'components/PerformanceManagement/forms/utils';
import { Skeleton } from 'components/Skeleton';
import { SortTh } from 'components/SortTh';
import { sortTypes } from 'components/SortTh/SortTh';
import { TapYearPicker } from 'components/TapYearPicker';
import _ from 'lodash';
import moment from 'moment';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useStateDisclosure } from 'utils/hooks';
import { HolidayRow } from './components';

export default function HolidaysTab() {
  const { setNotifications } = useNotifications();
  const queryClient = useQueryClient();

  const [
    openedHolidayModal,
    { close: closeHolidayModal, open: openHolidayModal, state: holidayState },
  ] = useStateDisclosure(false);

  const [viewHolidayYear, setViewHolidayYear] = useState(
    +moment().format('YYYY'),
  );

  const [sort, setSort] = useState({
    field: 'date_from',
    sortBy: sortTypes.desc,
  });

  const {
    holidays,
    canCopyHolidays,
    isLoading: isHolidaysLoading,
    isFetching: isHolidaysFetching,
  } = useLoadHolidays({
    filter: {
      byYear: viewHolidayYear,
    },
    sortBy: sort.sortBy
      ? `${sort.sortBy === sortTypes.asc ? '' : '-'}${sort.field}`
      : undefined,
  });

  const [
    openedDeleteModal,
    { open: openDeleteModal, close: closeDeleteModal, state: deleteModalState },
  ] = useStateDisclosure(false);

  const [
    openedConfirmationCopyModal,
    { open: openConfirmationCopyModal, close: closeConfirmationCopyModal },
  ] = useDisclosure(false);

  const { deleteHoliday, isLoading: isDeleting } = useDeleteHoliday({
    onSuccess: () => {
      setNotifications(['Holiday Deleted Successfully']);
      closeDeleteModal();
      clearCache.onChangeHolidays(queryClient);
    },
  });

  const { createHoliday, isLoading: isCreatingHoliday } = useCreateHoliday({
    onSuccess: () => {
      setNotifications(['Holiday Created Successfully']);
      clearCache.onChangeHolidays(queryClient);
      closeHolidayModal();
    },
  });

  const { updateHoliday, isLoading: isUpdatingHoliday } = useUpdateHoliday({
    onSuccess: () => {
      setNotifications(['Holiday Updated Successfully']);
      clearCache.onChangeHolidays(queryClient);
      closeHolidayModal();
    },
  });

  const onMutateHoliday = (callback) => (data) => {
    const { startDate, endDate, filters, employeeType, ...rest } = data;

    const formattedData = {
      date_from: moment(startDate).format('YYYY-MM-DD'),
      date_to: moment(endDate).format('YYYY-MM-DD'),
      filters:
        employeeType === employeeTypes.someEmployee
          ? filterOnlySelectedValues(filters)
          : {},
      ...rest,
    };

    callback(formattedData);
  };

  const { copyHolidays, isLoading: isCopying } = useCopyHolidays({
    onSuccess: () => {
      closeConfirmationCopyModal();
      setNotifications(['Holiday Copied Successfully']);
      clearCache.onChangeHolidays(queryClient);
    },
  });

  return (
    <>
      <Space h="md" />
      <Flex justify="flex-end" align="center" gap={10}>
        <TapYearPicker
          onNextYear={() => setViewHolidayYear((prev) => prev + 1)}
          onPrevYear={() => setViewHolidayYear((prev) => prev - 1)}
          year={viewHolidayYear}
        />
        <AddButton onClick={openHolidayModal}>Create Holiday</AddButton>
      </Flex>
      <AddEditHolidayModal
        opened={openedHolidayModal}
        onClose={closeHolidayModal}
        onCreate={onMutateHoliday(createHoliday)}
        onEdit={onMutateHoliday((data) =>
          updateHoliday({ holidayId: holidayState?.id, ...data }),
        )}
        isLoading={isCreatingHoliday || isUpdatingHoliday}
        holidayState={holidayState}
      />
      <ConfirmDeleteModal
        isLoading={isDeleting}
        onDelete={() => deleteHoliday(deleteModalState?.id)}
        opened={openedDeleteModal}
        onClose={closeDeleteModal}
        deleteItemName={deleteModalState?.name ?? ''}
      />
      <ConfirmationModal
        opened={openedConfirmationCopyModal}
        onClose={closeConfirmationCopyModal}
        onConfirm={() => copyHolidays({ year: viewHolidayYear })}
        title="Confirmation"
        isLoading={isCopying}
        text={`Are you sure you want to copy holidays from ${
          viewHolidayYear - 1
        }`}
      />
      <Stack align="center">
        <EmptyDataMessage
          mt={30}
          isVisible={_.isEmpty(holidays) && !isHolidaysLoading}
        />
        {canCopyHolidays && (
          <Button
            w="max-content"
            disabled={isHolidaysLoading || isCopying || isHolidaysFetching}
            rightSection={<IconCopy />}
            onClick={openConfirmationCopyModal}
          >
            Copy Holidays from {viewHolidayYear - 1}
          </Button>
        )}
      </Stack>
      <Skeleton
        skeletonWrapperProps={{ mt: 10 }}
        count={5}
        isVisible={isHolidaysLoading}
      />
      {!_.isEmpty(holidays) && !isHolidaysLoading && (
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Holiday</Table.Th>
              <SortTh
                label="Date"
                field="date_from"
                sorted={sort}
                setSorted={setSort}
              />
              <Table.Th>For</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {_.map(holidays, (holiday) => (
              <HolidayRow
                key={holiday?.id}
                data={holiday}
                onDelete={openDeleteModal}
                onEdit={(data) =>
                  openHolidayModal({ ...data, modalType: 'Edit' })
                }
              />
            ))}
          </Table.Tbody>
        </Table>
      )}
    </>
  );
}
