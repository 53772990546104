/* eslint-disable react/no-danger */
import {
  Accordion,
  AccordionItem,
  Avatar,
  Box,
  Flex,
  Text,
  rem,
} from '@mantine/core';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useGetDateAgo } from 'utils/hooks';
import styles from './styles.module.css';

export default function ReceivedEmail({
  receivedEmail,
  candidateDetails,
  opened,
}) {
  const text = useMemo(() => {
    const parser = new window.DOMParser();
    const doc = parser.parseFromString(
      receivedEmail?.message,
      'text/html',
    ).body;

    return doc.textContent ?? '';
  }, [receivedEmail?.message]);

  const { formattedMonths, formattedDays, months } = useGetDateAgo({
    date: receivedEmail?.created_at,
  });

  return (
    <AccordionItem value={`${receivedEmail.id}`}>
      <Accordion.Control className={styles.accordionItem}>
        <Flex gap={rem(10)}>
          <Avatar src={receivedEmail?.from?.employee_avatar} size={50} />
          <Box>
            <Item
              label="From"
              text={receivedEmail?.from?.employee_full_name}
              additionalInfo={
                <Text display="inline-block" c="var(--mantine-color-gray-6)">
                  {moment(receivedEmail?.created_at).format('hh:mm A')}
                </Text>
              }
            />
            <Item
              label="To"
              text={`${candidateDetails?.first_name} ${candidateDetails?.last_name}`}
            />
            <Item label="Subject" text={receivedEmail?.subject} />
            <Item
              label="Status"
              text={`Sent ${months ? formattedMonths : formattedDays}`}
            />
            <Text lineClamp={1} c="var(--mantine-color-gray-6)">
              {!opened && text}
            </Text>
          </Box>
        </Flex>
      </Accordion.Control>
      <Accordion.Panel ml={rem(60)}>
        <div dangerouslySetInnerHTML={{ __html: receivedEmail?.message }} />
      </Accordion.Panel>
    </AccordionItem>
  );
}

ReceivedEmail.propTypes = {
  receivedEmail: PropTypes.object.isRequired,
  candidateDetails: PropTypes.object.isRequired,
  opened: PropTypes.bool,
};

ReceivedEmail.defaultProps = {
  opened: false,
};

function Item({ label, text, additionalInfo }) {
  return (
    <Box>
      <Text display="inline-block" c="var(--mantine-color-gray-6)">
        {label}:
      </Text>{' '}
      <Box display="inline-block">
        {text} {additionalInfo}
      </Box>
    </Box>
  );
}

Item.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  additionalInfo: PropTypes.node,
};

Item.defaultProps = {
  additionalInfo: null,
};
