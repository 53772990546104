import { Avatar, Flex, Stack, Text } from '@mantine/core';
import { IconConfetti } from '@tabler/icons-react';
import { useLoadDashboardInfo } from 'api/hooks';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Time, celebrationTypes } from 'utils/constants';
import classes from '../styles.module.css';
import WidgetWrapper from './WidgetWrapper';

export default function Celebrations() {
  const { dashboardInfo, isLoading: isDashboardInfoLoading } =
    useLoadDashboardInfo({ staleTime: Time.WidgetStaleQueryTime });

  const events = dashboardInfo?.events ?? {};

  return (
    <WidgetWrapper
      title="Celebrations"
      Icon={<IconConfetti size={30} color="white" />}
      isLoading={isDashboardInfoLoading}
    >
      <Stack gap={0} flex={1}>
        {_.isEmpty(events?.today) &&
          _.isEmpty(events?.tomorrow) &&
          !isDashboardInfoLoading && (
            <Text m="auto" fw={600} fz="xl" c="var(--mantine-color-gray-6)">
              Nobody has celebrations
            </Text>
          )}
        <Stack gap={0} className={classes.card__content}>
          <CelebrationList label="Today" events={events?.today ?? []} />
          <CelebrationList label="Tomorrow" events={events?.tomorrow ?? []} />
        </Stack>
      </Stack>
    </WidgetWrapper>
  );
}

function CelebrationList({ events, label }) {
  if (_.isEmpty(events)) {
    return null;
  }

  return (
    <Stack gap={0}>
      <Text fz="xl" fw={500} c="var(--mantine-color-gray-6)">
        {label}
      </Text>
      <Stack gap={5}>
        {_.map(events, (event) => (
          <Flex
            key={`${event?.id} ${event?.type}`}
            align="center"
            gap={10}
            justify="space-between"
          >
            <Flex gap={10} align="center">
              <Avatar src={event?.avatar_filename} size={50} />
              <Stack gap={0} align="space-between">
                <Text>{event?.name}</Text>
                <Text c="var(--mantine-color-gray-6)">
                  {moment(event?.date).format('MMMM DD')} -{' '}
                  {celebrationTypes[event?.type] ?? event.type}
                </Text>
              </Stack>
            </Flex>
          </Flex>
        ))}
      </Stack>
    </Stack>
  );
}

CelebrationList.propTypes = {
  events: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
};
