import {
  Button,
  Flex,
  LoadingOverlay,
  Space,
  Text,
  TextInput,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { mockData } from 'api/mockData';
import { AssetRequestModal, RequestRow } from 'components/Requests';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { Fragment } from 'react';
import { useStateDisclosure } from 'utils/hooks';

export default function AssetsTab() {
  const { setNotifications } = useNotifications();

  const requests = mockData.requests.assets;

  const [
    modalOpened,
    { open: openModal, close: closeModal, state: openedRequest },
  ] = useStateDisclosure(false);
  const onRequestClick = (request) => {
    openModal(request);
  };

  const onRequestApprove = (request) => {
    setNotifications(`Request ${request.title} has been approved successfully`);
  };

  const onRequestDenied = (request) => {
    closeModal();
    modals.open({
      title: `Are you sure you want to deny request: ${request?.title}`,
      centered: true,
      children: (
        <>
          <TextInput label="Comment (optional)" placeholder="Comment..." />
          <Flex align="center" justify="center" gap="md">
            <Button
              color="red"
              onClick={() => {
                modals.closeAll();
                setTimeout(() => {
                  setNotifications(
                    `Request ${request.title} has been denied successfully`,
                  );
                }, 500);
              }}
              mt="md"
            >
              Confirm
            </Button>
            <Button
              variant="outline"
              onClick={() => {
                modals.closeAll();
              }}
              mt="md"
            >
              Cancel
            </Button>
          </Flex>
        </>
      ),
    });
  };
  return (
    <>
      <h2>Assets</h2>
      <LoadingOverlay
        visible={false}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <AssetRequestModal
        opened={modalOpened}
        onClose={() => closeModal()}
        request={openedRequest}
        isLoading={false}
        onApprove={onRequestApprove}
        onDeny={onRequestDenied}
      />
      {requests?.map((request) => (
        <Fragment key={request.id}>
          <Space h="md" />
          <RequestRow
            key={request.id}
            request={request}
            onClick={onRequestClick}
          />
        </Fragment>
      ))}
      {requests?.length === 0 && (
        <Flex align="center" justify="center">
          <Text fw={500} size="xl" c="dimmed">
            There are no records
          </Text>
        </Flex>
      )}
    </>
  );
}
