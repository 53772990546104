import { Table } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { ContextMenu } from 'components/ContextMenu';
import PropTypes from 'prop-types';

export default function AssetCategoryRow({ onEdit, onDelete, category }) {
  return (
    <Table.Tr>
      <Table.Td>{category?.name}</Table.Td>
      <Table.Td>{category?.employee_count}</Table.Td>
      <Table.Td ta="right">
        <ContextMenu
          menuItems={[
            {
              label: 'Edit',
              icon: IconEdit,
              onClick: () => {
                onEdit(category);
              },
            },
            {
              label: 'Delete',
              icon: IconTrash,
              onClick: () => {
                onDelete(category);
              },
              condition: !category?.employee_count,
            },
          ]}
        />
      </Table.Td>
    </Table.Tr>
  );
}

AssetCategoryRow.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  category: PropTypes.object.isRequired,
};
