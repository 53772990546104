import { TextInput, Button, List, Select, Paper } from '@mantine/core';
import { genericValidators } from 'utils';
import { useForm } from '@mantine/form';
import PropTypes from 'prop-types';
import { questionTypes } from 'utils/constants';

export default function SurveyQuestionForm({ question, onSubmit }) {
  const form = useForm({
    initialValues: {
      id: question?.id ?? undefined,
      title: question?.title ?? '',
      field_type: question?.field_type ?? 'text',
    },
    validate: {
      title: (value) =>
        genericValidators.notEmpty(value, 'Question is empty') ||
        genericValidators.lessThanOrEqualTo(
          value,
          255,
          'Question must be less than 255 chars',
        ),
    },
  });
  return (
    <form onSubmit={form.onSubmit(onSubmit)} noValidate>
      <TextInput
        label="Question"
        placeholder="Question"
        onChange={(event) =>
          form.setFieldValue('title', event.currentTarget.value)
        }
        value={form.values.title}
        error={form.errors.title}
        required
      />
      <Select
        label="Type"
        placeholder="Pick Type"
        allowDeselect={false}
        data={questionTypes}
        onChange={(val) => form.setFieldValue('field_type', val)}
        value={form.values.field_type}
      />

      {form.values.field_type === 'select' && (
        <Paper shadow="xs" withBorder p="md" mt="md" c="dimmed">
          <List type="ordered" size="xs">
            <List.Item>Strongly Disagree</List.Item>
            <List.Item>Disagree</List.Item>
            <List.Item>Neutral</List.Item>
            <List.Item>Agree</List.Item>
            <List.Item>Strongly Agree</List.Item>
          </List>
        </Paper>
      )}
      <Button type="submit" fullWidth mt="xl">
        {question ? 'Update Question' : 'Add Question'}
      </Button>
    </form>
  );
}

SurveyQuestionForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  question: PropTypes.object,
};
SurveyQuestionForm.defaultProps = {
  question: null,
};
