import {
  Button,
  Container,
  Flex,
  Loader,
  Radio,
  Select,
  Table,
  Text,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconCheck } from '@tabler/icons-react';
import { clearCache } from 'api/clearCache';
import { useCompleteSurveyRequest, useSurvey } from 'api/hooks';
import { useAuthContext } from 'auth';
import { PeerFeedbackEmployeeList } from 'components/PeerFeedbackEmployeeList';
import { usePermissions } from 'components/PermissionGuard/hooks';
import { UserMainInfoPreviewCard } from 'components/UserMainInfoPreviewCard';
import _ from 'lodash';
import { BackButton } from 'modules/requests/components';
import { usePreloadedRequest } from 'modules/requests/hooks/usePreloadedRequest';
import { useNotifications } from 'notifications/hooks/useNotifications';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import {
  formatSelectorDataObj,
  genericValidators,
  getUserFullName,
} from 'utils';
import {
  requestPerformanceTypes,
  requestStatuses,
  surveys,
  surveysSelectResponses,
} from 'utils/constants';
import { usePageTitle } from 'utils/hooks';

const errorsExample = {
  select: 'select variant',
  options: 'select option',
  text: 'enter data',
};

export default function SurveyPreview({ requestId }) {
  const { permissions, hasPermission } = usePermissions();

  const queryClient = useQueryClient();
  const { setNotifications } = useNotifications();

  const { user } = useAuthContext();

  const {
    request,
    setIsDataPreloaded,
    isLoading: isRequestLoading,
    isFetching: isRequestFetching,
  } = usePreloadedRequest(requestId);

  const form = useForm({
    initialValues: {
      questions: request?.response_data ?? [],
    },
    validate: {
      questions: (val) => {
        const err = val.map(({ response = '', field_type: fieldType }) =>
          genericValidators.notEmpty(
            response,
            `Please ${errorsExample?.[fieldType] ?? 'enter data'}`,
          ),
        );

        return _.isEmpty(err.filter(Boolean)) ? null : err;
      },
    },
  });

  const surveyId = useMemo(() => {
    const survey = request?.data;

    return survey ? survey?.id : null;
  }, [request]);

  const {
    survey: surveyData,
    isLoading,
    isFetched,
  } = useSurvey(
    {
      id: surveyId,
      canManageEmployee: hasPermission(permissions.canManageEmployee),
    },
    {
      enabled: Boolean(surveyId),
      onSuccess: ({ questions }) => {
        form.initialize({ questions: request?.response_data || questions });
      },
    },
  );

  const { completeSurveyRequest, isLoading: isCompleting } =
    useCompleteSurveyRequest({
      onSuccess: () => {
        setIsDataPreloaded(false);
        clearCache.onSurveyComplete(queryClient, { requestId });
        setNotifications(['Survey completed successfully']);
      },
    });

  const hasQuestions = surveyData?.questions?.length > 0;

  const onSubmit = ({ questions }) => {
    completeSurveyRequest({ requestId, response_data: questions ?? [] });
  };

  usePageTitle(`Survey - ${getUserFullName(user)} - Requests`, '/requests');

  if (
    request?.data?.type === +surveys.peerFeedback.value &&
    request?.type === requestPerformanceTypes.choice
  ) {
    return (
      <PeerFeedbackEmployeeList
        employeeId={request?.assignee_id}
        request={request}
      />
    );
  }

  return (
    <Container>
      <Flex gap={10} justify="space-between">
        <Flex align="center" mb="md">
          <Text fw={700} size="xl">
            {surveyData?.name}
          </Text>
          {!isLoading &&
            !isRequestLoading &&
            request?.status === requestStatuses.Completed && (
              <IconCheck color="green" />
            )}
        </Flex>
        <BackButton />
      </Flex>

      {request?.destination_to && (
        <UserMainInfoPreviewCard userInfo={request?.destination_to} />
      )}
      <form onSubmit={form.onSubmit(onSubmit)} noValidate>
        {hasQuestions && (
          <>
            <Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Question</Table.Th>
                  <Table.Th>Response</Table.Th>
                </Table.Tr>
              </Table.Thead>

              <Table.Tbody>
                {surveyData?.questions?.map((item, index) => (
                  <Table.Tr key={item.title}>
                    <Table.Td>{item.title}</Table.Td>
                    <Table.Td>
                      {item?.field_type === 'text' && (
                        <Textarea
                          placeholder="Text Response"
                          {...form.getInputProps(`questions.${index}.response`)}
                          error={form.errors.questions?.[index]}
                          disabled={
                            request?.status === requestStatuses.Completed
                          }
                        />
                      )}
                      {item?.field_type === 'select' && (
                        <Select
                          data={formatSelectorDataObj(
                            surveysSelectResponses,
                            true,
                          )}
                          placeholder="Select"
                          allowDeselect={false}
                          {...form.getInputProps(`questions.${index}.response`)}
                          error={form.errors.questions?.[index]}
                          disabled={
                            request?.status === requestStatuses.Completed
                          }
                        />
                      )}
                      {item?.field_type === 'options' && (
                        <Radio.Group
                          {...form.getInputProps(`questions.${index}.response`)}
                          error={form.errors.questions?.[index]}
                        >
                          <Flex gap={15}>
                            <Radio
                              value="no"
                              label="No"
                              disabled={
                                request?.status === requestStatuses.Completed
                              }
                            />
                            <Radio
                              value="yes"
                              label="Yes"
                              disabled={
                                request?.status === requestStatuses.Completed
                              }
                            />
                          </Flex>
                        </Radio.Group>
                      )}
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
            <Flex justify="end" mt="10">
              <Button
                disabled={
                  isCompleting ||
                  isRequestFetching ||
                  request?.status === requestStatuses.Completed
                }
                type="submit"
              >
                Submit
              </Button>
            </Flex>
          </>
        )}
      </form>
      {!hasQuestions && isFetched && (
        <Flex justify="center" align="center" p="md">
          <Text fw={500} size="xl" c="dimmed">
            There are no questions added
          </Text>
        </Flex>
      )}
      {(isLoading || isRequestLoading) && (
        <Flex align="center" p="xl" justify="center">
          <Loader color="blue" size="xl" />
        </Flex>
      )}
    </Container>
  );
}

SurveyPreview.propTypes = {
  requestId: PropTypes.string.isRequired,
};
