import { Flex } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import React from 'react';
import { useRequestBack } from '../hooks/usePreloadedRequest';

export default function BackButton() {
  const goBack = useRequestBack();

  return (
    <Flex
      style={{ cursor: 'pointer' }}
      align="center"
      gap={10}
      onClick={goBack}
    >
      <IconArrowLeft />
      Back
    </Flex>
  );
}
