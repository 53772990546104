import _ from 'lodash';
import { useCallback } from 'react';

export default function useDragAndDrop() {
  const sliceWidgetColumnPosition = ({
    toIndex,
    fromItem,
    state,
    toColumn,
  }) => {
    const grouped = _.groupBy(state, 'column');

    const currentColumn = grouped[toColumn] ?? [];

    const restColumns = _.omit(grouped, toColumn);

    currentColumn.splice(toIndex, 0, fromItem);

    const filteredData = _.flatMap(Object.values(restColumns)).filter(
      (val) => val?.id !== fromItem?.id,
    );

    return [...currentColumn, ...filteredData];
  };

  const sliceRowsPosition = ({
    toIndex,
    fromItem,
    toColumn,
    groupedData,
    fromColumn,
  }) => {
    const currentColumn = groupedData[toColumn] ?? { links: [] };

    const restColumns = _.omit(groupedData, toColumn);

    const newFromLinks = restColumns[fromColumn]?.links?.filter(
      (item) => item?.id !== fromItem?.id,
    );
    restColumns[fromColumn] = {
      ...restColumns[fromColumn],
      links: newFromLinks,
    };

    currentColumn?.links?.splice(toIndex, 0, fromItem);

    return { ...restColumns, [toColumn]: currentColumn };
  };

  const sliceRowPositionInSameCategory = ({
    toIndex,
    fromIndex,
    fromItem,
    toColumn,
    groupedData,
  }) => {
    const currentColumn = groupedData[toColumn] ?? { links: [] };

    const restColumns = _.omit(groupedData, toColumn);

    currentColumn?.links?.splice(fromIndex, 1);
    currentColumn?.links?.splice(toIndex, 0, fromItem);

    return { ...restColumns, [toColumn]: currentColumn };
  };

  const applyAllColumns = useCallback(
    (groupedWidgets, numberOfColumns) =>
      _.range(1, numberOfColumns + 1).reduce(
        (widgetBuff, colNumber) =>
          colNumber in widgetBuff
            ? widgetBuff
            : { ...widgetBuff, [colNumber]: [] },
        groupedWidgets,
      ),
    [],
  );

  return {
    sliceWidgetColumnPosition,
    applyAllColumns,
    sliceRowsPosition,
    sliceRowPositionInSameCategory,
  };
}
