import { Button, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import PropTypes from 'prop-types';
import { genericValidators } from 'utils';

export default function AddBonusReasonForm({
  onSubmit,
  reasonState,
  isLoading,
}) {
  const form = useForm({
    initialValues: {
      name: reasonState?.name ?? '',
    },
    validate: {
      name: (val) => genericValidators.notEmpty(val),
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)} noValidate>
      <Stack gap={10}>
        <TextInput {...form.getInputProps('name')} label="Reason" />
        <Button disabled={isLoading} ml="auto" type="submit">
          Submit
        </Button>
      </Stack>
    </form>
  );
}

AddBonusReasonForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  reasonState: PropTypes.object,
  isLoading: PropTypes.bool,
};

AddBonusReasonForm.defaultProps = {
  reasonState: {},
  isLoading: false,
};
