import { useLoadRequest } from 'api/hooks';
import { usePermissions } from 'components/PermissionGuard/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const usePreloadedRequest = (requestId) => {
  const { state } = useLocation();
  const [isDataPreloaded, setIsDataPreloaded] = useState(
    Boolean(state?.preloadedRequest),
  );
  const { permissions, hasPermission } = usePermissions();

  const {
    request: loadedRequest,
    isLoading,
    isFetching,
  } = useLoadRequest(
    {
      id: requestId,
      canManageEmployee: hasPermission(permissions.canManageEmployee),
    },
    {
      enabled: Boolean(requestId) && !isDataPreloaded,
    },
  );

  const request = useMemo(
    () =>
      isDataPreloaded && state?.preloadedRequest
        ? state?.preloadedRequest
        : loadedRequest,
    [loadedRequest, state?.preloadedRequest, isDataPreloaded],
  );

  useEffect(() => {
    // clear state for this url
    window.history.replaceState({}, '');
  }, []);

  return { request, isLoading, isFetching, setIsDataPreloaded };
};

export const useRequestBack = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const goBack = useCallback(() => {
    const [, page, tab] = pathname.split('/');
    navigate(`/${page}/${tab}`);
  }, [pathname, navigate]);

  return goBack;
};
