import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { Flex, Modal } from '@mantine/core';
import { useListState, useToggle } from '@mantine/hooks';
import { clearCache } from 'api/clearCache';
import {
  useLoadCompanyLinksCategories,
  useUpdateCategoriesOrder,
} from 'api/hooks';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import styles from './styles.module.css';

export default function CompanyCategoriesModal({ opened, onClose }) {
  const queryClient = useQueryClient();
  const { companyLinksCategories } = useLoadCompanyLinksCategories({
    enabled: opened,
    select: (data) => data?.data?.sort((a, b) => a.order - b.order),
  });

  const [state, { setState, reorder }] = useListState(companyLinksCategories);

  const { updateCategoriesOrder } = useUpdateCategoriesOrder({
    onSuccess: () => {
      clearCache.onChangeLinkCategory(queryClient);
    },
  });

  const onReorder = useCallback(
    (data) => {
      updateCategoriesOrder({ categories: _.map(data, 'id') });
    },
    [updateCategoriesOrder],
  );

  const [listStatus, toggleListStatus] = useToggle([
    'initializing',
    'reorder',
    'hold',
  ]);

  useEffect(() => {
    if (!_.isEmpty(companyLinksCategories)) {
      setState(companyLinksCategories);
    }
  }, [companyLinksCategories, setState]);

  const reorderList = useCallback(
    (from, to) => {
      if (!_.isNil(from) && !_.isNil(to) && !_.isEqual(from, to)) {
        toggleListStatus('reorder');
        reorder({ from, to });
      } else {
        toggleListStatus('hold');
      }
    },
    [reorder, toggleListStatus],
  );

  useEffect(() => {
    if (listStatus === 'reorder') {
      onReorder(state);
      toggleListStatus('hold');
    }
  }, [state, onReorder, listStatus, toggleListStatus]);

  return (
    <Modal
      title="Reorder Lists"
      centered
      opened={opened}
      onClose={onClose}
      // With transform not working dnd
      classNames={{ content: styles.removeTransform }}
    >
      <DragDropContext
        onDragEnd={({ destination, source }) =>
          reorderList(source?.index, destination?.index)
        }
      >
        <Droppable droppableId="dnd-list" direction="vertical">
          {(providedDroppable) => (
            <div
              {...providedDroppable.droppableProps}
              ref={providedDroppable.innerRef}
            >
              {state.map((item, index) => (
                <Draggable
                  key={item.id}
                  index={index}
                  draggableId={String(item.id)}
                >
                  {(provided) => (
                    <div
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      <Flex
                        style={{
                          border: '1px dashed var(--mantine-color-gray-4)',
                        }}
                        p={5}
                        fw={450}
                      >
                        {item.name}
                      </Flex>
                    </div>
                  )}
                </Draggable>
              ))}
              {providedDroppable.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Modal>
  );
}

CompanyCategoriesModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
