import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { getTokenData } from 'storage';
// eslint-disable-next-line no-undef
window.Pusher = Pusher;
class WS {
  constructor() {
    this.instance = null;
  }

  get ws() {
    return this.instance;
  }

  removeConnection() {
    this.instance = null;
  }

  boot(token) {
    // allow only one boot
    if (token === this.token && token !== undefined) return this.instance;

    // getTokenData for react cache, if you don't do this, it creates instance as null after file saving
    this.token = token ?? getTokenData()?.access_token;

    this.instance = new Echo({
      broadcaster: 'pusher',
      key: process.env.REACT_APP_WS_KEY,
      cluster: process.env.REACT_APP_WS_CLUSTER,
      wsHost: process.env.REACT_APP_WS_HOST,
      wsPort: process.env.REACT_APP_WS_PORT,
      wssPort: process.env.REACT_APP_WS_PORT,
      disableStatus: true,
      enableTransports: ['ws', 'wss'],
      forceTLS: false,
      authEndpoint: `${process.env.REACT_APP_BASE_URL}api/broadcasting/auth`,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      },
    });
    return this.instance;
  }
}

const echo = new WS();

export default echo;
