import { clearCache } from 'api/clearCache';
import {
  useCreateEmployeeStatusRecord,
  useLoadEmployeeStatuses,
  useUpdateEmployeeStatusRecord,
} from 'api/hooks';
import { usePermissions } from 'components/PermissionGuard/hooks';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { employeeReasons } from 'utils/constants';
import { useStateDisclosure } from 'utils/hooks';

export default function useChangeEmployeeStatusModal({ employeeId }) {
  const [opened, { open, close, state }] = useStateDisclosure(false);

  const { hasPermission, permissions } = usePermissions();

  const { setNotifications } = useNotifications();
  const { statuses, isLoading: isStatusesLoading } = useLoadEmployeeStatuses({
    enabled: hasPermission(permissions.canManageEmployee),
  });

  const queryClient = useQueryClient();

  const { updateEmployeeStatusRecord, isLoading: isUpdatingEmployeeRecord } =
    useUpdateEmployeeStatusRecord({
      onSuccess: () => {
        setNotifications(['Employee Status Updated Successfully']);
        clearCache.onChangeEmployeeStatusRecord(queryClient, {
          employeeId,
          type: employeeReasons.termination,
        });
        close();
      },
    });

  const { createEmployeeStatusRecord, isLoading: isEmployeeStatusCreating } =
    useCreateEmployeeStatusRecord({
      onSuccess: () => {
        setNotifications(['Employee Status Created Successfully']);
        clearCache.onChangeEmployeeStatusRecord(queryClient, {
          employeeId,
          type: employeeReasons.termination,
        });
        close();
      },
    });

  const applyTerminationData = useCallback(
    (
      payload,
      {
        isTerminationTypeSelected,
        isResignationVoluntary,
        terminationType,
        terminationRegrettable,
        reasonId,
      },
    ) => {
      if (isTerminationTypeSelected) {
        // eslint-disable-next-line no-param-reassign
        payload.termination_data = {
          termination_type: terminationType,
        };
        // eslint-disable-next-line no-param-reassign
        payload.reason_id = reasonId;

        if (isResignationVoluntary) {
          // eslint-disable-next-line no-param-reassign
          payload.termination_data.termination_regrettable =
            terminationRegrettable;
        }
      }
    },
    [],
  );

  const onUpdateEmployeeStatus = useCallback(
    ({
      termination_type: terminationType,
      termination_regrettable: terminationRegrettable,
      isTerminationTypeSelected,
      isResignationVoluntary,
      reason_id: reasonId,
      ...data
    }) => {
      const payload = {
        ...data,
        employee_id: employeeId,
        recordId: state?.id,
      };

      applyTerminationData(payload, {
        isTerminationTypeSelected,
        isResignationVoluntary,
        terminationType,
        terminationRegrettable,
        reasonId,
      });

      updateEmployeeStatusRecord(payload);
    },
    [employeeId, state?.id, updateEmployeeStatusRecord, applyTerminationData],
  );

  const onCreateEmployeeStatus = useCallback(
    ({
      termination_type: terminationType,
      termination_regrettable: terminationRegrettable,
      isTerminationTypeSelected,
      isResignationVoluntary,
      reason_id: reasonId,
      ...data
    }) => {
      const payload = {
        ...data,
        employee_id: employeeId,
      };

      applyTerminationData(payload, {
        terminationType,
        terminationRegrettable,
        isResignationVoluntary,
        isTerminationTypeSelected,
        reasonId,
      });

      createEmployeeStatusRecord(payload);
    },
    [employeeId, createEmployeeStatusRecord, applyTerminationData],
  );

  const getModalProps = useCallback(
    () => ({
      onClose: close,
      opened,
      status: state,
      onEdit: onUpdateEmployeeStatus,
      onCreate: onCreateEmployeeStatus,
      isLoading: isUpdatingEmployeeRecord || isEmployeeStatusCreating,
      isStatusesLoading,
      statuses,
    }),
    [
      close,
      opened,
      state,
      onUpdateEmployeeStatus,
      onCreateEmployeeStatus,
      isEmployeeStatusCreating,
      isUpdatingEmployeeRecord,
      isStatusesLoading,
      statuses,
    ],
  );

  return {
    openChangeEmployeeStatusModal: open,
    openedChangeEmployeeStatusModal: opened,
    changeEmployeeStatusModalState: state,
    closeChangeEmployeeStatusModal: close,
    statuses,
    isStatusesLoading,
    getModalProps,
  };
}
