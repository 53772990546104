import {
  Paper,
  Title,
  Text,
  TextInput,
  Button,
  Container,
  Group,
  Center,
  Box,
  rem,
} from '@mantine/core';
import { IconArrowLeft, IconAt } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { useForm } from '@mantine/form';
import { combineValidators, formValidators, genericValidators } from 'utils';
import { useDisclosure } from '@mantine/hooks';

import { useSendResetPasswordLink } from 'api/hooks';
import { useSetPageTitle } from 'utils/hooks';
import { useNotifications } from 'notifications/hooks/useNotifications';
import classes from './ForgotPassword.module.css';

export default function ForgotPassword() {
  const { setNotifications } = useNotifications();

  const [opened, { open, close }] = useDisclosure(false);

  const form = useForm({
    initialValues: {
      email: '',
    },
    validate: {
      email: (val) => {
        const errors = combineValidators(
          formValidators.email,
          genericValidators.notEmpty,
        )(val);
        return errors;
      },
    },
  });

  const { sendPasswordResetLink, isLoading: isSending } =
    useSendResetPasswordLink({
      onSuccess: () => {
        setNotifications(['The link was sent to the email']);
        open();
      },
    });

  const messageStyles = {
    maxHeight: opened ? '500px' : '0px',
    overflow: 'hidden',
    transition: 'max-height 0.25s ease, padding 0.15s ease',
  };

  useSetPageTitle('Forgot');

  return (
    <Container size={460} my={30}>
      <Title className={classes.title} ta="center">
        Forgot your password?
      </Title>
      <Text c="dimmed" fz="sm" ta="center">
        Enter your email to get a reset link
      </Text>

      <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
        <form onSubmit={form.onSubmit(sendPasswordResetLink)} noValidate>
          <TextInput
            label="Your email"
            type="email"
            placeholder="me@mantine.dev"
            required
            leftSection={<IconAt size={16} />}
            {...form.getInputProps('email')}
            onChange={(event) => {
              form.setFieldValue('email', event.currentTarget.value);
              close();
            }}
          />
          <Box
            c="white"
            mt="md"
            bg="var(--mantine-color-blue-5)"
            style={{
              borderRadius: '10px',
              ...messageStyles,
              transition: 'max-height 0.35s ease',
            }}
          >
            <Box p="md" style={{ ...messageStyles }}>
              We have sent you instructions on how to reset your password to{' '}
              <Text display="inline-block" fw="bold">
                {form.values.email}.
              </Text>{' '}
              Please wait a few minutes and check your email, including your
              spam folders if necessary.
            </Box>
          </Box>
          <Group justify="space-between" mt="lg" className={classes.controls}>
            <Link
              style={{ color: 'var(--mantine-color-blue-6)' }}
              to="/login"
              c="dimmed"
              size="sm"
              className={classes.control}
            >
              <Center inline>
                <IconArrowLeft
                  style={{ width: rem(12), height: rem(12) }}
                  stroke={1.5}
                />
                <Box ml={5}>Back to the login page</Box>
              </Center>
            </Link>
            <Button
              disabled={isSending}
              type="submit"
              className={classes.control}
            >
              Reset password
            </Button>
          </Group>
        </form>
      </Paper>
    </Container>
  );
}
