import { Button, Flex, Loader, Space, Table, Title } from '@mantine/core';
import { IconEdit, IconPlus, IconTrash } from '@tabler/icons-react';
import { clearCache } from 'api/clearCache';
import { useDeleteDepartment, useLoadDepartments } from 'api/hooks';
import { ContextMenu } from 'components/ContextMenu';
import { CreateEditDepartmentModal } from 'components/CreateEditDepartmentModal';
import { SettingsTableHead } from 'components/SettingsTable';
import _ from 'lodash';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { useQueryClient } from 'react-query';
import { useStateDisclosure } from 'utils/hooks';

const headerList = ['Department', 'People', ''];

export default function Department() {
  const { departments, isLoading } = useLoadDepartments();

  const { setNotifications } = useNotifications();
  const queryClient = useQueryClient();

  const { deleteDepartment } = useDeleteDepartment({
    onSuccess: () => {
      clearCache.onDepartmentChange(queryClient);
      setNotifications(['Department Deleted Successfully']);
    },
  });

  const [
    openedDepartment,
    { open: openDepartment, close: closeDepartment, state: stateDepartment },
  ] = useStateDisclosure(false);

  return (
    <>
      <CreateEditDepartmentModal
        opened={openedDepartment}
        onClose={closeDepartment}
        department={stateDepartment}
      />
      <Flex gap={10} justify="space-between" align="center">
        <Title fz="xl">Department</Title>
        <Button onClick={openDepartment} rightSection={<IconPlus size={14} />}>
          Add department
        </Button>
      </Flex>
      {isLoading && (
        <Flex justify="center">
          <Loader />
        </Flex>
      )}
      <Space h="xs" />
      <Table>
        {!_.isEmpty(departments) && (
          <SettingsTableHead headerList={headerList} />
        )}
        <Table.Tbody>
          {departments?.map((department) => {
            const menuItems = [
              {
                label: 'Edit',
                icon: IconEdit,
                onClick: () => {
                  openDepartment({ ...department, modalType: 'Edit' });
                },
              },
              {
                label: 'Delete',
                icon: IconTrash,
                onClick: () => {
                  deleteDepartment(department.id);
                },
                condition: !department.employees_count,
              },
            ];
            return (
              <Table.Tr key={department.id}>
                <Table.Td>{department.name}</Table.Td>
                <Table.Td>{department.employees_count ?? 0}</Table.Td>
                <Table.Td>
                  <Flex justify="end">
                    <ContextMenu menuItems={menuItems} />
                  </Flex>
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
    </>
  );
}
