import { Avatar, Flex, Paper, Stack, Text } from '@mantine/core';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import RequestStatus from '../components/RequestStatus';

export default function TimeOffRow({ request, onClick, displayStatus }) {
  const createdAt = request.created_at
    ? moment(request.created_at).format('DD/MM/YYYY, h:mm a')
    : '';

  const totalHours = request?.data?.timeoffDetails?.reduce(
    (prev, curr) => prev + +curr.hours,
    0,
  );

  const startDate = _.first(request?.data?.timeoffDetails)?.date;
  const endData = _.last(request?.data?.timeoffDetails)?.date;

  return (
    <Paper
      shadow="sm"
      radius="md"
      px="md"
      py="md"
      style={{ cursor: 'pointer' }}
      withBorder
      onClick={() => onClick({ ...request })}
    >
      <Flex gap={10} align="center" justify="space-between">
        <Flex gap={10}>
          <Avatar size={50} src={request?.author?.avatar_filename} />
          <Stack gap={2}>
            <Text fw={700} size="lg">
              {request?.title}
            </Text>
            <Flex gap={3} align="center">
              <Text fw={600}>
                {request?.author?.first_name} {request?.author?.last_name}
              </Text>
              <Text c="dimmed" size="sm">
                - {createdAt}
              </Text>
            </Flex>
            <Text c="dimmed" size="sm">
              I&lsquo;m requesting {totalHours} hours{' '}
              {request?.data?.policy_name ?? ''} for{' '}
              {moment(startDate).format('MMM DD, YYYY')} -{' '}
              {moment(endData).format('MMM DD, YYYY')}
            </Text>
          </Stack>
        </Flex>

        {displayStatus && <RequestStatus status={request?.status} />}
      </Flex>
    </Paper>
  );
}

TimeOffRow.propTypes = {
  request: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  displayStatus: PropTypes.bool,
};

TimeOffRow.defaultProps = {
  onClick: () => {},
  displayStatus: true,
};
