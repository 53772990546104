import { Stack } from '@mantine/core';
import { mockData } from 'api/mockData';
import { PageWrapper } from 'components/PageWrapper';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import GlobalSearchSection from 'components/GlobalSearch/GlobalSearchSection';
import { usePageTitle } from 'utils/hooks';

export default function GlobalSearch({ params, globalTabName }) {
  const { search = '' } = params;

  const filteredData = mockData.globalSearch.filter((item) =>
    item.name.includes(search),
  );

  const groupedData = _.groupBy(filteredData, 'type');

  usePageTitle('Search', globalTabName);

  return (
    <PageWrapper title="Search">
      <Stack gap={0}>
        <GlobalSearchSection data={groupedData} />
      </Stack>
    </PageWrapper>
  );
}

GlobalSearch.propTypes = {
  params: PropTypes.object,
  globalTabName: PropTypes.string,
};

GlobalSearch.defaultProps = {
  params: {},
  globalTabName: '',
};
