import { Flex, Text } from '@mantine/core';
import { IconCheck, IconCircleX, IconClock } from '@tabler/icons-react';
import React from 'react';
import { requestStatuses } from 'utils/constants';
import PropTypes from 'prop-types';

const colors = {
  success: 'green',
  fail: 'red',
  undefined: 'yellow',
};

const statuses = {
  [requestStatuses.Pending]: {
    icon: IconClock,
    label: 'Pending',
    color: colors.undefined,
  },
  [requestStatuses.Approved]: {
    icon: IconCheck,
    label: 'Approved',
    color: colors.success,
  },
  [requestStatuses.Denied]: {
    icon: IconCircleX,
    label: 'Denied',
    color: colors.fail,
  },
  [requestStatuses.Deleted]: {
    icon: IconCircleX,
    label: 'Deleted',
    color: colors.fail,
  },
};

export default function RequestStatus({ status }) {
  const Icon = statuses?.[status]?.icon ?? null;

  return (
    <Flex
      align="center"
      c={statuses?.[status]?.color ?? colors.undefined}
      gap={10}
    >
      {Icon && <Icon />}
      <Text>{statuses?.[status]?.label ?? status}</Text>
    </Flex>
  );
}

RequestStatus.propTypes = {
  status: PropTypes.string.isRequired,
};
