import {
  Button,
  Container,
  Group,
  TextInput,
  Select,
  Textarea,
} from '@mantine/core';
import PropTypes from 'prop-types';
import { useForm } from '@mantine/form';
import { genericValidators } from 'utils';

export default function TalentGroupForm({
  talentGroup,
  onSubmit,
  onDelete,
  isSubmitLoading,
  isDeleteLoading,
}) {
  const form = useForm({
    initialValues: {
      name: talentGroup?.name,
      icon: talentGroup?.icon,
      description: talentGroup?.description,
    },
    validate: {
      name: (value) =>
        genericValidators.notEmpty(value, 'Talent Pool Name is empty') ||
        genericValidators.lessThanOrEqualTo(
          value,
          50,
          'Talent Pool Name needs to be less than 50 chars',
        ),
      icon: (value) => genericValidators.notEmpty(value, 'Icon is empty'),
      description: (value) =>
        genericValidators.notEmpty(value, 'Description is empty'),
    },
  });
  const isEditAction = Boolean(talentGroup);
  return (
    <Container px={0} size="md">
      <form onSubmit={form.onSubmit(onSubmit)} noValidate>
        <TextInput
          mb="md"
          name="name"
          label="Talent Group Name"
          placeholder="Name"
          value={form.values.name ?? ''}
          onChange={(event) =>
            form.setFieldValue('name', event.currentTarget.value)
          }
          error={form.errors.name}
        />
        <Select
          mb="md"
          name="icon"
          label="Icon"
          placeholder="Pick Icon"
          data={['Draft', 'Open']}
          value={form.values.icon}
          onChange={(value) => form.setFieldValue('icon', value)}
          error={form.errors.icon}
        />
        <Textarea
          mb="md"
          name="description"
          label="Description"
          placeholder="Add information"
          minRows={3}
          value={form.values.description}
          onChange={(event) =>
            form.setFieldValue('description', event.currentTarget.value)
          }
          error={form.errors.description}
        />

        <Group gap="xl" mt="xl" justify="center">
          {isEditAction && (
            <Button
              disabled={isDeleteLoading}
              loading={isSubmitLoading}
              type="submit"
            >
              Update
            </Button>
          )}
          {!isEditAction && (
            <Button loading={isSubmitLoading} type="submit">
              Create
            </Button>
          )}
          {isEditAction && (
            <Button
              disabled={isSubmitLoading}
              loading={isDeleteLoading}
              onClick={onDelete}
              variant="filled"
              color="red"
            >
              Delete
            </Button>
          )}
        </Group>
      </form>
    </Container>
  );
}

TalentGroupForm.propTypes = {
  talentGroup: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  isSubmitLoading: PropTypes.bool,
  isDeleteLoading: PropTypes.bool,
};
TalentGroupForm.defaultProps = {
  talentGroup: null,
  onDelete: () => {},
  isSubmitLoading: false,
  isDeleteLoading: false,
};
