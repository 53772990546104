import { useAuthContext } from 'auth';
import { usePermissions } from 'components/PermissionGuard/hooks';
import PropTypes from 'prop-types';
import AllRequests from '../components/AllRequests';

export default function AllRequestTab({
  requestType,
  requestId,
  isRequestLoading,
  onPreview,
}) {
  const { hasPermission, permissions } = usePermissions();
  const { user } = useAuthContext();

  return (
    <AllRequests
      onPreview={onPreview}
      requestType={requestType}
      requestId={requestId}
      payload={{
        Performance: {
          assignee_id: user?.id,
          status: [0],
        },
        TimeoffRecords: {
          assignee_id: hasPermission(permissions.canManageEmployee)
            ? ''
            : user?.id,
        },
        Signature: {
          assignee_id: user?.id,
        },
        status: [0],
      }}
      isRequestLoading={isRequestLoading}
      displayStatus={false}
    />
  );
}

AllRequestTab.propTypes = {
  onPreview: PropTypes.func.isRequired,
  requestType: PropTypes.string,
  requestId: PropTypes.string,
  isRequestLoading: PropTypes.bool,
};

AllRequestTab.defaultProps = {
  requestType: '',
  requestId: '',
  isRequestLoading: false,
};
