import { useCandidateStatuses } from 'api/hooks';
import _ from 'lodash';
import { useMemo } from 'react';

export default function useCandidatesStatusSelectorData({ selectedValue }) {
  const { candidateStatuses } = useCandidateStatuses();

  const candidateStatusSelectorData = useMemo(() => {
    const groups = _.entries(candidateStatuses);
    const data = groups.map(([group, items]) => ({
      group,
      items: _.map(items, 'name') ?? [],
    }));
    const isStatusExist = _.some(data, ({ items }) =>
      _.includes(items, selectedValue),
    );
    if (selectedValue && !isStatusExist) {
      // if candidateDetails status was deleted
      return data.concat([{ group: 'Deleted', items: [selectedValue] }]);
    }
    return data;
  }, [candidateStatuses, selectedValue]);

  return { candidateStatusSelectorData, candidateStatuses };
}
