import { Button, Divider, Flex, Table } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { clearCache } from 'api/clearCache';
import {
  useCreateJobInformation,
  useDeleteJobInformation,
  useLoadJobInformationList,
  useLoadLookups,
  useUpdateJobInformation,
} from 'api/hooks';
import { ConfirmDeleteModal } from 'components/ConfirmDeleteModal';
import { CreateJobInformationModal } from 'components/CreateJobInformationModal';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import { Skeleton } from 'components/Skeleton';
import _ from 'lodash';
import { useNotifications } from 'notifications/hooks/useNotifications';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { getCurrentReasonType } from 'utils';
import { lookupsModels } from 'utils/constants';
import { useStateDisclosure } from 'utils/hooks';
import {
  BonusRecords,
  CompensationRecords,
  EmploymentStatusRecords,
} from './components';
import { JobInformationTabRow } from './tabs-rows';

export default function JobInformationTab({ employeeId, profile }) {
  const [
    deleteOpened,
    { open: openDeleteModal, close: closeDeleteModal, state: deleteState },
  ] = useStateDisclosure(false);
  const queryClient = useQueryClient();

  const { setNotifications } = useNotifications();
  const { lookups, isLoading: isLookupsLoading } = useLoadLookups({
    models: [lookupsModels.reason],
  });

  const {
    jobInformationList,
    isLoading: isJobInformationListLoading,
    refetch: refetchJobInformationList,
  } = useLoadJobInformationList(employeeId);

  const [
    openedAddJobInformation,
    {
      open: openAddJobInformation,
      close: closeAddJobInformation,
      state: jobInformationState,
    },
  ] = useStateDisclosure(false);

  const { createJobInformation, isLoading: isCreating } =
    useCreateJobInformation({
      onSuccess: (resp, params) => {
        clearCache.onChangeEmployeeJobInfo(queryClient, {
          reportTo: params?.report_to,
        });
        setNotifications(['Job Information has been successfully created']);
        refetchJobInformationList();
        closeAddJobInformation();
      },
    });

  const { deleteJobInformation, isLoading: isDeleting } =
    useDeleteJobInformation({
      onSuccess: () => {
        clearCache.onChangeEmployeeJobInfo(queryClient, {
          reportTo: deleteState?.report_to?.id,
        });
        closeDeleteModal();
        refetchJobInformationList();
        setNotifications(['Job Information has been successfully deleted']);
      },
    });

  const { updateJobInformation, isLoading: isUpdating } =
    useUpdateJobInformation({
      onSuccess: (resp, params) => {
        clearCache.onChangeEmployeeJobInfo(queryClient, {
          reportTo: [params?.report_to, jobInformationState?.report_to?.id],
        });
        closeAddJobInformation();
        refetchJobInformationList();
        setNotifications(['Job Information has been successfully updated']);
      },
    });

  return (
    <>
      <CreateJobInformationModal
        opened={openedAddJobInformation}
        onClose={closeAddJobInformation}
        onCreate={(data) => createJobInformation({ employeeId, ...data })}
        isLoading={isCreating || isUpdating}
        onEdit={(data) => updateJobInformation({ employeeId, ...data })}
        jobInformationState={jobInformationState}
      />
      <ConfirmDeleteModal
        opened={deleteOpened}
        onClose={closeDeleteModal}
        isLoading={isDeleting}
        onDelete={() =>
          deleteJobInformation({
            employeeId,
            job_information_id: deleteState?.id,
          })
        }
        deleteItemName="Job History"
      />
      <Flex mt={20} my={10} align="center" justify="space-between">
        <h2 style={{ margin: '0px' }}>Job Information</h2>
        <Button
          rightSection={<IconPlus size={14} />}
          onClick={openAddJobInformation}
        >
          Add job information
        </Button>
      </Flex>
      <Skeleton
        count={4}
        isVisible={isJobInformationListLoading}
        skeletonWrapperProps={{ my: 10 }}
      />

      <EmptyDataMessage
        isVisible={
          !isJobInformationListLoading && _.isEmpty(jobInformationList)
        }
        my={20}
      />
      <Table>
        {!_.isEmpty(jobInformationList) && (
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Job Title</Table.Th>
              <Table.Th>Location</Table.Th>
              <Table.Th>Division</Table.Th>
              <Table.Th>Department</Table.Th>
              <Table.Th>Reports to</Table.Th>
              <Table.Th>Date</Table.Th>
            </Table.Tr>
          </Table.Thead>
        )}
        <Table.Tbody>
          {_.map(jobInformationList, (jobInformation) => (
            <JobInformationTabRow
              jobInformation={jobInformation}
              key={jobInformation?.id}
              onEdit={(ji) =>
                openAddJobInformation({ ...ji, modalType: 'Edit' })
              }
              onDelete={openDeleteModal}
            />
          ))}
        </Table.Tbody>
      </Table>
      <Divider />

      <EmploymentStatusRecords employeeId={employeeId} profile={profile} />

      <Divider />

      <CompensationRecords
        employeeId={employeeId}
        compensationReasonType={getCurrentReasonType(
          lookups?.Reason,
          'Compensation',
        )}
        isLookupsLoading={isLookupsLoading}
      />

      <Divider />

      <BonusRecords
        profile={profile}
        compensationReasonType={getCurrentReasonType(lookups?.Reason, 'Bonus')}
        isLookupsLoading={isLookupsLoading}
      />

      <Divider />
    </>
  );
}

JobInformationTab.propTypes = {
  employeeId: PropTypes.string.isRequired,
  profile: PropTypes.object,
};

JobInformationTab.defaultProps = {
  profile: {},
};
