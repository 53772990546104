import _ from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';

export const formValidators = {
  firstName: (val) =>
    val.length > 50
      ? 'First Name should not be empty and no longer than 50 characters'
      : null,
  lastName: (val) =>
    val.length > 50
      ? 'Last Name should not be longer than 50 characters'
      : null,
  confirmPassword: (val) =>
    val.length <= 6 || val.length < 1
      ? 'Password should include at least 6 characters'
      : null,
  email: (val) =>
    /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(val)
      ? null
      : 'Invalid email',
  password: (val) =>
    val.length <= 6 || val.length < 1
      ? 'Password should include at least 6 characters'
      : null,
};

export const combineValidators =
  (...validators) =>
  (value) => {
    const errors = validators
      .map((validator) => validator(value))
      .filter((result) => result !== null);

    return errors.length > 0 ? errors[0] : null;
  };

export const genericValidators = {
  notEmpty: (val, message = 'Must not be empty') => {
    const currentValue = _.isNumber(val) ? _.toString(val) : val;
    return currentValue?.length > 0 ? null : message;
  },
  lessThanOrEqualTo: (val, limit, message) => {
    if (typeof val === 'number') {
      return val > limit ? message : null;
    }
    return val?.length > limit ? message : null;
  },
  notLessThan: (val, limit, message) => {
    if (typeof val === 'number') {
      return val < limit || Number.isNaN(val) ? message : null;
    }
    return val?.length < limit ? message : null;
  },
  isNumber: (val, message = 'Must be a number') =>
    typeof val === 'number' ? null : message,
};

export const datesValidators = {
  isDateSameOrAfter: (date1, date2, message = 'Invalid date') => {
    const date = moment(date1, 'YYYY-MM-DD', true);
    const referenceDate = moment(date2, 'YYYY-MM-DD', true);

    const isValidDates = date.isValid() && referenceDate.isValid();
    return isValidDates && date.isSameOrAfter(referenceDate) ? null : message;
  },
  isValidDate: (date, message = 'Invalid Date') =>
    moment(date, 'YYYY-MM-DD', true).isValid() ? null : message,
};

const transformValidators = (fields) =>
  fields?.reduce((acc, field) => {
    const validators = [];
    if (field.validation.isRequired) {
      validators.push((val) =>
        genericValidators.notEmpty(
          val,
          `${field.displayName} must not be empty`,
        ),
      );
    }
    if (field.validation.isEmail) {
      validators.push((val) => formValidators.email(val));
    }
    if (field.validation.maxLength) {
      validators.push((val) =>
        genericValidators.lessThanOrEqualTo(
          val,
          field.validation.maxLength,
          `${field.displayName} should not be longer than ${field.validation.maxLength}`,
        ),
      );
    }
    if (field.validation.minLength) {
      validators.push((val) =>
        genericValidators.notLessThan(
          val,
          field.validation.minLength,
          `${field.displayName} should not be less than ${field.validation.minLength}`,
        ),
      );
    }
    acc[field.name] = (value) =>
      validators.reduce((result, fn) => {
        if (!result) {
          return fn(value);
        }
        return result;
      }, null);

    return acc;
  }, {});

export const useFormValidations = (
  fields,
  jobInformation,
  customValidation,
) => {
  const validations = useMemo(() => transformValidators(fields), [fields]);
  const validationJobInformation = useMemo(
    () =>
      !_.isEmpty(jobInformation)
        ? {
            jobtitle_id: (value) =>
              genericValidators.notEmpty(
                value,
                'Job Title should not be empty',
              ),
            location_id: (value) =>
              genericValidators.notEmpty(value, 'Location should not be empty'),
            department_id: (value) =>
              genericValidators.notEmpty(
                value,
                'Department should not be empty',
              ),
            division_id: (value) =>
              genericValidators.notEmpty(value, 'Division should not be empty'),
          }
        : {},
    [jobInformation],
  );
  return {
    validate: {
      ...validations,
      ...validationJobInformation,
      ...customValidation,
    },
  };
};
