import { Divider, Flex, Text, Tooltip } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { IconCopy } from '@tabler/icons-react';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

export default function TemplateVariableItem({ templateList, item }) {
  const clipboard = useClipboard({ timeout: 500 });

  return (
    <>
      <Flex>
        <Text fw="600" fz={14}>
          {item}
        </Text>
        <Tooltip opened={clipboard.copied} label="Copied!">
          <IconCopy
            className={styles.copyIcon}
            cursor="pointer"
            onClick={() => clipboard.copy(item)}
          />
        </Tooltip>
      </Flex>
      <Text c="var(--mantine-color-gray-6)" fz={14}>
        {templateList[item]}
      </Text>
      <Divider />
    </>
  );
}

TemplateVariableItem.propTypes = {
  item: PropTypes.string.isRequired,
  templateList: PropTypes.object.isRequired,
};
