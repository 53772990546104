import { Button, Tooltip, rem } from '@mantine/core';
import { useGlobalTabsContext } from 'globalTabs';
import PropTypes from 'prop-types';
import { NavLink, useNavigate } from 'react-router-dom';

import classes from './SidebarItem.module.css';

function SidebarItem({
  icon: Icon,
  label,
  link,
  tabName,
  onClick,
  desktopOpened,
}) {
  const { setSelectedTab, setTabs } = useGlobalTabsContext();
  const navigation = useNavigate();
  const itemContent = link ? (
    <NavLink
      to={link}
      className={({ isActive }) =>
        `${classes.link} ${isActive ? classes.active : ''}`
      }
      data-testid={`sidebar-item-${label}`}
      onClick={() => {
        setTabs({ tabName, children: null, pathName: link });
        setSelectedTab(tabName);
        navigation({ pathname: link, search: '' });
      }}
    >
      <Icon style={{ width: rem(20), height: rem(20) }} stroke={1.5} />
      {!desktopOpened && <span className={classes.menuLabel}>{label}</span>}
    </NavLink>
  ) : (
    <Button
      onClick={onClick}
      className={classes.link}
      variant="outline"
      color="transparent"
      data-testid={`sidebar-item-${label}`}
    >
      <Icon style={{ width: rem(20), height: rem(20) }} stroke={1.5} />
    </Button>
  );
  return (
    <Tooltip
      label={label}
      position="right"
      transitionProps={{ duration: 0 }}
      zIndex={1001}
    >
      {itemContent}
    </Tooltip>
  );
}
SidebarItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  desktopOpened: PropTypes.bool,
  link: PropTypes.string,
  tabName: PropTypes.string,
  onClick: PropTypes.func,
};
SidebarItem.defaultProps = {
  desktopOpened: false,
  link: null,
  tabName: null,
  onClick: () => {},
};
export default SidebarItem;
