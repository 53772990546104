import { Avatar, Button, Flex, Paper, Stack, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronLeft } from '@tabler/icons-react';
import { CreateCandidateOfferModal } from 'CreateCandidateOfferModal';
import { useCancelCandidateOffer, useUpdateCandidateOffer } from 'api/hooks';
import { queryKeys } from 'api/keys';
import _ from 'lodash';
import moment from 'moment';
import { useNotifications } from 'notifications/hooks/useNotifications';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { offerStatuses } from 'utils/constants';
import { useTabNavigate } from 'utils/hooks';

export default function CandidateOfferHeader({ candidate, offer }) {
  const navigate = useTabNavigate();
  const { jobId, candidateId } = useParams();

  const { setNotifications } = useNotifications();

  const queryClient = useQueryClient();

  const [
    openedCandidateOffer,
    { open: openCandidateOffer, close: closeCandidateOffer },
  ] = useDisclosure(false);

  const goToCandidatePage = () =>
    navigate(`/hiring/job/${jobId}/candidate/${candidateId}`);

  const { updateCandidateOffer } = useUpdateCandidateOffer({
    onSuccess: () => {
      setNotifications(['Offer Updated Successfully']);
      queryClient.invalidateQueries(queryKeys.candidate(candidateId));
      closeCandidateOffer();
    },
  });

  const { cancelCandidateOffer } = useCancelCandidateOffer({
    onSuccess: () => {
      setNotifications(['Offer Canceled Successfully']);
      queryClient.invalidateQueries(queryKeys.candidate(candidateId));
      goToCandidatePage();
    },
  });

  const onSubmitCandidateOffer = (formattedOffer) => {
    const data = {
      ...formattedOffer,
      job_title_id: formattedOffer?.job_information?.id,
      pay_frequency: formattedOffer?.pay_frequency?.value,
      contact_id: formattedOffer?.contact?.id,
      job_id: jobId,
      expiry_date: moment(formattedOffer?.expiry_date).format('YYYY-MM-DD'),
      start_date: moment(formattedOffer?.start_date).format('YYYY-MM-DD'),
    };

    updateCandidateOffer({ id: offer?.id, ...data });
  };

  return (
    <Paper
      bg="var(--mantine-color-blue-6)"
      shadow="md"
      radius={0}
      py="lg"
      px="xl"
      mb={10}
    >
      <CreateCandidateOfferModal
        opened={openedCandidateOffer}
        onClose={closeCandidateOffer}
        offerState={{
          modalType: 'Edit',
          ...offer,
        }}
        onSubmit={onSubmitCandidateOffer}
        onCancelOffer={() => cancelCandidateOffer(offer?.id)}
        meta={candidate?.offer_meta}
      />
      <Flex justify="space-between" align="center">
        <Flex gap={20} align="center">
          <Avatar variant="outline" color="white" bg="gray" size={150}>
            {_.first(candidate?.first_name)}
            {_.first(candidate?.last_name)}
          </Avatar>
          <Stack gap={7}>
            <Text c="white">Viewing letter for:</Text>
            <Title c="white">
              {candidate?.first_name} {candidate?.last_name}
            </Title>
            <Text c="white">{candidate?.email}</Text>
          </Stack>
        </Flex>
        <Flex align="center" gap={20}>
          <Text c="white">
            {offer?.employee?.first_name} {offer?.employee?.last_name} sent this
            offer to {candidate?.first_name} on{' '}
            {moment(candidate?.created_at).format('dddd, MMM DD hh:mm A')}
          </Text>
          <Flex gap={5}>
            <Button
              variant="outline"
              color="white"
              onClick={goToCandidatePage}
              leftSection={<IconChevronLeft />}
            >
              Done
            </Button>
            <Button
              variant="outline"
              color="white"
              onClick={openCandidateOffer}
              disabled={
                _.isEmpty(offer) || offer?.status?.id !== offerStatuses.Sent
              }
            >
              Edit
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Paper>
  );
}

CandidateOfferHeader.propTypes = {
  candidate: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
};
