import { Menu, MenuTarget, Text, UnstyledButton } from '@mantine/core';
import React from 'react';
import PropTypes from 'prop-types';
import { IconEye } from '@tabler/icons-react';
import moment from 'moment';
import _ from 'lodash';
import { PreviewEmailTemplate } from 'components/PreviewEmailTemplate';
import { useStateDisclosure } from 'utils/hooks';

export default function PreviousOffersMenu({
  label,
  offers,
  statusColor,
  candidateDetails,
}) {
  const [
    openedTemplatePreview,
    { open: openTemplatePreview, close: onClose, state: stateTemplatePreview },
  ] = useStateDisclosure(false);

  return (
    <>
      <PreviewEmailTemplate
        opened={openedTemplatePreview}
        offer={stateTemplatePreview ?? {}}
        onClose={onClose}
        candidateDetails={candidateDetails}
        readOnly
      />
      <Menu>
        <MenuTarget>
          {typeof label === 'string' ? (
            <UnstyledButton c={statusColor}>{label}</UnstyledButton>
          ) : (
            label
          )}
        </MenuTarget>
        {!_.isEmpty(offers) && (
          <Menu.Dropdown>
            <Menu.Label>Previous Offers</Menu.Label>
            {offers?.map((offer) => (
              <Menu.Item
                key={offer?.id}
                leftSection={<IconEye />}
                onClick={() => openTemplatePreview(offer)}
              >
                <Text>
                  {moment(offer?.created_at).format('MMM DD hh:mm A')}
                </Text>
                <Text c="gray" size="sm">
                  Created by {offer?.employee?.first_name}{' '}
                  {offer?.employee?.last_name}
                </Text>
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        )}
      </Menu>
    </>
  );
}

PreviousOffersMenu.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  offers: PropTypes.array,
  statusColor: PropTypes.string,
  candidateDetails: PropTypes.object.isRequired,
};

PreviousOffersMenu.defaultProps = {
  statusColor: '',
  offers: [],
};
