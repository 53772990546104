import { Avatar, Button, Flex, Pill, Stack, Text, rem } from '@mantine/core';
import {
  IconArrowNarrowRight,
  IconPencil,
  IconTrash,
} from '@tabler/icons-react';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { requestStatuses } from 'utils/constants';
import RequestStatus from './RequestStatus';

export default function TimeOffUserInfo({
  request,
  policyName,
  onEdit,
  onCancel,
}) {
  const createdAt = request?.created_at
    ? moment(request?.created_at).format('DD/MM/YYYY, h:mm a')
    : '';

  const startDate = request?.data?.timeoffDetails
    ? moment(_.first(request?.data?.timeoffDetails)?.date).format('DD/MM/YYYY')
    : '';

  const endDate = request?.data?.timeoffDetails
    ? moment(_.last(request?.data?.timeoffDetails)?.date).format('DD/MM/YYYY')
    : '';

  const totalHours = request?.data?.timeoffDetails?.reduce(
    (prev, curr) => prev + +curr.hours,
    0,
  );

  return (
    <Flex gap={10}>
      <Avatar src={request?.author?.avatar_filename} size={80} />
      <Stack justify="top" gap={5}>
        <Text fw={700} size="lg">
          {request?.author?.first_name} {request?.author?.last_name}
        </Text>
        <Flex gap="sm">
          <Text c="dimmed" size="sm">
            {createdAt}
          </Text>
          {request?.data && <Pill>{policyName}</Pill>}
        </Flex>
        <Flex gap="md">
          <Flex gap={8}>
            <Text size="sm">Start Date: </Text>
            <Pill size="sm">
              <Text size="sm" fw={500}>
                {startDate}
              </Text>
            </Pill>
          </Flex>

          <IconArrowNarrowRight size={22} />
          <Flex gap={8}>
            <Text size="sm">End Date: </Text>
            <Pill size="sm">
              <Text size="sm" fw={500}>
                {endDate}
              </Text>
            </Pill>
          </Flex>
          {request?.status !== requestStatuses.Pending && (
            <RequestStatus status={request?.status} />
          )}
        </Flex>

        <Flex gap={8}>
          <Text size="sm">Total: </Text>
          <Pill size="sm">
            <Text size="sm" fw={500}>
              {totalHours}h
            </Text>
          </Pill>
        </Flex>
      </Stack>
      {request?.status === requestStatuses.Pending && (
        <Flex ml="auto" gap={10}>
          <Button
            p={0}
            style={{
              width: rem(50),
              height: rem(50),
              borderRadius: '10px',
              border: '1px solid gray',
            }}
            onClick={onEdit}
            bg="var(--white-dark)"
            variant="white"
          >
            <IconPencil size={40} />
          </Button>
          <Button
            p={0}
            style={{
              width: rem(50),
              height: rem(50),
              borderRadius: '10px',
              border: '1px solid gray',
            }}
            onClick={onCancel}
            bg="var(--white-dark)"
            variant="white"
          >
            <IconTrash size={40} cursor="pointer" />
          </Button>
        </Flex>
      )}
    </Flex>
  );
}

TimeOffUserInfo.propTypes = {
  request: PropTypes.object,
  policyName: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
};

TimeOffUserInfo.defaultProps = {
  request: {},
  onEdit: () => {},
  onCancel: () => {},
};
