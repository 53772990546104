import { Table } from '@mantine/core';
import PropTypes from 'prop-types';

export default function KeyValueTableRow({ label, value }) {
  return (
    <Table.Tr>
      <Table.Td>{label}</Table.Td>
      <Table.Td>{value}</Table.Td>
    </Table.Tr>
  );
}

KeyValueTableRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

KeyValueTableRow.defaultProps = {
  label: '',
  value: '',
};
