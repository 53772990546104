import { Modal, Text } from '@mantine/core';
import PropTypes from 'prop-types';
import TimeOffEmployeeForm from '../forms/TimeOffEmployeeForm';

export default function TimeOffEmployeeModal({
  isLoading,
  opened,
  onClose,
  onSubmit,
  categories,
  policy,
  policies,
}) {
  return (
    <Modal
      opened={opened}
      onClose={() => {
        onClose();
      }}
      title={policy ? 'Move To Other Policy' : 'Add Policy'}
      centered
    >
      {policy && (
        <Text c="red" size="xs">
          Not implemented
        </Text>
      )}
      <TimeOffEmployeeForm
        policy={policy}
        isLoading={isLoading}
        onSubmit={onSubmit}
        categories={categories}
        policies={policies}
      />
    </Modal>
  );
}

TimeOffEmployeeModal.propTypes = {
  isLoading: PropTypes.bool,
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  categories: PropTypes.array,
  policies: PropTypes.array,
  policy: PropTypes.object,
};

TimeOffEmployeeModal.defaultProps = {
  isLoading: false,
  opened: false,
  onClose: () => {},
  onSubmit: () => {},
  categories: [],
  policies: [],
  policy: null,
};
