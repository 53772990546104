import { Combobox, Flex, Stack, Text, useCombobox } from '@mantine/core';
import {
  IconBrandTelegram,
  IconCalendar,
  IconUsersGroup,
} from '@tabler/icons-react';
import { useLoadCandidateOffers } from 'api/hooks';
import _ from 'lodash';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { tabNames } from 'utils/constants';
import { useTabNavigate } from 'utils/hooks';
import { WidgetWrapper } from '.';
import styles from '../styles.module.css';

const allowedDays = ['30', '60', '90'];

export default function CandidatesOffers(rest) {
  const navigate = useTabNavigate();

  const [selectedDays, setSelectedDays] = useState('30');

  const combobox = useCombobox();

  const { candidateOffers, isLoading: isOffersLoading } =
    useLoadCandidateOffers({
      filter: { lastDays: selectedDays, onlyActive: 1 },
    });

  const groupedOffers = useMemo(
    () => _.groupBy(candidateOffers, 'status.name') ?? {},
    [candidateOffers],
  );

  const navigateToProfile = (offer) => {
    navigate(
      `/hiring/job/${offer?.job_information?.id}/candidate/${offer?.candidate?.id}`,
      {
        tabName: tabNames.hiringJobCandidate,
      },
    );
  };

  return (
    <WidgetWrapper
      title="Candidate With Offers"
      wrapperProps={{ h: 'max-content', mih: 300, mah: 450 }}
      Icon={<IconUsersGroup size={30} color="white" />}
      isLoading={isOffersLoading}
      rightSection={
        <Combobox
          onOptionSubmit={(val) => {
            setSelectedDays(val);
            combobox.closeDropdown();
          }}
          store={combobox}
          withinPortal
          width={90}
        >
          <Combobox.Target>
            <IconCalendar
              cursor="pointer"
              onClick={() => combobox.openDropdown()}
            />
          </Combobox.Target>

          <Combobox.Dropdown>
            {allowedDays.map((day) => (
              <Combobox.Option
                style={{
                  backgroundColor:
                    selectedDays === day
                      ? 'var(--mantine-color-gray-2)'
                      : undefined,
                }}
                key={day}
                value={day}
              >
                {day} days
              </Combobox.Option>
            ))}
          </Combobox.Dropdown>
        </Combobox>
      }
      {...rest}
    >
      {_.keys(groupedOffers)?.map((statusName) => (
        <Stack gap={0} key={statusName}>
          <Text fw={500}>{statusName}</Text>
          {groupedOffers[statusName]?.map((item) => (
            <div className={styles.recordHover} key={item?.id}>
              <Flex
                justify="space-between"
                w="100%"
                px={10}
                py={5}
                onClick={() => navigateToProfile(item)}
              >
                <Stack gap={0}>
                  <Text>
                    {item?.candidate?.first_name} {item?.candidate?.last_name}
                  </Text>
                  <Text>{item?.job_information?.name}</Text>
                </Stack>
                <Stack gap={0} align="center">
                  <Flex align="center">
                    <IconBrandTelegram />
                    Sent
                  </Flex>
                  {moment(item?.created_at).format('YYYY-MM-DD')}
                </Stack>
              </Flex>
            </div>
          ))}
        </Stack>
      ))}
    </WidgetWrapper>
  );
}
