export const mockData = {
  requests: {
    assets: [
      {
        id: 1,
        category: 'assets',
        title: 'IPad Test device',
        content: 'John requested New iPad for testing',
        created_at: '2024-01-02T07:24:17.000000Z',
        data: JSON.stringify({
          assignDate: '2024-01-02T07:24:17.000000Z',
          returnDate: '2024-01-15T07:24:17.000000Z',
          name: 'iPad Pro 2023',
        }),
      },
      {
        id: 2,
        category: 'assets',
        title: 'New Macbook Pro',
        content: 'Mark requested a new Macbook Pro 2023',
        created_at: '2024-01-02T07:24:17.000000Z',
        data: JSON.stringify({
          assignDate: '2024-05-01T07:24:17.000000Z',
          returnDate: '2024-06-01T07:24:17.000000Z',
          name: 'Macbook Pro 2023',
        }),
      },
    ],
    compensation: [
      {
        id: 1,
        category: 'compensation',
        title: 'Paternal Compensation',
        content: 'John requested a paternal compensation',
        created_at: '2024-01-02T07:24:17.000000Z',
        data: JSON.stringify({
          startDate: '2024-01-02T07:24:17.000000Z',
          endDate: '2024-01-15T07:24:17.000000Z',
          name: 'Paternal Compensation',
          type: 'Paternal',
          compensationValue: '5000',
        }),
      },
      {
        id: 2,
        category: 'compensation',
        title: 'Maternal Compensation',
        content: 'Angela requested a maternal compensation',
        created_at: '2024-01-02T07:24:17.000000Z',
        data: JSON.stringify({
          startDate: '2024-05-01T07:24:17.000000Z',
          endDate: '2024-06-01T07:24:17.000000Z',
          name: 'Maternal Compensation',
          type: 'Maternal',
          compensationValue: '10000',
        }),
      },
    ],
    employmentStatus: [
      {
        id: 1,
        category: 'employmentStatus',
        title: 'Employment Status',
        content: 'Robert employment status change request',
        created_at: '2024-01-02T07:24:17.000000Z',
        data: JSON.stringify({
          date: '2024-01-02T07:24:17.000000Z',
          currentStatus: 'Junior',
          newStatus: 'Middle',
        }),
      },
      {
        id: 2,
        category: 'employmentStatus',
        title: 'Employment Status',
        content: 'Emily employment status change request',
        created_at: '2024-01-02T07:24:17.000000Z',
        data: JSON.stringify({
          date: '2024-05-01T07:24:17.000000Z',
          currentStatus: 'Middle',
          newStatus: 'Senior',
        }),
      },
    ],
    jobInfo: [
      {
        id: 1,
        category: 'jobInfo',
        title: 'Job information change',
        content: 'Ernest hire date change request',
        created_at: '2024-01-02T07:24:17.000000Z',
        data: JSON.stringify({
          hireDate: '2024-01-02T07:24:17.000000Z',
          jobTitle: 'Engineer',
          currentJobTitle: 'Candidate',
        }),
      },
      {
        id: 2,
        category: 'jobInfo',
        title: 'Job information change',
        content: 'George job title change request',
        created_at: '2024-01-02T07:24:17.000000Z',
        data: JSON.stringify({
          hireDate: '2024-01-02T07:24:17.000000Z',
          jobTitle: 'Team Lead',
          currentJobTitle: 'Senior Engineer',
        }),
      },
    ],
    promotion: [
      {
        id: 1,
        category: 'promotion',
        title: 'Promotion Request',
        content: 'Elon requested promotion',
        created_at: '2024-01-02T07:24:17.000000Z',
        data: JSON.stringify({
          hireDate: '2024-01-02T07:24:17.000000Z',
          jobTitle: 'Engineer',
          currentJobTitle: 'Candidate',
        }),
      },
      {
        id: 2,
        category: 'promotion',
        title: 'Promotion Request',
        content: 'George requested promotion',
        created_at: '2024-01-02T07:24:17.000000Z',
        data: JSON.stringify({
          hireDate: '2024-01-02T07:24:17.000000Z',
          jobTitle: 'Team Lead',
          currentJobTitle: 'Senior Engineer',
        }),
      },
    ],
  },
  questions: [
    {
      id: 1,
      questions: 'How did you hear about this position?',
      response: 'My friend, Jean Cameron, told me about this position',
    },
    {
      id: 2,
      questions: 'What skills can you bring to this role?',
      response: 'I am skilled at Java, HTML, and Python',
    },
    {
      id: 3,
      questions: 'Who referred you for this position?',
      response: 'Jean Cameron',
    },
    {
      id: 4,
      questions: 'References (Name, Company, and Contact Info)',
      response: `Theodore Mathis
Manufacturing Company
VP of Product Development
+61 2 5555 2832
productdev@efficientoffice.com
Ricardo Meyer
Software Company
Senior Software Engineer
+612 5555 0185
softwareengineer@efficientoffice.com`,
    },
  ],
  globalSearch: [
    { type: 'Employee', name: 'Alice' },
    { type: 'Settings', name: 'Survey' },
    { type: 'Employee', name: 'Charlie' },
    { type: 'Hiring', name: 'David' },
    { type: 'Employee', name: 'Eva' },
    { type: 'Hiring', name: 'Frank' },
    { type: 'Settings', name: 'Onboarding' },
    { type: 'Employee', name: 'Grace' },
    { type: 'Hiring', name: 'Helen' },
    { type: 'Employee', name: 'Ian' },
    { type: 'Hiring', name: 'Jack' },
    { type: 'Employee', name: 'Kelly' },
    { type: 'Hiring', name: 'Liam' },
    { type: 'Employee', name: 'Mia' },
    { type: 'Hiring', name: 'Nathan' },
    { type: 'Employee', name: 'Olivia' },
  ],
};
