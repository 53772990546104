import { Flex, Table, Text } from '@mantine/core';

import { IconChartInfographic } from '@tabler/icons-react';
import { PageWrapper } from 'components/PageWrapper';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { usePageTitle } from 'utils/hooks';

const reportList = [
  {
    label: 'Head Count',
    url: '/reports/headcount',
    icon: <IconChartInfographic size={30} />,
  },
];

export default function Reports({ globalTabName }) {
  usePageTitle('Reports', globalTabName);

  return (
    <PageWrapper title="Reports">
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Report Name</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {reportList.map(({ label, url, icon }) => (
            <Table.Tr key={label}>
              <Table.Td>
                <Link
                  to={url}
                  style={{ textDecoration: 'none', color: 'var(--dark-white)' }}
                >
                  <Flex align="end" gap={6}>
                    {icon}
                    <Text lh="xs">{label}</Text>
                  </Flex>
                </Link>
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </PageWrapper>
  );
}

Reports.propTypes = {
  globalTabName: PropTypes.string,
};

Reports.defaultProps = {
  globalTabName: '',
};
