import { Stack, Text } from '@mantine/core';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import _ from 'lodash';
import PropTypes from 'prop-types';
import styles from '../styles.module.css';
import GenericAllowedWidget from './GenericAllowedWidget';

export default function AllowedWidgets({
  allowedWidgets,
  isLoading,
  isFetching,
}) {
  return (
    <Stack w="30%" className={styles.allowedWidgetsWrapper} pl={16}>
      <Text>Add Widgets</Text>

      <EmptyDataMessage isVisible={!isLoading && _.isEmpty(allowedWidgets)} />

      {_.map(allowedWidgets, (allowedWidget) => (
        <GenericAllowedWidget
          key={allowedWidget?.id}
          widget={allowedWidget}
          isFetching={isFetching}
        />
      ))}
    </Stack>
  );
}

AllowedWidgets.propTypes = {
  allowedWidgets: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
};
