import { Flex, Modal } from '@mantine/core';
import { useLoadWidgets, useUpdateWidgetPosition } from 'api/hooks';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { ActiveWidgets, AllowedWidgets } from './components';
import styles from './styles.module.css';

export default function WidgetSettings({ opened, onClose }) {
  const {
    widgets,
    isLoading: isWidgetLoadings,
    isFetching: isWidgetFetching,
  } = useLoadWidgets();

  const { updateWidgetPosition } = useUpdateWidgetPosition({
    onSuccess: () => {},
  });

  const onReorderWidgets = useCallback(
    (state) => {
      const orderedWidgets = state?.map(({ id, column }, index) => ({
        id,
        column,
        order: index + 1,
      }));

      updateWidgetPosition({ widgets: orderedWidgets });
    },
    [updateWidgetPosition],
  );

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      fullScreen
      classNames={{ body: styles.modalBody }}
    >
      <Flex w="100%" gap={10} h="100%">
        <ActiveWidgets
          usedWidgets={
            widgets?.usedWidgets?.sort?.((a, b) => a.order - b.order) ?? []
          }
          isLoading={isWidgetLoadings}
          isFetching={isWidgetFetching}
          onReorder={onReorderWidgets}
        />
        <AllowedWidgets
          allowedWidgets={widgets?.allowedWidgets ?? []}
          isLoading={isWidgetLoadings}
          isFetching={isWidgetFetching}
        />
      </Flex>
    </Modal>
  );
}

WidgetSettings.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
