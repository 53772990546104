import { Modal } from '@mantine/core';
import { AddBonusReasonForm } from 'components/AddBonusReasonForm';
import PropTypes from 'prop-types';

export default function AddBonusReasonModal({
  opened,
  onClose,
  reasonState,
  onEdit,
  onCreate,
  isLoading,
}) {
  const isEdit = reasonState?.modalType === 'Edit';

  return (
    <Modal
      title={`${isEdit ? 'Edit' : 'Create'} bonus reason`}
      opened={opened}
      onClose={onClose}
      centered
    >
      <AddBonusReasonForm
        reasonState={reasonState}
        onSubmit={isEdit ? onEdit : onCreate}
        isLoading={!opened || isLoading}
      />
    </Modal>
  );
}

AddBonusReasonModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  reasonState: PropTypes.object,
};

AddBonusReasonModal.defaultProps = {
  reasonState: {},
  isLoading: false,
};
