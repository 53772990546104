import { Flex, NumberInput, Radio, Select, Stack, Text } from '@mantine/core';
import React from 'react';
import PropTypes from 'prop-types';

const accruingTimeSelector = [
  { label: 'Days', value: 'days' },
  { label: 'Weeks', value: 'weeks' },
  { label: 'Months', value: 'Months' },
  { label: 'Years', value: 'years' },
];

const carryOverDataSelector = [
  { label: 'Up to...', value: 'up_to' },
  { label: 'Unlimited', value: 'unlimited' },
];

export default function AccrualOptions({ formValues, onChange }) {
  const optionsValue = formValues?.settings?.accrue_options;

  return (
    <Stack gap={0}>
      <Text fw={600} fz={14}>
        Accrual Options
      </Text>
      <AccrualOption
        onChange={(val) =>
          onChange('settings.accrue_options', {
            ...optionsValue,
            accruing_time: { ...optionsValue?.accruing_time, response: val },
          })
        }
        values={optionsValue ?? {}}
        optionName="accruing_time"
        noLabel="No, they begin accruing time immediately on hire"
        yesLabel="Yes, there is a waiting period"
        description="Is there a waiting period before new employees begin accruing time?"
        displayOnYes={
          <Stack gap={5} ml={10}>
            <Text fz="xs">Employees start accruing time...</Text>
            <Flex align="center" gap={5}>
              <NumberInput
                value={optionsValue?.accruing_time?.day ?? ''}
                onChange={(e) =>
                  onChange('settings.accrue_options', {
                    ...optionsValue,
                    accruing_time: { ...optionsValue?.accruing_time, day: e },
                  })
                }
                hideControls
                allowNegative={false}
                maxLength={3}
                w={50}
              />
              <Select
                value={optionsValue?.accruing_time?.period ?? ''}
                data={accruingTimeSelector}
                placeholder="Select"
                allowDeselect={false}
                onChange={(val) =>
                  onChange('settings.accrue_options', {
                    ...optionsValue,
                    accruing_time: {
                      ...optionsValue?.accruing_time,
                      period: val,
                    },
                  })
                }
              />
              after hire date
            </Flex>
          </Stack>
        }
      />
      <AccrualOption
        onChange={(val) =>
          onChange('settings.accrue_options', {
            ...optionsValue,
            cap_balance: { ...optionsValue?.cap_balance, response: val },
          })
        }
        values={optionsValue ?? {}}
        optionName="cap_balance"
        noLabel="No, they will continue to accrue time, regardless of balance"
        yesLabel="Yes, they stop accruing at a certain balance"
        description="Is there a cap on the balance an employee can accrue?"
        displayOnYes={
          <Stack gap={5} ml={10}>
            <Text fz="xs">
              Stop accruing time when an employee hits a balance of...
            </Text>
            <Flex align="center" gap={5}>
              <NumberInput
                value={optionsValue?.cap_balance?.day ?? ''}
                onChange={(e) =>
                  onChange('settings.accrue_options', {
                    ...optionsValue,
                    cap_balance: { ...optionsValue?.cap_balance, day: e },
                  })
                }
                hideControls
                allowNegative={false}
                maxLength={3}
                w={50}
                placeholder="days"
              />
              days
            </Flex>
          </Stack>
        }
      />
      <AccrualOption
        onChange={(val) =>
          onChange('settings.accrue_options', {
            ...optionsValue,
            carryover_date: { response: val },
          })
        }
        values={optionsValue ?? {}}
        optionName="carryover_date"
        noLabel="No, it’s “use it or lose it”"
        yesLabel="Yes, time can be carried over"
        description="Can unused time off be carried over after the carryover date? (You’ll set this date below)"
        displayOnYes={
          <Stack gap={5} ml={10}>
            <Text fz="xs">How much time can be carried over?</Text>
            <Flex align="center" gap={5}>
              <Select
                value={optionsValue?.carryover_date?.period ?? ''}
                data={carryOverDataSelector}
                placeholder="Select"
                allowDeselect={false}
                onChange={(val) =>
                  onChange('settings.accrue_options', {
                    ...optionsValue,
                    carryover_date: {
                      ...optionsValue?.carryover_date,
                      period: val,
                    },
                  })
                }
              />
              {optionsValue?.carryover_date?.period === 'up_to' && (
                <>
                  <NumberInput
                    value={optionsValue?.carryover_date?.day ?? ''}
                    onChange={(e) =>
                      onChange('settings.accrue_options', {
                        ...optionsValue,
                        carryover_date: {
                          ...optionsValue?.carryover_date,
                          day: e,
                        },
                      })
                    }
                    hideControls
                    allowNegative={false}
                    maxLength={3}
                    w={50}
                  />
                  days
                </>
              )}
            </Flex>
          </Stack>
        }
      />
    </Stack>
  );
}

AccrualOptions.propTypes = {
  formValues: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

function AccrualOption({
  onChange,
  values,
  optionName,
  noLabel,
  yesLabel,
  description,
  displayOnYes,
}) {
  return (
    <Stack>
      <Radio.Group
        description={description}
        mt="xs"
        onChange={onChange}
        value={values?.[optionName]?.response}
      >
        <Stack mt="xs" gap={5}>
          <Radio value="0" label={noLabel} />
          <Radio value="1" label={yesLabel} />
        </Stack>
      </Radio.Group>
      {values?.[optionName]?.response === '1' && displayOnYes}
    </Stack>
  );
}

AccrualOption.propTypes = {
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  optionName: PropTypes.string.isRequired,
  noLabel: PropTypes.string.isRequired,
  yesLabel: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  displayOnYes: PropTypes.node.isRequired,
};
