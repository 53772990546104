import { Modal } from '@mantine/core';
import { useLoadFileBlob } from 'api/hooks';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import { Loader } from 'components/Loader';
import { PDFPreview } from 'components/PDFPreview';
import PropTypes from 'prop-types';

export default function PDFPreviewModal({
  opened,
  onClose,
  fileId,
  isLoading,
}) {
  const {
    file,
    isLoading: isLoadingFileBlob,
    error,
  } = useLoadFileBlob(fileId, {
    enabled: !!(opened && fileId),
  });

  return (
    <Modal size="xl" opened={opened} onClose={onClose} centered>
      <EmptyDataMessage isVisible={!!error} message="Error on file load" />
      <Loader isLoading={isLoadingFileBlob || isLoading} />
      <PDFPreview file={file} isLoading={isLoading || isLoadingFileBlob} />
    </Modal>
  );
}

PDFPreviewModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fileId: PropTypes.number,
  isLoading: PropTypes.bool,
};

PDFPreviewModal.defaultProps = {
  isLoading: false,
  fileId: undefined,
};
