import { Draggable } from '@hello-pangea/dnd';
import { Button, Divider, Flex, Grid, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useToggle } from '@mantine/hooks';
import {
  IconEdit,
  IconGripVertical,
  IconPlus,
  IconTrash,
} from '@tabler/icons-react';
import { clearCache } from 'api/clearCache';
import {
  useCreateCompanyLink,
  useDeleteCompanyLink,
  useUpdateCompanyLink,
} from 'api/hooks';
import { ContextMenu } from 'components/ContextMenu';
import _ from 'lodash';
import { useNotifications } from 'notifications/hooks/useNotifications';
import PropTypes from 'prop-types';
import React from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

export default function CompanyLinkRow({ data, ...rest }) {
  const queryClient = useQueryClient();
  const { setNotifications } = useNotifications();
  const [rowMode, toggle] = useToggle(['view', 'edit']);

  const form = useForm({
    initialValues: {
      name: '',
      url: '',
    },
  });

  const { createCompanyLink, isLoading: isCreatingCompanyLink } =
    useCreateCompanyLink({
      onSuccess: () => {
        clearCache.onChangeCompanyLink(queryClient);
        setNotifications(['Company Link Created Successfully']);
        toggle('view');
        form.initialize({ name: '', url: '' });
      },
    });

  const onCreateLink = (payload) => {
    createCompanyLink({ ...payload, category_id: data?.id });
  };

  return (
    <div {...rest.droppableProps} ref={rest.innerRef}>
      {_.map(data?.links, (link, index) => (
        <React.Fragment key={link?.id}>
          <Draggable draggableId={String(link?.id)} index={index}>
            {(provided) => (
              <Grid
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                styles={{
                  inner: { margin: 0, width: '100%' },
                }}
                columns={9}
                align="center"
              >
                <Row data={link} rowType="Edit" />
              </Grid>
            )}
          </Draggable>
          <Divider />
        </React.Fragment>
      ))}
      {rest.placeholder}
      <Flex p={10}>
        {/* TODO: Refactor as component */}
        {rowMode === 'view' && (
          <Flex
            align="center"
            style={{ cursor: 'pointer' }}
            onClick={() => toggle('edit')}
          >
            <IconPlus size={15} /> Add Link
          </Flex>
        )}
        {rowMode === 'edit' && (
          <Grid styles={{ inner: { margin: 0 } }} columns={9} w="100%">
            <Grid.Col span={3}>
              <TextInput
                maw={200}
                placeholder="Link Name"
                {...form.getInputProps('name')}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <TextInput
                maw={300}
                placeholder="https://"
                {...form.getInputProps('url')}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <Flex gap={10} align="center" justify="flex-end">
                <Button
                  disabled={isCreatingCompanyLink}
                  onClick={form.onSubmit(onCreateLink)}
                >
                  Save
                </Button>
                <Button onClick={() => toggle('view')}>Cancel</Button>
              </Flex>
            </Grid.Col>
          </Grid>
        )}
      </Flex>
    </div>
  );
}

CompanyLinkRow.propTypes = {
  data: PropTypes.object.isRequired,
};

function Row({ data, rowType }) {
  const { setNotifications } = useNotifications();
  const queryClient = useQueryClient();
  const [rowMode, toggle] = useToggle(['view', 'edit']);

  const form = useForm({
    initialValues: {
      name: data?.name,
      url: data?.url,
    },
  });

  const { createCompanyLink, isLoading: isCreatingCompanyLink } =
    useCreateCompanyLink({
      onSuccess: () => {
        clearCache.onChangeCompanyLink(queryClient);
        setNotifications(['Company Link Created Successfully']);
        toggle('view');
        form.initialize({ name: '', url: '' });
      },
    });

  const { updateCompanyLink, isLoading: isUpdatingCompanyLink } =
    useUpdateCompanyLink({
      onSuccess: () => {
        clearCache.onChangeCompanyLink(queryClient);
        setNotifications(['Company Link Updated Successfully']);
        toggle('view');
      },
    });

  const { deleteCompanyLink } = useDeleteCompanyLink({
    onSuccess: () => {
      clearCache.onChangeCompanyLink(queryClient);
      setNotifications(['Company Link Deleted Successfully']);
    },
  });

  const onCreateLink = (payload) => {
    createCompanyLink({ ...payload, category_id: data?.id });
  };

  const onEditLink = (payload) => {
    updateCompanyLink({ linkId: data?.id, ...payload });
  };

  const menuItems = [
    {
      label: 'Edit',
      icon: IconEdit,
      onClick: () => toggle('edit'),
    },
    {
      label: 'Delete',
      icon: IconTrash,
      onClick: () => deleteCompanyLink(data?.id),
    },
  ];

  return rowMode === 'edit' ? (
    <>
      <Grid.Col span={3}>
        <TextInput
          maw={200}
          placeholder="Link Name"
          {...form.getInputProps('name')}
        />
      </Grid.Col>
      <Grid.Col span={3}>
        <TextInput
          maw={300}
          placeholder="https://"
          {...form.getInputProps('url')}
        />
      </Grid.Col>
      <Grid.Col span={3}>
        <Flex gap={10} align="center" justify="flex-end">
          <Button
            disabled={isCreatingCompanyLink || isUpdatingCompanyLink}
            onClick={form.onSubmit(
              rowType === 'Create' ? onCreateLink : onEditLink,
            )}
          >
            Save
          </Button>
          <Button onClick={() => toggle('view')}>Cancel</Button>
        </Flex>
      </Grid.Col>
    </>
  ) : (
    <>
      <Grid.Col span={3}>
        <Flex align="center">
          <IconGripVertical size={20} /> {data?.name}
        </Flex>
      </Grid.Col>
      <Grid.Col span={3}>
        <Link
          // handle external link
          target={
            !data?.url?.startsWith?.(window.location.origin)
              ? '_blank'
              : undefined
          }
          to={data?.url}
        >
          {data?.url}
        </Link>
      </Grid.Col>
      <Grid.Col span={3}>
        <Flex justify="end">
          <ContextMenu menuItems={menuItems} />
        </Flex>
      </Grid.Col>
    </>
  );
}

Row.propTypes = {
  data: PropTypes.object.isRequired,
  rowType: PropTypes.string.isRequired,
};
