import { IconPlus } from '@tabler/icons-react';
import PropTypes from 'prop-types';

export default function DropdownCloseButton({ onClose }) {
  return (
    <IconPlus
      style={{
        position: 'absolute',
        top: 3,
        right: 3,
        rotate: '45deg',
        cursor: 'pointer',
      }}
      stroke="2.5"
      color="var(--mantine-color-gray-6)"
      onClick={onClose}
    />
  );
}

DropdownCloseButton.propTypes = {
  onClose: PropTypes.func.isRequired,
};
