import {
  ActionIcon,
  Button,
  Flex,
  Paper,
  Pill,
  Select,
  Space,
  Table,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { useLoadLookups } from 'api/hooks';
import PropTypes from 'prop-types';
import { formatSelectorDataObj } from 'utils';
import { lookupsModels, surveyStatuses } from 'utils/constants';
import { useStateDisclosure } from 'utils/hooks';
import SurveyQuestionModal from '../modals/SurveyQuestionModal';

export default function SurveyEditForm({ survey, onSubmit, isLoading }) {
  const { lookups } = useLoadLookups({ models: [lookupsModels.survey] });

  const [opened, { open, close, state: modalState }] =
    useStateDisclosure(false);

  const form = useForm({
    initialValues: {
      name: survey?.name ?? '',
      questions: survey?.questions ?? [],
      type: survey?.type ? `${survey?.type}` : '',
      status: survey?.status ? `${survey?.status}` : surveyStatuses[0].value,
    },
  });

  const onAddQuestion = (question) => {
    if (modalState) {
      form.setFieldValue(`questions.${modalState.index}.title`, question.title);
      form.setFieldValue(
        `questions.${modalState.index}.field_type`,
        question.field_type,
      );
    } else {
      form.insertListItem('questions', question);
    }
    close();
  };

  const hasQuestions = form.values.questions?.length > 0;

  return (
    <>
      <SurveyQuestionModal
        opened={opened}
        onClose={close}
        onAddQuestion={onAddQuestion}
        question={modalState?.item}
      />
      <form onSubmit={form.onSubmit(onSubmit)} noValidate>
        <TextInput
          label="Survey Title"
          placeholder="Title"
          onChange={(event) =>
            form.setFieldValue('name', event.currentTarget.value)
          }
          value={form.values.name}
          error={form.errors.name}
          required
        />
        <Space h="md" />
        <Select
          label="Type"
          placeholder="Pick Type"
          allowDeselect={false}
          data={formatSelectorDataObj(lookups?.[lookupsModels.survey]?.types)}
          onChange={(val) => form.setFieldValue('type', val)}
          value={form.values.type}
        />
        <Select
          label="Status"
          placeholder="Pick status"
          data={surveyStatuses}
          onChange={(val) => form.setFieldValue('status', val)}
          value={form.values.status}
          allowDeselect={false}
        />
        <Paper shadow="xs" withBorder p="md" mt="md">
          {!hasQuestions && (
            <Flex justify="center" align="center" p="md">
              <Text fw={500} size="xl" c="dimmed">
                There are no questions added
              </Text>
            </Flex>
          )}
          {hasQuestions && (
            <Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Question</Table.Th>
                  <Table.Th>Type</Table.Th>
                  <Table.Th>Actions</Table.Th>
                </Table.Tr>
              </Table.Thead>

              <Table.Tbody>
                {form.values.questions.map((item, index) => (
                  <Table.Tr key={item.title}>
                    <Table.Td>{item.title}</Table.Td>
                    <Table.Td>
                      <Pill>{item.field_type.toUpperCase()}</Pill>
                    </Table.Td>
                    <Table.Td>
                      <Flex gap="md">
                        <ActionIcon
                          color="blue"
                          onClick={() => open({ item, index })}
                        >
                          <IconEdit size="1rem" />
                        </ActionIcon>
                        <ActionIcon
                          color="red"
                          onClick={() =>
                            form.removeListItem('questions', index)
                          }
                        >
                          <IconTrash size="1rem" />
                        </ActionIcon>
                      </Flex>
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          )}
        </Paper>
        <Flex align="center" justify="center" gap="md">
          <Button type="submit" mt="xl" loading={isLoading}>
            Submit
          </Button>
          <Button mt="xl" loading={isLoading} onClick={() => open()}>
            Add Question
          </Button>
        </Flex>
      </form>
    </>
  );
}

SurveyEditForm.propTypes = {
  onSubmit: PropTypes.func,
  survey: PropTypes.object,
  isLoading: PropTypes.bool,
};

SurveyEditForm.defaultProps = {
  onSubmit: () => {},
  isLoading: false,
  survey: null,
};
