import { Space, Tabs, Text } from '@mantine/core';
import { useState } from 'react';
import {
  AssetCategories,
  BonusReasons,
  CompensationChangeReason,
  Department,
  Division,
  EmployeeStatuses,
  JobTitle,
  Location,
  PaySchedule,
  TerminationReason,
} from './employee-profile-tabs';

const tabs = [
  { value: 'job-title', title: 'Job Title' },
  { value: 'department', title: 'Department' },
  { value: 'division', title: 'Division' },
  { value: 'location', title: 'Location' },
  { value: 'employee-statuses', title: 'Employee Statuses' },
  { value: 'bonus-reasons', title: 'Bonus Reasons' },
  { value: 'compensation-change-reason', title: 'Compensation Change Reason' },
  { value: 'pay-schedule', title: 'Pay Schedule' },
  { value: 'asset-categories', title: 'Asset Categories' },
  { value: 'termination-reasons', title: 'Termination Reasons' },
];

export default function EmployeeProfile() {
  const [selectedTab, setSelectedTab] = useState('job-title');

  return (
    <>
      <Space h="md" />
      <Tabs
        onChange={setSelectedTab}
        value={selectedTab}
        orientation="vertical"
        keepMounted={false}
      >
        <Tabs.List mr={15}>
          {tabs.map((tab) => (
            <Tabs.Tab
              w="100%"
              bg={selectedTab === tab.value ? 'blue' : undefined}
              c={selectedTab === tab.value ? 'white' : undefined}
              value={tab.value}
              key={tab.value}
            >
              <Text p={5}>{tab.title}</Text>
            </Tabs.Tab>
          ))}
        </Tabs.List>
        <Tabs.Panel value="job-title">
          <JobTitle />
        </Tabs.Panel>
        <Tabs.Panel value="department">
          <Department />
        </Tabs.Panel>
        <Tabs.Panel value="division">
          <Division />
        </Tabs.Panel>
        <Tabs.Panel value="location">
          <Location />
        </Tabs.Panel>
        <Tabs.Panel value="employee-statuses">
          <EmployeeStatuses />
        </Tabs.Panel>
        <Tabs.Panel value="bonus-reasons">
          <BonusReasons />
        </Tabs.Panel>
        <Tabs.Panel value="compensation-change-reason">
          <CompensationChangeReason />
        </Tabs.Panel>
        <Tabs.Panel value="pay-schedule">
          <PaySchedule />
        </Tabs.Panel>
        <Tabs.Panel value="asset-categories">
          <AssetCategories />
        </Tabs.Panel>
        <Tabs.Panel value="termination-reasons">
          <TerminationReason />
        </Tabs.Panel>
      </Tabs>
    </>
  );
}
