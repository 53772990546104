import { Table } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { ContextMenu } from 'components/ContextMenu';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

export default function HolidayRow({ data, onEdit, onDelete }) {
  const fromDate = moment(data?.date_from).format('MM-DD (dddd)');

  const toDate = moment(data?.date_to).format('MM-DD (dddd)');

  return (
    <Table.Tr>
      <Table.Td>{data?.name}</Table.Td>
      <Table.Td>
        {fromDate === toDate ? fromDate : `${fromDate} - ${toDate}`}
      </Table.Td>
      <Table.Td>
        {_.isEmpty(data?.filters) ? 'All employees' : 'Some employee'}
      </Table.Td>
      <Table.Td ta="right">
        <ContextMenu
          menuItems={[
            {
              label: 'Edit',
              icon: IconEdit,
              onClick: () => onEdit(data),
            },
            {
              label: 'Delete',
              icon: IconTrash,
              onClick: () => onDelete(data),
            },
          ]}
        />
      </Table.Td>
    </Table.Tr>
  );
}

HolidayRow.propTypes = {
  data: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
