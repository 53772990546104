import { Divider, Text } from '@mantine/core';
import React from 'react';
import _ from 'lodash';

import GlobalSearchItem from './GlobalSearchItem';

export default function GlobalSearchSection({ data }) {
  return _.keys(data).map((section) => (
    <React.Fragment key={section}>
      <Text c="gray" ml={10} fw={500}>
        {section}
      </Text>
      <Divider />
      {data[section].map((item) => (
        <GlobalSearchItem key={item.name} item={item} />
      ))}
    </React.Fragment>
  ));
}
