import { useAuthContext } from 'auth';
import PropTypes from 'prop-types';
import { requestStatuses } from 'utils/constants';
import AllRequests from '../components/AllRequests';

export default function SignatureTab({
  requestType,
  requestId,
  isRequestLoading,
  onPreview,
}) {
  const { user } = useAuthContext();

  return (
    <AllRequests
      onPreview={onPreview}
      requestType={requestType}
      requestId={requestId}
      isRequestLoading={isRequestLoading}
      payload={{
        Signature: {
          assignee_id: String(user?.id),
          status: requestStatuses.Pending,
        },
      }}
    />
  );
}

SignatureTab.propTypes = {
  onPreview: PropTypes.func.isRequired,
  requestType: PropTypes.string,
  requestId: PropTypes.string,
  isRequestLoading: PropTypes.bool,
};

SignatureTab.defaultProps = {
  requestType: '',
  isRequestLoading: false,
  requestId: '',
};
