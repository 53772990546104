/* eslint-disable react/jsx-props-no-spreading */
import { useForm } from '@mantine/form';
import React from 'react';
import { genericValidators } from 'utils';
import PropTypes from 'prop-types';
import { Button, Loader, Stack, TextInput } from '@mantine/core';

export default function JobInformationCommonForm({
  onSubmit,
  name,
  label,
  isLoading,
  type,
}) {
  const form = useForm({
    initialValues: {
      name: name ?? '',
    },
    validate: {
      name: (value) => genericValidators.notEmpty(value, 'Name is empty'),
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)} noValidate>
      <Stack gap={10}>
        <TextInput label={label} {...form.getInputProps('name')} />
        <Button disabled={isLoading} type="submit">
          {type === 'Edit' ? 'Submit' : 'Create'}
          {isLoading && <Loader ml={10} size={14} />}
        </Button>
      </Stack>
    </form>
  );
}

JobInformationCommonForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  isLoading: PropTypes.bool,
  type: PropTypes.string,
};

JobInformationCommonForm.defaultProps = {
  name: '',
  label: '',
  isLoading: false,
  type: 'Create',
};
