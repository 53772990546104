import { LoadingOverlay, Modal, Text, Button, Flex } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';
import {
  useTalentGroup,
  useUpdateTalentGroup,
  useDeleteTalentGroup,
} from 'api/hooks';
import PropTypes from 'prop-types';
import { TalentGroupForm } from 'components/TalentGroupForm';
import { PageWrapper } from 'components/PageWrapper';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { useQueryClient } from 'react-query';
import { useGlobalTabsContext } from 'globalTabs';
import { clearCache } from 'api/clearCache';
import { usePageTitle } from 'utils/hooks';

export default function EditTalentGroup({ params, globalTabName }) {
  const { groupId } = params;
  const { setNotifications } = useNotifications();
  const { onRemoveTab } = useGlobalTabsContext();
  const queryClient = useQueryClient();
  const [isModalOpened, { close: closeModal, open: openModal }] =
    useDisclosure(false);

  const navigate = useNavigate();
  const {
    talentGroupDetails,
    isLoading: isProfileLoading,
    error,
    isFetched,
  } = useTalentGroup(groupId);

  const { deleteTalentGroup, isLoading: isDeleteLoading } =
    useDeleteTalentGroup({
      onSuccess: () => {
        setNotifications(['The Talent Group has been successfully deleted']);
        clearCache.onTalentGroupChange(queryClient, { groupId });
        navigate('/hiring/talentGroups', { replace: true });
        onRemoveTab(globalTabName);
      },
    });
  const { updateTalentGroup, isLoading } = useUpdateTalentGroup({
    onSuccess: () => {
      setNotifications(['The Talent Group has been successfully updated']);
      navigate(`/hiring/talent-group/${groupId}`, { replace: true });
      clearCache.onTalentGroupChange(queryClient, { groupId });
      onRemoveTab(globalTabName);
    },
  });
  const submitForm = (values) => {
    updateTalentGroup({
      id: groupId,
      payload: {
        ...values,
      },
    });
  };

  const onDeleteClick = () => {
    openModal();
  };
  const onDelete = () => {
    deleteTalentGroup({ id: groupId });
  };

  usePageTitle(
    talentGroupDetails?.name
      ? `Edit Talent Group - ${talentGroupDetails?.name} - Hiring`
      : 'Edit Talent Group - Hiring',
    globalTabName,
  );

  return (
    <PageWrapper title="Edit Talent Group">
      <LoadingOverlay
        visible={isProfileLoading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      {talentGroupDetails && (
        <TalentGroupForm
          onSubmit={submitForm}
          talentGroup={talentGroupDetails}
          onDelete={onDeleteClick}
          isSubmitLoading={isLoading}
          isDeleteLoading={isDeleteLoading}
          enableFetch={isFetched}
        />
      )}

      <Modal
        opened={isModalOpened}
        onClose={closeModal}
        size="auto"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        title="Warning"
      >
        <Text>Are you sure you want to delete this talent group?</Text>
        <Flex mt={20} justify="center" align="center" gap="xl">
          <Button onClick={onDelete} variant="filled" color="red">
            Delete
          </Button>
          <Button onClick={closeModal} variant="outline">
            Cancel
          </Button>
        </Flex>
      </Modal>
      {error && <h3 style={{ textAlign: 'center' }}>Talent group Not Found</h3>}
    </PageWrapper>
  );
}

EditTalentGroup.propTypes = {
  params: PropTypes.object,
  globalTabName: PropTypes.string,
};

EditTalentGroup.defaultProps = {
  params: {},
  globalTabName: '',
};
