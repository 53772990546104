import { Accordion, Flex, Tabs, Text, rem } from '@mantine/core';
import {
  IconChecklist,
  IconDashboard,
  IconMail,
  IconRestore,
  IconSend,
  IconSignature,
  IconThumbUp,
} from '@tabler/icons-react';
import { useLoadRequest } from 'api/hooks';
import { usePermissions } from 'components/PermissionGuard/hooks';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { requestsCategories } from 'utils/constants';
import { usePageTitle } from 'utils/hooks';

import { PageWrapper } from '../../components/PageWrapper';
import {
  AssetsTab,
  CandidatesTab,
  CompensationTab,
  CompletedTab,
  EmploymentStatusTab,
  JobInfoTab,
  PerformanceTab,
  PromotionTab,
  SignatureTab,
  TimeOffTab,
} from './tabs';
import AllRequestTab from './tabs/AllRequestTab';
import SentRequestTab from './tabs/SentRequestTab';

const iconStyle = { width: rem(18), height: rem(18) };
const tabsSections = [
  {
    title: 'All Requests',
    value: 'all-requests',
    icon: IconMail,
    tabs: [{ title: 'Requests', value: 'all-requests' }],
  },
  {
    title: 'Feedback',
    value: 'feedback',
    icon: IconDashboard,
    tabs: [{ title: 'Performance', value: 'performance' }],
  },
  {
    title: 'Onboarding',
    value: 'onboarding',
    icon: IconRestore,
    tabs: [{ title: 'Candidates', value: 'candidates' }],
  },
  {
    title: 'Approvals',
    value: 'approvals',
    icon: IconThumbUp,
    tabs: [
      { title: 'Time Off', value: 'time-off' },
      { title: 'Assets', value: 'assets' },
      { title: 'Compensation', value: 'compensation' },
      { title: 'Employment Status', value: 'employment-status' },
      { title: 'Job Info', value: 'job-info' },
      { title: 'Promotion', value: 'promotion' },
    ],
  },
  {
    title: 'Completed',
    value: 'completed-requests',
    icon: IconChecklist,
    tabs: [{ title: 'Completed', value: 'completed-requests' }],
  },
  {
    title: 'Sent',
    value: 'sent-requests',
    icon: IconSend,
    tabs: [{ title: 'Requests', value: 'sent-requests' }],
  },
  {
    title: 'Signature',
    value: 'signature',
    icon: IconSignature,
    tabs: [{ title: 'Signature', value: 'signature' }],
  },
];

const tabs = tabsSections
  .map((section) => section.tabs.map((tab) => tab.value))
  .flat();

export default function Requests({ params, globalTabName }) {
  const { tabValue, requestType, requestId } = params;

  const { state } = useLocation();

  const navigate = useNavigate();

  const { permissions, hasPermission } = usePermissions();

  const navigateTo = (url, request) => {
    navigate(url, {
      state: state?.requestId ? { preloadedRequest: request } : null,
    });
  };

  const openPreview = (request) => {
    if (requestsCategories[request.category]) {
      navigateTo(
        `/requests/${tabValue}/${requestsCategories[request.category]}/${
          request?.id
        }`,
        request,
      );
    }
  };

  const { isLoading: isRequestLoading, isFetching: isRequestRefetching } =
    useLoadRequest(
      {
        id: state?.requestId,
        canManageEmployee: hasPermission(permissions.canManageEmployee),
      },
      {
        enabled: !!state?.requestId,
        onSuccess: (data) => {
          const preview = {
            [requestsCategories.TimeoffRecords]: openPreview,
            [requestsCategories.Performance]: openPreview,
            [requestsCategories.Signature]: openPreview,
          };
          preview?.[requestsCategories[data?.category]]?.(data);
        },
      },
    );

  useEffect(() => {
    if (!tabValue || !tabs.includes(tabValue)) {
      navigate('/requests/all-requests', { replace: true });
    }
  }, [tabValue, navigate]);

  usePageTitle(
    !requestId
      ? `Inbox - ${
          tabValue === 'all-requests' ? 'Requests' : _.upperFirst(tabValue)
        }`
      : '',
    globalTabName,
    requestType,
  );

  return (
    <PageWrapper title="Requests">
      <Tabs
        defaultValue="time-off"
        value={tabValue}
        onChange={(value) => navigate(`/requests/${value}`)}
        keepMounted={false}
        orientation="vertical"
        variant="pills"
        pos="relative"
      >
        <Flex
          pos="sticky"
          top={67}
          h="max-content"
          direction="column"
          gap={2}
          w={250}
        >
          {tabValue &&
            tabsSections.map((section) => {
              const Icon = section.icon;
              const isActive = section.tabs.some(
                (tab) => tab.value === tabValue,
              );
              return (
                <Accordion
                  key={section.value}
                  defaultValue={isActive ? section.value : ''}
                  variant="contained"
                >
                  <Accordion.Item value={section.value}>
                    <Accordion.Control icon={<Icon style={iconStyle} />}>
                      <Text fw={isActive ? 700 : 500}> {section.title} </Text>
                    </Accordion.Control>
                    <Accordion.Panel>
                      <Tabs.List>
                        {section.tabs.map((tab) => (
                          <Tabs.Tab key={tab.value} value={tab.value}>
                            {tab.title}
                          </Tabs.Tab>
                        ))}
                      </Tabs.List>
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>
              );
            })}
        </Flex>
        <Tabs.Panel h="100%" value="time-off" p="md">
          <TimeOffTab timeOffId={requestId} onPreview={openPreview} />
        </Tabs.Panel>
        <Tabs.Panel h="100%" value="performance" p="md">
          <PerformanceTab performanceId={requestId} onPreview={openPreview} />
        </Tabs.Panel>
        <Tabs.Panel h="100%" value="candidates" p="md">
          <CandidatesTab />
        </Tabs.Panel>
        <Tabs.Panel h="100%" value="assets" p="md">
          <AssetsTab />
        </Tabs.Panel>
        <Tabs.Panel h="100%" value="compensation" p="md">
          <CompensationTab />
        </Tabs.Panel>
        <Tabs.Panel h="100%" value="employment-status" p="md">
          <EmploymentStatusTab />
        </Tabs.Panel>
        <Tabs.Panel h="100%" value="job-info" p="md">
          <JobInfoTab />
        </Tabs.Panel>
        <Tabs.Panel h="100%" value="promotion" p="md">
          <PromotionTab />
        </Tabs.Panel>
        <Tabs.Panel h="100%" value="all-requests" p="md">
          <AllRequestTab
            onPreview={openPreview}
            requestType={requestType}
            requestId={requestId}
            isRequestLoading={isRequestLoading || isRequestRefetching}
          />
        </Tabs.Panel>
        <Tabs.Panel h="100%" value="sent-requests" p="md">
          <SentRequestTab
            onPreview={openPreview}
            requestType={requestType}
            requestId={requestId}
            isRequestLoading={isRequestLoading || isRequestRefetching}
          />
        </Tabs.Panel>
        <Tabs.Panel h="100%" value="completed-requests" p="md">
          <CompletedTab requestId={requestId} onPreview={openPreview} />
        </Tabs.Panel>
        <Tabs.Panel h="100%" value="signature" p="md">
          <SignatureTab
            requestId={requestId}
            requestType={requestType}
            onPreview={openPreview}
          />
        </Tabs.Panel>
      </Tabs>
    </PageWrapper>
  );
}

Requests.propTypes = {
  params: PropTypes.object,
  globalTabName: PropTypes.string,
};

Requests.defaultProps = {
  params: {},
  globalTabName: '',
};
