import { Stack } from '@mantine/core';
import { usePolicyTypeSelect } from 'components/TimeOff/hooks';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Period from '../Period';

export default function Quarterly({ formValues, onChange, scheduleType }) {
  const quarterly = usePolicyTypeSelect({
    onChange,
    formValues,
    scheduleType,
  });

  const months = moment.months();

  return (
    <Stack>
      <Period
        values={quarterly?.firstQuarter ?? {}}
        months={months.slice(0, 3)}
        valuePath="quarterly.firstQuarter"
        onChange={onChange}
      />
      <Period
        values={quarterly?.secondQuarter ?? {}}
        months={months.slice(3, 6)}
        valuePath="quarterly.secondQuarter"
        onChange={onChange}
      />
      <Period
        values={quarterly?.thirdQuarter ?? {}}
        months={months.slice(6, 9)}
        valuePath="quarterly.thirdQuarter"
        onChange={onChange}
      />
      <Period
        values={quarterly?.fourthQuarter ?? {}}
        months={months.slice(9, 12)}
        valuePath="quarterly.fourthQuarter"
        onChange={onChange}
        isLast
      />
    </Stack>
  );
}

Quarterly.propTypes = {
  formValues: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  scheduleType: PropTypes.string.isRequired,
};
