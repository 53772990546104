import {
  Box,
  Button,
  Flex,
  NumberInput,
  Select,
  Stack,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconCurrencyDollar } from '@tabler/icons-react';
import { DateFormInput } from 'components/DateFormInput';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  formatSelectorData,
  formatSelectorDataObj,
  genericValidators,
} from 'utils';

export default function CompensationRecordForm({
  onSubmit,
  schedules,
  metaData,
  changeReasons,
  isLoading,
  compensationRecordState,
}) {
  const form = useForm({
    initialValues: {
      effective_date: compensationRecordState?.effective_date ?? '',
      pay_schedule_id:
        _.toString(compensationRecordState?.pay_schedule_id) ?? '',
      pay_type: _.toString(compensationRecordState?.pay_type) ?? '',
      reason_id: _.toString(compensationRecordState?.reason_id) ?? '',
      comment: _.toString(compensationRecordState?.comment) ?? '',
      pay_rate: _.toString(compensationRecordState?.pay_rate) ?? '',
      pay_rate_frequency:
        _.toString(compensationRecordState?.pay_rate_frequency) ?? '',
    },
    validate: {
      effective_date: (val) => genericValidators.notEmpty(val),
      pay_schedule_id: (val) => genericValidators.notEmpty(val),
      pay_type: (val) => genericValidators.notEmpty(val),
      reason_id: (val) => genericValidators.notEmpty(val),
      pay_rate: (val, formValues) =>
        // 1,2 - ids of Salary and Hourly types
        ['1', '2'].includes(formValues.pay_type)
          ? genericValidators.notEmpty(val)
          : null,
      pay_rate_frequency: (val, formValues) =>
        formValues.pay_type === '1' ? genericValidators.notEmpty(val) : null,
    },
  });

  // allow for Salary and Hourly compensation types
  const canAllowPayRate =
    form.values.pay_type === '1' || form.values.pay_type === '2';

  const canAllowFrequency = form.values.pay_type === '1';

  return (
    <form onSubmit={form.onSubmit(onSubmit)} noValidate>
      <Stack gap={10}>
        <DateFormInput
          {...form.getInputProps('effective_date')}
          field={{ displayName: 'Effective Date' }}
          onChange={(val) =>
            form.setFieldValue(
              'effective_date',
              moment(val).format('YYYY-MM-DD'),
            )
          }
          required
        />
        <Select
          label="Pay Schedule"
          placeholder="Select pay schedule"
          data={formatSelectorData(schedules, { value: 'id', label: 'name' })}
          {...form.getInputProps('pay_schedule_id')}
          allowDeselect={false}
          required
        />
        <Select
          label="Pay Type"
          placeholder="Select pay type"
          data={formatSelectorDataObj(
            metaData?.EmployeeCompensation?.compensation_types,
          )}
          {...form.getInputProps('pay_type')}
          allowDeselect={false}
          required
        />

        <Flex gap={10} w="100%">
          {canAllowPayRate && (
            <>
              <NumberInput
                required
                w="100%"
                label="Pay Rate"
                placeholder="Enter Pay Rate"
                hideControls
                prefix="$"
                rightSection={<IconCurrencyDollar />}
                allowNegative={false}
                {...form.getInputProps('pay_rate')}
              />
              {form.values.pay_type === '2' && (
                <Box mt="auto" mb={8} style={{ whiteSpace: 'nowrap' }}>
                  per hour
                </Box>
              )}
            </>
          )}
          {canAllowFrequency && (
            <>
              <Box
                mt="auto"
                mb={
                  form.errors?.pay_rate || form.errors?.pay_rate_frequency
                    ? 25
                    : 8
                }
              >
                per
              </Box>
              <Select
                styles={{ label: { whiteSpace: 'nowrap' } }}
                label="Pay Rate Frequency"
                placeholder="Frequency"
                data={formatSelectorDataObj(
                  metaData?.EmployeeCompensation?.pay_rate_frequencies,
                )}
                {...form.getInputProps('pay_rate_frequency')}
                allowDeselect={false}
                required
              />
            </>
          )}
        </Flex>

        <Select
          data={formatSelectorData(changeReasons, {
            value: 'id',
            label: 'name',
          })}
          label="Change Reason"
          placeholder="Select Change Reason"
          {...form.getInputProps('reason_id')}
          allowDeselect={false}
          required
        />
        <Textarea
          label="Comment"
          minRows={4}
          autosize
          {...form.getInputProps('comment')}
        />
        <Button type="submit" disabled={isLoading}>
          Submit
        </Button>
      </Stack>
    </form>
  );
}

CompensationRecordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  schedules: PropTypes.array.isRequired,
  changeReasons: PropTypes.array.isRequired,
  metaData: PropTypes.object,
  isLoading: PropTypes.bool,
  compensationRecordState: PropTypes.object,
};

CompensationRecordForm.defaultProps = {
  metaData: {},
  isLoading: false,
  compensationRecordState: {},
};
