import {
  Box,
  Code,
  Paper,
  Stack,
  TextInput,
  UnstyledButton,
  rem,
} from '@mantine/core';
import {
  getHotkeyHandler,
  useClickOutside,
  useDisclosure,
} from '@mantine/hooks';
import { IconSearch } from '@tabler/icons-react';
import { mockData } from 'api/mockData';
import { useEffect, useState } from 'react';
import { tabNames } from 'utils/constants';
import { useTabNavigate } from 'utils/hooks';
import GlobalSearchList from './GlobalSearchList';
import styles from './style.module.css';

export default function GlobalSearch() {
  const [opened, handlers] = useDisclosure(false);
  const ref = useClickOutside(() => handlers.close());

  const [search, setSearch] = useState('');
  const [data, setData] = useState(mockData.globalSearch);

  const navigate = useTabNavigate();

  const onPressEnter = () => {
    navigate(`/global-search/${search}`, { tabName: tabNames.globalSearch });
    setSearch('');
  };

  useEffect(() => {
    setData(mockData.globalSearch.filter((item) => item.name.includes(search)));
  }, [search]);

  return (
    <Box
      pos="relative"
      style={{
        overflow: opened ? 'visible' : 'hidden',
      }}
    >
      <TextInput
        placeholder="Search"
        size="xs"
        leftSection={
          <IconSearch
            style={{ width: rem(12), height: rem(12) }}
            stroke={1.5}
          />
        }
        rightSectionWidth={55}
        rightSection={<Code>Enter</Code>}
        styles={{ section: { pointerEvents: 'none' } }}
        my="auto"
        onClick={() => handlers.toggle()}
        value={search}
        onChange={(e) => {
          setSearch(e.currentTarget.value);
        }}
        onKeyDown={getHotkeyHandler([['Enter', onPressEnter]])}
      />
      <Paper
        pos="absolute"
        w="400px"
        right={0}
        top={rem(40)}
        ref={ref}
        styles={{ root: { overflow: 'hidden', zIndex: 1000 } }}
      >
        <Stack gap={0}>
          <GlobalSearchList data={data} opened={opened} />
          <UnstyledButton
            ta="center"
            py={10}
            flex={1}
            c="white"
            fw={500}
            className={styles.seeAll__button}
            onClick={() => {
              navigate(`/global-search/${search}`, {
                tabName: tabNames.globalSearch,
              });
              handlers.toggle();
            }}
            disabled={data.length === 0}
          >
            View all results
          </UnstyledButton>
        </Stack>
      </Paper>
    </Box>
  );
}
