import { Button, Divider, Flex, Space, Stack, Title } from '@mantine/core';
import {
  IconChevronDown,
  IconList,
  IconMail,
  IconMailCancel,
  IconPencil,
} from '@tabler/icons-react';
import { CreateCandidateOfferModal } from 'CreateCandidateOfferModal';
import { clearCache } from 'api/clearCache';
import {
  useCancelCandidateOffer,
  useCreateCandidateOffer,
  useUpdateCandidateOffer,
} from 'api/hooks';
import { AddButton } from 'components/Buttons';
import { CandidateOfferSteps } from 'components/CandidateOfferSteps';
import { OfferDetails } from 'components/OfferDetails';
import { PreviewEmailTemplate } from 'components/PreviewEmailTemplate';
import { PreviousOffersMenu } from 'components/PreviousOffersMenu';
import _ from 'lodash';
import moment from 'moment';
import { useNotifications } from 'notifications/hooks/useNotifications';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useCandidateActiveOffer } from 'utils/api';
import { offerStatuses } from 'utils/constants';
import { useStateDisclosure, useTabNavigate } from 'utils/hooks';
import { CandidateManager } from '../CandidateManager';

export default function CandidateOffer({
  candidateDetails,
  leaveTab,
  jobId,
  offerTemplateProps,
  currentTab,
}) {
  const {
    openWithOfferModal,
    setOpenWithOfferModal,
    offerTemplateId,
    setOfferTemplateId,
  } = offerTemplateProps;

  const queryClient = useQueryClient();

  const navigate = useTabNavigate();

  const { currentOffer, canCreateNewOffer, previousSignedOffers } =
    useCandidateActiveOffer(candidateDetails?.offers ?? []);

  const [previewOfferTemplate, setPreviewOfferTemplate] = useState(null);

  const { setNotifications } = useNotifications();
  const [
    openedTemplatePreview,
    {
      open: openTemplatePreview,
      close: closeTemplatePreview,
      state: templatePreviewState,
    },
  ] = useStateDisclosure(false);

  const [
    openedOfferModal,
    { open: openOfferModal, close: closeOfferModal, state: offerModalState },
  ] = useStateDisclosure(false);

  const { createCandidateOffer, isLoading: isCandidateOfferCreating } =
    useCreateCandidateOffer({
      onSuccess: () => {
        setNotifications(['Offer created successfully']);
        closeTemplatePreview();
        clearCache.onChangeCandidateOffer(queryClient, {
          candidateId: candidateDetails?.id,
        });
      },
    });

  const onCloseCandidateOfferModal = () => {
    closeOfferModal();
    setOpenWithOfferModal(false);
  };

  const { cancelCandidateOffer, isLoading: isCanceling } =
    useCancelCandidateOffer({
      onSuccess: () => {
        setOfferTemplateId(null);
        onCloseCandidateOfferModal();
        closeTemplatePreview();
        setPreviewOfferTemplate(null);
        clearCache.onChangeCandidateOffer(queryClient, {
          candidateId: candidateDetails?.id,
        });
      },
    });

  const { updateCandidateOffer, isLoading: isCandidateOfferUpdating } =
    useUpdateCandidateOffer({
      onSuccess: () => {
        setNotifications(['Offer revised successfully']);
        closeTemplatePreview();
        clearCache.onChangeCandidateOffer(queryClient, {
          candidateId: candidateDetails?.id,
        });
      },
    });

  useEffect(() => {
    setPreviewOfferTemplate(canCreateNewOffer ? {} : currentOffer);
  }, [currentOffer, canCreateNewOffer]);

  useEffect(() => {
    if (!_.isEmpty(currentOffer) && !canCreateNewOffer) {
      setOfferTemplateId(null);
    }
  }, [currentOffer, setOfferTemplateId, canCreateNewOffer]);

  const onSubmitCandidateOffer = () => {
    const data = {
      ...previewOfferTemplate,
      job_title_id: previewOfferTemplate?.job_information?.id,
      pay_frequency: previewOfferTemplate?.pay_frequency?.value,
      contact_id: previewOfferTemplate?.contact?.id,
      job_id: jobId,
      expiry_date: moment(previewOfferTemplate?.expiry_date).format(
        'YYYY-MM-DD',
      ),
      start_date: moment(previewOfferTemplate?.start_date).format('YYYY-MM-DD'),
    };

    if (offerTemplateId === null) {
      updateCandidateOffer({ id: currentOffer?.id, ...data });
    } else {
      createCandidateOffer({
        id: candidateDetails?.id,
        ...data,
      });
    }
  };

  useEffect(() => {
    if (
      _.isEmpty(currentOffer) &&
      _.isEmpty(offerTemplateId) &&
      currentTab === 'candidateOffer'
    ) {
      leaveTab();
    }
  }, [currentOffer, leaveTab, offerTemplateId, currentTab]);

  return (
    <Stack>
      <PreviewEmailTemplate
        opened={openedTemplatePreview}
        onClose={closeTemplatePreview}
        offer={
          _.isEmpty(previewOfferTemplate)
            ? currentOffer ?? {}
            : previewOfferTemplate
        }
        candidateDetails={candidateDetails}
        onBack={() => {
          closeTemplatePreview();
          openOfferModal({ withPreviewOfferTemplate: true });
        }}
        onSubmit={onSubmitCandidateOffer}
        onCancelOffer={() => cancelCandidateOffer(currentOffer?.id)}
        readOnly={templatePreviewState?.readOnly}
        isLoading={
          isCandidateOfferCreating || isCandidateOfferUpdating || isCanceling
        }
      />
      <CreateCandidateOfferModal
        opened={openWithOfferModal || openedOfferModal}
        onClose={onCloseCandidateOfferModal}
        offerState={{
          modalType: offerTemplateId === null ? 'Edit' : 'Create',
          ...(canCreateNewOffer && !offerModalState?.withPreviewOfferTemplate
            ? {}
            : previewOfferTemplate),
          templateId: offerTemplateId,
        }}
        onSubmit={(data) => {
          setPreviewOfferTemplate(data);
          openTemplatePreview();
          onCloseCandidateOfferModal();
        }}
        onCancelOffer={() => cancelCandidateOffer(currentOffer?.id)}
        meta={candidateDetails?.offer_meta}
      />
      <Space h="md" />
      <Flex w="100%" gap={5}>
        <AddButton
          onClick={() =>
            navigate(
              `/offer-preview/jobId/${jobId}/offer/${currentOffer?.id}/candidate/${candidateDetails?.id}`,
            )
          }
          rightSection={<IconMail size={18} />}
          disabled={_.isEmpty(currentOffer)}
        >
          View Offer
        </AddButton>
        <AddButton
          onClick={() =>
            openOfferModal({
              withPreviewOfferTemplate: Boolean(offerTemplateId),
            })
          }
          rightSection={<IconPencil size={18} />}
          disabled={!offerTemplateId && canCreateNewOffer}
        >
          Revise Offer
        </AddButton>
        <Flex flex={1} justify="flex-end" align="center" gap={10}>
          {!_.isEmpty(previousSignedOffers) && (
            <PreviousOffersMenu
              label={
                <Button rightSection={<IconChevronDown />}>
                  Previous Offers
                </Button>
              }
              offers={previousSignedOffers}
              candidateDetails={candidateDetails}
            />
          )}
          <AddButton
            onClick={() => cancelCandidateOffer(currentOffer?.id)}
            rightSection={<IconMailCancel size={18} />}
            disabled={
              currentOffer?.status?.id !== offerStatuses.Sent || isCanceling
            }
          >
            Cancel Offer
          </AddButton>
        </Flex>
      </Flex>

      <CandidateOfferSteps
        currentOffer={currentOffer}
        candidateInfo={candidateDetails}
      />

      <Flex align="center" gap={5} mt={5}>
        <IconList />
        <Title size={20} fw={600}>
          Offer Details
        </Title>
      </Flex>
      <Divider />
      <Flex justify="space-between" w="100%">
        {!_.isEmpty(currentOffer) && (
          <OfferDetails
            offer={currentOffer}
            candidateDetails={candidateDetails}
          />
        )}

        <CandidateManager
          jobId={jobId}
          candidateDetails={candidateDetails}
          offerTemplateProps={offerTemplateProps}
        />
      </Flex>
    </Stack>
  );
}

CandidateOffer.propTypes = {
  candidateDetails: PropTypes.object.isRequired,
  leaveTab: PropTypes.func,
  jobId: PropTypes.string.isRequired,
  offerTemplateProps: PropTypes.object,
  currentTab: PropTypes.string.isRequired,
};

CandidateOffer.defaultProps = {
  leaveTab: () => {},
  offerTemplateProps: {},
};
