import { TextInput, Button } from '@mantine/core';
import PropTypes from 'prop-types';
import { useForm } from '@mantine/form';
import { genericValidators } from 'utils';

export default function TimeOffCategoryForm({ category, onSubmit, isLoading }) {
  const form = useForm({
    initialValues: {
      name: category?.name ?? '',
    },
    validate: {
      name: (value) =>
        genericValidators.notEmpty(value, 'Category name is empty') ||
        genericValidators.lessThanOrEqualTo(
          value,
          50,
          'Category name must be less than 50 chars',
        ),
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)} noValidate>
      <TextInput
        label="Category Name"
        placeholder="Category Name"
        onChange={(event) =>
          form.setFieldValue('name', event.currentTarget.value)
        }
        value={form.values.name}
        error={form.errors.name}
        required
      />
      <Button type="submit" fullWidth mt="xl" loading={isLoading}>
        Submit
      </Button>
    </form>
  );
}

TimeOffCategoryForm.propTypes = {
  onSubmit: PropTypes.func,
  category: PropTypes.object,
  isLoading: PropTypes.bool,
};

TimeOffCategoryForm.defaultProps = {
  onSubmit: () => {},
  isLoading: false,
  category: null,
};
