import { Button, Select, TextInput, Space } from '@mantine/core';
import PropTypes from 'prop-types';
import { useForm } from '@mantine/form';
import { requestTypes } from 'utils/constants';
import { DateFormInput } from 'components/DateFormInput';

export default function RequestForm({ onSubmit, isLoading }) {
  const form = useForm({
    initialValues: {
      name: '',
      type: 'assets',
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)} noValidate>
      <Select
        label="Type"
        placeholder="Pick Type"
        data={requestTypes}
        onChange={(val) => form.setFieldValue('type', val)}
        value={form.values.type}
        allowDeselect={false}
      />
      {form.values.type === 'assets' && <RequestAssetsFields form={form} />}
      {form.values.type === 'compensation' && (
        <RequestCompensationFields form={form} />
      )}
      {form.values.type === 'status' && (
        <RequestEmploymentStatusFields form={form} />
      )}
      {form.values.type === 'promotion' && (
        <RequestPromotionFields form={form} />
      )}
      {form.values.type === 'jobInfo' && <RequestJobInfoFields form={form} />}
      <Button type="submit" fullWidth mt="xl" loading={isLoading}>
        Submit
      </Button>
    </form>
  );
}

RequestForm.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};

RequestForm.defaultProps = {
  onSubmit: () => {},
  isLoading: false,
};

function RequestAssetsFields({ form }) {
  return (
    <>
      <Space h="md" />
      <TextInput
        label="Asset Name"
        placeholder="Name"
        onChange={(event) =>
          form.setFieldValue('name', event.currentTarget.value)
        }
        value={form.values.name}
        error={form.errors.name}
        required
      />
      <Space h="md" />
      <DateFormInput
        field={{
          displayName: 'Date Assign',
          placeholder: 'Select Assign Date',
        }}
        onChange={(val) => {
          form.setFieldValue('assignDate', val);
        }}
        error={form.errors.assignDate}
        value={form.values.assignDate}
      />
      <Space h="md" />
      <DateFormInput
        field={{
          displayName: 'Date Returned',
          placeholder: 'Select Return Date',
        }}
        onChange={(val) => {
          form.setFieldValue('returnDate', val);
        }}
        error={form.errors.returnDate}
        value={form.values.returnDate}
      />
    </>
  );
}

RequestAssetsFields.propTypes = {
  form: PropTypes.object.isRequired,
};

function RequestCompensationFields({ form }) {
  return (
    <>
      <Space h="md" />
      <Select
        label="Compensation Type"
        placeholder="Pick Compensation Type"
        data={[
          { value: 'paternal', label: 'Paternal compensation' },
          { value: 'maternal', label: 'Maternal compensation' },
        ]}
        onChange={(val) => form.setFieldValue('compensationType', val)}
        value={form.values.compensationType}
      />
      <Space h="md" />
      <TextInput
        label="Compensation Description"
        placeholder="Description"
        onChange={(event) =>
          form.setFieldValue(
            'compensationDescription',
            event.currentTarget.value,
          )
        }
        value={form.values.compensationDescription}
        error={form.errors.compensationDescription}
        required
      />
      <Space h="md" />
      <DateFormInput
        field={{
          displayName: 'Compensation Date',
          placeholder: 'Select Compensation Date',
        }}
        onChange={(val) => {
          form.setFieldValue('compensationDate', val);
        }}
        error={form.errors.compensationDate}
        value={form.values.compensationDate}
      />
    </>
  );
}

RequestCompensationFields.propTypes = {
  form: PropTypes.object.isRequired,
};

function RequestEmploymentStatusFields({ form }) {
  return (
    <>
      <Space h="md" />
      <Select
        label="Employment Status"
        placeholder="Pick Employment Status"
        data={[
          { value: 'unemployed', label: 'Unemployed' },
          { value: 'employed', label: 'Employed' },
          { value: 'other', label: 'Other' },
        ]}
        onChange={(val) => form.setFieldValue('employmentStatus', val)}
        value={form.values.employmentStatus}
      />
      <Space h="md" />

      <TextInput
        label="Description"
        placeholder="Description"
        onChange={(event) =>
          form.setFieldValue(
            'employmentStatusDescription',
            event.currentTarget.value,
          )
        }
        value={form.values.employmentStatusDescription}
        error={form.errors.employmentStatusDescription}
        required
      />
      <Space h="md" />

      <DateFormInput
        field={{
          displayName: 'Employment Status Date',
          placeholder: 'Select Employment Status Date',
        }}
        onChange={(val) => {
          form.setFieldValue('employmentStatusDate', val);
        }}
        error={form.errors.employmentStatusDate}
        value={form.values.employmentStatusDate}
      />
    </>
  );
}

RequestEmploymentStatusFields.propTypes = {
  form: PropTypes.object.isRequired,
};

function RequestPromotionFields({ form }) {
  return (
    <>
      <Space h="md" />
      <Select
        label="Position"
        placeholder="Pick Position"
        data={[
          { value: 'intern', label: 'Intern' },
          { value: 'junior', label: 'Junior' },
          { value: 'middle', label: 'Middle' },
          { value: 'senior', label: 'Senior' },
          { value: 'lead', label: 'Lead' },
        ]}
        onChange={(val) => form.setFieldValue('employeePosition', val)}
        value={form.values.employeePosition}
      />
      <Space h="md" />
      <TextInput
        label="Description"
        placeholder="Description"
        onChange={(event) =>
          form.setFieldValue(
            'employmentPromotionDescription',
            event.currentTarget.value,
          )
        }
        value={form.values.employmentPromotionDescription}
        error={form.errors.employmentPromotionDescription}
        required
      />
      <Space h="md" />
      <DateFormInput
        field={{
          displayName: 'Promotion Date',
          placeholder: 'Select Promotion Date',
        }}
        onChange={(val) => {
          form.setFieldValue('promotionDate', val);
        }}
        error={form.errors.promotionDate}
        value={form.values.promotionDate}
      />
    </>
  );
}

RequestPromotionFields.propTypes = {
  form: PropTypes.object.isRequired,
};

function RequestJobInfoFields({ form }) {
  return (
    <>
      <Space h="md" />
      <TextInput
        label="Title"
        placeholder="Job Title"
        onChange={(event) =>
          form.setFieldValue('jobTitle', event.currentTarget.value)
        }
        value={form.values.jobTitle}
        error={form.errors.jobTitle}
        required
      />
      <Space h="md" />
      <DateFormInput
        field={{
          displayName: 'Hire Date',
          placeholder: 'Select Hire Date',
        }}
        onChange={(val) => {
          form.setFieldValue('hireDate', val);
        }}
        error={form.errors.hireDate}
        value={form.values.hireDate}
      />
    </>
  );
}

RequestJobInfoFields.propTypes = {
  form: PropTypes.object.isRequired,
};
