import {
  Box,
  Button,
  Code,
  Divider,
  FileInput,
  Flex,
  Modal,
  Stack,
  Text,
  rem,
} from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { UploadFile } from 'components/UploadFile';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';
import { acceptTypes } from 'utils/constants';
import { useFileSizeControl } from 'utils/hooks';
import { canvasPreview } from './CanvasPreview';
import styles from './styles.module.css';

// in mb
const imageMaxSize = 2;

export default function ImageCropModal({
  opened,
  onClose,
  url,
  onSave,
  onSaveEmptyImage,
  isLoading,
}) {
  const [crop, setCrop] = useState({
    x: 0,
    y: 0,
    width: 100,
    height: 100,
    unit: 'px',
  });
  const [imgSrc, setImgSrc] = useState(url);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [isConverting, setIsConverting] = useState(false);

  const onSelectFile = useCallback((file) => {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setCrop();
        setImgSrc(reader.result?.toString() || '');
      });
      reader.readAsDataURL(file);
    }
  }, []);

  const {
    setFile: validateFile,
    error,
    displayError,
  } = useFileSizeControl({
    maxFileSize: imageMaxSize,
    onSelectFile,
  });

  useEffect(() => {
    if (
      crop?.width &&
      crop?.height &&
      imgRef.current &&
      previewCanvasRef.current
    ) {
      canvasPreview(imgRef.current, previewCanvasRef.current, crop);
    }
  }, [crop, imgSrc]);

  const onSaveClick = async () => {
    const image = imgRef.current;

    const previewCanvas = previewCanvasRef.current;

    if (!image || !previewCanvas || !crop) {
      throw new Error('Crop canvas does not exist');
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      crop.width * scaleX,
      crop.height * scaleY,
    );

    const ctx = offscreen.getContext('2d');
    if (!ctx) {
      throw new Error('No 2d context');
    }

    // for png with transparent bg
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, offscreen.width, offscreen.height);

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height,
    );

    setIsConverting(true);
    const blob = await offscreen
      .convertToBlob({
        type: 'image/jpeg',
        quality: 0.75,
      })
      .finally(() => setIsConverting(false));

    onSave(blob);
  };

  useEffect(() => {
    document.onpaste = (evt) => {
      const dT = evt.clipboardData || window.clipboardData;
      const file = dT.files[0];
      if (
        file &&
        acceptTypes.currentValidImages.includes(file?.type?.split('/')[1])
      ) {
        validateFile(file);
      }
    };
  }, [validateFile]);

  return (
    <Modal size="2xl" opened={opened} onClose={onClose} centered>
      <Text c="red" mb={10}>
        {error}
      </Text>
      <Flex gap={30}>
        {!imgSrc && (
          <Stack>
            <UploadFile
              selectedFile={null}
              setSelectedFile={onSelectFile}
              accept={acceptTypes.currentValidImages}
              maxFileSize={imageMaxSize}
              onReject={displayError}
            />
            <Button
              disabled={isLoading || isConverting}
              onClick={onSaveEmptyImage}
            >
              Save Empty Image
            </Button>
          </Stack>
        )}
        {imgSrc && (
          <Stack align="center" mi2={200} miw={200}>
            <ReactCrop
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => setCrop(c)}
              minWidth={100}
              minHeight={100}
              maxWidth={500}
              maxHeight={500}
              aspect={1}
              circularCrop
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                onLoad={(img) => {
                  const { width, height } = img.currentTarget;
                  const availableSize = width > height ? height : width;

                  const size = availableSize >= 500 ? 500 : availableSize;

                  setCrop({
                    x: 0,
                    y: 0,
                    // for circular crop width === height
                    width: size,
                    height: size,
                    unit: 'px',
                  });
                }}
                style={{
                  objectFit: 'contain',
                  maxWidth: '500px',
                  maxHeight: '500px',
                }}
                crossOrigin="anonymous"
              />
            </ReactCrop>
            <Flex align="start" gap={10}>
              <Button
                p={0}
                style={{
                  width: rem(40),
                  height: rem(40),
                  borderRadius: '10px',
                  border: '1px solid gray',
                }}
                onClick={() => {
                  setImgSrc(null);
                  setCrop();
                  canvasPreview(imgRef.current, previewCanvasRef.current, {
                    width: 0,
                    height: 0,
                    x: 0,
                    y: 0,
                  });
                }}
                variant="white"
              >
                <IconTrash size={30} cursor="pointer" />
              </Button>
              <Stack gap={5} mt="auto">
                <FileInput
                  classNames={{ input: styles.fileInput }}
                  accept={acceptTypes.currentValidImages}
                  variant="filled"
                  placeholder="Upload image"
                  onChange={validateFile}
                />
                <Code>or paste copied image</Code>
              </Stack>
              <Button
                disabled={isLoading || isConverting}
                onClick={onSaveClick}
              >
                Save
              </Button>
            </Flex>
          </Stack>
        )}

        <Stack pos="relative">
          <Stack gap={5}>
            <Text>Preview</Text>
            <Divider />
          </Stack>
          {!imgSrc && (
            <Box
              w={200}
              h={200}
              style={{
                border: '1px solid var(--mantine-color-blue-6)',
                borderRadius: '14px',
              }}
              pos="relative"
            >
              <Box pos="absolute" className={styles.previewEmptyText}>
                Empty Preview
              </Box>
            </Box>
          )}
          {imgSrc && (
            <canvas ref={previewCanvasRef} className={styles.canvasPreview} />
          )}
        </Stack>
      </Flex>
    </Modal>
  );
}

ImageCropModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onSaveEmptyImage: PropTypes.func,
  url: PropTypes.string,
  isLoading: PropTypes.bool,
};

ImageCropModal.defaultProps = {
  url: '',
  onSaveEmptyImage: () => {},
  isLoading: false,
};
