/* eslint-disable react/jsx-props-no-spreading */
import { DateInput } from '@mantine/dates';
import PropTypes from 'prop-types';
import moment from 'moment';
import { IconCalendar } from '@tabler/icons-react';

export default function DateFormInput({
  field,
  onChange,
  value,
  error,
  ...rest
}) {
  const parsedValue = value ? moment(value, 'YYYY-MM-DD').toDate() : null;
  const { clearable } = rest;
  return (
    <DateInput
      label={field?.displayName}
      placeholder={field?.placeholder || field?.displayName}
      value={parsedValue}
      rightSection={!clearable && <IconCalendar size={20} color="black" />}
      valueFormat="YYYY-MM-DD"
      onChange={(val) => {
        onChange(val ? moment(val, 'YYYY-MM-DD').toDate() : null);
      }}
      error={error}
      required={field?.validation?.isRequired}
      {...rest}
    />
  );
}

DateFormInput.propTypes = {
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  error: PropTypes.any,
};
DateFormInput.defaultProps = {
  value: null,
  error: null,
};
