import { Button, Stack, Text } from '@mantine/core';
import { clearCache } from 'api/clearCache';
import { useAttachWidget } from 'api/hooks';
import { useNotifications } from 'notifications/hooks/useNotifications';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';

export default function GenericAllowedWidget({ widget, isFetching }) {
  const { setNotifications } = useNotifications();
  const queryClient = useQueryClient();

  const {
    attachWidget,
    variables,
    isLoading: isAttaching,
  } = useAttachWidget({
    onSuccess: () => {
      setNotifications(['Widget Attached Successfully']);
      clearCache.onChangeWidget(queryClient);
    },
  });

  const isLoading =
    (isAttaching || isFetching) && variables?.widget_id === widget?.id;

  return (
    <Stack
      key={widget?.id}
      gap={0}
      style={{
        border: '1px solid var(--mantine-color-gray-4)',
        borderRadius: '10px',
      }}
      p={5}
    >
      <Text fw={600}>{widget?.name}</Text>
      <Text c="var(--mantine-color-gray-6)">{widget?.description}</Text>
      <Button
        w="max-content"
        ml="auto"
        h={27}
        px={22}
        onClick={() => attachWidget({ widget_id: widget?.id })}
        disabled={isLoading}
        loading={isLoading}
      >
        Add
      </Button>
    </Stack>
  );
}

GenericAllowedWidget.propTypes = {
  widget: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
};
