import { Button, Divider, Flex, Space, Table, Title } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { clearCache } from 'api/clearCache';
import {
  useCreateAssetCategory,
  useDeleteAssetCategory,
  useLoadAssetCategories,
  useUpdateAssetCategory,
} from 'api/hooks';
import { ConfirmDeleteModal } from 'components/ConfirmDeleteModal';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import { Loader } from 'components/Loader';
import { OneFieldModal } from 'components/OneFieldModal';
import { SettingsTableHead } from 'components/SettingsTable';
import _ from 'lodash';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { useQueryClient } from 'react-query';
import { useStateDisclosure } from 'utils/hooks';
import { AssetCategoryRow } from '../components';

export default function AssetCategories() {
  const { setNotifications } = useNotifications();
  const queryClient = useQueryClient();

  const [
    openedAddCategory,
    { open: openAddCategory, close: closeAddCategory, state: addCategoryState },
  ] = useStateDisclosure(false);

  const { assetCategories, isLoading: isAssetCategoriesLoading } =
    useLoadAssetCategories();

  const [
    openedDeleteModal,
    { open: openDeleteModal, close: closeDeleteModal, state: deleteModalState },
  ] = useStateDisclosure(false);

  const { createAssetCategory, isLoading: isCreatingCategory } =
    useCreateAssetCategory({
      onSuccess: () => {
        clearCache.onChangeAssetCategory(queryClient);
        setNotifications(['Asset Category Successfully Created']);
        closeAddCategory();
      },
    });

  const { updateAssetCategory, isLoading: isAssetCategoryUpdating } =
    useUpdateAssetCategory({
      onSuccess: () => {
        clearCache.onChangeAssetCategory(queryClient);
        setNotifications(['Asset Category Updated Created']);
        closeAddCategory();
      },
    });

  const { deleteAssetCategory, isLoading: isAssetCategoryDeleting } =
    useDeleteAssetCategory({
      onSuccess: () => {
        clearCache.onChangeAssetCategory(queryClient);
        setNotifications(['Asset Category Deleted Created']);
        closeDeleteModal();
      },
    });

  return (
    <>
      <ConfirmDeleteModal
        opened={openedDeleteModal}
        onClose={closeDeleteModal}
        isLoading={isAssetCategoryDeleting}
        onDelete={() => deleteAssetCategory(deleteModalState?.id)}
      />
      <OneFieldModal
        isLoading={isCreatingCategory || isAssetCategoryUpdating}
        state={addCategoryState}
        opened={openedAddCategory}
        onClose={closeAddCategory}
        onCreate={createAssetCategory}
        onEdit={(data) =>
          updateAssetCategory({
            assetCategoryId: addCategoryState?.id,
            ...data,
          })
        }
        title="Asset Category"
      />
      <Flex gap={10} justify="space-between" align="center">
        <Title fz="xl">Asset Categories</Title>
        <Button
          onClick={() => openAddCategory({ modalType: 'Create' })}
          rightSection={<IconPlus size={14} />}
        >
          Add Asset Category
        </Button>
      </Flex>

      <Space h="xs" />

      <Loader isLoading={isAssetCategoriesLoading} />

      <EmptyDataMessage
        isVisible={!isAssetCategoriesLoading && _.isEmpty(assetCategories)}
      />

      {!_.isEmpty(assetCategories) && (
        <>
          <Table>
            <SettingsTableHead headerList={['Asset Category', 'People', '']} />
            <Table.Tbody>
              {_.map(assetCategories, (assetCategory) => (
                <AssetCategoryRow
                  key={assetCategory?.id}
                  category={assetCategory}
                  onEdit={(data) =>
                    openAddCategory({ ...data, modalType: 'Edit' })
                  }
                  onDelete={openDeleteModal}
                />
              ))}
            </Table.Tbody>
          </Table>
          <Divider />
        </>
      )}
    </>
  );
}
