import { Container, Tabs } from '@mantine/core';
import {
  IconFiles,
  IconPencilPlus,
  IconSignature,
  IconSignatureOff,
} from '@tabler/icons-react';
import { clearCache } from 'api/clearCache';
import { useCreateManagementRequest, useUpdateFileStatus } from 'api/hooks';
import { useAuthContext } from 'auth';
import { PageWrapper } from 'components/PageWrapper';
import { RequestSignatureModal } from 'components/RequestSignatureModal';
import _ from 'lodash';
import moment from 'moment';
import { useNotifications } from 'notifications/hooks/useNotifications';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { getFileExt } from 'utils';
import { fileStatuses, fileTypes, filterEmployeeTypes } from 'utils/constants';
import { usePageTitle, useStateDisclosure } from 'utils/hooks';
import { AllFiles, SignatureFiles } from './tabs';

const contentType = 'Company';

const tabs = {
  allFiles: 'allFiles',
  signatureFiles: 'signatureFiles',
};

export default function OrganizationFiles({ globalTabName }) {
  const { user } = useAuthContext();

  const [selectedTab, setSelectedTab] = useState(tabs.allFiles);

  const contentId = user?.company_id;

  const { setNotifications, setErrors } = useNotifications();
  const queryClient = useQueryClient();

  const [
    openedRequestSignatureModal,
    {
      open: openRequestSignature,
      close: closeRequestSignature,
      state: requestSignatureState,
    },
  ] = useStateDisclosure(false);

  const { createManagementRequest, isLoading: isCreatingRequest } =
    useCreateManagementRequest({
      onSuccess: () => {
        closeRequestSignature();
        setNotifications(['Request Signature Created Successfully!']);
        clearCache.onSendSignatureRequest(queryClient);
      },
    });

  const { updateFileStatus } = useUpdateFileStatus({
    onSuccess: () => {
      setNotifications(['File Status Changed Successfully!']);
      clearCache.onChangeFiles(queryClient, { contentId, contentType });
    },
  });

  usePageTitle('Files', globalTabName);

  const onSendRequestSignature = ({
    filter,
    employeeIds,
    employeeType,
    ...rest
  }) =>
    createManagementRequest({
      category: 'Signature',
      template_id: requestSignatureState?.id,
      template_name: requestSignatureState?.name,
      client_time: moment(),
      filter:
        employeeType === filterEmployeeTypes.someEmployee && !_.isEmpty(filter)
          ? filter
          : undefined,
      employeeIds:
        employeeType === filterEmployeeTypes.someEmployee
          ? employeeIds
          : undefined,
      ...rest,
    });

  const fileActions = useMemo(
    () => [
      {
        label: 'Request Signature',
        onClick: openRequestSignature,
        icon: IconPencilPlus,
        type: fileTypes.file,
        condition: (val) =>
          val?.status === fileStatuses.assignable &&
          getFileExt(val?.name) === 'pdf',
      },
      {
        label: 'Mark as Signature type',
        onClick: (file) => {
          if (file?.metadata?.is_protected) {
            setErrors([
              'A password-protected file cannot be converted to Signature Type. Delete file and upload without password',
            ]);
          } else {
            updateFileStatus({
              fileId: file?.id,
              status: fileStatuses.assignable,
            });
          }
        },
        icon: IconSignature,
        type: fileTypes.file,
        condition: (val) =>
          val?.status === fileStatuses.readable &&
          getFileExt(val?.name) === 'pdf',
      },
      {
        label: 'Mark as Readable Type',
        onClick: (file) =>
          updateFileStatus({
            fileId: file?.id,
            status: fileStatuses.readable,
          }),
        icon: IconSignatureOff,
        type: fileTypes.file,
        condition: (val) =>
          val?.status === fileStatuses.assignable &&
          getFileExt(val?.name) === 'pdf',
      },
    ],
    [setErrors, openRequestSignature, updateFileStatus],
  );

  return (
    <PageWrapper title="Files">
      <RequestSignatureModal
        opened={openedRequestSignatureModal}
        onClose={closeRequestSignature}
        signatureState={requestSignatureState}
        disabled={isCreatingRequest || !openedRequestSignatureModal}
        onSendRequestSignature={onSendRequestSignature}
      />
      <Tabs value={selectedTab} onChange={setSelectedTab} keepMounted={false}>
        <Container>
          <Tabs.List>
            <Tabs.Tab value={tabs.allFiles} leftSection={<IconFiles />}>
              All Files
            </Tabs.Tab>
            <Tabs.Tab
              value={tabs.signatureFiles}
              leftSection={<IconSignature />}
            >
              Signature Files
            </Tabs.Tab>
          </Tabs.List>
        </Container>
        <Tabs.Panel value={tabs.allFiles}>
          <AllFiles
            contentType={contentType}
            contentId={contentId}
            fileActions={fileActions}
          />
        </Tabs.Panel>
        <Tabs.Panel value={tabs.signatureFiles}>
          <SignatureFiles
            contentType={contentType}
            contentId={contentId}
            fileActions={fileActions}
          />
        </Tabs.Panel>
      </Tabs>
    </PageWrapper>
  );
}

OrganizationFiles.propTypes = {
  globalTabName: PropTypes.string,
};

OrganizationFiles.defaultProps = {
  globalTabName: '',
};
