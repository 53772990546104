import { Modal } from '@mantine/core';
import { CompensationRecordForm } from 'components/CompensationRecordForm';
import PropTypes from 'prop-types';

export default function CompensationRecordModal({
  opened,
  onClose,
  onEdit,
  onCreate,
  isLoading,
  compensationRecordState,
  schedules,
  metaData,
  changeReasons,
}) {
  return (
    <Modal
      centered
      opened={opened}
      onClose={onClose}
      title={`${
        compensationRecordState?.modalType === 'Edit' ? 'Edit' : 'Create'
      } Compensation`}
    >
      <CompensationRecordForm
        onSubmit={
          compensationRecordState?.modalType === 'Edit' ? onEdit : onCreate
        }
        isLoading={isLoading || !opened}
        compensationRecordState={compensationRecordState}
        schedules={schedules}
        metaData={metaData}
        changeReasons={changeReasons}
      />
    </Modal>
  );
}

CompensationRecordModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  schedules: PropTypes.array.isRequired,
  changeReasons: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  compensationRecordState: PropTypes.object,
  metaData: PropTypes.object,
};

CompensationRecordModal.defaultProps = {
  isLoading: false,
  compensationRecordState: {},
  metaData: {},
};
