import { Modal } from '@mantine/core';
import { PayScheduleForm } from 'components/PayScheduleForm';
import PropTypes from 'prop-types';

export default function PayScheduleModal({
  opened,
  onClose,
  isLoading,
  payScheduleState,
  onEdit,
  onCreate,
  frequencyObjectList,
}) {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      title={`${
        payScheduleState?.modalType === 'Edit' ? 'Edit' : 'Create'
      } Pay Schedule`}
    >
      <PayScheduleForm
        payScheduleState={payScheduleState}
        isLoading={isLoading || !opened}
        onSubmit={payScheduleState?.modalType === 'Edit' ? onEdit : onCreate}
        frequencyObjectList={frequencyObjectList}
      />
    </Modal>
  );
}

PayScheduleModal.propTypes = {
  frequencyObjectList: PropTypes.object.isRequired,
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  payScheduleState: PropTypes.object,
};

PayScheduleModal.defaultProps = {
  isLoading: false,
  payScheduleState: {},
};
