import { useResetPassword } from 'api/hooks';
import { useAuthContext } from 'auth';
import PasswordUpdate from 'components/Password/PasswordUpdate';
import { useNotifications } from 'notifications/hooks/useNotifications';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCheckValidPasswordToken, useSetPageTitle } from 'utils/hooks';

export default function ResetPassword() {
  const { setNotifications } = useNotifications();
  const { isLoggedIn, clearData } = useAuthContext();
  const navigate = useNavigate();
  const { resetHash } = useParams();

  const { isCheckValidationLoading, isInvalidToken } =
    useCheckValidPasswordToken(resetHash);

  const { resetPassword, isLoading } = useResetPassword({
    onSuccess: () => {
      setNotifications(['Your password has now been reset, please login now']);
      navigate('/login');
    },
  });

  const onSubmit = async (data) => {
    if (isLoggedIn) {
      await clearData();
    }

    const payload = {
      password: data?.newPassword,
      password_confirmation: data?.confirmNewPassword,
    };

    resetPassword({ hash: resetHash, ...payload });
  };

  useSetPageTitle('Reset Password');

  return (
    <PasswordUpdate
      submitButtonLabel="Reset Password"
      formTitle="Reset Password"
      onSubmit={onSubmit}
      isLoading={isLoading || isCheckValidationLoading || isInvalidToken}
    />
  );
}
