import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  InputError,
  InputLabel,
  Loader,
  Modal,
  Radio,
  Stack,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { useLoadTotalEmployeesByJobInfoFilter } from 'api/hooks';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { EmployeeMultipleAutocomplete } from 'components/EmployeeMultipleAutocomplete';
import { EmployeeFilterList } from 'components/EmployeesFilter/components';
import { useEmployeeFilter } from 'components/EmployeesFilter/hooks';
import { filterOnlySelectedValues } from 'components/PerformanceManagement/forms/utils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { filterEmployeeTypes } from 'utils/constants';

export default function RequestSignatureModal({
  opened,
  onClose,
  onSendRequestSignature,
  disabled,
  signatureState,
}) {
  const {
    employeeFilters,
    selectedValues,
    onChangeEmployeeFilter,
    clearEmployeeFilter,
  } = useEmployeeFilter();

  const formattedSelectedFilterValues = useMemo(() => {
    const res = _.keys(selectedValues).reduce((prev, curr) => {
      const filteredSelected = _.map(
        selectedValues[curr]?.filter((v) => v?.value),
        'id',
      );

      if (_.isEmpty(filteredSelected)) return prev;

      return { ...prev, [`in${curr}`]: filteredSelected };
    }, {});

    return res;
  }, [selectedValues]);

  const { reset, ...form } = useForm({
    initialValues: {
      employeeType: filterEmployeeTypes.all,
      employeeIds: [],
      comment: '',
    },
    validate: {
      employeeType: (val, { employeeIds }) => {
        if (val === filterEmployeeTypes.someEmployee) {
          return _.isEmpty(employeeIds) &&
            _.isEmpty(formattedSelectedFilterValues)
            ? 'At least one of the employees/categories must be selected'
            : null;
        }
        return null;
      },
    },
  });

  const { employeeCount, isLoading: isEmployeeCountLoading } =
    useLoadTotalEmployeesByJobInfoFilter(
      { filters: filterOnlySelectedValues(selectedValues) },
      { enabled: !_.isEmpty(filterOnlySelectedValues(selectedValues)) },
    );

  useEffect(() => {
    if (!opened) {
      clearEmployeeFilter();
      reset();
    }
  }, [clearEmployeeFilter, opened, reset]);

  const [
    openedConfirmationModal,
    { open: openConfirmationModal, close: closeConfirmationModal },
  ] = useDisclosure(false);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      title="Send Request Signature"
    >
      <ConfirmationModal
        opened={openedConfirmationModal}
        onClose={closeConfirmationModal}
        onConfirm={() => {
          closeConfirmationModal();
          onSendRequestSignature({
            ...form.values,
            filter: formattedSelectedFilterValues,
          });
        }}
        title="Confirmation"
        isLoading={disabled}
        text={`Are you sure you want to send "${signatureState?.name}" for signature?`}
      />

      <form onSubmit={form.onSubmit(openConfirmationModal)} noValidate>
        <Stack gap={5}>
          <Radio.Group
            onChange={(val) => {
              form.setFieldValue('employeeType', val);
              form.setFieldValue('employeeIds', []);
            }}
            value={form.values.employeeType}
          >
            <InputLabel>Who should be included?</InputLabel>
            <Stack gap={10}>
              <Radio value={filterEmployeeTypes.all} label="All employees" />
              <Radio
                value={filterEmployeeTypes.someEmployee}
                label="Some employee"
              />
            </Stack>
          </Radio.Group>
          {form.values.employeeType === filterEmployeeTypes.someEmployee && (
            <>
              <Flex
                px={15}
                my={5}
                ml="-10"
                gap={10}
                c={employeeCount || isEmployeeCountLoading ? '' : 'orange'}
                align="center"
                justify="end"
              >
                <InputError>{form.errors.employeeType}</InputError>
                <Avatar size={20} variant="filled" />
                {isEmployeeCountLoading ? (
                  <Loader size={20} />
                ) : (
                  employeeCount
                )}{' '}
                Filtered Employee
              </Flex>
              <Divider />
              <Box
                style={{
                  overflow: 'auto',
                  marginLeft: '-10px',
                  marginRight: '-10px',
                }}
                mah="500"
              >
                <EmployeeFilterList
                  filteredData={employeeFilters}
                  onSelectFilter={(data) => {
                    onChangeEmployeeFilter(data);
                    form.clearFieldError('employeeType');
                  }}
                  selectedValues={selectedValues}
                />
              </Box>
              <Divider />
              <EmployeeMultipleAutocomplete
                values={form.values.employeeIds ?? []}
                setValues={(val) => {
                  form.setFieldValue('employeeIds', val);
                  if (!_.isEmpty(val)) form.clearFieldError('employeeType');
                }}
                clearable
                label="Select employee"
                error={form.errors.employeeType}
              />
            </>
          )}
          <Textarea
            label="Comment"
            resize="block"
            rows={5}
            {...form.getInputProps('comment')}
          />
          <Button ml="auto" type="submit" disabled={disabled}>
            Submit
          </Button>
        </Stack>
      </form>
    </Modal>
  );
}

RequestSignatureModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSendRequestSignature: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  signatureState: PropTypes.object,
};

RequestSignatureModal.defaultProps = {
  signatureState: {},
};
