import { Box, PasswordInput, Popover, Progress } from '@mantine/core';
import { IconCircleCheck, IconCirclePlus, IconKey } from '@tabler/icons-react';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getPasswordStrength, passwordRequirements } from './utils';
import { PasswordRequirement } from '.';

import styles from './style.module.css';

export default function PasswordConfirmation({
  newPassword,
  setNewPassword,
  confirmPassword,
  setConfirmPassword,
  newPasswordError,
  confirmPasswordError,
}) {
  const [popoverOpened, setPopoverOpened] = useState(false);

  const checks = passwordRequirements.map((requirement) => (
    <PasswordRequirement
      key={requirement.re}
      label={requirement.label}
      meets={requirement.re.test(newPassword)}
    />
  ));

  const strength = getPasswordStrength(newPassword);
  const isFullSecured = strength === 100 ? 'teal' : '';
  const secureColor = strength > 50 ? 'yellow' : 'red';
  const color = isFullSecured || secureColor;

  return (
    <>
      <Popover
        opened={popoverOpened}
        position="bottom"
        width="target"
        transitionProps={{ transition: 'pop' }}
      >
        <Popover.Target>
          <div
            onFocusCapture={() => setPopoverOpened(true)}
            onBlurCapture={() => setPopoverOpened(false)}
          >
            <PasswordInput
              leftSection={
                <Box pos="relative" w="100%" h="100%">
                  <IconKey className={styles.keyStyles} />
                  <IconCirclePlus size={10} className={styles.additionalIcon} />
                </Box>
              }
              label="New Password"
              autoComplete="nope"
              value={newPassword}
              onChange={(e) => setNewPassword(e.currentTarget.value)}
              error={newPasswordError}
            />
          </div>
        </Popover.Target>
        <Popover.Dropdown>
          <Progress color={color} value={strength} size={5} mb="xs" />
          <PasswordRequirement
            label="Includes at least 8 characters"
            meets={newPassword.length > 7}
          />
          {checks}
        </Popover.Dropdown>
      </Popover>
      <PasswordInput
        leftSection={
          <Box pos="relative" w="100%" h="100%">
            <IconKey className={styles.keyStyles} />
            <IconCircleCheck size={10} className={styles.additionalIcon} />
          </Box>
        }
        label="Confirm Password"
        error={confirmPasswordError}
        onChange={(e) => setConfirmPassword(e.currentTarget.value)}
        value={confirmPassword}
      />
    </>
  );
}

PasswordConfirmation.propTypes = {
  setNewPassword: PropTypes.func.isRequired,
  setConfirmPassword: PropTypes.func.isRequired,
  newPassword: PropTypes.string,
  confirmPassword: PropTypes.string,
  newPasswordError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  confirmPasswordError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

PasswordConfirmation.defaultProps = {
  newPassword: '',
  confirmPassword: '',
  newPasswordError: null,
  confirmPasswordError: null,
};
