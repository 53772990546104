import { Box, Flex, ScrollArea } from '@mantine/core';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

import { IconX } from '@tabler/icons-react';
import { useGlobalTabsContext } from './hooks/useGlobalTabsContext';
import styles from './styles.module.css';

export default function GlobalTabsLayout() {
  const navigation = useNavigate();
  const { tabs, selectedTab, setSelectedTab, onRemoveTab } =
    useGlobalTabsContext();

  return (
    <Box>
      <ScrollArea.Autosize maw="calc(100vw - 100px)">
        <Flex
          w="100%"
          wrap="nowrap"
          style={{ borderBottom: '2px solid var(--mantine-color-gray-3)' }}
          pos="relative"
        >
          {_.keys(tabs).map((tab) => (
            <Flex
              align="center"
              gap={5}
              size="xl"
              pt={8}
              pb={5}
              px={10}
              key={tab}
              pos="relative"
              style={{
                cursor: 'pointer',
                zIndex: 1,
              }}
              onClick={() => {
                setSelectedTab(tab);
                navigation(`${tabs[tab]?.pathName}`);
              }}
              bg={selectedTab === tab ? 'var(--white)' : ''}
              className={styles.tab}
            >
              {selectedTab === tab && (
                <Box
                  pos="absolute"
                  w="100%"
                  h="2"
                  bottom={-2}
                  left={0}
                  style={{
                    borderBottom: '2px solid var(--mantine-color-blue-7)',
                    zIndex: 1000,
                  }}
                  className={styles.tabLine}
                />
              )}
              {tab}
              <IconX
                size={15}
                className={styles.iconX}
                onClick={(e) => {
                  e.stopPropagation();
                  if (_.keys(tabs).length > 1) {
                    onRemoveTab(tab);
                    if (selectedTab === tab) {
                      const validTab = _.keys(tabs)?.find(
                        (i) => i !== selectedTab,
                      );
                      setSelectedTab(validTab);
                      navigation(`${tabs[validTab]?.pathName}`);
                    }
                  }
                }}
              />
            </Flex>
          ))}
        </Flex>
      </ScrollArea.Autosize>
      {_.keys(tabs).map((tab) => (
        <div
          key={tab}
          style={{ display: tab === selectedTab ? 'block' : 'none' }}
        >
          {tabs[tab]?.children}
        </div>
      ))}
    </Box>
  );
}
