import { Flex, Text } from '@mantine/core';
import PropTypes from 'prop-types';

export default function EmptyDataMessage({ isVisible, message, ...props }) {
  return (
    isVisible && (
      <Flex align="center" justify="center" {...props}>
        <Text fw={500} size="xl" c="dimmed">
          {message}
        </Text>
      </Flex>
    )
  );
}

EmptyDataMessage.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  message: PropTypes.string,
};

EmptyDataMessage.defaultProps = {
  message: 'No entries',
};
