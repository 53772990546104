import { Modal } from '@mantine/core';
import PropTypes from 'prop-types';
import TimeOffCategoryForm from '../forms/TimeOffCategoryForm';

export default function TimeOffCategoryModal({
  isLoading,
  opened,
  onClose,
  onSubmit,
  category,
}) {
  return (
    <Modal
      opened={opened}
      onClose={() => {
        onClose();
      }}
      title={category ? 'Edit Category' : 'Create Category'}
      centered
    >
      <TimeOffCategoryForm
        category={category}
        isLoading={isLoading}
        onSubmit={onSubmit}
      />
    </Modal>
  );
}

TimeOffCategoryModal.propTypes = {
  isLoading: PropTypes.bool,
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  category: PropTypes.object,
};

TimeOffCategoryModal.defaultProps = {
  isLoading: false,
  opened: false,
  onClose: () => {},
  onSubmit: () => {},
  category: null,
};
