import { Loader, Select } from '@mantine/core';
import { useEmployees } from 'api/hooks';
import React from 'react';
import { useSearchableSelector } from 'utils/hooks';
import PropTypes from 'prop-types';

import _ from 'lodash';

export default function EmployeeAutocomplete({
  formValue,
  defaultValue,
  onChange,
  ...props
}) {
  const { setSearch, debouncedSearch, updateSelectorData, selectorData } =
    useSearchableSelector({
      defaultSelectorData: _.isEmpty(defaultValue)
        ? undefined
        : [
            {
              value: _.toString(defaultValue?.id),
              label: `${defaultValue?.first_name} ${defaultValue?.last_name}`,
            },
          ],
    });

  const { isFetching: isUserFetching } = useEmployees(
    { canManageEmployee: true, search: debouncedSearch },
    {
      enabled: debouncedSearch?.length > 2,
      onSuccess: (data) => {
        const searchedData = _.map(data?.data ?? [], (item) => ({
          ...item,
          value: _.toString(item?.id),
          label: `${item?.first_name} ${item?.last_name}`,
        }));
        updateSelectorData(searchedData);
      },
    },
  );

  return (
    <Select
      value={
        typeof formValue === 'object'
          ? _.toString(formValue?.value) || _.toString(formValue?.id)
          : formValue
      }
      placeholder="Name"
      onChange={onChange}
      data={selectorData}
      onSearchChange={(value) =>
        value
          ? setSearch(value)
          : onChange(value, typeof formValue === 'string' ? '' : {})
      }
      rightSection={isUserFetching ? <Loader size={14} /> : undefined}
      searchable
      required
      clearable
      {...props}
    />
  );
}

EmployeeAutocomplete.propTypes = {
  formValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.object,
};

EmployeeAutocomplete.defaultProps = {
  defaultValue: {},
  formValue: '',
};
