import { useAuthContext } from 'auth';
import { AppSplashScreen } from 'components/AppSplashScreen';
import {
  CandidateOffer,
  CandidateOfferPreview,
  ChoosePassword,
  ResetPassword,
} from 'modules';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import Authentication from './modules/auth/Authentication';
import { Dashboard } from './modules/dashboard';
import ForgotPassword from './modules/forgot/ForgotPassword';

export default function App() {
  const { isLoggedIn, isUserLoading, user } = useAuthContext();
  return (
    <>
      {isLoggedIn && (
        <>
          {isUserLoading && <AppSplashScreen />}
          {!isUserLoading && user && (
            <Routes>
              <Route path="/*" element={<Dashboard />} />
              <Route
                exact
                path="/offer-preview/jobId/:jobId/offer/:offerId/candidate/:candidateId"
                element={<CandidateOfferPreview />}
              />
              <Route
                exact
                path="/candidate-offer/:offerHash"
                element={<CandidateOffer />}
              />
              <Route
                exact
                path="/reset-password/:resetHash"
                element={<ResetPassword />}
              />
              <Route
                exact
                path="/choose-password/:userHash"
                element={<ChoosePassword />}
              />
              <Route path="*" element={<Dashboard />} />
            </Routes>
          )}
        </>
      )}
      {!isLoggedIn && (
        <Routes>
          <Route exact path="/login" element={<Authentication />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route
            exact
            path="/candidate-offer/:offerHash"
            element={<CandidateOffer />}
          />
          <Route
            exact
            path="/reset-password/:resetHash"
            element={<ResetPassword />}
          />
          <Route
            exact
            path="/choose-password/:userHash"
            element={<ChoosePassword />}
          />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      )}
    </>
  );
}
