import { Button, Flex, LoadingOverlay, Space, Tabs } from '@mantine/core';
import { useCandidate } from 'api/hooks';
import { CandidateProfileInfo } from 'components/CandidateProfileInfo';
import { PageWrapper } from 'components/PageWrapper';
import _ from 'lodash';
import { Files } from 'modules/files';
import { Notes } from 'modules/notes';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { getUserFullName } from 'utils';
import { useCandidateActiveOffer } from 'utils/api';
import { usePageTitle, useTabNavigate } from 'utils/hooks';

import { tabNames } from 'utils/constants';
import { CandidateInfo, CandidateOffer, Email } from './candidate-tabs';

// candidateOffer name is used in Candidate offer tab
const tabs = ['candidateInfo', 'notes', 'emails', 'files', 'candidateOffer'];

export default function CandidateProfile({ params, globalTabName }) {
  const { candidateId, jobId } = params;

  const [offerTemplateId, setOfferTemplateId] = useState(null);
  const [openWithOfferModal, setOpenWithOfferModal] = useState(false);

  const [tab, setTab] = useState(tabs[0]);
  const [notesCount, setNotesCount] = useState(0);

  const { candidateDetails, isLoading, isFetching, error } =
    useCandidate(candidateId);
  const navigate = useTabNavigate();
  const navigateToEdit = (id) => {
    navigate(`/hiring/job/${jobId}/candidate/${id}/edit/`, {
      tabName: tabNames.hiringJobCandidate,
    });
  };

  const onSelectOfferTemplate = (templateId) => {
    if (templateId) {
      setOpenWithOfferModal(true);
      setTab('candidateOffer');
    }
    setOfferTemplateId(templateId);
  };

  const { currentOffer } = useCandidateActiveOffer(candidateDetails?.offers);

  usePageTitle(
    getUserFullName(candidateDetails)
      ? `Hiring - ${getUserFullName(candidateDetails)} - Candidates`
      : 'Hiring - Candidates',
    globalTabName,
  );

  return (
    <PageWrapper
      withFlexStyles={tab === 'candidateInfo'}
      leftSection={<CandidateProfileInfo candidateDetails={candidateDetails} />}
      rightSection={
        <Button onClick={() => navigateToEdit(candidateDetails.id)}>
          Edit
        </Button>
      }
    >
      <LoadingOverlay
        visible={isLoading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      {!isFetching && candidateDetails && (
        <Flex gap="md" flex={1}>
          <Flex w="100%">
            <Tabs
              w="100%"
              mt={20}
              value={tab}
              onChange={setTab}
              display="flex"
              styles={{ root: { flexDirection: 'column' } }}
            >
              <Tabs.List>
                <Tabs.Tab value="candidateInfo">Candidate Info</Tabs.Tab>
                <Tabs.Tab value="notes">Notes ({notesCount ?? 0})</Tabs.Tab>
                <Tabs.Tab value="emails">
                  Emails ({candidateDetails?.emails?.length ?? 0})
                </Tabs.Tab>
                <Tabs.Tab value="files">Files</Tabs.Tab>
                {(!_.isEmpty(currentOffer) || offerTemplateId) && (
                  <Tabs.Tab value="candidateOffer">Candidate Offer</Tabs.Tab>
                )}
              </Tabs.List>
              <Tabs.Panel
                styles={{
                  root: { flexDirection: 'column' },
                }}
                value="candidateInfo"
                display={tab === 'candidateInfo' ? 'flex' : undefined}
                flex={1}
              >
                <CandidateInfo
                  candidateDetails={candidateDetails}
                  jobId={jobId}
                  offerTemplateProps={{
                    offerTemplateId,
                    setOfferTemplateId: onSelectOfferTemplate,
                  }}
                />
              </Tabs.Panel>
              <Tabs.Panel value="notes">
                <Space h="md" />
                <Notes
                  contentType="CandidateProfile"
                  contentId={candidateDetails.id}
                  setNotesCount={setNotesCount}
                />
              </Tabs.Panel>
              <Tabs.Panel value="emails">
                <Email candidateDetails={candidateDetails} jobId={jobId} />
              </Tabs.Panel>
              <Tabs.Panel value="files">
                <Space h="md" />
                <Files
                  contentType="CandidateProfile"
                  contentId={candidateDetails.id}
                />
              </Tabs.Panel>
              <Tabs.Panel value="candidateOffer">
                <CandidateOffer
                  currentTab={tab}
                  candidateDetails={candidateDetails}
                  leaveTab={() => setTab(tabs[0])}
                  jobId={jobId}
                  offerTemplateProps={{
                    offerTemplateId,
                    setOfferTemplateId: onSelectOfferTemplate,
                    openWithOfferModal,
                    setOpenWithOfferModal,
                  }}
                />
              </Tabs.Panel>
            </Tabs>
          </Flex>
        </Flex>
      )}
      {error && <h3 style={{ textAlign: 'center' }}>Candidate Not Found</h3>}
    </PageWrapper>
  );
}

CandidateProfile.propTypes = {
  params: PropTypes.object,
  globalTabName: PropTypes.string,
};

CandidateProfile.defaultProps = {
  params: {},
  globalTabName: '',
};
