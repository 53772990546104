import { Card, Flex, Grid, Stack, Tabs, Text, rem } from '@mantine/core';

import {
  IconBrandGoogleAnalytics,
  IconRefresh,
  IconUsers,
} from '@tabler/icons-react';

import { PermissionGuard } from 'components/PermissionGuard';
import { usePermissions } from 'components/PermissionGuard/hooks';
import { UserItem } from 'components/UserItem';
import { Time, hideForNow } from 'utils/constants';

import { useLoadWidgets } from 'api/hooks';
import GenericActiveWidget from 'components/WidgetSettings/components/GenericActiveWidget';
import { useDragAndDrop } from 'components/WidgetSettings/hooks';
import TimeOff from 'components/WidgetSettings/widgets/TimeOff';
import _ from 'lodash';
import { useMemo, useRef } from 'react';
import classes from '../Home.module.css';
import { WhatsHappening } from './cards';
import { HeadCountTab, MyTeamTab } from './my-team-tabs';

const numberOfColumns = 3;

export default function EmployeeSection() {
  const { permissions, hasPermission } = usePermissions();

  const iconStyle = { width: rem(16), height: rem(16) };

  const { applyAllColumns } = useDragAndDrop();

  const columnWrapperRef = useRef();

  const { widgets, isLoading: isWidgetFetching } = useLoadWidgets({
    staleTime: Time.WidgetStaleQueryTime,
  });

  const groupedWidgets = useMemo(
    () =>
      applyAllColumns(
        _.groupBy(widgets?.usedWidgets ?? [], 'column'),
        numberOfColumns,
      ) ?? {},
    [widgets, applyAllColumns],
  );

  return (
    <PermissionGuard permission={permissions.canViewEmployees}>
      <Grid gutter="md">
        <Grid.Col span={5} py={0}>
          <TimeOff />
        </Grid.Col>
        <Grid.Col span={7} py={0}>
          <WhatsHappening />
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col mt="0" span={12} py={0}>
          <Card withBorder p="xl" radius="md" className={classes.card}>
            <div className={classes.inner}>
              <div>
                <Text fz="xl" className={classes.label}>
                  My Team
                </Text>
                <Tabs
                  keepMounted={false}
                  mt="20"
                  orientation="vertical"
                  defaultValue="headcount"
                >
                  <Tabs.List mr="30">
                    <Tabs.Tab
                      value="headcount"
                      leftSection={
                        <IconBrandGoogleAnalytics style={iconStyle} />
                      }
                    >
                      Headcount
                    </Tabs.Tab>
                    {(hasPermission(permissions.canManageEmployee) ||
                      hasPermission(permissions.canAccessAdmin)) && (
                      <Tabs.Tab
                        value="team"
                        leftSection={<IconUsers style={iconStyle} />}
                      >
                        My Team
                      </Tabs.Tab>
                    )}

                    {hideForNow &&
                      hasPermission(permissions.canManageEmployee) && (
                        <Tabs.Tab
                          value="turnover"
                          leftSection={<IconRefresh style={iconStyle} />}
                        >
                          Turnover
                        </Tabs.Tab>
                      )}
                  </Tabs.List>
                  <Tabs.Panel value="headcount">
                    <HeadCountTab />
                  </Tabs.Panel>
                  <Tabs.Panel value="team">
                    <MyTeamTab />
                  </Tabs.Panel>
                  {hideForNow &&
                    hasPermission(permissions.canManageEmployee) && (
                      <Tabs.Panel value="turnover">
                        Turnover
                        <UserItem />
                      </Tabs.Panel>
                    )}
                </Tabs>
              </div>
            </div>
          </Card>
        </Grid.Col>
      </Grid>

      <Flex wrap="nowrap" gap={10} ref={columnWrapperRef}>
        {_.keys(groupedWidgets)?.map((columnNumber) => (
          <Stack
            gap={10}
            key={columnNumber}
            flex={1}
            style={{ overflow: 'auto' }}
          >
            {groupedWidgets?.[columnNumber]?.map((widget) => (
              <GenericActiveWidget
                key={widget?.id}
                widget={widget}
                isFetching={isWidgetFetching}
              />
            ))}
          </Stack>
        ))}
      </Flex>
    </PermissionGuard>
  );
}
