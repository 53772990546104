import moment from 'moment';
import { employeeStatuses } from 'utils/constants';

export const defaultFilterValues = {
  date: {
    from: moment().subtract(1, 'years'),
    to: moment(),
  },
  sort: {
    field: '',
    sortBy: '',
  },
  locations: [],
  employeeStatus: employeeStatuses.All,
};
