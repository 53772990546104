import { Flex, Grid, Paper, Text, rem } from '@mantine/core';
import { IconFileTypePdf, IconFolder } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { fileTypes } from 'utils/constants';
import FileContextMenu from './FileContextMenu';

export default function FileColumn({
  file,
  onClick,
  onDelete,
  onDownload,
  fileActions,
}) {
  const isFolder = file?.type === fileTypes.directory;
  return (
    <Grid.Col
      key={file.id}
      span={3}
      style={{ cursor: 'pointer' }}
      onClick={() => onClick?.(file)}
      position="absolute"
    >
      <Flex justify="flex-end">
        <FileContextMenu
          file={file}
          onDelete={onDelete}
          onDownload={onDownload}
          position="absolute"
          fileActions={fileActions}
        />
      </Flex>
      <Paper shadow="sm" radius="md" p="xl">
        <Flex
          mih={50}
          gap="md"
          justify="center"
          align="center"
          direction="column"
          wrap="wrap"
        >
          {isFolder && (
            <>
              <IconFolder style={{ width: rem(50), height: rem(50) }} />
              <Text>{file.name}</Text>
              <Text size="sm" c="dimmed">
                {file.metadata?.children ?? 0} Items
              </Text>
            </>
          )}
          {!isFolder && (
            <>
              <IconFileTypePdf style={{ width: rem(50), height: rem(50) }} />
              <Text>{file.name}</Text>
            </>
          )}
        </Flex>
      </Paper>
    </Grid.Col>
  );
}

FileColumn.propTypes = {
  file: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  fileActions: PropTypes.array,
};

FileColumn.defaultProps = {
  onClick: () => {},
  onDelete: () => {},
  onDownload: () => {},
  fileActions: [],
};
