import { Flex, Pagination, Stack, Text } from '@mantine/core';
import { IconCircuitResistor } from '@tabler/icons-react';
import { useRequests } from 'api/hooks';
import { Loader } from 'components/Loader';
import { usePermissions } from 'components/PermissionGuard/hooks';
import { TimeOffRequestPreview } from 'components/Requests/views';
import SignatureRequestPreview from 'components/Requests/views/SignatureRequestPreview';
import { SurveyPreview } from 'components/Survey/views';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { requestsCategories } from 'utils/constants';
import { usePagination } from 'utils/hooks';
import AllRequestsList from './AllRequestsList';

export default function AllRequests({
  onPreview,
  requestType,
  requestId,
  payload,
  displayStatus,
  limit,
  isRequestLoading,
}) {
  const { hasPermission, permissions } = usePermissions();

  const { state } = useLocation();

  const withPreloadedData = useMemo(
    () => !_.isEmpty(state?.preloadedRequest) || !_.isEmpty(state?.requestId),
    [state],
  );

  const { page, setPage, validateCurrentPage } = usePagination({ page: 1 });

  const { requests, isLoading, pagination } = useRequests({
    filters: {
      ...payload,
    },
    canManageEmployee: hasPermission(permissions.canManageEmployee),
    limit,
    page,
  });

  useEffect(() => {
    validateCurrentPage(pagination?.total_pages);
  }, [pagination, validateCurrentPage]);

  if (requestType === requestsCategories.Performance && requestId) {
    return <SurveyPreview requestId={requestId} />;
  }

  if (requestType === requestsCategories.TimeoffRecords && requestId) {
    return <TimeOffRequestPreview requestId={requestId} />;
  }

  if (requestType === requestsCategories.Signature && requestId) {
    return <SignatureRequestPreview requestId={requestId} />;
  }

  return (
    <>
      {_.isEmpty(requests) && !isLoading && (
        <Stack align="center" justify="center" flex={1} gap={5}>
          <IconCircuitResistor color="var(--mantine-color-blue-6)" size="70" />
          <Text fw={600} fz="xl">
            You don&apos;t have any notifications at the moment...
          </Text>
        </Stack>
      )}
      {isLoading || isRequestLoading || withPreloadedData ? (
        <Loader mt={10} isLoading />
      ) : (
        <>
          <AllRequestsList
            requests={requests}
            onPreview={onPreview}
            displayStatus={displayStatus}
          />
          {!limit && pagination?.total_pages > 1 && (
            <Flex justify="flex-end" mt={10}>
              <Pagination
                total={pagination?.total_pages}
                value={page}
                onChange={setPage}
              />
            </Flex>
          )}
        </>
      )}
    </>
  );
}

AllRequests.propTypes = {
  payload: PropTypes.object.isRequired,
  onPreview: PropTypes.func.isRequired,
  requestType: PropTypes.string,
  requestId: PropTypes.string,
  displayStatus: PropTypes.bool,
  isRequestLoading: PropTypes.bool,
  limit: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
};

AllRequests.defaultProps = {
  requestType: '',
  requestId: '',
  displayStatus: true,
  limit: null,
  isRequestLoading: false,
};
