import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Flex, NumberInput, Select, Stack } from '@mantine/core';
import { formatSelectorDataObj } from 'utils';
import { timeOffPolicyScheduleType } from 'utils/constants';
import {
  EveryOtherWeek,
  Monthly,
  Quarterly,
  TwiceMonth,
  TwiceYear,
  Weekly,
  Yearly,
} from './scheduleTypes';
import { AccrualOptions } from './accrual-options';
import { CarryOverRules } from './carryover-rules';

export default function AccrualSettings({ formValues, setValues }) {
  const typeProvider = {
    [timeOffPolicyScheduleType.weekly]: Weekly,
    [timeOffPolicyScheduleType.every_other_week]: EveryOtherWeek,
    [timeOffPolicyScheduleType.twice_a_month]: TwiceMonth,
    [timeOffPolicyScheduleType.monthly]: Monthly,
    [timeOffPolicyScheduleType.quarterly]: Quarterly,
    [timeOffPolicyScheduleType.twice_a_year]: TwiceYear,
    [timeOffPolicyScheduleType.yearly]: Yearly,
  };

  const TypeSelector =
    typeProvider[
      timeOffPolicyScheduleType[formValues.settings?.accrue?.scheduleType]
    ] ?? null;

  return (
    <Stack mb={20}>
      <Flex align="start" gap={5}>
        <Flex align="center" gap={5}>
          Employees accrue
          <NumberInput
            value={formValues?.settings?.accrue?.days ?? ''}
            onChange={(e) => setValues('settings.accrue.days', e)}
            hideControls
            allowNegative={false}
            maxLength={3}
            w={50}
            placeholder="day"
          />
          {Number(formValues?.settings?.accrue?.days) > 1 ? 'days' : 'day'}
          <Select
            value={formValues?.settings?.accrue?.scheduleType}
            data={formatSelectorDataObj(timeOffPolicyScheduleType)}
            onChange={(val) => {
              setValues('settings.accrue.scheduleType', val);
              if (!formValues.settings?.accrue?.period) {
                setValues('settings.accrue.period', {});
              }
            }}
            allowDeselect={false}
            placeholder="Select Period"
          />
        </Flex>
        {TypeSelector && (
          <TypeSelector
            formValues={formValues}
            onChange={setValues}
            scheduleType={formValues.settings?.accrue?.scheduleType}
          />
        )}
      </Flex>

      <Divider />

      <AccrualOptions formValues={formValues} onChange={setValues} />

      <Divider />

      <CarryOverRules formValues={formValues} onChange={setValues} />
    </Stack>
  );
}

AccrualSettings.propTypes = {
  formValues: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
};
