import { Button, Divider, Flex, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconCalendarTime, IconSunHigh } from '@tabler/icons-react';
import {
  useCreateEmployeeRequest,
  useLoadTimeOffBalance,
  useUserPolicy,
} from 'api/hooks';
import { queryKeys } from 'api/keys';
import { useAuthContext } from 'auth';
import { RequestTimeOffModal } from 'components/RequestTimeOffModal';
import _ from 'lodash';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { useQueryClient } from 'react-query';

import { WidgetWrapper } from 'components/WidgetSettings/widgets';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

const calculateTotalHours = (array) =>
  _.reduce(
    array,
    (prev, curr) => prev + (_.last(curr.accrued_time).balance ?? 0),
    0,
  );

const currentDate = moment().format('YYYY-MM-DD');

export default function TimeOff() {
  const { user } = useAuthContext();
  const { setNotifications } = useNotifications();
  const queryClient = useQueryClient();

  const [
    openedRequestModal,
    { open: openRequestModal, close: closeRequestModal },
  ] = useDisclosure(false);

  const { policies, isLoading: isPoliciesLoading } = useUserPolicy(user?.id);

  const { createEmployeeRequest, isLoading: isEmployeeRequestCreating } =
    useCreateEmployeeRequest({
      onSuccess: () => {
        closeRequestModal();
        setNotifications(['Time Off request created successfully']);
        queryClient.invalidateQueries([_.first(queryKeys.requests([]))]);
      },
    });

  const onCreateTimeOff = (data) => {
    createEmployeeRequest({
      timeoffDetails: data?.timeoffDetails,
      policy_id: Number(data?.policyId),
      policy_name:
        policies?.find((policy) => +policy.id === +data.policyId)?.name ??
        undefined,
      employee_id: user?.id,
      category: 'TimeoffRecords',
      content: data?.notes,
    });
  };

  const { timeOffBalance, isLoading: isTimeOffBalanceLoading } =
    useLoadTimeOffBalance({
      startDate: currentDate,
      endDate: currentDate,
    });

  const groupedTimeOff = useMemo(
    () => _.groupBy(timeOffBalance, 'category.name'),
    [timeOffBalance],
  );

  return (
    <WidgetWrapper
      title="Time Off"
      wrapperProps={{ h: 366 }}
      isLoading={isTimeOffBalanceLoading}
    >
      <RequestTimeOffModal
        opened={openedRequestModal}
        onClose={closeRequestModal}
        policies={policies}
        isLoading={isPoliciesLoading || isEmployeeRequestCreating}
        onCreate={onCreateTimeOff}
      />
      <Stack px="xl" justify="space-between" flex={1} gap={0}>
        <Flex my={10} align="center" justify="space-around">
          <CategoryBalance
            categoryName="Vacation"
            totalHours={calculateTotalHours(groupedTimeOff?.Vacation ?? [])}
          />
          <Divider orientation="vertical" />
          <CategoryBalance
            categoryName="Sick"
            totalHours={calculateTotalHours(groupedTimeOff?.Sick ?? [])}
          />
        </Flex>
        <Flex align="center" justify="center" mt="auto" gap={10}>
          <Button
            w="100%"
            leftSection={<IconCalendarTime />}
            onClick={openRequestModal}
          >
            Request Time Off
          </Button>
        </Flex>
      </Stack>
    </WidgetWrapper>
  );
}

function CategoryBalance({ categoryName, totalHours }) {
  return (
    <Stack align="center">
      <Text fz={23}>{categoryName}</Text>
      <Flex c="var(--mantine-color-blue-6)" align="center" justify="center">
        <IconSunHigh size={40} />
        <Text fz={35} fw="bold">
          {totalHours}
        </Text>
      </Flex>
      <Text ta="center">Hours Available</Text>
    </Stack>
  );
}

CategoryBalance.propTypes = {
  categoryName: PropTypes.string.isRequired,
  totalHours: PropTypes.number.isRequired,
};
