import {
  Button,
  Divider,
  Flex,
  Loader,
  Space,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import { clearCache } from 'api/clearCache';
import {
  useDisableCompanyIntegration,
  useEnabledCompanyIntegration,
  useLoadCompanyIntegrations,
  useLoadIntegrations,
} from 'api/hooks';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import _ from 'lodash';
import { useNotifications } from 'notifications/hooks/useNotifications';
import React, { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useStateDisclosure } from 'utils/hooks';
import { IntegrationsSkeleton } from './components';

const sectionsLabels = {
  enabled: 'Installed',
  disabled: 'Not Installed',
};

const confirmationModalMeta = {
  text: (state) =>
    state
      ? `Are you sure you want to ${
          state?.status === 'enabled' ? 'uninstall' : 'install'
        } ${_.capitalize(state?.provider_name)} ${state?.auth_type_name}`
      : '',

  title: (state) =>
    state
      ? `${_.capitalize(state?.provider_name)} ${state?.auth_type_name}`
      : '',
};

export default function IntegrationsTab() {
  const { setNotifications } = useNotifications();
  const queryClient = useQueryClient();

  const [
    openedConfirmationModal,
    {
      close: closeConfirmationModal,
      open: openConfirmationModal,
      state: confirmationModalState,
    },
  ] = useStateDisclosure(false);

  const { integrations, isLoading: isIntegrationsLoading } =
    useLoadIntegrations();
  const { companyIntegrations, isLoading: isCompanyIntegrationsLoading } =
    useLoadCompanyIntegrations();

  const {
    disableCompanyIntegration,
    isLoading: isDisablingCompanyIntegration,
    variables: disableId,
  } = useDisableCompanyIntegration({
    onSuccess: () => {
      closeConfirmationModal();
      clearCache.onChangeCompanyIntegrations(queryClient);
      setNotifications(['Uninstalled Successfully!']);
    },
  });

  const {
    enableCompanyIntegration,
    isLoading: isEnablingCompanyIntegration,
    variables: enableId,
  } = useEnabledCompanyIntegration({
    onSuccess: () => {
      closeConfirmationModal();
      clearCache.onChangeCompanyIntegrations(queryClient);
      setNotifications(['Installed Successfully!']);
    },
  });

  const isMutating =
    isEnablingCompanyIntegration || isDisablingCompanyIntegration;

  const groupedCompanyIntegrations = useMemo(() => {
    const emptyIntegrations = { enabled: [], disabled: [] };
    if (isIntegrationsLoading || isCompanyIntegrationsLoading)
      return emptyIntegrations;
    const grouped = integrations?.reduce((prev, curr) => {
      const isExist = _.some(
        companyIntegrations,
        (i) => i.name === curr.provider_name,
      );

      prev[isExist ? 'enabled' : 'disabled'].push(curr);
      return prev;
    }, emptyIntegrations);

    return grouped;
  }, [
    integrations,
    companyIntegrations,
    isIntegrationsLoading,
    isCompanyIntegrationsLoading,
  ]);

  return (
    <>
      <Space h="md" />
      <IntegrationsSkeleton
        isVisible={isIntegrationsLoading || isCompanyIntegrationsLoading}
      />

      <ConfirmationModal
        opened={openedConfirmationModal}
        onClose={closeConfirmationModal}
        text={confirmationModalMeta.text(confirmationModalState)}
        title={confirmationModalMeta.title(confirmationModalState)}
        onConfirm={() =>
          confirmationModalState?.status === 'enabled'
            ? disableCompanyIntegration(confirmationModalState?.id)
            : enableCompanyIntegration(confirmationModalState?.id)
        }
        isLoading={isMutating}
      />

      <EmptyDataMessage
        isVisible={
          !(isIntegrationsLoading || isCompanyIntegrationsLoading) &&
          _.isEmpty(integrations)
        }
      />

      {!(isIntegrationsLoading || isCompanyIntegrationsLoading) && (
        <Stack gap={10}>
          {_.keys(groupedCompanyIntegrations)
            .filter((key) => !_.isEmpty(groupedCompanyIntegrations[key]))
            .map((key, index, keysArr) => (
              <React.Fragment key={key}>
                <Text fw={500} fz={18}>
                  {sectionsLabels[key]}
                </Text>
                {_.map(groupedCompanyIntegrations[key], (item) => {
                  const isEnablingCurrentItem =
                    isEnablingCompanyIntegration && item?.id === enableId;
                  const isDisablingCurrentItem =
                    isDisablingCompanyIntegration && item?.id === disableId;

                  const isMutatingCurrentItem =
                    isEnablingCurrentItem || isDisablingCurrentItem;
                  const hasSingleSignOn =
                    key === 'disabled' &&
                    groupedCompanyIntegrations.enabled.some(
                      (i) => i?.auth_type === 1,
                    );

                  return (
                    <Flex gap={20} key={item?.id}>
                      <img
                        src={item?.logo}
                        alt="integration logo"
                        style={{
                          width: '150px',
                          height: '150px',
                          marginLeft: '20px',
                          objectFit: 'contain',
                          backgroundColor: 'white',
                          borderRadius: '5px',
                          border: '1px solid var(--mantine-color-gray-2)',
                        }}
                      />
                      <Stack gap={20} justify="space-around">
                        <Text mt="auto" style={{ textTransform: 'capitalize' }}>
                          {item?.provider_name} {item?.auth_type_name}
                        </Text>
                        <Tooltip
                          opened={hasSingleSignOn ? undefined : false}
                          label="One Single Sign On has already been activated"
                          withArrow
                        >
                          <Button
                            mb="auto"
                            w="max-content"
                            onClick={() =>
                              openConfirmationModal({ ...item, status: key })
                            }
                            disabled={isMutating || hasSingleSignOn}
                            rightSection={
                              isMutatingCurrentItem ? (
                                <Loader size={14} />
                              ) : undefined
                            }
                          >
                            {key === 'enabled' ? 'Uninstall' : 'Install'}
                          </Button>
                        </Tooltip>
                      </Stack>
                    </Flex>
                  );
                })}
                {_.last(keysArr) !== key && <Divider />}
              </React.Fragment>
            ))}
        </Stack>
      )}
    </>
  );
}
