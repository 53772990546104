import { Button, Select, Stack, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { DateFormInput } from 'components/DateFormInput';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { formatSelectorData, genericValidators } from 'utils';
import { employeeStatusTypes } from 'utils/constants';

const isTerminationStatus = (statuses, statusId) => {
  const statusItem = statuses?.find(
    (item) => _.toString(item?.id) === statusId,
  );
  return statusItem?.type === employeeStatusTypes.terminated;
};

const isResignationVoluntary = (val) => val === 'Resignation (Voluntary)';

export default function ChangeEmployeeStatusForm({
  onSubmit,
  statuses,
  status,
  isLoading,
  terminationTypes,
  terminationRegrettable,
  terminationReasons,
}) {
  const form = useForm({
    initialValues: {
      start_date: status?.start_date ?? '',
      status_id: _.toString(status?.status_id) ?? '',
      comment: status?.comment ?? '',
      termination_type: status?.termination_data?.termination_type ?? '',
      termination_regrettable:
        status?.termination_data?.termination_regrettable ?? '',
      reason_id: _.toString(status?.reason_id) ?? '',
    },
    validate: {
      start_date: (val) => genericValidators.notEmpty(val),
      status_id: (val) => genericValidators.notEmpty(val),
      termination_type: (val, { status_id: statusId }) => {
        if (!isTerminationStatus(statuses, statusId)) return null;
        return genericValidators.notEmpty(val);
      },
      termination_regrettable: (val, { termination_type: terminationType }) => {
        if (!isResignationVoluntary(terminationType)) return null;
        return genericValidators.notEmpty(val);
      },
      reason_id: (val, { status_id: statusId }) => {
        if (!isTerminationStatus(statuses, statusId)) return null;
        return genericValidators.notEmpty(val);
      },
    },
  });

  const isTerminationTypeSelected = useMemo(() => {
    if (form.values.status_id) {
      return isTerminationStatus(statuses, form.values.status_id);
    }

    return false;
  }, [form.values.status_id, statuses]);

  return (
    <form
      onSubmit={form.onSubmit((data) =>
        onSubmit({
          ...data,
          isTerminationTypeSelected,
          isResignationVoluntary: isResignationVoluntary(
            form.values.termination_type,
          ),
        }),
      )}
      noValidate
    >
      <Stack>
        <DateFormInput
          value={form.values.start_date}
          onChange={(val) =>
            form.setFieldValue('start_date', moment(val).format('YYYY-MM-DD'))
          }
          required
          field={{ displayName: 'Start Date' }}
          error={form.errors.start_date}
        />
        <Select
          placeholder="Status"
          allowDeselect={false}
          data={formatSelectorData(
            statuses?.filter((data) => data.id !== 0),
            { value: 'id', label: 'name' },
          )}
          label="Status"
          required
          value={form.values.status_id}
          onChange={(val) => form.setFieldValue('status_id', val)}
          error={form.errors.status_id}
        />
        {isTerminationTypeSelected && (
          <Select
            {...form.getInputProps('termination_type')}
            data={terminationTypes}
            allowDeselect={false}
            label="Termination Type"
            placeholder="Termination Type"
            required
          />
        )}
        {isTerminationTypeSelected &&
          isResignationVoluntary(form.values.termination_type) && (
            <Select
              {...form.getInputProps('termination_regrettable')}
              data={terminationRegrettable}
              allowDeselect={false}
              label="Termination Regrettable"
              placeholder="Termination Regrettable"
              required
            />
          )}
        {isTerminationTypeSelected && (
          <Select
            {...form.getInputProps('reason_id')}
            data={formatSelectorData(terminationReasons, {
              value: 'id',
              label: 'name',
            })}
            allowDeselect={false}
            label="Termination Reason"
            placeholder="Termination Reason"
            required
          />
        )}
        <Textarea
          label="Comment"
          resize="block"
          rows={4}
          placeholder="Comment"
          value={form.values.comment}
          onChange={(e) => form.setFieldValue('comment', e.currentTarget.value)}
        />
        <Button disabled={isLoading} type="submit" w="max-content" ml="auto">
          Submit
        </Button>
      </Stack>
    </form>
  );
}

ChangeEmployeeStatusForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  statuses: PropTypes.array.isRequired,
  status: PropTypes.object,
  isLoading: PropTypes.bool,
  terminationTypes: PropTypes.array,
  terminationRegrettable: PropTypes.array,
  terminationReasons: PropTypes.array,
};

ChangeEmployeeStatusForm.defaultProps = {
  status: {},
  terminationTypes: [],
  terminationRegrettable: [],
  terminationReasons: [],
  isLoading: false,
};
