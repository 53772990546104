import { Table, rem, Flex, Loader, Pill } from '@mantine/core';
import moment from 'moment';

import {
  IconCircleCheck,
  IconCircleX,
  IconTrash,
  IconEdit,
} from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { ContextMenu } from 'components/ContextMenu';

export default function PerformanceManagementTable({
  performanceCycles,
  onEditCycle,
  onDeleteCycle,
  deleteState,
}) {
  return (
    <Table highlightOnHover>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Name</Table.Th>
          <Table.Th>Type</Table.Th>
          <Table.Th>Status</Table.Th>
          <Table.Th>Feedback</Table.Th>
          <Table.Th>Assessment</Table.Th>
          <Table.Th>Created At</Table.Th>
          <Table.Th>Updated At</Table.Th>
        </Table.Tr>
      </Table.Thead>

      <Table.Tbody>
        {performanceCycles?.map((cycle) => (
          <PerformanceCycleRow
            key={cycle.id}
            cycle={cycle}
            onDelete={onDeleteCycle}
            onEdit={onEditCycle}
            isDeleting={
              deleteState.isDeleting && deleteState.itemId === cycle.id
            }
          />
        ))}
      </Table.Tbody>
    </Table>
  );
}
function PerformanceCycleRow({ cycle, onEdit, onDelete, isDeleting }) {
  const menuItems = [
    {
      label: 'Edit',
      icon: IconEdit,
      onClick: () => {
        onEdit(cycle);
      },
    },
    {
      label: 'Delete',
      icon: IconTrash,
      onClick: () => {
        onDelete(cycle);
      },
    },
  ];
  const createdAt = cycle.created_at
    ? moment(cycle.created_at).format('DD/MM/YYYY, h:mm a')
    : '';
  const updatedAt = cycle.updated_at
    ? moment(cycle.updated_at).format('DD/MM/YYYY, h:mm a')
    : '';
  return (
    <Table.Tr opacity={isDeleting ? 0.5 : 1}>
      <Table.Td>
        <Flex align="center" gap="md">
          {!isDeleting && <ContextMenu menuItems={menuItems} />}
          {isDeleting && <Loader color="blue" size={19} />}
          {cycle.name}
        </Flex>
      </Table.Td>
      <Table.Td>
        <Pill style={{ textTransform: 'capitalize' }}>
          {cycle.type_name} Date
        </Pill>
      </Table.Td>
      <Table.Td>
        <Pill>{cycle.status_name}</Pill>
      </Table.Td>

      <Table.Td>
        {cycle.feedback ? (
          <IconCircleCheck
            color="green"
            style={{ width: rem(20), height: rem(20) }}
          />
        ) : (
          <IconCircleX
            color="red"
            style={{ width: rem(20), height: rem(20) }}
          />
        )}
      </Table.Td>
      <Table.Td>
        {cycle.assessment ? (
          <IconCircleCheck
            color="green"
            style={{ width: rem(20), height: rem(20) }}
          />
        ) : (
          <IconCircleX
            color="red"
            style={{ width: rem(20), height: rem(20) }}
          />
        )}
      </Table.Td>
      <Table.Td>{createdAt}</Table.Td>
      <Table.Td>{updatedAt}</Table.Td>
    </Table.Tr>
  );
}

PerformanceCycleRow.propTypes = {
  cycle: PropTypes.object.isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
};
PerformanceCycleRow.defaultProps = {
  onEdit: () => {},
  onDelete: () => {},
  isDeleting: false,
};
PerformanceManagementTable.propTypes = {
  performanceCycles: PropTypes.array,
  onEditCycle: PropTypes.func,
  onDeleteCycle: PropTypes.func,
  deleteState: PropTypes.object,
};
PerformanceManagementTable.defaultProps = {
  performanceCycles: [],
  onEditCycle: () => {},
  onDeleteCycle: () => {},
  deleteState: {},
};
