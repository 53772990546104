import { Flex, Space, Table, Title } from '@mantine/core';
import { clearCache } from 'api/clearCache';
import {
  useCreateEmployeeStatus,
  useDeleteEmployeeStatus,
  useLoadEmployeeStatuses,
  useUpdateEmployeeStatus,
} from 'api/hooks';
import { AddButton } from 'components/Buttons';
import { ConfirmDeleteModal } from 'components/ConfirmDeleteModal';
import { CreateEditEmployeeStatusModal } from 'components/CreateEditEmployeeStatusModal';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import { Loader } from 'components/Loader';
import { SettingsTableHead } from 'components/SettingsTable';
import _ from 'lodash';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { useQueryClient } from 'react-query';
import { useStateDisclosure } from 'utils/hooks';
import { EmployeeStatusesRow } from '../components';

const headerList = ['Name', 'People', ''];

export default function EmployeeStatuses() {
  const queryClient = useQueryClient();

  const [
    openedStatusModal,
    { close: closeStatusModal, open: openStatusModal, state: statusState },
  ] = useStateDisclosure();

  const [
    openedDeleteStatusModal,
    {
      open: openDeleteStatusModal,
      close: closeDeleteStatusModal,
      state: deleteStatusState,
    },
  ] = useStateDisclosure(false);

  const { statuses, isLoading: isEmployeeStatusesLoading } =
    useLoadEmployeeStatuses();

  const { setNotifications } = useNotifications();

  const { createEmployeeStatus, isLoading: isEmployeeStatusCreating } =
    useCreateEmployeeStatus({
      onSuccess: () => {
        setNotifications(['Employee Status Created Successfully']);
        closeStatusModal();
        clearCache.onChangeEmployeeStatus(queryClient);
      },
    });

  const { deleteEmployeeStatus, isLoading: isDeletingEmployeeStatus } =
    useDeleteEmployeeStatus({
      onSuccess: () => {
        setNotifications(['Employee Status Deleted Successfully']);
        closeDeleteStatusModal();
        clearCache.onChangeEmployeeStatus(queryClient);
      },
    });

  const { updateEmployeeStatus, isLoading: isUpdatingEmployeeStatus } =
    useUpdateEmployeeStatus({
      onSuccess: () => {
        setNotifications(['Employee Status Updated Successfully']);
        closeStatusModal();
        clearCache.onChangeEmployeeStatus(queryClient);
      },
    });

  return (
    <>
      <CreateEditEmployeeStatusModal
        opened={openedStatusModal}
        onClose={closeStatusModal}
        statusState={statusState}
        onCreate={createEmployeeStatus}
        onEdit={(data) => {
          updateEmployeeStatus({ statusId: statusState?.id, ...data });
        }}
        isLoading={isEmployeeStatusCreating || isUpdatingEmployeeStatus}
      />
      <ConfirmDeleteModal
        opened={openedDeleteStatusModal}
        onClose={closeDeleteStatusModal}
        onDelete={() => {
          deleteEmployeeStatus(deleteStatusState?.id);
        }}
        isLoading={isDeletingEmployeeStatus}
      />
      <Flex justify="space-between" align="center">
        <Title fz="xl">Employee Statuses</Title>
        <AddButton onClick={() => openStatusModal({ modalType: 'Create' })}>
          Add Status
        </AddButton>
      </Flex>
      <Space h="xs" />
      {!_.isEmpty(statuses) && (
        <Table>
          <SettingsTableHead headerList={headerList} />
          <Table.Tbody>
            {_.map(statuses, (status) => (
              <EmployeeStatusesRow
                key={status.id}
                status={status}
                onEditEmployeeStatus={(data) =>
                  openStatusModal({ ...data, modalType: 'Edit' })
                }
                onDeleteEmployeeStatus={openDeleteStatusModal}
                isLoading={isDeletingEmployeeStatus || isUpdatingEmployeeStatus}
              />
            ))}
          </Table.Tbody>
        </Table>
      )}
      <Loader isLoading={isEmployeeStatusesLoading} />
      <EmptyDataMessage
        isVisible={!isEmployeeStatusesLoading && _.isEmpty(statuses)}
      />
    </>
  );
}
