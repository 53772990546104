import { MultiSelect } from '@mantine/core';
import { useEmployees } from 'api/hooks';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { excludeFromSelectorData, formatSelectorData } from 'utils';

export default function EmployeeMultipleAutocomplete({
  values,
  setValues,
  selectedEmployee,
  excludeIds,
  ...props
}) {
  const [search, setSearch] = useState('');
  const [multiselectData, setMultiSelectData] = useState([]);

  useEmployees(
    { canManageEmployee: true, search },
    {
      onSuccess: (data) => {
        const candidateList = _.get(data, 'data', []);
        setMultiSelectData((prev) => {
          const newData = formatSelectorData(candidateList, {
            value: 'id',
            label: 'full_name',
          });
          return _.uniqBy(
            prev.concat(excludeFromSelectorData(newData, excludeIds)),
            'value',
          );
        });
      },
      enabled: search?.length > 2,
    },
  );

  const handleChangeWithDebounce = _.debounce(async (value) => {
    setSearch(value);
  }, 300);

  useEffect(
    () => () => handleChangeWithDebounce.cancel(),
    [handleChangeWithDebounce],
  );

  useEffect(() => {
    setMultiSelectData(selectedEmployee);
  }, [selectedEmployee]);

  return (
    <MultiSelect
      mb="md"
      value={values}
      label="Pick employees"
      placeholder={values?.length === 0 ? 'Tap employee name' : undefined}
      data={multiselectData}
      onChange={setValues}
      onSearchChange={handleChangeWithDebounce}
      searchable
      {...props}
    />
  );
}

EmployeeMultipleAutocomplete.propTypes = {
  values: PropTypes.array.isRequired,
  setValues: PropTypes.func.isRequired,
  selectedEmployee: PropTypes.array,
  excludeIds: PropTypes.array,
};

EmployeeMultipleAutocomplete.defaultProps = {
  selectedEmployee: [],
  excludeIds: [],
};
