import { Flex, Paper, Pill, Text } from '@mantine/core';
import moment from 'moment';
import PropTypes from 'prop-types';

import { surveyTypes } from 'utils/constants';

export default function RequestRow({ request, onClick, requestType }) {
  const survey = request.data;
  const createDate =
    requestType === 'completed'
      ? request.data?.response_at
      : request?.created_at;
  const createdAt = createDate
    ? moment(createDate).format('DD/MM/YYYY, h:mm a')
    : '';

  const surveyType = surveyTypes.find(
    (item) => item.value === `${survey?.type}`,
  );

  const surveyReferTo = request?.destination_to
    ? ` for ${request?.destination_to?.first_name} ${request?.destination_to?.last_name}`
    : '';
  return (
    <Paper
      shadow="sm"
      radius="md"
      px="xl"
      py="md"
      style={{ cursor: 'pointer' }}
      withBorder
      onClick={() => onClick(request)}
    >
      <Text fw={700} size="lg">
        {request?.title}
      </Text>
      <Flex gap="sm">
        <Text c="dimmed" size="sm">
          {createdAt}
        </Text>
        {surveyType && <Pill>{surveyType?.label}</Pill>}
      </Flex>

      {!survey && <Text mt="xs">{request?.content}</Text>}
      {survey && (
        <Flex gap="sm">
          <Text size="md">Please fill out survey{surveyReferTo}:</Text>
          <Text size="md" fw={700}>
            {survey.name}
          </Text>
        </Flex>
      )}
    </Paper>
  );
}

RequestRow.propTypes = {
  request: PropTypes.object.isRequired,
  requestType: PropTypes.string,
  onClick: PropTypes.func,
};

RequestRow.defaultProps = {
  onClick: () => {},
  requestType: 'request',
};
