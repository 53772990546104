import {
  Avatar,
  Divider,
  Flex,
  InputError,
  Loader,
  Stack,
} from '@mantine/core';
import { useLoadTotalEmployeesByJobInfoFilter } from 'api/hooks';
import { filterOnlySelectedValues } from 'components/PerformanceManagement/forms/utils';
import { Skeleton } from 'components/Skeleton';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { EmployeeFilterList } from './components';

export function EmployeesFilter({
  onSelectFilter,
  selectedValues,
  error,
  employeeFilters,
  isLookupsLoading,
}) {
  const { employeeCount, isLoading: isEmployeeCountLoading } =
    useLoadTotalEmployeesByJobInfoFilter(
      { filters: filterOnlySelectedValues(selectedValues) },
      { enabled: !_.isEmpty(filterOnlySelectedValues(selectedValues)) },
    );

  return (
    <Stack gap={0}>
      <Skeleton isVisible={isLookupsLoading} count={5} />
      <InputError mb={3}>{error}</InputError>
      {!_.isEmpty(employeeFilters) && (
        <Stack h="100%" pos="relative" gap={0}>
          <Stack
            pos="sticky"
            top={60}
            gap={0}
            style={{ zIndex: 100 }}
            bg="var(--mantine-color-gray-1)"
          >
            <Flex justify="space-between" px={15} my={5}>
              Filter Employees By
              <Flex
                gap={10}
                c={employeeCount || isEmployeeCountLoading ? '' : 'orange'}
                align="center"
              >
                <Avatar size={20} variant="filled" />
                {isEmployeeCountLoading ? (
                  <Loader size={20} />
                ) : (
                  employeeCount
                )}{' '}
                Employees Included
              </Flex>
            </Flex>
            <Divider orientation="horizontal" />
          </Stack>
          <EmployeeFilterList
            filteredData={employeeFilters}
            selectedValues={selectedValues}
            onSelectFilter={onSelectFilter}
          />
        </Stack>
      )}
    </Stack>
  );
}

EmployeesFilter.propTypes = {
  onSelectFilter: PropTypes.func.isRequired,
  selectedValues: PropTypes.object.isRequired,
  error: PropTypes.string,
  employeeFilters: PropTypes.object,
  isLookupsLoading: PropTypes.bool,
};

EmployeesFilter.defaultProps = {
  error: '',
  employeeFilters: {},
  isLookupsLoading: false,
};
