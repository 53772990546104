import { Box, Flex, Table } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { ContextMenu } from 'components/ContextMenu';
import PropTypes from 'prop-types';

export default function EmploymentStatusRecordRow({
  record,
  onEdit,
  onDelete,
}) {
  const menuItems = [
    {
      label: 'Edit',
      icon: IconEdit,
      onClick: () => onEdit(record),
    },
    {
      label: 'Delete',
      icon: IconTrash,
      onClick: () => onDelete(record),
    },
  ];

  return (
    <Table.Tr>
      <Table.Td style={{ position: 'relative' }}>
        {record?.active && (
          <Box
            pos="absolute"
            left={-2}
            top="50%"
            bg="green"
            w={7}
            h={7}
            style={{ transform: 'translateY(-50%)', borderRadius: '50%' }}
          />
        )}
        <Box pl={5}>{record?.start_date}</Box>
      </Table.Td>
      <Table.Td>{record?.status?.name}</Table.Td>
      <Table.Td>{record?.comment}</Table.Td>
      <Table.Td>
        <Flex justify="end">
          <ContextMenu menuItems={menuItems} />
        </Flex>
      </Table.Td>
    </Table.Tr>
  );
}

EmploymentStatusRecordRow.propTypes = {
  record: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
