import { LoadingOverlay, Text, Flex } from '@mantine/core';

export default function CandidatesTab() {
  return (
    <>
      <h2>Candidates</h2>
      <LoadingOverlay
        visible={false}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />

      <Flex align="center" justify="center">
        <Text fw={500} size="xl" c="dimmed">
          There are no records
        </Text>
      </Flex>
    </>
  );
}
