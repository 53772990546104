import { Box, Divider, Stack } from '@mantine/core';
import {
  IconCalendar,
  IconDeviceDesktopAnalytics,
  IconFolder,
  IconHome2,
  IconLogout,
  IconMail,
  IconSettings,
  IconSettingsBolt,
  IconUserCircle,
  IconUserSearch,
  IconUsersGroup,
} from '@tabler/icons-react';
import { useLoadBuildVersion } from 'api/hooks';
import { useAuthContext } from 'auth';
import { PermissionGuard } from 'components/PermissionGuard';
import { usePermissions } from 'components/PermissionGuard/hooks';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { tabNames } from 'utils/constants';
import { SidebarItem } from '../SidebarItem';
import classes from './Sidebar.module.css';

export default function Sidebar({ desktopOpened }) {
  const { logout } = useAuthContext();
  const { permissions } = usePermissions();

  const { buildVersion } = useLoadBuildVersion();

  const formattedBuildVersion = `${buildVersion?.version}.${buildVersion?.build_number}, ${buildVersion?.date}`;

  return (
    <nav className={classes.navbar}>
      <NavLink to="/" className={classes.logo}>
        <svg
          fill="none"
          height="36"
          viewBox="0 0 24 24"
          width="36"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 8L12 3L16 5.5M4 8V16L12 21M4 8L8 10.5M12 13L20 8M12 13V21M12 13L8 10.5M20 8V16L12 21M20 8L16 5.5M8 10.5L16 5.5"
            stroke="var(--dark-white)"
          />
        </svg>
      </NavLink>
      <div className={classes.navbarMain}>
        <Stack justify="start" gap="xs">
          <SidebarItem
            link="/"
            tabName={tabNames.dashboard}
            desktopOpened={desktopOpened}
            icon={IconHome2}
            label="Dashboard"
          />
          <Divider my="xs" />

          <PermissionGuard permission={permissions.canViewMyProfile}>
            <SidebarItem
              icon={IconUserCircle}
              label="My Info"
              link="/my-profile"
              tabName={tabNames.myProfile}
              desktopOpened={desktopOpened}
            />
          </PermissionGuard>

          <PermissionGuard permission={permissions.canViewEmployees}>
            <SidebarItem
              icon={IconUsersGroup}
              label="Employees"
              link="/employees"
              tabName={tabNames.employees}
              desktopOpened={desktopOpened}
            />
          </PermissionGuard>

          <PermissionGuard permission={permissions.canAccessHiring}>
            <SidebarItem
              icon={IconUserSearch}
              label="Hiring"
              tabName={tabNames.hiring}
              link="/hiring"
              desktopOpened={desktopOpened}
            />
          </PermissionGuard>
          <PermissionGuard permission={permissions.canManageRequests}>
            <SidebarItem
              icon={IconMail}
              label="Requests"
              tabName={tabNames.requests}
              link="/requests"
              desktopOpened={desktopOpened}
            />
          </PermissionGuard>
          <PermissionGuard permission={permissions.canAccessReports}>
            <SidebarItem
              link="/reports"
              tabName={tabNames.reports}
              icon={IconDeviceDesktopAnalytics}
              label="Reports"
              desktopOpened={desktopOpened}
            />
          </PermissionGuard>
          <PermissionGuard permission={permissions.canManageEmployee}>
            <SidebarItem
              link="/files"
              tabName={tabNames.files}
              icon={IconFolder}
              label="Files"
              desktopOpened={desktopOpened}
            />
          </PermissionGuard>
          <PermissionGuard permission={permissions.canManageEmployee}>
            <SidebarItem
              link="/calendar"
              tabName={tabNames.calendar}
              icon={IconCalendar}
              label="Calendar"
              desktopOpened={desktopOpened}
            />
          </PermissionGuard>
        </Stack>
      </div>
      <Divider my="sm" styles={{ marginTop: '20px', marginBottom: '20px' }} />
      <Stack justify="center" gap="xs">
        <PermissionGuard permission={permissions.canAccessSettings}>
          <SidebarItem
            icon={IconSettings}
            label="Settings"
            link="/settings"
            tabName={tabNames.settings}
            desktopOpened={desktopOpened}
          />
        </PermissionGuard>
        <PermissionGuard permission={permissions.canAccessAdmin}>
          <SidebarItem
            icon={IconSettingsBolt}
            label="Administration"
            link="/administration"
            tabName={tabNames.administration}
            desktopOpened={desktopOpened}
          />
        </PermissionGuard>
        <SidebarItem icon={IconLogout} label="Logout" onClick={logout} />
        {!desktopOpened && (
          <Box c="gray" fz="xs" ml={8}>
            Ver {formattedBuildVersion}
          </Box>
        )}
      </Stack>
    </nav>
  );
}

Sidebar.propTypes = {
  desktopOpened: PropTypes.bool,
};
Sidebar.defaultProps = {
  desktopOpened: false,
};
