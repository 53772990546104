import { Loader, Select } from '@mantine/core';
import { IconWorld } from '@tabler/icons-react';
import { useUpdateEmployeeLanguage } from 'api/hooks';
import i18next from 'i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { formatSelectorData } from 'utils';

export default function LanguageSelector({
  languages,
  userSelectedLanguage,
  employeeId,
  refetchUserData,
}) {
  const [selectedLanguage, setSelectedLanguage] = useState();

  const { updateEmployeeLanguage, isLoading: isUpdating } =
    useUpdateEmployeeLanguage({
      onSuccess: () => {
        refetchUserData();
      },
    });

  useEffect(() => {
    setSelectedLanguage(_.toString(userSelectedLanguage?.id));
  }, [userSelectedLanguage]);

  return (
    <Select
      data={formatSelectorData(languages, {
        value: 'id',
        label: 'code',
      })}
      rightSection={isUpdating ? <Loader size={15} /> : <IconWorld />}
      value={selectedLanguage}
      placeholder="EN"
      onChange={(val) => {
        setSelectedLanguage(val);
        const langObj = languages?.find((lang) => lang.id === +val);
        i18next.changeLanguage(langObj.code);
        updateEmployeeLanguage({ langId: val, employeeId });
      }}
      allowDeselect={false}
      disabled={isUpdating}
    />
  );
}

LanguageSelector.propTypes = {
  languages: PropTypes.array.isRequired,
  userSelectedLanguage: PropTypes.object,
  refetchUserData: PropTypes.func.isRequired,
  employeeId: PropTypes.number,
};

LanguageSelector.defaultProps = {
  userSelectedLanguage: {},
  employeeId: -1,
};
