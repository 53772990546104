import Code from '@tiptap/extension-code';
import { variablePattern, variablePatternG } from 'utils/constants';
import { markInputRule, mergeAttributes } from '@tiptap/react';

export const Placeholder = Code.extend({
  addOptions() {
    return {
      HTMLAttributes: { class: 'templatePlaceholder' },
    };
  },

  addInputRules() {
    return [
      markInputRule({
        find: variablePattern,
        type: this.type,
      }),
    ];
  },

  addPasteRules() {
    return [
      markInputRule({
        find: variablePatternG,
        type: this.type,
      }),
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'code',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        class: 'templatePlaceholder',
      }),
      0,
    ];
  },

  parseHTML() {
    return [
      {
        tag: 'code',
      },
    ];
  },

  addAttributes() {
    return {
      ...this.parent?.(),
      class: 'templatePlaceholder',
      variable: {
        default: 'template',
      },
    };
  },

  addKeyboardShortcuts() {
    return {
      ArrowRight: () => {
        const { state } = this.editor;
        const { from, to } = state.selection;

        if (from > 1 && from === to) {
          let codeOnLeft = false;
          state.doc.nodesBetween(from - 1, to - 1, (node) => {
            const code = node.marks.find(
              (markItem) => markItem.type.name === 'code',
            );
            if (code) codeOnLeft = true;
          });

          let noCodeUnderCursor = true;
          state.doc.nodesBetween(from, to, (node) => {
            const code = node.marks.find(
              (markItem) => markItem.type.name === 'code',
            );
            if (code) noCodeUnderCursor = false;
          });

          let nothingOnRight = true;
          state.doc.nodesBetween(from + 1, to + 1, (node) => {
            if (node) nothingOnRight = false;
          });

          if (codeOnLeft && noCodeUnderCursor && nothingOnRight) {
            return this.editor.chain().unsetCode().insertContent(' ').run();
          }
        }

        return false;
      },
      'Shift-{': () => {
        if (this.editor.state.selection.empty) {
          return false;
        }
        return this.editor.chain().setCode().run();
      },
    };
  },
});
