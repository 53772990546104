import {
  Button,
  Flex,
  Input,
  NumberInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  TextInput,
  Textarea,
} from '@mantine/core';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useForm } from '@mantine/form';
import { genericValidators } from 'utils';
import _ from 'lodash';
import classNames from './BoardingTaskForm.module.css';

const dueDatesValues = {
  null: 'none',
  0: 'on-hire-date',
  custom: 'custom-date',
};

export default function BoardingTaskForm({
  onSubmit,
  taskState,
  list,
  hireDate,
  isLoading,
}) {
  const form = useForm({
    initialValues: {
      name: taskState?.name ?? '',
      dueDate:
        taskState?.date_due === undefined
          ? 'none'
          : dueDatesValues[taskState?.date_due] ?? dueDatesValues.custom,
      date: taskState?.date_due ? Math.abs(taskState?.date_due) : '',
      beforeAfterSelector: taskState?.date_due < 0 ? 'Before' : 'After',
      description: taskState?.description ?? '',
      tasklistId: _.toString(taskState?.tasklist_id) ?? '',
    },
    validate: {
      name: (value) => genericValidators.notEmpty(value, 'Name is empty'),
      tasklistId: (value) => genericValidators.notEmpty(value, 'List is empty'),
      date: (value, { dueDate }) => {
        if (dueDate !== dueDatesValues.custom) return null;
        const errors = [];
        const emptyCheckError = genericValidators.notEmpty(
          value,
          'Should not be empty',
        );
        const minimumError = genericValidators.lessThanOrEqualTo(
          +value,
          9999,
          'Must be in the range of 0 to 9999',
        );

        if (emptyCheckError) errors.push(emptyCheckError);
        if (minimumError) errors.push(minimumError);

        return !_.isEmpty(errors) ? errors : null;
      },
    },
  });

  const preSubmit = (data) => {
    const {
      beforeAfterSelector,
      tasklistId,
      description,
      date,
      dueDate,
      name,
    } = data;

    let formattedDate = null;

    if (dueDate === dueDatesValues[0]) {
      formattedDate = 0;
    } else if (dueDate === dueDatesValues.custom) {
      formattedDate =
        beforeAfterSelector === 'Before' ? _.toString(-date) : date;
    }

    onSubmit({
      name,
      tasklist_id: tasklistId,
      description,
      date_due: formattedDate,
      id: taskState?.id,
    });
  };

  const isNotAvailableList = useMemo(
    () =>
      !_.some(list, (item) => item?.name === taskState?.tasklist_name) &&
      taskState?.modalType === 'Edit',
    [list, taskState],
  );

  return (
    <form onSubmit={form.onSubmit(preSubmit)} noValidate>
      <Stack>
        <TextInput {...form.getInputProps('name')} label="Name" />
        <Select
          {...form.getInputProps('tasklistId')}
          label="List"
          disabled={isNotAvailableList}
          placeholder="Select List"
          data={isNotAvailableList ? [form.values.tasklistId] : list}
          allowDeselect={false}
        />
        <RadioGroup label="Due date" {...form.getInputProps('dueDate')}>
          <Stack>
            <Radio value="none" label="None" />
            <Radio
              value="on-hire-date"
              label={
                <Text fz="inherit" fw="inherit">
                  On Hire date{' '}
                  {hireDate && (
                    <span className={classNames.hireDate}>({hireDate})</span>
                  )}
                </Text>
              }
            />
            <Radio
              my="auto"
              value="custom-date"
              styles={{ body: { display: 'flex', alignItems: 'center' } }}
              label={
                <Flex gap={10} align="center">
                  <NumberInput
                    w="70px"
                    hideControls
                    placeholder="days"
                    disabled={form.values.dueDate !== dueDatesValues.custom}
                    {...form.getInputProps('date')}
                    error={!!form.errors.date}
                    allowNegative={false}
                  />
                  <Select
                    w="40%"
                    {...form.getInputProps('beforeAfterSelector')}
                    defaultValue="After"
                    data={['After', 'Before']}
                    disabled={form.values.dueDate !== dueDatesValues.custom}
                    allowDeselect={false}
                  />
                  <Text>hire date</Text>
                </Flex>
              }
            />
            {form.errors.date && <Input.Error>{form.errors.date}</Input.Error>}
          </Stack>
        </RadioGroup>
        <Textarea
          rows={4}
          {...form.getInputProps('description')}
          label="Description"
        />
        <Button disabled={isLoading} type="submit">
          Submit
        </Button>
      </Stack>
    </form>
  );
}

BoardingTaskForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  taskState: PropTypes.object,
  hireDate: PropTypes.string,
  isLoading: PropTypes.bool,
};

BoardingTaskForm.defaultProps = {
  taskState: {},
  hireDate: '',
  isLoading: false,
};
