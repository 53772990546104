import { Space, Tabs, Text } from '@mantine/core';
import React, { useState } from 'react';
import {
  CandidateStatuses,
  CandidateSources,
  EmailTemplates,
  OfferTemplates,
} from './hiring-tabs';

const tabs = [
  { value: 'candidateStatuses', title: 'Candidate Statuses' },
  { value: 'candidateSources', title: 'Candidate Sources' },
  { value: 'emailTemplates', title: 'Email Templates' },
  { value: 'offerTemplates', title: 'Offer Templates' },
];

export default function HiringProviderTab() {
  const [selectedTab, setSelectedTab] = useState(tabs[0].value);

  return (
    <>
      <Space h="md" />
      <Tabs
        onChange={setSelectedTab}
        value={selectedTab}
        orientation="vertical"
        keepMounted={false}
      >
        <Tabs.List mr={15}>
          {tabs.map((tab) => (
            <Tabs.Tab
              w="100%"
              bg={selectedTab === tab.value ? 'blue' : undefined}
              c={selectedTab === tab.value ? 'white' : undefined}
              value={tab.value}
              key={tab.value}
            >
              <Text p={5}>{tab.title}</Text>
            </Tabs.Tab>
          ))}
        </Tabs.List>
        <Tabs.Panel value="candidateStatuses">
          <CandidateStatuses />
        </Tabs.Panel>
        <Tabs.Panel value="candidateSources">
          <CandidateSources />
        </Tabs.Panel>
        <Tabs.Panel value="emailTemplates">
          <EmailTemplates />
        </Tabs.Panel>
        <Tabs.Panel value="offerTemplates">
          <OfferTemplates />
        </Tabs.Panel>
      </Tabs>
    </>
  );
}
