import { useResetPassword } from 'api/hooks';
import { useAuthContext } from 'auth';
import PasswordUpdate from 'components/Password/PasswordUpdate';
import { useNotifications } from 'notifications/hooks/useNotifications';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCheckValidPasswordToken, useSetPageTitle } from 'utils/hooks';

export default function ChoosePassword() {
  const { setNotifications } = useNotifications();
  const { isLoggedIn, clearData } = useAuthContext();
  const { userHash } = useParams();
  const navigate = useNavigate();

  const { isCheckValidationLoading, isInvalidToken } =
    useCheckValidPasswordToken(userHash);

  const { resetPassword, isLoading } = useResetPassword({
    onSuccess: () => {
      setNotifications(['Password stored successfully']);
      navigate('/login');
    },
  });

  const onSubmit = async (data) => {
    if (isLoggedIn) {
      await clearData();
    }

    const payload = {
      password: data?.newPassword,
      password_confirmation: data?.confirmNewPassword,
    };

    resetPassword({ hash: userHash, ...payload });
  };

  useSetPageTitle('Choose Password');

  return (
    <PasswordUpdate
      submitButtonLabel="Choose Password"
      formTitle="Choose Password"
      onSubmit={onSubmit}
      isLoading={isLoading || isCheckValidationLoading || isInvalidToken}
    />
  );
}
