import { Flex, Modal } from '@mantine/core';
import TimeOffRecordForm from 'components/TimeOff/forms/TimeOffRecordForm';
import PropTypes from 'prop-types';

export default function RequestTimeOffModal({
  opened,
  onClose,
  policies,
  isLoading,
  onCreate,
  onEdit,
  requestState,
}) {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      title="Send timeoff request"
      size="80%"
    >
      <Flex gap={10}>
        <TimeOffRecordForm
          policies={policies}
          isLoading={isLoading}
          onSubmit={requestState?.modalType === 'Edit' ? onEdit : onCreate}
          withNotes
          requests={{ ...requestState?.data, notes: requestState?.content }}
        />
      </Flex>
    </Modal>
  );
}

RequestTimeOffModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onCreate: PropTypes.func,
  onEdit: PropTypes.func,
  policies: PropTypes.array,
  requestState: PropTypes.object,
};

RequestTimeOffModal.defaultProps = {
  policies: [],
  requestState: {},
  onEdit: () => {},
  onCreate: () => {},
};
