import {
  Button,
  Flex,
  Menu,
  MultiSelect,
  Select,
  Skeleton,
  Stack,
  rem,
} from '@mantine/core';
import {
  IconArrowLeft,
  IconFileTypeCsv,
  IconSettings,
} from '@tabler/icons-react';
import {
  useExportHeadCountList,
  useLoadHeadCountList,
  useLoadHeadCountsStats,
  useLoadLookups,
} from 'api/hooks';
import { DatePickerFormInput } from 'components/DatePicketFormInput';
import { Loader } from 'components/Loader';
import { PageWrapper } from 'components/PageWrapper';
import { sortTypes } from 'components/SortTh/SortTh';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatSelectorData, formatSelectorDataObj } from 'utils';
import { employeeStatuses, lookupsModels } from 'utils/constants';
import { useExportFile, usePageTitle } from 'utils/hooks';
import { useFilterReport, useReadDataFromQuery } from './hooks';

import { HeadCountChart } from '../components';
import HeadCountTable from '../components/HeadCountTable';

export default function HeadCount({ globalTabName, locationSearch }) {
  const {
    dateRange,
    selectedStatus,
    sort,
    selectedLocation,

    filterHandlers,
  } = useFilterReport({ globalTabName });

  useReadDataFromQuery({ filterHandlers, locationSearch });

  const navigate = useNavigate();

  const { lookups, isLoading: isLookupsLoading } = useLoadLookups({
    models: [lookupsModels.location],
  });
  const locations = useMemo(
    () =>
      formatSelectorData(lookups?.[lookupsModels.location] ?? [], {
        value: 'id',
        label: 'name',
      }),
    [lookups],
  );

  const payload = useMemo(
    () => ({
      fromDate:
        dateRange?.from && dateRange?.to
          ? moment(dateRange.from)?.format('YYYY-MM-DD')
          : undefined,
      toDate:
        dateRange?.from && dateRange?.to
          ? moment(dateRange.to)?.format('YYYY-MM-DD')
          : undefined,

      filter: {
        byStatusType: selectedStatus,
        inLocation: selectedLocation?.join(','),
      },
      sortBy: sort.sortBy
        ? `${sort.sortBy === sortTypes.asc ? '' : '-'}${sort.field}`
        : undefined,
    }),
    [dateRange, selectedLocation, selectedStatus, sort],
  );

  const { headCountStats } = useLoadHeadCountsStats(payload);

  const { headCountList, isLoading } = useLoadHeadCountList(
    {
      ...payload,
    },
    { keepPreviousData: true },
  );

  const { exportFile } = useExportFile();

  const { exportHeadCountList, isLoading: isExporting } =
    useExportHeadCountList(payload, {
      onSuccess: (data) => {
        exportFile({
          data,
          name: `${moment(dateRange.from)?.format('YYYY-MM-DD')}_${moment(
            dateRange.to,
          )?.format('YYYY-MM-DD')} Head Count`,
        });
      },
    });

  const onExport = () => {
    exportHeadCountList();
  };

  const onUpdateSelectorData = (callback) => (data) => {
    callback(data);
  };

  usePageTitle('Reports - Headcount', globalTabName);

  return (
    <PageWrapper title="Headcount">
      <Stack gap={10}>
        <Flex ml={42} gap={10} align="end">
          <DatePickerFormInput
            from={dateRange.from}
            to={dateRange.to}
            onChangeFrom={(val) =>
              onUpdateSelectorData(() =>
                filterHandlers.setDateRange((prev) => ({ ...prev, from: val })),
              )()
            }
            onChangeTo={(val) =>
              onUpdateSelectorData(() =>
                filterHandlers.setDateRange((prev) => ({ ...prev, to: val })),
              )()
            }
            maxDate={moment().toDate()}
          />
          <Select
            value={selectedStatus}
            data={formatSelectorDataObj(employeeStatuses, true)}
            onChange={onUpdateSelectorData(filterHandlers.setSelectedStatus)}
            label="Employee Status"
            placeholder="Status"
            allowDeselect={false}
          />

          <Flex gap={10}>
            {isLookupsLoading ? (
              <Skeleton h={38} w={250} />
            ) : (
              <MultiSelect
                flex={1}
                data={locations}
                value={selectedLocation}
                placeholder="Location"
                label="Select Location"
                onChange={onUpdateSelectorData(
                  filterHandlers.setSelectedLocation,
                )}
                w="250"
                styles={{
                  pillsList: { flexWrap: 'nowrap', overflow: 'hidden' },
                }}
              />
            )}

            <Button mt="auto" onClick={filterHandlers.resetFilter}>
              Reset
            </Button>
          </Flex>

          <Flex ml="auto" gap={10}>
            <Button onClick={() => navigate('/reports')}>
              <IconArrowLeft /> Back to reports
            </Button>

            <Menu shadow="md" width={180}>
              <Menu.Target>
                <Button ml="auto" leftSection={<IconSettings />}>
                  Export
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  leftSection={
                    <IconFileTypeCsv
                      stroke="gray"
                      style={{ width: rem(20), height: rem(20) }}
                    />
                  }
                  onClick={onExport}
                  disabled={!dateRange.from || !dateRange.to || isExporting}
                >
                  Export as CSV
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>
        <HeadCountChart
          monthlyStats={headCountStats?.monthly_stats}
          yearStats={_.omit(headCountStats, 'monthly_stats')}
          range={dateRange}
        />

        <Loader isLoading={isLoading} />

        <HeadCountTable
          headCountList={headCountList}
          sort={sort}
          setSort={filterHandlers.setSort}
          isLoading={isLoading}
        />
      </Stack>
    </PageWrapper>
  );
}

HeadCount.propTypes = {
  globalTabName: PropTypes.string,
  locationSearch: PropTypes.string,
};

HeadCount.defaultProps = {
  globalTabName: '',
  locationSearch: '',
};
