import { Flex, Select } from '@mantine/core';
import { DatePickerFormInput } from 'components/DatePicketFormInput';
import moment from 'moment';
import PropTypes from 'prop-types';
import { formatSelectorDataObj } from 'utils';

export default function PerformanceFilter({
  dateSelectData,
  selectedMonthType,
  setSelectedMonthType,
  dateRange,
  setDateRange,
  rightSection,
}) {
  return (
    <Flex mt={10} gap={10}>
      <Select
        data={formatSelectorDataObj(dateSelectData, true)}
        allowDeselect={false}
        value={selectedMonthType}
        onChange={setSelectedMonthType}
      />
      {/* 0 is Custom Date Range */}
      {selectedMonthType === '0' && (
        <DatePickerFormInput
          label=""
          from={dateRange.from}
          to={dateRange.to}
          onChangeFrom={(val) =>
            setDateRange((prev) => ({
              from: moment.isDate(val) ? moment(val).format('YYYY-MM-DD') : '',
              to: prev.to ?? '',
            }))
          }
          onChangeTo={(val) =>
            setDateRange((prev) => ({
              from: prev.from ?? '',
              to: moment.isDate(val) ? moment(val).format('YYYY-MM-DD') : '',
            }))
          }
          onClear={() => setDateRange({ from: '', to: '' })}
          clearable
          maxDate={new Date()}
        />
      )}
      {rightSection}
    </Flex>
  );
}

PerformanceFilter.propTypes = {
  selectedMonthType: PropTypes.string.isRequired,
  setSelectedMonthType: PropTypes.func.isRequired,
  dateRange: PropTypes.shape({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }).isRequired,
  setDateRange: PropTypes.func.isRequired,
  dateSelectData: PropTypes.object,
  rightSection: PropTypes.node,
};

PerformanceFilter.defaultProps = {
  dateSelectData: {},
  rightSection: null,
};
