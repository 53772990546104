import { Flex, Space, Table } from '@mantine/core';
import { AddButton } from 'components/Buttons';
import { CreateCandidateStatusModal } from 'components/CreateCandidateStatusModal';
import { useNotifications } from 'notifications/hooks/useNotifications';
import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import {
  useCandidateStatuses,
  useCreateCandidateStatus,
  useDeleteCandidateStatus,
  useUpdateCandidateStatus,
  useUpdateCandidateStatusOrder,
} from 'api/hooks';
import { useQueryClient } from 'react-query';
import { useStateDisclosure } from 'utils/hooks';
import { Loader } from 'components/Loader';
import { ConfirmDeleteModal } from 'components/ConfirmDeleteModal';
import { queryKeys } from 'api/keys';
import { CandidateStatusesRow } from './tab-rows';

export default function CandidateStatuses() {
  const { setNotifications } = useNotifications();
  const queryClient = useQueryClient();

  const [
    openedCreateModal,
    { open: openCreateModal, close: closeCreateModal, state: statusState },
  ] = useStateDisclosure(false);

  const [
    openedDeleteModal,
    { open: openDeleteModal, close: closeDeleteModal, state: deleteState },
  ] = useStateDisclosure(false);

  const {
    candidateStatuses,
    types,
    isLoading: isCandidatesLoading,
    refetch: refetchCandidateStatuses,
  } = useCandidateStatuses();

  const { createCandidateStatus, isLoading: isCreating } =
    useCreateCandidateStatus({
      onSuccess: () => {
        refetchCandidateStatuses();
        setNotifications(['Candidate Status Created Successfully']);
        closeCreateModal();
      },
    });

  const { deleteCandidateStatus, isLoading: isDeleting } =
    useDeleteCandidateStatus({
      onSuccess: () => {
        refetchCandidateStatuses();
        setNotifications(['Candidate Status Deleted Successfully']);
        closeDeleteModal();
      },
    });

  const { updateCandidateStatus } = useUpdateCandidateStatus({
    onSuccess: () => {
      refetchCandidateStatuses();
      setNotifications(['Candidate Status Updated Successfully']);
      closeCreateModal();
    },
  });

  const { updateCandidateStatusOrder, isLoading: isOrderLoading } =
    useUpdateCandidateStatusOrder({
      onSuccess: (data) => {
        setNotifications(['Status Order Updated Successfully']);
        queryClient.setQueryData(queryKeys.candidateStatuses(), data);
      },
    });

  const formattedStatusType = useMemo(
    () =>
      _.entries(types).map(([key, value]) => ({
        value: key,
        label: value,
      })),
    [types],
  );

  const onReorder = useCallback(
    (data) => {
      const ids = _.map(data, 'id');
      updateCandidateStatusOrder({ statuses: ids });
    },
    [updateCandidateStatusOrder],
  );

  return (
    <>
      <ConfirmDeleteModal
        opened={openedDeleteModal}
        onClose={closeDeleteModal}
        deleteItemName={deleteState?.name}
        onDelete={() => deleteCandidateStatus(deleteState?.id)}
        isLoading={isDeleting}
      />
      <CreateCandidateStatusModal
        opened={openedCreateModal}
        onClose={closeCreateModal}
        onSubmit={createCandidateStatus}
        statusState={statusState}
        types={formattedStatusType}
        isLoading={isCreating}
        onEdit={(data) =>
          updateCandidateStatus({ id: statusState?.id, ...data })
        }
      />
      <Flex justify="flex-end">
        <AddButton onClick={() => openCreateModal()}>Add Status</AddButton>
      </Flex>
      <Space h="xs" />
      {!_.isEmpty(candidateStatuses) && (
        <Table>
          <Table.Thead>
            <Table.Tr
              styles={{
                tr: { backgroundColor: 'var(--mantine-color-gray-4)' },
              }}
            >
              <Table.Th>Status Name</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {_.keys(candidateStatuses).map((type) => (
              <CandidateStatusesRow
                key={type}
                onEdit={(data) =>
                  openCreateModal({ ...data, modalType: 'Edit' })
                }
                onDelete={openDeleteModal}
                type={type}
                data={candidateStatuses[type]}
                onReorder={onReorder}
                isLoading={isOrderLoading}
              />
            ))}
          </Table.Tbody>
        </Table>
      )}
      <Loader isLoading={isCandidatesLoading} />
    </>
  );
}
