import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@mantine/core';
import { BoardingTaskForm } from 'components/BoardingTaskForm';

export default function BoardingTaskModal({
  opened,
  onClose,
  taskState,
  onSubmit,
  title,
  taskList,
  hireDate,
  isLoading,
}) {
  return (
    <Modal title={title} opened={opened} onClose={onClose} centered>
      <BoardingTaskForm
        onSubmit={(data) =>
          onSubmit({ ...data, modalType: taskState?.modalType })
        }
        list={taskList}
        taskState={taskState}
        hireDate={hireDate}
        isLoading={isLoading || !opened}
      />
    </Modal>
  );
}

BoardingTaskModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  taskState: PropTypes.object,
  title: PropTypes.string,
  hireDate: PropTypes.string,
  taskList: PropTypes.array,
  isLoading: PropTypes.bool,
};

BoardingTaskModal.defaultProps = {
  taskState: {},
  title: '',
  hireDate: '',
  taskList: [],
  isLoading: false,
};
