import { Button, Flex, Paper, Stack, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  useApproveRequest,
  useDeleteRequest,
  useDenyRequest,
  useEmployeePolicyList,
  useUpdateRequest,
  useUserPolicy,
} from 'api/hooks';
import { queryKeys } from 'api/keys';
import { useAuthContext } from 'auth';
import { ConfirmDeleteModal } from 'components/ConfirmDeleteModal';
import { Loader } from 'components/Loader';
import { usePermissions } from 'components/PermissionGuard/hooks';
import { RequestTimeOffModal } from 'components/RequestTimeOffModal';
import _ from 'lodash';
import { BackButton } from 'modules/requests/components';
import {
  usePreloadedRequest,
  useRequestBack,
} from 'modules/requests/hooks/usePreloadedRequest';
import { useNotifications } from 'notifications/hooks/useNotifications';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { getUserFullName } from 'utils';
import { requestStatuses } from 'utils/constants';
import { usePageTitle } from 'utils/hooks';
import { TimeOffUserInfo } from '../components';
import TimeOffList from '../components/TimeOffList';

export default function TimeOffRequestPreview({ requestId }) {
  const { setNotifications } = useNotifications();
  const queryClient = useQueryClient();

  const { user } = useAuthContext();

  const goBack = useRequestBack();
  const { hasPermission, permissions } = usePermissions();

  const invalidateQueries = () => {
    queryClient.invalidateQueries(['requests']);
    queryClient.invalidateQueries(queryKeys.manageRequest(requestId));
    queryClient.invalidateQueries(queryKeys.employeeRequest(requestId));
  };

  const {
    approveRequest: approveTimeOffRequest,
    isLoading: isTimeOffApproving,
  } = useApproveRequest({
    onSuccess: () => {
      setNotifications(['Request has been approved successfully']);
      goBack();
      invalidateQueries();
      queryClient.invalidateQueries(
        queryKeys.timeOffRecords(hasPermission(permissions.canManageEmployee)),
      );
    },
  });

  const { denyRequest: denyTomeOffRequest, isLoading: isTimeOffDenying } =
    useDenyRequest({
      onSuccess: () => {
        setNotifications(['Request has been denied successfully']);
        goBack();
        invalidateQueries();
      },
    });

  const { request, isLoading } = usePreloadedRequest(requestId);

  const [policies, setPolicies] = useState([]);

  useEmployeePolicyList(request?.data?.employee_id, {
    enabled:
      !!request?.data?.employee_id &&
      hasPermission(permissions.canManageEmployee),
    onSuccess: (data) => {
      setPolicies(data);
    },
  });

  useUserPolicy(user?.id, {
    enabled: user?.id && !hasPermission(permissions.canManageEmployee),
    onSuccess: (data) => {
      setPolicies(data);
    },
  });

  const [
    openedEditTimeOff,
    { open: openEditTimeOff, close: closeEditTimeOff },
  ] = useDisclosure(false);

  const [
    openedConfirmDeleteTimeOff,
    { open: openConfirmDeleteTimeOff, close: closeConfirmDeleteTimeOff },
  ] = useDisclosure(false);

  const { updateRequest: updateTimeOffRequest, isLoading: isTimeOffUpdating } =
    useUpdateRequest({
      onSuccess: () => {
        setNotifications(['Request has been updated successfully']);
        invalidateQueries();
        closeEditTimeOff();
      },
    });

  const { deleteRequest: deleteTimeOffRequest, isLoading: isTimeOffDeleting } =
    useDeleteRequest({
      onSuccess: () => {
        setNotifications(['Request has been deleted successfully']);
        invalidateQueries();
        closeConfirmDeleteTimeOff();
      },
    });

  const onUpdateRequest = (data) => {
    updateTimeOffRequest({
      requestId: request?.id,
      timeoffDetails: data?.timeoffDetails,
      policy_id: data?.policyId,
      policy_name:
        policies?.find((pol) => +pol.id === +data.policyId)?.name ?? undefined,
      employee_id: request?.data?.employee_id,
      category: 'TimeoffRecords',
      content: data?.notes,
    });
  };

  const isDisabledButtons = isLoading || isTimeOffApproving || isTimeOffDenying;

  usePageTitle(
    request?.author
      ? `Time Off - ${getUserFullName(request?.author)} - Requests`
      : 'Time Off - Requests',
    '/requests',
  );

  return (
    <Stack gap="md">
      <RequestTimeOffModal
        opened={openedEditTimeOff}
        onClose={closeEditTimeOff}
        onEdit={onUpdateRequest}
        policies={policies}
        requestState={{ ...request, modalType: 'Edit' }}
        isLoading={isTimeOffUpdating}
      />
      <ConfirmDeleteModal
        opened={openedConfirmDeleteTimeOff}
        onClose={closeConfirmDeleteTimeOff}
        onDelete={() => deleteTimeOffRequest(request?.id)}
        isLoading={isTimeOffDeleting}
        customText="Are you sure you want to cancel this request?"
        cancelButtonLabel="No"
        confirmButtonLabel="Yes, Cancel"
      />
      <Flex justify="end">
        <BackButton />
      </Flex>
      <Loader isLoading={isLoading} />
      {!isLoading && _.isEmpty(request) && <Title>Request Not Found</Title>}
      {!isLoading && !_.isEmpty(request) && (
        <>
          <TimeOffUserInfo
            request={request}
            policyName={request?.data?.policy_name}
            onEdit={openEditTimeOff}
            onCancel={openConfirmDeleteTimeOff}
          />
          <TimeOffList timeOffList={request?.data?.timeoffDetails} />
          {request?.content && (
            <Paper
              size="sm"
              w="100%"
              h="100%"
              radius="sm"
              bg="var(--mantine-color-gray-1)"
              p={5}
            >
              <Text span fw={600}>
                Comments:{' '}
              </Text>
              {request?.content}
            </Paper>
          )}
          {request?.status === requestStatuses.Pending &&
            hasPermission(permissions.canManageEmployee) && (
              <Flex gap="md" justify="center" align="center">
                <Button
                  onClick={() => approveTimeOffRequest(request?.id)}
                  disabled={isDisabledButtons}
                  loading={isDisabledButtons}
                >
                  Approve
                </Button>
                <Button
                  disabled={isDisabledButtons}
                  loading={isDisabledButtons}
                  color="red"
                  onClick={() => denyTomeOffRequest(request?.id)}
                >
                  Deny
                </Button>
              </Flex>
            )}
        </>
      )}
    </Stack>
  );
}

TimeOffRequestPreview.propTypes = {
  requestId: PropTypes.string.isRequired,
};
