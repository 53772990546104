import {
  Box,
  Button,
  Flex,
  MultiSelect,
  NumberInput,
  Select,
  Stack,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconCurrencyDollar } from '@tabler/icons-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { formatSelectorData, genericValidators } from 'utils';

export default function CreateEditBandForm({
  onSubmit,
  isLoading,
  state,
  jobTitles,
  compensationGroups,
}) {
  const form = useForm({
    initialValues: {
      name: state?.name ?? '',
      minSalary: state?.min_salary ?? '',
      maxSalary: state?.max_salary ?? '',
      midpointSalary: state?.midpoint_salary ?? '',
      job_title_ids: state?.job_titles?.map((item) => String(item.id)) ?? [],
      group_id: _.toString(state?.group_id) ?? '',
    },
    validate: {
      minSalary: (val, { midpointSalary }) => {
        if (!val) return genericValidators.notEmpty(val);
        return +val < +midpointSalary
          ? null
          : 'Must be less than the midpoint value';
      },
      midpointSalary: (val) => genericValidators.notEmpty(val),
      maxSalary: (val, { midpointSalary }) => {
        if (!val) return genericValidators.notEmpty(val);
        return +val > +midpointSalary
          ? null
          : 'Must be greater than the midpoint value';
      },
      name: (val) => genericValidators.notEmpty(val),
      job_title_ids: (val) => genericValidators.notEmpty(val),
      group_id: (val) => genericValidators.notEmpty(val),
    },
  });

  const percentDiff = useMemo(() => {
    const { minSalary, maxSalary } = form.values;

    if (!minSalary || !maxSalary) return 0;

    const diff = 100 - (minSalary * 100) / maxSalary;

    return diff < 0 ? 0 : diff.toFixed(2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.minSalary, form.values.maxSalary]);

  return (
    <form
      onSubmit={form.onSubmit((data) =>
        onSubmit({ ...data, percentage: percentDiff }),
      )}
      noValidate
    >
      <Stack>
        <Flex gap={10}>
          <TextInput
            required
            flex={1}
            label="Name"
            placeholder="Name"
            {...form.getInputProps('name')}
          />
          <Select
            required
            flex={1}
            {...form.getInputProps('group_id')}
            label="Group"
            placeholder="Select Group"
            data={formatSelectorData(compensationGroups, {
              value: 'id',
              label: 'name',
            })}
            allowDeselect={false}
          />
        </Flex>
        <Flex gap={10}>
          <NumberInput
            required
            allowNegative={false}
            min={1}
            hideControls
            prefix="$"
            rightSection={<IconCurrencyDollar />}
            label="Min"
            placeholder="Min"
            {...form.getInputProps('minSalary')}
          />
          <NumberInput
            allowNegative={false}
            min={1}
            required
            hideControls
            prefix="$"
            rightSection={<IconCurrencyDollar />}
            label="Midpoint"
            placeholder="Midpoint"
            {...form.getInputProps('midpointSalary')}
          />
          <NumberInput
            allowNegative={false}
            min={1}
            hideControls
            required
            prefix="$"
            rightSection={<IconCurrencyDollar />}
            label="Maximum"
            placeholder="Maximum"
            {...form.getInputProps('maxSalary')}
          />
          <Stack gap={0}>
            Percentage
            <Box mt={5}>{percentDiff} %</Box>
          </Stack>
        </Flex>
        <MultiSelect
          mb="md"
          required
          {...form.getInputProps('job_title_ids')}
          label="Select Job titles"
          placeholder="Select Job titles"
          data={formatSelectorData(jobTitles, {
            value: 'id',
            label: 'name',
          })}
          searchable
        />
        <Button disabled={isLoading} ml="auto" type="submit">
          Submit
        </Button>
      </Stack>
    </form>
  );
}

CreateEditBandForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  state: PropTypes.object,
  jobTitles: PropTypes.array,
  compensationGroups: PropTypes.array,
};

CreateEditBandForm.defaultProps = {
  state: undefined,
  jobTitles: [],
  compensationGroups: [],
};
