import { Paper, Button, Textarea } from '@mantine/core';
import PropTypes from 'prop-types';

export default function CreateNoteContainer({
  onSubmit,
  value,
  onChange,
  error,
  isLoading,
}) {
  const submitEnabled = value?.length > 0;
  return (
    <Paper shadow="xl" p="lg" my={20}>
      <form onSubmit={onSubmit} noValidate>
        <Textarea
          label="Add a note"
          placeholder="Write your note here... "
          value={value}
          onChange={onChange}
          error={error}
          maxRows={20}
          minRows={3}
          autosize
          required
        />
        <Button
          disabled={!submitEnabled}
          type="submit"
          mt={20}
          variant="filled"
          loading={isLoading}
        >
          Post
        </Button>
      </form>
    </Paper>
  );
}

CreateNoteContainer.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.object,
  isLoading: PropTypes.bool,
};

CreateNoteContainer.defaultProps = {
  value: '',
  error: null,
  isLoading: false,
};
