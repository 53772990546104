import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { employeeStatuses } from 'utils/constants';
import { useUpdateTabQuery } from 'utils/hooks';

const defaultValues = {
  date: {
    from: moment().subtract(1, 'years'),
    to: moment(),
  },
  sort: {
    field: '',
    sortBy: '',
  },
  locations: [],
  employeeStatus: employeeStatuses.All,
};

const useFilterReport = ({ globalTabName }) => {
  const [dateRange, setDateRange] = useState({ ...defaultValues.date });
  const [selectedStatus, setSelectedStatus] = useState(
    defaultValues.employeeStatus,
  );
  const [sort, setSort] = useState({ ...defaultValues.sort });

  const [selectedLocation, setSelectedLocation] = useState(
    defaultValues.locations,
  );

  const resetFilter = useCallback(() => {
    setDateRange({ ...defaultValues.date });
    setSelectedStatus(defaultValues.employeeStatus);
    setSort({ ...defaultValues.sort });
    setSelectedLocation(defaultValues.locations);
  }, []);

  useUpdateTabQuery({
    globalTabName,
    queryObject: {
      locations: selectedLocation,
      employeeStatus: selectedStatus,
      sortField: sort.field,
      sortSortBy: sort.sortBy,
      from: dateRange.from,
      to: dateRange.to,
    },
  });

  const filterHandlers = useMemo(
    () => ({
      setSelectedLocation,
      setSelectedStatus,
      setSort,
      setDateRange,
      resetFilter,
    }),
    [
      setSelectedStatus,
      setSelectedLocation,
      setSort,
      setDateRange,
      resetFilter,
    ],
  );

  return {
    dateRange,
    selectedStatus,
    sort,
    selectedLocation,

    filterHandlers,
  };
};

export default useFilterReport;
