/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import '@mantine/core/styles.css';
// eslint-disable-next-line import/no-unresolved
import '@mantine/dates/styles.css';
// eslint-disable-next-line import/no-unresolved
import '@mantine/dropzone/styles.css';
// eslint-disable-next-line import/no-unresolved
import '@mantine/notifications/styles.css';

import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import '@mantine/carousel/styles.css';
import { MantineProvider } from '@mantine/core';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react';
import { AuthContextProvider } from 'auth';
import 'i18n';
import NotificationsProvider from 'notifications/NotificationsProvider';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-image-crop/dist/ReactCrop.css';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/cjs/Page/AnnotationLayer.css';
import 'react-pdf/dist/cjs/Page/TextLayer.css';
import { resolver, themes } from 'utils';
import App from './App';
import './index.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});

const pca = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
  },
});

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: process.env.REACT_APP_ROLLBAR_ENV,
  enabled: process.env.REACT_APP_ROLLBAR_ENV === 'prod',
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary>
        <MantineProvider
          defaultColorScheme={themes.dark}
          cssVariablesResolver={resolver}
        >
          <ModalsProvider>
            <Notifications autoClose={4000} />
            <QueryClientProvider client={queryClient}>
              <NotificationsProvider>
                <BrowserRouter>
                  <AuthContextProvider>
                    <GoogleOAuthProvider
                      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                    >
                      <MsalProvider instance={pca}>
                        <App />
                      </MsalProvider>
                    </GoogleOAuthProvider>
                  </AuthContextProvider>
                </BrowserRouter>
              </NotificationsProvider>
            </QueryClientProvider>
          </ModalsProvider>
        </MantineProvider>
      </ErrorBoundary>
    </RollbarProvider>
  </React.StrictMode>,
);
