import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import YearStats from './YearStats';

export default function HeadCountChart({ monthlyStats, yearStats, range }) {
  return (
    <ResponsiveContainer height={350} width="100%" style={{ display: 'flex' }}>
      <BarChart
        height={300}
        data={_.map(monthlyStats, (val, index) => ({
          ...val,
          index,
        }))}
        style={{ flex: 1 }}
      >
        <XAxis dataKey="month" stroke="#228be6" />
        <XAxis
          dataKey={({ index, month, year }) =>
            index === 0 || month === 'Jan' ? year : ''
          }
          xAxisId="year"
          axisLine={false}
          tickLine={false}
          stroke="#228be6"
          orientation="top"
        />
        <YAxis padding={{ top: 40 }} />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <Bar
          dataKey="cumulative_total"
          fill="#228be6"
          barSize={30}
          label={{ position: 'top' }}
        />
      </BarChart>
      {!_.isEmpty(yearStats) && (
        <YearStats yearStats={yearStats} range={range} />
      )}
    </ResponsiveContainer>
  );
}

HeadCountChart.propTypes = {
  monthlyStats: PropTypes.array,
  yearStats: PropTypes.object,
  range: PropTypes.object,
};

HeadCountChart.defaultProps = {
  monthlyStats: [],
  yearStats: {},
  range: {},
};
