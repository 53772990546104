import { Button, Flex, Group, LoadingOverlay, Text } from '@mantine/core';
import PropTypes from 'prop-types';
import { tabNames } from 'utils/constants';
import { useTabNavigate } from 'utils/hooks';
import NotificationItem from './NotificationItem';

export default function NotificationsList({
  notifications,
  isLoading,
  showSeeAll,
  onMarkedAsRead,
  closeMenu,
  fullView,
}) {
  const hasNotifications = notifications.length > 0;
  const navigate = useTabNavigate();
  const navigateToNotifications = () => {
    navigate(`/notifications`, { tabName: tabNames.notifications });
  };
  return (
    <Group p="md">
      <LoadingOverlay
        visible={isLoading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      {notifications?.map((notification) => (
        <NotificationItem
          key={notification.id}
          notification={notification}
          onMarkedAsRead={onMarkedAsRead}
          fullView={fullView}
          closeMenu={closeMenu}
        />
      ))}
      {!hasNotifications && (
        <Flex p="sm" justify="center" align="center" w="100%">
          <Text size="xl" c="dimmed" ta="center">
            There are no unread notifications
          </Text>
        </Flex>
      )}
      {showSeeAll && (
        <Flex justify="center" w="100%">
          <Button
            onClick={() => {
              navigateToNotifications();
              closeMenu();
            }}
            variant="white"
          >
            See all notifications
          </Button>
        </Flex>
      )}
    </Group>
  );
}

NotificationsList.propTypes = {
  notifications: PropTypes.array,
  isLoading: PropTypes.bool,
  showSeeAll: PropTypes.bool,
  fullView: PropTypes.bool,
  onMarkedAsRead: PropTypes.func,
  closeMenu: PropTypes.func,
};

NotificationsList.defaultProps = {
  notifications: [],
  isLoading: false,
  fullView: false,
  showSeeAll: true,
  onMarkedAsRead: () => {},
  closeMenu: () => {},
};
