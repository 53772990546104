import { useCreateEmployee, useLoadLookups } from 'api/hooks';
import { EmployeeForm } from 'components/EmployeeForm';
import { PageWrapper } from 'components/PageWrapper';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { clearCache } from 'api/clearCache';
import { useGlobalTabsContext } from 'globalTabs';
import { useNotifications } from 'notifications/hooks/useNotifications';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { lookupsModels } from 'utils/constants';
import { usePageTitle } from 'utils/hooks';

export default function CreateEmployee({ globalTabName }) {
  const { setNotifications } = useNotifications();
  const queryClient = useQueryClient();

  const { lookups } = useLoadLookups({
    models: [
      lookupsModels.location,
      lookupsModels.department,
      lookupsModels.division,
      lookupsModels.jobTitle,
    ],
  });

  const { onRemoveTab } = useGlobalTabsContext();
  const navigate = useNavigate();

  const { createEmployee, isLoading } = useCreateEmployee({
    onSuccess: (response) => {
      setNotifications(['Employee Created Successfully']);
      clearCache.onChangeEmployeeStatus(queryClient);
      const id = response?.data?.id;
      if (id) {
        navigate(`/employees/${id}`);
        onRemoveTab(globalTabName);
      }
    },
  });

  const submitForm = (values) =>
    createEmployee({
      payload: {
        ...values,
        dob: values?.dob ? moment(values.dob).format('YYYY-MM-DD') : undefined,
        hire_date: values?.hire_date
          ? moment(values.hire_date).format('YYYY-MM-DD')
          : undefined,
      },
    });

  usePageTitle('Create Employee - Employees', globalTabName);

  return (
    <PageWrapper title="Create Employee">
      <EmployeeForm
        onSubmit={submitForm}
        isSubmitLoading={isLoading}
        jobInformation={{
          locations: lookups[lookupsModels.location],
          departments: lookups[lookupsModels.department],
          divisions: lookups[lookupsModels.division],
          jobTitles: lookups[lookupsModels.jobTitle],
        }}
      />
    </PageWrapper>
  );
}

CreateEmployee.propTypes = {
  globalTabName: PropTypes.string,
};

CreateEmployee.defaultProps = {
  globalTabName: '',
};
