import PropTypes from 'prop-types';
import { Files } from '../../files';

export default function FilesTab({ employeeId }) {
  return (
    <>
      <h1>Files</h1>
      <Files contentType="Employee" contentId={employeeId} />
    </>
  );
}

FilesTab.propTypes = {
  employeeId: PropTypes.string.isRequired,
};
