import { Button, Card, Space, Text, rem, useMantineTheme } from '@mantine/core';
import { IconGauge } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { tabNames } from 'utils/constants';
import { useTabNavigate } from 'utils/hooks';
import classes from '../Hiring.module.css';

export default function TalentGroupBlock({ item }) {
  const navigate = useTabNavigate();
  const navigateToTalentGroupProfile = (id) => {
    navigate(`/hiring/talent-group/${id}`, {
      tabName: tabNames.hiringTalentGroup,
    });
  };
  const navigateToEdit = (id) => {
    navigate(`/hiring/talent-group/${id}/edit/`, {
      tabName: tabNames.editTalentGroup,
    });
  };
  const theme = useMantineTheme();

  return (
    <Card key="" shadow="md" radius="md" className={classes.card} padding="xl">
      <IconGauge
        style={{ width: rem(50), height: rem(50) }}
        stroke={2}
        color={theme.colors.blue[6]}
        onClick={() => navigateToTalentGroupProfile(item.id)}
        className={classes.pointer}
      />
      <Text
        fz="lg"
        fw={500}
        className={(classes.cardTitle, classes.pointer)}
        mt="md"
        onClick={() => navigateToTalentGroupProfile(item.id)}
      >
        {item.name}
      </Text>
      <Text
        fz="sm"
        c="dimmed"
        mt="sm"
        onClick={() => navigateToTalentGroupProfile(item.id)}
        className={classes.pointer}
      >
        {item.description}
      </Text>
      <Space h="xl" />
      <Button onClick={() => navigateToEdit(item.id)}>Edit</Button>
    </Card>
  );
}

TalentGroupBlock.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,
};
