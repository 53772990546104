import { Button, Flex, Group, Pill, Text, rem } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { IconPhoto, IconUpload, IconX } from '@tabler/icons-react';
import React from 'react';
import PropTypes from 'prop-types';

export default function UploadFile({
  setSelectedFile,
  maxFileSize,
  selectedFile,
  onFileSubmit,
  accept,
  onReject,
}) {
  return (
    <>
      <Dropzone
        onDrop={(files) => setSelectedFile(files[0])}
        maxSize={maxFileSize * 1024 ** 2}
        onReject={onReject}
        accept={accept.split(',')}
      >
        <Group
          justify="center"
          gap="xl"
          mih={220}
          style={{ pointerEvents: 'none' }}
        >
          <Dropzone.Accept>
            <IconUpload
              style={{
                width: rem(52),
                height: rem(52),
                color: 'var(--mantine-color-blue-6)',
              }}
              stroke={1.5}
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX
              style={{
                width: rem(52),
                height: rem(52),
                color: 'var(--mantine-color-red-6)',
              }}
              stroke={1.5}
            />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconPhoto
              style={{
                width: rem(52),
                height: rem(52),
                color: 'var(--mantine-color-dimmed)',
              }}
              stroke={1.5}
            />
          </Dropzone.Idle>

          <div>
            <Text size="xl" inline>
              Drag files here or click to select files
            </Text>
            <Text size="sm" c="dimmed" inline mt={7}>
              File should not exceed {maxFileSize}mb
            </Text>
          </div>
        </Group>
      </Dropzone>
      {selectedFile && (
        <Flex
          gap="md"
          justify="center"
          align="center"
          direction="column"
          wrap="wrap"
          p="xl"
        >
          <div>
            <Pill withRemoveButton onRemove={() => setSelectedFile()}>
              {selectedFile.name}
            </Pill>
          </div>
          <Button onClick={onFileSubmit}>Submit</Button>
        </Flex>
      )}
    </>
  );
}

UploadFile.propTypes = {
  setSelectedFile: PropTypes.func.isRequired,
  onFileSubmit: PropTypes.func,
  onReject: PropTypes.func,
  maxFileSize: PropTypes.number,
  selectedFile: PropTypes.object,
  accept: PropTypes.string,
};

UploadFile.defaultProps = {
  onFileSubmit: () => {},
  onReject: () => {},
  maxFileSize: 20,
  selectedFile: null,
  accept: '',
};
