import PropTypes from 'prop-types';
import { usePermissions } from './hooks/usePermissions';

export default function PermissionGuard({
  permission,
  children,
  fallback: FallbackComponent,
}) {
  const { hasPermission } = usePermissions();
  return hasPermission(permission) ? children : FallbackComponent;
}

PermissionGuard.propTypes = {
  permission: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node,
};

PermissionGuard.defaultProps = {
  fallback: null,
};
