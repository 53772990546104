import { useState } from 'react';
import { LoadingOverlay, Button, Text, Flex } from '@mantine/core';
import PropTypes from 'prop-types';
import { PageWrapper } from 'components/PageWrapper';
import {
  EmployeeTimeOffTable,
  TimeOffEmployeeModal,
  TimeOffPolicyDateModal,
  TimeOffRecordModal,
  TimeOffRecordsTable,
  UpcomingTimeOffTable,
} from 'components/TimeOff';
import { useTimeOffGroupedPolicies } from 'components/TimeOff/hooks';
import { useStateDisclosure } from 'utils/hooks';
import {
  useAttachTimOffPolicy,
  useDetachTimOffPolicy,
  useCreateTimeOffRecord,
  useTimeOffRecords,
  useTimeOffPolicies,
  useUpdateTimeOffRecord,
  useDeleteTimeOffRecord,
} from 'api/hooks';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { modals } from '@mantine/modals';
import moment from 'moment';
import { PermissionGuard } from 'components/PermissionGuard';
import { usePermissions } from 'components/PermissionGuard/hooks';
import { useQueryClient } from 'react-query';
import { queryKeys } from 'api/keys';

export default function TimeOffTab({ employeeId }) {
  const queryClient = useQueryClient();

  const { permissions, hasPermission } = usePermissions();
  const {
    groupedCategories,
    policies,
    isPoliciesLoading,
    isCategoriesLoading,
    refetchPolicies,
  } = useTimeOffGroupedPolicies({ employeeId, showEmptyCategories: false });

  const { policies: allPolicies, isLoading: isAllPoliciesLoading } =
    useTimeOffPolicies(
      {
        canManageEmployee: hasPermission(permissions.canManageEmployee),
      },
      {
        enabled: hasPermission(permissions.canManageEmployee),
      },
    );

  const { setNotifications } = useNotifications();
  const [policyId, setPolicyId] = useState(null);
  const [year, setYear] = useState(null);
  const { records, isLoading: isRecordsLoading } = useTimeOffRecords(
    {
      employee_id: employeeId,
      policy_id: policyId,
      year: year ? moment(year).format('YYYY') : undefined,
      canManageEmployee: hasPermission(permissions.canManageEmployee),
    },
    { enabled: Boolean(employeeId) },
  );

  const { records: upcomingTimeOff } = useTimeOffRecords(
    {
      employee_id: employeeId,
      canManageEmployee: hasPermission(permissions.canManageEmployee),
      upcoming: 1,
    },
    { enabled: Boolean(employeeId) },
  );

  const [
    isPolicyModalOpened,
    { open: openPolicyModal, close: closePolicyModal, state: policyToEdit },
  ] = useStateDisclosure(false);

  const [
    isPolicyDateModalOpened,
    {
      open: openPolicyDateModal,
      close: closePolicyDateModal,
      state: policyToUpdate,
    },
  ] = useStateDisclosure(false);

  const [
    isRecordModalOpened,
    { open: openRecordModal, close: closeRecordModal, state: recordToEdit },
  ] = useStateDisclosure(false);

  const { attachPolicy, isLoading } = useAttachTimOffPolicy({
    onSuccess: () => {
      setNotifications(['Policy Successfully Attached']);
      refetchPolicies();
      closePolicyModal();
    },
    onError: () => {
      closePolicyModal();
    },
  });

  const { detachPolicy } = useDetachTimOffPolicy({
    onSuccess: () => {
      setNotifications(['Policy Successfully Removed']);
      refetchPolicies();
      closePolicyModal();
    },
    onError: () => {
      closePolicyModal();
    },
  });

  const { createRecord, isLoading: isCreatingRecord } = useCreateTimeOffRecord({
    onSuccess: () => {
      setNotifications(['Record Successfully Created']);
      closeRecordModal();
      queryClient.invalidateQueries(
        queryKeys.timeOffRecords(hasPermission(permissions.canManageEmployee)),
      );
    },
    onError: () => {
      closeRecordModal();
    },
  });

  const { updateRecord, isLoading: isUpdatingRecord } = useUpdateTimeOffRecord({
    onSuccess: () => {
      setNotifications(['Record Successfully Updated']);
      closeRecordModal();
      queryClient.invalidateQueries(
        queryKeys.timeOffRecords(hasPermission(permissions.canManageEmployee)),
      );
    },
    onError: () => {
      closeRecordModal();
    },
  });

  const { deleteRecord } = useDeleteTimeOffRecord(
    hasPermission(permissions.canManageEmployee),
    {
      onSuccess: () => {
        setNotifications(['Record Successfully Deleted']);
        closeRecordModal();
        queryClient.invalidateQueries(
          queryKeys.timeOffRecords(
            hasPermission(permissions.canManageEmployee),
          ),
        );
      },
      onError: () => {
        closeRecordModal();
      },
    },
  );
  const onAttachPolicySubmit = (values) => {
    if (values.policyId) {
      attachPolicy({ employeeId, policyId: values.policyId });
    }
  };

  const onDeletePolicy = (policy) => {
    modals.openConfirmModal({
      title: 'Remove Policy',
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to remove{' '}
          <Text span fw={500}>
            {policy.name}
          </Text>{' '}
          Record
        </Text>
      ),
      labels: { confirm: 'Delete policy', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: () => {
        detachPolicy({ employeeId, policyId: policy.id });
      },
    });
  };
  const onEditPolicyClick = (policy) => {
    openPolicyModal(policy);
  };
  const onEditPolicyDateClick = (policy) => {
    openPolicyDateModal(policy);
  };
  const onEditPolicySubmit = (values) => {
    // eslint-disable-next-line no-console
    console.log('Values: ', values);
    // TODO: Implement Edit policy when api is ready
  };

  const onPolicyDateSubmit = (values) => {
    // eslint-disable-next-line no-console
    console.log('Values: ', values);
    // TODO: Implement accrual date submit when api is ready
  };

  const onRecordSubmit = (values) => {
    createRecord({
      ...values,
      employee_id: employeeId,
      policy_id: values.policyId,
      policy_name:
        allPolicies?.find((policy) => +values.policyId === +policy.id)?.name ??
        undefined,
    });
  };
  const onRecordUpdate = (values) => {
    if (recordToEdit) {
      updateRecord({ id: recordToEdit.id, payload: values });
    }
  };

  const onRecordDeleteClick = (record) => {
    modals.openConfirmModal({
      title: 'Delete Record',
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete record for{' '}
          <Text span fw={500}>
            {record.date}
          </Text>
        </Text>
      ),
      labels: { confirm: 'Delete Record', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: () => deleteRecord(record.id),
    });
  };
  return (
    <PageWrapper
      title="Time Off"
      rightSection={
        <PermissionGuard permission={permissions.canManageEmployee}>
          <Flex gap={4}>
            <Button onClick={() => openPolicyModal()}>
              Add Time Off Policy
            </Button>
            <Button onClick={() => openRecordModal()}>Record Time Off</Button>
          </Flex>
        </PermissionGuard>
      }
    >
      <TimeOffEmployeeModal
        opened={isPolicyModalOpened}
        policies={allPolicies}
        onClose={() => closePolicyModal()}
        onSubmit={policyToEdit ? onEditPolicySubmit : onAttachPolicySubmit}
        isLoading={isLoading}
        policy={policyToEdit}
      />
      <TimeOffRecordModal
        opened={isRecordModalOpened}
        policies={policies}
        onClose={() => closeRecordModal()}
        onSubmit={recordToEdit ? onRecordUpdate : onRecordSubmit}
        isLoading={isCreatingRecord || isUpdatingRecord}
        record={recordToEdit}
      />
      <TimeOffPolicyDateModal
        opened={isPolicyDateModalOpened}
        onClose={() => closePolicyDateModal()}
        onSubmit={onPolicyDateSubmit}
        isLoading={false}
        policy={policyToUpdate}
      />
      <LoadingOverlay
        visible={
          isCategoriesLoading || isPoliciesLoading || isAllPoliciesLoading
        }
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <UpcomingTimeOffTable upcomingTimeOff={upcomingTimeOff?.data} />
      <EmployeeTimeOffTable
        categories={groupedCategories}
        onDeletePolicy={onDeletePolicy}
        onEditPolicy={onEditPolicyClick}
        onPolicyDateUpdateClick={onEditPolicyDateClick}
      />
      <TimeOffRecordsTable
        records={records?.data ?? []}
        isLoading={isRecordsLoading}
        policies={policies}
        selectedPolicy={policyId}
        selectedYear={year}
        onYearChanged={(value) => {
          setYear(value);
        }}
        onPolicyChanged={(value) => setPolicyId(value)}
        onEditRecord={(record) => {
          openRecordModal(record);
        }}
        onDeleteRecord={onRecordDeleteClick}
      />
    </PageWrapper>
  );
}

TimeOffTab.propTypes = {
  employeeId: PropTypes.string.isRequired,
};
