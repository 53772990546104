import { Box, Button, Flex, InputError, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { IconHistory } from '@tabler/icons-react';
import { clearCache } from 'api/clearCache';
import { useCreatePeerFeedback, useLoadEmployeeSubordinates } from 'api/hooks';
import { useAuthContext } from 'auth';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { EmployeeMultipleAutocomplete } from 'components/EmployeeMultipleAutocomplete';
import { PeerFeedbackEmployeeHistoryModal } from 'components/PeerFeedbackEmployeeHistoryModal';
import { Skeleton } from 'components/Skeleton';
import { UserMainInfoPreviewCard } from 'components/UserMainInfoPreviewCard';
import _ from 'lodash';
import { useNotifications } from 'notifications/hooks/useNotifications';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { requestStatuses } from 'utils/constants';
import { useStateDisclosure } from 'utils/hooks';

export default function EmployeeSelectForm({ request, employeeId }) {
  const { setNotifications } = useNotifications();
  const queryClient = useQueryClient();

  const [
    openedConfirmationModal,
    { open: openConfirmationModal, close: closeConfirmationModal },
  ] = useDisclosure(false);

  const { user } = useAuthContext();

  const [
    openedHistoryModal,
    {
      open: openHistoryModal,
      close: closeHistoryModal,
      state: historyModalState,
    },
  ] = useStateDisclosure(false);

  const { subordinates, isLoading: isSubordinatesLoading } =
    useLoadEmployeeSubordinates(employeeId);

  const { initialize, ...form } = useForm({
    initialValues: {
      values: [],
    },
  });

  useEffect(() => {
    if (!_.isEmpty(subordinates) && _.isEmpty(request?.data?.selections)) {
      initialize({
        values: Array.from({ length: subordinates?.length ?? 0 }).map(
          (i, index) => ({
            employeesIds: [],
            destination_to: subordinates[index]?.id,
          }),
        ),
      });
    }
  }, [initialize, subordinates, request]);

  const selectedEmployeeForFeedback = useMemo(
    () =>
      form.values.values?.reduce(
        (prev, curr) => (_.isEmpty(curr?.employeesIds) ? prev : prev + 1),
        0,
      ),
    [form.values.values],
  );

  const { createPeerFeedback, isLoading: isCreatingPeerFeedback } =
    useCreatePeerFeedback({
      onSuccess: () => {
        closeConfirmationModal();
        setNotifications(['Peer Feedback Send Successfully']);
        clearCache.onSurveyComplete(queryClient, { requestId: request?.id });
      },
    });

  const onSubmitRequest = () => {
    createPeerFeedback({
      requestId: request?.id,
      selections: _.filter(
        form.values.values,
        (val) => !_.isEmpty(val?.employeesIds),
      ),
    });
  };

  const onConfirmRequest = () => {
    if (selectedEmployeeForFeedback === subordinates?.length) {
      onSubmitRequest();
    } else {
      openConfirmationModal();
    }
  };

  return (
    <>
      <Skeleton
        count={5}
        skeletonProps={{ height: '50px' }}
        isVisible={isSubordinatesLoading}
      />
      <ConfirmationModal
        opened={openedConfirmationModal}
        onClose={closeConfirmationModal}
        text={`Are you sure you want to send feedback to only
        ${selectedEmployeeForFeedback}
        ${selectedEmployeeForFeedback <= 1 ? 'employee' : 'employees'} when
        ${subordinates?.length} are available?`}
        title="Confirmation"
        onConfirm={onSubmitRequest}
        isLoading={isCreatingPeerFeedback}
      />
      <form onSubmit={form.onSubmit(onConfirmRequest)} noValidate>
        <PeerFeedbackEmployeeHistoryModal
          opened={openedHistoryModal}
          onClose={closeHistoryModal}
          employee={historyModalState}
        />
        <Stack gap={10}>
          {_.map(subordinates, (item, index) => {
            const selectedEmployees =
              request?.data?.selections?.find(
                (selectedItem) => selectedItem?.destination_to === item?.id,
              )?.employees ?? [];

            return (
              <Flex
                align="center"
                justify="space-between"
                gap={30}
                key={item?.id}
              >
                <Box
                  w={250}
                  flex={1}
                  style={{ borderRadius: '10px', overflow: 'hidden' }}
                >
                  <UserMainInfoPreviewCard bg="" userInfo={item} />
                </Box>
                <Flex gap={10} align="center">
                  <Box w={450}>
                    <EmployeeMultipleAutocomplete
                      values={form.values.values?.[index]?.employeesIds ?? []}
                      setValues={(val) =>
                        form.setFieldValue(`values.${index}.employeesIds`, val)
                      }
                      disabled={request?.status === requestStatuses.Completed}
                      selectedEmployee={
                        request?.status === requestStatuses.Completed
                          ? selectedEmployees
                          : undefined
                      }
                      clearable
                      label="Get Feedback from..."
                      // exclude current user and current subordinate
                      excludeIds={[item?.id, user?.id]}
                    />
                  </Box>
                  <IconHistory
                    style={{ marginTop: '6px' }}
                    cursor="pointer"
                    onClick={() => openHistoryModal(item)}
                    size={20}
                  />
                </Flex>
              </Flex>
            );
          })}
          {form.errors.values && <InputError>{form.errors.values}</InputError>}
          {request?.status === requestStatuses.Pending && (
            <Button
              type="submit"
              mt={10}
              ml="auto"
              disabled={isCreatingPeerFeedback}
            >
              Submit
            </Button>
          )}
        </Stack>
      </form>
    </>
  );
}

EmployeeSelectForm.propTypes = {
  employeeId: PropTypes.number.isRequired,
  request: PropTypes.object.isRequired,
};
