import { Avatar, Flex, Popover, Stack, Text } from '@mantine/core';
import {
  IconCalendar,
  IconChevronRightPipe,
  IconClock,
} from '@tabler/icons-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTabNavigate } from 'utils/hooks';
import DropdownCloseButton from '../DropdownCloseButton';

export default function TimeOffDropdown({ event, onClose, onClickUser }) {
  const navigation = useTabNavigate();

  const formattedStartEndDates = useMemo(() => {
    const { start_date: startDate, end_date: endDate } = event;

    if (startDate === endDate) {
      return moment(startDate).format('MMM DD');
    }

    return `${moment(startDate).format('MMM DD')} - ${moment(endDate).format(
      'MMM DD',
    )}`;
  }, [event]);

  return (
    <Popover.Dropdown
      p={0}
      styles={{
        arrow: {
          backgroundColor: 'var(--mantine-color-gray-1)',
        },
        dropdown: {
          borderRadius: '10px',
        },
      }}
    >
      <Stack
        bg="white"
        gap={0}
        style={{ zIndex: 2 }}
        styles={{ root: { borderRadius: '10px 10px 0px 0px' } }}
      >
        <DropdownCloseButton onClose={onClose} />
        <Flex gap={10} m={10} align="center">
          <Avatar
            src={event?.employees?.avatar_filename}
            size={50}
            onClick={onClickUser}
            style={{ cursor: 'pointer' }}
          />
          <Stack gap={0}>
            <Text onClick={onClickUser} style={{ cursor: 'pointer' }}>
              {event?.employees?.first_name} {event?.employees?.last_name}
            </Text>
            <Text c="gray" fw="bold" fz={13}>
              {event?.employees?.jobTitle}
            </Text>
            <Flex align="center" gap={5}>
              <IconCalendar width={20} height={20} />
              <Text c="gray" fw="bold" fz={13}>
                {formattedStartEndDates} | {event?.policy_name}
              </Text>
            </Flex>
          </Stack>
        </Flex>
        <Flex
          justify="center"
          align="center"
          h={50}
          bg="var(--mantine-color-gray-1)"
          w="100%"
          c="var(--mantine-color-gray-6)"
          gap={10}
          fw="bold"
        >
          <IconClock size={20} />
          {event?.total_hours} hours of {event?.policy_name}.
          <Flex
            gap={5}
            align="center"
            c="var(--mantine-color-blue-5)"
            onClick={() => {
              onClose();
              navigation(
                `/requests/all-requests/timeOffId/${event?.request_id}`,
              );
            }}
            style={{ cursor: 'pointer' }}
          >
            Details
            <IconChevronRightPipe size={15} />
          </Flex>
        </Flex>
      </Stack>
    </Popover.Dropdown>
  );
}

TimeOffDropdown.propTypes = {
  event: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onClickUser: PropTypes.func.isRequired,
};
