import { Flex, Title } from '@mantine/core';
import React from 'react';

export default function ErrorOfferState() {
  return (
    <Flex justify="center" align="center">
      <Title>This offer does not exist or has expired</Title>
    </Flex>
  );
}
