import { FormOption } from 'components/FormOption';
import React from 'react';
import PropTypes from 'prop-types';
import { IconClock } from '@tabler/icons-react';

export default function Accrual({ onClick, selected, error, viewOnly }) {
  return (
    <FormOption
      label="Accrual based on time"
      text="Time taken is deducted from employee’s balance that accrues on a schedule or is set manually."
      icon={<IconClock size="30" />}
      onClick={onClick}
      selected={selected}
      error={error}
      viewOnly={viewOnly}
    />
  );
}

Accrual.propTypes = {
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  error: PropTypes.bool,
  viewOnly: PropTypes.bool,
};

Accrual.defaultProps = {
  onClick: () => {},
  selected: false,
  error: false,
  viewOnly: false,
};
