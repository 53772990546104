import { Button, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import PropTypes from 'prop-types';
import { genericValidators } from 'utils';

export default function OneFieldForm({
  onSubmit,
  state,
  isLoading,
  field,
  label,
}) {
  const form = useForm({
    initialValues: {
      [field]: state?.[field] ?? '',
    },
    validate: {
      [field]: (val) => genericValidators.notEmpty(val),
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)} noValidate>
      <Stack gap={10}>
        <TextInput {...form.getInputProps(field)} label={label} />
        <Button disabled={isLoading} ml="auto" type="submit">
          Submit
        </Button>
      </Stack>
    </form>
  );
}

OneFieldForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  state: PropTypes.object,
  isLoading: PropTypes.bool,
  field: PropTypes.string,
  label: PropTypes.string,
};

OneFieldForm.defaultProps = {
  state: {},
  isLoading: false,
  field: 'name',
  label: 'Name',
};
