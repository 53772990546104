import { Avatar, Flex, Stack, Table, Text } from '@mantine/core';
import { IconCheck, IconCircleArrowLeft } from '@tabler/icons-react';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import { UserMainInfoPreviewCard } from 'components/UserMainInfoPreviewCard';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

export default function EmployeeList({ request }) {
  return (
    <>
      <Flex justify="end">
        <Flex c="green" fw={500} align="center" gap={5}>
          <IconCheck size={20} />
          Completed on {moment(request?.created_at).format('YYYY-MM-DD')}
        </Flex>
      </Flex>
      <EmptyDataMessage
        isVisible={_.isEmpty(request?.data?.selections)}
        message="In this request, no one was selected from the employee for feedback"
        mt="10"
      />
      {!_.isEmpty(request?.data?.selections) && (
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Employees</Table.Th>
              <Table.Th>Get Feedback from</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {_.map(request?.data?.selections, (item) => (
              <Table.Tr key={item?.destination_to?.id}>
                <Table.Td>
                  <UserMainInfoPreviewCard
                    userInfo={item?.destination_to}
                    bg=""
                  />
                </Table.Td>
                <Table.Td>
                  <Flex gap={10} align="center">
                    <IconCircleArrowLeft color="var(--mantine-color-blue-5)" />
                    <Stack gap={10}>
                      {_.map(item?.employees, (employee) => (
                        <Flex align="center" gap={10} key={employee?.id}>
                          <Avatar src={employee?.avatar_filename} />
                          <Text>
                            {employee?.first_name} {employee?.last_name}
                          </Text>
                        </Flex>
                      ))}
                    </Stack>
                  </Flex>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      )}
    </>
  );
}

EmployeeList.propTypes = {
  request: PropTypes.object.isRequired,
};
