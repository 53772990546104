import { Modal } from '@mantine/core';
import { useLoadEmployeeAssessmentSurveys } from 'api/hooks';
import { FeedbackTableView } from 'components/FeedbackTableView';
import PropTypes from 'prop-types';
import { surveys } from 'utils/constants';

export default function PeerFeedbackEmployeeHistoryModal({
  opened,
  onClose,
  employee,
}) {
  const { employeeAssessmentSurveys, isLoading: isEmployeeAssessmentLoading } =
    useLoadEmployeeAssessmentSurveys(
      {
        employeeId: employee?.id,
        filter: {
          byFeedbackSurveyType: surveys.peerFeedback.value,
        },
        page: 1,
        per_page: 10,
      },
      { enabled: !!employee?.id },
    );

  return (
    <Modal
      opened={opened}
      centered
      onClose={onClose}
      size="xl"
      title={
        employee
          ? `${employee?.first_name} ${employee?.last_name} - Peer Feedback History`
          : undefined
      }
    >
      <FeedbackTableView
        surveys={employeeAssessmentSurveys}
        isLoading={isEmployeeAssessmentLoading}
        limit={10}
      />
    </Modal>
  );
}

PeerFeedbackEmployeeHistoryModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  employee: PropTypes.object,
};

PeerFeedbackEmployeeHistoryModal.defaultProps = {
  employee: undefined,
};
