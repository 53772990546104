import { Modal } from '@mantine/core';
import { CompensationChangeReasonForm } from 'components/CompensationChangeReasonForm';
import PropTypes from 'prop-types';

export default function CompensationChangeReasonModal({
  opened,
  onClose,
  onEdit,
  onCreate,
  changeReasonState,
  isLoading,
}) {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      title={`${
        changeReasonState?.modalType === 'Edit' ? 'Edit' : 'Create'
      } Compensation Change Reason`}
    >
      <CompensationChangeReasonForm
        onSubmit={changeReasonState?.modalType === 'Edit' ? onEdit : onCreate}
        changeReasonState={changeReasonState}
        isLoading={isLoading || !opened}
      />
    </Modal>
  );
}

CompensationChangeReasonModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  changeReasonState: PropTypes.object,
  isLoading: PropTypes.bool,
};

CompensationChangeReasonModal.defaultProps = {
  changeReasonState: {},
  isLoading: false,
};
