import {
  Button,
  Container,
  Group,
  Select,
  Skeleton,
  Stack,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEmployeeSchema, useLoadEmployeeStatuses } from 'api/hooks';
import { DateFormInput } from 'components/DateFormInput';
import { JobInformationSelectors } from 'components/JobInformationSelectors';
import { usePermissions } from 'components/PermissionGuard/hooks';
import { SelectFormInput } from 'components/SelectFormInput';
import { TextFormField } from 'components/TextFormField';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import {
  formatSelectorData,
  genericValidators,
  useFormValidations,
} from 'utils';

const customFields = [{ name: 'status_id', enabled: true }];

export default function EmployeeForm({
  onSubmit,
  profile,
  onDelete,
  isSubmitLoading,
  isDeleteLoading,
  enableFetch,
  jobInformation,
  isProfileLoading,
}) {
  const { statuses, isLoading: isEmployeeStatusesLoading } =
    useLoadEmployeeStatuses();

  const { hasPermission, permissions } = usePermissions();
  const { schema, isLoading } = useEmployeeSchema(
    { canManageEmployee: hasPermission(permissions.canManageEmployee) },
    { enabled: enableFetch },
  );
  const fields = useMemo(
    () => schema?.[0].available_fields?.filter((field) => field.enabled),
    [schema],
  );

  const validations = useFormValidations(fields, jobInformation, {
    status_id: (val) => (!profile ? genericValidators.notEmpty(val) : null),
  });
  const { reset, ...form } = useForm(validations);

  const initialValues = useMemo(() => {
    if (profile) {
      const initial = schema?.[0]?.available_fields
        ?.concat(customFields)
        ?.filter((field) => field.enabled)
        ?.reduce((acc, field) => {
          acc[field.name] = profile?.[field.name];
          return acc;
        }, {});

      if (Object.keys(form.values).length === 0 && initial) {
        form.setValues(initial);
      }
      return initial;
    }
    return null;
  }, [schema, profile, form]);

  useEffect(() => {
    reset();
  }, [profile, reset]);

  return (
    <Container px={0} size="md">
      <form onSubmit={form.onSubmit(onSubmit)} noValidate>
        {fields?.length > 0 &&
          fields?.map((field) => {
            if (field.type === 'string') {
              return (
                <TextFormField
                  field={field}
                  key={field.name}
                  onChange={(event) => {
                    form.setFieldValue(field?.name, event.currentTarget.value);
                  }}
                  error={form.errors[field?.name]}
                  value={
                    form.values?.[field?.name] ?? initialValues?.[field?.name]
                  }
                />
              );
            }
            if (field.type === 'enum') {
              return (
                <SelectFormInput
                  key={field.name}
                  field={field}
                  onChange={(val) => {
                    form.setFieldValue(field?.name, val);
                  }}
                  error={form.errors[field?.name]}
                  value={
                    form.values?.[field?.name] ?? initialValues?.[field?.name]
                  }
                />
              );
            }
            if (field.type === 'date') {
              return (
                <DateFormInput
                  key={field.name}
                  field={field}
                  onChange={(val) => {
                    form.setFieldValue(field?.name, val);
                  }}
                  error={form.errors[field?.name]}
                  value={
                    form.values?.[field?.name] ?? initialValues?.[field?.name]
                  }
                />
              );
            }
            return null; // TODO: Check and return other types of fields
          })}

        {(isProfileLoading || isLoading) && (
          <Stack mt={10}>
            {_.map({ length: 20 }, (v, index) => (
              <Skeleton height={40} key={index} />
            ))}
          </Stack>
        )}

        {!profile && (
          <Select
            label="Employee Status"
            placeholder="Select employee status"
            value={
              form.values?.status_id ? _.toString(form.values?.status_id) : ''
            }
            onChange={(val) => form.setFieldValue('status_id', val)}
            data={formatSelectorData(
              statuses?.filter((data) => data.id !== 0),
              { value: 'id', label: 'name' },
            )}
            disabled={isEmployeeStatusesLoading}
            required
            error={form.errors?.status_id}
          />
        )}

        {!_.isEmpty(jobInformation) && (
          <JobInformationSelectors
            jobInformation={jobInformation ?? {}}
            values={form.values}
            onChange={(field, value) => form.setFieldValue(field, value)}
            errors={form.errors}
          />
        )}

        <Group gap="xl" mt="xl" justify="center">
          {profile && (
            <Button
              disabled={isDeleteLoading || isProfileLoading}
              loading={isSubmitLoading}
              type="submit"
            >
              Update
            </Button>
          )}
          {!profile && (
            <Button loading={isSubmitLoading} type="submit">
              Create
            </Button>
          )}
          {profile && (
            <Button
              disabled={isSubmitLoading || isProfileLoading}
              loading={isDeleteLoading}
              onClick={onDelete}
              variant="filled"
              color="red"
            >
              Delete
            </Button>
          )}
        </Group>
      </form>
    </Container>
  );
}

EmployeeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  profile: PropTypes.object,
  jobInformation: PropTypes.object,
  isSubmitLoading: PropTypes.bool,
  isDeleteLoading: PropTypes.bool,
  enableFetch: PropTypes.bool,
  isProfileLoading: PropTypes.bool,
};
EmployeeForm.defaultProps = {
  profile: null,
  jobInformation: {},
  onDelete: () => {},
  isSubmitLoading: false,
  isDeleteLoading: false,
  enableFetch: true,
  isProfileLoading: false,
};
