import {
  Avatar,
  Box,
  Button,
  Flex,
  LoadingOverlay,
  Pagination,
  ScrollArea,
  Stack,
  Table,
} from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useEffect, useMemo, useState } from 'react';

import { useEmployees, useEmployeeSchema } from 'api/hooks';
import { PermissionGuard } from 'components/PermissionGuard';
import { usePermissions } from 'components/PermissionGuard/hooks';
import { UserFilter } from 'components/UsersFilter';
import { sortFields } from 'utils';
import { usePagination, useTabNavigate } from 'utils/hooks';

import { EmployeeFilterList } from 'components/EmployeesFilter/components';
import { useEmployeeFilter } from 'components/EmployeesFilter/hooks';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import _ from 'lodash';
import { tabNames, userStatuses, userStatusMeta } from 'utils/constants';
import styles from '../Employees.module.css';

const headers = [
  'avatar',
  'first_name',
  'last_name',
  'email',
  'jobTitle',
  'dob',
];

export default function Employees() {
  const { permissions, hasPermission } = usePermissions();

  const [search, setSearch] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(userStatuses.all);

  const { page, setPage, validateCurrentPage } = usePagination({
    page: 1,
  });

  const navigate = useTabNavigate();
  const navigateToProfile = (id) => {
    navigate(`/employees/${id}`, { tabName: tabNames.employeesProfile });
  };

  const navigateToCreateEmployee = () => {
    navigate('/employees/create', { tabName: tabNames.createEmployees });
  };
  const {
    schema,
    isLoading: isSchemaLoading,
    isFetched,
  } = useEmployeeSchema({
    canManageEmployee: hasPermission(permissions.canManageEmployee),
  });

  const {
    employeeFilters,
    selectedValues,
    isLookupsLoading,
    onChangeEmployeeFilter,
  } = useEmployeeFilter();

  const formattedSelectedFilterValues = useMemo(() => {
    const res = _.keys(selectedValues).reduce((prev, curr) => {
      const filteredSelected = _.map(
        selectedValues[curr]?.filter((v) => v?.value),
        'id',
      );

      if (_.isEmpty(filteredSelected)) return prev;

      return { ...prev, [`in${curr}`]: filteredSelected };
    }, {});

    return res;
  }, [selectedValues]);

  const { employees, isLoading, pagination } = useEmployees(
    {
      page,
      search,
      status: userStatusMeta[selectedStatus],
      canManageEmployee: hasPermission(permissions.canManageEmployee),
      params: {
        filter: {
          ...formattedSelectedFilterValues,
        },
      },
    },
    {
      keepPreviousData: true,
      enabled: isFetched,
    },
  );

  useEffect(() => {
    validateCurrentPage(pagination?.total_pages);
  }, [pagination?.total_pages, validateCurrentPage]);

  const fields = useMemo(
    () =>
      schema?.[0].available_fields
        ?.filter(
          (field) =>
            (field.enabled || field.view_only) && headers.includes(field.name),
        )
        ?.sort((a, b) =>
          sortFields(a, b, ['avatar', 'first_name', 'last_name']),
        ),
    [schema],
  );

  const rows = employees?.map((employee) => {
    const cells = fields?.map((field) => {
      if (field.name === 'avatar') {
        return (
          <Table.Td key={`${employee.id}-${field.name}`}>
            <Avatar size={26} src={employee.avatar_filename} radius={26} />
          </Table.Td>
        );
      }
      return (
        <Table.Td key={`${employee.id}-${field.name}`}>
          {employee[field.name]}
        </Table.Td>
      );
    });

    return (
      <Table.Tr
        className={[styles.rowSelected, styles.pointer]}
        key={employee.id}
        onClick={() => navigateToProfile(employee.id)}
      >
        {cells}
      </Table.Tr>
    );
  });

  return (
    <Flex gap={10}>
      <Stack gap={0} flex={1}>
        <Flex justify="end" w="100%" mb={10}>
          <PermissionGuard permission={permissions.canManageEmployee}>
            <Button
              onClick={navigateToCreateEmployee}
              rightSection={<IconPlus size={14} />}
            >
              Add Employee
            </Button>
          </PermissionGuard>
        </Flex>
        <LoadingOverlay
          visible={isLoading || isSchemaLoading}
          zIndex={1000}
          overlayProps={{ radius: 'sm', blur: 2 }}
        />
        <UserFilter
          search={search}
          setSearch={setSearch}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
        />
        {rows?.length > 0 ? (
          <ScrollArea>
            <Table miw={800} verticalSpacing="sm">
              <Table.Thead>
                <Table.Tr>
                  {fields?.map((field) => (
                    <Table.Th key={field?.name}>{field?.displayName}</Table.Th>
                  ))}
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{rows}</Table.Tbody>
            </Table>
          </ScrollArea>
        ) : (
          <EmptyDataMessage isVisible />
        )}
        {pagination?.total_pages > 1 && (
          <Flex justify="flex-end" mt={10}>
            <Pagination
              total={pagination?.total_pages}
              value={page}
              onChange={setPage}
            />
          </Flex>
        )}
      </Stack>
      <Box
        style={{
          backgroundColor: 'white',
          borderRadius: '10px',
          overflow: 'hidden',
          border: '1px solid var(--mantine-color-gray-2)',
        }}
        w={300}
        h="max-content"
      >
        {!isLookupsLoading && (
          <EmployeeFilterList
            filteredData={employeeFilters}
            onSelectFilter={onChangeEmployeeFilter}
            selectedValues={selectedValues}
          />
        )}
      </Box>
    </Flex>
  );
}
