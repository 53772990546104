import { Stack } from '@mantine/core';
import React from 'react';
import PropTypes from 'prop-types';
import { usePolicyTypeSelect } from 'components/TimeOff/hooks';
import moment from 'moment';
import Period from '../Period';

export default function TwiceYear({ formValues, onChange, scheduleType }) {
  const twiceYear = usePolicyTypeSelect({
    onChange,
    formValues,
    scheduleType,
  });

  const months = moment.months();
  return (
    <Stack>
      <Period
        values={twiceYear?.firstHalf ?? {}}
        months={months.slice(0, 6)}
        valuePath="twice_a_year.firstHalf"
        onChange={onChange}
      />
      <Period
        values={twiceYear?.secondHalf ?? {}}
        months={months.slice(6, 12)}
        valuePath="twice_a_year.secondHalf"
        onChange={onChange}
        isLast
      />
    </Stack>
  );
}

TwiceYear.propTypes = {
  formValues: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  scheduleType: PropTypes.string.isRequired,
};
