import { Checkbox, Flex, Loader, Pill, Table } from '@mantine/core';
import moment from 'moment';

import { IconEdit, IconTrash } from '@tabler/icons-react';
import { ContextMenu } from 'components/ContextMenu';
import PropTypes from 'prop-types';
import { useStateDisclosure } from 'utils/hooks';
import SurveyPreviewModal from './modals/SurveyPreviewModal';

export default function SurveysTable({
  surveys,
  showContextMenu,
  onEditSurvey,
  onDeleteSurvey,
  deleteState,
  selectedSurveyIds,
  selectable,
  onSelectSurvey,
}) {
  const [
    previewOpened,
    { open: openPreview, close: closePreview, state: surveyPreview },
  ] = useStateDisclosure(false);

  return (
    <>
      <SurveyPreviewModal
        survey={surveyPreview}
        opened={previewOpened}
        onClose={() => closePreview()}
      />
      <Table highlightOnHover>
        <Table.Thead>
          <Table.Tr>
            {selectable && <Table.Th />}
            <Table.Th>Title</Table.Th>
            <Table.Th>Status</Table.Th>
            <Table.Th>Type</Table.Th>
            <Table.Th>Created At</Table.Th>
            <Table.Th>Updated At</Table.Th>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>
          {surveys?.map((survey) => {
            const isSelected = selectedSurveyIds?.some(
              (item) => item === survey?.id,
            );
            return (
              <SurveyRow
                showContextMenu={showContextMenu}
                onClick={() => openPreview(survey)}
                key={survey.id}
                survey={survey}
                onDelete={onDeleteSurvey}
                onEdit={onEditSurvey}
                isDeleting={
                  deleteState.isDeleting && deleteState.itemId === survey.id
                }
                selected={isSelected}
                selectable={selectable}
                onSelectSurvey={onSelectSurvey}
              />
            );
          })}
        </Table.Tbody>
      </Table>
    </>
  );
}
function SurveyRow({
  survey,
  onEdit,
  onDelete,
  isDeleting,
  showContextMenu,
  onClick,
  selectable,
  selected,
  onSelectSurvey,
}) {
  const menuItems = [
    {
      label: 'Edit',
      icon: IconEdit,
      onClick: () => {
        onEdit(survey);
      },
    },
    {
      label: 'Delete',
      icon: IconTrash,
      onClick: () => {
        onDelete(survey);
      },
    },
  ];
  const createdAt = survey.created_at
    ? moment(survey.created_at).format('DD/MM/YYYY, h:mm a')
    : '';
  const updatedAt = survey.updated_at
    ? moment(survey.updated_at).format('DD/MM/YYYY, h:mm a')
    : '';

  return (
    <Table.Tr
      opacity={isDeleting ? 0.5 : 1}
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      {selectable && (
        <Table.Td>
          <Checkbox
            checked={selected}
            onChange={(e) =>
              onSelectSurvey(e.currentTarget.checked, survey?.id)
            }
            onClick={(e) => e.stopPropagation()}
          />
        </Table.Td>
      )}
      <Table.Td>
        <Flex align="center" gap="md">
          {!isDeleting && showContextMenu && (
            <ContextMenu menuItems={menuItems} />
          )}
          {isDeleting && <Loader color="blue" size={19} />}
          {survey.name}
        </Flex>
      </Table.Td>
      <Table.Td>
        <Pill style={{ textTransform: 'capitalize' }}>
          {survey?.survey_status_name}
        </Pill>
      </Table.Td>
      <Table.Td>
        <Pill>{survey?.survey_type_name}</Pill>
      </Table.Td>
      <Table.Td>{createdAt}</Table.Td>
      <Table.Td>{updatedAt}</Table.Td>
    </Table.Tr>
  );
}

SurveyRow.propTypes = {
  survey: PropTypes.object.isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
  showContextMenu: PropTypes.bool,
  onClick: PropTypes.func,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  onSelectSurvey: PropTypes.func,
};
SurveyRow.defaultProps = {
  onEdit: () => {},
  onDelete: () => {},
  onClick: () => {},
  onSelectSurvey: () => {},
  isDeleting: false,
  showContextMenu: true,
  selectable: false,
  selected: false,
};
SurveysTable.propTypes = {
  surveys: PropTypes.array,
  onEditSurvey: PropTypes.func,
  onDeleteSurvey: PropTypes.func,
  onSelectSurvey: PropTypes.func,
  deleteState: PropTypes.object,
  showContextMenu: PropTypes.bool,
  selectedSurveyIds: PropTypes.array,
  selectable: PropTypes.bool,
};
SurveysTable.defaultProps = {
  surveys: [],
  selectedSurveyIds: [],
  onEditSurvey: () => {},
  onDeleteSurvey: () => {},
  onSelectSurvey: () => {},
  deleteState: {},
  showContextMenu: true,
  selectable: false,
};
