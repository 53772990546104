import { useLoadCompanyIntegrations } from 'api/hooks';
import { useMemo } from 'react';

const useAllowedIntegrations = () => {
  const { companyIntegrations, isLoading: isCompanyIntegrationsLoading } =
    useLoadCompanyIntegrations();

  const allowedIntegrations = useMemo(
    () =>
      companyIntegrations?.reduce(
        (prev, curr) => ({ ...prev, [curr.name]: true }),
        {},
      ),
    [companyIntegrations],
  );

  return {
    isCompanyIntegrationsLoading,
    allowedIntegrations,
  };
};

export default useAllowedIntegrations;
