import { LoadingOverlay, Modal, Text, Button, Flex } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useJob, useUpdateJob, useDeleteJob } from 'api/hooks';
import { JobForm } from 'components/JobForm';
import { PageWrapper } from 'components/PageWrapper';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { useQueryClient } from 'react-query';
import { useGlobalTabsContext } from 'globalTabs';
import { usePageTitle } from 'utils/hooks';
import _ from 'lodash';
import { clearCache } from 'api/clearCache';

export default function EditJob({ params, globalTabName }) {
  const { jobId } = params;
  const { setNotifications } = useNotifications();
  const [isModalOpened, { close: closeModal, open: openModal }] =
    useDisclosure(false);

  const queryClient = useQueryClient();
  const { onRemoveTab } = useGlobalTabsContext();

  const navigate = useNavigate();
  const {
    jobDetails,
    isLoading: isProfileLoading,
    error,
    isFetched,
  } = useJob({ jobId });

  const { deleteJob, isLoading: isDeleteLoading } = useDeleteJob({
    onSuccess: () => {
      setNotifications(['The job has been successfully deleted']);
      navigate('/hiring', { replace: true });
      clearCache.onJobChange(queryClient, { isDelete: true, jobId });
      onRemoveTab(globalTabName);
    },
  });
  const { updateJob, isLoading } = useUpdateJob({
    onSuccess: () => {
      setNotifications(['The job has been successfully updated']);
      clearCache.onJobChange(queryClient, { jobId });
      navigate(`/hiring/job/${jobId}`, { replace: true });
      onRemoveTab(globalTabName);
    },
  });
  const submitForm = (values) => {
    updateJob({
      id: jobId,
      payload: {
        ...values,
      },
    });
  };

  const onDeleteClick = () => {
    openModal();
  };
  const onDelete = () => {
    deleteJob({ id: jobId });
  };

  usePageTitle(
    !_.isEmpty(jobDetails)
      ? `Edit Job - ${jobDetails?.title} - Job`
      : 'Edit Job',
    globalTabName,
  );
  return (
    <PageWrapper title="Edit Job">
      <LoadingOverlay
        visible={isProfileLoading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      {jobDetails && (
        <JobForm
          onSubmit={submitForm}
          job={jobDetails}
          onDelete={onDeleteClick}
          isSubmitLoading={isLoading}
          isDeleteLoading={isDeleteLoading}
          enableFetch={isFetched}
        />
      )}

      <Modal
        opened={isModalOpened}
        onClose={closeModal}
        size="auto"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        title="Warning"
      >
        <Text>Are you sure you want to delete this job?</Text>
        <Flex mt={20} justify="center" align="center" gap="xl">
          <Button onClick={onDelete} variant="filled" color="red">
            Delete
          </Button>
          <Button onClick={closeModal} variant="outline">
            Cancel
          </Button>
        </Flex>
      </Modal>
      {error && <h3 style={{ textAlign: 'center' }}>Job Not Found</h3>}
    </PageWrapper>
  );
}

EditJob.propTypes = {
  params: PropTypes.object,
  globalTabName: PropTypes.string,
};

EditJob.defaultProps = {
  params: {},
  globalTabName: '',
};
