import { Button, Menu, rem } from '@mantine/core';
import { IconSend, IconSettings, IconUserX } from '@tabler/icons-react';
import { useSendResetPasswordLink } from 'api/hooks';
import { usePermissions } from 'components/PermissionGuard/hooks';
import { useNotifications } from 'notifications/hooks/useNotifications';
import PropTypes from 'prop-types';

export default function Options({ profile, onChangeEmployeeStatus }) {
  const { setNotifications } = useNotifications();
  const { permissions, hasPermission } = usePermissions();

  const { sendPasswordResetLink, isLoading: isSending } =
    useSendResetPasswordLink({
      onSuccess: () => {
        setNotifications(['The link was sent to the email']);
      },
    });

  const onSendPassLink = () => {
    sendPasswordResetLink({ email: profile?.email });
  };
  if (!hasPermission(permissions.canManageEmployee)) return null;

  return (
    <Menu shadow="md" width={250}>
      <Menu.Target>
        <Button leftSection={<IconSettings />}>Options</Button>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Employee</Menu.Label>
        <Menu.Item
          leftSection={<IconSend style={{ width: rem(14), height: rem(14) }} />}
          onClick={onSendPassLink}
          disabled={isSending}
        >
          Send change pass link
        </Menu.Item>
        <Menu.Item
          leftSection={
            <IconUserX style={{ width: rem(14), height: rem(14) }} />
          }
          onClick={onChangeEmployeeStatus}
        >
          End Employment
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

Options.propTypes = {
  profile: PropTypes.object.isRequired,
  onChangeEmployeeStatus: PropTypes.func.isRequired,
};
