import { Table } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { ContextMenu } from 'components/ContextMenu';
import moment from 'moment';
import PropTypes from 'prop-types';

export default function PayScheduleRow({ paySchedule, onEdit, onDelete }) {
  const menuItems = [
    {
      label: 'Edit',
      icon: IconEdit,
      onClick: () => onEdit(paySchedule),
    },
    {
      label: 'Delete',
      icon: IconTrash,
      onClick: () => onDelete(paySchedule),
    },
  ];
  return (
    <Table.Tr>
      <Table.Td>{paySchedule?.name}</Table.Td>
      <Table.Td>{paySchedule?.frequency_name}</Table.Td>
      {/* works only for Monthly frequency */}
      <Table.Td>
        {moment().format('MMM')} {paySchedule?.settings?.pay_period}
      </Table.Td>
      <Table.Td>{paySchedule?.employees_count}</Table.Td>
      <Table.Td ta="right">
        <ContextMenu menuItems={menuItems} />
      </Table.Td>
    </Table.Tr>
  );
}

PayScheduleRow.propTypes = {
  paySchedule: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};
