import { Avatar, Flex, Stack, Text } from '@mantine/core';
import React from 'react';
import PropTypes from 'prop-types';

export default function EmployeeForContact({ employee }) {
  return (
    <Flex gap={10}>
      <Avatar size={60} src={employee?.avatar_filename} />
      <Stack gap={0}>
        <Text>
          {employee?.first_name} {employee?.last_name}
        </Text>
        <Text c="var(--mantine-color-gray-6)">
          {employee?.job_title || employee?.jobTitle}
        </Text>
        <Text c="var(--mantine-color-gray-6)">{employee?.phone_mobile}</Text>
        <Text c="var(--mantine-color-gray-6)">{employee?.email}</Text>
      </Stack>
    </Flex>
  );
}

EmployeeForContact.propTypes = {
  employee: PropTypes.object.isRequired,
};
