import { Button, Flex, Modal, Stack, Text } from '@mantine/core';
import PropTypes from 'prop-types';

export default function ConfirmationModal({
  opened,
  onClose,
  title,
  text,
  onConfirm,
  isLoading,
}) {
  return (
    <Modal opened={opened} onClose={onClose} centered title={title}>
      <Stack>
        <Text size="sm">{text}</Text>
        <Flex gap={10} justify="end">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onConfirm} disabled={!opened || isLoading}>
            Confirm
          </Button>
        </Flex>
      </Stack>
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
  title: undefined,
  isLoading: false,
};
