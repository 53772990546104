import { Table, rem, Button } from '@mantine/core';
import PropTypes from 'prop-types';
import { IconTrash } from '@tabler/icons-react';
import classes from './Candidate.module.css';

export default function PoolCandidatesTableRow({
  item,
  deleteId,
  onDelete,
  isDeleteLoading,
}) {
  return (
    <Table.Tr className={[classes.rowSelected, classes.pointer]}>
      <Table.Td>
        {item.first_name} {item.last_name}
      </Table.Td>
      <Table.Td>{item.createdAt?.toDateString()}</Table.Td>
      <Table.Td>{item.description}</Table.Td>
      <Table.Td>
        <Button
          p={0}
          style={{ width: rem(35), height: rem(35) }}
          variant="white"
          onClick={() => onDelete(item)}
          loading={isDeleteLoading && item.id === deleteId}
        >
          {!(isDeleteLoading && item.id === deleteId) && (
            <IconTrash style={{ width: rem(30), height: rem(30) }} />
          )}
        </Button>
      </Table.Td>
    </Table.Tr>
  );
}

PoolCandidatesTableRow.propTypes = {
  item: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  isDeleteLoading: PropTypes.bool,
  deleteId: PropTypes.number,
};
PoolCandidatesTableRow.defaultProps = {
  onDelete: () => {},
  isDeleteLoading: false,
  deleteId: -1,
};
