import { Select } from '@mantine/core';
import PropTypes from 'prop-types';

export default function SelectFormInput({ field, onChange, value, error }) {
  return (
    <Select
      label={field?.displayName}
      placeholder={field?.displayName}
      data={field?.options ? ['', ...field.options] : []}
      value={value}
      onChange={onChange}
      error={error}
      required={field?.validation?.isRequired}
    />
  );
}

SelectFormInput.propTypes = {
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  error: PropTypes.any,
};
SelectFormInput.defaultProps = {
  value: '',
  error: null,
};
