import { Box, Flex, Loader, Stack, Text } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { clearCache } from 'api/clearCache';
import { useDetachWidget } from 'api/hooks';
import { useNotifications } from 'notifications/hooks/useNotifications';
import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { WidgetsTypes } from 'utils/constants';
import CandidatesOffers from '../widgets/CandidatesOffers';
import Celebrations from '../widgets/Celebrations';
import CompanyHolidays from '../widgets/CompanyHolidays';
import CompanyLinks from '../widgets/CompanyLinks';
import TimeOffRequest from '../widgets/TimeOffRequest';
import WelcomeTo from '../widgets/WelcomeTo';
import WhoIsOut from '../widgets/WhoIsOut';

const GenericActiveWidget = memo(({ widget, isFetching, deletable }) => {
  const queryClient = useQueryClient();
  const { setNotifications } = useNotifications();

  const {
    detachWidget,
    isLoading: isDetachingWidget,
    variables: detachWidgetId,
  } = useDetachWidget({
    onSuccess: () => {
      setNotifications(['Widget Detached Successfully']);
      clearCache.onChangeWidget(queryClient);
    },
  });

  const Provider = {
    [WidgetsTypes.celebrations]: Celebrations,
    [WidgetsTypes.whoOut]: WhoIsOut,
    [WidgetsTypes.companyLinks]: CompanyLinks,
    [WidgetsTypes.companyHolidays]: CompanyHolidays,
    [WidgetsTypes.welcomeAtomate]: WelcomeTo,
    [WidgetsTypes.candidatesOffers]: CandidatesOffers,
    [WidgetsTypes.timeoffRequests]: TimeOffRequest,
  };

  const Component = Provider?.[widget?.widget?.component_name] ?? null;

  const isCurrentItemDeleting = useMemo(
    () => (isDetachingWidget || isFetching) && detachWidgetId === widget?.id,
    [isDetachingWidget, isFetching, detachWidgetId, widget?.id],
  );

  if (Component) {
    return (
      <Component
        deletable={deletable}
        onDelete={() => detachWidget(widget?.id)}
        isCurrentItemDeleting={isCurrentItemDeleting}
      />
    );
  }

  return (
    <Stack
      gap={0}
      w="100%"
      pb={10}
      style={{
        border: '1px solid var(--mantine-color-gray-3)',
        borderBottom: '1px solid var(--mantine-color-dark-1)',
        borderRadius: '10px',
      }}
      bg="var(--white)"
    >
      <Box
        w="100%"
        bg="var(--mantine-color-blue-5)"
        h={5}
        style={{ borderRadius: '10px 10px 0 0' }}
      />
      <Stack gap={0} px={10} pt={10}>
        <Flex align="center" justify="space-between">
          <Text fw={600}>{widget?.widget?.name}</Text>
          {isCurrentItemDeleting && <Loader size={20} />}

          {!isCurrentItemDeleting && deletable && (
            <IconTrash
              cursor="pointer"
              size={20}
              onClick={() => detachWidget(widget?.id)}
            />
          )}
        </Flex>
        <Text>{widget?.widget?.description}</Text>
      </Stack>
    </Stack>
  );
});

export default GenericActiveWidget;

GenericActiveWidget.propTypes = {
  widget: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  deletable: PropTypes.bool,
};

GenericActiveWidget.defaultProps = {
  deletable: false,
};
