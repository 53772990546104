import { Avatar, Paper, Text } from '@mantine/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classes from './EmployeesGridItem.module.css';

export default function EmployeesGridItem({ employee }) {
  return (
    <Paper
      radius="md"
      withBorder
      p="lg"
      bg="var(--mantine-color-body)"
      className={classes.card}
    >
      <Link
        to={`/employees/${employee?.id}`}
        style={{ color: 'inherit', textDecoration: 'none' }}
      >
        <Avatar
          src={employee?.avatar_filename}
          size={120}
          radius={120}
          mx="auto"
        />
        <Text ta="center" fz="lg" fw={500} mt="md" c="inherit">
          {employee.first_name} {employee.last_name}
        </Text>
      </Link>
    </Paper>
  );
}
EmployeesGridItem.propTypes = {
  employee: PropTypes.object.isRequired,
};
