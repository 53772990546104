import { Card, Text } from '@mantine/core';

import { usePermissions } from 'components/PermissionGuard/hooks';
import { PermissionGuard } from 'components/PermissionGuard';

import classes from '../Home.module.css';

export default function AdminSection() {
  const { permissions } = usePermissions();

  return (
    <PermissionGuard permission={permissions.canAccessAdmin}>
      <Card
        withBorder
        my={10}
        p="xl"
        mx="md"
        radius="md"
        className={classes.card}
      >
        <Text fz="xl" className={classes.label}>
          Admin section
        </Text>
      </Card>
    </PermissionGuard>
  );
}
