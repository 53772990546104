import { Flex, Space, Table, rem } from '@mantine/core';
import { AddButton } from 'components/Buttons';
import React, { useMemo } from 'react';
import _ from 'lodash';
import {
  useCreateEmailTemplate,
  useDeleteEmailTemplate,
  useLoadEmailTemplates,
  useUpdateEmailTemplate,
} from 'api/hooks';
import { useStateDisclosure } from 'utils/hooks';
import { Loader } from 'components/Loader';
import { ConfirmDeleteModal } from 'components/ConfirmDeleteModal';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { CreateEmailTemplate } from 'components/CreateEmailTemplate';
import { TemplatesRow } from './tab-rows';

export default function EmailTemplates() {
  const [
    openedDeleteModal,
    { open: openDeleteModal, close: closeDeleteModal, state: deleteState },
  ] = useStateDisclosure(false);

  const [
    openedCreateModal,
    {
      open: openCreateModal,
      close: closeCreateModal,
      state: emailTemplateState,
    },
  ] = useStateDisclosure(false);

  const { setNotifications } = useNotifications();
  const {
    emailTemplates,
    isLoading: isEmailLoading,
    refetch: refetchEmails,
  } = useLoadEmailTemplates();

  const { createEmailTemplate, isLoading: isCreatingTemplate } =
    useCreateEmailTemplate({
      onSuccess: () => {
        setNotifications(['Email Template Created Successfully']);
        closeCreateModal();
        refetchEmails();
      },
    });

  const { updateEmailTemplate, isLoading: isUpdatingEmailTemplate } =
    useUpdateEmailTemplate({
      onSuccess: () => {
        setNotifications(['Email Template Updated Successfully']);
        closeCreateModal();
        refetchEmails();
      },
    });

  const { deleteEmailTemplate, isLoading: isDeleting } = useDeleteEmailTemplate(
    {
      onSuccess: () => {
        setNotifications(['Email Template Delete Successfully!']);
        closeDeleteModal();
        refetchEmails();
      },
    },
  );

  const { templatesCreatedWithSystem, restTemplates } = useMemo(
    () =>
      _.isEmpty(emailTemplates)
        ? { restTemplates: [], templatesCreatedWithSystem: [] }
        : _.groupBy(emailTemplates, (val) =>
            val?.is_deletable ? 'restTemplates' : 'templatesCreatedWithSystem',
          ),

    [emailTemplates],
  );

  return (
    <>
      <ConfirmDeleteModal
        opened={openedDeleteModal}
        onClose={closeDeleteModal}
        deleteItemName={deleteState?.name}
        isLoading={isDeleting}
        onDelete={() => deleteEmailTemplate(deleteState?.id)}
      />
      <CreateEmailTemplate
        opened={openedCreateModal}
        onClose={closeCreateModal}
        emailState={emailTemplateState}
        isLoading={isCreatingTemplate || isUpdatingEmailTemplate}
        onCreate={createEmailTemplate}
        onEdit={(data) =>
          updateEmailTemplate({ id: emailTemplateState?.id, ...data })
        }
      />
      <Flex justify="flex-end">
        <AddButton onClick={() => openCreateModal()}>Add Template</AddButton>
      </Flex>
      <Space h="xs" />
      {!_.isEmpty(emailTemplates) && (
        <Table>
          <Table.Thead
            styles={{
              thead: { backgroundColor: 'var(--mantine-color-gray-4)' },
            }}
          >
            <Table.Tr>
              <Table.Th>Name</Table.Th>
              <Table.Th miw={rem(300)}>Subject</Table.Th>
              <Table.Th />
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {_.map(templatesCreatedWithSystem, (item) => {
              const isLast =
                item?.id === _.last(templatesCreatedWithSystem)?.id;
              return (
                <React.Fragment key={item?.id}>
                  <TemplatesRow
                    data={item}
                    isLast={isLast}
                    onEdit={(data) =>
                      openCreateModal({ ...data, modalType: 'Edit' })
                    }
                  />
                </React.Fragment>
              );
            })}
            {_.map(restTemplates, (item) => (
              <TemplatesRow
                key={item?.id}
                data={item}
                onEdit={(data) =>
                  openCreateModal({ ...data, modalType: 'Edit' })
                }
                onDelete={openDeleteModal}
              />
            ))}
          </Table.Tbody>
        </Table>
      )}
      <Loader isLoading={isEmailLoading} />
    </>
  );
}
