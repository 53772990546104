import { useLoadLookups } from 'api/hooks';
import _ from 'lodash';
import { useMemo } from 'react';
import { lookupsFilters, lookupsModels } from 'utils/constants';

const employeeStatusFilters = lookupsFilters[lookupsModels.employeeStatus];

const getFormattedData = (
  data,
  fieldLabel,
  { icon, selectedValues = [] } = {},
) => ({
  values: _.uniqBy((data ?? []).concat(selectedValues), 'id'),
  field: fieldLabel,
  icon,
});

const markAsExist = (dataList) =>
  dataList.map((item) => ({
    ...item,
    isExist: true,
  }));

const mergeDataWithSelected = ({ data, selectedValues, label, modelName }) => ({
  [modelName]: getFormattedData(data[modelName], label, {
    selectedValues: selectedValues[modelName],
  }),
});

export function useFormattedFilters({ selectedValues }) {
  const { lookups, isLoading: isLookupsLoading } = useLoadLookups({
    models: [
      lookupsModels.jobTitle,
      lookupsModels.department,
      lookupsModels.division,
      lookupsModels.location,
      lookupsModels.employeeStatus,
    ],
    filters: {
      [lookupsModels.employeeStatus]: [employeeStatusFilters.onlyActive],
    },
  });

  const markedLookupsAsExist = useMemo(() => {
    if (_.isEmpty(lookups)) return {};

    return {
      [lookupsModels.jobTitle]: markAsExist(lookups[lookupsModels.jobTitle]),
      [lookupsModels.department]: markAsExist(
        lookups[lookupsModels.department],
      ),
      [lookupsModels.division]: markAsExist(lookups[lookupsModels.division]),
      [lookupsModels.location]: markAsExist(lookups[lookupsModels.location]),
      [lookupsModels.employeeStatus]: markAsExist(
        lookups[lookupsModels.employeeStatus],
      ),
    };
  }, [lookups]);

  const filteredData = useMemo(() => {
    if (_.isEmpty(markedLookupsAsExist)) return {};

    return {
      ...mergeDataWithSelected({
        data: markedLookupsAsExist,
        selectedValues,
        label: 'Job Title',
        modelName: lookupsModels.jobTitle,
      }),
      ...mergeDataWithSelected({
        data: markedLookupsAsExist,
        selectedValues,
        label: 'Department',
        modelName: lookupsModels.department,
      }),
      ...mergeDataWithSelected({
        data: markedLookupsAsExist,
        selectedValues,
        label: 'Division',
        modelName: lookupsModels.division,
      }),
      ...mergeDataWithSelected({
        data: markedLookupsAsExist,
        selectedValues,
        label: 'Location',
        modelName: lookupsModels.location,
      }),
      ...mergeDataWithSelected({
        data: markedLookupsAsExist,
        selectedValues,
        label: 'Employee Status',
        modelName: lookupsModels.employeeStatus,
      }),
    };
  }, [selectedValues, markedLookupsAsExist]);

  return {
    isLookupsLoading,
    filteredData,
  };
}
