import { Select, Stack } from '@mantine/core';
import { useLoadEmployeeAssessmentSurveys } from 'api/hooks';
import { FeedbackTableView } from 'components/FeedbackTableView';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { surveys } from 'utils/constants';
import { usePagination } from 'utils/hooks';
import { PerformanceFilter } from '../components';

export default function FeedbackTab({ dateSelectData, employeeId }) {
  const [selectedMonthType, setSelectedMonthType] = useState('3');

  const { page, setPage } = usePagination({ page: 1 });

  const [dateRange, setDateRange] = useState({ from: '', to: '' });
  const [assessmentType, setAssessmentType] = useState('0');

  const { employeeAssessmentSurveys, isLoading: isEmployeeAssessmentLoading } =
    useLoadEmployeeAssessmentSurveys(
      {
        employeeId,
        filter: {
          lastMonths: selectedMonthType !== '0' ? selectedMonthType : undefined,
          fromDate: selectedMonthType === '0' ? dateRange.from : undefined,
          toDate: selectedMonthType === '0' ? dateRange.to : undefined,
          byFeedbackSurveyType: assessmentType,
        },
        page,
      },
      {
        enabled: !!(selectedMonthType === '0'
          ? dateRange.from && dateRange.to
          : selectedMonthType),
      },
    );

  const onChangeFilter = (callback) => (data) => {
    setPage(1);
    callback(data);
  };

  return (
    <Stack ml={-20}>
      <PerformanceFilter
        selectedMonthType={selectedMonthType}
        setSelectedMonthType={onChangeFilter(setSelectedMonthType)}
        dateSelectData={dateSelectData}
        dateRange={dateRange}
        setDateRange={setDateRange}
        rightSection={
          <Select
            ml="auto"
            value={assessmentType}
            onChange={onChangeFilter(setAssessmentType)}
            data={[
              { value: '0', label: 'All' },
              surveys.peerFeedback,
              surveys.upwardFeedback,
            ]}
            allowDeselect={false}
          />
        }
      />
      <FeedbackTableView
        isLoading={isEmployeeAssessmentLoading}
        surveys={employeeAssessmentSurveys}
        page={page}
        setPage={setPage}
      />
    </Stack>
  );
}

FeedbackTab.propTypes = {
  employeeId: PropTypes.string.isRequired,
  dateSelectData: PropTypes.object,
};

FeedbackTab.defaultProps = {
  dateSelectData: {},
};
