import { Button, Divider, Flex, Space, Table, Title } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { clearCache } from 'api/clearCache';
import {
  useCreatePaySchedule,
  useDeletePaySchedule,
  useLoadLookups,
  useLoadPaySchedule,
  useUpdatePaySchedule,
} from 'api/hooks';
import { ConfirmDeleteModal } from 'components/ConfirmDeleteModal';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import { Loader } from 'components/Loader';
import { PayScheduleModal } from 'components/PayScheduleModal';
import { SettingsTableHead } from 'components/SettingsTable';
import _ from 'lodash';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { useQueryClient } from 'react-query';
import { lookupsModels } from 'utils/constants';
import { useStateDisclosure } from 'utils/hooks';
import { PayScheduleRow } from '../components';

const headerList = ['Name', 'Frequency', 'Period Ends', 'People', ''];

export default function PaySchedule() {
  const queryClient = useQueryClient();
  const { setNotifications } = useNotifications();
  const { lookups } = useLoadLookups({
    models: [lookupsModels.compensationPaySchedule],
  });

  const { paySchedules, isLoading: isPaySchedulesLoading } =
    useLoadPaySchedule();

  const [
    openedPayScheduleModal,
    {
      open: openPayScheduleModal,
      close: closePayScheduleModal,
      state: payScheduleState,
    },
  ] = useStateDisclosure(false);

  const [
    openedConfirmationDeleteModal,
    {
      open: openConfirmationDeleteModal,
      close: closeConfirmationDeleteModal,
      state: confirmationDeleteState,
    },
  ] = useStateDisclosure(false);

  const { createPaySchedule, isLoading: isPayScheduleCreating } =
    useCreatePaySchedule({
      onSuccess: () => {
        clearCache.onChangePaySchedule(queryClient);
        setNotifications(['Pay Schedule Created Successfully']);
        closePayScheduleModal();
      },
    });

  const { updatePaySchedule, isLoading: isPayScheduleUpdating } =
    useUpdatePaySchedule({
      onSuccess: () => {
        clearCache.onChangePaySchedule(queryClient);
        setNotifications(['Pay Schedule Updated Successfully']);
        closePayScheduleModal();
      },
    });
  const { deletePaySchedule, isLoading: isDeletingPaySchedule } =
    useDeletePaySchedule({
      onSuccess: () => {
        clearCache.onChangePaySchedule(queryClient);
        setNotifications(['Pay Schedule Deleted Successfully']);
        closeConfirmationDeleteModal();
      },
    });

  return (
    <>
      <PayScheduleModal
        opened={openedPayScheduleModal}
        onClose={closePayScheduleModal}
        payScheduleState={payScheduleState}
        onCreate={({ name, ...settings }) =>
          createPaySchedule({ name, settings })
        }
        onEdit={({ name, ...settings }) =>
          updatePaySchedule({
            payScheduleId: payScheduleState?.id,
            name,
            settings,
          })
        }
        frequencyObjectList={
          lookups?.CompensationPaySchedule?.frequencies ?? {}
        }
        isLoading={isPayScheduleCreating || isPayScheduleUpdating}
      />
      <ConfirmDeleteModal
        opened={openedConfirmationDeleteModal}
        onClose={closeConfirmationDeleteModal}
        onDelete={() => deletePaySchedule(confirmationDeleteState?.id)}
        isLoading={isDeletingPaySchedule}
      />
      <Flex gap={10} justify="space-between" align="center">
        <Title fz="xl">Pay Schedule</Title>
        <Button
          onClick={() => openPayScheduleModal()}
          rightSection={<IconPlus size={14} />}
        >
          Add Pay Schedule
        </Button>
      </Flex>

      <Space h="xs" />

      <Loader isLoading={isPaySchedulesLoading} />

      <EmptyDataMessage
        isVisible={!isPaySchedulesLoading && _.isEmpty(paySchedules)}
      />

      {!_.isEmpty(paySchedules) && (
        <>
          <Table>
            <SettingsTableHead headerList={headerList} />
            <Table.Tbody>
              {_.map(paySchedules, (paySchedule) => (
                <PayScheduleRow
                  key={paySchedule?.id}
                  paySchedule={paySchedule}
                  onEdit={(data) =>
                    openPayScheduleModal({ ...data, modalType: 'Edit' })
                  }
                  onDelete={openConfirmationDeleteModal}
                />
              ))}
            </Table.Tbody>
          </Table>
          <Divider />
        </>
      )}
    </>
  );
}
