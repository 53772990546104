import { Tabs } from '@mantine/core';
import { useLoadLookups } from 'api/hooks';
import { TabList, TabsCore } from 'components/Tabs';
import PropTypes from 'prop-types';
import { lookupsModels } from 'utils/constants';
import { AssessmentTab, FeedbackTab } from './tabs';

export default function PerformanceTab({ employeeId }) {
  const { lookups } = useLoadLookups({
    models: [lookupsModels.surveyResponse],
  });

  return (
    <TabsCore defaultValue="feedback" ml="20" mr="20">
      <TabList withPaddings maw="max-content">
        <Tabs.Tab value="feedback">Feedback</Tabs.Tab>
        <Tabs.Tab value="assessment">Assessment</Tabs.Tab>
      </TabList>
      <Tabs.Panel value="feedback">
        <FeedbackTab
          dateSelectData={
            lookups?.[lookupsModels.surveyResponse]?.filter_intervals
          }
          employeeId={employeeId}
        />
      </Tabs.Panel>
      <Tabs.Panel value="assessment">
        <AssessmentTab
          dateSelectData={
            lookups?.[lookupsModels.surveyResponse]?.filter_intervals
          }
          employeeId={employeeId}
        />
      </Tabs.Panel>
    </TabsCore>
  );
}

PerformanceTab.propTypes = {
  employeeId: PropTypes.string.isRequired,
};
