import { useEffect, useMemo, useRef } from 'react';
import { Space, Flex, Button, LoadingOverlay } from '@mantine/core';
import {
  useTrainings,
  useTrainingsCategories,
  useCreateTraining,
  useCreateCategory,
  useDeleteTraining,
  useDeleteCategory,
  useUpdateCategory,
  useUpdateTraining,
} from 'api/hooks';
import { TrainingsTable } from 'components/TrainingsTable';
import { IconCertificate, IconCategory } from '@tabler/icons-react';
import { CreateCategoryModal } from 'components/CreateCategoryModal';
import { CreateTrainingModal } from 'components/CreateTrainingModal';
import { useStateDisclosure } from 'utils/hooks';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { useQueryClient } from 'react-query';
import { clearCache } from 'api/clearCache';
import { ConfirmDeleteModal } from 'components/ConfirmDeleteModal';

export default function TrainingsTab() {
  const { setNotifications } = useNotifications();

  const queryClient = useQueryClient();

  const [
    openedConfirmDeleteCategory,
    {
      open: openConfirmDeleteCategory,
      close: closeConfirmDeleteCategory,
      state: stateConfirmDeleteCategory,
    },
  ] = useStateDisclosure(false);

  const [
    openedConfirmDeleteTraining,
    {
      open: openConfirmDeleteTraining,
      close: closeConfirmDeleteTraining,
      state: stateConfirmDeleteTraining,
    },
  ] = useStateDisclosure(false);

  const {
    trainings,
    isLoading: isTrainingsLoading,
    refetch: refetchTraining,
    isRefetching: isTrainingsRefetching,
  } = useTrainings();
  const {
    categories,
    isLoading: isCategoriesLoading,
    refetch: refetchCategories,
    isRefetching: isCategoriesRefetching,
  } = useTrainingsCategories();

  const [
    categoryModalOpened,
    {
      open: openCategoryModal,
      close: closeCategoryModal,
      state: categoryToEdit,
    },
  ] = useStateDisclosure(false);
  const [
    trainingModalOpened,
    {
      open: openTrainingModal,
      close: closeTrainingModal,
      state: trainingToEdit,
    },
  ] = useStateDisclosure(false);

  const { createTraining, isLoading: isCreatingTraining } = useCreateTraining({
    onSuccess: () => {
      refetchTraining();
      closeTrainingModal();
      setNotifications(['Training successfully created']);
    },
  });

  const { createCategory, isLoading: isCreatingCategory } = useCreateCategory({
    onSuccess: () => {
      refetchCategories();
      closeCategoryModal();
      setNotifications(['Category successfully created']);
      clearCache.onCategoryChange(queryClient);
    },
  });

  const { updateCategory, isLoading: isUpdatingCategory } = useUpdateCategory({
    onSuccess: () => {
      refetchCategories();
      closeCategoryModal();
      setNotifications(['Category successfully updated']);
      clearCache.onCategoryChange(queryClient);
    },
  });

  const { updateTraining, isLoading: isUpdatingTraining } = useUpdateTraining({
    onSuccess: () => {
      refetchTraining();
      closeTrainingModal();
      setNotifications(['Training successfully updated']);
    },
  });

  const {
    deleteTraining,
    isLoading: isDeletingTraining,
    variables: deleteTrainingVariable,
  } = useDeleteTraining({
    onSuccess: () => {
      refetchTraining();
      setNotifications(['Training successfully deleted']);
      closeConfirmDeleteTraining();
    },
  });

  const {
    deleteCategory,
    isLoading: isDeletingCategory,
    variables: deleteCategoryVariable,
  } = useDeleteCategory({
    onSuccess: () => {
      refetchCategories();
      setNotifications(['Category successfully deleted']);
      clearCache.onCategoryChange(queryClient);
      closeConfirmDeleteCategory();
    },
  });
  const onEditTraining = (training) => {
    openTrainingModal(training);
  };

  const onEditCategory = (category) => {
    openCategoryModal(category);
  };

  const onDeleteCategory = (category) => {
    openConfirmDeleteCategory(category);
  };

  const onDeleteTraining = (training) => {
    openConfirmDeleteTraining(training);
  };
  const groupedCategories = useMemo(() => {
    // TODO: Optimize this
    const activeCategories = categories?.map((category) => ({
      ...category,
      trainings: trainings?.filter(
        (training) => training.category_id === category.id,
      ),
    }));
    activeCategories.push({
      name: 'All Trainings',
      trainings: trainings?.filter((training) => !training.category_id),
    });
    return activeCategories;
  }, [categories, trainings]);

  const onSubmitCreateTraining = (payload) => {
    createTraining(payload);
  };

  const onSubmitUpdateTraining = (payload) => {
    if (trainingToEdit) {
      updateTraining({ id: trainingToEdit.id, payload });
    }
  };

  const onSubmitCreateCategory = (payload) => {
    createCategory(payload);
  };

  const onSubmitUpdateCategory = (payload) => {
    if (categoryToEdit) {
      updateCategory({ id: categoryToEdit.id, payload });
    }
  };
  const categoryModalRef = useRef(null);

  useEffect(() => {
    if (categoryModalRef.current) {
      categoryModalRef.current.value = isUpdatingCategory;
    }
  }, [isUpdatingCategory]);

  return (
    <>
      <LoadingOverlay
        visible={isTrainingsLoading || isCategoriesLoading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <CreateCategoryModal
        opened={categoryModalOpened}
        onClose={() => {
          closeCategoryModal();
        }}
        onSubmit={
          categoryToEdit ? onSubmitUpdateCategory : onSubmitCreateCategory
        }
        isLoading={isCreatingCategory || isUpdatingCategory}
        category={categoryToEdit}
      />
      <ConfirmDeleteModal
        opened={openedConfirmDeleteCategory}
        onClose={closeConfirmDeleteCategory}
        onDelete={() => deleteCategory(stateConfirmDeleteCategory?.id)}
        customText={`Are you sure you want to delete "${
          stateConfirmDeleteCategory?.name ?? ''
        }"`}
        subText="All trainings with this category will be re-assigned to no category. This action cannot be undone."
        cancelButtonLabel="Keep this Category"
        confirmButtonLabel="Delete This Category"
        modalProps={{ size: 'lg' }}
        isLoading={isDeletingCategory}
      />
      <ConfirmDeleteModal
        opened={openedConfirmDeleteTraining}
        onClose={closeConfirmDeleteTraining}
        onDelete={() => deleteTraining(stateConfirmDeleteTraining?.id)}
        customText={`Are you sure you want to delete "${
          stateConfirmDeleteTraining?.name ?? ''
        }"`}
        modalProps={{ size: 'lg' }}
        subText={`Deleting this training will affect ${
          stateConfirmDeleteTraining?.total_employee ?? 0
        } employees`}
        cancelButtonLabel="Keep this Training"
        confirmButtonLabel="Delete This Training"
        isLoading={isDeletingTraining}
      />
      <CreateTrainingModal
        opened={trainingModalOpened}
        onClose={() => {
          closeTrainingModal();
        }}
        categories={categories}
        onSubmit={
          trainingToEdit ? onSubmitUpdateTraining : onSubmitCreateTraining
        }
        isLoading={isCreatingTraining || isUpdatingTraining}
        training={trainingToEdit}
      />
      <Space h="md" />
      <Flex gap="md" justify="flex-end" align="center">
        <Button
          onClick={() => openTrainingModal()}
          rightSection={<IconCertificate size={20} />}
          disabled={isTrainingsRefetching || isCategoriesRefetching}
        >
          Add Training
        </Button>
        <Button
          onClick={() => openCategoryModal()}
          rightSection={<IconCategory size={20} />}
          disabled={isTrainingsRefetching || isCategoriesRefetching}
        >
          Add Category
        </Button>
      </Flex>
      <TrainingsTable
        groupedItems={groupedCategories}
        onDeleteTraining={onDeleteTraining}
        onDeleteCategory={onDeleteCategory}
        onEditTraining={onEditTraining}
        onEditCategory={onEditCategory}
        categoryDeleteState={{
          isDeleting: isDeletingCategory,
          itemId: deleteCategoryVariable,
        }}
        trainingDeleteState={{
          isDeleting: isDeletingTraining,
          itemId: deleteTrainingVariable,
        }}
      />
    </>
  );
}
