import { Space, Stack, Tabs, TextInput, rem } from '@mantine/core';
import { IconSettings } from '@tabler/icons-react';
import { PageWrapper } from 'components/PageWrapper';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePageTitle } from 'utils/hooks';

import { useLoadLookups } from 'api/hooks';
import { useAuthContext } from 'auth';
import { LanguageSelector } from 'components/LanguageSelector';
import { lookupsModels } from 'utils/constants';
import classes from './UserProfile.module.css';
import { PasswordAndSecurityTab } from './tabs';

const tabs = ['general', 'password-security'];
const iconStyle = { width: rem(18), height: rem(18) };

const tabsLabel = {
  [tabs[0]]: 'General',
  [tabs[1]]: 'Password & Security',
};

export default function UserProfile({ params, globalTabName }) {
  const { tab: tabValue } = params;

  const { user, refetchUser } = useAuthContext();
  const { lookups } = useLoadLookups({ models: [lookupsModels.language] });

  const navigate = useNavigate();

  useEffect(() => {
    if (!tabValue || !tabs.includes(tabValue)) {
      navigate('/user/profile/general', { replace: true });
    }
  }, [tabValue, navigate]);

  usePageTitle(
    `Account Settings - ${tabsLabel[tabValue] ?? ''}`,
    globalTabName,
  );

  return (
    <PageWrapper title="Account Settings">
      <Tabs
        value={tabValue}
        onChange={(value) => navigate(`/user/profile/${value}`)}
        keepMounted={false}
      >
        <Tabs.List>
          <Tabs.Tab
            value="general"
            leftSection={<IconSettings style={iconStyle} />}
          >
            General
          </Tabs.Tab>
          <Tabs.Tab
            value="password-security"
            leftSection={<IconSettings style={iconStyle} />}
          >
            Password & Security
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="general">
          <Space h="md" />
          <Stack gap={10}>
            <LanguageSelector
              employeeId={user?.id}
              userSelectedLanguage={user?.lang}
              languages={lookups?.Language ?? []}
              refetchUserData={refetchUser}
            />
            <TextInput
              label="Email"
              placeholder="Add your email"
              classNames={classes}
            />
          </Stack>
        </Tabs.Panel>
        <Tabs.Panel value="password-security">
          <PasswordAndSecurityTab />
        </Tabs.Panel>
      </Tabs>
    </PageWrapper>
  );
}

UserProfile.propTypes = {
  params: PropTypes.object,
  globalTabName: PropTypes.string,
};

UserProfile.defaultProps = {
  params: {},
  globalTabName: '',
};
