import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Flex, Stack, Table, Text } from '@mantine/core';
import {
  IconAmbulance,
  IconCircleCheck,
  IconClock,
  IconSunHigh,
  IconTrash,
} from '@tabler/icons-react';
import moment from 'moment';
import { ContextMenu } from 'components/ContextMenu';
import { useStateDisclosure } from 'utils/hooks';
import { ConfirmDeleteModal } from 'components/ConfirmDeleteModal';
import { useDeleteTimeOffRecords } from 'api/hooks';
import { usePermissions } from 'components/PermissionGuard/hooks';
import { Loader } from 'components/Loader';
import { useQueryClient } from 'react-query';
import { queryKeys } from 'api/keys';
import { useNotifications } from 'notifications/hooks/useNotifications';

const getUpcomingIcon = (policy) => {
  const availablePolicyIcons = {
    sick: <IconAmbulance size={50} stroke={1.5} />,
    vacation: <IconSunHigh size={50} stroke={1.5} />,
    default: <IconSunHigh size={50} stroke={1.5} />,
  };

  const icon = _.keys(availablePolicyIcons).find((item) =>
    policy?.toLowerCase()?.includes(item.toLowerCase()),
  );

  return availablePolicyIcons[icon] ?? availablePolicyIcons.default;
};

const formatTimeOffUpcomingDate = (startDate, endDate) => {
  const startD = moment(startDate, 'YYYY-MM-DD');
  const endD = moment(endDate, 'YYYY-MM-DD');
  const isSameMonth = endD.month() === startD.month();

  const formattedDate = isSameMonth
    ? `${startD.format('MMM DD')} - ${endD.format('DD')}`
    : `${startD.format('MMM DD')} - ${endD.format('MMM DD')}`;

  return formattedDate;
};

export default function UpcomingTimeOffTable({ upcomingTimeOff }) {
  const queryClient = useQueryClient();
  const { setNotifications } = useNotifications();

  const [
    openedCancelConfirmation,
    {
      close: closeCancelConfirmation,
      open: openCancelConfirmation,
      state: cancelConfirmationState,
    },
  ] = useStateDisclosure(false);

  const { hasPermission, permissions } = usePermissions();

  const { deleteRecords, isLoading: isDeleting } = useDeleteTimeOffRecords(
    hasPermission(permissions.canManageEmployee),
    {
      onSuccess: () => {
        closeCancelConfirmation();
        setNotifications(['Time off canceled successfully']);
        queryClient.invalidateQueries(
          queryKeys.timeOffRecords(
            hasPermission(permissions.canManageEmployee),
          ),
        );
      },
    },
  );

  if (_.isEmpty(upcomingTimeOff)) return null;

  return (
    <Stack gap={0}>
      <ConfirmDeleteModal
        onClose={closeCancelConfirmation}
        opened={openedCancelConfirmation}
        onDelete={() => {
          const ids = _.map(cancelConfirmationState?.interval?.records, 'id');
          const { startDate, endDate } = cancelConfirmationState;
          deleteRecords({ ids, start_date: startDate, end_date: endDate });
        }}
        customText={`Are you sure you want to cancel this time-off for ${
          cancelConfirmationState?.formattedDate ?? ''
        } ?`}
        confirmButtonLabel="Yes, Cancel"
        isLoading={isDeleting}
      />
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>
              <Flex align="center" gap="xs">
                <Flex align="center" gap={10}>
                  <IconClock />
                  <Text fw={700} size="xl">
                    Upcoming Time Off
                  </Text>
                </Flex>
              </Flex>
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {_.map(upcomingTimeOff, (timeOffInterval) => {
            const { start_date: startDate, end_date: endDate } =
              timeOffInterval?.interval ?? {};
            const formattedDate = formatTimeOffUpcomingDate(startDate, endDate);

            const totalHours = timeOffInterval?.interval?.records?.reduce(
              (prev, cur) => prev + +cur.hours,
              0,
            );

            const icon = getUpcomingIcon(
              timeOffInterval?.interval?.policy_name,
            );

            const menuItems = [
              {
                label: 'Cancel',
                icon: IconTrash,
                onClick: () =>
                  openCancelConfirmation({
                    ...timeOffInterval,
                    startDate,
                    endDate,
                    formattedDate,
                  }),
              },
            ];

            return (
              <Table.Tr key={timeOffInterval?.interval?.start_date}>
                <Table.Th>
                  <Flex h={50} align="center" justify="space-between" gap={5}>
                    <Flex gap={5}>
                      {icon}
                      <Stack gap={0}>
                        <Text fw={600}>{formattedDate}</Text>
                        <Flex gap={5} align="center" color="green">
                          <IconCircleCheck color="white" fill="green" />
                          {totalHours} hours of{' '}
                          {timeOffInterval?.interval?.policy_name}
                        </Flex>
                      </Stack>
                    </Flex>
                    {isDeleting ? (
                      <Loader isLoading />
                    ) : (
                      <ContextMenu menuItems={menuItems} />
                    )}
                  </Flex>
                </Table.Th>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
    </Stack>
  );
}

UpcomingTimeOffTable.propTypes = {
  upcomingTimeOff: PropTypes.array,
};

UpcomingTimeOffTable.defaultProps = {
  upcomingTimeOff: [],
};
