import { useAddJob } from 'api/hooks';
import { queryKeys } from 'api/keys';
import { JobForm } from 'components/JobForm';
import { useGlobalTabsContext } from 'globalTabs';
import { useNotifications } from 'notifications/hooks/useNotifications';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { tabNames } from 'utils/constants';
import { usePageTitle, useTabNavigate } from 'utils/hooks';
import { PageWrapper } from '../../components/PageWrapper';

export default function CreateJob({ globalTabName }) {
  const navigate = useTabNavigate();
  const { setNotifications } = useNotifications();

  const queryClient = useQueryClient();
  const { onRemoveTab } = useGlobalTabsContext();

  const { addJob, isLoading } = useAddJob({
    onSuccess: () => {
      setNotifications(['The job has been successfully created']);
      queryClient.invalidateQueries([queryKeys.jobs()[0]]);
      navigate(`/hiring`, { tabName: tabNames.hiring });
      onRemoveTab(globalTabName);
    },
  });

  const submitForm = (values) => {
    addJob({
      title: values.title,
      status: values.status,
      type: values.type,
      experience: values.experience,
      compensation: values.compensation,
      location: values.location,
      description: values.description,
    });
  };

  usePageTitle('Create Job - Hiring', globalTabName);

  return (
    <PageWrapper title="Create Job">
      <JobForm onSubmit={submitForm} isSubmitLoading={isLoading} />
    </PageWrapper>
  );
}

CreateJob.propTypes = {
  globalTabName: PropTypes.string,
};

CreateJob.defaultProps = {
  globalTabName: '',
};
