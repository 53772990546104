import { Table } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { ContextMenu } from 'components/ContextMenu';
import PropTypes from 'prop-types';

export default function GenericRow({
  data,
  onEditRecord,
  onDeleteRecord,
  withEmployeeCount,
}) {
  const menuItems = [
    {
      label: 'Edit',
      icon: IconEdit,
      onClick: () => {
        onEditRecord(data);
      },
      condition: !!onEditRecord,
    },
    {
      label: 'Delete',
      icon: IconTrash,
      onClick: () => {
        onDeleteRecord(data);
      },
      condition: !data.employees_count && !!onDeleteRecord,
    },
  ];

  return (
    <Table.Tr>
      <Table.Td>{data?.name}</Table.Td>
      {withEmployeeCount && <Table.Td>{data?.employees_count}</Table.Td>}
      <Table.Td ta="right">
        <ContextMenu menuItems={menuItems} />
      </Table.Td>
    </Table.Tr>
  );
}

GenericRow.propTypes = {
  data: PropTypes.object.isRequired,
  onEditRecord: PropTypes.func,
  onDeleteRecord: PropTypes.func,
  withEmployeeCount: PropTypes.bool,
};

GenericRow.defaultProps = {
  withEmployeeCount: false,
  onEditRecord: undefined,
  onDeleteRecord: undefined,
};
