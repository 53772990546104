import { useForm } from '@mantine/form';
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Flex, Space, TextInput } from '@mantine/core';
import { genericValidators } from 'utils';

export default function CreateSourceForm({ onSubmit, isLoading }) {
  const form = useForm({
    initialValues: {
      name: '',
    },
    validate: {
      name: (value) =>
        genericValidators.notEmpty(value, 'Source name must not be empty'),
    },
  });
  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <TextInput
        label="Source name"
        placeholder="name"
        {...form.getInputProps('name')}
      />
      <Space h="10px" />
      <Flex justify="flex-end">
        <Button disabled={isLoading} type="submit">
          Create
        </Button>
      </Flex>
    </form>
  );
}

CreateSourceForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

CreateSourceForm.defaultProps = {
  isLoading: false,
};
