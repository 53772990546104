import { Button, Flex, Stack, Table } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { clearCache } from 'api/clearCache';
import {
  useCreateEmployeeCompensation,
  useDeleteEmployeeCompensation,
  useLoadEmployeeCompensationRecords,
  useLoadLookups,
  useLoadPaySchedule,
  useLoadReasons,
  useUpdateEmployeeCompensation,
} from 'api/hooks';
import { CompensationRecordModal } from 'components/CompensationRecordModal';
import { ConfirmDeleteModal } from 'components/ConfirmDeleteModal';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import { Skeleton } from 'components/Skeleton';
import _ from 'lodash';
import { useNotifications } from 'notifications/hooks/useNotifications';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { lookupsModels } from 'utils/constants';
import { useStateDisclosure } from 'utils/hooks';
import { CompensationRecordRow } from '../tabs-rows';

const headers = [
  'Effective Date',
  'Pay Schedule',
  'Pay Type',
  'Pay Rate',
  'Change Reason',
  'Comment',
];

export default function CompensationRecords({
  employeeId,
  compensationReasonType,
  isLookupsLoading,
}) {
  const queryClient = useQueryClient();
  const [
    openedCompensationRecord,
    {
      open: openCompensationRecord,
      close: closeCompensationRecord,
      state: compensationRecordState,
    },
  ] = useStateDisclosure(false);

  const { setNotifications } = useNotifications();

  const {
    employeeCompensationRecords,
    isLoading: isCompensationRecordsLoading,
  } = useLoadEmployeeCompensationRecords(
    { employeeId },
    { enabled: !!employeeId },
  );

  const { reasons, isLoading: isReasonsLoading } = useLoadReasons(
    {
      params: { filter: { byType: compensationReasonType } },
    },
    { enabled: !!compensationReasonType },
  );

  const { lookups } = useLoadLookups({
    models: [lookupsModels.compensationPaySchedule, lookupsModels.compensation],
  });

  const [
    openedDeleteCompensation,
    {
      open: openDeleteCompensation,
      close: closeDeleteCompensation,
      state: deleteCompensationState,
    },
  ] = useStateDisclosure(false);

  const { paySchedules, isLoading: isPaySchedulesLoading } =
    useLoadPaySchedule();

  const { createEmployeeCompensation, isLoading: isCreatingCompensation } =
    useCreateEmployeeCompensation({
      onSuccess: () => {
        clearCache.onChangeCompensationRecord(
          queryClient,
          employeeId,
          compensationReasonType,
        );
        setNotifications(['Employee Compensation Created Successfully']);
        closeCompensationRecord();
      },
    });

  const { updateEmployeeCompensation, isLoading: isUpdatingCompensation } =
    useUpdateEmployeeCompensation({
      onSuccess: () => {
        clearCache.onChangeCompensationRecord(
          queryClient,
          employeeId,
          compensationReasonType,
        );
        setNotifications(['Employee Compensation Updated Successfully']);
        closeCompensationRecord();
      },
    });

  const { deleteEmployeeCompensation, isLoading: isDeletingCompensation } =
    useDeleteEmployeeCompensation({
      onSuccess: () => {
        clearCache.onChangeCompensationRecord(
          queryClient,
          employeeId,
          compensationReasonType,
        );
        setNotifications(['Employee Compensation Deleted Successfully']);
        closeDeleteCompensation();
      },
    });

  return (
    <Stack gap={0} mt={10}>
      <CompensationRecordModal
        opened={openedCompensationRecord}
        onClose={closeCompensationRecord}
        onEdit={(data) =>
          updateEmployeeCompensation({
            ...data,
            compensationId: compensationRecordState?.id,
            employee_id: employeeId,
          })
        }
        onCreate={(data) =>
          createEmployeeCompensation({ ...data, employee_id: employeeId })
        }
        compensationRecordState={compensationRecordState}
        isLoading={
          isPaySchedulesLoading ||
          isReasonsLoading ||
          isCreatingCompensation ||
          isUpdatingCompensation
        }
        schedules={paySchedules}
        metaData={lookups}
        changeReasons={reasons}
      />

      <ConfirmDeleteModal
        opened={openedDeleteCompensation}
        onClose={closeDeleteCompensation}
        onDelete={() => deleteEmployeeCompensation(deleteCompensationState?.id)}
        isLoading={isDeletingCompensation}
      />
      <Flex justify="space-between" align="center">
        <h2 style={{ margin: '0px' }}>Compensation</h2>
        <Button
          rightSection={<IconPlus size={14} />}
          onClick={() => openCompensationRecord()}
        >
          Add entry
        </Button>
      </Flex>

      <Skeleton
        count={4}
        isVisible={isCompensationRecordsLoading || isLookupsLoading}
        skeletonWrapperProps={{ my: 10 }}
      />

      <EmptyDataMessage
        isVisible={
          !isCompensationRecordsLoading &&
          !isLookupsLoading &&
          _.isEmpty(employeeCompensationRecords)
        }
        my={20}
      />

      {!_.isEmpty(employeeCompensationRecords) && (
        <Table>
          <Table.Thead>
            <Table.Tr>
              {_.map(headers, (item) => (
                <Table.Th key={item}>{item}</Table.Th>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {_.map(employeeCompensationRecords, (employeeCompensation) => (
              <CompensationRecordRow
                key={employeeCompensation?.id}
                compensationRecord={employeeCompensation}
                onEdit={(data) =>
                  openCompensationRecord({ modalType: 'Edit', ...data })
                }
                onDelete={openDeleteCompensation}
              />
            ))}
          </Table.Tbody>
        </Table>
      )}
    </Stack>
  );
}

CompensationRecords.propTypes = {
  employeeId: PropTypes.string.isRequired,
  compensationReasonType: PropTypes.string,
  isLookupsLoading: PropTypes.bool.isRequired,
};

CompensationRecords.defaultProps = {
  compensationReasonType: '',
};
