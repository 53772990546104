import { Modal } from '@mantine/core';
import PropTypes from 'prop-types';
import { CreateFolderForm } from 'components/CreateFolderForm';

export default function CreateFolderModal({
  isLoading,
  opened,
  onClose,
  onSubmit,
}) {
  return (
    <Modal
      zIndex={1001}
      opened={opened}
      onClose={onClose}
      title="Create Folder"
      centered
    >
      <CreateFolderForm onSubmit={onSubmit} isLoading={isLoading} />
    </Modal>
  );
}

CreateFolderModal.propTypes = {
  isLoading: PropTypes.bool,
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

CreateFolderModal.defaultProps = {
  isLoading: false,
  opened: false,
  onClose: () => {},
  onSubmit: () => {},
};
