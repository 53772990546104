import { Container, LoadingOverlay } from '@mantine/core';

import { useNavigate } from 'react-router-dom';
import { PageWrapper } from 'components/PageWrapper';
import PropTypes from 'prop-types';

import { SurveyEditForm } from 'components/Survey';
import { useSurvey, useUpdateSurvey } from 'api/hooks';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { useQueryClient } from 'react-query';
import { queryKeys } from 'api/keys';
import { usePageTitle } from 'utils/hooks';

export default function SurveyEdit({ params, globalTabName }) {
  const { surveyId } = params;
  const { setNotifications } = useNotifications();

  const queryClient = useQueryClient();

  const {
    survey,
    isLoading,
    remove: clearSurveyCache,
  } = useSurvey({ id: surveyId, canManageEmployee: true });

  const navigate = useNavigate();

  const { updateSurvey, isLoading: isSurveyUpdating } = useUpdateSurvey({
    onSuccess: () => {
      setNotifications(['Survey successfully updated']);
      clearSurveyCache();
      queryClient.invalidateQueries(queryKeys.surveys([]));
      navigate('/settings/surveys');
    },
  });

  const onSurveySubmit = (values) => {
    updateSurvey({ id: survey.id, payload: values });
  };

  usePageTitle('Edit Survey - Settings', globalTabName);

  return (
    <PageWrapper title="Edit Survey">
      <LoadingOverlay
        visible={isLoading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <Container px={0} size="md">
        {!isLoading && (
          <SurveyEditForm
            survey={survey}
            onSubmit={onSurveySubmit}
            isLoading={isSurveyUpdating}
          />
        )}
      </Container>
    </PageWrapper>
  );
}

SurveyEdit.propTypes = {
  params: PropTypes.object,
  globalTabName: PropTypes.string,
};

SurveyEdit.defaultProps = {
  params: {},
  globalTabName: '',
};
