import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { extractWithVal, formatUserFilterPayload } from 'utils';
import { amApi } from '../api';
import { queryKeys } from '../keys';

export const useEmployees = (
  { canManageEmployee, page, search, status, params },
  options,
) => {
  const { data, ...rest } = useQuery(
    queryKeys.employees(canManageEmployee, page, search, status, params),
    async () =>
      canManageEmployee
        ? amApi.getManageEmployees({
            ...formatUserFilterPayload({ status, search, page }),
            ...params,
          })
        : amApi.getViewEmployee({
            ...formatUserFilterPayload({ status, search, page }),
            ...params,
          }),
    {
      ...options,
    },
  );

  return {
    employees: data?.data ?? [],
    pagination: data?.pagination ?? null,
    ...rest,
  };
};

export const useEmployee = ({ canManageEmployee, id }, options) => {
  const { data, ...rest } = useQuery(
    canManageEmployee ? queryKeys.employee(id) : queryKeys.employeeProfile(id),
    canManageEmployee
      ? async () => amApi.getEmployee({ userId: id })
      : async () => amApi.getEmployeeProfile({ userId: id }),
    {
      ...options,
    },
  );
  return { profile: data, ...rest };
};

export const useJobs = ({ page, search }, options) => {
  const { data, ...rest } = useQuery(
    queryKeys.jobs(page, search),
    async () => amApi.getJobs(formatUserFilterPayload({ search, page })),
    {
      ...options,
    },
  );
  return {
    jobs: data?.data ?? [],
    pagination: data?.pagination ?? null,
    ...rest,
  };
};

export const useJob = ({ jobId, ...params }, options) => {
  const { data, ...rest } = useQuery(
    queryKeys.job(jobId, params),
    async () => amApi.getJob(jobId, params),
    {
      ...options,
    },
  );
  return {
    jobDetails: data?.job ?? null,
    candidates: data?.candidates?.data,
    pagination: data?.candidates?.pagination ?? {},
    ...rest,
  };
};

export const useCandidates = ({ page, search }, options) => {
  const { data, ...rest } = useQuery(
    queryKeys.candidates(page, search),
    async () => amApi.getCandidates(formatUserFilterPayload({ page, search })),
    {
      ...options,
    },
  );
  return { candidates: data?.data, pagination: data?.pagination, ...rest };
};

export const useCandidate = (id, options) => {
  const { data, ...rest } = useQuery(
    queryKeys.candidate(id),
    async () => amApi.getCandidate({ candidateId: id }),
    {
      ...options,
    },
  );
  return { candidateDetails: data, ...rest };
};

export const useTalentGroups = (options) => {
  const { data, ...rest } = useQuery(
    queryKeys.talentGroups(),
    async () => amApi.getTalentGroups(),
    {
      ...options,
    },
  );
  return { talentGroups: data, ...rest };
};

export const useTalentGroup = (id, options) => {
  const { data, ...rest } = useQuery(
    queryKeys.talentGroup(id),
    async () => amApi.getTalentGroup({ talentGroupId: id }),
    {
      ...options,
    },
  );
  return { talentGroupDetails: data, ...rest };
};

export const useRequests = ({ canManageEmployee, ...params }, options) => {
  const { data, ...rest } = useQuery(
    queryKeys.requests(
      params ? Object.values(params) : ['all'],
      canManageEmployee,
    ),
    canManageEmployee
      ? async () => amApi.getManageRequests(params)
      : async () => amApi.getEmployeeRequests(extractWithVal(params)),
    {
      ...options,
    },
  );
  return {
    requests: data?.data ?? [],
    pagination: data?.pagination ?? {},
    ...rest,
  };
};

export const useRequest = (id, options) => {
  const { data: details, ...rest } = useQuery(
    queryKeys.request(id),
    async () => amApi.getRequest({ requestId: id }),
    {
      ...options,
    },
  );
  return { details, ...rest };
};

export const useCurrentUser = (options) => {
  const { data: user, ...rest } = useQuery(
    queryKeys.user(),
    async () => amApi.getUser(),
    {
      ...options,
    },
  );
  return { user, ...rest };
};

export const useEmployeeSchema = ({ canManageEmployee }, options) => {
  const { data: schema, ...rest } = useQuery(
    canManageEmployee
      ? queryKeys.employeeSchema()
      : queryKeys.employeeProfileSchema(),
    canManageEmployee
      ? async () => amApi.getEmployeeSchema()
      : async () => amApi.getProfileEmployeeSchema(),
    {
      ...options,
    },
  );
  return { schema, ...rest };
};

export const useNotes = ({ contentId, contentType }, options) => {
  const { data, ...rest } = useQuery(
    queryKeys.notes(),
    async () =>
      amApi.getNotes({ content_id: contentId, content_type: contentType }),
    {
      ...options,
    },
  );
  return { notes: data ?? [], ...rest };
};

export const useLoadFileBlob = (fileId, options) => {
  const { data, ...rest } = useQuery(
    queryKeys.fileBlob(fileId),
    async () => amApi.getFileBlob(fileId),
    {
      ...options,
    },
  );
  return { file: data?.data ?? null, ...rest };
};

export const useFiles = (
  { canManageEmployee, contentId, contentType, parentId, userId, ...params },
  options,
) => {
  const { data, ...rest } = useQuery(
    queryKeys.files(
      contentId,
      contentType,
      parentId,
      userId,
      canManageEmployee,
      params,
    ),
    async () =>
      canManageEmployee
        ? amApi.getManageFiles({
            content_id: contentId,
            content_type: contentType,
            parent_id: parentId,
            user_id: userId,
            ...params,
          })
        : amApi.getEmployeeFiles({
            content_id: contentId,
            content_type: contentType,
            parent_id: parentId,
            user_id: userId,
          }),
    {
      ...options,
    },
  );

  return { files: data ?? [], ...rest };
};

export const usePushNotifications = (
  { status, userId, ...payload },
  options,
) => {
  const { data, ...rest } = useQuery(
    queryKeys.notifications(status, payload),
    async () => amApi.getNotifications(userId, { status, ...payload }),
    {
      ...options,
    },
  );
  const queryClient = useQueryClient();

  const invalidatedNotificationsList = useCallback(() => {
    queryClient.invalidateQueries('push_notifications');
  }, [queryClient]);

  const notifications = (data?.data ?? [])?.sort((a, b) => a.status - b.status);
  if (typeof status === 'number') {
    return {
      notifications: notifications?.filter((item) => item.status === status),
      invalidatedNotificationsList,
      ...rest,
    };
  }
  return {
    notifications,
    invalidatedNotificationsList,
    pagination: data?.pagination,
    ...rest,
  };
};

export const useTrainings = (options) => {
  const { data, ...rest } = useQuery(
    queryKeys.trainings(),
    async () => amApi.getTrainings(),
    {
      ...options,
    },
  );
  return { trainings: data ?? [], ...rest };
};

export const useTrainingsCategories = (options, payload) => {
  const { data, ...rest } = useQuery(
    queryKeys.trainingsCategories(payload),
    async () => amApi.getTrainingCategories(payload),
    {
      ...options,
    },
  );
  return { categories: data ?? [], ...rest };
};

export const useTimeOffPolicies = (
  { employeeId, canManageEmployee },
  options,
) => {
  const { data, ...rest } = useQuery(
    queryKeys.timeOffPolicies(canManageEmployee, employeeId),
    async () => amApi.getTimeOffPolicies({ employeeId, canManageEmployee }),
    {
      ...options,
    },
  );

  return {
    policies: (employeeId ? data : data?.data) ?? [],
    policyTypes: data?.types ?? {},
    ...rest,
  };
};

export const useTimeOffCategories = ({ canManageEmployee }, options) => {
  const { data, ...rest } = useQuery(
    queryKeys.timeOffCategories(canManageEmployee),
    canManageEmployee
      ? async () => amApi.getManageTimeOffCategories()
      : async () => amApi.getProfileTimeOffCategories(),
    {
      ...options,
    },
  );
  return { categories: data ?? [], ...rest };
};

export const useTimeOffRecords = (
  { canManageEmployee, ...payload },
  options,
) => {
  const { data, ...rest } = useQuery(
    queryKeys.timeOffRecords(canManageEmployee, payload),
    canManageEmployee
      ? async () => amApi.getManageTimeOffRecords({ ...payload })
      : async () => amApi.getProfileTimeOffRecords({ ...payload }),
    {
      ...options,
    },
  );
  return { records: data ?? [], ...rest };
};

export const useUserPolicy = (userId, options) => {
  const { data, ...rest } = useQuery(
    queryKeys.userPolicy(userId),
    async () => amApi.getUserPolicy(),
    {
      ...options,
    },
  );
  return { policies: data ?? [], ...rest };
};

export const useEmployeePolicyList = (employeeId, options) => {
  const { data, ...rest } = useQuery(
    queryKeys.employeePolicyList(employeeId),
    async () => amApi.getEmployeePolicyList({ employeeId }),
    {
      ...options,
    },
  );
  return { policies: data ?? [], ...rest };
};

export const usePerformanceCycles = (options) => {
  const { data, ...rest } = useQuery(
    queryKeys.performanceCycles(),
    async () => amApi.getPerformanceCycles(),
    {
      ...options,
    },
  );
  return { performanceCycles: data ?? [], ...rest };
};

export const usePerformanceCycle = (id, options) => {
  const { data, ...rest } = useQuery(
    queryKeys.performanceCycle(id),
    async () => amApi.getPerformanceCycle(id),
    {
      ...options,
    },
  );
  return { cycle: data, ...rest };
};

export const useSurveys = (params, options) => {
  const { data, ...rest } = useQuery(
    queryKeys.surveys(params ? Object.values(params) : ['all']),
    async () => amApi.getSurveys(params),
    {
      ...options,
    },
  );
  return { surveys: data?.data ?? [], ...rest };
};

export const useSurvey = ({ id, canManageEmployee }, options) => {
  const { data, ...rest } = useQuery(
    queryKeys.survey(id),
    async () =>
      canManageEmployee ? amApi.getSurvey(id) : amApi.getEmployeeSurvey(id),
    {
      ...options,
    },
  );
  return { survey: data, ...rest };
};

export const useUsers = ({ page, search }, options) => {
  const { data, ...rest } = useQuery(
    queryKeys.users(page, search),
    async () => amApi.getUsers(formatUserFilterPayload({ search, page })),
    {
      ...options,
    },
  );

  return {
    users: data?.data ?? [],
    pagination: data?.pagination ?? null,
    ...rest,
  };
};

export const useUserTrainings = ({ userId, ...params }, options) => {
  const { data, ...rest } = useQuery(
    queryKeys.userTrainings(userId),
    async () => amApi.getUserTrainings(userId, params),
    {
      ...options,
    },
  );

  return {
    trainings: data,
    ...rest,
  };
};

export const useUserRecords = ({ userId, ...params }, options) => {
  const { data, ...rest } = useQuery(
    queryKeys.userRecords(userId, params),
    async () => amApi.getUserRecords(userId, params),
    {
      ...options,
    },
  );

  return {
    records: data?.data ?? [],
    years: data?.years?.map(String) ?? [],
    ...rest,
  };
};

export const useLoadJobTitles = (options) => {
  const { data, ...rest } = useQuery(
    queryKeys.jobTitles(),
    async () => amApi.getJobTitles(),
    { ...options },
  );

  return {
    jobTitles: data ?? [],
    ...rest,
  };
};

export const useLoadDepartments = (options) => {
  const { data, ...rest } = useQuery(
    queryKeys.departments(),
    async () => amApi.getDepartments(),
    { ...options },
  );

  return {
    departments: data ?? [],
    ...rest,
  };
};

export const useLoadLocations = (options) => {
  const { data, ...rest } = useQuery(
    queryKeys.locations(),
    async () => amApi.getLocations(),
    { ...options },
  );

  return {
    locations: data ?? [],
    ...rest,
  };
};

export const useLoadDivisions = (options) => {
  const { data, ...rest } = useQuery(
    queryKeys.divisions(),
    async () => amApi.getDivisions(),
    { ...options },
  );

  return {
    divisions: data ?? [],
    ...rest,
  };
};

export const useLoadJobInformationList = (id, options) => {
  const { data: jobInformationList, ...rest } = useQuery(
    queryKeys.jobInformationList(id),
    async () => amApi.getJobInformationList(id),
    { ...options },
  );

  return {
    jobInformationList: jobInformationList ?? [],
    ...rest,
  };
};

export const useLoadTaskList = (params, options) => {
  const { data: list, ...rest } = useQuery(
    queryKeys.tasksList(params),
    async () => amApi.getTasksList(params),
    { ...options },
  );

  return {
    list: list?.data ?? [],
    ...rest,
  };
};

export const useLoadTasks = (params, options) => {
  const { data: taskList, ...rest } = useQuery(
    queryKeys.tasks(params),
    async () => amApi.getTasks(params),
    { ...options },
  );

  return {
    tasksList: taskList?.data ?? [],
    ...rest,
  };
};

export const useLoadEmployeeBoardingList = (
  { userId, ...payload },
  options,
) => {
  const { data: onboardingList, ...rest } = useQuery(
    queryKeys.employeeBoardingList(userId, payload),
    async () => amApi.getEmployeeBoardingList(userId, payload),
    { ...options },
  );

  return {
    onboardingList: onboardingList?.data ?? [],
    employeeHireDate: onboardingList?.employee_hire_date ?? undefined,
    ...rest,
  };
};

export const useCandidateSources = (payload, options) => {
  const { data: candidateSource, ...rest } = useQuery(
    queryKeys.candidateSource(),
    async () => amApi.getCandidateSource(payload),
    { ...options },
  );

  return {
    candidateSource: candidateSource ?? [],
    ...rest,
  };
};

export const useCandidateStatuses = (payload, options) => {
  const { data: candidateStatuses, ...rest } = useQuery(
    queryKeys.candidateStatuses(),
    async () => amApi.getCandidateStatuses(payload),
    { ...options },
  );

  return {
    candidateStatuses: candidateStatuses?.data ?? [],
    types: candidateStatuses?.types,
    ...rest,
  };
};

export const useLoadEmailTemplates = (options) => {
  const { data: emailTemplates, ...rest } = useQuery(
    queryKeys.emailTemplates(),
    async () => amApi.getEmailTemplates(),
    { ...options },
  );

  return {
    emailTemplates: emailTemplates ?? [],
    ...rest,
  };
};

export const useLoadOfferTemplates = (options) => {
  const { data: offerTemplates, ...rest } = useQuery(
    queryKeys.offerTemplates(),
    async () => amApi.getOfferTemplates(),
    { ...options },
  );

  return {
    offerTemplates: offerTemplates ?? [],
    ...rest,
  };
};

export const useLoadCandidateQuestions = ({ jobId, candidateId }, options) => {
  const { data: candidateQuestions, ...rest } = useQuery(
    queryKeys.candidateQuestions(jobId, candidateId),
    async () => amApi.getCandidateQuestions(),
    { ...options },
  );

  return {
    candidateQuestions: candidateQuestions ?? [],
    ...rest,
  };
};

export const useLoadCandidateOffer = (hash, options) => {
  const { data: candidateOffer, ...rest } = useQuery(
    queryKeys.candidateOffer(hash),
    async () => amApi.getCandidateOffer(hash),
    { ...options },
  );

  return {
    candidateOffer: candidateOffer ?? {},
    ...rest,
  };
};

export const useLoadTemplateVariables = (options) => {
  const { data: templateVariables, ...rest } = useQuery(
    queryKeys.templateVariables(),
    async () => amApi.getTemplatesVariables(),
    { ...options },
  );

  return {
    templateVariables: templateVariables ?? {},
    ...rest,
  };
};

export const useLoadRequest = ({ id, canManageEmployee }, options) => {
  const { data: request, ...rest } = useQuery(
    canManageEmployee
      ? queryKeys.manageRequest(id)
      : queryKeys.employeeRequest(id),
    async () =>
      canManageEmployee
        ? amApi.getManageRequest(id)
        : amApi.getEmployeeRequest(id),
    { ...options },
  );

  return {
    request: request ?? {},
    ...rest,
  };
};

export const useLoadDashboardInfo = (options) => {
  const { data: dashboardInfo, ...rest } = useQuery(
    queryKeys.dashboardInfo(),
    async () => amApi.getDashboardInfo(),
    { ...options },
  );

  return {
    dashboardInfo: dashboardInfo ?? {},
    ...rest,
  };
};

export const useLoadLookups = (params, options) => {
  const { data: lookups, ...rest } = useQuery(
    queryKeys.lookups(params),
    async () => amApi.getLookups(params),
    { ...options },
  );

  return {
    lookups: lookups ?? {},
    ...rest,
  };
};

export const useLoadHeadCountsStats = (params, options) => {
  const { data: headCountStats, ...rest } = useQuery(
    queryKeys.headCountReportStats(params),
    async () => amApi.getHeadCountReportStats(params),
    { ...options },
  );

  return {
    headCountStats: headCountStats ?? [],
    ...rest,
  };
};

export const useLoadHeadCountList = (params, options) => {
  const { data: headCountList, ...rest } = useQuery(
    queryKeys.headCountList(params),
    async () => amApi.getHeadCountList(params),
    { ...options },
  );

  return {
    headCountList: headCountList?.data ?? [],
    pagination: headCountList?.pagination ?? null,
    ...rest,
  };
};

export const useLoadPolicy = (id, options) => {
  const { data: policy, ...rest } = useQuery(
    queryKeys.policy(id),
    async () => amApi.getPolicy(id),
    { ...options },
  );

  return {
    policy: policy ?? {},
    ...rest,
  };
};

export const useCheckIsValidPasswordResetToken = (token, options) => {
  const { ...rest } = useQuery(
    queryKeys.isValidResetPasswordToken(token),
    async () => amApi.checkIsValidPasswordResetToken(token),
    { ...options },
  );

  return {
    ...rest,
  };
};

export const useLoadEmployeeStatuses = (options) => {
  const { data: statuses, ...rest } = useQuery(
    queryKeys.employeeStatuses(),
    async () => amApi.getEmployeeStatuses(),
    { ...options },
  );

  return {
    statuses: statuses ?? [],
    ...rest,
  };
};

export const useLoadEmployeeStatusRecords = (employeeId, options) => {
  const { data: records, ...rest } = useQuery(
    queryKeys.employeeStatusRecords(employeeId),
    async () => amApi.getEmployeeStatusRecords(employeeId),
    { ...options },
  );

  return {
    records: records ?? [],
    ...rest,
  };
};

export const useLoadReasons = ({ params }, options) => {
  const { data: reasons, ...rest } = useQuery(
    queryKeys.reasons(params),
    async () => amApi.getReasons(params),
    { ...options },
  );

  return {
    reasons: reasons?.data ?? [],
    ...rest,
  };
};

export const useLoadPaySchedule = (options) => {
  const { data: paySchedules, ...rest } = useQuery(
    queryKeys.paySchedules(),
    async () => amApi.getPaySchedules(),
    { ...options },
  );

  return {
    paySchedules: paySchedules ?? [],
    ...rest,
  };
};

export const useLoadEmployeeCompensationRecords = ({ employeeId }, options) => {
  const { data: employeeCompensationRecords, ...rest } = useQuery(
    queryKeys.employeeCompensationRecords(employeeId),
    async () => amApi.getEmployeeCompensationRecords(employeeId),
    { ...options },
  );

  return {
    employeeCompensationRecords: employeeCompensationRecords ?? [],
    ...rest,
  };
};

export const useLoadEmployeeOrgTree = (options) => {
  const { data: employeeOrgTree, ...rest } = useQuery(
    queryKeys.employeeOrgTree(),
    async () => amApi.getEmployeeOrgTree(),
    { ...options },
  );

  return {
    employeeOrgTree: employeeOrgTree ?? [],
    ...rest,
  };
};

export const useLoadEmployeeBonusRecords = ({ employeeId }, options) => {
  const { data: employeeBonusRecords, ...rest } = useQuery(
    queryKeys.employeeBonusRecords(employeeId),
    async () => amApi.getEmployeeBonusRecords(employeeId),
    { ...options },
  );

  return {
    employeeBonusRecords: employeeBonusRecords ?? [],
    ...rest,
  };
};

export const useLoadEmployeeAssessmentSurveys = (
  { employeeId, ...payload },
  options,
) => {
  const { data: employeeAssessmentSurveys, ...rest } = useQuery(
    queryKeys.employeeAssessmentSurveys(employeeId, payload),
    async () => amApi.getEmployeeAssessmentSurveys(employeeId, payload),
    { ...options },
  );

  return {
    employeeAssessmentSurveys: employeeAssessmentSurveys ?? {},
    ...rest,
  };
};

export const useLoadTotalEmployeesByJobInfoFilter = (filters, options) => {
  const { data, ...rest } = useQuery(
    queryKeys.totalEmployeesByJobInfoFilter(filters),
    async () => amApi.getTotalEmployeesByJobInfoFilter(filters),
    { ...options },
  );

  return {
    employeeCount: data?.count ?? 0,
    ...rest,
  };
};

export const useLoadAssetCategories = (options) => {
  const { data: assetCategories, ...rest } = useQuery(
    queryKeys.assetCategories(),
    async () => amApi.getAssetCategories(),
    { ...options },
  );

  return {
    assetCategories: assetCategories?.data ?? [],
    ...rest,
  };
};

export const useLoadEmployeeAssets = (employeeId, options) => {
  const { data: assets, ...rest } = useQuery(
    queryKeys.employeeAssets(employeeId),
    async () => amApi.getEmployeeAssets(employeeId),
    { ...options },
  );

  return {
    assets: assets ?? [],
    ...rest,
  };
};

export const useLoadEmployeeSubordinates = (employeeId, options) => {
  const { data: subordinates, ...rest } = useQuery(
    queryKeys.employeeSubordinates(employeeId),
    async () => amApi.getEmployeeSubordinates(employeeId),
    { ...options },
  );

  return {
    subordinates: subordinates?.children ?? [],
    ...rest,
  };
};

export const useCheckIsValidUserProviderToken = (provider, options) => {
  const data = useQuery(
    queryKeys.userTokenProvider(provider),
    async () => amApi.checkIsValidUserProviderToken(provider),
    { ...options },
  );

  return {
    ...data,
  };
};

export const useLoadCompanyIntegrations = (options, companyId = 1) => {
  const { data: companyIntegrations, ...rest } = useQuery(
    queryKeys.companyActiveIntegrations(companyId),
    async () => amApi.getCompanyActiveIntegrations(companyId),
    { ...options },
  );

  return {
    companyIntegrations: companyIntegrations?.data ?? [],
    ...rest,
  };
};

export const useLoadIntegrations = (options) => {
  const { data: integrations, ...rest } = useQuery(
    queryKeys.integrations(),
    async () => amApi.getIntegrations(),
    { ...options },
  );

  return {
    integrations: integrations ?? [],
    ...rest,
  };
};

export const useLoadBuildVersion = (options) => {
  const { data: buildVersion, ...rest } = useQuery(
    queryKeys.buildVersion(),
    async () => amApi.getBuildVersion(),
    { ...options },
  );

  return {
    buildVersion: buildVersion ?? '',
    ...rest,
  };
};

export const useLoadRoles = (payload, options) => {
  const { data: roles, ...rest } = useQuery(
    queryKeys.allRoles(payload),
    async () => amApi.getRoles(payload),
    { ...options },
  );

  return {
    roles: roles ?? [],
    ...rest,
  };
};

export const useLoadCompensationGroups = (payload, options) => {
  const { data: compensationGroups, ...rest } = useQuery(
    queryKeys.compensationGroups(),
    async () => amApi.getCompensationGroups(payload),
    { ...options },
  );

  return {
    compensationGroups: compensationGroups ?? [],
    ...rest,
  };
};

export const useLoadCalendarData = (payload, options) => {
  const { data: calendarData, ...rest } = useQuery(
    queryKeys.calendarData(payload),
    async () => amApi.getCalendarData(payload),
    { ...options },
  );

  return {
    calendarData: calendarData ?? [],
    ...rest,
  };
};

export const useLoadCalendarTypes = (options) => {
  const { data: eventTypes, ...rest } = useQuery(
    queryKeys.eventTypes(),
    async () => amApi.getCalendarEventTypes(),
    { ...options },
  );

  return {
    eventTypes: eventTypes ?? [],
    ...rest,
  };
};

export const useLoadTimeOffBalance = (payload, options) => {
  const { data: timeOffBalance, ...rest } = useQuery(
    queryKeys.timeOffBalance(payload),
    async () => amApi.getTimeOffBalance(payload),
    { ...options },
  );

  return {
    timeOffBalance: timeOffBalance ?? [],
    ...rest,
  };
};

export const useLoadHolidays = (payload, options) => {
  const { data: holidays, ...rest } = useQuery(
    queryKeys.holidays(payload),
    async () => amApi.getHolidays(payload),
    { ...options },
  );

  return {
    holidays: holidays?.data ?? [],
    canCopyHolidays: holidays?.canCopy ?? false,
    ...rest,
  };
};

export const useLoadWidgets = (options) => {
  const { data: widgets, ...rest } = useQuery(
    queryKeys.widgets(),
    async () => amApi.getWidgets(),
    { ...options },
  );

  return {
    widgets: widgets ?? [],
    ...rest,
  };
};

export const useLoadEmployeeHolidays = (payload, options) => {
  const { data: holidays, ...rest } = useQuery(
    queryKeys.employeeHolidays(payload),
    async () => amApi.getEmployeeHolidays(payload),
    { ...options },
  );

  return {
    holidays: holidays ?? [],
    ...rest,
  };
};

export const useLoadCandidateOffers = (payload, options) => {
  const { data: candidateOffers, ...rest } = useQuery(
    queryKeys.candidatesOffers(payload),
    async () => amApi.getCandidatesOffers(payload),
    { ...options },
  );

  return {
    candidateOffers: candidateOffers ?? [],
    ...rest,
  };
};

export const useLoadCompanyLinks = (options) => {
  const { data: companyLinks, ...rest } = useQuery(
    queryKeys.companyLinks(),
    async () => amApi.getCompanyLinks(),
    { ...options },
  );

  return {
    companyLinks: companyLinks?.data ?? [],
    ...rest,
  };
};

export const useLoadCompanyLinksCategories = (options) => {
  const { data: companyLinksCategories, ...rest } = useQuery(
    queryKeys.companyLinksCategories(),
    async () => amApi.getCompanyLinksCategories(),
    { ...options },
  );

  return {
    companyLinksCategories: companyLinksCategories ?? [],
    ...rest,
  };
};
