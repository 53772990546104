import { Avatar, Flex, Pagination, Stack, Text } from '@mantine/core';
import { IconUsersGroup } from '@tabler/icons-react';
import { useEmployees } from 'api/hooks';
import { useAuthContext } from 'auth';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import { usePermissions } from 'components/PermissionGuard/hooks';
import _ from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { employeeStatuses, tabNames } from 'utils/constants';
import { usePagination, useTabNavigate } from 'utils/hooks';
import { WidgetWrapper } from '.';
import styles from '../styles.module.css';

export default function WelcomeTo() {
  const { user } = useAuthContext();

  const { hasPermission, permissions } = usePermissions();
  const [formattedEmployee, setFormattedEmployee] = useState({});
  const [pagination, setPagination] = useState({});

  const navigate = useTabNavigate();

  const { page, setPage } = usePagination({ page: 1 });

  const { isLoading: isEmployeeLoading, isFetching: isFetchingEmployee } =
    useEmployees(
      {
        canManageEmployee: hasPermission(permissions.canManageEmployee),
        status: employeeStatuses.Active,
        params: {
          page,
          sortBy: '-hire_date',
        },
      },
      {
        onSettled: ({ data, pagination: p } = {}) => {
          if (_.isArray(data)) {
            setFormattedEmployee(
              _.groupBy(data, (employee) =>
                employee?.hire_date
                  ? moment(employee?.hire_date, 'YYYY-MM-DD').format(
                      'dddd, MMM DD',
                    )
                  : 'Empty Hire Date',
              ),
            );
          }

          if (p) setPagination(p);
        },
        onError: () => {
          setFormattedEmployee({});
          setPagination({});
        },
      },
    );

  return (
    <WidgetWrapper
      title={`Welcome to ${user?.company_name}`}
      wrapperProps={{ h: 'min-content', mih: 300, mah: 450 }}
      Icon={<IconUsersGroup size={30} color="white" />}
      isLoading={isEmployeeLoading || isFetchingEmployee}
    >
      <EmptyDataMessage
        isVisible={
          (!isEmployeeLoading || !isFetchingEmployee) &&
          _.isEmpty(formattedEmployee)
        }
        m="auto"
      />
      {!_.isEmpty(formattedEmployee) && (
        <Stack gap={10} w="100%">
          {_.keys(formattedEmployee).map((hireDateKey) => (
            <Stack gap={5} key={hireDateKey} pos="relative" w="100%">
              <Text
                fw={600}
                pos="sticky"
                left={0}
                top={0}
                bg="var(--mantine-color-body)"
                style={{ zIndex: 10 }}
              >
                Started {hireDateKey}
              </Text>
              {formattedEmployee[hireDateKey]?.map((item) => (
                <div className={styles.recordHover} key={item?.id}>
                  <Flex
                    onClick={() =>
                      navigate(`/employees/${item?.id}`, {
                        tabName: tabNames.employeesProfile,
                      })
                    }
                    align="center"
                    w="100%"
                    p={5}
                  >
                    <Avatar size={60} src={item?.avatar_filename} />
                    <Stack
                      gap={5}
                      flex={1}
                      h="100%"
                      justify="center"
                      w="100%"
                      px={5}
                    >
                      <Text lh={1} fw={500}>
                        {item?.first_name} {item?.last_name}
                      </Text>
                      {item?.jobTitle && (
                        <Text fz={14} lh={1}>
                          {item?.jobTitle}
                        </Text>
                      )}
                      {item?.current_job_information?.location?.name && (
                        <Text fz={14} lh={1}>
                          {item?.current_job_information?.location?.name}
                        </Text>
                      )}
                    </Stack>
                  </Flex>
                </div>
              ))}
            </Stack>
          ))}
          {pagination?.total_pages > 1 && (
            <Flex justify="flex-end" mt={10}>
              <Pagination
                total={pagination?.total_pages}
                value={page}
                onChange={setPage}
              />
            </Flex>
          )}
        </Stack>
      )}
    </WidgetWrapper>
  );
}
