import { Divider, Flex, Paper, Stack, Text } from '@mantine/core';
import React from 'react';
import _ from 'lodash';
import { IconPencil } from '@tabler/icons-react';
import { useLoadTemplateVariables } from 'api/hooks';
import { Loader } from 'components/Loader';

import TemplateVariableItem from './TemplateVariableItem';

export default function TemplateVariableList() {
  const { templateVariables, isLoading: isTemplatesLoading } =
    useLoadTemplateVariables();

  return (
    <Paper
      p="md"
      w="35%"
      h="100%"
      bg="white"
      top={0}
      right="-36%"
      pos="absolute"
      style={{ overflow: 'auto' }}
    >
      <Flex align="center" gap={5}>
        <Text>Placeholders</Text>
        <IconPencil color="var(--mantine-color-gray-6)" />
      </Flex>

      <Divider />
      <Loader mt="5" isLoading={isTemplatesLoading} />

      <Stack mt="5" gap={2}>
        {_.keys(templateVariables).map((key) => (
          <TemplateVariableItem
            key={key}
            templateList={templateVariables}
            item={key}
          />
        ))}
      </Stack>
    </Paper>
  );
}
