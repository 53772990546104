import { Divider, Modal, Space } from '@mantine/core';
import { AddEmployeeBonusForm } from 'components/AddEmployeeBonusForm';
import { UserMainInfoPreviewCard } from 'components/UserMainInfoPreviewCard';
import PropTypes from 'prop-types';

export default function AddEmployeeBonusModal({
  opened,
  onClose,
  onEdit,
  onCreate,
  isLoading,
  employeeBonusState,
  userProfile,
  employeeBonusReasons,
}) {
  const isEdit = employeeBonusState?.modalType === 'Edit';

  return (
    <Modal
      title={`${isEdit ? 'Edit' : 'Create'} employee bonus`}
      centered
      opened={opened}
      onClose={onClose}
    >
      <UserMainInfoPreviewCard userInfo={userProfile} />
      <Divider />
      <Space h="md" />
      <AddEmployeeBonusForm
        onSubmit={isEdit ? onEdit : onCreate}
        isLoading={!opened || isLoading}
        employeeBonusState={employeeBonusState}
        employeeBonusReasons={employeeBonusReasons}
      />
    </Modal>
  );
}

AddEmployeeBonusModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  employeeBonusState: PropTypes.object,
  isLoading: PropTypes.bool,
  userProfile: PropTypes.object,
  employeeBonusReasons: PropTypes.array,
};

AddEmployeeBonusModal.defaultProps = {
  employeeBonusState: {},
  isLoading: false,
  userProfile: {},
  employeeBonusReasons: [],
};
