import { ScrollArea, SimpleGrid, Flex, Text } from '@mantine/core';
import { EmployeesGridItem } from 'components/EmployeesGridItem';
import PropTypes from 'prop-types';
import { Loader } from 'components/Loader';

export default function EmployeesGrid({ employees, isLoading }) {
  const rows = employees
    .slice(0, 10)
    .map((employee) => (
      <EmployeesGridItem key={employee.id} employee={employee} />
    ));

  return (
    <>
      {isLoading && (
        <Flex h="100%" w="100%" align="center" justify="center">
          <Loader isLoading={isLoading} />
        </Flex>
      )}
      <ScrollArea>
        <SimpleGrid cols={4}>{rows}</SimpleGrid>
        {!isLoading && employees?.length === 0 && (
          <Flex align="center" justify="center">
            <Text fw={500} size="xl" c="dimmed">
              There are no employees
            </Text>
          </Flex>
        )}
      </ScrollArea>
    </>
  );
}

EmployeesGrid.propTypes = {
  employees: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
