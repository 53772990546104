import { Modal } from '@mantine/core';
import PropTypes from 'prop-types';
import { TrainingForm } from 'components/TrainingForm';

export default function CreateTrainingModal({
  isLoading,
  opened,
  onClose,
  onSubmit,
  categories,
  training,
}) {
  return (
    <Modal
      opened={opened}
      onClose={() => {
        onClose();
      }}
      title="Create Training"
      centered
    >
      <TrainingForm
        training={training}
        isLoading={isLoading}
        onSubmit={onSubmit}
        categories={categories}
      />
    </Modal>
  );
}

CreateTrainingModal.propTypes = {
  isLoading: PropTypes.bool,
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  categories: PropTypes.array,
  training: PropTypes.object,
};

CreateTrainingModal.defaultProps = {
  isLoading: false,
  opened: false,
  onClose: () => {},
  onSubmit: () => {},
  categories: [],
  training: null,
};
