import { Button, Flex, Loader, Modal, Stack, Text, Title } from '@mantine/core';
import React from 'react';
import PropTypes from 'prop-types';
import { IconTrash } from '@tabler/icons-react';

export default function ConfirmDeleteModal({
  opened,
  onClose,
  onDelete,
  subText,
  deleteItemName,
  customText,
  isLoading,
  cancelButtonLabel,
  confirmButtonLabel,
  modalProps,
}) {
  return (
    <Modal centered opened={opened} onClose={onClose} {...modalProps}>
      <Stack gap={5} align="center">
        <IconTrash stroke={1.5} size={90} color="red" />
        <Title fw={500} fz={20} ta="center">
          {deleteItemName && !customText
            ? `Are you sure you want to delete the item ${deleteItemName} ?`
            : customText || 'Are you sure you want to delete this item ?'}
        </Title>
        {subText && (
          <Text c="gray" fz={15} ta="center">
            {subText}
          </Text>
        )}
        <Flex gap={10} ml="auto" mt="15">
          <Button onClick={onClose}>{cancelButtonLabel}</Button>
          <Button
            disabled={isLoading || !opened}
            onClick={onDelete}
            rightSection={
              isLoading && (
                <Loader color="var(--mantine-color-white)" size="xs" />
              )
            }
          >
            {confirmButtonLabel}
          </Button>
        </Flex>
      </Stack>
    </Modal>
  );
}

ConfirmDeleteModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  deleteItemName: PropTypes.string,
  customText: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  confirmButtonLabel: PropTypes.string,
  subText: PropTypes.string,
  modalProps: PropTypes.object,
  isLoading: PropTypes.bool,
};

ConfirmDeleteModal.defaultProps = {
  deleteItemName: '',
  customText: '',
  cancelButtonLabel: 'Cancel',
  confirmButtonLabel: 'Yes, Delete',
  isLoading: false,
  subText: '',
  modalProps: {},
};
