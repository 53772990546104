import { Modal, Stack, Text } from '@mantine/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';

export default function CompanyLinksModal({ opened, onClose, companyLinks }) {
  return (
    <Modal centered opened={opened} onClose={onClose} title="Company Link">
      {companyLinks?.map((category) => (
        <Stack gap={0} key={category?.id}>
          <Text fz="lg" fw="500">
            {category?.name}
          </Text>
          {category?.links?.map((link) => (
            <Link
              key={link?.id}
              to={link?.url}
              target={
                !link?.url?.startsWith?.(window.location.origin)
                  ? '_blank'
                  : undefined
              }
              className={styles.hoverOpacity}
              onClick={() => onClose()}
            >
              {link?.name}
            </Link>
          ))}
        </Stack>
      ))}
    </Modal>
  );
}

CompanyLinksModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  companyLinks: PropTypes.array.isRequired,
};
