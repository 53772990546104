import { Stack } from '@mantine/core';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Document, Page } from 'react-pdf';

export default function PDFPreview({ file, isLoading }) {
  const [numPages, setNumPages] = useState(null);

  if (isLoading || !file) return null;

  return (
    <Document
      file={file}
      onLoadSuccess={({ numPages: np }) => setNumPages(np)}
      loading={() => {}}
    >
      <Stack gap={10}>
        {Array.from({ length: numPages }, (item, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            customTextRenderer={false}
          />
        ))}
      </Stack>
    </Document>
  );
}

PDFPreview.propTypes = {
  file: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isLoading: PropTypes.bool.isRequired,
};

PDFPreview.defaultProps = {
  file: null,
};
