import { Flex, Stack, Text, Title } from '@mantine/core';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { EmployeeForContact } from 'components/EmployeeForContact';

export default function OfferDetails({ offer, candidateDetails }) {
  const offerDate = useMemo(() => {
    if (offer?.expiry_date) {
      const expiryDate = moment(offer?.expiry_date).format('MMMM D');
      const leftDays = moment(offer?.expiry_date).diff(moment(), 'days');

      return { expiryDate, leftDays, status: leftDays < 0 ? 'expired' : '' };
    }
    return {};
  }, [offer]);

  return (
    <Stack>
      <Stack gap={2}>
        <Title fz={15}>
          Offer Expires {offerDate.expiryDate} (
          {offerDate.status || offerDate.leftDays}
          {!offerDate.status ? ' days' : ''})
        </Title>
        <Text c="var(--mantine-color-gray-6)">
          {candidateDetails?.first_name} will still be able to view and sign the
          offer after the expiration date.
        </Text>
      </Stack>

      <Flex justify="space-between" gap={5}>
        <Stack>
          <OfferSection
            title="Job Info"
            items={[
              {
                label: 'Start Date',
                data: moment(offer?.start_date).format('DD/MM/YYYY'),
              },
              {
                label: 'Job Title',
                data: offer?.job_information?.name,
              },
            ]}
          />

          <OfferSection
            title="Compensation"
            items={[
              {
                label: 'Pay Rate',
                data: `${offer?.pay_rate ?? ''}$ per month`,
              },
              {
                label: 'Pay frequency',
                data: offer?.pay_frequency?.label || offer?.pay_frequency?.name,
              },
            ]}
          />
        </Stack>

        <Stack gap={8}>
          <Title fz={15}>Questions ?</Title>
          <EmployeeForContact employee={offer?.contact ?? {}} />
        </Stack>
      </Flex>
    </Stack>
  );
}

OfferDetails.propTypes = {
  candidateDetails: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
};

function OfferSection({ title, items }) {
  return (
    <Stack gap={2}>
      <Title fz={15}>{title}</Title>
      <Flex gap={20}>
        {_.map(items, (item) => (
          <Stack gap={2} key={item.label}>
            <Text c="var(--mantine-color-gray-6)">{item?.label}</Text>
            <Text>{item?.data}</Text>
          </Stack>
        ))}
      </Flex>
    </Stack>
  );
}

OfferSection.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};
