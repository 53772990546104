import { Table } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { ContextMenu } from 'components/ContextMenu';
import PropTypes from 'prop-types';

export default function BonusRecordRow({ bonusRecord, onEdit, onDelete }) {
  const menuItems = [
    {
      label: 'Edit',
      icon: IconEdit,
      onClick: () => {
        onEdit(bonusRecord);
      },
    },
    {
      label: 'Delete',
      icon: IconTrash,
      onClick: () => {
        onDelete(bonusRecord);
      },
    },
  ];

  return (
    <Table.Tr>
      <Table.Td>{bonusRecord?.date}</Table.Td>
      <Table.Td>{bonusRecord?.amount}</Table.Td>
      <Table.Td>{bonusRecord?.reason?.name}</Table.Td>
      <Table.Td>{bonusRecord?.comment}</Table.Td>
      <Table.Td ta="right">
        <ContextMenu menuItems={menuItems} />
      </Table.Td>
    </Table.Tr>
  );
}

BonusRecordRow.propTypes = {
  bonusRecord: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
