import {
  Avatar,
  Box,
  Group,
  Menu,
  Skeleton,
  Text,
  UnstyledButton,
  rem,
} from '@mantine/core';
import { IconChevronDown, IconLogout, IconSettings } from '@tabler/icons-react';
import { useAuthContext } from 'auth';
import cx from 'clsx';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { GlobalSearch } from 'components/GlobalSearch';
import NotificationsPopup from 'components/Notifications/NotificationsPopup';
import classes from './Navbar.module.css';

export default function Navbar() {
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const { user, isUserLoading, logout } = useAuthContext();

  return (
    <Box>
      <header className={classes.header}>
        <Group justify="space-between" h="100%">
          <Group visibleFrom="sm">
            <GlobalSearch />
            <NotificationsPopup />

            <Menu
              width={260}
              position="bottom-end"
              transitionProps={{ transition: 'pop-top-right' }}
              onClose={() => setUserMenuOpened(false)}
              onOpen={() => setUserMenuOpened(true)}
              withinPortal
              trigger="hover"
              data-testid="user-profile-dropdown"
            >
              <Menu.Target>
                <UnstyledButton
                  className={cx(classes.user, {
                    [classes.userActive]: userMenuOpened,
                  })}
                >
                  <Group gap={7}>
                    {isUserLoading && <Skeleton height={30} circle />}
                    {!isUserLoading && (
                      <>
                        <Avatar
                          src={user?.avatar_filename}
                          alt={`${user?.first_name} ${user?.last_name}`}
                          radius="xl"
                          size={30}
                        />
                        <Text fw={500} size="sm" lh={1} mr={3}>
                          {`${user?.first_name} ${user?.last_name}`}
                        </Text>
                      </>
                    )}

                    <IconChevronDown
                      style={{ width: rem(12), height: rem(12) }}
                      stroke={1.5}
                    />
                  </Group>
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Label>Settings</Menu.Label>
                <Menu.Item
                  component={Link}
                  to="/user/profile"
                  leftSection={
                    <IconSettings
                      style={{ width: rem(16), height: rem(16) }}
                      stroke={1.5}
                    />
                  }
                  data-testid="user-profile-dropdown-settings"
                >
                  Account settings
                </Menu.Item>

                <Menu.Item
                  leftSection={
                    <IconLogout
                      style={{ width: rem(16), height: rem(16) }}
                      stroke={1.5}
                    />
                  }
                  onClick={logout}
                  data-testid="user-profile-dropdown-logout"
                >
                  Logout
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Group>
      </header>
    </Box>
  );
}
