import React from 'react';
import PropTypes from 'prop-types';
import {
  IconArrowsDownUp,
  IconSortAscending,
  IconSortDescending,
} from '@tabler/icons-react';
import { Flex, Table } from '@mantine/core';

export const sortTypes = {
  asc: 'asc',
  desc: 'desc',
};

export default function SortTh({ sorted, setSorted, label, field }) {
  return (
    <Table.Th>
      <Flex w="max-content" align="center" gap={10}>
        {label}
        {sorted.field === field && sorted.sortBy === sortTypes.asc && (
          <IconSortAscending
            size={18}
            style={{ cursor: 'pointer' }}
            onClick={() => setSorted({ field, sortBy: '' })}
          />
        )}
        {sorted.field === field && sorted.sortBy === sortTypes.desc && (
          <IconSortDescending
            size={18}
            style={{ cursor: 'pointer' }}
            onClick={() => setSorted({ field, sortBy: sortTypes.asc })}
          />
        )}
        {(sorted.sortBy === '' || sorted.field !== field) && (
          <IconArrowsDownUp
            size={18}
            style={{ cursor: 'pointer' }}
            onClick={() => setSorted({ field, sortBy: sortTypes.desc })}
          />
        )}
      </Flex>
    </Table.Th>
  );
}

SortTh.propTypes = {
  sorted: PropTypes.object.isRequired,
  setSorted: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
};
