import { Modal } from '@mantine/core';
import PropTypes from 'prop-types';
import SurveyQuestionForm from '../forms/SurveyQuestionForm';

export default function SurveyQuestionModal({
  opened,
  onClose,
  onAddQuestion,
  question,
}) {
  return (
    <Modal
      opened={opened}
      onClose={() => {
        onClose();
      }}
      title="Add Question"
      centered
    >
      <SurveyQuestionForm question={question} onSubmit={onAddQuestion} />
    </Modal>
  );
}

SurveyQuestionModal.propTypes = {
  onClose: PropTypes.func,
  onAddQuestion: PropTypes.func,
  opened: PropTypes.bool,
  question: PropTypes.object,
};
SurveyQuestionModal.defaultProps = {
  onClose: () => {},
  onAddQuestion: () => {},
  opened: false,
  question: null,
};
