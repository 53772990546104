import { useForm } from '@mantine/form';
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Stack, Text } from '@mantine/core';
import { genericValidators } from 'utils';
import { Accrual, Flexible, Manually } from '../options';

export default function TimeOffPolicyConfigure({ isLoading, onSubmit }) {
  const form = useForm({
    initialValues: { option: '' },
    validate: {
      option: (val) =>
        genericValidators.notEmpty(val, 'Please select a policy'),
    },
  });

  return (
    <form noValidate onSubmit={form.onSubmit(onSubmit)}>
      <Stack>
        <Text>What kind of policy will this be?</Text>
        <Accrual
          onClick={() => form.setFieldValue('option', '0')}
          selected={form.values.option === '0'}
          error={!!form.errors.option}
        />
        <Flexible
          onClick={() => form.setFieldValue('option', '10')}
          selected={form.values.option === '10'}
          error={!!form.errors.option}
        />
        <Manually
          onClick={() => form.setFieldValue('option', '20')}
          selected={form.values.option === '20'}
          error={!!form.errors.option}
        />
        <Input.Error>{form.errors.option}</Input.Error>
        <Button disabled={isLoading} type="submit">
          Create
        </Button>
      </Stack>
    </form>
  );
}

TimeOffPolicyConfigure.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

TimeOffPolicyConfigure.defaultProps = {
  isLoading: false,
};
