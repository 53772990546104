import { Modal } from '@mantine/core';
import React from 'react';
import PropTypes from 'prop-types';
import { CreateSourceForm } from 'components/CreateSourceForm';

export default function CreateSourceModal({
  opened,
  onClose,
  isLoading,
  onSubmit,
}) {
  return (
    <Modal
      centered
      title="Create Candidate Source"
      opened={opened}
      onClose={onClose}
    >
      <CreateSourceForm isLoading={isLoading} onSubmit={onSubmit} />
    </Modal>
  );
}

CreateSourceModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

CreateSourceModal.defaultProps = {
  isLoading: false,
};
