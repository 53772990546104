import { useForm } from '@mantine/form';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Flex, Select, Stack, TextInput } from '@mantine/core';
import { genericValidators } from 'utils';
import _ from 'lodash';

export default function CreateCandidateStatusForm({
  onSubmit,
  isLoading,
  statusState,
  types,
}) {
  const defaultType = useMemo(() => _.first(types)?.value ?? '', [types]);

  const form = useForm({
    initialValues: {
      name: _.toString(statusState?.name),
      type: _.toString(statusState?.type) || defaultType,
    },
    validate: {
      name: (val) =>
        genericValidators.notEmpty(val, 'Name should not be empty'),

      type: (val) =>
        genericValidators.notEmpty(val, 'Type should not be empty'),
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)} noValidate>
      <Stack gap={10}>
        <TextInput
          required
          label="Status name"
          placeholder="name"
          {...form.getInputProps('name')}
        />
        <Select
          label="Status type"
          required
          allowDeselect={false}
          data={types}
          placeholder="Select type"
          {...form.getInputProps('type')}
        />
        <Flex justify="flex-end">
          <Button w="max-content" type="submit" disabled={isLoading}>
            Submit
          </Button>
        </Flex>
      </Stack>
    </form>
  );
}

CreateCandidateStatusForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  statusState: PropTypes.object,
  types: PropTypes.array,
};

CreateCandidateStatusForm.defaultProps = {
  isLoading: false,
  statusState: {},
  types: [],
};
