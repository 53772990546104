import { Button, Flex, Loader, Space, Table, Title } from '@mantine/core';
import { IconEdit, IconPlus, IconTrash } from '@tabler/icons-react';
import { clearCache } from 'api/clearCache';
import { useDeleteLocation, useLoadLocations } from 'api/hooks';
import { ContextMenu } from 'components/ContextMenu';
import { CreateLocationModal } from 'components/CreateLocationModal';
import { SettingsTableHead } from 'components/SettingsTable';
import _ from 'lodash';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { useQueryClient } from 'react-query';
import { useStateDisclosure } from 'utils/hooks';

const headerList = ['Location', 'Address', 'People', ''];

export default function Location() {
  const { locations, isLoading } = useLoadLocations();
  const { setNotifications } = useNotifications();

  const queryClient = useQueryClient();

  const [
    openedLocation,
    { open: openLocation, close: closeLocation, state: locationState },
  ] = useStateDisclosure(false);

  const { deleteLocation } = useDeleteLocation({
    onSuccess: () => {
      clearCache.onLocationChange(queryClient);
      setNotifications(['Location Deleted Successfully']);
    },
  });

  return (
    <>
      {openedLocation && (
        <CreateLocationModal
          opened={openedLocation}
          onClose={closeLocation}
          location={locationState}
        />
      )}
      <Flex gap={10} justify="space-between" align="center">
        <Title fz="xl">Location</Title>
        <Button onClick={openLocation} rightSection={<IconPlus size={14} />}>
          Add Location
        </Button>
      </Flex>
      {isLoading && (
        <Flex justify="center">
          <Loader />
        </Flex>
      )}

      <Space h="xs" />

      <Table>
        {!_.isEmpty(locations) && <SettingsTableHead headerList={headerList} />}
        <Table.Tbody>
          {locations?.map((location) => {
            const menuItems = [
              {
                label: 'Edit',
                icon: IconEdit,
                onClick: () => {
                  openLocation({ ...location, modalType: 'Edit' });
                },
              },
              {
                label: 'Delete',
                icon: IconTrash,
                onClick: () => {
                  deleteLocation(location?.id);
                },
                condition: !location.employees_count,
              },
            ];
            return (
              <Table.Tr key={location.id}>
                <Table.Td>{location.name}</Table.Td>
                <Table.Td>{location.address_line1}</Table.Td>
                <Table.Td>{location.employees_count ?? 0}</Table.Td>
                <Table.Td>
                  <Flex justify="end">
                    <ContextMenu menuItems={menuItems} />
                  </Flex>
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
    </>
  );
}
