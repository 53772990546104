import { Table } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { ContextMenu } from 'components/ContextMenu';
import PropTypes from 'prop-types';

export default function AssetRecordRow({ record, onEdit, onDelete }) {
  return (
    <Table.Tr>
      <Table.Td>{record?.category?.name}</Table.Td>
      <Table.Td>{record?.description}</Table.Td>
      <Table.Td>{record?.serial_number}</Table.Td>
      <Table.Td>{record?.assigned_at}</Table.Td>
      <Table.Td>{record?.returned_at}</Table.Td>
      <Table.Td ta="right">
        <ContextMenu
          menuItems={[
            {
              label: 'Edit',
              icon: IconEdit,
              onClick: () => {
                onEdit(record);
              },
            },
            {
              label: 'Delete',
              icon: IconTrash,
              onClick: () => {
                onDelete(record);
              },
            },
          ]}
        />
      </Table.Td>
    </Table.Tr>
  );
}

AssetRecordRow.propTypes = {
  record: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
