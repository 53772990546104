import { Fragment } from 'react';
import { Flex, Text, Table, Loader } from '@mantine/core';
import { IconCalendarEvent, IconTrash, IconReplace } from '@tabler/icons-react';

import PropTypes from 'prop-types';
import moment from 'moment';
import { ContextMenu } from 'components/ContextMenu';
import { usePermissions } from 'components/PermissionGuard/hooks';

export default function EmployeeTimeOffTable({
  categories,
  onDeletePolicy,
  onEditPolicy,
  onPolicyDateUpdateClick,
}) {
  return (
    <Table highlightOnHover>
      {categories
        .sort((a, b) => {
          if (a.id === 0) return -1;
          if (b.id === 0) return 1;
          return b.policies.length - a.policies.length;
        })
        .map((item) => (
          <Fragment key={item.name + item.id}>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>
                  <Flex align="center" gap="xs">
                    <Text fw={700} size="xl">
                      {item.name}
                    </Text>
                  </Flex>
                </Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Policy Name</Table.Th>
                <Table.Th>Created at</Table.Th>
                <Table.Th>Updated at</Table.Th>
                <Table.Th>Scheduled (Hours)</Table.Th>
                <Table.Th>Available (Hours)</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {item.policies.map((policy) => (
                <EmployeeTimeOffRow
                  key={policy.id}
                  record={policy}
                  onDeleteClick={onDeletePolicy}
                  onMoveClick={onEditPolicy}
                  onAdjustClick={onPolicyDateUpdateClick}
                />
              ))}
            </Table.Tbody>
          </Fragment>
        ))}
    </Table>
  );
}

function EmployeeTimeOffRow({
  record,
  isUpdating,
  onAdjustClick,
  onMoveClick,
  onDeleteClick,
}) {
  const { permissions, hasPermission } = usePermissions();
  const menuItems = [
    {
      label: 'Adjust Accrual Start Date',
      icon: IconCalendarEvent,
      onClick: () => {
        onAdjustClick(record);
      },
    },
    {
      label: 'Move To Another Policy',
      icon: IconReplace,
      onClick: () => {
        onMoveClick(record);
      },
    },
    {
      label: 'Remove Policy',
      icon: IconTrash,
      onClick: () => {
        onDeleteClick(record);
      },
    },
  ];
  return (
    <Table.Tr opacity={isUpdating ? 0.5 : 1}>
      <Table.Td>
        <Flex gap={4} align="center">
          {isUpdating && <Loader color="blue" size={15} />}
          {!isUpdating && hasPermission(permissions.canManageEmployee) && (
            <ContextMenu menuItems={menuItems} width={250} />
          )}
          {record.name}
        </Flex>
      </Table.Td>
      <Table.Td>
        {moment(record.created_at).format('DD/MM/YYYY, h:mm a')}
      </Table.Td>
      <Table.Td>
        {moment(record.updated_at).format('DD/MM/YYYY, h:mm a')}
      </Table.Td>
      <Table.Td>16</Table.Td>
      <Table.Td>18</Table.Td>
    </Table.Tr>
  );
}

EmployeeTimeOffRow.propTypes = {
  record: PropTypes.object.isRequired,
  isUpdating: PropTypes.bool,
  onAdjustClick: PropTypes.func,
  onMoveClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};
EmployeeTimeOffRow.defaultProps = {
  isUpdating: false,
  onAdjustClick: () => {},
  onMoveClick: () => {},
  onDeleteClick: () => {},
};

EmployeeTimeOffTable.propTypes = {
  categories: PropTypes.array,
  onDeletePolicy: PropTypes.func,
  onEditPolicy: PropTypes.func,
  onPolicyDateUpdateClick: PropTypes.func,
};
EmployeeTimeOffTable.defaultProps = {
  categories: [],
  onDeletePolicy: () => {},
  onEditPolicy: () => {},
  onPolicyDateUpdateClick: () => {},
};
