import { useToggle } from '@mantine/hooks';
import { useLoadCandidateOffer } from 'api/hooks';
import React from 'react';
import { useParams } from 'react-router-dom';
import { CandidateOfferLetter } from 'components/CandidateOfferLetter';
import { useSetPageTitle } from 'utils/hooks';

export default function CandidateOffer() {
  const { offerHash } = useParams();
  const [signStatus, toggleSignStatus] = useToggle([
    'pending',
    'signed',
    'error',
  ]);

  const { candidateOffer, isLoading: isOfferLoading } = useLoadCandidateOffer(
    offerHash,
    {
      onError: () => {
        toggleSignStatus('error');
      },
    },
  );

  useSetPageTitle('Candidate Offer');

  return (
    <CandidateOfferLetter
      signStatus={signStatus}
      candidateOffer={candidateOffer}
      isLoading={isOfferLoading}
      toggleSignStatus={toggleSignStatus}
    />
  );
}
