import { Modal } from '@mantine/core';
import React from 'react';
import PropTypes from 'prop-types';
import { CreateCandidateStatusForm } from 'components/CreateCandidateStatusForm';

export default function CreateCandidateStatusModal({
  opened,
  onClose,
  onSubmit,
  isLoading,
  statusState,
  types,
  onEdit,
}) {
  return (
    <Modal
      title={`${statusState?.modalType ?? 'Create'} Candidate Status`}
      centered
      opened={opened}
      onClose={onClose}
    >
      <CreateCandidateStatusForm
        statusState={statusState}
        isLoading={isLoading || !opened}
        onSubmit={statusState?.modalType === 'Edit' ? onEdit : onSubmit}
        types={types}
      />
    </Modal>
  );
}

CreateCandidateStatusModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  isLoading: PropTypes.bool,
  statusState: PropTypes.object,
  types: PropTypes.array,
};

CreateCandidateStatusModal.defaultProps = {
  isLoading: false,
  statusState: {},
  types: [],
  onEdit: () => {},
};
