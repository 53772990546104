import {
  Button,
  InputLabel,
  Radio,
  Space,
  Stack,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { DatePickerFormInput } from 'components/DatePicketFormInput';
import { EmployeesFilter } from 'components/EmployeesFilter';
import { useEmployeeFilter } from 'components/EmployeesFilter/hooks';
import {
  employeeTypes,
  filterOnlySelectedValues,
  markEmployeeFiltersAsSelected,
} from 'components/PerformanceManagement/forms/utils';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { datesValidators, genericValidators } from 'utils';

export default function AddEditHolidayForm({
  holidayState,
  onSubmit,
  isLoading,
}) {
  const form = useForm({
    initialValues: {
      name: holidayState?.name ?? '',
      startDate: holidayState?.date_from ?? '',
      endDate: holidayState?.date_to ?? '',
      employeeType: _.isEmpty(holidayState?.filters)
        ? employeeTypes.all
        : employeeTypes.someEmployee,
      filters: markEmployeeFiltersAsSelected(holidayState?.filters) ?? {},
    },
    validate: {
      name: (value) =>
        genericValidators.notEmpty(value, 'Holiday name is empty') ||
        genericValidators.lessThanOrEqualTo(
          value,
          50,
          'Holiday name must be less than 50 chars',
        ),
      filters: (val, { employeeType }) =>
        _.isEmpty(filterOnlySelectedValues(val)) &&
        employeeType === employeeTypes.someEmployee
          ? 'At least one must be selected'
          : null,
      startDate: (value) =>
        datesValidators.isValidDate(
          moment(value).format('YYYY-MM-DD'),
          'Start Date must be a valid date',
        ),
      endDate: (value) =>
        datesValidators.isValidDate(
          moment(value).format('YYYY-MM-DD'),
          'End Date must be a valid date',
        ),
    },
  });

  const { employeeFilters, onChangeEmployeeFilter, isLookupsLoading } =
    useEmployeeFilter({
      initialValues: form.values.filters,
    });

  return (
    <form noValidate onSubmit={form.onSubmit(onSubmit)}>
      <Stack>
        <TextInput
          label="Name"
          placeholder="Name"
          {...form.getInputProps('name')}
          required
        />
        <DatePickerFormInput
          required
          from={form.values.startDate}
          to={form.values.endDate}
          onChangeFrom={(val) => form.setFieldValue('startDate', val)}
          onChangeTo={(val) => form.setFieldValue('endDate', val)}
          minDate={
            (form.values.startDate && form.values.endDate) ||
            (!form.values.startDate && !form.values.endDate)
              ? undefined
              : moment(form.values.startDate, 'YYYY-MM-DD').toDate()
          }
          errors={form.errors.startDate ?? form.errors.endDate}
          onClear={() => {
            form.setFieldValue('startDate', null);
            form.setFieldValue('endDate', null);
          }}
          clearable
        />

        <Radio.Group
          {...form.getInputProps('employeeType')}
          error={form.errors.employeeType}
        >
          <InputLabel required>Who should be included?</InputLabel>
          <Stack gap={10}>
            <Radio value={employeeTypes.all} label="All employees" />
            <Radio value={employeeTypes.someEmployee} label="Some employee" />
          </Stack>
        </Radio.Group>

        {form.values.employeeType === employeeTypes.someEmployee && (
          <>
            <Space h="md" />
            <EmployeesFilter
              error={form.errors.filters}
              onSelectFilter={(data) =>
                onChangeEmployeeFilter(data, (values) =>
                  form.setFieldValue('filters', values),
                )
              }
              employeeFilters={employeeFilters}
              isLookupsLoading={isLookupsLoading}
              selectedValues={form.values.filters}
            />
          </>
        )}
        <Button ml="auto" type="submit" disabled={isLoading}>
          Submit
        </Button>
      </Stack>
    </form>
  );
}

AddEditHolidayForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  holidayState: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
};

AddEditHolidayForm.defaultProps = {
  holidayState: {},
};
