import { Table } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { ContextMenu } from 'components/ContextMenu';
import PropTypes from 'prop-types';

export default function CompensationReasonRow({ reason, onEdit, onDelete }) {
  const menuItems = [
    {
      label: 'Edit',
      icon: IconEdit,
      onClick: () => onEdit(reason),
    },
    {
      label: 'Delete',
      icon: IconTrash,
      onClick: () => onDelete(reason),
      condition: !reason?.employees_count,
    },
  ];
  return (
    <Table.Tr>
      <Table.Td>{reason?.name}</Table.Td>
      <Table.Td>{reason?.employees_count}</Table.Td>
      <Table.Td ta="right">
        <ContextMenu menuItems={menuItems} />
      </Table.Td>
    </Table.Tr>
  );
}

CompensationReasonRow.propTypes = {
  reason: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
