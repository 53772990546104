import { Flex, Pagination, Table } from '@mantine/core';
import { CompletedSurveyPreviewModal } from 'components/CompletedSurveyPreviewModal';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import { Skeleton } from 'components/Skeleton';
import _ from 'lodash';
import { AssessmentRaw } from 'modules/employee-profile/tabs/PerformanceTab/components';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useStateDisclosure } from 'utils/hooks';

export default function FeedbackTableView({
  isLoading,
  surveys,
  page,
  setPage,
  limit,
}) {
  const [
    openedSurveyPreview,
    {
      open: openSurveyPreview,
      close: closeSurveyPreview,
      state: surveyPreviewState,
    },
  ] = useStateDisclosure(false);

  const allSurveys = useMemo(() => {
    const responses = _.get(surveys, 'responses.data', []).map((response) => ({
      ...response,
      status: 'completed',
    }));

    const pendingSurveys = _.get(surveys, 'pending.data', []).map(
      (response) => ({ ...response, status: 'pending' }),
    );

    const result = pendingSurveys?.concat(responses);
    return limit ? _.take(result, limit) : result;
  }, [surveys, limit]);

  const pagination = useMemo(() => {
    const pendingPagination = _.get(surveys, 'pending.pagination');
    const responsesPagination = _.get(surveys, 'responses.pagination');

    return pendingPagination?.total_pages > responsesPagination?.total_pages
      ? pendingPagination
      : responsesPagination;
  }, [surveys]);

  return (
    <>
      <EmptyDataMessage
        isVisible={!isLoading && _.isEmpty(allSurveys)}
        message="No Feedback for this user yet"
        my={30}
      />

      <CompletedSurveyPreviewModal
        opened={openedSurveyPreview}
        onClose={closeSurveyPreview}
        responses={surveyPreviewState?.response_data ?? []}
      />

      <Skeleton
        skeletonWrapperProps={{ gap: 5 }}
        count={5}
        isVisible={isLoading}
      />

      {!isLoading && !_.isEmpty(allSurveys) && (
        <>
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Name</Table.Th>
                <Table.Th>Type</Table.Th>
                <Table.Th>Status</Table.Th>
                <Table.Th>Sent On</Table.Th>
                <Table.Th>Completed On</Table.Th>
                <Table.Th>View</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {_.map(allSurveys, (survey) => (
                <AssessmentRaw
                  survey={survey}
                  key={`${survey?.request_id}${survey?.id}`}
                  onClickPreview={() => openSurveyPreview(survey)}
                />
              ))}
            </Table.Tbody>
          </Table>
          {pagination?.total_pages > 1 && !limit && (
            <Flex justify="flex-end" my={10}>
              <Pagination
                total={pagination?.total_pages}
                value={page}
                onChange={setPage}
              />
            </Flex>
          )}
        </>
      )}
    </>
  );
}

FeedbackTableView.propTypes = {
  isLoading: PropTypes.bool,
  surveys: PropTypes.object,
  page: PropTypes.number,
  setPage: PropTypes.func,
  limit: PropTypes.number,
};

FeedbackTableView.defaultProps = {
  isLoading: false,
  surveys: {},
  page: undefined,
  setPage: () => {},
  limit: undefined,
};
