import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  useCreateEmployeeTask,
  useDeleteEmployeeTask,
  useLoadEmployeeBoardingList,
  useLoadTaskList,
  useMarkAsCompletedTask,
  useUpdateEmployeeTask,
} from 'api/hooks';
import _ from 'lodash';
import { Loader } from 'components/Loader';
import { ImportBoardingTemplate } from 'components/ImportBoardingTemplate';
import { EmployeeBoardingList } from 'components/EmployeeBoardingList';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { boardingListTypes } from 'utils/constants';

const titles = {
  [boardingListTypes.onboarding]: 'Onboarding',
  [boardingListTypes.offboarding]: 'Offboarding',
};

export default function EmployeeBoardingTab({ employeeId, type }) {
  const {
    onboardingList,
    employeeHireDate,
    isLoading: isOnboardingListLoading,
    isFetching: isOnboardingListFetching,
    refetch: refetchBoardingList,
  } = useLoadEmployeeBoardingList({
    userId: employeeId,
    'filter[task_type]': type,
  });

  const { setNotifications } = useNotifications();

  const { 0: uncompleted = [], 1: completed = [] } = useMemo(
    () => _.groupBy(onboardingList, 'status'),
    [onboardingList],
  );

  const { list } = useLoadTaskList({
    'filter[task_type]': type,
  });

  const { createEmployeeTask, isLoading: isCreatingEmployeeTask } =
    useCreateEmployeeTask({
      onSuccess: () => {
        refetchBoardingList();
        setNotifications(['Task Created Successfully']);
      },
    });

  const { updateEmployeeTask, isLoading: isUpdatingEmployeeTask } =
    useUpdateEmployeeTask({
      onSuccess: () => {
        refetchBoardingList();
        setNotifications(['Task Updated Successfully']);
      },
    });

  const { deleteEmployeeTask } = useDeleteEmployeeTask({
    onSuccess: () => {
      refetchBoardingList();
      setNotifications(['Task Deleted Successfully']);
    },
  });

  const {
    markAsCompletedTask,
    variables: passedCompleteVar,
    isLoading: isCompletingLoading,
  } = useMarkAsCompletedTask({
    onSuccess: () => {
      refetchBoardingList();
      setNotifications(['Task Completed Successfully']);
    },
  });

  const onSubmitTask = ({ modalType, ...rest }) => {
    const payload = {
      date_due: rest?.date_due,
      description: rest?.description,
      employee_id: employeeId,
      tasklist_name: rest?.tasklist_id,
      task_type: type,
      name: rest?.name,
    };

    if (modalType === 'Edit') {
      updateEmployeeTask({ ...payload, id: rest?.id });
    } else {
      createEmployeeTask({ ...payload });
    }
  };

  return (
    <>
      <Loader mt={10} isLoading={isOnboardingListLoading} />
      {_.isEmpty(onboardingList) && !isOnboardingListLoading && (
        <ImportBoardingTemplate
          title="There are no onboarding tasks for this employee."
          taskList={list}
          employeeId={employeeId}
          onImportEnded={refetchBoardingList}
          onSubmitTask={onSubmitTask}
          taskType={type}
          hireDate={employeeHireDate}
          isTaskListLoading={
            isCreatingEmployeeTask ||
            isUpdatingEmployeeTask ||
            isOnboardingListFetching
          }
        />
      )}
      {(!_.isEmpty(uncompleted) || !_.isEmpty(completed)) && (
        <EmployeeBoardingList
          tasksList={uncompleted}
          isCompletedOnboarding={false}
          boardingType={titles[type] ?? ''}
          onDeleteTask={({ id }) => deleteEmployeeTask(id)}
          markAsComplete={({ id }) => markAsCompletedTask({ id, status: 1 })}
          taskList={list}
          onSubmitTaskList={onSubmitTask}
          completeItemState={{
            isCompletingLoading:
              isCompletingLoading || isOnboardingListFetching,
            completeId: passedCompleteVar?.id,
          }}
          employeeHireDate={employeeHireDate}
          isTaskListLoading={isCreatingEmployeeTask || isUpdatingEmployeeTask}
        />
      )}

      {!_.isEmpty(completed) && (
        <EmployeeBoardingList
          tasksList={completed}
          isCompletedOnboarding
          boardingType="Completed"
          onDeleteTask={({ id }) => deleteEmployeeTask(id)}
          markAsComplete={({ id }) => markAsCompletedTask({ id, status: 0 })}
          onSubmitTaskList={onSubmitTask}
          taskList={list}
          completeItemState={{
            isCompletingLoading:
              isCompletingLoading || isOnboardingListFetching,
            completeId: passedCompleteVar?.id,
          }}
          isTaskListLoading={isCreatingEmployeeTask || isUpdatingEmployeeTask}
        />
      )}
    </>
  );
}

EmployeeBoardingTab.propTypes = {
  employeeId: PropTypes.string.isRequired,
  type: PropTypes.number.isRequired,
};
