import { Container } from '@mantine/core';
import { useCreateTimeOffPolicy } from 'api/hooks';
import { PageWrapper } from 'components/PageWrapper';
import TimeOffPolicyForm from 'components/TimeOff/forms/TimeOffPolicyForm';
import { useTimeOffGroupedPolicies } from 'components/TimeOff/hooks';
import { useNotifications } from 'notifications/hooks/useNotifications';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import { clearCache } from 'api/clearCache';
import _ from 'lodash';

export default function PolicyCreate({ params }) {
  const { policyType } = params;

  const { setNotifications } = useNotifications();

  const navigate = useNavigate();

  const { categories, policyTypes, isPoliciesLoading, isCategoriesLoading } =
    useTimeOffGroupedPolicies();

  const queryClient = useQueryClient();

  const { createPolicy, isLoading: isCreatingPolicy } = useCreateTimeOffPolicy({
    onSuccess: () => {
      clearCache.onTimeOffPolicyChange(queryClient);
      setNotifications(['Policy successfully created']);
      navigate('/settings/time-off');
    },
  });

  const currentPolicyType = useMemo(() => {
    if (_.isEmpty(policyTypes) || !policyType) {
      return '';
    }

    const type = _.keys(policyTypes).find(
      (key) => policyTypes[key] === policyType,
    );

    return type;
  }, [policyType, policyTypes]);

  const isLoading =
    isCategoriesLoading || isPoliciesLoading || isCreatingPolicy;

  return (
    <PageWrapper title="Create Time Off policy">
      <Container px={0} size="md">
        <TimeOffPolicyForm
          policy={{}}
          isLoading={isLoading}
          onSubmit={(values) =>
            createPolicy({ ...values, type: currentPolicyType })
          }
          categories={categories}
          selectedPolicyType={currentPolicyType}
        />
      </Container>
    </PageWrapper>
  );
}

PolicyCreate.propTypes = {
  params: PropTypes.object,
};

PolicyCreate.defaultProps = {
  params: {},
};
