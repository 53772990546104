import { Avatar, Flex, Popover, Stack, Text } from '@mantine/core';
import PropTypes from 'prop-types';
import DropdownCloseButton from '../DropdownCloseButton';

export default function AnniversaryDropdown({ event, onClickUser, onClose }) {
  return (
    <Popover.Dropdown
      p={0}
      styles={{
        arrow: {
          backgroundColor: 'var(--mantine-color-blue-4)',
        },
        dropdown: { border: 'none', borderRadius: '10px' },
      }}
    >
      <DropdownCloseButton onClose={onClose} />
      <Stack
        w="100%"
        bg="white"
        gap={0}
        style={{ zIndex: 2 }}
        styles={{ root: { borderRadius: '10px 10px 0px 0px' } }}
      >
        <Flex gap={10} m={10} align="center">
          <Avatar
            src={event?.employees?.avatar_filename}
            size={50}
            style={{ cursor: 'pointer' }}
            onClick={onClickUser}
          />
          <Stack gap={10}>
            <Text
              style={{ lineHeight: '0.5', cursor: 'pointer' }}
              onClick={onClickUser}
            >
              {event?.employees?.first_name} {event?.employees?.last_name}
            </Text>
            <Text c="gray" fw="bold" fz={13} style={{ lineHeight: '0.5' }}>
              {event?.employees?.jobTitle}
            </Text>
          </Stack>
        </Flex>
        <Flex
          justify="center"
          align="center"
          h={50}
          bg="var(--mantine-color-blue-4)"
          w="100%"
          c="white"
          fw="bold"
        >
          {event?.employees?.first_name}`s {event?.anniversary_years}-year
          Anniversary!
        </Flex>
      </Stack>
    </Popover.Dropdown>
  );
}

AnniversaryDropdown.propTypes = {
  event: PropTypes.object.isRequired,
  onClickUser: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
