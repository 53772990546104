import React from 'react';
import PropTypes from 'prop-types';
import { EmailTemplateForm } from 'components/EmailTemplateForm';
import { TemplateModal } from 'components/TemplateModal';

export default function CreateEmailTemplate({
  opened,
  onClose,
  onCreate,
  onEdit,
  emailState,
  isLoading,
}) {
  return (
    <TemplateModal
      opened={opened}
      onClose={onClose}
      title={`${emailState?.modalType ?? 'Create'} Email Template`}
    >
      <EmailTemplateForm
        emailState={emailState}
        onSubmit={emailState?.modalType === 'Edit' ? onEdit : onCreate}
        isLoading={isLoading || !opened}
      />
    </TemplateModal>
  );
}

CreateEmailTemplate.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  isLoading: PropTypes.bool,
  emailState: PropTypes.object,
};

CreateEmailTemplate.defaultProps = {
  emailState: {},
  onEdit: () => {},
  isLoading: false,
};
