import React from 'react';
import PropTypes from 'prop-types';
import AllRequests from '../components/AllRequests';

export default function SentRequestTab({
  requestType,
  requestId,
  isRequestLoading,
  onPreview,
}) {
  return (
    <AllRequests
      onPreview={onPreview}
      requestType={requestType}
      requestId={requestId}
      isRequestLoading={isRequestLoading}
      payload={{ sent: '[]' }}
    />
  );
}

SentRequestTab.propTypes = {
  onPreview: PropTypes.func.isRequired,
  requestType: PropTypes.string,
  requestId: PropTypes.string,
  isRequestLoading: PropTypes.bool,
};

SentRequestTab.defaultProps = {
  requestType: '',
  isRequestLoading: false,
  requestId: '',
};
