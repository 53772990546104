import { Button, Text, Space } from '@mantine/core';
import PropTypes from 'prop-types';
import { useForm } from '@mantine/form';
import { genericValidators } from 'utils';
import { DateFormInput } from 'components/DateFormInput';
import moment from 'moment';

export default function TimeOffPolicyDateForm({ policy, onSubmit, isLoading }) {
  const form = useForm({
    initialValues: {
      startDate: '',
    },
    validate: {
      startDate: (value) =>
        genericValidators.notEmpty(value, 'You select a date'),
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)} noValidate>
      <Space h="md" />

      <Text size="sm" c="dimmed">
        Current Policy
      </Text>
      <Text size="md">{policy?.name}</Text>
      <Space h="md" />

      <Text size="sm" c="dimmed">
        Current Start Date
      </Text>
      <Text size="md">
        {moment(policy?.created_at).format('DD/MM/YYYY, h:mm a')}
      </Text>
      <Space h="md" />

      <DateFormInput
        field={{ displayName: 'Update Accrual Date', placeholder: '2022-1-1' }}
        onChange={(val) => {
          form.setFieldValue('startDate', val);
        }}
        error={form.errors.startDate}
        value={form.values.startDate}
      />
      <Button type="submit" fullWidth mt="xl" loading={isLoading}>
        Submit
      </Button>
    </form>
  );
}

TimeOffPolicyDateForm.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  policy: PropTypes.object,
};

TimeOffPolicyDateForm.defaultProps = {
  onSubmit: () => {},
  isLoading: false,
  policy: null,
};
