import { Button, Select, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import PropTypes from 'prop-types';
import { formatSelectorDataObj, genericValidators } from 'utils';
import { surveyStatuses } from 'utils/constants';

export default function SurveyForm({ onSubmit, isLoading, surveyTypes }) {
  const form = useForm({
    initialValues: {
      name: '',
      type: '10',
      status: '0',
    },
    validate: {
      name: (value) =>
        genericValidators.notEmpty(value, 'Survey name is empty') ||
        genericValidators.lessThanOrEqualTo(
          value,
          50,
          'Survey name must be less than 50 chars',
        ),
    },
  });

  return (
    <form onSubmit={form.onSubmit(onSubmit)} noValidate>
      <TextInput
        label="Survey Name"
        placeholder="Name"
        onChange={(event) =>
          form.setFieldValue('name', event.currentTarget.value)
        }
        value={form.values.name}
        error={form.errors.name}
        required
      />
      <Select
        label="Type"
        placeholder="Pick Type"
        data={formatSelectorDataObj(surveyTypes)}
        onChange={(val) => form.setFieldValue('type', val)}
        value={form.values.type}
      />
      <Select
        label="Status"
        placeholder="Pick status"
        data={surveyStatuses}
        onChange={(val) => form.setFieldValue('status', val)}
        value={form.values.status}
        allowDeselect={false}
      />
      <Button type="submit" fullWidth mt="xl" loading={isLoading}>
        Submit
      </Button>
    </form>
  );
}

SurveyForm.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  surveyTypes: PropTypes.object,
};

SurveyForm.defaultProps = {
  onSubmit: () => {},
  isLoading: false,
  surveyTypes: {},
};
