import { Box, Table } from '@mantine/core';
import moment from 'moment';
import PropTypes from 'prop-types';

export default function AssessmentRaw({ survey, onClickPreview }) {
  const completedByName =
    survey?.status === 'pending'
      ? `${survey?.assigned_to?.first_name} ${survey?.assigned_to?.last_name}`
      : `${survey?.author_id?.first_name} ${survey?.author_id?.last_name}`;

  return (
    <Table.Tr>
      <Table.Td>{completedByName}</Table.Td>
      <Table.Td>{survey?.survey_type}</Table.Td>
      <Table.Td
        c={
          survey?.status === 'pending'
            ? 'var(--mantine-color-yellow-8)'
            : 'var(--mantine-color-green-5)'
        }
        style={{ textTransform: 'capitalize' }}
      >
        {survey?.status}
      </Table.Td>
      <Table.Td>{moment(survey?.sent_at).format('YYYY-MM-DD')}</Table.Td>
      <Table.Td>
        {survey?.completed_at
          ? moment(survey?.completed_at).format('YYYY-MM-DD')
          : ''}
      </Table.Td>
      <Table.Td>
        {survey?.status === 'completed' && (
          <Box
            c="var(--mantine-color-blue-6)"
            onClick={onClickPreview}
            style={{ cursor: 'pointer' }}
          >
            Preview
          </Box>
        )}
      </Table.Td>
    </Table.Tr>
  );
}

AssessmentRaw.propTypes = {
  survey: PropTypes.object.isRequired,
  onClickPreview: PropTypes.func,
};

AssessmentRaw.defaultProps = {
  onClickPreview: () => {},
};
