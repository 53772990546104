import { useEffect, useState } from 'react';
import moment from 'moment';
import { Table, Space, LoadingOverlay, Pagination, Flex } from '@mantine/core';
import { useJobs } from 'api/hooks';
import { usePagination } from 'utils/hooks';
import { UserFilter } from 'components/UsersFilter';
import { JobsTableRow } from '../tab-rows';

export default function JobOpenings() {
  const [search, setSearch] = useState('');

  const { page, setPage, validateCurrentPage } = usePagination({ page: 1 });

  const { jobs, isLoading, pagination } = useJobs({
    page,
    search,
  });

  useEffect(() => {
    validateCurrentPage(pagination?.total_pages);
  }, [pagination, validateCurrentPage]);

  return (
    <>
      <LoadingOverlay
        visible={isLoading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <Space h="md" />
      <UserFilter
        search={search}
        setSearch={setSearch}
        withStatusFilter={false}
      />
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Title</Table.Th>
            <Table.Th>Location</Table.Th>
            <Table.Th>Status</Table.Th>
            <Table.Th>Candidates</Table.Th>
            <Table.Th>Interviews</Table.Th>
            <Table.Th>Type</Table.Th>
            <Table.Th>Created at</Table.Th>
            <Table.Th>Updated at</Table.Th>
            <Table.Th>Actions</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {jobs
            ?.sort((a, b) => moment(b.created_at).diff(moment(a.created_at)))
            .map((item) => (
              <JobsTableRow key={item.id} item={item} />
            ))}
        </Table.Tbody>
      </Table>
      {pagination?.total_pages > 1 && (
        <Flex justify="flex-end" mt={10}>
          <Pagination
            total={pagination?.total_pages}
            value={page}
            onChange={setPage}
          />
        </Flex>
      )}
    </>
  );
}
