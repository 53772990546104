import { Button, Container, Flex, Modal, Text } from '@mantine/core';
import { CreateFolderModal } from 'components/CreateFolderModal';
import { FileManager } from 'components/FileManager';
import { UploadFileModal } from 'components/UploadFileModal';
import { useMemo, useState } from 'react';

import { useDisclosure } from '@mantine/hooks';
import {
  useCreateFile,
  useDeleteFile,
  useDownloadFile,
  useFiles,
} from 'api/hooks';
import { Header } from 'components/FileManager/FileManager';
import { PDFPreviewModal } from 'components/PDFPreviewModal';
import { usePermissions } from 'components/PermissionGuard/hooks';
import { useNotifications } from 'notifications/hooks/useNotifications';
import PropTypes from 'prop-types';
import { getFileExt } from 'utils';
import { useStateDisclosure } from 'utils/hooks';

export default function Files({
  contentType,
  contentId,
  fileActions,
  withPath,
  withCreateButtons,
  fileLoadParams,
}) {
  const [path, setPath] = useState([]);
  const [displayMode, setDisplayMode] = useState('grid');
  const [fileToDelete, setFileToDelete] = useState();

  const { setNotifications } = useNotifications();
  const [isModalOpened, { close: closeModal, open: openModal }] =
    useDisclosure(false);
  const [
    folderModalOpened,
    { open: openFolderModal, close: closeFolderModal },
  ] = useDisclosure(false);
  const [fileModalOpened, { open: openFileModal, close: closeFileModal }] =
    useDisclosure(false);

  const [
    openedPDFPreviewModal,
    {
      open: openPDFPreviewModal,
      close: closePDFPreviewModal,
      state: PDFPreviewState,
    },
  ] = useStateDisclosure(false);

  const { permissions, hasPermission } = usePermissions();

  const currentParentId = path?.[path.length - 1]?.id ?? null;
  const {
    files,
    isLoading,
    refetch: refetchFiles,
  } = useFiles({
    contentType,
    contentId,
    parentId: currentParentId,
    canManageEmployee: hasPermission(permissions.canManageEmployee),
    ...fileLoadParams,
  });

  const { createFile, isLoading: isCreatingFile } = useCreateFile({
    onSuccess: () => {
      setNotifications(['File Created Successfully']);
      closeFolderModal();
      closeFileModal();
      refetchFiles();
    },
    onError: () => {
      closeFileModal();
      closeFolderModal();
    },
  });

  const { deleteFile, isLoading: isDeletingFile } = useDeleteFile({
    onSuccess: () => {
      setNotifications(['File Deleted Successfully']);
      closeModal();
      refetchFiles();
    },
    onError: () => {
      closeModal();
    },
  });
  const { downloadFile } = useDownloadFile({
    onSuccess: () => {
      setNotifications(['File Downloaded Successfully']);
      closeModal();
      refetchFiles();
    },
    onError: () => {
      closeModal();
    },
  });

  const onDownloadClick = (item) => {
    downloadFile(item);
  };
  const onFileClick = (item) => {
    if (item?.type === 'd') {
      setPath((prev) => [...prev, item]);
    }
    if (
      item?.type === 'f' &&
      getFileExt(item?.name) === 'pdf' &&
      !item?.metadata?.is_protected
    ) {
      openPDFPreviewModal(item);
    }
  };

  const goBack = () => {
    setPath((prev) => (prev.length > 0 ? [...prev.slice(0, -1)] : []));
  };

  const onCreateFolderClick = () => {
    openFolderModal();
  };

  const onUploadFileClick = () => {
    openFileModal();
  };

  const onFolderCreateSubmit = (values) => {
    const payload = {
      ...values,
      content_id: contentId,
      content_type: contentType,
      parent_id: currentParentId,
      type: 'd',
    };
    createFile(payload);
  };

  const onFileUploadSubmit = (file) => {
    if (file) {
      const payload = {
        file,
        content_id: contentId,
        content_type: contentType,
        parent_id: currentParentId,
        type: 'f',
      };
      createFile(payload);
    }
  };

  const onFileDeleteClick = (file) => {
    openModal();
    setFileToDelete(file);
  };

  const onDeleteFile = () => {
    if (fileToDelete) {
      deleteFile(fileToDelete);
    }
  };
  const sorted = useMemo(
    () =>
      files?.sort((a, b) => {
        if (a.type < b.type) {
          return -1;
        }
        if (a.type > b.type) {
          return 1;
        }
        return 0;
      }),
    [files],
  );

  const onDisplayModeChange = (value) => {
    setDisplayMode(value);
  };

  return (
    <Container>
      <PDFPreviewModal
        fileId={PDFPreviewState?.id}
        opened={openedPDFPreviewModal}
        onClose={closePDFPreviewModal}
      />
      <CreateFolderModal
        opened={folderModalOpened}
        onClose={closeFolderModal}
        onSubmit={onFolderCreateSubmit}
        isLoading={isCreatingFile}
      />
      <UploadFileModal
        opened={fileModalOpened}
        onClose={closeFileModal}
        onSubmit={onFileUploadSubmit}
        isLoading={isCreatingFile}
      />
      <Header
        path={path}
        onBackClick={goBack}
        onCreateFolderClick={onCreateFolderClick}
        onUploadFileClick={onUploadFileClick}
        onDisplayModeChange={onDisplayModeChange}
        withPath={withPath}
        withCreateButtons={withCreateButtons}
      />
      <FileManager
        files={sorted}
        displayMode={displayMode}
        onClick={onFileClick}
        isLoading={isLoading}
        onDownloadClick={onDownloadClick}
        onDeleteClick={onFileDeleteClick}
        fileActions={fileActions}
      />
      <Modal
        opened={isModalOpened}
        onClose={() => {
          closeModal();
          setFileToDelete();
        }}
        size="auto"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        title="Warning"
      >
        <Text>Are you sure you want to delete this file?</Text>
        <Flex mt={20} justify="center" align="center" gap="xl">
          <Button
            onClick={onDeleteFile}
            loading={isDeletingFile}
            variant="filled"
            color="red"
          >
            Delete
          </Button>
          <Button
            onClick={() => {
              closeModal();
              setFileToDelete();
            }}
            disabled={isDeletingFile}
            variant="outline"
          >
            Cancel
          </Button>
        </Flex>
      </Modal>
    </Container>
  );
}

Files.propTypes = {
  contentType: PropTypes.string.isRequired,
  contentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,

  fileActions: PropTypes.array,
  withPath: PropTypes.bool,
  withCreateButtons: PropTypes.bool,
  fileLoadParams: PropTypes.object,
};

Files.defaultProps = {
  fileActions: [],
  withPath: true,
  withCreateButtons: true,
  fileLoadParams: {},
};
