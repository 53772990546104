import { Box, Container, Flex } from '@mantine/core';
import PropTypes from 'prop-types';

export default function PageWrapper({
  title,
  children,
  leftSection,
  rightSection,
  withFlexStyles,
  dataTestId,
}) {
  return (
    <Container
      className={
        withFlexStyles ? 'mainContainerFlexWrapper' : 'mainContainerWrapper'
      }
      fluid
      data-testid={dataTestId || `${title}-page-container`}
    >
      <Flex className="pageTitle" justify="space-between">
        <Box flex="1" py={5}>
          {title && !leftSection && (
            <h1 data-testid={`${title}-title`}>{title}</h1>
          )}
          {leftSection}
        </Box>
        {rightSection}
      </Flex>

      {children}
    </Container>
  );
}

PageWrapper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any.isRequired,
  rightSection: PropTypes.node,
  leftSection: PropTypes.node,
  withFlexStyles: PropTypes.bool,
  dataTestId: PropTypes.string,
};

PageWrapper.defaultProps = {
  title: '',
  dataTestId: '',
  rightSection: null,
  leftSection: null,
  withFlexStyles: false,
};
