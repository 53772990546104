import { Flex } from '@mantine/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import PropTypes from 'prop-types';

export default function TapYearPicker({
  onNextYear,
  onPrevYear,
  year,
  isNextDisabled,
}) {
  return (
    <Flex align="center" h="100%">
      <IconChevronLeft
        style={{ border: '1px solid var(--mantine-color-gray-4)' }}
        height="36"
        cursor="pointer"
        onClick={onPrevYear}
      />
      <Flex
        style={{
          borderTop: '1px solid var(--mantine-color-gray-4)',
          borderBottom: '1px solid var(--mantine-color-gray-4)',
        }}
        h="36"
        align="center"
        px={5}
      >
        {year}
      </Flex>
      <IconChevronRight
        style={{ border: '1px solid var(--mantine-color-gray-4)' }}
        height="36"
        cursor={isNextDisabled ? 'no-drop' : 'pointer'}
        color={isNextDisabled ? 'var(--mantine-color-gray-4)' : undefined}
        onClick={!isNextDisabled ? onNextYear : undefined}
      />
    </Flex>
  );
}

TapYearPicker.propTypes = {
  onNextYear: PropTypes.func.isRequired,
  onPrevYear: PropTypes.func.isRequired,
  year: PropTypes.number.isRequired,
  isNextDisabled: PropTypes.bool,
};

TapYearPicker.defaultProps = {
  isNextDisabled: false,
};
