import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Flex, Stack, Text, Title } from '@mantine/core';
import moment from 'moment';
import { useGetDateAgo } from 'utils/hooks';

export default function CandidateProfileInfo({ candidateDetails }) {
  const { months, formattedMonths, formattedDays } = useGetDateAgo({
    date: candidateDetails?.created_at,
  });

  return (
    <Flex align="center" gap={10}>
      <Avatar size={90} />
      <Stack justify="space-around" gap={5}>
        <Title>
          {candidateDetails?.first_name} {candidateDetails?.last_name}
        </Title>
        <Text>
          Applied on{' '}
          {candidateDetails?.created_at &&
            moment(candidateDetails?.created_at).format('MMMM DD, YYYY')}{' '}
          ({months ? formattedMonths : formattedDays})
        </Text>
      </Stack>
    </Flex>
  );
}

CandidateProfileInfo.propTypes = {
  candidateDetails: PropTypes.object,
};

CandidateProfileInfo.defaultProps = {
  candidateDetails: {},
};
