import { LoadingOverlay, Modal, Text, Button, Flex } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';
import {
  useCandidate,
  useUpdateCandidate,
  useDeleteCandidate,
} from 'api/hooks';
import { CandidateForm } from 'components/CandidateForm';
import { PageWrapper } from 'components/PageWrapper';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import { useGlobalTabsContext } from 'globalTabs';
import { clearCache } from 'api/clearCache';
import { getUserFullName } from 'utils';
import { usePageTitle } from 'utils/hooks';

export default function EditCandidate({ params, globalTabName }) {
  const { candidateId, jobId } = params;
  const { setNotifications } = useNotifications();
  const queryClient = useQueryClient();
  const { onRemoveTab } = useGlobalTabsContext();

  const [isModalOpened, { close: closeModal, open: openModal }] =
    useDisclosure(false);

  const navigate = useNavigate();
  const {
    candidateDetails,
    isLoading: isProfileLoading,
    error,
    isFetched,
  } = useCandidate(candidateId);

  const { deleteCandidate, isLoading: isDeleteLoading } = useDeleteCandidate({
    onSuccess: () => {
      setNotifications(['The candidate has been successfully deleted']);
      navigate(`/hiring/job/${jobId}`, { replace: true });
      clearCache.onCandidateChange(queryClient, { candidateId, jobId });
      onRemoveTab(globalTabName);
    },
  });

  const { updateCandidate, isLoading } = useUpdateCandidate({
    onSuccess: () => {
      clearCache.onCandidateChange(queryClient, { candidateId, jobId });
      setNotifications(['The candidate has been successfully updated']);
      navigate(`/hiring/job/${jobId}/candidate/${candidateId}`, {
        replace: true,
      });
      onRemoveTab(globalTabName);
    },
  });
  const submitForm = (values) => {
    updateCandidate({
      id: candidateId,
      payload: {
        ...values,
      },
    });
  };

  const onDeleteClick = () => {
    openModal();
  };
  const onDelete = () => {
    deleteCandidate({ id: candidateId });
  };

  usePageTitle(
    getUserFullName(candidateDetails)
      ? `Edit Candidate - ${getUserFullName(candidateDetails)} - Candidates`
      : 'Edit Candidate - Candidates',
    globalTabName,
  );

  return (
    <PageWrapper title="Edit Candidate">
      <LoadingOverlay
        visible={isProfileLoading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      {candidateDetails && (
        <CandidateForm
          jobId={jobId}
          onSubmit={submitForm}
          candidate={candidateDetails}
          onDelete={onDeleteClick}
          isSubmitLoading={isLoading}
          isDeleteLoading={isDeleteLoading}
          enableFetch={isFetched}
        />
      )}

      <Modal
        opened={isModalOpened}
        onClose={closeModal}
        size="auto"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        title="Warning"
      >
        <Text>Are you sure you want to delete this candidate?</Text>
        <Flex mt={20} justify="center" align="center" gap="xl">
          <Button onClick={onDelete} variant="filled" color="red">
            Delete
          </Button>
          <Button onClick={closeModal} variant="outline">
            Cancel
          </Button>
        </Flex>
      </Modal>
      {error && <h3 style={{ textAlign: 'center' }}>Candidate Not Found</h3>}
    </PageWrapper>
  );
}

EditCandidate.propTypes = {
  params: PropTypes.object,
  globalTabName: PropTypes.string,
};

EditCandidate.defaultProps = {
  params: {},
  globalTabName: '',
};
