import { Box } from '@mantine/core';
import Files from 'modules/files/Files';
import PropTypes from 'prop-types';

export default function AllFiles({ contentType, contentId, fileActions }) {
  return (
    <Box my={10}>
      <Files
        contentType={contentType}
        contentId={contentId}
        fileActions={fileActions}
      />
    </Box>
  );
}

AllFiles.propTypes = {
  contentType: PropTypes.string.isRequired,
  contentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,

  fileActions: PropTypes.array,
};

AllFiles.defaultProps = {
  fileActions: [],
};
