import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { Button, Flex, Grid, GridCol, Space } from '@mantine/core';
import { useDisclosure, useListState, useToggle } from '@mantine/hooks';
import { IconEdit, IconList, IconTrash } from '@tabler/icons-react';
import { clearCache } from 'api/clearCache';
import {
  useCreateLinkCategory,
  useDeleteCompanyLinkCategory,
  useLoadCompanyLinks,
  useReorderCompanyLinks,
  useUpdateCompanyLinkCategory,
} from 'api/hooks';
import { AddButton } from 'components/Buttons';
import { CompanyCategoriesModal } from 'components/CompanyCategoriesModal';
import { ConfirmDeleteModal } from 'components/ConfirmDeleteModal';
import { ContextMenu } from 'components/ContextMenu';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import { Loader } from 'components/Loader';
import { OneFieldModal } from 'components/OneFieldModal';
import { useDragAndDrop } from 'components/WidgetSettings/hooks';
import _ from 'lodash';
import { useNotifications } from 'notifications/hooks/useNotifications';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useStateDisclosure } from 'utils/hooks';
import CompanyLinkRow from './components/CompanyLinkRow';

const applyAllRows = (categories) =>
  categories?.reduce?.(
    (acc, curr) => ({
      ...acc,
      [curr?.id]: {
        ...curr,
        links: curr?.links?.sort((a, b) => a.order - b.order) ?? [],
      },
    }),
    {},
  );

const applyAsMap = (categories, state) =>
  categories?.reduce?.(
    (acc, curr) =>
      curr.id in state
        ? acc.set(curr.id, {
            ...curr,
            links: curr?.links?.sort((a, b) => a.order - b.order) ?? [],
          })
        : acc,
    new Map(),
  );

export default function CompanyLinksTab() {
  const { setNotifications } = useNotifications();
  const queryClient = useQueryClient();

  const [
    openedCreateCategory,
    {
      open: openCreateCategory,
      close: closeCreateCategory,
      state: createCategoryState,
    },
  ] = useStateDisclosure(false);

  const { createLinkCategory, isLoading: isCreatingLinkCategory } =
    useCreateLinkCategory({
      onSuccess: () => {
        setNotifications(['Link Category Created Successfully']);
        closeCreateCategory();
        clearCache.onChangeLinkCategory(queryClient);
      },
    });

  const { companyLinks, isLoading: isCompanyLinksLoading } =
    useLoadCompanyLinks();

  const { reorderCompanyLinks } = useReorderCompanyLinks({
    onSuccess: () => {
      clearCache.onChangeCompanyLink(queryClient);
    },
  });

  const { sliceRowsPosition, sliceRowPositionInSameCategory } =
    useDragAndDrop();

  const [state, { setState }] = useListState(companyLinks);
  const [listStatus, toggleListStatus] = useToggle([
    'initializing',
    'reorder',
    'hold',
  ]);

  useEffect(() => {
    if (!_.isEmpty(companyLinks)) {
      setState(applyAllRows(companyLinks));
    }
  }, [companyLinks, setState]);

  const reorderWithDroppableContext = (source, destination) => {
    const { index: from, droppableId: fromDroppableId } = source;
    const { index: to, droppableId: toDroppableId } = destination;

    setState((prev) => {
      // TODO REFACTOR
      const cloned = _.cloneDeep(prev);

      const fromItem = cloned[fromDroppableId]?.links?.[from];
      const toItem = cloned[toDroppableId]?.links?.[to];
      const isSameColumn = fromDroppableId === toDroppableId;

      if (!isSameColumn) {
        fromItem.column = +toDroppableId;

        const data = sliceRowsPosition({
          fromIndex: from,
          toIndex: to,
          fromItem,
          toItem,
          state,
          toColumn: +toDroppableId,
          fromColumn: +fromDroppableId,
          groupedData: cloned,
        });

        return data;
      }

      const data = sliceRowPositionInSameCategory({
        fromIndex: from,
        toIndex: to,
        fromItem,
        toItem,
        state,
        toColumn: +toDroppableId,
        fromColumn: +fromDroppableId,
        groupedData: cloned,
      });

      return data;
    });
  };

  const reorderList = (source, destination) => {
    if (!_.isNil(source?.index) && !_.isNil(destination?.index)) {
      toggleListStatus('reorder');
      reorderWithDroppableContext(source, destination);
    } else {
      toggleListStatus('hold');
    }
  };

  const onReorderList = useCallback(
    (data) => {
      const formattedData = _.keys(data).map((key) =>
        data[key]?.links?.map((link) => ({ id: link?.id, category_id: key })),
      );

      reorderCompanyLinks({ links: _.flatMap(formattedData) });
    },
    [reorderCompanyLinks],
  );

  useEffect(() => {
    if (listStatus === 'reorder') {
      onReorderList(state);
      toggleListStatus('hold');
    }
  }, [state, listStatus, onReorderList, toggleListStatus]);

  const [
    openedConfirmationModal,
    {
      open: openConfirmationModal,
      close: closeConfirmationModal,
      state: confirmationState,
    },
  ] = useStateDisclosure(false);

  const { deleteCompanyLinkCategory, isLoading: isDeletingCategory } =
    useDeleteCompanyLinkCategory({
      onSuccess: () => {
        closeConfirmationModal();
        setNotifications(['Category Link Deleted Successfully']);
        clearCache.onChangeLinkCategory(queryClient);
      },
    });

  const { updateCompanyLinkCategory, isLoading: isUpdatingCategory } =
    useUpdateCompanyLinkCategory({
      onSuccess: () => {
        closeCreateCategory();
        setNotifications(['Category Link Updated Successfully']);
        clearCache.onChangeLinkCategory(queryClient);
      },
    });

  const [
    openedCategoriesModal,
    { open: openCategoriesModal, close: closeCategoriesModal },
  ] = useDisclosure(false);

  const stateAsMap = useMemo(
    () =>
      applyAsMap(
        companyLinks?.sort((a, b) => a.order - b.order),
        state,
      )
        .keys()
        .toArray(),
    [companyLinks, state],
  );

  return (
    <>
      <Space h="md" />
      <Flex justify="flex-end" align="center" gap={10}>
        <Button onClick={openCategoriesModal}>Categories</Button>
        <AddButton onClick={openCreateCategory}>Create Category</AddButton>
      </Flex>
      <OneFieldModal
        opened={openedCreateCategory}
        onClose={closeCreateCategory}
        state={createCategoryState}
        onEdit={(data) =>
          updateCompanyLinkCategory({
            categoryId: createCategoryState?.id,
            ...data,
          })
        }
        onCreate={createLinkCategory}
        title="Category"
        formSettings={{
          label: 'Category Name',
          field: 'name',
        }}
        isLoading={isCreatingLinkCategory || isUpdatingCategory}
      />
      <ConfirmDeleteModal
        opened={openedConfirmationModal}
        onClose={closeConfirmationModal}
        onDelete={() => deleteCompanyLinkCategory(confirmationState?.id)}
        deleteItemName={confirmationState?.name}
        isLoading={isDeletingCategory}
      />
      <CompanyCategoriesModal
        opened={openedCategoriesModal}
        onClose={closeCategoriesModal}
      />
      <Loader isLoading={isCompanyLinksLoading} />
      <EmptyDataMessage
        isVisible={_.isEmpty(state) && !isCompanyLinksLoading}
      />
      {!_.isEmpty(companyLinks) && (
        <DragDropContext
          onDragEnd={({ destination, source }) =>
            reorderList(source, destination)
          }
        >
          <Grid
            columns={9}
            styles={{ inner: { margin: 0, width: '100%' } }}
            mt={10}
          >
            <GridCol
              span={3}
              style={{ backgroundColor: 'var(--mantine-color-gray-4)' }}
              fw={500}
            >
              Link Name
            </GridCol>
            <GridCol
              span={6}
              style={{ backgroundColor: 'var(--mantine-color-gray-4)' }}
              fw={500}
            >
              Link Url
            </GridCol>

            <GridCol
              span={9}
              style={{ display: 'flex', flexDirection: 'column', padding: 0 }}
            >
              {!_.isEmpty(state) &&
                stateAsMap.map((categoryId) => {
                  const menuItems = [
                    {
                      label: 'Edit',
                      icon: IconEdit,
                      onClick: () =>
                        openCreateCategory({
                          modalType: 'Edit',
                          ...state[categoryId],
                        }),
                    },
                    {
                      label: 'Delete',
                      icon: IconTrash,
                      onClick: () => openConfirmationModal(state[categoryId]),
                    },
                  ];
                  return (
                    <React.Fragment key={categoryId}>
                      <Flex
                        align="center"
                        style={{
                          backgroundColor: 'var(--mantine-color-gray-2)',
                        }}
                        p={10}
                        justify="space-between"
                      >
                        <Flex align="center" gap={10}>
                          <IconList size={15} />
                          {state[categoryId]?.name}
                        </Flex>
                        <ContextMenu menuItems={menuItems} />
                      </Flex>
                      <Droppable droppableId={String(categoryId)}>
                        {(provided) => (
                          <CompanyLinkRow
                            data={state[categoryId]}
                            {...provided}
                          />
                        )}
                      </Droppable>
                    </React.Fragment>
                  );
                })}
            </GridCol>
          </Grid>
        </DragDropContext>
      )}
    </>
  );
}
