import {
  Button,
  Flex,
  Loader,
  Modal,
  Select,
  Stack,
  TextInput,
} from '@mantine/core';
import React from 'react';
import PropTypes from 'prop-types';
import {
  useCreateLocation,
  useLoadLookups,
  useUpdateLocation,
} from 'api/hooks';
import { useForm } from '@mantine/form';
import { formatSelectorData, genericValidators } from 'utils';
import { useNotifications } from 'notifications/hooks/useNotifications';
import _ from 'lodash';
import { clearCache } from 'api/clearCache';
import { useQueryClient } from 'react-query';
import { lookupsModels } from 'utils/constants';

export default function CreateLocationModal({ opened, onClose, location }) {
  const { lookups } = useLoadLookups(
    {
      models: [lookupsModels.country, lookupsModels.timezone],
    },
    { enabled: opened },
  );

  const queryClient = useQueryClient();

  const form = useForm({
    initialValues: {
      name: location?.name ?? '',
      address_line1: location?.address_line1 ?? '',
      address_line2: location?.address_line2 ?? '',
      city: location?.city ?? '',
      state: location?.state ?? '',
      zip: location?.zip ?? '',
      country_id: _.toString(location?.country_id) ?? '',
      timezone_id: _.toString(location?.timezone_id) ?? '',
    },
    validate: {
      name: (value) =>
        genericValidators.notEmpty(value, 'Category name is empty'),
      address_line1: (value) =>
        genericValidators.notEmpty(value, 'Address Line - 1 is empty'),
      zip: (value) => genericValidators.notEmpty(value, 'ZIP is empty'),
      city: (value) => genericValidators.notEmpty(value, 'City is empty'),
      country_id: (value) =>
        genericValidators.notEmpty(value, 'Country is empty'),
      timezone_id: (value) =>
        genericValidators.notEmpty(value, 'Timezone is empty'),
    },
  });

  const { setNotifications } = useNotifications();
  const { createLocation, isLoading: isCreating } = useCreateLocation({
    onSuccess: () => {
      setNotifications(['Location has been successfully created']);
      clearCache.onLocationChange(queryClient);
      onClose();
    },
  });

  const { updateLocation } = useUpdateLocation({
    onSuccess: () => {
      setNotifications(['Location has been successfully updated']);
      clearCache.onLocationChange(queryClient);
      onClose();
    },
  });

  const onSubmitForm = () => {
    if (location.modalType === 'Edit') {
      updateLocation({ id: location?.id, payload: { ...form.values } });
    } else {
      createLocation({ ...form.values });
    }
  };

  return (
    <Modal centered opened={opened} onClose={onClose} title="Create Location">
      <form onSubmit={form.onSubmit(onSubmitForm)} noValidate>
        <Stack gap={10}>
          <TextInput
            label="Location Name"
            required
            value={form.values.name}
            error={form.errors.name}
            onChange={(event) =>
              form.setFieldValue('name', event.currentTarget.value)
            }
          />
          <TextInput
            label="Street Address 1"
            required
            value={form.values.address_line1}
            error={form.errors.address_line1}
            onChange={(event) =>
              form.setFieldValue('address_line1', event.currentTarget.value)
            }
          />
          <TextInput
            label="Street Address 2"
            value={form.values.address_line2}
            error={form.errors.address_line2}
            onChange={(event) =>
              form.setFieldValue('address_line2', event.currentTarget.value)
            }
          />
          <Flex gap={10}>
            <TextInput
              label="City"
              required
              value={form.values.city}
              error={form.errors.city}
              onChange={(event) =>
                form.setFieldValue('city', event.currentTarget.value)
              }
            />
            <TextInput
              label="State"
              value={form.values.state}
              error={form.errors.state}
              onChange={(event) =>
                form.setFieldValue('state', event.currentTarget.value)
              }
            />
            <TextInput
              label="ZIP"
              required
              value={form.values.zip}
              error={form.errors.zip}
              onChange={(event) =>
                form.setFieldValue('zip', event.currentTarget.value)
              }
            />
          </Flex>
          <Select
            data={formatSelectorData(lookups?.[lookupsModels.country] ?? [], {
              value: 'id',
              label: 'name',
            })}
            value={form.values.country_id}
            error={form.errors.country_id}
            onChange={(value) => form.setFieldValue('country_id', value)}
            placeholder="Select..."
            required
            label="Country"
            searchable
          />
          <Select
            data={formatSelectorData(lookups?.[lookupsModels.timezone] ?? [], {
              value: 'id',
              label: 'label',
            })}
            value={form.values.timezone_id}
            error={form.errors.timezone_id}
            onChange={(value) => form.setFieldValue('timezone_id', value)}
            placeholder="Select..."
            required
            label="Time Zone"
            searchable
          />
          <Button disabled={isCreating} type="submit">
            {location?.modalType === 'Edit' ? 'Submit' : 'Create'}{' '}
            {isCreating && <Loader ml={10} size={14} />}
          </Button>
        </Stack>
      </form>
    </Modal>
  );
}

CreateLocationModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  location: PropTypes.object,
};

CreateLocationModal.defaultProps = {
  location: {},
};
